import $ from 'jquery'
import axios from 'axios'
import { GAAddToCart, GARemoveCartItem } from '../helper/analytics'

let CART_SERVICE_URL = process.env.REACT_APP_CART_SERVICE_URL
let SERINO_API = process.env.REACT_APP_SERINO_API
let REACT_APP_LAYER_URL = process.env.REACT_APP_LAYER_URL
let BASIC_TOKEN = process.env.REACT_APP_BASIC_TOKEN

export const getAppToken = (appId, user) => {
    return BASIC_TOKEN
}

export default class Cart {

    getCartItems(access_token, x_app_key) {
        return $.ajax({
            url: CART_SERVICE_URL+"/cart",
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        });
    }

    addCartItem(access_token, x_app_key, payload) {
        GAAddToCart(payload, x_app_key)
        return $.ajax({
            url: CART_SERVICE_URL+"/cart",
            method: 'POST',
            data: payload,
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            accept: 'application/json',
            dataType: 'json',
        });
    }

    updateQuantity(access_token, x_app_key, item_id, quantity) {
        return $.ajax({
            url: CART_SERVICE_URL + `/cart/${item_id}/quantity`,
            method: 'PATCH',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            contentType: 'application/json',
			data: JSON.stringify(quantity)
        })
    }

    getCart(access_token, x_app_key) {
        return $.ajax({
            url: CART_SERVICE_URL + `/cart`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            accept: 'application/json',
			dataType: 'json'
        })
    }

    removeCartItem(access_token, x_app_key, item_id, cart_items) {
        GARemoveCartItem(item_id, x_app_key, cart_items)
        return $.ajax({
            url: CART_SERVICE_URL + `/cart/${item_id}`,
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            contentType: 'application/json',
			dataType: 'json'
        })
    }

    clearCart(access_token, x_app_key) {
        return $.ajax({
            url: CART_SERVICE_URL + `/cart`,
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            contentType: 'application/json',
            dataType: 'json'
        })
    }

    deleteCartItems(access_token, x_app_key, payload) {
        return axios.delete(`${CART_SERVICE_URL}/cart`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                "content-type": "application/json",
                "X-APP-KEY": x_app_key,
            },
            data: {
                ids: payload
            }
        })
    }

    getUnavailableProducts(access_token, x_app_key, branch_id) {
        return axios.get(`${SERINO_API}/products/public/get-unavailable-products/${branch_id}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                "content-type": "application/json",
                "X-APP-KEY": x_app_key,
            }
        })
    }

    deleteUnavailableProducts(access_token, x_app_key, payload) {
        return axios.delete(`${SERINO_API}/products/public/delete-unavailable-products`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                "content-type": "application/json",
                "X-APP-KEY": x_app_key,
            },
            data: payload
        })
    }

    validateTimeSlots = (branch_id, payload, x_app_key) => {
        return $.ajax({
            url: `${REACT_APP_LAYER_URL}/delivery/branch/${branch_id}/capacity-slots/validate`,
            method: 'POST',
            data: payload,
            headers: {
                "Authorization": `Basic ${getAppToken(x_app_key)}`,
                "X-APP-KEY": x_app_key
            },
            accept: 'application/json',
            dataType: 'json',
        });
    }
} 

