import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default class LocationPickerComponentInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            isAddressInputLoading: false,
            isChangeAddressActive: false,
            isSearchActive: false,
            location: {},
        }
    }

    componentDidMount() {
        let tmp = this
        var serinoMap = new window.SerinoMaps(`${process.env.REACT_APP_MAP_JS_LIB_KEY}`);
        
        serinoMap.autosuggest({
            button: `#search-${tmp.props.id}`,
            textContainer: `#query-${tmp.props.id}`,
            onSelect: (data) => {
                console.log('data')
                tmp.getGeocodeByAddress(data)
                tmp.setState({
                    location: data,
                    address: data.label,
                    isAddressInputLoading: true,
                    isChangeAddressActive: true
                })
            }
        });

        // $(`#search-${tmp.props.id}`).on('click', () => {
        //     setTimeout(() => {
        //         let el = $('.ui-menu-item')
        //         let first_el = el[0]
        //         if ($(first_el).find('div')[0].innerHTML == 'No Results Found.') {
        //             // log('test', first_el)
        //             $('.ui-menu-item').addClass("avoid-clicks")
        //         }
        //     }, 500)
        // })
    }

    getGeocodeByAddress = (data) => {
        console.log(data)
        localStorage.setItem('address_input', data.label)
        localStorage.setItem('longitude', data.position.lng)
        localStorage.setItem('latitude', data.position.lat)
    }


    handleFieldChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
        sessionStorage.setItem('locationInput', e.target.value)
    }

    onEnter = (e) => {
        if (e.keyCode === 13) {
            this.setState({ isAddressInputLoading: true, isSearchActive: false })
            setTimeout(() => {
                document.getElementById(`search-${this.props.id}`).click()
                this.setState({ isAddressInputLoading: false, isSearchActive: true })
            }, 500)
        }
    }

    searchAddress = (e) => {
        if (e.detail === 1) {
            this.setState({ isAddressInputLoading: true, isSearchActive: false })
            setTimeout(() => {
                document.getElementById(`search-${this.props.id}`).click()
                this.setState({ isAddressInputLoading: false, isSearchActive: true })
            }, 500)
        }
    }

    // refreshAddress() {
    //     localStorage.removeItem('address_input')
    // }


    render() {
        return (
            <div className="location-input-container">
                <div className="input-group mb-3">
                    <input
                        className="location-picker-input form-control"
                        type="text"
                        id={`query-${this.props.id}`}
                        name="address"
                        defaultValue={this.props.clearInput === true || this.props.clearInput === "true" ? "" : this.state.address}
                        onChange={(e) => this.handleFieldChange(e)}
                        // onClick={()=> this.refreshAddress()}
                        placeholder={`Enter street, city and click search`}
                        // disabled={this.props.disabled != null ? this.props.disabled : false}
                        required={true}
                        onKeyUp={(e) => this.onEnter(e)} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" id={`search-${this.props.id}`} type="button">
                                <FontAwesomeIcon icon={faSearch} onClick={(e) => this.searchAddress(e)} />
                            </button>
                        </div>
                </div>
            </div>
        )
    }
}