import { Component } from "react";
import Swal from 'sweetalert2'
import $ from 'jquery'
import cardLogo from '../../assets/images/landers_card_logo.png'
import { generateYearsArray, log } from '../../helper/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from "@fortawesome/free-solid-svg-icons";
import Calendar from 'react-calendar';
import moment from 'moment-timezone'
import RewadAPI from "../../_core/link-reward";
import { getAccessToken, getUrl } from '../../helper/helper'
import axios from 'axios'
import CreditCardSpace from 'credit-card-space';
import { uuid } from 'uuidv4';
import FulfillmentTypeComponent from '../partials/fulfillmetType'
import ParallaxComponent from '../partials/ParallaxComponent'
import LoginApi from '../../_core/login'
import createOrderOMRS from "../../_core/checkout";

var rewardAPI = new RewadAPI()
var omrsAPI = new createOrderOMRS()

const x_app_key = process.env.REACT_APP_X_APP_KEY
let paymayaKey = process.env.REACT_APP_PAYMAYAKEY
let merchant_pk = process.env.REACT_APP_PK
let merchant_sk = process.env.REACT_APP_SK
var login = new LoginApi()

const crypto = require('crypto');

export default class LandersMembership extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOMRSEnabled: process.env.REACT_APP_OMRS_STATUS == 1 ? true : false,
            deliveryChecked: false,
            pickupChecked: false,
            selectedPayment: '',
            payment_method: 'cc',
            isLoading: false,

            isLoading: false,
            loadingInfo: '',
            activeFormPage: 1,

            // PERSONAL INFO
            first_name: '',
            last_name: '',
            // mobile_no: '0917 421-3300',
            mobile_no: '',
            nationality: '',
            selectedDate: '',
            calendarValue: '',
            email: '',
            confirm_email: '',
            gender: [{name:"Male"},{name:"Female"}],
            card_type: '',
            address: '',
            city: '',
            province: '',
            birthday: '',
            civil_status: '',
            selectedGender: '',
            nationality: '',
            address_number: '',
            barangay: '',
            cline: [],

            // FORM VALIDATIONS
            isBirthdayValid: null,
            isGenderValid: null,
            isCardValid: null,
            isContactValid: null,
            isCalendarOpen: false,
            isEmailMatch: null,
            host: '',
            domain: '',
            domain_name: '',
            isFormValid: null,

            //ADD CART TO CREATE BULK
            product: [],
            itemReferenceId: '',
            itemReferenceType: '',
            itemName: '',
            itemSKU: '',
            itemImage: '',
            itemDescription: '',
            basePrice: '',
            price: '',
            custom_data: [{'name':'savings','value':0}],
            productPrice:[],
            branches: [],
            nationalityList: [],
            group_id: '',


            //CREATE BULK AND PAYMENT
            group_id:'',
            total_amount: '',

            //CC PAYMENT
             cvc: "",
             expMonth: "",
             expYear: "",
             name: "",
             number: "",
 
             // payment form error states
             cvcError: "",
             expMonthError: "",
             expYearError: "",
             nameError: "",
             numberError: "",
 
             months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        }
    }


    componentDidMount() {
        // Swal.fire({
        // });Swal.showLoading()
        // setTimeout(() => {
        //     Swal.fire({
        //         text: 'done'
        //     })
        // },2000)

        // console.log("Mnumber",this.state.mobile_no)

        // console.log("final Mnumber","0"+this.state.mobile_no.slice(0,3)+" "+this.state.mobile_no.slice(3,6)+"-"+this.state.mobile_no.slice(6,10))

        
        sessionStorage.removeItem("fromSignupApply")
        this.clearAddress()
        this.handlePaymentResponse()

        let client;
        login.getClientDetails(localStorage.getItem('access_token'), process.env.REACT_APP_X_APP_KEY)
        .then((res) => {
            this.setState({
                first_name: res.data.firstName,
                last_name: res.data.lastName,
                email: res.data.email,
                // mobile_no: res.data.contactNumbers.find(x => x.isPrimary),
                mobile_no: res.data.contactNumbers[0].value.substring(3),
                // contact[0].substring(3)
				selectedDate: moment.utc(res.data.birthdate).format('MM/DD/YYYY')

            })
            // console.log("numberr", this.state.mobile_no)
            // console.log("numberr 1","0"+this.state.mobile_no.slice(0,3)+" "+this.state.mobile_no.slice(3,6)+"-"+this.state.mobile_no.slice(6,10))
        })

        getAccessToken()
        .then(() => {
            var host = window.location.host
            var parts = host.split('.')
            let domain = (parts[0] == 'eats') || (parts[0] == 'market') ? 'sub' : 'main'

            this.setState({
                host: parts,
                domain: domain,
                domain_name: parts[0],
            })
        })

        const input = document.querySelector('#number');
        const ccSpace = new CreditCardSpace(input);
         
        const cardNumber = ccSpace.value()
        this.getProductMembership()
        this.getNationality()
        this.getBranchCode()
    }

    handleSubmit = (e) => {
        this.setState({isLoading: false})
	}

    getProductMembership = async () => {
        rewardAPI.GetProductMembership(localStorage.getItem("access_token"))
            .then((res) => {
                setTimeout(() => {
                    this.setState({
                        // product: product.items,
                        itemReferenceId: res.data.items[0].id,
                        itemReferenceType: res.data.items[0].type,
                        itemName: res.data.items[0].name,
                        itemSKU: res.data.items[0].sku,
                        itemImage: "",
                        itemDescription: res.data.items[0].description,
                        basePrice: res.data.items[0].product_prices[0].price,
                        price: res.data.items[0].product_prices[0].price ? res.data.items[0].product_prices[0].price : "600",
                        productPrice: res.data.items[0].product_prices[0]
                    })
                },3000)
            })
            setTimeout(() => {
                console.log("itemReferenceId: ",this.state.itemReferenceId)
                console.log("itemReferenceType: ",this.state.itemReferenceType)
                console.log("itemName: ",this.state.itemName)
                console.log("itemSKU: ",this.state.itemSKU)
                console.log("itemImage: ",this.state.itemImage)
                console.log("itemDescription: ",this.state.itemDescription)
                console.log("basePrice: ",this.state.basePrice)
                console.log("price: ",this.state.price)
                console.log("productPrice: ",this.state.productPrice)
            },10000)
        }


    getBranchCode = () => {
        rewardAPI.GetBranch(localStorage.getItem("access_token"))
            .then((res) => {
                console.log("GetProducts",res)
                this.setState({
                    branches: res.data
                })
                console.log("branches",this.state.branches)
            })
    }

    getNationality = () => {
        rewardAPI.GetNationality(localStorage.getItem("access_token"))
            .then((res) => {
                console.log("GetNationality",res)
                this.setState({
                    nationalityList: res.data
                })
                console.log("nationality list",this.state.nationalityList)
            })
    }

    //TO BE CHANGE WHEN MEMBERSHIP IS ADDED IN ESB
    
    // createBulk = () => {
    //     this.setState({loadingInfo: 'creating order...'})
    //     let modeOfPayment= this.state.payment_method
    //     let group_id = uuid()
    //     let payload = [
    //         {
    //             "billingAddress": "",
    //             "booking_duration_from": "",
    //             "booking_duration_to": "",
    //             "booking_resource_code": "",
    //             "cart_id": localStorage.getItem('SRN-CART-ID'),
    //             "changeFor": "",
    //             "contactNumber": this.state.mobile_no,
    //             "description": "",
    //             "email": this.state.email,
    //             "firstName": this.state.first_name,
    //             "fulfillmentType": "Digital",
    //             "group_id": group_id,
    //             "items": [
    //                 {
    //                     "custom_data": `[{'name':'savings','value':0},${this.state.price}]`,
    //                     "itemRefDesc": this.state.itemDescription,
    //                     "itemRefId": this.state.itemReferenceId,
    //                     "itemRefImg": "null",
    //                     "itemRefName": this.state.itemName,
    //                     "itemSku": this.state.itemSKU,
    //                     "itemType": "basic_product",
    //                     "item_custom": null,
    //                     "item_instruction": "",
    //                     "price": this.state.price,
    //                     "qty": 1,
    //                     "selected_options": null
    //                 }
    //             ],
    //             "lastName": this.state.last_name,
    //             "latitude": 14.5590246,
    //             "longitude": 121.0146013,
    //             "modeOfPayment": this.state.payment_method == "cc" ? "Online Credit Card" : "Paymaya Wallet",
    //             "notes": {
    //                 "commentatorName": " ",
    //                 "note": "",
    //                 "status": "",
    //                 "title": "Note to Rider",
    //                 "type": ""
    //             },
    //             "option_id": "1",
    //             "paymentStatus": "Pending",
    //             "pickupDatetime": "",
    //             "pinned_address": "",
    //             "shippingAddress": this.state.branch_code,
    //             "shippingType": "",
    //             "storeId": 8,
    //             "trackingNumber": "",
    //             "type": "Products",
    //             "user_input_address": ""
    //         }
    //     ]
    //     console.log("payload", payload)
    //     rewardAPI.CreateBulk(payload, localStorage.getItem('access_token'))
    //         .then((res) => {
    //             this.setState({
    //                 group_id: res.message.data.group_id,
    //                 // total_amount: res.message.data.order_amounts.total_items_amount
    //                 total_amount: this.state.price
    //             })
    //             localStorage.setItem('group_id', res.message.data.group_id)
    //             this.handleCardPayment(res, modeOfPayment)
    //             this.updateTransactionId()
    //         })
    // }

    createBulk = () => {
        this.setState({loadingInfo: 'creating order...'})
        let modeOfPayment= this.state.payment_method
        let group_id = uuid()

        let payloadOMRS = {
            "payment": {
                "type": "online",
                "option": "Paymaya Credit/Debit"
            },
            "orders": [
                {
                    "store_reference_id": "6",
                    "merchant_reference_id": "market",
                    "fulfillment_type": "digital",
                    "shipping_address": {
                        "full_address": sessionStorage.getItem("address"),
                        "pinned_address": "",
                        "region": sessionStorage.getItem("province") || "na",
                        "city": sessionStorage.getItem("city") || "na",
                        "barangay": sessionStorage.getItem("line1") || "na",
                        "street": "",
                        "landmark": "",
                        "zip_code": sessionStorage.getItem("zipCode") || "na",
                        "latitude": 0,
                        "longitude": 0,
                        "details": ""
                    },
                    "customer": {
                        "first_name": this.state.first_name,
                        "last_name": this.state.last_name,
                        "email": this.state.email,
                        "contact_number": this.state.mobile_no,
                        "reference_1": "",
                        "reference_2": "",
                        "reference_3": ""
                    },
                    "schedule": "",
                    "reservation_reference": "",
                    "notes": [],
                    "items": [
                        {
                            "type": "digital",
                            "reference_id": this.state.itemReferenceId.toString() || "123",
                            "sku": this.state.itemSKU || "1232323",
                            "barcode": "11010101001",
                            "name": this.state.itemName+" Membership" || "",
                            "description": this.state.itemDescription || "not set",
                            "quantity": 1,
                            "price": this.state.price || 700,
                            "image": "",
                            "options": "",
                            "instructions": "",
                            "reference_1": "",
                            "reference_2": "",
                            "reference_3": ""
                        }
                    ],
                    "action_to_proceed": "",
                    "source": "Central PH",
                    "platform": "Web",
                    "reference_1": "",
                    "reference_2": "",
                    "reference_3": ""
                }
            ]
        }

        let payload = [
            {
                "billingAddress": "",
                "booking_duration_from": "",
                "booking_duration_to": "",
                "booking_resource_code": "",
                "cart_id": localStorage.getItem('SRN-CART-ID'),
                "changeFor": "",
                "contactNumber": this.state.mobile_no,
                "description": "",
                "email": this.state.email,
                "firstName": this.state.first_name,
                "fulfillmentType": "Digital",
                "group_id": group_id,
                "items": [
                    {
                        "custom_data": `[{'name':'savings','value':0},${this.state.price}]`,
                        "itemRefDesc": this.state.itemDescription,
                        "itemRefId": this.state.itemReferenceId,
                        "itemRefImg": "null",
                        "itemRefName": this.state.itemName,
                        "itemSku": this.state.itemSKU,
                        "itemType": "basic_product",
                        "item_custom": null,
                        "item_instruction": "",
                        "price": this.state.price,
                        "qty": 1,
                        "selected_options": null
                    }
                ],
                "lastName": this.state.last_name,
                "latitude": 14.5590246,
                "longitude": 121.0146013,
                "modeOfPayment": this.state.payment_method == "cc" ? "Online Credit Card" : "Paymaya Wallet",
                "notes": {
                    "commentatorName": " ",
                    "note": "",
                    "status": "",
                    "title": "Note to Rider",
                    "type": ""
                },
                "option_id": "1",
                "paymentStatus": "Pending",
                "pickupDatetime": "",
                "pinned_address": "",
                "shippingAddress": this.state.branch_code,
                "shippingType": "",
                "storeId": 8,
                "trackingNumber": "",
                "type": "Products",
                "user_input_address": ""
            }
        ]
        

        console.log("payload", this.state.isOMRSEnabled == true ? "payloadOMRS" : "Not OMRS")
        if (this.state.isOMRSEnabled == true) {
            omrsAPI.createOrderOMRS(localStorage.getItem('access_token'), payloadOMRS)
            .then((res) => {
                this.setState({
                    group_id: res.data.data[0].tracking_number,
                    // total_amount: res.message.data.order_amounts.total_items_amount
                    total_amount: this.state.price
                })
                localStorage.setItem('group_id', res.data.data[0].tracking_number)
                this.handleCardPayment(res, modeOfPayment)
                this.updateTransactionId()
            })
        } else {
            rewardAPI.CreateBulk(payload, localStorage.getItem('access_token'))
            .then((res) => {
                console.log("ressnotomrs", res)
                this.setState({
                    group_id: res.message.data.group_id,
                    // total_amount: res.message.data.order_amounts.total_items_amount
                    total_amount: this.state.price
                })
                localStorage.setItem('group_id', res.message.data.group_id)
                this.handleCardPayment(res, modeOfPayment)
                this.updateTransactionId()
            })
        }
    }

    hashString = (payload) => {
        let str = `${payload.secretKey}/${payload.publicKey}/${payload.merchantTrnId}/${payload.amount}/${payload.currency}/${payload.description}`;
        
        const shasum = crypto.createHash('sha256');
        return shasum.update(str).digest('hex');
    }

    handleCardPayment = (data, modeOfPayment) => {
        // log('cardpayment data', data)
        this.setState({loadingInfo: 'loading payment...'})
        let hashPayload = {
            "merchantTrnId": this.state.group_id,
            "amount": this.state.total_amount.toFixed(2),
            "description": "Paymaya Payment Web App",
            "publicKey": merchant_pk,
            "secretKey": merchant_sk,
            "currency": "PHP"
        };
        let hash = this.hashString(hashPayload);
        localStorage.setItem('card payment data', JSON.stringify(data))
        // this.guestPayment(data, hash)
        switch (modeOfPayment) {
            case 'cc':
                this.guestPayment(data, hash)
                break;
            case 'pm':
                this.walletPayment(data, hash)
                break;
            default:
                break;
        }
    }

    walletPayment = (data, hash) => {
        let batchPayload = {
            "ref_id": this.state.isOMRSEnabled == true ? data.data.data[0].tracking_number : [data.message.data.group_id]
        }
        axios.post(`${process.env.REACT_APP_SERINO_API}/customer/billing/public/create-batch-payment`, batchPayload, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                "content-type": "application/json",
                "X-APP-KEY": x_app_key,
            }
        })
        .then((batch_data) => {
            // log('batch_dataaaa', batch_data)
            let paymentPayload = {
                walletPayment: {
                    totalAmount: {
                        value: this.state.total_amount,
                        currency: 'PHP'
                    },
                    // requestReferenceNumber: data.message.data.group_id,
                    requestReferenceNumber: this.state.isOMRSEnabled == true ? data.data.data[0].tracking_number :[data.message.data.group_id],
                    redirectUrl: {
                        success: getUrl('main') + '/membership?r=success',
                        failure: getUrl('main') + '/membership?r=fail',
                        cancel: getUrl('main') + '/membership?r=cancel'
                    }
                },
                transactionDetails: {
                    currency: 'PHP',
                    amount: this.state.total_amount,
                    products: this.state.isOMRSEnabled == true ? data.data.data[0].items[0] : JSON.stringify(data.message.data.items),
                    description: "Paymaya Payment Web App",
                    paymentMethod: "Paymaya Wallet",
                    // merchantTxnId: data.message.data.group_id,
                    merchantTxnId: this.state.isOMRSEnabled == true ? data.data.data[0].tracking_number : [data.message.data.group_id],
                    merchantId: merchant_pk,
                    // param1: data.message.data.group_id,
                    param1: this.state.isOMRSEnabled == true ?  data.data.data[0].tracking_number : [data.message.data.group_id],
                    param2: 'Central Main',
                    hashValue: hash.trim()
                },
                
                processor: "PAYMAYA WALLET"
            }

            localStorage.setItem('paymentPayload', JSON.stringify(paymentPayload))
            
            $.ajax({
                url: process.env.REACT_APP_PAYMENT_URL + '/api/cards/paymaya-wallet',
                method: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(paymentPayload)
            }).done((result) => {
                log('result', result)
                if (result.success) {
                    result.hasOwnProperty('redirectUrl') ? window.location = result.redirectUrl : 
                    Swal.fire({
                        title: "Error!",
                        // text: "Session has expired",
                        text: "Payment Failed!",
                        icon: "error"
                    })
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "Payment Failed!",
                        icon: "error"
                    }).then((value) => {
                        // this.removeInfo();
                        window.location = "/";
                    });
                }
            }).fail(function (result) {
                if (result.status === 401) {
                    Swal.fire({
                        title: "Error!",
                        text: "Payment Failed!",
                        icon: "error"
                    }).then((value) => {
                        // this.removeInfo();
                        window.location = "/";
                    });
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "Payment Failed!",
                        icon: "error"
                    })
                }
            });;
        })
        .catch((res) => {
            console.log("paymaya payment error", res)
            Swal.fire({
                title: "Error!",
                text: "Payment Failed!",
                icon: "error"
            })
        });
    }

    guestPayment = (data, hash) => {

        // console.log('data message', data.data.data[0].items[0])
        let paymentPayload = {
            tokenPayment: {
                paymentTokenId: this.state.guestPaymentToken,
                totalAmount: {
                    amount: this.state.total_amount.toFixed(2),
                    currency: 'PHP'
                },
                requestReferenceNumber: this.state.group_id,
                // requestReferenceNumber: data.message.data.items,
                redirectUrl: {
                    success: getUrl('main') + '/membership?r=success',
                    failure: getUrl('main') + '/membership?r=fail',
                    cancel: getUrl('main') + '/membership?r=cancel'
                },
                "buyer": {
                    "firstName": this.state.first_name, 
                    "lastName": this.state.last_name,
                    "contact": {
                    "phone": `+63${this.state.mobile_no}`,
                    "email": this.state.email
                    },
                    "shippingAddress": {
                    "firstName": this.state.first_name,
                    "lastName": this.state.last_name,
                    "phone": `+63${this.state.mobile_no}`,
                    "email": this.state.email,
                    "line1": this.state.branch_code,
                    "line2": "", // leave blank
                    "city": "", // leave blank
                    "zipCode": "", // leave blank
                    "countryCode": "PH", //hardcode mo nalang na PH
                    "shippingType": "" // leave blank
                    },
                    "billingAddress": {
                    "line1": this.state.branch_code,
                    "line2": "", // leave blank
                    "city": "", // leave blank
                    "zipCode": "", // leave blank
                    "countryCode": "PH" //hardcode mo nalang na PH
                    }
                }
            },

            transactionDetails: {
                currency: 'PHP',
                amount: this.state.total_amount.toFixed(2),
                products: this.state.isOMRSEnabled == true ? data.data.data[0].items[0] : JSON.stringify(data.message.data.items),
                description: "Paymaya Payment Web App",
                paymentMethod: "Credit / Debit Card",
                // merchantTxnId: data.message.data.group_id,
                merchantTxnId: this.state.isOMRSEnabled == true ? data.data.data[0].tracking_number : [data.message.data.group_id],
                merchantId: merchant_pk,
                // param1: data.message.data.group_id,
                param1: this.state.isOMRSEnabled == true ? data.data.data[0].tracking_number : [data.message.data.group_id],
                param2: 'Central Main',
                hashValue: hash.trim()
            },
            processor: "PAYMAYA"
        }

        console.log("axios",paymentPayload)
        localStorage.setItem('payment payload', JSON.stringify(paymentPayload))
        axios.post(`${process.env.REACT_APP_PAYMENT_URL}/api/cards/token/pay`, paymentPayload, {
            headers: {
                "content-type": "application/json"
            }
        })
        .then((result) => {
            // console.log('ressssuuult', result)
            // console.log('ressssuuult', result.success)
            localStorage.setItem('payment result', JSON.stringify(result))
            if (result.data.success && result.data.status) {
                if (result.data.status == "FOR_AUTHENTICATION") {
                    window.location = result.data.verificationUrl
                    console.log(result.data.verificationUrl)
                } else {
                    Swal.fire({
                        title: "Success!",
                        text: "Payment Successful",
                        icon: "success"
                    }).then((value) => {
                        window.location = "/";
                    });
                }
            } 
            // else {
            //     // console.log('payment 1 fail', result)
            //     // console.log('payment 1 fail success', result.success)
            //     Swal.fire({
            //         title: "FAIL",
            //         text: "Payment Failed",
            //         icon: "error"
            //     }).then((value) => {
            //         window.location = "/";
            //     });
            // }
        }).catch((err) => {
            log('ressssuuult FAILED', err)
            log('ressssuuult FAILED RESPONSE', err.response)
            if (err.status === 401) {
                localStorage.removeItem('access_token')
                window.location = "/";
            }
            else {
                Swal.fire({
                    title: "FAIL!",
                    text: "Payment Failed",
                    icon: "error"
                }).then((value) => {
                    window.location = "/";
                });
            }
        }).catch((res) => {
            console.log('ressssuuult', res)
            Swal.fire({
                title: "FAIL!",
                text: "Payment Failed",
                icon: "error"
            }).then((value) => {
                window.location = "/";
            });
        });
    }
    
    Apply = () => {
        this.setState({
            isLoading: true,
            loadingInfo: 'loading membership...'
        })
        // document.getElementById("personaldetailsform").click()
        if (this.state.isFormValid == true) {
            let payload = {
                "last_name": this.state.last_name,
                "first_name": this.state.first_name,
                "email": this.state.email,
                "mobile_number": "0"+this.state.mobile_no.slice(0,3)+" "+this.state.mobile_no.slice(3,6)+"-"+this.state.mobile_no.slice(6,10),
                "card_type": "premium",
                "birth_date": this.state.selectedDate,
                "civil_status": "single",
                "gender": this.state.selectedGender || "male",
                "nationality": this.state.nationality || "fil",
                "branch_code": this.state.branch_code || "1040",
                "address": {
                    // "address": this.state.address,
                    // "number": this.state.address_number || "0000",
                    // "barangay": this.state.barangay || "na",
                    // "city": this.state.city || "na",
                    // "province": this.state.province || "na",
                    // "zip": this.state.zip_code || "na",
                    
                    "address": sessionStorage.getItem("address"),
                    "number": "0000",
                    "barangay": sessionStorage.getItem("line1") || "na",
                    "city": sessionStorage.getItem("city") || "na",
                    "province": sessionStorage.getItem("province") || "na",
                    "zip": sessionStorage.getItem("zipCode") || "na",
                }
            }
            console.log("payload", payload)
            rewardAPI.ApplyLanders(payload, localStorage.getItem('access_token'))
                .then((res) => {
                    console.log("Apply landers res",res)
                    if (res.data.customer_code != "") {
                        this.createBulk()
                        this.setState({
                            isLoading: true
                        })
                        localStorage.setItem('mName', this.state.first_name +' '+ this.state.last_name)
                        localStorage.setItem('mEmail', this.state.email)
                        localStorage.setItem('mID',res.data.customer_code)
                        localStorage.setItem('applied', true)
                    } else {
                        Swal.fire({
                            text: "Failed to add member",
                            icon: 'error'
                        }).then(() => {
                            console.log("error")
                        })
                        this.setState({ isLoading: false })
                    }
                })
        } else {
            // this.validateForm()
            this.setState({
                isLoading: false
            })
            // alert("invalid form")
        }
    }

    updateTransactionId = () => {
        let payload = {
            "customer_code": localStorage.getItem('mID'),
            "transaction_number": localStorage.getItem('group_id')
        }
        rewardAPI.UpdateTransactionId(payload, localStorage.getItem('access_token'))
            .then((res) => {
                console.log("linkUpdate landers res",res)
            })
    }

    handlePaymentResponse = () => {
        let objFromUrl;
        let search;
        let isloggedIn = localStorage.getItem("isLoggedIn")
        if (this.props.location.search.length > 0) {
            search = this.props.location.search.substring(1);
            objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            console.log('obj',objFromUrl.page)
            if (objFromUrl.r == "success" && localStorage.getItem('applied') === 'true')  {
                if( isloggedIn == "true" || isloggedIn == true ) {
                    this.linkMembershipCard()
                } else {
                    Swal.fire({
                        text: "",
                        html: `<div class='col-md-12'>
                                    <h3 class='cmb-title-3 my-3 text-center text-dark'>Membership Added Successfully</h6>
                                    <p class='my-3 text-left pl-3 tex-dark'><strong>Name: ${localStorage.getItem('mName')}</strong></p>
                                    <p class='my-3 text-left pl-3 tex-dark'><strong>Email: ${localStorage.getItem('mEmail')}</strong></p>
                                    <p class='my-3 text-left pl-3 tex-dark'><strong>Membership ID: ${localStorage.getItem('mID')}</strong></p>
                                </div>`,
                        icon: 'success',
                    }).then(() => {
                        localStorage.removeItem('mName')
                        localStorage.removeItem('mEmail')
                        localStorage.removeItem('mID')
                        window.location = "/membership"
                    })
                }
               console.log('obj',objFromUrl)
            } else if (objFromUrl.r == "fail" ) {
                let payload = {
                    "customer_code": localStorage.getItem('mID'),
                    "transaction_number": ""
                }
                rewardAPI.UpdateTransactionId(payload, localStorage.getItem('access_token'))
                    .then((res) => {
                        console.log("linkUpdate landers res",res)
                    })  
                Swal.fire({
                    text: "Payment Fail",
                    icon: 'error',
                })
            } else if (objFromUrl.r == "cancel" ) {
                let payload = {
                    "customer_code": localStorage.getItem('mID'),
                    "transaction_number": ""
                }
                rewardAPI.UpdateTransactionId(payload, localStorage.getItem('access_token'))
                    .then((res) => {
                        console.log("linkUpdate landers res",res)
                    })
                Swal.fire({
                    text: "Payment Cancelled",
                    icon: 'error',
                })
            }
            else{
                console.log('applied')
            }
        }
        
    }

    initializePaymaya = () => {

        var paymaya = window.paymaya

        // PAYMAYA

        log('PayMaya')

        let source = document.getElementById('paymaya')
        
            log(source)
        if (source) {
            let destination = document.getElementById('cmb-payment-form')
            destination.appendChild(source);
            log(destination)
        }

        const paymentFormResponse = (response) => {
            if (response.data.id === "handler") {
                if (response.data.success) {
                    var resp = JSON.parse(response.data.payload.resp);
                    let token = resp.paymentTokenId;
                    this.Apply()

                    if (this.state.isLoading) {
                        return
                    }
                    this.setState({
                        guestPaymentToken: token,
                        isLoading: true
                    })
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "Please try again!",
                        icon: "error"
                    });
                }
            }
        }

        var options = {};
        options.pk = paymayaKey;
        options.handler = paymentFormResponse;
        options.source = "cdn.paymaya.com Source";
        options.style = {
            '#name': {
                "name": "ksjdkljaskldj",
                "display": "block",
                "width": "100%",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "maxWidth": "100%",
                "font-family": "'gotham-regular', sans-serif",
            },
            '#creditCard': {
                "display": "block",
                "width": "100%",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "maxWidth": "100%",
                "font-family": "'gotham-regular', sans-serif",
            },
            '#expMonth': {
                "width": "100px",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid #ced4da",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "font-family": "'gotham-regular', sans-serif",
            },
            '#expYear': {
                "width": "100px",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "font-family": "'gotham-regular', sans-serif"
            },
            '#cvv': {
                "display": "block",
                "width": "100px",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "font-family": "'gotham-regular', sans-serif",
            },
            'label': {
                "font-size": "13px",
                "font-family": "'gotham-regular', sans-serif"
            },
            'button': {
                "font-family": "'gotham-regular', sans-serif",
                "font-weight": "bold",
                "font-size": "14px",
                "box-sizing": "border-box",
                "margin-left": "20px",
                "margin-right": "20px",
                "width": "calc(100% - 40px)",
                "display": "inline-block",
                "border": "none",
                "border-radius": "0",                
                "background-image": "-webkit-linear-gradient(left, #131216, #18181b, #1d1d1f, #232224, #282829, #282829, #282829, #282829, #232224, #1d1d1f, #18181b, #131216)",
                "background-image": "-o-linear-gradient(left, #131216, #18181b, #1d1d1f, #232224, #282829, #282829, #282829, #282829, #232224, #1d1d1f, #18181b, #131216)",
                "background-image": "linear-gradient(to right, #131216, #18181b, #1d1d1f, #232224, #282829, #282829, #282829, #282829, #232224, #1d1d1f, #18181b, #131216)",
                "color": "white",
                "height": "44px",
                "line-height": "44px",
                "text-transform": "uppercase",
            },            
            'iframe': {
                "width": "calc(100% - 40px)",
                "min-width": "calc(100% - 40px)",
                "margin-left": "20px",
                "margin-right": "20px"
            }
        }
        paymaya.createForm(options);
    }

    handlePaymentFormSubmit = async (event) => {
        event.preventDefault()

        //document.getElementById("personaldetailsform").click() //TEMPORARY FIX 12/27/2021
        
        if (this.state.isFormValid == true) {
            // if (this.state.isLoading) {
            //     return
            // }
    
            this.setState({
                isLoading: true,
                cvcError: '',
                expMonthError: '',
                expYearError: '',
                nameError: '',
                numberError: '',
            })
    
            if (this.state.name.length == 0) {
                this.setState({
                    nameError: ' ',
                    isLoading: false
                })
            }
    
            let cvc =  this.state.cvc
            let expMonth = this.state.expMonth
            let expYear =  this.state.expYear
            let name =  this.state.name
            let number =  this.state.number.split(' ').join('')
            let pk =  paymayaKey
    
            let stagingPayload = {
                card: {
                    name: name,
                    number: number,
                    expMonth: expMonth,
                    expYear: expYear,
                    cvc: cvc
                }
            }
    
            let api_url = process.env.REACT_APP_PAYMAYA_API
            let api = `/payments/v1/payment-tokens`
    
            try {
    
                let stagingResult = await axios.post(`${api_url}${api}`, stagingPayload, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Basic " + window.btoa(paymayaKey + ':'),
                        origin: "https://cdn.paymaya.com"
                    }
                })
    
                log("sr",{stagingResult})
                log("sr.data",stagingResult.data)
                if (stagingResult) {
                    localStorage.setItem('stagingResult', JSON.stringify(stagingResult))
                }
                console.log("result sr", stagingResult.status == 200)
                if (stagingResult.status == "200") {
                    let token = stagingResult.data.paymentTokenId;
                        this.setState({
                            guestPaymentToken: token,
                        })
                        let payload = {
                            "last_name": this.state.last_name,
                            "first_name": this.state.first_name,
                            "email": this.state.email,
                            "mobile_number": "0"+this.state.mobile_no.slice(0,3)+" "+this.state.mobile_no.slice(3,6)+"-"+this.state.mobile_no.slice(6,10),
                            "card_type": "premium",
                            "birth_date": this.state.selectedDate,
                            "civil_status": "single",
                            "gender": this.state.selectedGender || "male",
                            "nationality": this.state.nationality || "fil",
                            "branch_code": this.state.branch_code || "1040",
                            "address": {
                                "address": sessionStorage.getItem("address"),
                                "number": "0000",
                                "barangay": sessionStorage.getItem("line1") || "na",
                                "city": sessionStorage.getItem("city") || "na",
                                "province": sessionStorage.getItem("province") || "na",
                                "zip": sessionStorage.getItem("zipCode") || "na",
                            }
                        }
                        console.log("payload", payload)
                        rewardAPI.ApplyLanders(payload, localStorage.getItem('access_token'))
                            .then((res) => {
                                console.log("Apply landers res",res)
                                if (res.data.customer_code != "") {
                                    this.createBulk()
                                    this.setState({
                                        isLoading: true
                                    })
                                    localStorage.setItem('mName', this.state.first_name +' '+ this.state.last_name)
                                    localStorage.setItem('mEmail', this.state.email)
                                    localStorage.setItem('mID',res.data.customer_code)
                                    localStorage.setItem('applied', true)
                                } else {
                                    Swal.fire({
                                        text: "Failed to add member",
                                        icon: 'error'
                                    }).then(() => {
                                        console.log("error")
                                    })
                                    this.setState({ isLoading: false })
                                }
                            })
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "Please try again!",
                        icon: "error"
                    });
                }
    
            }
            catch (stagingResultError) {
                log('stagingResultError', stagingResultError)
                log('stagingResultError', stagingResultError.response)
                let error = stagingResultError.response.data.parameters
    
                const hasError = (field) => {
                    return  error.find(x => x.field == `card.${field}`)
                }
    
                if (this.state.name.length == 0) {
                    this.setState({
                        nameError: ' ',
                        isLoading: false
                    })
                }
    
                this.setState({
                    cvcError: hasError('cvc') ? hasError('cvc').description : '',
                    expMonthError: hasError('expMonth') ? hasError('expMonth').description : '',
                    expYearError: hasError('expYear') ? hasError('expYear').description : '',
                    nameError: hasError('name') ? hasError('name').description : this.state.name.length == 0 ? ' ' : '',
                    numberError: hasError('number') ? hasError('number').description : '',
                    isLoading: false
                })
    
                if (stagingResultError.response.status == 400) {
    
                    Swal.fire({
                        title: "Error!",
                        text: "Please try again!",
                        icon: "error"
                    });
                }
            }
        } else {
            console.log("form not valid")
        }
    }

    handlePageForm = (page) => {
        this.setState({
            activeFormPage: page
        })
    }

    handleFieldChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value 
        })
    }

    openCalendar = () => {
		if(this.state.isCalendarOpen != true) {
			this.setState({
				isCalendarOpen: true
			})
		}
	}

	closeCalendar = (e) => {
		if(this.state.isCalendarOpen != false) {
			this.setState({
				isCalendarOpen: false
			})
		}
	}

    onCalendarChange = (e) => {
        let date = moment(e).format(moment.HTML5_FMT.DATE)
		this.setState({
			selectedDate: moment.utc(date).format('MM/DD/YYYY'),
			calendarValue: date
		})
    }

    handleGender = (e) => {
        var selectBox = document.getElementById("gender-select");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        // alert(selectedValue);
        this.setState({
            selectedGender: selectedValue
        })
    }

    handleNationality = () => {
        var selectBox = document.getElementById("nationality-select");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        // alert(selectedValue);
        this.setState({
            nationality: selectedValue
        })
        console.log(this.state.nationality)
    }

    handleBranch = () => {
        var selectBox = document.getElementById("branch-select");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        // alert(selectedValue);
        this.setState({
            branch_code: selectedValue
        })
    }

    handlePayment = () => {
        var selectBox = document.getElementById("payment-select");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        // alert(selectedValue);
        this.setState({
            payment_method: selectedValue
        })
    }


    openFulfillmentTypeSignUp = () => {
		$("#fulfillmenttype_modal").modal("show")
        sessionStorage.setItem("signUpEnabled", true)
		localStorage.setItem("fulfillment_mode", 1)
	}

    validateForm = (e) => {
        e.preventDefault();
		e.stopPropagation();

        this.setState({isLoading: true})

        let isBirthdayValid = false
		if (this.state.selectedDate != '') {
			isBirthdayValid = true
		}

        let isEmailMatch = false
		if (this.state.email.toLowerCase() == this.state.confirm_email.toLowerCase()) {
			isEmailMatch =  true	
		} else {
			this.setState({
				isEmailMatch : false,
                isLoading: false
			})
		}

        let isGenderValid = false
		if (this.state.selectedGender != '') {
			isGenderValid = true
		}

        let isPickupBrandValid = false
		if (this.state.branch_code != '') {
			isPickupBrandValid = true
		}
        
        let isContactValid = false
        if (this.state.mobile_no.length > 0) {

            var phoneRegex = /(^\+[6][3][9]\d{9}$)|(^[0][9]\d{9}$)/
    
            // Contact
            let contact = '+63' + this.state.mobile_no
            
    
            if (phoneRegex.test(contact.trim())) {
                isContactValid = true
            }
    
        }
        setTimeout(() => {
        	this.setState({
                isContactValid,
				isBirthdayValid,
                isGenderValid,
                isPickupBrandValid
        	}, () => {
        		if (isBirthdayValid && isGenderValid && isContactValid && isPickupBrandValid && isEmailMatch) {
                    this.setState({
                        isFormValid: true
                    })
                    rewardAPI.ValidateApplyEmail(this.state.email, localStorage.getItem("access_token"))
                    .then((res) => {
                        console.log("validate email",res)
                        if (res.data.isExist == true || res.data.isPending == true) {
                            Swal.fire({
                                text: res.message,
                                icon: 'error'
                            })
                        } else {
                            if (this.state.payment_method == "cc") {
                                setTimeout(() => {
                                    document.getElementById("confirmandpay").click()
                                },1500)
                            } else {
                                setTimeout(() => {
                                    this.Apply()
                                },1500)
                            }
                        }
                    }).catch(() => {
                        Swal.fire({
                            text: "failed to verify email, please try again.",
                            icon: 'error'
                        })
                        this.setState({isLoading: false})
                    })
        		}
        	})
        }, 100)

    }

    validateAddressForm = (e) => {
        e.preventDefault();
		e.stopPropagation();
        
        this.handlePageForm(3)
    }

    validateEmail = (e) => {

        var email = this.state.email
        var emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        
        if (!emailValidation.test(email)) {
            Swal.fire({
                html: 'Please enter valid email.',
                icon: 'warning',
                customClass: {
                    confirmButton: 'btn btn-block srn-crm-ok-btn'
                },
                buttonsStyling: false
            }).then(() => {
                    this.setState({ isLoading: false })
            })
        } else {
            rewardAPI.ValidateApplyEmail(email,localStorage.getItem("access_token"))
            .then((res) => {
                console.log("validate email",res)
                if (res.data.isExist == true || res.data.isPending == true) {
                    Swal.fire({
                        text: res.message,
                        icon: 'error'
                    })
                } else {
                    document.getElementById('multi-step-form').style.display = 'block'
                    document.getElementById('enter-email').style.display = 'none'
                    document.getElementById('premium-card-container').style.display = 'none'
                    document.getElementById('apply-membeship-header').style.display = 'none'
                }
            })
        }
        this.setState({ isLoading: false })
    }

    linkMembershipCard = () => {
        let isloggedIn = localStorage.getItem("isLoggedIn")
        let payload = {
            "email": localStorage.getItem('userEmail'),
            "name": localStorage.getItem('mName'),
            "membership_id": localStorage.getItem('mID')
        }
        Swal.fire({
        });Swal.showLoading()
        if( isloggedIn == "true" || isloggedIn == true ) {
            if(payload.email != null && payload.name != null && payload.membership_id != null) {
                rewardAPI.linkMembership(payload, localStorage.getItem('access_token'))
                .then((res) => {
                    console.log('link membership',res)
                    if (res.message == "Membership linked successfully") {
                        Swal.fire({
                            text: "",
                            html: `<div class='col-md-12'>
                                        <h3 class='cmb-title-3 my-3 text-center text-dark'>Membership Added Successfully</h6>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Name: ${localStorage.getItem('mName')}</strong></p>
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Email: ${localStorage.getItem('mEmail')}</strong></p>
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Membership ID: ${localStorage.getItem('mID')}</strong></p>
                                    </div>`,
                            icon: 'success',
                        }).then(() => {
                            localStorage.removeItem('mName')
                            localStorage.removeItem('mEmail')
                            localStorage.removeItem('mID')
                            localStorage.removeItem('userEmail')
                            window.location = "/profile?page=4#!"
                        })
                        console.log("membership Linking success")
                    } else {
                        console.log("membership Linking failed")
                    }
                    this.setState({ isLoading: false })
                })
                .catch(err => {
                    Swal.fire({
                        // text: "",
                        // html: `<div class='col-md-12'>
                        //             <h3 class='cmb-title-3 my-3 text-center text-dark'>link membership failed</h6>
                        //             <p class='my-3 text-left pl-3 tex-dark'><strong>Name: ${localStorage.getItem('mName')}</strong></p>
                        //             <p class='my-3 text-left pl-3 tex-dark'><strong>Email: ${localStorage.getItem('mEmail')}</strong></p>
                        //             <p class='my-3 text-left pl-3 tex-dark'><strong>Membership ID: ${localStorage.getItem('mID')}</strong></p>
                        //         </div>`,
                        // icon: 'error',
                        text: "",
                            html: `<div class='col-md-12'>
                                        <h3 class='cmb-title-3 my-3 text-center text-dark'>Membership Added Successfully</h6>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Name: ${localStorage.getItem('mName')}</strong></p>
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Email: ${localStorage.getItem('mEmail')}</strong></p>
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Membership ID: ${localStorage.getItem('mID')}</strong></p>
                                    </div>`,
                            icon: 'success',
                    }).then(() => {
                        Swal.fire({
                            // text: "",
                            // html: `<div class='col-md-12'>
                            //             <h3 class='cmb-title-3 my-3 text-center text-dark'>link membership to account failed</h6>
                            //             <p class='my-3 text-left pl-3 tex-dark'><strong>Name: ${localStorage.getItem('mName')}</strong></p>
                            //             <p class='my-3 text-left pl-3 tex-dark'><strong>Email: ${localStorage.getItem('mEmail')}</strong></p>
                            //             <p class='my-3 text-left pl-3 tex-dark'><strong>Membership ID: ${localStorage.getItem('mID')}</strong></p>
                            //         </div>`,
                            // icon: 'error',
                            text: "",
                            html: `<div class='col-md-12'>
                                        <h3 class='cmb-title-3 my-3 text-center text-dark'>Membership Added Successfully</h6>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Name: ${localStorage.getItem('mName')}</strong></p>
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Email: ${localStorage.getItem('mEmail')}</strong></p>
                                        <p class='my-3 text-left pl-3 tex-dark'><strong>Membership ID: ${localStorage.getItem('mID')}</strong></p>
                                    </div>`,
                            icon: 'success',
                        })
                    }).then(() => {
                        localStorage.removeItem('mName')
                        localStorage.removeItem('mEmail')
                        localStorage.removeItem('mID')
                        // localStorage.removeItem('userEmail')
                        window.location = "/profile?page=4#!"
                    })
                    // console.log("asdasdasd",err.successful, err)
                    // if (err.status != "0") {
                    //     Swal.fire({
                    //         html: err.responseJSON.message,
                    //         icon: 'error'
                    //     }).then(() => {
                    //         this.setState({ isLoading: false })
                    //     })
                    // } else {
                    //     Swal.fire({
                    //         html: "failed to link membership",
                    //         icon: 'error'
                    //     }).then(() => {
                    //         this.setState({ isLoading: false })
                    //     })
                    // }
                    console.log("membership Linking failed")
                })
            }
        }
    }

    clearAddress = () => {
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('line1')
        sessionStorage.removeItem('line2')
        sessionStorage.removeItem('city')
        sessionStorage.removeItem('province')
        sessionStorage.removeItem('country')
        sessionStorage.removeItem('longitude')
        sessionStorage.removeItem('latitude')
        sessionStorage.removeItem('isPrimary')
    }


    handleFieldChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    proceedToEmail = () => {
        this.getProductMembership()
        this.getNationality()
        document.getElementById('enter-email').style.display = 'block'
        document.getElementById('premium-card-container').style.display = 'none'
        document.getElementById('apply-membeship-header').style.display = 'none'
    }
    
    clickPay = () => {
        document.getElementById("personaldetailsform").click()
    }
    render() {
        return (
            <div className="landers-membership-container">
                {/* <ParallaxComponent page="generic" /> */}
                <div className="container">
                <div className="landers-member-ship-header-container" id="apply-membeship-header">
                    <div className="row back-button" onClick={() => window.history.back()}>
                        <i class="fa fa-chevron-left"><span>Back</span></i>
                    </div>
                    <h2 className="d-none landers-member-ship-header">APPLY LANDERS MEMBERSHIP</h2>
                </div>
                {/* premiusm card */}
                <div className="d-none landers-membership-body" id="premium-card-container">
                    <div className="landers-memberhip-container-container card">
                        <div className="landers-membership-card-container">
                            <div className="card membership-card">
                                <div className="card inner-membership-card">
                                    <img className="membership-card-logo" src={cardLogo}></img>
                                </div>
                            </div>
                        </div>
                        <h6 className="landers-membership-card-type">Premium Membership Card</h6>
                        <h5 className="landers-membership-card-price"> ₱600.00 </h5>


                        <div className="lander-membership-card-details">
                            <p className="lander-membership-card-details-label">Annual Membership Card Fee:<span>₱800</span></p>
                            <p className="lander-membership-card-details-label">Extension Card Eligibility:<span>One(1) card at ₱400</span></p>
                        </div>

                        <div className="lander-membership-card-benefits">
                            <p className="lander-membership-card-details-label">Benefits</p>
                            <ul className="lander-membership-card-benefits-list">
                                <li>
                                   <span>Access to a wide selection of top-notch local and international branded goods</span>
                                </li>
                                <li>
                                    <span>Participation in Super Crazy Sale, Super Fuel Sale and other promotional activities</span>
                                </li>
                                <li>
                                    <span>Free haircut or shampoo and blow-dry services at Federal Barbers</span>
                                </li>
                                <li>
                                    <span>Member discount at Capital Care Pharmacy</span>
                                </li>
                                <li>
                                    <span>Excellent food and drink choices from Landers Central, Doppio, Dough & Co.</span>
                                </li>
                                <li>
                                    <span>Fast and easy shopping on our line store</span>
                                </li>
                            </ul>
                        </div>

                        <div className="landers-membership-card-btn-container">
                            <button className="btn btn-block srn-crm-proceed" onClick={() => this.proceedToEmail()}>
                                APPLY FOR PREMIUM MEMBERSHIP
                            </button>
                        </div>

                        {/* <div className="landers-membership-card-btn-container">
                            <button className="btn btn-block srn-crm-proceed" onClick={() => this.getBranchCode()}>
                                GET BRANCH
                            </button>
                        </div>


                        <div className="landers-membership-card-btn-container">
                            <button className="btn btn-block srn-crm-proceed" onClick={() => this.addToCart()}>
                                add
                            </button>
                        </div>

                        <div className="landers-membership-card-btn-container">
                            <button className="btn btn-block srn-crm-proceed" onClick={() => this.getProductMembership()}>
                                GetProductMembership
                            </button>
                        </div>

                        <div className="landers-membership-card-btn-container">
                            <button className="btn btn-block srn-crm-proceed" onClick={() => this.createBulk()}>
                                CreateBulk
                            </button>
                        </div> */}
                    </div>
                </div>
                
                {/* enter email form */}
                <div className="d-none landers-membership-body" id="enter-email">
                    <div className="landers-memberhip-container-container card">
                        <h4 className="landers-member-ship-header">ENTER EMAIL ADDRESS</h4>
                        <div className="form-group">
                            <label className="srn-crm-label">Email <span className="srn-required-indicator">*</span></label>
                            <input name="email" type="email" id="email_input" className="form-control srn-crm-form-control" placeholder="Enter email address" onChange={(e) => this.handleFieldChange(e)}/>
                        </div>

                        <div className="landers-membership-card-btn-container">
                            <button className="btn btn-block srn-crm-proceed" onClick={() => this.validateEmail()}>
                                PROCEED
                            </button>
                        </div>
                    </div>
                </div>

                {/* multi-step-form */}
                <div className="d-block landers-membership-body" id="multi-step-form">
                    <div id="srn-multi-step-form-component">
                        <div className="srn-multi-step-form-card-body card-body">
                            <div className="srn-multi-step-form-container">

                                {/* <div className="d-none srn-multi-step-pagination-container">
                                    <div className="srn-multi-step-btn-container">
                                        <a className={`srn-multi-step-btn multi-btn-1 ${this.state.activeFormPage > 1 ? 'done' : ''} ${this.state.activeFormPage == 1 ? 'current' : null}`}>
                                            {this.state.activeFormPage > 1 ? <FontAwesomeIcon icon={faCheck} className="srn-multi-step-form-icon"/> : 1}
                                        </a>
                                        <span className={`srn-multi-step-label  ${this.state.activeFormPage == 1 ? 'current-label' : null }`}> Personal Details</span>
                                    </div>
                                    <div className="srn-multi-step-btn-container">
                                        <a className={`srn-multi-step-btn ${this.state.activeFormPage > 2 ? 'done' : ''} ${this.state.activeFormPage == 2 ? 'current' : null}`}>
                                            {this.state.activeFormPage > 2 ? <FontAwesomeIcon icon={faCheck} className="srn-multi-step-form-icon"/> : 2}
                                        </a>
                                        <span className={`srn-multi-step-label  ${this.state.activeFormPage == 2 ? 'current-label' : null }`}> Address</span>
                                    </div>
                                    <div className="srn-multi-step-btn-container">
                                        <a className={`srn-multi-step-btn ${this.state.activeFormPage > 3 ? 'done' : ''} ${this.state.activeFormPage == 3 ? 'current' : null}`}>3</a>
                                        <span className={`srn-multi-step-label  ${this.state.activeFormPage == 3 ? 'current-label' : null }`}> Checkout</span>
                                    </div>
                                </div> */}
                                <h2 className="landers-member-ship-header">APPLY LANDERS MEMBERSHIP</h2>
                                    <span class="srn-required-indicator mt-4 p-4">* <span className="text-dark">Required Fields</span></span>
                                <div className="landers-membership-body" id="premium-card-container">
                                    <div className="landers-memberhip-container-container card">
                                        <div className="landers-membership-card-container">
                                            <div className="card membership-card">
                                                <div className="card inner-membership-card">
                                                    <img className="membership-card-logo" src={cardLogo}></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="landers-membership-card-price-container">
                                            <span className="landers-membership-card-type">Premium</span>
                                            <span className="landers-membership-card-price">₱700.00</span>
                                        </div>
                                    </div>
                                    <div className="landers-membership-card-btn-container">
                            {/* <button className="btn btn-block srn-crm-proceed" onClick={() => this.createBulk()}>
                                CreateBulk
                            </button> */}
                        </div>
                                </div>
                                {/* Personal-Details-Form */}
                                <div className="d-block srn-multi-step-form-content">
                                    <div className={`d-block srn-multi-step-form-card card`} id="personal-details">
                                        <div className="srn-multi-step-form-card-header card-header">
                                            <h4 className="srn-multi-step-form-header">PERSONAL DETAILS</h4>
                                        </div>
                                        <div className="srn-multi-step-form-card-body card-body">
                                            <form className="srn-multi-step-form" onSubmit={(e) => this.validateForm(e)}>

                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <label htmlFor="" className="srn-crm-label">First Name <span class="srn-required-indicator">*</span></label>
                                                        <input
                                                            name="first_name"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-firstname"
                                                            placeholder="Enter First Name"
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            value={this.state.first_name || ''}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <label htmlFor="" className="srn-crm-label">Last Name <span class="srn-required-indicator">*</span></label>
                                                        <input
                                                            name="last_name"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-lastname"
                                                            placeholder="Enter Last Name"
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            value={this.state.last_name || ''}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <label htmlFor="" className="srn-crm-label">Email <span class="srn-required-indicator">*</span></label>
                                                        <input
                                                            name="email"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-email"
                                                            placeholder="Enter Email"
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            value={this.state.email || ''}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-6">
                                                        <label htmlFor="" className="srn-crm-label">Confirm email <span class="srn-required-indicator">*{this.state.isEmailMatch == false ? "email does not match" : " "}</span></label>
                                                            <input
                                                                name="confirm_email"
                                                                type="text"
                                                                className="form-control srn-crm-form-control srn-crm-email"
                                                                placeholder="Enter Email"
                                                                onChange={(e) => this.handleFieldChange(e)}
                                                                value={this.state.confirm_email || ''}
                                                                required
                                                            />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="" className="srn-crm-label">Address<span class="srn-required-indicator"> *</span></label>
                                                        <input
                                                            name="address"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-email"
                                                            placeholder="Enter Address"
                                                            id="signup-address-input"
                                                            onClick={() => this.openFulfillmentTypeSignUp()}
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            value={this.state.address || sessionStorage.getItem("address")}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-6">
                                                        <label htmlFor="" className="srn-crm-label">Mobile No. <span class="srn-required-indicator">*&nbsp;{this.state.isContactValid === false && "Please enter a valid phone number."}</span></label>
                                                        <div className="input-group srn-crm-input-group">
                                                            <div className="input-group-prepend srn-crm-register-number-prefix">
                                                                <span className="srn-crm-register-number-prefix-span"><i className="flag-container"></i> +63</span>
                                                            </div>
                                                            <input
                                                                name="mobile_no"
                                                                type="number"
                                                                className={`form-control srn-crm-form-control srn-crm-mobile`}
                                                                placeholder="XXXXXXXXXX"
                                                                pattern="[0-9]*"
                                                                defaultValue={this.state.mobile_no || ""}
                                                                onChange={(e) => this.handleFieldChange(e)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <label htmlFor="" className="srn-crm-label">Birthday <span class="srn-required-indicator">*&nbsp;{this.state.isBirthdayValid === false && "Please fill out this field."}</span></label>
                                                        <div className="input-birthday-container">
                                                            <input
                                                                readOnly
                                                                name="birthday"
                                                                type="text"
                                                                className="form-control srn-crm-form-control srn-crm-birthday"
                                                                placeholder="MM / DD / YY"
                                                                onClick={() => this.openCalendar()}
                                                                defaultValue={this.state.selectedDate}
                                                                required
                                                            />
                                                            <div className="input-group-append" onClick={() => this.openCalendar()}>
                                                                <span ><i class="fa fa-calendar"></i></span>
                                                                {
                                                                    this.state.isCalendarOpen != false ?
                                                                    <div className="calendar-image">
                                                                        <Calendar
                                                                        onChange={(e) => this.onCalendarChange(e)}
                                                                        maxDate={moment().subtract(18, 'years')._d}
                                                                        className="srnt-cart-ft-calendar"
                                                                        />
                                                                        <button className="btn btn-dark btn-block srn-crm-btn srn-crm-bg-black srn-crm-register-btn" type="submit" onClick={() => this.closeCalendar()}>
                                                                            SET DATE
                                                                        </button>
                                                                    </div>
                                                                    :null
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-md-6 gender-container">
                                                        <label htmlFor="" className="srn-crm-label">Gender<span class="srn-required-indicator"> *</span></label>
                                                        <select class="form-select srn-crm-register-number-prefix-span text-left" id="gender-select" 
                                                            onChange={(e) => this.handleGender(e.value)} required>
                                                            <option value="" selected disabled>Gender</option>
                                                            {
                                                                this.state.gender.map((gender) => {
                                                                    return (
                                                                        <option value="male" onClick={() => this.handleGender(gender.name)}>{gender.name}</option>
                                                                    )
                                                                })
                                                                
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6" >
                                                        <label htmlFor="" className="srn-crm-label">Nationality<span class="srn-required-indicator"> *</span></label>
                                                        <select class="form-select srn-crm-register-number-prefix-span w-100 text-left" id="nationality-select" 
                                                            onClick={() => this.getNationality()}
                                                            onChange={(e) => this.handleNationality(e.value)} required>
                                                            <option value="" selected disabled>Nationality</option>
                                                            {
                                                                this.state.nationalityList.map((nation) => {
                                                                    return (
                                                                        <option value={nation.name} onClick={() => this.handleNationality(nation.name)}>{nation.name}</option>
                                                                    )
                                                                })
                                                                
                                                            }
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-6 landers-membership-fulfillment">
                                                        <label htmlFor="" className="srn-crm-label">Branch<span class="srn-required-indicator"> *</span></label>
                                                        <select class={`form-select srn-crm-register-number-prefix-span w-100 text-left`}
                                                            onClick={() => this.getBranchCode()}
                                                            onChange={() => this.handleBranch()} id="branch-select">
                                                            <option value="" selected disabled>Branch</option>
                                                            {
                                                                this.state.branches.map((branch) => {
                                                                    return (
                                                                        <option value={branch.code}>{branch.name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                

                                                <div className="form-group">
                                                    <button className="d-none btn btn-dark btn-block srn-crm-proceed" id="personaldetailsform" type="submit" disabled={this.state.isLoading}>
                                                        {this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                                                        NEXT
                                                    </button>
                                                </div>
                                            </form>
                                            
                                            <FulfillmentTypeComponent
                                                access_token={localStorage.getItem("access_token")}
                                                x_app_key={1}
                                                brand_id={0}
                                                product={null}
                                                isPickupDisabled
                                                handleModalClose={this.props.handleModalClose ? this.props.handleModalClose : null}
                                                main={this.props.main}
                                            />
                                        </div>
                                    </div>

                                    {/* Disabled ADDRESS INPUT */}
                                    <div className={`d-none srn-multi-step-form-card card `} id="address">
                                        <div className="srn-multi-step-form-card-header card-header">
                                            <h4 className="srn-multi-step-form-header">ADDRESS</h4>
                                        </div>
                                        <div className="srn-multi-step-form-card-body card-body">
                                            <form className="srn-multi-step-form" onSubmit={(e) => this.validateAddressForm(e)}>
                                                <div className="form-group">
                                                    <label htmlFor="" className="srn-crm-label">State/Province <span class="srn-required-indicator">*</span></label>
                                                    <input
                                                            name="province"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-state"
                                                            placeholder="State/Province"
                                                            required={true}
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            
                                                        />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="" className="srn-crm-label">City/District <span class="srn-required-indicator">*</span></label>
                                                    <input
                                                            name="city"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-city"
                                                            placeholder="City/District"
                                                            required={true}
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            
                                                        />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="" className="srn-crm-label">Barangay <span class="srn-required-indicator">*</span></label>
                                                    <input
                                                            name="barangay"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-barangay"
                                                            placeholder="Barangay"
                                                            required={true}
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            
                                                        />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="" className="srn-crm-label">Street address <span class="srn-required-indicator">*</span></label>
                                                    <input
                                                            name="address"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-street"
                                                            placeholder="Street address"
                                                            required={true}
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            
                                                        />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="" className="srn-crm-label">ZIP code <span class="srn-required-indicator">*</span></label>
                                                    <input
                                                            name="zip_code"
                                                            type="text"
                                                            className="form-control srn-crm-form-control srn-crm-zip-code"
                                                            placeholder="ZIP code"
                                                            required={true}
                                                            onChange={(e) => this.handleFieldChange(e)}
                                                            
                                                        />
                                                </div>

                                                <div className="form-group row">
                                                    <div className="col-md-6">
                                                        <button className="btn btn-dark btn-block srn-crm-proceed" type="button" disabled={this.state.isLoading} onClick={() => this.handlePageForm(1)}>
                                                            {this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                                                            PREVIOUS
                                                        </button>
                                                    </div>
                                                    <div className="col-md-6" onClick={() => this.getBranchCode()}>
                                                        <button className="btn btn-dark btn-block srn-crm-proceed" type="submit" disabled={this.state.isLoading}>
                                                            {this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                                                            NEXT
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                            
                                        </div>
                                    </div>

                                    <div className={`d-block srn-multi-step-form-card card`} id="checkout">
                                        <div className="srn-multi-step-form-card-header card-header card-header-checkout">
                                            <h4 className="srn-multi-step-form-header">Payment</h4>
                                        </div>
                                        <div className="srn-multi-step-form-card-body card-body card-checkout">
                                            <div className="cc-content">
                                            <label className="srn-paymaya-form-label" for="name">Payment method<span class="srn-required-indicator"> *</span></label>
                                                <select class="col-6 form-select landers-membership-payment-select" onChange={() => this.handlePayment()} id="payment-select">
                                                    <option defaultValue="cc">Online Credit Card</option>
                                                    <option value="pm">Paymaya Wallet</option>
                                                </select>
                                                <span
                                                    className={`btn btn-secondary btn-lg btn-block checkout-btn mt-4 w-100 ${this.state.payment_method === 'pm' ? '' : 'd-none'} ${this.state.loadingInfo.length > 0 ? "button-loading" : "PLACE "}`} id="checkout-btn-paymaya" disabled={this.state.isLoading}
                                                    onClick={() => this.clickPay()}
                                                >{this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}{this.state.loadingInfo.length > 0 ? this.state.loadingInfo : "PLACE ORDER"}</span>

                                                    <form onSubmit={(event) => this.handlePaymentFormSubmit(event)} className={`${this.state.payment_method != 'pm' ? 'd-block' : 'hide-cc'} cc-payment-form srn-paymaya-form-container`}>
                                                    <div className={`cmb-cc-container ${this.state.isLoading ? 'cmb-cc-container-loading' : ''}`}>
                                                        <div className="cmb-cc-loading">
                                                            <FontAwesomeIcon icon={faSpinner} spin />
                                                        </div>
                                                        <div className="srn-paymaya-form">
                                                            <div className="row">
                                                                <div className="form-group col-6">
                                                                    <label className="srn-paymaya-form-label" for="name">Name on Card<span class="srn-required-indicator"> *</span></label>
                                                                    <input name="name" type="text"className={`form-control srn-paymaya-form-input ${this.state.nameError?.length > 0 ? 'has-error' : ''}`} id="name" placeholder="Name" onChange={(e) => this.handleFieldChange(e)} />
                                                                        {
                                                                            this.state.nameError?.length > 0 &&
                                                                            <div class="invalid-feedback">
                                                                                {this.state.nameError}
                                                                            </div>
                                                                        }
                                                                </div>
                                                                <div className="form-group col-6">
                                                                    <label className="srn-paymaya-form-label" for="number">Card Number<span class="srn-required-indicator"> *</span></label>
                                                                    <input name="number" type="int" className={`form-control srn-paymaya-form-input ${this.state.numberError?.length > 0 ? 'has-error' : ''}`} id="number" placeholder="Credit Card Number" onChange={(e) => this.handleFieldChange(e)} />
                                                                        {
                                                                            this.state.numberError?.length > 0 &&
                                                                            <div class="invalid-feedback">
                                                                                {this.state.numberError}
                                                                            </div>
                                                                        }
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="form-row">
                                                                <div className="col-6" style={{paddingRight: "20px"}}>
                                                                    <div class="form-row">
                                                                        <div class="col">
                                                                            <div className="form-group">
                                                                                <label className="srn-paymaya-form-label" for="expMonth">Expiry Date<span class="srn-required-indicator"> *</span></label>
                                                                                <select name="expMonth" className={`custom-select srn-paymaya-form-input ${this.state.expMonthError?.length > 0 ? 'has-error' : ''}`} onChange={(e) => this.handleFieldChange(e)}>
                                                                                    <option selected value="">MM</option>
                                                                                    {
                                                                                        this.state.months.map(month => (
                                                                                            <option value={month}>{month}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                                {
                                                                                    this.state.expMonthError?.length > 0 &&
                                                                                    <div class="invalid-feedback">
                                                                                        {this.state.expMonthError}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div class="col">
                                                                            <div className="form-group">
                                                                                <label className="srn-paymaya-form-label" for="expYear">&nbsp;</label>
                                                                                <select name="expYear" className={`custom-select srn-paymaya-form-input ${this.state.expYearError?.length > 0 ? 'has-error' : ''}`} onChange={(e) => this.handleFieldChange(e)}>
                                                                                    <option selected value="">YYYY</option>
                                                                                    {
                                                                                        generateYearsArray()?.map(year => (
                                                                                            <option value={year}>{year}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                                {
                                                                                    this.state.expYearError?.length > 0 &&
                                                                                    <div class="invalid-feedback">
                                                                                        {this.state.expYearError}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6" style={{paddingLeft: "20px"}}>
                                                                    <div class="form-group">
                                                                        <label className="srn-paymaya-form-label" for="cvc">CVC<span class="srn-required-indicator"> *</span></label>
                                                                        <input name="cvc" type="text" max="999" className={`form-control srn-paymaya-form-input ${this.state.cvcError?.length > 0 ? 'has-error' : ''}`} id="cvc" placeholder="CVV" onChange={(e) => this.handleFieldChange(e)} />
                                                                        {
                                                                            this.state.cvcError?.length > 0 &&
                                                                            <div class="invalid-feedback">
                                                                                {this.state.cvcError}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <button type="submit" class="d-none btn btn-primary checkout-btn srn-paymaya-submit-btn"  id="confirmandpay">
                                                        Confirm and Pay
                                                    </button>
                                                    <span
                                                        className={`btn btn-secondary btn-lg btn-block checkout-btn mt-4 w-100 ${this.state.loadingInfo.length > 0 ? "button-loading" : "PLACE "}`} id="checkout-btn-paymaya" disabled={this.state.isLoading}
                                                        onClick={() => this.clickPay()}
                                                    >{this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}{this.state.loadingInfo.length > 0 ? this.state.loadingInfo : "PLACE ORDER"}</span>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                </div>
            </div>
            
        )
    }
}