import $ from 'jquery'
// import axios from 'axios'

// let IDMS_URL = process.env.REACT_APP_IDMS_URL
let MEMBERSHIP_URL = process.env.REACT_APP_MEMBERSHIP_URL
let ACCOUNT_KEY = process.env.REACT_APP_MEMBERSHIP_ACCOUNT_KEY
let APPLY_LANDERS = process.env.REACT_APP_MEMBERSHIP_URL
let ADD_MEMBERSHIP_CART = process.env.REACT_APP_CART_SERVICE_URL
let GET_PRODUCT_MEMBERSHIP = process.env.REACT_APP_LAYER_URL
let CREATE_BULK = process.env.REACT_APP_SERINO_API

export default class RewadAPI {

    linkRewardCard(access_token, payload) {
        return $.ajax({
            url: MEMBERSHIP_URL + `/public/membership/link`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": ACCOUNT_KEY
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    linkMembership(payload, access_token) {
        return $.ajax({
            url: MEMBERSHIP_URL + `/public/membership/link-v2`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": ACCOUNT_KEY
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    ApplyLanders(payload, access_token) {
        return $.ajax({
            url: APPLY_LANDERS + `/public/membership/add-member`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": ACCOUNT_KEY
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    ValidateApplyEmail(email, access_token) {
        return $.ajax({
            url: APPLY_LANDERS + `/public/membership/validate/${email}`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": ACCOUNT_KEY
            },
        })
    }

    ValidateMembership(payload, access_token) {
        return $.ajax({
            url: APPLY_LANDERS + `/public/membership/validate-member`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": ACCOUNT_KEY
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    GetProductMembership(access_token) {
        return $.ajax({
            url: GET_PRODUCT_MEMBERSHIP + `/products/inventory-transactions?perPage=12&product_reference_type=sku&product_reference[]=NEW_PREMIUM_CARD&application_id=1`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
        })
    }

    GetNationality(access_token) {
        return $.ajax({
            url: APPLY_LANDERS + `/public/membership/nationalities`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
        })
    }

    GetBranch(access_token) {
        return $.ajax({
            url: APPLY_LANDERS + `/public/membership/branches`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
        })
    }

    AddMembershipCart(payload, access_token) {
        return $.ajax({
            url: ADD_MEMBERSHIP_CART + `/cart`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    CreateBulk(payload, access_token) {
        return $.ajax({
            url: CREATE_BULK + `/orders/bulkCreate`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    UpdateTransactionId(payload, access_token) {
        return $.ajax({
            url: MEMBERSHIP_URL + `/public/membership/update-transaction-id`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    UpdateTransactionIdPaid(transactionId, access_token) {
        return $.ajax({
            url: MEMBERSHIP_URL + `/public/api/membership/setPaid?status=S&transactionId=${transactionId}`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
            contentType: 'application/json',
        })
    }
} 