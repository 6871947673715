import React, { Component } from "react"
import $ from 'jquery'
import LocationPicker from '../partials/LocationPicker'
import {
    getAccessToken,
    triggerSerinoEvent,
    log,
    getSwalContent,
    getCrossCart,
    handleExpiredToken,
    handleLandersBrandId,
    mainAddressHandler,
    genericAddCartItem,
    getFilteredProductsByStock,
    getMaxOnlinePaymentAmountObject,
    getBrandsWithDisabledCash,
    setSessionStorage,
} from '../../helper/helper'
import { fbqEvent } from '../../helper/fbpixel'
import { addToCartAnalytics } from '../../helper/analytics'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// import marketLogo from '../../assets/images/central_market_checkout.png'
import eatsLogo from '../../assets/images/central_gold.png'
import landersLogo from '../../assets/images/landers_membership_logo.png'
import Cart from '../../_core/cart'
import ProductList from "../../_core/products";

import prodDescMarketLogo from '../../assets/images/market-footer2.png'
import {EntryPoints} from './main/EntryPoints'
import CoreAPI from '../../_core/core'


let market_app_id = process.env.REACT_APP_MARKET_APP_ID
let eats_app_id = process.env.REACT_APP_EATS_APP_ID
// let counttt = 0
let swalCounter = 0
let coreAPI = new CoreAPI()
let fbqEventCounter = 0

export default class CartComponent extends Component {
    constructor() {
        super()
        this.state = {
            apps: [],
            cartsBelowMinimum: [],
            cartsAboveMaximum: [],
            cartQuantity: 'null',
            eatsCart: {},
            marketCart: {},
            branch_id: '',
            current_brand_id: 1,
            appkey: '',
            access_token: '',
            isCashEnabled: false,
            isSearchActive: false,
            app_key: 1,
            appsJson: []
        }
    }

    async componentDidMount() {

        this.getDataFromJSON()
        
        let locationPicker = document.getElementById('srn-header-location-picker')
        let locationPicker2 = document.getElementById('srn-header-location-picker-2')

        if (locationPicker) {
            locationPicker.style.display = 'none'
        }
        if (locationPicker2) {
            locationPicker2.style.display = 'none'
        }


        localStorage.setItem('domain', 'main')

        // if (!localStorage.getItem('SRN-CART-V2-SELECTED-ITEM-IDS')) {
            let items = await getCrossCart(localStorage.getItem('access_token'))
            if (items && items.length > 0) {
              let ids = items.map((x) => x.id);
              if (ids && ids.length > 0) {
                localStorage.setItem(
                  'SRN-CART-V2-SELECTED-ITEM-IDS',
                  JSON.stringify(ids)
                );
              }
            }
        // }
        // Remove old option id default
        if (!localStorage.getItem('remove-default-selected-order-option-id')) {
            localStorage.removeItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID')
            localStorage.setItem('remove-default-selected-order-option-id', '1')
        }
        if (!localStorage.getItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID')) {
            localStorage.setItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID', '')
        }
        
        getAccessToken().then(async access_token => {
            this.setState({access_token})
            let cartSummarySubscriptions = window.serino_components.cartSummary.subscriptions
            let cartTotalSubscriptions = window.serino_components.cartTotal.subscriptions
            let cartTotalEvents = window.serino_components.cartTotal.events
            let cartEvents = window.serino_components.cart.events
            // console.log('ADDRESS::', localStorage.getItem('address_object'))
            document.addEventListener(cartEvents.onTokenExpire('marketCart'), (event) =>  {
                handleExpiredToken()
            })

            document.addEventListener(cartEvents.onLoad('marketCart'), (event) => {
                this.updateSummaryItems(event, 'marketSummary', 'srn-market-summary-container')
                triggerSerinoEvent(cartTotalSubscriptions.updateCartItems('cartTotal'), this.state.apps)
            })

            document.addEventListener(cartEvents.onCartUpdated('marketCart'), (event) => {
                this.updateSummaryItems(event, 'marketSummary', 'srn-market-summary-container')
                triggerSerinoEvent(cartTotalSubscriptions.updateCartItems('cartTotal'), this.state.apps)
            })

            document.addEventListener(cartEvents.onLoad('eatsCart'), (event) =>  {
                this.updateSummaryItems(event, 'eatsSummary', 'srn-eats-summary-container')
                triggerSerinoEvent(cartTotalSubscriptions.updateCartItems('cartTotal'), this.state.apps)
            })

            document.addEventListener(cartEvents.onCartUpdated('eatsCart'), (event) =>  {
                this.updateSummaryItems(event, 'eatsSummary', 'srn-eats-summary-container')
                triggerSerinoEvent(cartTotalSubscriptions.updateCartItems('cartTotal'), this.state.apps)
            })
            document.addEventListener(cartEvents.onMultipleDeleteEmpty('marketCart'), (event) => {
                Swal.fire({
                    html: `<div class='col-md-12'>
                        <h6 class='cmb-title-3 my-3 text-center'>Please select item/s to delete.</h6>
                        </div>`,
                    customClass: {
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                        cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                    }
                })
            })
            document.addEventListener(cartEvents.onMultipleDeleteEmpty('eatsCart'), (event) => {
                Swal.fire({
                    html: `<div class='col-md-12'>
                        <h6 class='cmb-title-3 my-3 text-center'>Please select item/s to delete.</h6>
                        </div>`,
                    customClass: {
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                        cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                    }
                })
            })
            // let marketSummaryComponent = window.serino_components.cartSummary.create({
            //     id: 'marketSummary',
            //     // isPromoOn: true,
            //     // mode: 'edit'
            // }, 'srn-market-summary-container')

            // let eatsSummaryComponent = window.serino_components.cartSummary.create({
            //     id: 'eatsSummary',
            //     // isPromoOn: true,
            //     // mode: 'edit'
            //     showBrand: true
            // }, 'srn-eats-summary-container')

            let cartTotalComponent = window.serino_components.cartTotal.create({
                access_token: access_token,
                id: 'cartTotal',
                logo: <img src={landersLogo} className="srn-landers-logo" />
            }, 'srn-cart-total-container')

            document.addEventListener(cartTotalEvents.onMembershipLink('cartTotal'), (event) =>  {
                window.location = '/membership'
            })

            document.addEventListener(cartTotalEvents.onCartBelowMinimum('cartTotal'), (event) =>  {

                let cartEvents = window.serino_components.cart.subscriptions

                const triggerBelowMinimumEvent = (eventId, detail) => {
                    let event = new CustomEvent(cartEvents.minMaxError(eventId), {
                        detail: detail,
                        bubbles: true,
                        composed: true
                    })
                    document.dispatchEvent(event);
                }

                switch(event.detail.app) {
                    case(market_app_id):
                        triggerBelowMinimumEvent('marketCart', event.detail)
                        break;
                    case(eats_app_id):
                        triggerBelowMinimumEvent('eatsCart', event.detail)
                        break;
                    default:
                        break;
                }
                // console.log(event.detail)

                let cartsBelowMinimum = this.state.cartsBelowMinimum.concat(event.detail.carts)
                // log(event.detail)
                this.setState({cartsBelowMinimum: event.detail.carts})

                $('#srn-min-modal').modal('show')
            })
            document.addEventListener(cartTotalEvents.onCartAboveMaximum('cartTotal'), (event) =>  {
                this.setState({
                    cartsAboveMaximum: event.detail.carts
                })
                $('#srn-max-modal').modal('show')
            })

            document.addEventListener(cartTotalEvents.onSoldoutDelete, (event) =>  {
                triggerSerinoEvent(window.serino_components.cart.subscriptions.refreshCart('eatsCart'))
                triggerSerinoEvent(window.serino_components.cart.subscriptions.refreshCart('marketCart'))
            })
            document.addEventListener(cartTotalEvents.onCartsEmptyOnCheckout('cartTotal'), (event) =>  {
                Swal.fire({
                    html: getSwalContent('Your cart is empty'),
                    customClass: {
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                    }
                }).then(res => {
                    swalCounter = 0
                })
            })

            document.addEventListener(cartTotalEvents.onCartCheckoutSuccess('cartTotal'), (event) =>  {
                let isMarketNotEmpty = false
                let marketCart = this.state.apps.find(x => x.app.id == market_app_id)
                let marketSelecteditems = marketCart.cart.length > 0 ? marketCart.cart[0]['cart_items'].filter(x => x.selected) : []

                if ((!localStorage.getItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID') || localStorage.getItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID') == ' ') && marketSelecteditems.length > 0) {
                    if (swalCounter == 1) {
                        return
                    }
                    swalCounter++

                    Swal.fire({
                        html: getSwalContent('Please select action to proceed'),
                        customClass: {
                            confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                        }
                    }).then(res => {
                        swalCounter = 0
                    })
                }
                else {
                    if (fbqEventCounter === 0) {
                        fbqEvent('InitiateCheckout')
                        fbqEventCounter++
                    }
                    this.props.history.push('/checkout')
                    // window.location = '/checkout'
                }

            })

            let marketCart = window.serino_components.cart.create({
                access_token: access_token,
                app_key: process.env.REACT_APP_MARKET_APP_ID,
                id: 'marketCart',
                mode: 'edit',
                multiSelect: true,
                enableDelete: true,
                showItemImage: true,
                isPromoOn: true,
                isOrderOptionsEnabled: true,
                showSimilarItems: true,
                similar_items_props: {
                    title: `Similar Products`,
                    interval: 8000,
                    currency: '₱'
                },
                address: localStorage.getItem(`address_object_${market_app_id}`) ? localStorage.getItem(`address_object_${market_app_id}`) : '',
            }, 'srn-cart-market-container')

            let eatsCart = window.serino_components.cart.create({
                access_token: access_token,
                app_key: process.env.REACT_APP_EATS_APP_ID,
                id: 'eatsCart',
                mode: 'edit',
                multiSelect: true,
                enableDelete: true,
                showItemImage: true,
                isPromoOn: true,
                address: localStorage.getItem(`address_object_${eats_app_id}`) ? localStorage.getItem(`address_object_${eats_app_id}`) : '',
                showBrand: true,
                showSimilarItems: true,
                similar_items_props: {
                    title: 'Best Partnered With',
                    interval: 8000,
                    currency: '₱'
                },
            }, 'srn-cart-eats-container')


            document.addEventListener(cartEvents.onChangeAddress('eatsCart'), async (event) =>  {                
                let clearCartConfirmation = await Swal.fire({
                    showCloseButton: true,
                    title: "Confirm Changes",
                    html: getSwalContent('Your cart will reset if you switch to a different fulfillment method'),
                    confirmButtonText: 'CONFIRM',
                    showCancelButton: true,
                    cancelButtonText: 'CANCEL',
                    customClass: {
                        title: "cart-swal-title",
                        html: "cart-swal-html",
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                        cancelButton: 'cmb-btn-primary-swal-checkoutcancel'      
                    }
                })

                if (clearCartConfirmation.isDismissed) {
                    return
                }
                // this.clearCart(localStorage.getItem('access_token'), eats_app_id)
                // console.log('onChangeAddress', event.detail.brand.id)
                localStorage.setItem('brand_id', event.detail.brand.id)
                localStorage.setItem('branch_id', event.detail.brand.branch_id)
                this.setState({current_brand_id: event.detail.brand.id})
                let addresses = localStorage.getItem(`address_object_${eats_app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${eats_app_id}`)) : []
                let address = addresses.find(x => x.brand_id == event.detail.brand.id)
                log({address})

                if (address) {
                    this.handleAddressChange(address, 'brand')
                }

                this.setState({ app_key: 2}, () => {
                    $('#fulfillmenttype_modal').modal('show')
                })
            })

            document.addEventListener(cartEvents.onChangeAddress('marketCart'), async (event) =>  {                
                let clearCartConfirmation = await Swal.fire({
                    showCloseButton: true,
                    title: "Confirm Changes",
                    html: getSwalContent('Your cart will reset if you switch to a different fulfillment method'),
                    confirmButtonText: 'CONFIRM',
                    showCancelButton: true,
                    cancelButtonText: 'CANCEL',
                    customClass: {
                        container: "cart-swal-container",
                        title: "cart-swal-title",
                        html: "cart-swal-html",
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                        cancelButton: 'cmb-btn-primary-swal-checkoutcancel'      
                    }
                })

                if (clearCartConfirmation.isDismissed) {
                    return
                }

                // this.clearCart(localStorage.getItem('access_token'), market_app_id)
                // console.log('onChangeAddress', event.detail.brand.id)
                localStorage.setItem('brand_id', event.detail.brand.id)
                localStorage.setItem('branch_id', event.detail.brand.branch_id)
                this.setState({ current_brand_id: event.detail.brand.id})

                let addresses = localStorage.getItem(`address_object_${market_app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_id}`)) : []
                let address = addresses.find(x => x.brand_id == event.detail.brand.id)
                log({address})

                if (address) {
                    this.handleAddressChange(address, 'brand')
                }
                
                this.setState({ app_key: 1}, () => {
                    $('#fulfillmenttype_modal').modal('show')
                    // console.log("onchangeaddress",this.state.x_app_key)
                })
            })

            document.addEventListener(cartEvents.onOrderOptionSelect('marketCart'), (event) =>  {                        
                localStorage.setItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID', event.detail.id)
                localStorage.setItem('selected_order_option_text', event.detail.value)
            })
            
            document.addEventListener(cartEvents.onCartIncrement('eatsCart'), (event) =>  {
                addToCartAnalytics()
            })

            this.setState({
                marketCart,
                eatsCart,
                access_token
            })

            this.addCouponEventListeners()
            this.addCartEventListeners()
            this.addSearchEvents()

        });

    }

    handleSuccessCheckout = async () => {

        // Commented this out until the rules are clear
        // Validate maximum amount of brands in cart
        // const maxOnlineAmt = getMaxOnlinePaymentAmountObject(this.state.apps)
        // if (Number(this.state.grandTotal) > Number(maxOnlineAmt?.max_online_payment_amount)) {
        //     $('#srn-max-modal').modal('show')
        //     return;
        // }

        let marketCart = this.state.apps.find(x => x.app.id == market_app_id)
        let marketSelecteditems = marketCart.cart.length > 0 ? marketCart.cart[0]['cart_items'].filter(x => x.selected) : []

        const optionId = localStorage.getItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID')

        if ((!optionId || optionId === ' ') && marketSelecteditems.length > 0) {
            if (swalCounter === 1) {
                return
            }
            swalCounter++

            Swal.fire({
                html: getSwalContent('Please select action to proceed'),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            }).then(res => {
                swalCounter = 0
            })
        }
        else {
            if (fbqEventCounter === 0) {
                fbqEvent('InitiateCheckout')
                fbqEventCounter++
            }
            this.props.history.push('/checkout')
        }
    }

    getDataFromJSON = async () => {
        let brands = []
        let appsJson = []

        try {
            const dataFromJSON = await coreAPI.getDataFromJSON()

            if (!dataFromJSON) {
                return
            }

            if (dataFromJSON.data && dataFromJSON.data.brands) {
                brands = dataFromJSON.data.brands
            }
            if (dataFromJSON.data && dataFromJSON.data.apps) {
                appsJson = dataFromJSON.data.apps
            }
            this.setState({brands, appsJson})
        }
        catch(getDataErr) {
            log(getDataErr)
        }
    }

    clearCart = (access_token, appId) => {
        let cart = new Cart()
        cart.clearCart(access_token, appId)
    }

    addCartEventListeners = () => {
        let cartEvents = window.serino_components.cart.events
        
        document.addEventListener(cartEvents.onAddressError('eatsCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            })
        })

        document.addEventListener(cartEvents.onAddressError('marketCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            })
        })

        let cartTotalEvents = window.serino_components.cartTotal.events

        document.addEventListener(cartTotalEvents.onGetGrandTotal('cartTotal'), (event) =>  {
            this.setState({grandTotal: event.detail}, () => {
                this.getFulfillmentDetails()
            })
        })
    }

    addCouponEventListeners = () => {
        let cartEvents = window.serino_components.cart.events
        
        document.addEventListener(cartEvents.onCouponEmpty('marketCart'), (event) => {
            Swal.fire({
                showCloseButton: true,
                title: "Invalid Voucher",
                html: getSwalContent('Please Enter Code'),
                customClass: {
                    confirmButton: 'voucher-confirm'
                }
            })
        })

        document.addEventListener(cartEvents.onErrorPromoValidation('marketCart'), (event) => {
            Swal.fire({
                showCloseButton: true,
                title: "Invalid Voucher",
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'voucher-confirm'
                }
            })
        })

        document.addEventListener(cartEvents.onCartBelowMinimum('marketCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            })
        })
        document.addEventListener(cartEvents.onCartEmpty('marketCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'voucher-confirm'
                }
            })
        })
        
        document.addEventListener(cartEvents.onPromoUseMultiple('marketCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'voucher-confirm'
                }
            })
        })

        // EATS PROMO EVENTS

        document.addEventListener(cartEvents.onCouponEmpty('eatsCart'), (event) => {
            Swal.fire({
                showCloseButton: true,
                title: "Invalid Voucher",
                html: getSwalContent('Please Enter Code'),
                customClass: {
                    confirmButton: 'voucher-confirm'
                }
            })
        })

        document.addEventListener(cartEvents.onErrorPromoValidation('eatsCart'), (event) => {
            Swal.fire({
                showCloseButton: true,
                title: "Invalid Voucher",
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'voucher-confirm'
                }
            })
        })

        document.addEventListener(cartEvents.onCartBelowMinimum('eatsCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            })
        })

        document.addEventListener(cartEvents.onCartEmpty('eatsCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            })
        })

        document.addEventListener(cartEvents.onPromoUseMultiple('eatsCart'), (event) => {
            Swal.fire({
                html: getSwalContent(event.detail.message ? event.detail.message : event.detail.error && event.detail.error.message ? event.detail.error.message : 'Invalid Coupon' ),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            })
        })
    }

    updateSummaryItems = (event) => {
        // let cartSummarySubscriptions = window.serino_components.cartSummary.subscriptions

        let cart = event.detail.cart


        let apps = this.state.apps

        let app = {
            cart: cart,
            app: event.detail.app
        }

        if (apps.filter(e => e.app.id == app.app.id).length > 0) {
            let appIndex = apps.findIndex(e => e.app.id == app.app.id)
            apps[appIndex] = app
            // console.log(apps[appIndex]['cart'].length)
            this.setState({
                apps,
                cartQuantity: apps.length > 1 ? this.getCartQuantity(apps) : 'null',
                product: {}
            }, () => {
                this.updateCartCount(apps)
                localStorage.setItem('SRN-CURRENT-APPS', JSON.stringify(apps))
                if (!this.getCartQuantity(apps)) {                    
                    $('.srn-cart-quantity').text('0')
                }
                localStorage.setItem('appCount', apps.length)
            })
        }
        else {
            apps.push(app)
            this.setState({
                apps,
                cartQuantity: apps.length > 1 ? this.getCartQuantity(apps) : 'null',
                product: {}
            }, () => {
                localStorage.setItem('SRN-CURRENT-APPS', JSON.stringify(apps))
                this.updateCartCount(apps)
                sessionStorage.setItem('appCount', apps.length)
                // log('updateSummaryItems', this.getCartQuantity(apps))
            })
        }

        if (app.app.id == market_app_id) {
            log('apps', apps)
            this.handleUnavailableitemsMarket(app)
        }


        // triggerSerinoEvent(cartSummarySubscriptions.updateCartItems(summaryId), app)

    }

    updateCartCount = (apps) => {

        setTimeout(() => {

            let count = apps.map(app => app.cart).length
            let eats_cart = []
            let market_cart = []

            let carts = apps.map(app => app.cart)
            
            let cart_items = []

            if (carts && carts.length > 0) {
                carts.map(cart => {
                    if (cart) {
                        cart.map(x => {
                            x.cart_items.map(y => {
                                cart_items.push(y)                                
                                if (x.brand.id == 1) {
                                    market_cart.push(y)
                                }
                                else {
                                    eats_cart.push(y)
                                }
                            })
                            
                            // localStorage.setItem(`${x.brand.id == 1 ? 'market' : 'eats'}_cart_count`, cart_items.length)
                        })
                    }
                })
            }

            
            let cartCount = 0
            
            if (cart_items.length > 0) {
                log('market cart', market_cart && market_cart.length > 0 ? market_cart.reduce((a, b) => +a + +b.quantity, 0) : 0)
                log('eats cart', eats_cart && eats_cart.length > 0 ? eats_cart.reduce((a, b) => +a + +b.quantity, 0) : 0)
                cartCount = cart_items.reduce((a, b) => +a + +b.quantity, 0)
            }

            localStorage.setItem('eats_cart_count', eats_cart && eats_cart.length > 0 ? eats_cart.reduce((a, b) => +a + +b.quantity, 0) : 0)
            localStorage.setItem('market_cart_count', market_cart && market_cart.length > 0 ? market_cart.reduce((a, b) => +a + +b.quantity, 0) : 0)

            triggerSerinoEvent(window.serino_components.SearchProductListComponent.subscriptions.onCartItems, cart_items)

            if (cartCount > 0) {
                log('cartCount', cartCount)
                $('.srn-cart-quantity').text(cartCount)
            }
        }, 500)
    }

    getCartQuantity = (apps) => {        
        let count = 0
        let cart_items = apps?.map((item) => item.cart?.reduce((a, b) => +a + +b.cart_items.length, 0))
        if (cart_items && cart_items.length > 0 ) {            
            count = cart_items.reduce((a, b) => a + b, 0)
        }
        return count
    }

    addToCart = (product, cart_id) => {
        console.log(product)
        let payload = {
            "itemReferenceId": product.id,
            "itemReferenceType": product.type,
            "itemName": product.name,
            "itemSKU": product.sku,
            "itemImage": product.images,
            "itemDescription": product.description,
            "quantity": product.quantity && product.quantity.length > 0 ? product.quantity : 1,
            "basePrice": product.price,
            "price": product.price,
            "instructions": "",
            "domainReferenceId": localStorage.getItem('branch_id'),
            "domainReferenceType": "1",
            "include_promo": true
        }
        triggerSerinoEvent(window.serino_components.cart.subscriptions.addNewCartItem(cart_id), payload)
    }

    getAddressObject = (address_object) => {
        this.setState({address_object})
    }

    handleLocationChange = (address_object) => {
        log('address_object', address_object)
        this.setState({address_object})
        let id = address_object.app_id

        let cartSubs = window.serino_components.cart.subscriptions
        triggerSerinoEvent(cartSubs.deletePromo)

        if (this.state.product && this.state.product.id) {
            genericAddCartItem(this.state.product, this.handleMainAddress, this.handleRefreshCart)
        }
        else {
            setTimeout(() => {
                window.location.reload()
            }, 500)
        }

        
    }

    getAppCartQuantity = (app_id) => {
        let apps = this.state.apps
        let cart_items = apps.filter(x => x.app.id == app_id).map((item) => item.cart?.reduce((a, b) => +a + +b.cart_items.length, 0))
        // log('getAppCartQuantity', apps.filter(x => x.app.id == app_id))
        if (cart_items && cart_items.length > 0) {
            return cart_items.reduce((a, b) => a + b, 0)
        }
        else {
            return 0
        }
    }

    // componentDidUnMount () {

    //     let cartEvents = window.serino_components.cart.events
    //     let cartTotalEvents = window.serino_components.cartTotal.events
    //     document.removeEventListener(cartEvents.onLoad)
    //     document.removeEventListener(cartEvents.onCartUpdated)
    //     document.removeEventListener(cartEvents.onLoad)
    //     document.removeEventListener(cartEvents.onCartUpdated)
    //     document.removeEventListener(cartEvents.onMultipleDeleteEmpty)
    //     document.removeEventListener(cartEvents.onMultipleDeleteEmpty)
    //     document.removeEventListener(cartTotalEvents.onMembershipLink)
    //     document.removeEventListener(cartTotalEvents.onCartBelowMinimum)
    //     document.removeEventListener(cartTotalEvents.onCartAboveMaximum)
    //     document.removeEventListener(cartTotalEvents.onCartCheckoutSuccess)
    //     document.removeEventListener(cartEvents.onChangeAddress)
    //     document.removeEventListener(cartEvents.onChangeAddress)
    //     document.removeEventListener(cartEvents.onOrderOptionSelect)
    //     document.removeEventListener(cartEvents.onCartIncrement)
    //     document.removeEventListener(cartEvents.onAddressError)
    //     document.removeEventListener(cartEvents.onAddressError)
    //     document.removeEventListener(cartEvents.onCouponEmpty)
    //     document.removeEventListener(cartEvents.onErrorPromoValidation)
    //     document.removeEventListener(cartEvents.onCartBelowMinimum)
    //     document.removeEventListener(cartEvents.onCartEmpty)
    //     document.removeEventListener(cartEvents.onPromoUseMultiple)
    //     document.removeEventListener(cartEvents.onCouponEmpty)
    //     document.removeEventListener(cartEvents.onErrorPromoValidation)
    //     document.removeEventListener(cartEvents.onCartBelowMinimum)
    //     document.removeEventListener(cartEvents.onCartEmpty)
    //     document.removeEventListener(cartEvents.onPromoUseMultiple)

    // }
    getFulfillmentDetails = () => {
        let carts = this.state.apps.map(app => app.cart)

        let cart_items = []
        carts.map(cart => {
            cart.map(item => {
                cart_items.push(item)
            })
        })

        const brands = this.state.brands;

        const brandWithSmallestCashMax = getMaxOnlinePaymentAmountObject(this.state.apps, brands, 'cash');

        let maxCashAmt = brandWithSmallestCashMax?.max_cash_amount ?? 3000;

        let fulfillments = cart_items.map((x) => {
            if (x && x.fulfillment) {
                return x.fulfillment.fulfillmentType_status
            }
        });

        const handleCashDisable = getBrandsWithDisabledCash(this.state.apps, brands)
        let disableCash = handleCashDisable?.length > 0

        const isCashEnabled = disableCash ? false : (fulfillments.filter(x => x == 2).length < 1) && this.state.grandTotal < maxCashAmt && this.state.grandTotal != 0;

        /** Preemptively disables/enables cash MOP before going to checkout */
        setSessionStorage('isCashEnabled', isCashEnabled)
    }

    handleClickEvent = (brand) => {
        console.log(brand)
        var str = brand.name;
        str = str.replace(/\s+/g, "-").toLowerCase();
        localStorage.setItem("brand_logo", brand.logo);
        localStorage.setItem("brand_id", brand.id);
    
        localStorage.removeItem("branch_id");
        localStorage.removeItem("Address");
        localStorage.removeItem("brand_name");
        localStorage.removeItem("brand_best_seller");
        localStorage.removeItem("fulfillment_type_status");
        localStorage.setItem("brand_name", str + "-brand");
        localStorage.setItem("brand_name_str", brand.name);
        localStorage.setItem("brand_best_seller", brand.name.toLowerCase());
        localStorage.setItem("brand_id", brand.id.toString());
        sessionStorage.setItem("viewAction", "brandSelect");
        localStorage.setItem("brandN", str);
        sessionStorage.removeItem("foodType");
        sessionStorage.removeItem("viewAllAction");
        sessionStorage.removeItem("searchKeyWord");
        setTimeout(() => {
          // this.props.history.push(`/eats-products?${str}`);
          window.location = `/eats-products?${str}`
        }, 300);
    }

    addSearchEvents = () => {

        let searchComponentEvent = window.serino_components.SearchProductListComponent.events
        let cartEvents = window.serino_components.cart.subscriptions
        
        document.addEventListener(searchComponentEvent.onSelect, (e) => {
            if (e.detail && e.detail.product && e.detail.product.application_id) {
                this.setState({
                    app_key: e.detail.product.application_id
                }, () => {
                    this.productDescription(e.detail.product, this.state.app_key)
                })      
            }
        })

        document.addEventListener(searchComponentEvent.onAddToCart, (e) => {
            setTimeout(() => {
                this.handleSearchDisplay(true)
            }, 50)
            this.setState({
                isSearchActive: true
            })
            let brand_id = handleLandersBrandId(e.detail.brand_id, e.detail.application_id)
            localStorage.setItem('brand_id', brand_id)

            this.setState({product: e.detail, app_key: e.detail.application_id}, () => {
                if (e.detail.application_id == market_app_id) {
                    genericAddCartItem(this.state.product, this.handleMainAddress, this.handleRefreshCart)
                }
                else {
                    genericAddCartItem(this.state.product, this.handleMainAddress, this.handleRefreshCart)
                }
            })
        })

        document.addEventListener(searchComponentEvent.updateQuantity, (e) => {
            this.setState({
                isSearchActive: true
            })
            let app_key = e.detail.detail.app_key
            let cart_item = e.detail.detail.item
            triggerSerinoEvent(cartEvents.updateCartQuantity(app_key == market_app_id ? 'marketCart' : 'eatsCart'), cart_item)
        })

        document.addEventListener(searchComponentEvent.onUpdateIncrement, (e) => {
            this.setState({ isSearchActive: true })
            let app_key = e.detail.app_key
            let cart_item = e.detail.payload.item
            triggerSerinoEvent(cartEvents.updateCartQuantity(app_key == market_app_id ? 'marketCart' : 'eatsCart'), cart_item)
        })

        document.addEventListener(searchComponentEvent.onUpdateDecrement, (e) =>{
            this.setState({ isSearchActive: true })
            let app_key = e.detail.app_key
            let cart_item = e.detail.payload.item
            triggerSerinoEvent(cartEvents.updateCartQuantity(app_key == market_app_id ? 'marketCart' : 'eatsCart'), cart_item)
        })

        document.addEventListener(searchComponentEvent.onDeleteItem, (e) => {
            setTimeout(() => {
                this.handleSearchDisplay(true)
            }, 50)
            let app_key = e.detail.detail.app_key
            triggerSerinoEvent(cartEvents.deleteCartItem(app_key == market_app_id ? 'marketCart' : 'eatsCart'), e.detail.detail.cart_item)
        })

        $('#descriptionModal').on('hidden.bs.modal', () => {
            setTimeout(() => {
                if (this.state.isSearchActive) {
                    this.handleSearchDisplay(true)
                }
            }, 300)
        })

    }

    productDescription = (detail) => {
        $('#descriptionModal').modal('show')

        let productDescription = window.serino_components.ProductDescriptionComponent.create({
            product: detail,
            currency: '₱',
            mode: (parseInt(this.state.app_key) == 1 || parseInt(this.state.app_key) == 7) ? 1 : 2,
            x_app_key: this.state.app_key
        }, "srn-product-description-container")

        $('#descriptionModal').animate({ scrollTop: 0 }, 'fast')
    }

    handleSearchDisplay = (bool) => {
        document.getElementById('srn-product-search-result-container').style.display = 'block'
    }

    handleMainAddress = () => {
        this.setState({isAddBtnLoading: false})
        mainAddressHandler()
    }

    handleRefreshCart = () => {
        triggerSerinoEvent(window.serino_components.cart.subscriptions.refreshCart('eatsCart'), localStorage.getItem(`address_object_${eats_app_id}`))
        triggerSerinoEvent(window.serino_components.cart.subscriptions.refreshCart('marketCart'), localStorage.getItem(`address_object_${market_app_id}`))
    }

    handleUnavailableitemsMarket = async (app) => {
        if (app.cart && app.cart.length > 0) {
            app.cart.forEach(async c => {
                let product_references = c.cart_items.map(x => `&product_reference[]=${x.itemReferenceId}`)
                var productAPI = new ProductList();
                let getProductResult = await productAPI.getInventoryProducts(product_references, c.brand.branch_id, 'id')
                let products = getProductResult.data.data.items
                // products = products.map((x, i) => {
                //     if (i === 0) {
                //         return {...x, inventory: []}
                //     }
                //     return x
                // })
                let filteredProducts = getFilteredProductsByStock(products)
                let oos_items = products.filter(product => !filteredProducts.includes(product)).map(x => x.id.toString())
                if (oos_items && oos_items.length > 0) {
                    let oos_cart = c.cart_items.filter(item => oos_items.includes(item.itemReferenceId));
                    triggerSerinoEvent(window.serino_components.cartTotal.subscriptions.pushUnavailableItems('cartTotal'), oos_cart)
                }
            })
        }
    }


    handleAddressChange = async (address, type) => {
        log('handleAddressChange', address)
        triggerSerinoEvent(
          window.serino_components.FulfillmentTypeComponent.subscriptions
            .onAddressFill,
          {
            address: address ? address : null,
            type: type
          }
        )
      }
    

    render() {
        return (
            <div className="d-flex flex-column h-100" id="cartmaincontainer">
                <div className={`container cart-container ${(this.state.cartQuantity != 'null') && !(this.state.cartQuantity > 0) ? "empty-cart" : ""}`}>  
                    {
                        // (this.state.app_key === 1 || this.state.app_key === 2) && localStorage.getItem('access_token') ?
                        <LocationPicker
                            access_token={localStorage.getItem('access_token')}
                            id="cart_page"
                            x_app_key={this.state.app_key}
                            brand_id={localStorage.getItem('brand_id')}
                            hidden={true}
                            getAddressObject={this.getAddressObject} 
                            handleLocationChange={this.handleLocationChange}
                        /> 
                        // : ''
                    }
                    <div className="row">
                        <div className={`${(this.state.cartQuantity != 'null') && !(this.state.cartQuantity > 0) ? "col-md-12" : "col-md-7"} cart-inner-container`}>
                            <span className="cart-back" onClick={() => window.history.back()}>← Back</span>
                            <h1 className={`srn-3-cart-title ${(this.state.cartQuantity != 'null') && !(this.state.cartQuantity > 0) ? "d-none" : ""}`}> Your Cart </h1>
                            <div id="srn-cart-market-container" className="srn-cart-component-container srn-cart-market-container"></div>
                            <div className="srn-cart-continue-btn-container">
                                {
                                    // this.getAppCartQuantity(1) > 0 &&
                                    // <a href={getUrl('market')} className="btn srn-cart-continue-btn srn-cart-continue-btn-market">
                                        // Continue Shopping Market
                                    // </a>
                                }
                            </div>
                            <div id="srn-cart-eats-container" className={`srn-cart-component-container srn-cart-eats-container ${(this.state.cartQuantity != 'null') && !(this.state.cartQuantity > 0) ? "d-none" : ""}`}></div>
                            <div className="srn-cart-continue-btn-container">
                                {
                                    // this.getAppCartQuantity(2) > 0 &&
                                    // <a href={getUrl('eats')} className="btn srn-cart-continue-btn srn-cart-continue-btn-eats">
                                        // Continue Shopping Eats
                                    // </a>
                                }
                            </div>
                            {
                                (this.state.cartQuantity != 'null') && !(this.state.cartQuantity > 0) ?
                                <div className="srn-cart-no-items-container">
                         
                                    <h6 className="srn-cart-no-items-header">Your Cart is Empty</h6>
                                    <span className="srn-cart-no-items">You haven’t added anything on your cart yet</span>

                                    <div className="srn-cart-no-items-option mweb-options row">
                                        <button onClick={() => window.location ="/eats"}>GO TO CENTRAL EATS</button>
                                        <button onClick={() => window.location ="/market"}>GO TO CENTRAL MARKET</button>
                                    </div>

                                    <div className="srn-cart-no-items-option web-options row">
                                        <EntryPoints data={this.state.appsJson} />
                                    </div>

                                </div> : null
                            }
                        </div>
                        <div className={`col-md-5 ${(this.state.cartQuantity != 'null') && !(this.state.cartQuantity > 0) ? "d-none" : ""}`}>
                            <div id="srn-market-summary-container" className="srn-cart-component-container srn-cart-summary-container"></div>
                            <div id="srn-eats-summary-container" className="srn-cart-component-container srn-cart-summary-container"></div>
                            <div class="cartpositionfixed">
                                <div id="srn-cart-total-container" className="srn-cart-total-eats-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal srn-min-max-modal" id="srn-min-modal">
                    <div className="modal-dialog modal-dialog-centered modal-lg srn-min-max-modal-dialog">
                        <div className="modal-content srn-min-max-modal-content">
                            <div className="modal-body srn-min-max-modal-body">
                                <h4 className="srn-min-max-modal-title">Below Minimum</h4>
                                <p className="srn-min-max-modal-description">Your order does not meet the minimum amount for delivery or pickup.</p>
                                <div className="srn-min-max-brand-details-container">
                                    <div className="srn-min-max-brand-container">
                                        {
                                            this.state.cartsBelowMinimum?.map(item => (
                                                    <div className="srn-min-max-brand-details">
                                                        <div className="srn-min-max-logo-details">
                                                            <div className="srn-min-max-logo-and-button">
                                                                <img className="srn-min-max-logo"src={item.brand.logo}/>
                                                                <div className="srn-min-max-buttons">
                                                                    {
                                                                        item.brand.id == 1 ?
                                                                        <button className="srn-min-max-add-item" id="btn-continue-shopping" onClick={() => window.location = `/market` }>
                                                                            ADD ITEMS
                                                                        </button>
                                                                        :
                                                                        <button className="srn-min-max-add-item"  onClick={() => this.handleClickEvent(item.brand) }>
                                                                            ADD ITEMS
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <p className="m-0 srn-min-max-minimum">Minimum for {item.fulfillmentType} ₱{item.minimum}</p>
                                                            <p className="srn-min-max-add-more"><FontAwesomeIcon icon={faExclamationCircle} title="close"/>  Add more items worth ₱{parseFloat(item.difference).toFixed(2)} or more.</p>
                                                        </div>
                                                    </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="srn-min-max-modal-btn-container d-flex">
                                <span className="srn-min-max-backtocart" data-dismiss="modal">Back to cart</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal srn-min-max-modal" id="srn-max-modal">
                    <div className="modal-dialog modal-dialog-centered modal-lg srn-min-max-modal-dialog">
                        <div className="modal-content srn-min-max-modal-content">
                            <div className="modal-body srn-min-max-modal-body">
                                <h4 className="srn-min-max-modal-title">Above Maximum</h4>
                                <p className="srn-min-max-modal-description">Your order exceeds the maximum amount for delivery or pickup.</p>
                                {
                                    this.state.cartsAboveMaximum?.length > 0 &&
                                    <div className="srn-min-max-brand-details-container">
                                        <div className="srn-min-max-brand-container">
                                            {
                                                this.state.cartsAboveMaximum?.map(item => (
                                                    <div className="srn-min-max-brand-details">
                                                        <div className="srn-min-max-logo-details">
                                                            <div className="srn-min-max-logo-and-button">
                                                                <img alt={item.brand.name} className="srn-min-max-logo"src={item.brand.logo}/>
                                                            </div>
                                                            <p className="m-0 srn-min-max-minimum">Maximum for {item.fulfillmentType} ₱{item.maximum}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="srn-min-max-modal-btn-container d-flex">
                                <span className="srn-min-max-backtocart" data-dismiss="modal">Back to cart</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal srn-product-description-main" id="descriptionModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered srn-modal-md" role="document">
                        
                        <div className="modal-content" id={`x${this.state.app_key}`}>
                            <div className="modal-header srn-description-modal-header">
                                {
                                    this.state.app_key == process.env.REACT_APP_MARKET_APP_ID ?
                                        <img src={prodDescMarketLogo} className="srn-ft-brand-logo" />
                                        :
                                        <img src={eatsLogo} className="srn-ft-brand-logo" />
                                }
                                {/* <img src={brand_logo} className="srn-ft-brand-logo" /> */}
                                <button type="button" className="close d-block" data-dismiss="modal" aria-label="Close">
                                    <span >&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="srn-product-description-container"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}