import React from 'react'
import EatsLogo from '../../../assets/images/footer_eats.svg'
import {  getUrl } from '../../../helper/helper'

export const EatsFooter = () => (
    <footer className="app-footer">
        <div className="eats-footer-red"></div>
        <div className="container">
        	<a href={`${getUrl('eats')}`}>
            	<img className="footer-logo" src={EatsLogo}/>
        	</a>        </div>
    </footer>
)

export default EatsFooter;