import React, { Component } from "react";
import $ from 'jquery'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'
import { getAccessToken, setCrossStorage, getUrl, log, handleExpiredToken, getCrossCart } from '../../helper/helper'
import { fbqEvent } from '../../helper/fbpixel'
import FulfillmentTypeComponent from '../partials/fulfillmetType'
import checkCircle from '../../assets/images/check_circle.svg'
import welcomecentral from '../../assets/images/CM Home Banner apply member.png'
import ParallaxComponent from '../partials/ParallaxComponent'


import ProfileAPI from '../../_core/profile'
import LoginAPI from '../../_core/login'
import RewadAPI from "../../_core/link-reward";
import CartAPI from "../../_core/cart";

var loginAPI = new LoginAPI()
var profileAPI = new ProfileAPI()
var rewardAPI = new RewadAPI()
var cartAPI = new CartAPI()

const x_app_key = process.env.REACT_APP_X_APP_KEY
const tenant_id = process.env.REACT_APP_TENANT_ID
const market_app_key = process.env.REACT_APP_MARKET_APP_ID
const eats_app_key = process.env.REACT_APP_EATS_APP_ID

export default class Login extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            username: '',
            password: '',
            verification_code: '',
            isLoading: false,
            isLocked: true,
            isLoggedIn: localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false,
            label_address: '',
            street_name: '',
            bldg_name: '',
            isChecked: true,
            card_number: '',
            host: '',
            domain: '',
            domain_name: '',
            membership_id: '',
            membership_name: '',
            isCreateAddressSubmitLoading: false,
            isAddressValid: true
        }
    }

    async componentDidMount() {
        sessionStorage.removeItem("mName")
        sessionStorage.removeItem("mMembershipId")
        sessionStorage.removeItem("fromSignupApply")
        sessionStorage.setItem("signupPage", true)

        this.clearAddress() //Temp Fix clearing address
        this.signupRedirect()
        let crm = window.serino_components.crm;
        let login_crm_events = crm.events.loginEvents;
        let register_crm_events = crm.events.registerEvents
        
        getAccessToken()
        .then(async() => {

            let all_cart_items = await getCrossCart(localStorage.getItem('access_token'))

            localStorage.setItem('temp_cart', JSON.stringify(all_cart_items))

            var host = window.location.host
            var parts = host.split('.')
            let domain = (parts[0] == 'eats') || (parts[0] == 'market') ? 'sub' : 'main'

            this.setState({
                host: parts,
                domain: domain,
                domain_name: parts[0],
            })


            // login form
            let srn_crm_logincomponent = crm.createLoginComponent({
                access_token: localStorage.getItem('access_token'),
                x_app_key: x_app_key,
                tenant_id: tenant_id
            },"crm-login-container");

            // login events
            document.addEventListener(login_crm_events.signUpLink, (loginEvent) => {
                sessionStorage.setItem('signUpEnabled', true)
                localStorage.setItem("isLoggedIn", true)
                localStorage.setItem("fulfillment_mode", 1)
                sessionStorage.setItem("signupPage", true)

                this.createSignupComponent()
                document.getElementById('signup-container').style.display = 'block'
                document.getElementById('crm-login-container').style.display = 'none'
            })

            document.addEventListener(login_crm_events.success, async (loginEvent) => {
                console.log('loginEvent.detail', loginEvent.detail)
                localStorage.setItem('access_token', loginEvent.detail.data.AccessToken)
                localStorage.setItem('refresh_token', loginEvent.detail.data.RefreshToken)

                setCrossStorage('isLoggedIn', true, domain)

                localStorage.setItem('isLoggedIn', true)
                localStorage.removeItem('Address')
                localStorage.removeItem('SRN-CART-ID')
                localStorage.removeItem('main_address')

                let clearCartMarketResult
                let clearCartEatsResult

                // try {
                //     clearCartMarketResult = await cartAPI.clearCart(loginEvent.detail.data.AccessToken, market_app_key)
                // }
                // catch(marketErr) {
                //     handleExpiredToken()
                // }

                // try {
                //     clearCartEatsResult = await cartAPI.clearCart(loginEvent.detail.data.AccessToken, eats_app_key)
                // }
                // catch(marketErr) {
                //     handleExpiredToken()
                // }

                this.getDefaultAddress(loginEvent.detail.data.AccessToken, x_app_key)
            })

            document.addEventListener(login_crm_events.failed, (loginEvent) => {
                console.log(loginEvent.detail)
                Swal.fire({
                    title: 'Login failed',
                    text: 'Incorrect username or password.',
                    customClass: {
                        confirmButton: 'btn btn-block srn-crm-ok-btn'
                    },
                    buttonsStyling: false
                })
            })
            
            document.addEventListener(login_crm_events.forgotPasswordLink, (loginEvent) => {
                $('#forgot-pass-modal').modal('show')
            })

            // sign up event
            document.addEventListener(register_crm_events.success, (e) => {
                // this.timer()
                // $('#verify-code-modal2').modal({
                // // $('#welcome-modal').modal({
                //     backdrop: 'static',
                //     keyboard: false
                // })
                console.log("success",e)
                this.setState({ 
                    username: e.detail.username,
                    email: e.detail.email,
                    password: e.detail.password, 
                    membership_name: e.detail.name 
                })
                localStorage.setItem("signupEmail",e.detail.email)
                this.handleConfirmSignup()
            })

            document.addEventListener(register_crm_events.failed, (e) => {
                console.log(e.detail)
                if (e.detail.data.message.errors[0].msg == "username already exist") {
                    Swal.fire({
                        title: ' ',
                        text: "email already exist",
                        customClass: {
                            confirmButton: 'btn btn-block srn-crm-ok-btn'
                        },
                        buttonsStyling: false
                    }) 
                } else {
                    Swal.fire({
                        title: ' ',
                        text: e.detail.data.message.errors[0].msg,
                        customClass: {
                            confirmButton: 'btn btn-block srn-crm-ok-btn'
                        },
                        buttonsStyling: false
                    })
                }
            })

            crm.createForgotPasswordComponent({
                access_token: localStorage.getItem('access_token'),
                x_app_key: x_app_key,
                tenant_id: tenant_id
            }, 'srn-crm-forgot-pass')

            let forgotPass_event = crm.events.forgotPasswordEvents
            document.addEventListener(forgotPass_event.success, (e) => {
                $('#confirm-forgot-pass-modal').modal({
                    backdrop: 'static',
                    keyboard: false
                })
                $('#forgot-pass-modal').modal('hide')
                this.createConfirmPassComponent(e.detail)
            })

            document.addEventListener(forgotPass_event.failed, () => {
                alert('failed')
            })
            
            
            let confirmPass_event = crm.events.confirmPasswordEvents
            document.addEventListener(confirmPass_event.continue_success, (e) => {
                Swal.fire({
                    text: 'Password changed successfully.',
                    icon: 'success'
                }).then(() => {
                    window.location = '/login'
                })
            })

            document.addEventListener(confirmPass_event.continue_failed, (e) => {
                console.log('failed',  e.detail.apiResponse.data.message)
                Swal.fire({
                    text: e.detail.apiResponse.data.message,
                    icon: 'error'
                })
            })
            if (sessionStorage.getItem('signUpEnabled') === "true") {
                this.createSignupComponent()
                document.getElementById('crm-login-container').style.display = 'none'
                document.getElementById('signup-container').style.display = 'block'
                document.getElementById('email-container').style.display = 'none'
            }

            
        })

        document.getElementById('flex-fill').classList.add('gray-body')
       
    }

    createSignupComponent = () => {
        let crm = window.serino_components.crm;
        let srn_register_component = crm.createRegisterComponent({
            access_token: localStorage.getItem('access_token'),
            x_app_key: x_app_key,
            email: this.state.email,
            application_id: process.env.REACT_APP_X_APP_KEY
        }, "srn-crm-signup-container");
        // console.log('sadfsdfdddddddddddd',process.env.REACT_APP_X_APP_KEY)
        $("input.srn-crm-mobile").attr('maxlength', '10');  
    }

    createConfirmPassComponent = (email) => {
        let crm = window.serino_components.crm;
        crm.createConfirmPasswordComponent({
            access_token: localStorage.getItem('access_token'),
            email: email,
            tenant_id: tenant_id
        }, 'srn-crm-confirm-forgot-pass')
    }

    getDefaultAddress = async (access_token_props, x_app_key) => {

        // let access_token = access_token_props

        // let refreshTokenResult = await loginAPI.handleRefreshToken(access_token_props)

        // log({refreshTokenResult})

        // if (refreshTokenResult.message === 'Success') {
        //     localStorage.setItem('access_token', refreshTokenResult.data.AccessToken)
        //     access_token = refreshTokenResult.data.AccessToken
        //     let clearCartMarketResult = await cartAPI.clearCart(refreshTokenResult.data.AccessToken, market_app_key)
        //     let clearCartEatsResult = await cartAPI.clearCart(refreshTokenResult.data.AccessToken, eats_app_key)
        // }
        // else {
        //     return;
        // }

        localStorage.setItem(`address_object_${process.env.REACT_APP_EATS_APP_ID}`, '[]')
        localStorage.setItem(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`, '[]')
        localStorage.removeItem('Address')
        localStorage.removeItem('main_address')
        localStorage.removeItem('default_address')

        loginAPI.getClientAddress(access_token_props, x_app_key)
            .then(async(res) => {
                // let access_token = access_token_props

                // let refreshTokenResult = await loginAPI.handleRefreshToken(access_token_props)

                // log({refreshTokenResult})

                // if (refreshTokenResult.message === 'Success') {
                //     localStorage.setItem('access_token', refreshTokenResult.data.AccessToken)
                //     access_token = refreshTokenResult.data.AccessToken
                //     let clearCartMarketResult = await cartAPI.clearCart(refreshTokenResult.data.AccessToken, market_app_key)
                //     let clearCartEatsResult = await cartAPI.clearCart(refreshTokenResult.data.AccessToken, eats_app_key)
                // }
                // else {
                //     return;
                // }
                // let clearCartMarketResult = await cartAPI.clearCart(access_token_props, market_app_key)
                // let clearCartEatsResult = await cartAPI.clearCart(access_token_props, eats_app_key)
                sessionStorage.setItem('initial_login', 1)

                let primaryAddress = res.data.filter((d =>{
                    return 1 == d.isPrimary
                }))
                if (primaryAddress.length > 0) {
                    let defaultAddress = primaryAddress[0].city + ', ' + primaryAddress[0].province + ', ' + primaryAddress[0].country
                    localStorage.setItem('Address', defaultAddress)

                    if(localStorage.getItem('isNewAccount') == true || localStorage.getItem('isNewAccount') == 'true' && localStorage.getItem("signupEmail") == localStorage.getItem("userEmail")) {
                        $('#welcome-modal').modal({
                            backdrop: 'static',
                            keyboard: false
                        })
                    } else {
                        window.location = getUrl('main')
                        localStorage.removeItem("signupEmail")
                        localStorage.removetItem('isNewAccount')
                    }
                } else {
                    if(localStorage.getItem('isNewAccount') == true || localStorage.getItem('isNewAccount') == 'true' && localStorage.getItem("signupEmail") == localStorage.getItem("userEmail")) {
                        $('#welcome-modal').modal({
                            backdrop: 'static',
                            keyboard: false
                        })
                    } else {
                        window.location = getUrl('main')
                        localStorage.removeItem("signupEmail")
                        localStorage.removetItem('isNewAccount')
                    }
                    // window.location = getUrl('main')
                }
                
            })
    }

    // validateEmail = () => {
    //     var email = this.state.email
    //     var emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //         if (!emailValidation.test(email)) {
    //             Swal.fire({
    //                 html: 'Please enter valid email.',
    //                 icon: 'warning',
    //                 customClass: {
    //                     confirmButton: 'btn btn-block srn-crm-ok-btn'
    //                 },
    //                 buttonsStyling: false
    //             }).then(() => {
    //                  this.setState({ isLoading: false })
    //             })
    //         } else {
    //             // return this.isValidEmail  = true
    //             this.handleVerifyEmail()
    //         }
    //         // localStorage.setItem("isLoggedIn", true)
	// 	    // localStorage.setItem("fulfillment_mode", "1")
    // }

    // handleVerifyEmail = () => {
    //     this.setState({ isLoading: true })
    //     let email_input = $('#email_input').val()
    //     if (email_input == '' || email_input == null) {
    //        Swal.fire({
    //            html: 'Email is required.',
    //            icon: 'warning',
    //            customClass: {
    //                confirmButton: 'btn btn-block srn-crm-ok-btn'
    //            },
    //            buttonsStyling: false
    //        }).then(() => {
    //             this.setState({ isLoading: false })
    //        })
    //     } else {
    //         let username = this.state.email.split('@')[0]
    //         loginAPI.verifyEmail(localStorage.getItem('access_token'), x_app_key, username)
    //             .then((res) => {
    //                 if (res.status) {
    //                     let userMeta = res.data.metas.filter((k => {
    //                         return "CentralMain" === k.key 
    //                     }))

    //                     let rewardsEnabled = res.data.metas.filter((m => {
    //                         return "Rewards" === m.key
    //                     }))

    //                     if (res.status === 200) {
    //                         Swal.fire({
    //                             text: 'Email has pending verification',
    //                             icon: 'warning',
    //                             customClass: {
    //                                 confirmButton: 'btn btn-block srn-crm-ok-btn'
    //                             },
    //                             buttonsStyling: false
    //                         }).then(() => {
    //                             this.setState({ isLoading: false })
    //                         })
    //                     }

    //                     if (userMeta[0].value === 1 || userMeta[0].value === "1") {
    //                         Swal.fire({
    //                             text: 'Email already exist',
    //                             icon: 'warning',
    //                             customClass: {
    //                                 confirmButton: 'btn btn-block srn-crm-ok-btn'
    //                             },
    //                             buttonsStyling: false
    //                         }).then(() => {
    //                             this.setState({ isLoading: false })
    //                         })
    //                     } else {
    //                         if (rewardsEnabled.length !== 0 ) {
    //                             if (rewardsEnabled[0].value === 1) {
    //                                 // get reward
    //                                 let getMemberShipID = res.data.metas.filter((m => {
    //                                     return "MembershipId" === m.key
    //                                 }))

    //                                 this.setState({
    //                                     card_number: getMemberShipID[0].value
    //                                 })
    //                             }
    //                         }

    //                         sessionStorage.setItem('signUpEnabled', true)
    //                         this.createSignupComponent()
    //                         document.getElementById('signup-container').style.display = 'block'
    //                         document.getElementById('email-container').style.display = 'none'
    //                         this.setState({ isLoading: false })
    //                     }

                        
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //                 if (err.status === 400) {
    //                     sessionStorage.setItem('signUpEnabled', true)
    //                     this.createSignupComponent()
    //                     document.getElementById('signup-container').style.display = 'block'
    //                     document.getElementById('email-container').style.display = 'none'
    //                     this.setState({ isLoading: false })
    //                 }
    //             })
    //     }
    // }

    handleConfirmSignup = async () => {

        // Swal.fire({
        //     imageUrl: checkCircle,
        //     title: "success",
        //     html: "Registered Successfully!",
        //     className: "swal-profile",
        //     customClass: {
        //         container: 'swal-address-container',
        //         popup: 'swal-address-update',
        //         image: 'swal-icon-success'
        //     }
        // }).then(() => {
        //     localStorage.setItem('isLoggedIn', true)
        //     localStorage.removeItem('address_input')
        //     this.skipForm()
        // })

        let payload = {
			"email": this.state.email,
			"password": this.state.password,
			"tenant_id": tenant_id
		}
            let res = await loginAPI.confirmSignup(localStorage.getItem('access_token'), x_app_key, payload)
            if (res.status === 200 || res.status === 201) {
                await this.getClientAccessToken()
                setTimeout(() => {
                    Swal.fire({
                        imageUrl: checkCircle,
                        title: "success",
                        html: "Registered Successfully!",
                        className: "swal-profile",
                        customClass: {
                            container: 'swal-address-container',
                            popup: 'swal-address-update',
                            image: 'swal-icon-success'
                        }
                    }).then(() => {
                        fbqEvent('CompleteRegistration')
                        localStorage.setItem('isLoggedIn', true)
                        localStorage.removeItem('address_input')
                        this.skipForm()
                    },5000)
                })
            } else {
               console.log("error verif")
            }
        }

    handleVerifyCode = async () => {
        let input = $('#verification-code').val()
        if (input == '' || input == null) {
            Swal.fire({
                title: 'Invalid Verification Code',
                text: 'Please enter valid verification code again',
                customClass: {
                    confirmButton: 'btn btn-block srn-crm-ok-btn'
                },
                buttonsStyling: false
            })
        } else {
            try {

                let payload = {
                    "username": this.state.username,
                    "code": this.state.verification_code,
                    "tenant_id": tenant_id
                }
                let res = await loginAPI.confirmSignup(localStorage.getItem('access_token'), x_app_key, payload)

                if (res.status === 200 || res.status === 201) {
                    $('#verify-code-modal').modal('hide')
                    await this.getClientAccessToken()
                    Swal.fire({
                        confirmButtonText: 'CLOSE',
                        title: 'Success!',
                        text: 'Account Verified Successfully ',
                        customClass: {
                            confirmButton: 'btn btn-block srn-crm-ok-btn'
                        },
                        buttonsStyling: false
                    }).then(() => {
                        // window.location = '/'
                        localStorage.setItem('isLoggedIn', true)
                        localStorage.removeItem('address_input')
                        setTimeout(() => {
                            $('#welcome-modal').modal({
                                backdrop: 'static',
                                keyboard: false
                            })
                        },1000)
                    })
                } else {
                    Swal.fire({
                        title: 'Invalid Verification Code',
                        text: 'Please enter valid verification code again',
                        customClass: {
                            confirmButton: 'btn btn-block srn-crm-ok-btn'
                        },
                        buttonsStyling: false
                    })
                }
            }
            catch(err) {
                if (err.status === 400) {
                    Swal.fire({
                        title: 'Invalid Verification Code',
                        text: 'Please enter valid verification code again',
                        customClass: {
                            confirmButton: 'btn btn-block srn-crm-ok-btn'
                        },
                        buttonsStyling: false
                    })
                }
            }
        }
    }

    getClientAccessToken = () => {
        let payload = {
            "email": this.state.email,
			"password": this.state.password,
            "tenant_id": tenant_id
        }
        loginAPI.getClientAccessToken(payload)
            .then((res) => {
                localStorage.setItem('access_token', res.data.AccessToken)
                localStorage.setItem('refresh_token', res.data.RefreshToken)
                localStorage.setItem('isLoggedIn', true)
                localStorage.setItem('userEmail', this.state.email)
                sessionStorage.removeItem('signUpEnabled')
                this.handleAddAddress()
                this.linkMembershipCard()
                this.setMeta()
                if(res.message == "Success") {
                    if(sessionStorage.getItem('mMembershipId') != null ) {
                        localStorage.setItem('isNewAccount', false)
                    } else {
                        localStorage.setItem('isNewAccount', true)
                    }
                }
            })
    }

    linkMembershipCard = () => {
        if( sessionStorage.getItem("mMembershipId") != null ) {
            let payload = {
                "email": this.state.email,
                "name": sessionStorage.getItem("mName"),
                "membership_id": sessionStorage.getItem("mMembershipId")
            }
            rewardAPI.linkMembership(payload, localStorage.getItem('access_token'))
                .then((res) => {
                    if(res.message == "Membership linked successfully") {
                        console.log("linked successfuly")
                        localStorage.removeItem("isNewAccount")
                    }
                })
                .catch(err => {
                    console.log(err.successful, err)
                })
        } else {
            console.log("no membership")
        }
    }
    
    handleAddAddress = (e) => {
        let payload = {
            "address": sessionStorage.getItem('address'),
            "label": sessionStorage.getItem('address'),
            "line1": sessionStorage.getItem('line1'),
            "line2": sessionStorage.getItem('line2'),
            "city": sessionStorage.getItem('city'),
            "province": sessionStorage.getItem('province'),
            "country": sessionStorage.getItem('country'),
            "zipCode": "1111",
            "longitude": sessionStorage.getItem('longitude'),
            "latitude": sessionStorage.getItem('latitude'),
            "isPrimary": sessionStorage.getItem('isPrimary'),
            "custom_data": sessionStorage.getItem('custom_data'),
        }

        profileAPI.addAddress(localStorage.getItem('access_token'), x_app_key, payload)
            .then((res) => {
                this.clearAddress() //Temp Fix clearing address
                console.log("add address", res)
            })
    }

    clearAddress = () => {
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('address')
        sessionStorage.removeItem('line1')
        sessionStorage.removeItem('line2')
        sessionStorage.removeItem('city')
        sessionStorage.removeItem('province')
        sessionStorage.removeItem('country')
        sessionStorage.removeItem('longitude')
        sessionStorage.removeItem('latitude')
        sessionStorage.removeItem('isPrimary')
    }

    setMeta = () => {
        let username = this.state.username
        let payload = {
            "key": "CentralMain",
            "value": 1
        }
        loginAPI.addNewMeta(localStorage.getItem('access_token'), x_app_key, payload, username)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // handleResendVerification = () => {
    //     if (this.state.isLocked) {
    //         return
    //     } else {
    //         let payload = {
    //             "username": this.state.username,
    //             "tenant_id": tenant_id
    //         }
    //         loginAPI.resendConfirmation(localStorage.getItem('access_token'), x_app_key, payload)
    //             .then((res) => {
    //                 if (res.status === 200) {
    //                     this.timer()
    //                     this.setState({ isLocked: true })

    //                     Swal.fire({
    //                         text: 'Verification code successfully sent. Kindly check your email.',
    //                         icon: 'success'
    //                     })
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log(err)
    //             })
    //     }
        
    // }

    handleFieldChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    // saveAddress = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     if (localStorage.getItem('address_input') != null ) {
    //             $('#signup-address-input').val(localStorage.getItem('address_input'))
    //             document.getElementById("closeAddressModal").click();
    //             this.setState({
    //                 isAddressValid: true
    //             })
    //             // setTimeout(() => {
    //             //     localStorage.re
    //             // })
    //     } else {
    //         this.setState({
    //             isAddressValid: false
    //         })
    //     }
    // }

    setChecked = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    }

    // cancelAddress = () => {
    //     document.getElementById('card-signup').style.display = 'none'
    //     document.getElementById('card-link-membership').style.display = 'block'
    // }

    skipForm = () => {
        setCrossStorage('isLoggedIn', true, this.state.domain)
        sessionStorage.removeItem("signUpEnabled")
        sessionStorage.removeItem("signupPage")
        sessionStorage.setItem('initial_login', 1)
        sessionStorage.setItem('signup_login', 1)
        setTimeout(() => {
            this.getDefaultAddress(localStorage.getItem('access_token'), x_app_key)
            $('#welcome-modal').modal({
                backdrop: 'static',
                keyboard: false
            })
        }, 
        2000)
    }

    skipForm2 = () => {
        sessionStorage.removeItem("signUpEnabled")
        sessionStorage.removeItem("signupPage")
        localStorage.removeItem("isNewAccount")
        window.location = getUrl('main')
    }
    // timer = () => {
    //     var tmp = this
    //     var seconds = 60
    //     var elem = document.getElementById('timer_div')
    //     var timer = setInterval(countdown, 1000)

    //     function countdown() {
    //         if (seconds == -1) {
    //             clearInterval(timer)
    //             tmp.setState({ isLocked: false })
    //         } else {
    //             elem.innerHTML = seconds
    //             seconds--
    //         }
    //     }
    // }

    // validate = (evt) => {
    //     const verification_code = (evt.target.validity.valid) ? evt.target.value : this.state.verification_code

    //     this.setState({ verification_code })
    // }

    signupRedirect = () => {
        if( sessionStorage.getItem('signupredirect') === "true") {
            document.getElementById('email-container').style.display = 'block'
            document.getElementById('crm-login-container').style.display = 'none'
            sessionStorage.removeItem('signupredirect')
        }
    }
    
    signupLogin = () => {
        window.location.reload()
    }

    applyMembership = () => {
        sessionStorage.removeItem("signUpEnabled")
        sessionStorage.removeItem("signupPage")
        sessionStorage.setItem("fromSignupApply", true)
        // window.location="/"
        window.location = "/membership"
    }

    render() {
        return (
            <div className="crm-container" id="login-signup-container">
                <ParallaxComponent page="generic" />
                <div className="container" style={{position: "relative"}}>
                    <div id="crm-login-container"></div>

                    <div className="email-container" id="email-container">
                        <div class="signupcontainer">
                            <h4>Sign up</h4>
                            <div className="form-group">
                                <label className="srn-crm-label">Email <span className="srn-required-indicator">*</span></label>
                                <input name="email" type="email" id="email_input" className="form-control srn-crm-form-control" placeholder="Enter email address" onChange={(e) => this.handleFieldChange(e)}/>
                            </div>
                            <div className="form-group">
                                <button className="btn btn-block srn-crm-proceed" type="submit" disabled={this.state.isLoading} onClick={() => this.validateEmail()}>
                                    {this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin />} PROCEED
                                </button>
                            </div>
                        </div>
                        <div className="signup-login-btn">
                            <span onClick = {() => this.signupLogin()}>LOGIN</span>
                        </div>
                    </div>

                    <div className="signup-container" id="signup-container">
                        <div id="srn-crm-signup-container"></div>
                    </div>


                    {/* default address form */}
                    {/*<div className="card card-signup d-block" id="card-signup">*/}
                    {/* <div className="card card-signup" id="card-signup">
                        <div className="card-header">
                            <small className="srn-crm-signup-label">completing registration</small>
                            <small className="srn-crm-signup-page">1 of 2</small>
                        </div>
                        <div className="card-body">
                            <form className="srn-crm-default-address-form" id="srn-crm-default-address-form">
                                <h3 className="srn-crm-title">Default delivery address</h3>

                                <div className="form-group">
                                    
                                        <label className="srn-crm-label">Label <span className="srn-required-indicator">*</span></label>
                                        <input name="label_address" type="text" 
                                            className="form-control srn-crm-form-control srn-crm-label-address" 
                                            placeholder="Address label"
                                            value={this.state.label_address || ''}
                                            onChange={(e) => this.handleFieldChange(e)}></input>
                                    </div>

                                    <div className="form-group">
                                        <label className="srn-crm-label">Delivery Address <span className="srn-required-indicator">*</span></label>
                                        <span className="srn-ft-location-icon">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        </span>
                                        <LocationPickerComponentInput id="add_address_component" />
                                    </div>

                                    
                                    <div className="form-group">
                                        <label className="srn-crm-label">House No. / Street Name <span className="srn-required-indicator">*</span></label>
                                        <input name="street_name" type="text" 
                                            className="form-control srn-crm-form-control srn-crm-street-name" 
                                            required={true}
                                            value={this.state.street_name || ''}
                                            onChange={(e) => this.handleFieldChange(e)}></input>
                                    </div>

                                    <div className="form-group">
                                        <label className="srn-crm-label">Floor / Unit / Building Name (optional)</label>
                                        <input name="bldg_name" type="text" 
                                            className="form-control srn-crm-form-control srn-crm-street-name"
                                            onChange={(e) => this.handleFieldChange(e)}></input>
                                    </div>

                                    <div className="form-check mb-5">
                                        <input type="checkbox" name="default_address_chckbx" 
                                            id="default_address_chckbx" 
                                            className="form-check-input srn-crm-checkbox"
                                            onChange={() => this.setChecked()}
                                            checked={this.state.isChecked}></input>
                                        <label className="form-check-label srn-crm-label" for="default_address_chckbx">Use as my default address</label>
                                    </div>

                                    <div className="form-group srn-crm-action-btn">
                                        <button type="button" className="btn btn-outline-dark btn-block" onClick={() => this.cancelAddress()}>
                                            Skip
                                        </button>
                                        <button type="submit" className="btn btn-block srn-crm-save-btn" onClick={(e) => this.handleAddAddress(e)} disabled={this.state.isCreateAddressSubmitLoading}>
                                            Save
                                        </button>
                                    </div>
                            </form>
                            
                        </div>
                    </div> */}
                    {/* end default address */}

                    {/* link address */}
                    {/* <div className="card card-link-membership" id="card-link-membership">
                        <div className="card-header">
                            <small className="srn-crm-signup-label">completing registration</small>
                            <small className="srn-crm-signup-page">2 of 2</small>
                        </div>
                        <div className="card-body">
                            <form className="srn-crm-link-membership-form" id="srn-crm-link-membership-form">
                                <h3 className="srn-crm-title">Link Landers Membership Card</h3>
                                <small className="srn-crm-description">Verify and link your Landers membership code with this account to enjoy exclusive online deals!</small>
                           
                                <div className="form-group">
                                    <label className="srn-crm-label">Enter Membership Name</label>
                                    <input name="membership_name" type="text" 
                                            className="form-control srn-crm-form-control srn-crm-membership_name" 
                                            placeholder="Printed Name on Membership card (First & Last Name)"
                                            value={this.state.membership_name || ''}
                                            onChange={(e) => this.handleFieldChange(e)}></input>
                                </div>

                                <div className="form-group">
                                    <label className="srn-crm-label">Enter Membership Card Number</label>
                                    <input name="card_number" type="text" 
                                            className="form-control srn-crm-form-control srn-crm-card_number" 
                                            placeholder="Membership Card Number"
                                            value={this.state.card_number || ''}
                                            onChange={(e) => this.handleFieldChange(e)}></input>
                                </div>

                                <div className="form-group srn-crm-action-btn">
                                        <button type="button" className="btn btn-outline-dark btn-block" onClick={() => this.skipForm()}>
                                            Skip
                                        </button>
                                        <button type="button" className="btn btn-block srn-crm-save-btn" onClick={() => this.linkMembershipCard()}>
                                            Verify
                                        </button>
                                </div>
                            </form>
                        </div>
                    </div> */}
                    {/* membership card */}

                </div>
                
                <FulfillmentTypeComponent
                    access_token={localStorage.getItem("access_token")}
                    x_app_key={1}
                    // closing_time={this.state.address_object.closing_time}
                    // offHours={this.state.address_object.offHours}
                    brand_id={0}
                    // busyStore={this.state.address_object.busyStore}
                    // getFulfillmentDetails={this.getFulfillmentDetails}
                    product={null}
                    isPickupDisabled
                    handleModalClose={this.props.handleModalClose ? this.props.handleModalClose : null}
                    main={this.props.main}
                />

                {/* MODAL */}
                {/* ADD ADDRESS */}
                {/* <div class="modal fade" tabindex="-1" role="dialog" id="signup-address-modal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div className="modal-body p-0">
								<div className="card card-signup p-0 d-block m-0" id="card-signup">
									<div className="card-body">
										<form className="srn-crm-default-address-form" id="srn-crm-default-address-form" onSubmit={(e) => this.saveAddress(e)}>
											<h3 className="srn-crm-title">Default delivery address</h3>

											<div className="form-group">
													<label className="srn-crm-label">Label <span className="srn-required-indicator">*</span></label>
													<input name="label_address" type="text" 
														className="form-control srn-crm-form-control srn-crm-label-address" 
														placeholder="Address label"
														value={this.state.label_address || ''}
                                                        required={true}
														onChange={(e) => this.handleFieldChange(e)}></input>
												</div>

												<div className="form-group">
													<label className="srn-crm-label">Delivery Address <span className="srn-required-indicator">* {this.state.isAddressValid == false ? "Please choose an address" : ""}</span></label>
													<span className="srn-ft-location-icon">
														<FontAwesomeIcon icon={faMapMarkerAlt} />
													</span>
                                                    <LocationPickerComponentInput id="add_address_component" />
												</div>

												
												<div className="form-group">
													<label className="srn-crm-label">House No. / Street Name <span className="srn-required-indicator">*</span></label>
													<input name="street_name" type="text" 
														className="form-control srn-crm-form-control srn-crm-street-name" 
														required={true}
														value={this.state.street_name || ''}
														onChange={(e) => this.handleFieldChange(e)}></input>
												</div>

												<div className="form-group">
													<label className="srn-crm-label">Floor / Unit / Building Name (optional)</label>
													<input name="bldg_name" type="text" 
														className="form-control srn-crm-form-control srn-crm-street-name"
                                                        required={true}
														onChange={(e) => this.handleFieldChange(e)}></input>
												</div>

												<div className="form-check mb-5">
													<input type="checkbox" name="default_address_chckbx" 
														id="default_address_chckbx" 
														className="form-check-input srn-crm-checkbox"
														onChange={() => this.setChecked()}
														checked={this.state.isChecked}></input>
													<label className="form-check-label srn-crm-label" for="default_address_chckbx">Use as my default address</label>
												</div>

												<div className="form-group srn-crm-action-btn">
													<button  className="btn btn-block srn-crm-save-btn w-100" type="submit">
														Confirm
													</button>
                                                    <button className="d-none" data-dismiss="modal" id="closeAddressModal"/>
												</div>
										</form>
									</div>
								</div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div class="modal fade" tabindex="-1" role="dialog" id="welcome-modal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            
                            <div className="modal-body">
                                <div className="modal-header crm-verify-email-modal-header">
                                    <h6 className="welcome-title">Welcome to</h6>
                                    <h3 className="welcome-tag">CENTRAL</h3>
                                </div>
                                {/* <h6>Marketing Content</h6> */}
                                <img className="" src={welcomecentral} />
                                <div className="modal-welcome-footer">
                                    <div className="form-group sign-up-btn-container">
                                        <button 
                                            href={`/membership`} 
                                            className="btn btn-dark btn-block srn-crm-btn srn-crm-bg-black srn-crm-register-btn" 
                                            type="submit" 
                                            onClick={() => this.applyMembership()}
                                            disabled={this.state.isLoading} >
                                                {this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
                                                Apply Membership
                                        </button>
                                    </div>
                                    <a onClick={() => this.skipForm2()}>Skip</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal fade" tabindex="-1" role="dialog" id="verify-code-modal2" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div className="modal-header crm-verify-email-modal-header">
                                <h3 className="verification-title">Verification Process</h3>
                                <h3 className="verification-tag">Email Verification</h3>
                                <label className="crm-label-notice text-secondary">Please check your email for the verification code</label>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="srn-crm-label">Verification Code <span className="srn-required-indicator">*</span></label>
                                    <input name="verification_code" id="verification-code" type="text" pattern="[0-9]*"
                                        maxLength="6"
                                        className="form-control srn-crm-form-control verification-code-input"
                                        placeholder="Enter verification code"
                                        value={this.state.verification_code || ''}
                                        onInput={(e) => this.validate(e)}/>
                                </div>

                                <div className="form-group">
                                    <label className="srn-crm-label"><span className="srn-required-indicator">*</span> Required Fields</label>
                                </div>

                                <div className="form-group">
                                    <button className="btn btn-block srn-crm-proceed" type="submit" onClick={() => this.handleVerifyCode()}>
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                            <div className="modal-footer crm-verify-email-modal-footer">
                                <label className="crm-label">Didn’t get the email?</label>
                                <a href="#!" className={`srn-crm-resend-email-btn ${this.state.isLocked ? 'text-secondary': ''}`} 
                                    onClick={() => this.handleResendVerification()} 
                                    disabled={this.state.isLocked}>
                                        <small id="timer_div" className={`${this.state.isLocked === false ? 'hide' : 'show'}`} ></small> RESEND EMAIL VERIFICATION</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabindex="-1" role="dialog" id="forgot-pass-modal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div className="modal-body">
                                <div id="srn-crm-forgot-pass"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabindex="-1" role="dialog" id="confirm-forgot-pass-modal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div className="modal-body">
                                <div class="fpheadercontainer">
                                    <p class="fplabel">Reset Password</p>
                                    <button type="button" className="btn srn-ft-close-btn" data-dismiss="modal" aria-label="Close">
                                        <a href="#" className="close"/>
                                    </button> 
                                </div>
                                <div id="srn-crm-confirm-forgot-pass"></div>
                            </div>
                        </div>
                    </div>
                </div>      
            </div>
        )
    }
 
}
