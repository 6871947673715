/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { faAngleLeft, faAngleRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React, { Component } from "react";
import categoryPlaceholderImg from "../../../assets/images/category_item_icon.png";
import MarketLogo from "../../../assets/images/market-footer2.png";
import marketBannerMobile from "../../../assets/images/market-products-banner-mobile.png";
import P88AndBelowBanner from "../../../assets/images/Market88Below.jpg";
import HotOffersBanner from "../../../assets/images/MarketHotOffers.jpg";
import TopPicksBanner from "../../../assets/images/MarketTopPicks.jpg";
import WhatsNewBanner from "../../../assets/images/MarketWhatsNew.jpg";
import marketsidebanner1 from "../../../assets/images/market_side_banner01.jpg";
import marketsidebanner2 from "../../../assets/images/market_side_banner02.jpg";
import marketsidebanner3 from '../../../assets/images/market-side-adsfind.jpg'
import {
  getAccessToken,
  getTemporaryToken,
  getCrossDomainToken,
  getCrossStorage,
  getSubdomainCartCount,
  getUrl,
  handleExpiredToken,
  handleLandersBrandId,
  log,
  membershipChecker,
  setCrossStorage,
  setHeaderAddress,
  setSubdomainCartCount,
  triggerSerinoEvent,
  getThumbnail,
  mainAddressHandler,
  getAddressObject,
  getAddressFromObjectChecker,
  handleShowProductActions
} from "../../../helper/helper";

import { GAImpressions } from "../../../helper/analytics";
import ApplicationAPI from "../../../_core/application";
import Cart from "../../../_core/cart";
import LoginAPI from "../../../_core/login";
import ProductList from "../../../_core/products";
import LocationPicker from "../../partials/LocationPicker";
import ParallaxComponent from '../../partials/ParallaxComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component'

var login = new LoginAPI();
var cart = new Cart();
var productAPI = new ProductList();
var application = new ApplicationAPI();

const API_URL = process.env.REACT_APP_SERINO_API;
const x_app_key = process.env.REACT_APP_MARKET_APP_ID;

let similar_items_id = [];
let retry_interval = 500;

export default class MarketProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: localStorage.getItem("access_token"),
      cart_items: [],
      product: {},
      address_object: [],
      banner_image: "",
      categoryName: "",
      branch_id: "",
      fromSearch: false,
      fromLandingPage: false,
      viewAllAction: sessionStorage.getItem("viewAllAction"),
      searchKeyword: "",
      isLoggedIn: localStorage.getItem("isLoggedIn")
        ? localStorage.getItem("isLoggedIn")
        : false,
      similar_items: [],
      newKeyword: "",
      triggerGetDefaultAddress: {
        state: false,
        app_key: x_app_key,
      },
      subcategories: localStorage.getItem("categories_display")
        ? JSON.parse(localStorage.getItem("categories_display"))
        : {},
      sub_cat: localStorage.getItem("selected_sub_cat_name")
        ? JSON.parse(localStorage.getItem("selected_sub_cat_name"))
        : "",
      noneSelected: false,
      isCategoryLoading: false,
      isSearchActive: false,
      collection: false
    };
  }
  async componentDidMount() {
    sessionStorage.removeItem("signupPage", true)

    if (this.props.location.search?.length > 0) {
      let search = this.props.location.search.substring(1);
      let searchTest = this.props.location.search.substring(1, 5);

      if (searchTest === "type") {
        let objFromUrl = JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        localStorage.setItem("collection_id", objFromUrl.collection_id);
        localStorage.removeItem("banner_image");

        sessionStorage.setItem("viewAllAction", "bundle");
        localStorage.setItem("auto_tag", objFromUrl.collection_name);
      }

      if (searchTest == "cate") {
        this.setState({collection: true})
      }

      if (searchTest === "sear") {
        let objFromUrl = JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );

        console.log("asdklaskd", objFromUrl.search == "choco");
        console.log("asdklaskd", objFromUrl.search);
        console.log("asdklaskd", search);

        localStorage.removeItem("banner_image");
        sessionStorage.setItem("viewAllAction", "viewSearchResult");
        sessionStorage.setItem("searchKeyWord", objFromUrl.search);
        sessionStorage.setItem("from", "mainlandingsearch");

        this.setState({
          fromSearch: true,
          searchKeyword: objFromUrl.search,
          newKeyword: objFromUrl.search,
        });
      }
      
      if (searchTest === "pdes"){
        let objFromUrl = JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        let product_id = objFromUrl.pdesc;

        if (product_id) {
          application.getProductDetails(x_app_key, product_id).then((res) => {
            this.productDescription(res.data.items[0], x_app_key);
          });
        }
      }
    }

    this.initializecategories()
    $(".dropdown-toggle").dropdown();
    this.headerChange();
    localStorage.setItem("brand_id", process.env.REACT_APP_MARKET_APP_ID);
    localStorage.setItem("brand_name", "Landers Central");
    localStorage.setItem("domain", "sub");

    let getAddressesTries = 0;
    let getTokenTries = 0;

    let access_token_interval = setInterval(async () => {
      let access_token = await getCrossDomainToken();

      getTokenTries++;

      if (access_token || getTokenTries == 5) {
        clearInterval(access_token_interval);

        // log('token from crossdomain') 

        if (access_token && access_token != null) {
          localStorage.setItem("access_token", access_token);
          this.initializeComponents();
        } else {
          access_token = await getAccessToken();
          localStorage.setItem("access_token", access_token);
          setCrossStorage("access_token", access_token, "sub")

          // log('token from setCrossStorage', access_token)
          setTimeout(async () => {
            this.initializeComponents();
          }, 80);
        }

        // login.getClientDetails(localStorage.getItem('access_token'), process.env.REACT_APP_X_APP_KEY)
        // .then((res) => {
        //     let membership = res.data?.metas?.find((x) => x.key == "Rewards" || x.key == "RewardsLinked"); // temporary fix , need to get membershipID
        //     let user_role = res.data && res.data.roles ? res.data.roles[0].name : res.data.role;

        //     if (user_role == "Client") {
        //         localStorage.setItem("isLoggedIn", true);
        //     }
            
        //     if (user_role === "Guest") {
        //         localStorage.removeItem("isLoggedIn");
        //     }
        //     if (membership) {
        //         localStorage.setItem("isMember", true);
        //     } else {
        //     localStorage.removeItem("isMember");
        //     }
        //     console.log("loginGetClien",res)
        // })

        this.setState({ access_token: access_token });
      }
    }, retry_interval);

    let address_object = await getCrossStorage(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`, 'sub')

    setTimeout(() => {
      if (address_object && address_object.length > 0) {
        let parsed = JSON.parse(address_object);
        if (parsed && parsed.length > 0) {
          this.setState({
            address_object: parsed,
          });

          let address = parsed.find(
            (x) => x.brand_id == localStorage.getItem("brand_id")
          );
          if (address && address != null) {
            localStorage.setItem("branch_id", address.branch_id);
            localStorage.setItem("address_object", address_object);
            localStorage.setItem(
              `address_object_${process.env.REACT_APP_MARKET_APP_ID}`,
              address_object
            );
            localStorage.setItem("Address", address.address);
            localStorage.setItem(
              "fulfillment_type_status",
              address.fulfillmentType_status
            );

            document.getElementById("query-market_product_page").value =
              address.address;
            setHeaderAddress(address);
          } else {
            this.setMainAddress();
          }
        } else {
          localStorage.removeItem('branch_id')
          this.setState({
            triggerGetDefaultAddress: {
              state: !this.state.triggerGetDefaultAddress,
              app_key: x_app_key,
            },
          });
        }
      }
    }, 100);

    getSubdomainCartCount();
  }

  initializecategories = () => {

    // log('category initialized')
    this.setState({
      isCategoryLoading: true
    })

    getTemporaryToken().then(access_token => {
      // CATEGORY LIST COMPONENT AND EVENT
      let categoryListSide = window.serino_components.CategoryListComponent.create({
        access_token: access_token,
        activeCategory: localStorage.getItem("collection_id"),
        brand_id: 6,
        branch_id: 6,
        x_app_key: x_app_key,
        mode: 1,
        },"srn-category-list-container-side");
        
        this.userRole()
        let categoryEvents = window.serino_components.CategoryListComponent.events
        // Reuse loaded categories
        document.addEventListener(categoryEvents.onCategoryLoad, (e) => {    
            let categoryList = 
              window.serino_components.CategoryListComponent.create({
                access_token: access_token,
                activeCategory: localStorage.getItem("collection_id"),
                brand_id: 6,
                branch_id: 6,
                x_app_key: x_app_key,
                mode: 1,
                data: e.detail,
            }, "srn-category-list-container");

            this.setState({
                isCategoryLoading: false
            })

        log("e.detail pinpoint", e.detail);

        let search = this.props.location.search.substring(1);
        let objFromUrl = JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );

        console.log({objFromUrl})

        if (objFromUrl.type == "bundle") {
          // log("bundle detected");
        } else {
          this.handlePinpointCatetory(e.detail.categoryTree);
        }
      });
    })
  }

  initializeComponents = async () => {
    if (sessionStorage.getItem("searchKeyWord") != null) {
      this.setState({
        newKeyword: decodeURIComponent(sessionStorage.getItem("searchKeyWord")),
      });
    }
    if (localStorage.getItem("isLoggedIn") == null) {
      let isLoggedIn = await getCrossStorage("isLoggedIn", "sub");
      this.setState({
        isLoggedIn: isLoggedIn,
      });

      localStorage.setItem("isLoggedIn", isLoggedIn);
    }

    let access_token = localStorage.getItem("access_token");

    this.getCart(null, true); // update cart item

    // if (access_token && access_token == null) {
    //     window.location = '/'
    // }
    // let searchProduct =
    //   window.serino_components.SearchProductListComponent.create(
    //     {
    //       access_token: access_token,
    //       x_app_key: x_app_key,
    //       brand_id: 1,
    //       mode: 1,
    //       perPage: 100
    //     },
    //     "srn-search-product-list-container"
    //   );

    // let searchProductSide =
    //   window.serino_components.SearchProductListComponent.create(
    //     {
    //       access_token: access_token,
    //       x_app_key: x_app_key,
    //       categordId: 13,
    //       brand_id: 1,
    //       mode: 1,
    //       perPage: 100
    //     },
    //     "srn-search-product-list-container-side"
    //   );

    this.addSearchEvents()

    let categoryEvents = window.serino_components.CategoryListComponent.events;


    document.addEventListener(
      categoryEvents.category_select,
      (event) => {
        localStorage.setItem(
          "banner_image",
          event.detail.col_cat.banner_img
        );
        localStorage.removeItem("auto_tag");
        // sessionStorage.removeItem('viewAllAction')
        localStorage.setItem(
          "selected_child_category",
          JSON.stringify(event.detail.col)
        );
        localStorage.setItem(
          "selected_sub_cat_name",
          JSON.stringify(event.detail.col_cat.sub_cat)
        );

        let categories_display = {
          category: event.detail.col_cat.collection,
          subcategory: event.detail.col_cat.sub_cat,
          sub_cat_collection_id: event.detail.col_cat.sub_cat_collection_id,
          parent_id: event.detail.col_cat.parent_id,
          children: event.detail.col_cat.children,
          banner_img: event.detail.col.image,
          branch_id: event.detail.col_cat.branch_id,
        };
        localStorage.setItem(
          "categories_display",
          JSON.stringify(categories_display)
        );
        this.setState(
          {
            banner_image: event.detail.col_cat.banner_img,
            sub_cat: event.detail.col_cat.sub_cat,
            subcategories: categories_display,
          },
          () => {
            this.selectCollectionCategory(event.detail.col_cat);
          }
        );
        document.getElementById("sub-cats").style.display = "block";
      }
    );

    document.addEventListener(categoryEvents.collection_select, (e) => {
      localStorage.removeItem("banner_image");
      this.setState({ banner_image: "", categoryName: "" });
      localStorage.setItem("auto_tag", e.detail.name);
      this.selectCollection(e.detail.id);
    });

    document.addEventListener(categoryEvents.onBundleSelect, (e) => {
      window.location = `/market-products?type=bundle&collection_id=${e.detail.id}&collection_name=${e.detail.name}`;
  })

    // PRODUCT LIST COMPONENT AND EVENTS
    let productList = window.serino_components.ProductListComponent.create(
      {
        access_token: access_token,
        activeCategory: "",
        brand_id: 9,
        branch_id: localStorage.getItem("branch_id")
          ? localStorage.getItem("branch_id")
          : 6,
        mode: 1,
        x_app_key: x_app_key,
      },
      "srn-product-list-container"
    );

    let productListComponentSubscription =
      window.serino_components.ProductListComponent.subscriptions;
    let viewAllAction = sessionStorage.getItem("viewAllAction");
    // log({ viewAllAction });
    switch (viewAllAction) {
      case "collectionComponent":
        let onLoadCollectionComponent = new CustomEvent(
          productListComponentSubscription.viewAllProduct,
          {
            bubbles: true,
            detail: {
              ids: localStorage.getItem("app_layer_ids"),
              sku: localStorage.getItem("product_sku"),
              auto_tag: localStorage.getItem("auto_tag"),
              brand_id: localStorage.getItem("branch_id")
                ? localStorage.getItem("branch_id")
                : "",
            },
          },
        );
        document.dispatchEvent(onLoadCollectionComponent);
        break;
      case "collectionMenu":
        let onSelectCollection = new CustomEvent(
          productListComponentSubscription.onSelectCollection,
          {
            bubbles: false,
            detail: {
              id: localStorage.getItem("collection_id"),
              isLoggedIn:
                localStorage.getItem("isLoggedIn") === true ||
                localStorage.getItem("isLoggedIn") === "true"
                  ? "clients"
                  : "guest",
              auto_tag:
                localStorage.getItem("auto_tag") === "88 and Below"
                  ? "p88 & below"
                  : localStorage.getItem("auto_tag"),
            },
          }
        );
        let categoryDescriptionAuto =
          window.serino_components.CategoryDescriptionComponent.create(
            {
              access_token: access_token,
              selected_category: null,
              category_description: localStorage.getItem('auto_tag'),
            },
            "srn-category-description-container"
          );
        document.getElementById("sub-cats").style.display = "none";
        document.dispatchEvent(onSelectCollection);
        break;
      case "bundle":
        let onBundleSelection = new CustomEvent(
          productListComponentSubscription.onBundleSelection,
          {
            bubbles: false,
            detail: {
              id: localStorage.getItem("collection_id"),
            },
          }
        );
        let categoryDescriptionManual =
          window.serino_components.CategoryDescriptionComponent.create(
            {
              access_token: access_token,
              selected_category: null,
              category_description: localStorage.getItem('auto_tag'),
            },
            "srn-category-description-container"
          );
        document.getElementById("sub-cats").style.display = "none";
        document.dispatchEvent(onBundleSelection);
        break;
      case "viewSearchResult":
        let onLoadViewSearchResult = new CustomEvent(
          productListComponentSubscription.onViewSearchResult,
          {
            bubbles: true,
            detail: sessionStorage.getItem("searchKeyWord"),
          }
        );
        document.dispatchEvent(onLoadViewSearchResult);

        this.setState({ fromSearch: true, fromLandingPage: true });
        break;
      default:
        let onLoadProductList = new CustomEvent(
          productListComponentSubscription.onProductList,
          {
            bubbles: true,
            detail: localStorage.getItem("collection_id"),
          }
        );
        document.dispatchEvent(onLoadProductList);

        let catg = JSON.parse(localStorage.getItem("categories_display"))
          ? JSON.parse(localStorage.getItem("categories_display"))
          : null;

        // log("default viewAllAction", catg);

        if (catg && catg != null) {
          let categoryDescription =
            window.serino_components.CategoryDescriptionComponent.create(
              {
                access_token: access_token,
                selected_category: catg.category ? catg.category : null,
                category_description: sessionStorage.getItem("categoryUrl")
                  ? sessionStorage.getItem("categoryUrl")
                  : null,
              },
              "srn-category-description-container"
            );

          document.getElementById("sub-cats").style.display = "block";
            
          console.log("ctg",catg)
          this.setState({
            categoryName: catg.category,
            subcategories: catg,
            fromSearch: false,
          });
        } else {
          // Redirect to Market Landing page if there's no category selected
          // this.handlePinpointCatetory()
        }
    }

    let productListEvent = window.serino_components.ProductListComponent.events;
    document.addEventListener(productListEvent.onAddtoCart, (e) => {
      // log("product list add to cart event called", e.detail.product_details.name );
      this.setState(
        {
          product: e.detail.product_details,
        },
        () => {
          this.addToCart(e.detail.product_details, "list");
        }
      );

      // this.hideDeliverNow()
    });

    document.addEventListener(productListEvent.onDeleteItem, (e) => {
      this.deleteCartItem(e.detail, "list");
    });

    // increment button
    document.addEventListener(productListEvent.onIncrementCart, (e) => {
      let id = e.detail.cartItemId;
      let payload = {
        quantity: e.detail.quantity,
        include_promo: true,
      };
      let cart_items = e.detail.cart_items;
      this.updateCartQuantity(x_app_key, id, payload, cart_items);
    });

    // decrement button
    document.addEventListener(productListEvent.onDecrementCart, (e) => {
      let id = e.detail.cartItemId;
      let payload = {
        quantity: e.detail.quantity,
        include_promo: true,
      };
      let cart_items = e.detail.cart_items;
      this.updateCartQuantity(x_app_key, id, payload, cart_items);
    });

    // manual input on quantity
    document.addEventListener(productListEvent.updateQuantity, (e) => {
      let id = e.detail.detail.id;
      let payload = {
        quantity: e.detail.detail.quantity,
        include_promo: true,
      };
      let cart_items = e.detail.cart_items;
      this.updateCartQuantity(x_app_key, id, payload, cart_items);
    });

    // remove item
    document.addEventListener(productListEvent.onDeleteItem, (e) => {
      let id = e.detail.cartItemId;
      this.removeCartItem(x_app_key, id);
    });

    document.addEventListener(productListEvent.onSelectedProduct, (e) => {
      this.props.history.push(`?pdesc=${e.detail.product_details.id}`);
      this.productDescription(e.detail.product_details, access_token);
      this.setState({ product: e.detail.product_details });
      this.productListSubscription();
    });

    document.addEventListener(productListEvent.onProductsLoad, (e) => {
      GAImpressions(e.detail, 'Market Category Products')
    })

    // PRODUCT DESCRIPTION EVENTS
    let productDescriptionEvent =
      window.serino_components.ProductDescriptionComponent.events;
    document.addEventListener(productDescriptionEvent.addtoCart, (e) => {
      // log("addto caret called");
      this.setState(
        {
          product: e.detail.product_details.id,
        },
        () => {
          this.addToCart(this.state.product);
        }
      );
    });
    document.addEventListener(productDescriptionEvent.onDeleteItem, (e) => {
      let id = e.detail.cartItemId;
      this.removeCartItem(x_app_key, id);
    });
    document.addEventListener(productDescriptionEvent.onDecrementCart, (e) => {
      let app_key = x_app_key;
      let id = e.detail.cartItemId;
      let payload = {
        quantity: e.detail.quantity,
        include_promo: true,
      };
      let cart_items = e.detail.cart_items;
      this.updateCartQuantity(app_key, id, payload, cart_items);
    });

    document.addEventListener(productDescriptionEvent.onIncrementCart, (e) => {
      let app_key = x_app_key;
      let id = e.detail.cartItemId;
      let payload = {
        quantity: e.detail.quantity,
        include_promo: true,
      };
      let cart_items = e.detail.cart_items;
      this.updateCartQuantity(app_key, id, payload, cart_items);
    });

    // manual input quantity
    document.addEventListener(productDescriptionEvent.updateQuantity, (e) => {
      // log("description manual input", e.detail);
      let id = e.detail.detail.id;
      let payload = {
        quantity: e.detail.detail.quantity,
        include_promo: true,
      };
      this.updateCartQuantity(
        x_app_key,
        id,
        payload,
        e.detail.detail.cart_items
      );
    });

    document.addEventListener(
      window.serino_components.cart.events.onLoad("marketCartProducts"),
      (event) => {
        triggerSerinoEvent(
          window.serino_components.ProductDescriptionComponent.subscriptions
            .setButtonLoading,
          false
        );
        // this.getCart(access_token, x_app_key);
      }
    );

    // similar items add to cart
    let similarProductEvent =
      window.serino_components.SimilarProductsComponent.events;
    document.addEventListener(
      similarProductEvent.onAddToCart("market-product-page"),
      (e) => {
        this.setState(
          {
            product: e.detail.product_details,
          },
          () => {
            this.addToCart(e.detail.product_details);
          }
        );
      }
    );

    document.addEventListener(
      similarProductEvent.onGetSimilarItems("market-product-page"),
      (e) => {
        this.setState({ similar_items: e.detail });
      }
    );

    document.addEventListener(
      similarProductEvent.onUpdateIncrement("market-product-page"),
      (e) => {
        let id = e.detail.cartItemId;
        let app_key = x_app_key;
        let payload = {
          quantity: e.detail.quantity,
          include_promo: true,
        };
        let cart_items = e.detail.cart_items;
        this.updateCartQuantity(app_key, id, payload, cart_items);
      }
    );

    document.addEventListener(
      similarProductEvent.onUpdateDecrement("market-product-page"),
      (e) => {
        let id = e.detail.cartItemId;
        let app_key = x_app_key;
        let payload = {
          quantity: e.detail.quantity,
          include_promo: true,
        };
        let cart_items = e.detail.cart_items;
        this.updateCartQuantity(app_key, id, payload, cart_items);
      }
    );

    document.addEventListener(
      similarProductEvent.onDeleteItem("market-product-page"),
      (e) => {
        let app_key = x_app_key;
        let id = e.detail.cartItemId;
        this.removeCartItem(app_key, id);
      }
    );

    document.addEventListener(
      similarProductEvent.updateQuantity("market-product-page"),
      (e) => {
        let id = e.detail.cartItemId;
        let app_key = x_app_key;
        let payload = {
          quantity: e.detail.quantity,
          include_promo: true,
        };
        let cart_items = e.detail.cart_items;
        this.updateCartQuantity(app_key, id, payload, cart_items);
      }
    );

    document.addEventListener(
      similarProductEvent.onShowProductDetails("market-product-page"),
      (e) => {
        handleShowProductActions(e.detail.product_details)
        let brand_id = handleLandersBrandId(
          e.detail.product_details.brand_id,
          e.detail.app_key
        );
        localStorage.setItem("brand_id", brand_id);

        this.setState(
          {
            app_key: e.detail.x_app_key,
            product: e.detail.product_details,
          },
          () => {
            this.props.history.push(`?pdesc=${e.detail.product_details.id}`);
            this.productDescription(e.detail.product_details, access_token);
          }
        );
      }
    );

    $("#descriptionModal").on("hidden.bs.modal", function () {
      similar_items_id = [];
    });

    // fulfillment type event CONFIRM
    let fulfillmentTypeEvent =
      window.serino_components.FulfillmentTypeComponent.events;
    document.addEventListener(fulfillmentTypeEvent.onConfirm, (e) => {
      if (Object.entries(this.state.product).length > 0) {
        let branch_id = e.detail.detail.branch_id;
        this.setState(
          {
            branch_id: branch_id,
          },
          () => {
            this.reloadProductList(branch_id);
            if (branch_id) {
              this.addToCartComponent(this.state.product, branch_id);
            }
          }
        );
      }
    });
  };

  removeCartItem = (app_key, id) => {
    let access_token = localStorage.getItem("access_token");
    cart
      .removeCartItem(access_token, app_key, id, this.state.cart_items)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.getCart(res.items); // update cart item
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateCartQuantity = async (app_key, id, payload, cart_items) => {
    // log(app_key, id, payload);
    let access_token = localStorage.getItem("access_token");
    cart
      .updateQuantity(access_token, app_key, id, payload)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // setTimeout(() => {
          //     this.getCart(access_token, app_key)
          // }, 1500)

          this.productListSubscription();
          this.productDescriptioSubscription();
          this.similarProductScubscription();
          this.updateCartCount(cart_items);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCart = async (cartItems, isInitial = false) => {
      let cart_items = []
      if (isInitial) {
          try {

              let cartResult = await cart.getCartItems(localStorage.getItem('access_token'), x_app_key)

              cart_items = cartResult.data
          }
          catch(cartError) {
              if (cartError.responseJSON.message == 'Invalid Access Token') {
                  handleExpiredToken()
              }
          }
      }
      else {
          cart_items = cartItems
      }

      if (this.state.isSearchActive) {
          this.handleSearchDisplay(true)
      }

      this.setState({
          cart_items: cart_items,
          isAddBtnLoading: false,
          isSearchActive: false,
          product: {}
      }, () => {
          // update cart items in component
          this.productListSubscription();
          this.productDescriptioSubscription();
          this.similarProductScubscription();
          this.updateCartCount(this.state.cart_items);
          triggerSerinoEvent(window.serino_components.SearchProductListComponent.subscriptions.onCartItems, this.state.cart_items)
      })
  }


  updateCartCount = async (cart_items) => {
    
    if(!cart_items) {
        return
    }

    let cartCount = cart_items.reduce((a, b) => +a + +b.quantity, 0);
    let details = {
      market: cartCount,
      eats: localStorage.getItem("eats_cart_count"),
      app: "market",
    };
    setSubdomainCartCount(details);
  };

  productListSubscription = async () => {
    // subscription for cart quantity
    let productListSubscription =
      window.serino_components.ProductListComponent.subscriptions;
    let productListCartItems = new CustomEvent(
      productListSubscription.onCartItems,
      {
        detail: this.state.cart_items,
      }
    );
    await document.dispatchEvent(productListCartItems);
  };

  productDescriptioSubscription = () => {
    console.log("productDescription::", this.state.cart_items);
    // subscription for cart quantity
    let productDescriptionSubscription =
      window.serino_components.ProductDescriptionComponent.subscriptions;
    let productDescriptionItems = new CustomEvent(
      productDescriptionSubscription.onCartItems,
      {
        detail: this.state.cart_items,
      }
    );
    document.dispatchEvent(productDescriptionItems);
  };

  similarProductScubscription = () => {
    let similarProductScubscription =
      window.serino_components.SimilarProductsComponent.subscriptions;
    let similarProductItems = new CustomEvent(
      similarProductScubscription.onCartItems("market-product-page"),
      {
        detail: this.cart_items,
      }
    );
    document.dispatchEvent(similarProductItems);
  };

  selectCollectionCategory = (selected) => {
    getCrossDomainToken().then((access_token) => {
      let categoryDescription =
        window.serino_components.CategoryDescriptionComponent.create(
          {
            access_token: access_token,
            selected_category: selected.collection,
            category_description: selected.sub_cat,
          },
          "srn-category-description-container"
        );

      this.setState({
        categoryName: selected.collection,
        sub_cat: selected.sub_cat,
      });

      localStorage.setItem("collection_id", selected.col_id);

      let productListComponentSubscription =
        window.serino_components.ProductListComponent.subscriptions;
      let onLoadProductList = new CustomEvent(
        productListComponentSubscription.onProductList,
        {
          bubbles: true,
          detail: selected.col_id,
        }
      );
      document.dispatchEvent(onLoadProductList);
    });
  };

  selectCollection = (collection_id) => {
    localStorage.setItem("collection_id", collection_id);
    localStorage.removeItem("banner_image");
    sessionStorage.setItem("viewAllAction", "collectionMenu"); //view from collection menu
    window.location = "/market-products";

    // let productListComponentSubscription = window.serino_components.ProductListComponent.subscriptions
    // let onLoadProductList = new CustomEvent(productListComponentSubscription.collectionSelect, {
    //     bubbles: true,
    //     detail: collection_id
    // })
    // document.dispatchEvent(onLoadProductList)
  };

  updateCartItem = (item, type) => {
    if (type == "list") {
      let payload = [
        {
          itemReferenceType: item.payload.item_type,
          itemReferenceId: item.payload.item_ref_id,
          itemName: item.payload.item_ref_name,
          itemDescription: item.payload.item_ref_desc,
          quantity: item.payload.quantity,
          price: item.payload.price,
          itemImage: item.payload.item_ref_img,
        },
      ];
      let productListComponentSubscription =
        window.serino_components.ProductListComponent.subscriptions;
      let incrementCartItemEvent = new CustomEvent(
        productListComponentSubscription.onCartItems,
        {
          bubbles: true,
          detail: payload,
        }
      );
      document.dispatchEvent(incrementCartItemEvent);
    } else if (type == "desc") {
      let payload = [
        {
          itemReferenceType: item.type,
          itemReferenceId: item.id,
          itemName: item.name,
          itemDescription: item.desc,
          quantity: 1,
          price: item.price,
          itemImage: item.img,
          branch_id: localStorage.getItem("branch_id"),
        },
      ];
      let productDescriptionSubscription =
        window.serino_components.ProductDescriptionComponent.subscriptions;
      let addNewCartItemEvent = new CustomEvent(
        productDescriptionSubscription.onCartItems,
        {
          bubbles: true,
          detail: payload,
        }
      );
      document.dispatchEvent(addNewCartItemEvent);
    } else {
      let payload = [
        {
          itemReferenceType: item.payload.item_type,
          itemReferenceId: item.payload.item_ref_id,
          itemName: item.payload.item_ref_name,
          itemDescription: item.payload.item_ref_desc,
          quantity: item.payload.quantity,
          price: item.payload.price,
          itemImage: item.payload.item_ref_img,
        },
      ];
      let productTagsSubscription =
        window.serino_components.ProductTagsComponent.subscriptions;
      let incrementCartItemEvent = new CustomEvent(
        productTagsSubscription.onCartItems,
        {
          bubbles: true,
          detail: payload,
        }
      );
      document.dispatchEvent(incrementCartItemEvent);
    }
  };

  deleteCartItem = (item, type) => {
    if (type == "collection") {
      let payload = [
        {
          itemReferenceId: item.item_ref_id,
          itemName: item.item_ref_name,
        },
      ];
      let productTagsSubscription =
        window.serino_components.ProductTagsComponent.subscriptions;
      let deleteCartItemEvent = new CustomEvent(
        productTagsSubscription.onCartItems,
        {
          bubbles: true,
          detail: payload,
        }
      );
      document.dispatchEvent(deleteCartItemEvent);
    } else if (type == "desc") {
      let payload = [
        {
          itemReferenceId: item.id,
          itemName: item.name,
        },
      ];
      let productDescriptionSubscription =
        window.serino_components.ProductDescriptionComponent.subscriptions;
      let addNewCartItemEvent = new CustomEvent(
        productDescriptionSubscription.onCartItems,
        {
          bubbles: true,
          detail: payload,
        }
      );
      document.dispatchEvent(addNewCartItemEvent);
    } else {
      let payload = [
        {
          itemReferenceId: item.item_ref_id,
          itemName: item.item_ref_name,
        },
      ];
      let productlistSubscription =
        window.serino_components.ProductListComponent.subscriptions;
      let deleteCartItemEvent = new CustomEvent(
        productlistSubscription.onCartItems,
        {
          bubbles: true,
          detail: payload,
        }
      );
      document.dispatchEvent(deleteCartItemEvent);
    }
  };

  productDescription = (detail, access_token) => {
    $("#descriptionModal").modal("show");
    // log("detail", detail);
    similar_items_id.push(detail.id); // product id for similar item

    let productDescription =
      window.serino_components.ProductDescriptionComponent.create(
        {
          product: detail,
          currency: "₱",
          mode: 1,
          access_token: access_token,
        },
        "srn-product-description-container"
      );

    let similarProductScubscription =
      window.serino_components.SimilarProductsComponent.subscriptions;
    let initSimilarProducts = new CustomEvent(
      similarProductScubscription.onLoad("market-product-page"),
      {
        detail: similar_items_id,
      }
    );
    document.dispatchEvent(initSimilarProducts);

    let similarProduct =
      window.serino_components.SimilarProductsComponent.create(
        {
          access_token: access_token,
          x_app_key: x_app_key,
          branch_id: localStorage.getItem("branch_id")
            ? localStorage.getItem("branch_id")
            : 6,
          time_interval: 8000,
          currency: "₱",
          product_ids: similar_items_id,
          id: "market-product-page",
        },
        "srn-similar-product-container"
      );

    // this.getCart(access_token, x_app_key)
    this.productDescriptioSubscription();
  };

  handleCartDuplicate = (product) => {
    return (
      this.state.cart_items.filter((x) => x.itemReferenceId == product.id)
        .length > 0
    );
  };

  addToCart = async (product) => {
    log('addToCart triggered', product)
    let hasDuplicate = this.handleCartDuplicate(product);

    if (hasDuplicate) {
      return;
    }

    // log("addToCart", product);

    triggerSerinoEvent(
      window.serino_components.ProductDescriptionComponent.subscriptions
        .setButtonLoading,
      product.id
    );

    const proceed = async (branch_id) => {
      let price;
      let price_object = {};
      let custom_data = [];

      let member = product.product_prices.find((p) => p.name === "member");
      let nonmember = product.product_prices.find(
        (p) => p.name === "nonmember"
      );

      if (membershipChecker()) {
          price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
          price_object = member
      } else {
          price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
          price_object = nonmember
      }

      let savings = { name: "savings", value: nonmember.price - member.price };

      if (product.sell_by_type && product.sell_by_type == 1) {
          custom_data.push({
              name: 'weight',
              value: product.weight
          })
      }

      if (product.barcode && product.barcode.length > 0) {
          custom_data.push({
              name: 'barcode',
              value: product.barcode
          })   
      }

      custom_data.push(savings);
      custom_data.push(price_object);
      
      // SAVE ADDRESS IN CART START
      if (getAddressFromObjectChecker) {
          let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

          if (!address) {
              triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
          }

          custom_data.push({
              name: 'address',
              value: address
          })
      }
      // SAVE ADDRESS IN CART 
      
      let payload = {
        itemReferenceId: product.id,
        itemReferenceType: product.type,
        itemName: product.name,
        itemSKU: product.sku,
        itemImage: getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
        itemDescription: product.description,
        quantity: product.quantity && product.quantity > 0 ? product.quantity : 1,
        basePrice: nonmember.price,
        price: price,
        instructions: "",
        domainReferenceId: branch_id,
        domainReferenceType: "1",
        item_type: product.sell_by_type ? product.sell_by_type : 0,
        include_promo: true,
      };

      if (custom_data && custom_data.length > 0) {
        payload.custom_data = JSON.stringify(custom_data);
      }

      try {
        const addCartItemResult = await cart.addCartItem(
          localStorage.getItem("access_token"),
          x_app_key,
          payload
        );

        setTimeout(() => {
          this.getCart(addCartItemResult.items);
        }, 100);
      }
      catch(err) {
        if (err.responseJSON.message == 'Invalid Access Token') {
            handleExpiredToken()
        }
      }
    };

    if (localStorage.getItem("branch_id")) {
      proceed(localStorage.getItem("branch_id"));
    } else {
      let localAddressObject = localStorage.getItem(
        `address_object_${x_app_key}`
      );
      let address_object = [];
      if (localAddressObject && localAddressObject.length > 0) {
        address_object = JSON.parse(localAddressObject);
      }
      if (address_object && address_object.length > 0) {
        let address = address_object.find(
          (x) => x.brand_id == localStorage.getItem("brand_id")
        );
        // log("product", this.state.product.brand_id);
        if (address) {
          if (address.branch_id && address.branch_id != null) {
            // log("address", address.brand_id);
            localStorage.setItem("branch_id", address.branch_id);
            proceed(address.branch_id);
          } else {
            this.handleMainAddress();
          }
        } else {
          this.handleMainAddress();
        }
      } else {
        this.handleMainAddress();
      }
    }
  };

  handleMainAddress = (type = "addtocart") => {
    this.setState({ isAddBtnLoading: false });
    mainAddressHandler()
  };

  reloadProductList = (branch_id) => {
    // PRODUCT LIST COMPONENT AND EVENTS
    let productList = window.serino_components.ProductListComponent.create(
      {
        access_token: localStorage.getItem('access_token'),
        activeCategory: "",
        brand_id: 1,
        branch_id: localStorage.getItem("branch_id")
          ? localStorage.getItem("branch_id")
          : 6,
        mode: 1,
        x_app_key: x_app_key,
      },
      "srn-product-list-container"
    );
    
    setTimeout(() => {
      if (sessionStorage.getItem('viewAllAction') === 'bundle') {
        triggerSerinoEvent(window.serino_components.ProductListComponent.subscriptions.onBundleSelection, {
          id: localStorage.getItem("collection_id"),
          branch_id: branch_id
        })
      }
      else {
        triggerSerinoEvent(window.serino_components.ProductListComponent.subscriptions.reloadCategoryProducts, {
          category_id: localStorage.getItem("collection_id"),
          branch_id: branch_id
        })
      }
    }, 100)

  };

  addToCartComponent = async (product, branch_id) => {
    // log("addToCartComponent", product);
    
    if (this.handleCartDuplicate(product)) {
      return;
    }


    triggerSerinoEvent(
      window.serino_components.ProductDescriptionComponent.subscriptions
        .setButtonLoading,
      product.id
    );

    let price;
    let price_object = {};
    let custom_data = [];

    let member = product.product_prices.find((p) => p.name === "member");
    let nonmember = product.product_prices.find((p) => p.name === "nonmember");

    if (membershipChecker()) {
        price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
        price_object = member
    } else {
        price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
        price_object = nonmember
    }
    
    if (product.sell_by_type && product.sell_by_type == 1) {
        custom_data.push({
            name: 'weight',
            value: product.weight
        })
    }

    if (product.barcode && product.barcode.length > 0) {
        custom_data.push({
            name: 'barcode',
            value: product.barcode
        })   
    }
    
    let savings = { name: "savings", value: nonmember.price - member.price };
    custom_data.push(savings);
    custom_data.push(price_object);
    
    // SAVE ADDRESS IN CART START
    if (getAddressFromObjectChecker) {
        let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

        if (!address) {
            triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
        }

        custom_data.push({
            name: 'address',
            value: address
        })
    }
    // SAVE ADDRESS IN CART 
    
    let payload = {
      itemReferenceId: product.id,
      itemReferenceType: product.type,
      itemName: product.name,
      itemSKU: product.sku,
      itemImage: getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
      itemDescription: product.description,
      quantity: product.quantity && product.quantity > 0 ? product.quantity : 1,
      basePrice: nonmember.price,
      price: price,
      instructions: "",
      domainReferenceId: branch_id
        ? branch_id
        : localStorage.getItem("branch_id"),
      domainReferenceType: "1",
      item_type: product.sell_by_type ? product.sell_by_type : 0,
      include_promo: true,
    };

    if (custom_data && custom_data.length > 0) {
      payload.custom_data = JSON.stringify(custom_data);
    }

    try {
      const addCartItemResult = await cart.addCartItem(
        localStorage.getItem("access_token"),
        x_app_key,
        payload
      );
      setTimeout(() => {
        this.getCart(addCartItemResult.items);
      }, 100);
    }
    catch(err) {
      if (err.responseJSON.message == 'Invalid Access Token') {
          handleExpiredToken()
      }
    }

  };

  userRole = async () => {
    // let isLoggedIn = localStorage.getItem('isLoggedIn')
    let isLoggedIn = "false"

    let categoryListSubscription =
      window.serino_components.CategoryListComponent.subscriptions;
    let categoryUserRole = new CustomEvent(categoryListSubscription.userRole, {
      detail: isLoggedIn,
    });
    document.dispatchEvent(categoryUserRole);
  };

  showSideCategories = () => {
    document.getElementById("side-categ").style.width = "250px";
    document.getElementById("side-categ").style.padding = "20px 10px";
    document.getElementById('side-categ-backdrop').style.display = "block"

    // accordion for side category
    // var accordion = document.getElementsByClassName("cmb-collection-sub-items")
    // var i;
    // for ( i = 0; i < accordion.length; i++) {
    //     accordion[i].addEventListener("click", function() {
    //         this.childNodes[1].classList.toggle('active-categ')
    //         var sub_categ = this.childNodes[1].children
    //         for (var x = 0; x < sub_categ.length; x++) {
    //             if (sub_categ[x].style.display === "block") {
    //                 sub_categ[x].style.display = "none"
    //             } else {
    //                 sub_categ[x].style.display = "block"
    //             }
    //         }
    //     })
    // }
  }

  closeSideNav = () => {
    document.getElementById("side-categ").style.width = "0";
    document.getElementById("side-categ").style.padding = "0";
    document.getElementById("side-categ-backdrop").style.display = "none";
  };

  hideDeliverNow = () => {
    var host = window.location.pathname

    if (
      host.includes('market')
    ) {
      $(".srn-ft-proceed-btn").click(() => {
        setTimeout(() => {
          let deliverNow = document.getElementsByClassName(
            "srn-fulfillmenttype-deliver-now"
          );
          if (deliverNow && deliverNow.length > 0) {
            deliverNow[0].style.display = "none";
          }
        }, 300);
      });
    }
  };
  handleLocationChange = (address_object, branch_id) => {
    this.setState(
      {
        address_object,
        cart_items: [],
        isAddBtnLoading: false,
      },
      () => {
        // update cart items in component
        let address = address_object.find(
          (x) => x.brand_id == localStorage.getItem("brand_id")
        );
        this.reloadProductList(address.branch_id)

        this.productListSubscription();
        this.productDescriptioSubscription();
        this.similarProductScubscription();
        this.updateCartCount([])
        if (this.state.product && this.state.product.id) {
          this.handleInitialAddToCart(this.state.product)
        }
      }
    );
  };

  getAddressObject = (address_object) => {
    this.setState({ address_object });
  };

  headerChange = () => {
    $(".borderTop").addClass("green");
  };

  handleBackFromSearch = (domain) => {
    if (this.state.fromLandingPage === true) {
      window.location.href = getUrl(domain);
    } else {
      setTimeout(() => {
        this.setState({ fromSearch: false });
        let productListComponentSubscription =
          window.serino_components.ProductListComponent.subscriptions;
        let onLoadCollectionMenu = new CustomEvent(
          productListComponentSubscription.onProductList,
          {
            bubbles: true,
            detail: localStorage.getItem("collection_id"),
          }
        );
        document.dispatchEvent(onLoadCollectionMenu);
      }, 1000);
    }
  };

  handleModalClose = () => {
    this.productListSubscription();
    this.productDescriptioSubscription();
    this.similarProductScubscription();
  };

  handleClearAddress = () => {
    this.setState(
      {
        cart_items: [],
      },
      () => {
        // this.getCart(localStorage.getItem("access_token"), x_app_key);
        this.productListSubscription();
        this.productDescriptioSubscription();
        this.similarProductScubscription();
      }
    );
  };

  handleSelectCollectionCategory = (selected) => {
    log("selected", selected);
    localStorage.setItem("banner_image", selected.banner_img);
    localStorage.removeItem("auto_tag");
    sessionStorage.removeItem("viewAllAction");
    localStorage.setItem(
      "selected_sub_cat_name",
      JSON.stringify(selected.sub_cat)
    );
    this.setState(
      {
        banner_image: selected.banner_img,
        sub_cat: selected.sub_cat,
      },
      () => {
        this.selectCollectionCategory(selected);
      }
    );
  };

  setMainAddress = async () => {
    let main_address = "";
    let getMainAddressTries = 0;

    let getMainAddressesInterval = setInterval(async () => {
      let main_address = await getCrossStorage("main_address", "sub");

      getMainAddressTries++;

      if (
        (main_address || getMainAddressTries == 3) &&
        main_address != undefined &&
        main_address !== "0"
      ) {
        clearInterval(getMainAddressesInterval);

        if (main_address && main_address.length > 0) {
          localStorage.setItem("main_address", main_address);

          let parsed = JSON.parse(main_address);

          localStorage.setItem("Address", parsed.address);

          if (document.getElementById("query-market_product_page")) {
            document.getElementById("query-market_product_page").text =
              parsed.address;
          }

          setHeaderAddress(parsed);

          this.handleMainAddress("initial");
        }
      }
    });
  };

  handleScroll = (direction, elementId) => {
    let element = document.getElementById(elementId);
    let parentWidth = element.clientWidth;

    if (direction == 1) {
      element.scrollLeft = element.scrollLeft + parentWidth;
    }
    if (direction == 0) {
      element.scrollLeft = element.scrollLeft - parentWidth;
    }
  };

  handlePinpointCatetory = (categ_tree) => {
    // log('handlePinpointCatetory started.............')
    let catg = JSON.parse(localStorage.getItem("categories_display"))
      ? JSON.parse(localStorage.getItem("categories_display"))
      : null;
    // log({ catg });

    // if (catg == null) {

    // let categ_tree = sessionStorage.getItem('categ_tree') ? sessionStorage.getItem('categ_tree') : null
    let categ_name = this.props.location.search.replace(/_/g, " ");
    if (categ_name.charAt(0) === "?") {
      categ_name = categ_name.slice(1);
    }
    // log({categ_name})
    // log({categ_tree})
    if (categ_tree != null && categ_name && categ_name.length > 0) {
      let selectedCategory = categ_tree.find((x) => x.name === categ_name);
      let matches = [];
      let parent_category = null;
      console.log(
        selectedCategory?.id !== localStorage.getItem("collection_id")
      );
      if (
        selectedCategory === undefined ||
        selectedCategory?.id !== localStorage.getItem("collection_id")
      ) {
        // let selectedCategory = categ_tree.flatMap(cg => cg.child).find(c => c.name === categ_name);
        for (let i = 0; i < categ_tree.length; i++) {
          matches = matches.concat(
            categ_tree[i].child.filter((c) => c.name === categ_name)
          );

          if (matches.length > 0) {
            parent_category = categ_tree[i];
            break;
          }
        }

        if (matches.length > 0) {
          selectedCategory = matches[0];
        } else if (selectedCategory.parent_id == 0) {
          parent_category = selectedCategory;
        } else {
          this.setState({ noneSelected: true });
        }

        // log({ matches });
        // log({ selectedCategory });
        // log({ parent_category });

        let categoryObj = {
          col_id: selectedCategory.id,
          collection: parent_category.name,
          sub_cat: selectedCategory.name,
          sub_cat_collection_id: selectedCategory.id,
          parent_id: selectedCategory.parent_id,
          banner_img: parent_category.image,
          branch_id: selectedCategory.branch_id,
          children: parent_category.child,
        };
        // log({ categoryObj });
        // log(parent_category.col_id);
        localStorage.setItem("category_branch_id", parent_category.branch_id);
        localStorage.setItem("collection_id", parent_category.col_id);
        localStorage.setItem("banner_image", parent_category.banner_img);
        sessionStorage.setItem("viewAllAction", "categoryMenu");
        localStorage.setItem("categories_display", JSON.stringify(categoryObj));
        localStorage.setItem(
          "selected_sub_cat_name",
          JSON.stringify(parent_category.name)
        );

        this.setState(
          {
            subcategories: categoryObj,
          },
          () => {
            // log("after sub", this.state.subcategories.children);
          }
        );

        this.handleSelectCollectionCategory(categoryObj);
        document.getElementById("sub-cats").style.display = "block";
      }
    } else {
      this.setState({ noneSelected: true });
    }

    // }
  };

  addSearchEvents = () => {

    log('addSearchEvents triggered')

    // search component event
    let searchEvents =
      window.serino_components.SearchProductListComponent.events;
    // document.addEventListener(searchEvents.onViewAllResult, (e) => {
    //   this.setState({
    //     fromSearch: true,
    //     searchKeyword: e.detail.searchKeyword,
    //     newKeyword: e.detail.searchKeyword,
    //   });
    //   let productListComponentSubscription =
    //     window.serino_components.ProductListComponent.subscriptions;
    //   let onLoadViewSearchResult = new CustomEvent(
    //     productListComponentSubscription.onViewSearchResult,
    //     {
    //       bubbles: true,
    //       detail: e.detail.searchKeyword,
    //     }
    //   );
    //   document.dispatchEvent(onLoadViewSearchResult);
    // });

    document.addEventListener(searchEvents.onAddToCart, (e) => {
      this.handleSearchDisplay(true)
      log('searchEvents.onAddToCart')
      this.setState({ product: e.detail, isSearchActive: true }, () => {
        setTimeout(() => this.handleSearchDisplay(true), 100)        
        this.addToCart(e.detail);
      });
    });

    document.addEventListener(searchEvents.onSelect, (e) => {
        this.productDescription(e.detail.product, x_app_key)
        document.getElementById('srn-product-search-result-container').style.display = 'none'
    })

    // document.addEventListener(searchEvents.onViewAllResult, (e) => {
    //     console.log(e.detail)
    //     localStorage.removeItem('banner_image')
    //     sessionStorage.setItem('viewAllAction', 'viewSearchResult')
    //     sessionStorage.setItem('searchKeyWord', e.detail.searchKeyword)
    //     window.location = '/market-products'
    // })

    // delete item event
    document.addEventListener(searchEvents.onDeleteItem, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.detail.cartItemEntity;
        this.removeCartItem(x_app_key, id)
    });

    // manual input quantity
    document.addEventListener(searchEvents.updateQuantity, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.detail.id;
        let payload = {
            quantity: e.detail.detail.quantity,
            include_promo: true,
        };
        this.updateCartQuantity(x_app_key, id, payload)
        log('e.detail', e.detail.payload.cart_items)
        this.updateCartCount(e.detail.payload.cart_items)
    });

    // increment button
    document.addEventListener(searchEvents.onUpdateIncrement, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.payload.cartItemId;
        let payload = {
            quantity: e.detail.payload.quantity,
            include_promo: true,
        };
        this.updateCartQuantity(x_app_key, id, payload)
        log('e.detail', e.detail.payload.cart_items)
        this.updateCartCount(e.detail.payload.cart_items)
    });

    // decrement button
    document.addEventListener(searchEvents.onUpdateDecrement, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.payload.cartItemId;
        let payload = {
            quantity: e.detail.payload.quantity,
            include_promo: true,
        };
        this.updateCartQuantity(x_app_key, id, payload)
        log('e.detail', e.detail.payload.cart_items)
        this.updateCartCount(e.detail.payload.cart_items)
    });
  }

  handleSearchDisplay = (bool) => {
    if (document.getElementById('srn-product-search-result-container')) {
        document.getElementById('srn-product-search-result-container').style.display = 'block'
    }
  }

  removePreviousListeners = () => {
    let searchEvents = window.serino_components.SearchProductListComponent.events
    Object.keys(searchEvents).forEach(evt => {
        log('evt', evt)
        document.removeEventListener(evt, () => {
            console.log(evt)
        })
    })
  }

  handleInitialAddToCart = async (selected_product) => {

    let sku = selected_product.sku

    if (sku) {
      if (this.state.product && this.state.product.id) {
        let getProductResult = await productAPI.getInventoryProduct(sku, localStorage.getItem('branch_id'), x_app_key)
        
        if (getProductResult.data && getProductResult.data.data && getProductResult.data.data.items) {
          let transformedProducts = this.handlePriceAdjustments(getProductResult.data.data.items)
          
          transformedProducts = this.getFilteredProductsByStock(transformedProducts)
          
          if(transformedProducts && transformedProducts.length > 0) {        
            let product = transformedProducts.find(x => x.sku === sku)
            if (product) {
              
              this.addToCart(product)
            }
          }
        }
      }
    }
  }

  handlePriceAdjustments = (products) => {
      let mutated = products.map(product => {
          
          if (product.sell_by_type == '1') {

              let newProductPrices = product.product_prices.map(price_item => {

                  let price = parseFloat(price_item.price) * parseFloat(product.weight)
                  let original_price = parseFloat(price_item.original_price) * parseFloat(product.weight)
                  
                  let price_rounded = Math.round(price.toFixed(3) * 100) / 100
                  let original_price_rounded = Math.round(original_price.toFixed(3) * 100) / 100

                  price_item.price = price_rounded
                  price_item.original_price = original_price_rounded

                  return price_item
              })

              product.product_prices = newProductPrices
          }

          return product
      })

      return mutated
  }

  getFilteredProductsByStock = (products) => {

      let filteredByStocks = products.filter(product => product.inventory && product.inventory.length > 0 && product.inventory[0].stock && product.inventory[0].stock.length > 0 && product.inventory[0]?.threshold && product.inventory[0]?.threshold[0]?.value)

      filteredByStocks = filteredByStocks.filter(product => parseInt(product.inventory[0]?.stock) > parseInt(product.inventory[0]?.threshold[0]?.value))

      return filteredByStocks
  }

  viewCategories = () => {
    document.getElementById("more-categ").click()
  }

  handlePageRedirect = () => {
    window.location = '/market'
  }

  getbannerMargin = () => {
    if (document.body.clientWidth < 800) {
      let space = $('#market-container').css('margin-top')
      log({space})
      return 0
    }
  }

  render() {
    let bannerCategory = localStorage.getItem("banner_image")
      ? localStorage.getItem("banner_image")
      : this.state.banner_image;
    return (
      <div className="d-flex flex-column h-100" id="marketprodcat">
        {this.state.access_token && (
          <LocationPicker
            access_token={this.state.access_token}
            id="market_product_page"
            x_app_key={x_app_key}
            // getAddressObject={this.getAddressObject}
            brand_id={1}
            getAddressObject={this.getAddressObject}
            app_key={process.env.REACT_APP_MARKET_APP_ID}
            handleLocationChange={this.handleLocationChange}
            handleModalClose={this.handleModalClose}
            handleClearAddress={this.handleClearAddress}
            cart_items={this.state.cart_items}
            triggerGetDefaultAddress={this.state.triggerGetDefaultAddress}
            hidden={true}
          />
        )}
        <div
          className="container main-landing-container market-prod-container market-prod-container-style2"
          id="market-container"
        >
                <div
                  className={`market-category-banner-container ${
                    this.state.fromSearch == true ? "d-none" : ""
                  }`}
                  style={{
                    marginTop: this.getbannerMargin()
                  }}
                >
                  <img src={bannerCategory} className="market-prod-banner-img" />
                </div>
          {/* SIDE CATEGORY LIST ON SMALL SCREEN */}

          <div className="categ-search-section">
            <button
              className="btn-categ"
              onClick={() => this.showSideCategories()}
            >
              Categories and Collection
            </button>
            <div
              className={`location-picker-container srn-search-container srn-location-picker-mobile`}
            >
              <div id="srn-search-product-list-container"></div>
            </div>
          </div>

          <div id="side-categ" className="sidenav side-categ">
            <h6>Select Categories</h6>
              {
                  this.state.isCategoryLoading &&
                  <div className="srn-category-loading-container">
                      <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
              }
            <div
              id="srn-category-list-container"
              className="side-category-list"
            ></div>
            {/* <h6>Select Collection</h6> */}
          </div>

          <div
            id="side-categ-backdrop"
            className="side-menu-backdrop side-menu-backdrop-transition"
            onClick={() => this.closeSideNav()}
          ></div>

          {/* side category */}
          <div className="row no-gutters">
            <div
              className={`d-none col col-md-2 side-container catfixed  ${
                this.state.fromSearch === true ? "d-none" : ""
              }`}
            >
              <div className="row search-item-contrainer">
                <div
                  id="srn-search-product-list-container-side"
                  className="d-none"
                ></div>
              </div>
              <h6>Select Categories</h6>
              {
                  this.state.isCategoryLoading &&
                  <div className="srn-category-loading-container">
                      <FontAwesomeIcon icon={faSpinner} spin />
                  </div>
              }

              <div
                id="srn-category-list-container-side"
                className="side-category-list"
              ></div>
            {/* <ParallaxComponent page="market-product" /> */}
            </div>
            {
              // <div className={`col col-md-8 col-sm-12`}>
              //     <p>Please select category.</p>
              // </div>
            }
            <div className={`col col-md-12 col-sm-12`}>
              <div className="row no-gutters mb-20">
                {/* <div className="what-new-items-container">
                                    <div id="srn-product-whats-new-container" className="srn-product-whats-new-component-container"></div>
                                </div> */}
              </div>
              {/* <div className={`srn-category-title-mobile-container ${this.state.fromSearch === true || this.state.viewAllAction == 'collectionMenu' ? 'd-none' : " "}`}>
                                <h6 className="srn-category-title-mobile">{this.state.categoryName}</h6>                                
                                <a href="#!" className="srn-category-mobile-view-all" onClick={() => this.showSideCategories()}>
                                <a href="#!" className="srn-category-mobile-view-all">
                                    <span>view all</span>
                                </a>
                            </div> */}
              {
                (sessionStorage.getItem('viewAllAction') == 'bundle') || (sessionStorage.getItem('viewAllAction') == 'collectionMenu') ?
                <h6 className="sub-cat-header" onClick={() => this.handlePageRedirect()}> ← Back</h6> : null
              }
              <div
                className={`srn-sub-category-list-container ${
                  this.state.fromSearch === true ? "d-none" : " "
                }`}
                id="sub-cats"
              >
                <h6 className="sub-cat-header" onClick={() => this.viewCategories()}> ← VIEW CATEGORIES</h6>
                <div
                  className="srn-sub-category-list"
                  id="srn-sub-category-list"
                >
                  {this.state.subcategories?.children?.map((child) => (
                    <div
                      className={`srn-sub-category-item ${
                        child.name == this.state.sub_cat
                          ? "srn-sub-category-item-active"
                          : ""
                      }`}
                      onClick={() =>
                        this.handleSelectCollectionCategory({
                          col_id: child.id,
                          collection: this.state.subcategories.category,
                          sub_cat: child.name,
                          sub_cat_collection_id: child.id,
                          parent_id: this.state.subcategories.parent_id,
                          banner_img: this.state.subcategories.banner_img,
                          branch_id: child.branch_id,
                          children: this.state.subcategories.children,
                        })
                      }
                    >
                      <div className="srn-sub-category-image-container">
                        {
                          child.image && child.image != null &&
                          <img
                            src={
                              child.image && child.image != null
                                ? child.image
                                : null
                            }
                            className="srn-sub-category-image"
                          />
                        }
                      </div>
                      <div className="srn-sub-category-details">
                        <p className="srn-sub-category-name">{child.name}</p>
                      </div>
                    </div>
                  ))}

                  {/* {
                                        this.state.collection_component_id && this.state.collection_component_id.map((id, x) => {
                                            return (
                                                <div id={id}></div>
                                            )
                                        })
                                    } */}
                </div>
                <a
                  onClick={() => this.handleScroll(0, "srn-sub-category-list")}
                  id={`srn-sub-category-list-arrow-left`}
                  href="#!"
                  className={`srn-sub-category-list-arrow srn-sub-category-list-arrow-left `}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </a>
                <a
                  onClick={() => this.handleScroll(1, "srn-sub-category-list")}
                  id="srn-sub-category-list-arrow-right"
                  href="#!"
                  className={`srn-sub-category-list-arrow srn-sub-category-list-arrow-right`}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </a>
              </div>
              {/* <img
                src={marketBannerMobile}
                className="srn-market-mobile-banner"
              /> */}
              <div className="srn-product-filter-container d-none">
                <div className="srn-product-filter-content">
                  <div className="srn-product-filter-list">
                    <a
                      href="#!"
                      className="srn-product-filter-item srn-product-filter-item-active"
                    >
                      All
                    </a>
                    <a href="#!" className="srn-product-filter-item">
                      New
                    </a>
                    <a href="#!" className="srn-product-filter-item">
                      Sale
                    </a>
                  </div>
                  <div className="srn-product-filter-dropdown">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Dropdown button
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`row category-description-container ${
                  this.state.fromSearch == true ? "d-none" : ""
                }`}
              >
                <div className="col-md-12">
                  <div
                    id="srn-category-description-container"
                    className="srn-category-description-component-containerasdasd text-left"
                  ><p className="sub-category-title">{localStorage.getItem("auto_tag")}</p></div>
                </div>
              </div>
              {/* {this.state.fromSearch && this.state.fromSearch === true ? (
                <a
                  className="back-btn"
                  onClick={() => this.handleBackFromSearch("market")}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Back
                </a>
              ) : (
                ""
              )} */}
              <div className="row no-gutters mb-20 search-label-container">
                <h3
                  className={`search-result-label ${
                    this.state.fromSearch === true ? "" : "d-none"
                  }`}
                >
                  Search results
                  <span className="search-keyword">
                    "
                    {
                      (this.state.newKeyword = decodeURIComponent(
                        this.state.newKeyword
                      ))
                    }
                    "
                  </span>
                </h3>
                <div id="srn-product-list-container" className={`srn-product-list-market ${this.state.collection == true ? "market-collection" : "category"}`}></div>
              </div>
            </div>
            <div
              className={`col-md-2 col-sm-12 market-landing-ads ${
                this.state.fromSearch === true ? "d-none" : " "
              }`}
            >
              {/* <div class="sticky-top">
                <img className="srn-ad-img mb-4" src={marketsidebanner1} />
                <img className="srn-ad-img" src={marketsidebanner3} />
              </div> */}
            </div>
          </div>
          <div
            className="modal srn-product-description-market"
            id="descriptionModal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header srn-ft-modal-header">
                  <img src={MarketLogo} className="srn-ft-brand-logo" />
                  {/* <img src={brand_logo} className="srn-ft-brand-logo" /> */}
                  <button type="button" className="close d-block" data-dismiss="modal" aria-label="Close">
                      <span >&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div
                    id="srn-product-description-container"
                    className="srn-product-description-market"
                  ></div>
                  <h6
                    className={`srn-market-similar-product-label ${
                      this.state.similar_items.length > 0 ? "" : "d-none"
                    }`}
                  >
                    Similar Products
                  </h6>
                  <div
                    id="srn-similar-product-container"
                    className={`srn-market-similar-items-container ${
                      this.state.similar_items.length > 0 ? "" : "d-none"
                    }`}
                  ></div>
                  {/* <ProductListComponent
                                        mode="market"
                                        access_token={this.state.access_token}
                                        app_key="1"
                                        tags="Ozark Trail Walhalla Day Pack Backpack"
                                        addToCart={this.addToCart}
                                        similar_items_id="srn-market-product-page"
                                    /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="srn-market-cart-container" className="d-none"></div>
      </div>
    );
  }
}
