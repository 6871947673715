import React, { Component, Fragment } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Swal from 'sweetalert2'
import { debounce } from "lodash"

import ProductList from '../../_core/products'
import { getThumbnail, log, triggerSerinoEvent, membershipChecker, getAddressObject, mainAddressHandler, getAddressFromObjectChecker, loadingDisplay } from '../../helper/helper'

let productsAPI = new ProductList()

export default class ProductListComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: this.props.products ? this.props.products : [],
            cart_items: this.props.cart_items ? this.props.cart_items : [],
            isProductLoading: false,
            current_product: {},
            quantity: 0,
            isBtnLoading: false,
            isLoading: this.props.isLoading ? this.props.isLoading : false,
            isMembershipActive: localStorage.getItem('isMember') ? localStorage.getItem('isMember') : false
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.products !== prevProps.products) {
            if (this.props.type === 'bundle') {
                this.handleProductsFilter(this.props.products)
                this.setState({
                    cart_items: this.props.cart_items,
                })
            }
            else {
                this.setState({
                    products: this.props.products,
                })   
            }
        }
        if (this.props.cart_items !== prevProps.cart_items) {
            this.setState({cart_items: this.props.cart_items})
        }
        if (this.props.isLoading !== prevProps.isLoading) {
            this.setState({isLoading: this.props.isLoading})
        }
    }

    componentDidMount() {
        if (this.props.type === 'bundle') {
            let products = this.props.products

            if (products && products.length > 0) {
                this.handleProductsFilter(products)
            }
        }
    }

    handleProductsFilter = (products) => {
        let filtered = this.getFilteredProductsByStock(products)
        let transformedProducts = this.handlePriceAdjustments(filtered)


        this.setState({
            products: transformedProducts
        })

    }

    handleInitialItems = (products) => {
        let cart_items = products.map(item => {
            let cart_item = this.setCartDetails(item, this.props.branch_id)
            return cart_item
        })


        this.setState({
            cart_items: cart_items
        })

    }

    getCartItem = (productId) => {
        if (this.state.cart_items) {
            return this.state.cart_items.filter((item) => {
                return productId == item.itemReferenceId
            })
        }
        
    }

    getCartItemQuantity = (productId) => {
        let cartItem = this.getCartItem(productId);

        if (cartItem) {
            let qtys = []
            
            cartItem.map(item => {
                // qtys.push(item.item_type == 1 ? parseInt(item.unit_quantity) : item.quantity)
                qtys.push(parseInt(item.quantity))
            })
            let productQuantity = qtys.reduce((a, b) => { return a + b }, 0)

            return parseInt(productQuantity)
        }


        return 0;
    }

    updateInputQuantities = (cart_items) => {
        this.state.products.forEach(product => {
            let updatedQuantity = this.getCartItemQuantity(product.id)

            let quantityInput = document.getElementById(`srn-product-list-item-quantity-input-${product.id}`)

            if (quantityInput) {
                quantityInput.text = updatedQuantity
                quantityInput.value = updatedQuantity
            }

        })
    }

    setCurrentProduct = (event, product) => {
        const updatedQuantity = event.currentTarget.value;

        if (updatedQuantity == '') {
            return
        }
        this.setState({
            quantity: updatedQuantity,
            current_product: product
        }, () => {
            this.handleDebounce()
        })
    }

    handleDebounce = debounce(() => {
        this.updateCartItemQuantity()
    }, 500)

    updateCartItemQuantity = async () => {

        let updatedQuantity = this.state.quantity
        let product = this.state.current_product


        if (updatedQuantity == '' || product == {} || product == null) {
            return
        }

        let cartItem = this.getCartItem(product.id)
        let cartItemId = cartItem.find(x => x.id)

        if (parseInt(updatedQuantity) === 0) {
            this.handleUpdateCart(product, 'decrement', this.props.x_app_key, 1)
        }
        if (cartItem && cartItem.length > 0) {
            this.handleUpdateCart(product, 'increment', this.props.x_app_key, updatedQuantity)
        }
        else {
            let updatedProduct = product
            updatedProduct.quantity = updatedQuantity
            this.handleAddToCart(updatedProduct)
        }
    }

    setAddToCartProduct = async (product, parent) => {


        if (this.state.current_product && this.state.current_product !== product) {
            await this.setState({
                quantity: 0
            })
        }

        let updatedQuantity = this.state.quantity;

        updatedQuantity++

        let quantityInput = document.getElementById(
            `srn-product-list-item-quantity-input-${product.id}`
        )

        if (quantityInput) {
            quantityInput.value = updatedQuantity;
            quantityInput.text = updatedQuantity;
        }

        this.setState({
            quantity: updatedQuantity,
            current_product: product
        }, () => {
            this.handleDebounce()
        })
    }

    handleUpdateCart = async (item, action, app, inputQuantity) => {


        let cartItemEntity
        let cart_item

        item.quantity = this.getCartItemQuantity(item.id)
        cartItemEntity = this.getCartItem(item.id)

        if (action === 'decrement' && !cartItemEntity) {
          return;
        }

        cart_item = cartItemEntity.find(x => x.id)

        let hasOption = (cart_item.optionIds != null && cart_item.optionIds.length > 0)

        let defaultOptions = []

        if (action == 'increment' && hasOption) {
            let optionGroups = await productsAPI.getProductOptionGroups(item.id)
            optionGroups.data.items.forEach(og => {
                og.default_options.map(y => defaultOptions.push(y))
            })

            if (cartItemEntity.length > 1) {
                let item_with_default_options = cartItemEntity.find(x => {
                    return JSON.parse(x.optionIds).sort().join(',') == defaultOptions.sort().join(',')
                })

                if (item_with_default_options) {
                    cart_item = item_with_default_options
                    item.quantity = item_with_default_options.quantity
                }
                else {
                    this.handleAddToCart(item)
                    return
                }
            }
            else {
                if (defaultOptions && defaultOptions != null) {
                    let optionIdsFromCart = JSON.parse(cartItemEntity[0].optionIds)
                    if (optionIdsFromCart.sort().join(',') != defaultOptions.sort().join(',')) {
                        this.handleAddToCart(item)
                        return 
                    }
                }
            }
        }

        if (action === 'decrement' && cartItemEntity.length > 1 && hasOption) {
            Swal.fire({
                html: "<div class='col-md-12'>" +
                    "<h6 class='cmb-title-2 my-3 text-center'>Adjust Quantity</h6>" +
                    "<p class='text-center cmb-swal-content'>At least one of your items were customized, please adjust the quantity in you cart.</p>" +
                    "</div>",
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal',
                    popup: 'rounded-0'
                },
                reverseButtons: true
            }).then(res => {
                this.setState({
                    isBtnLoading: false
                })
                return false
            })
        }
        else if ((action === 'decrement' && item.quantity <= 1) || (action === 'decrement' && inputQuantity)) {
            if (this.props.type === 'bundle') {
                    let cart_items = this.state.cart_items.filter(x => x.id != cart_item.id)

                    cart_item.application_id = item.application_id

                    this.setState({
                        cart_items,
                        isBtnLoading: false
                    }, () => this.updateInputQuantities(cart_items))


                    if (this.props.delete) {
                        this.props.delete(cart_item)
                    }
            }
            else {
                Swal.fire({
                    showCloseButton: true,
                    html: "<div class='col-md-12'>" +
                        "<img class='spring-img-swal' src='' />" +
                        "<h6 class='cmb-title-2 my-3 text-center'>Removing item</h6>" +
                        "<p class='text-center'>Are you sure you want to remove this item from your cart?</p>" +
                        "</div>",
                    customClass: {
                        cancelButton: 'cmb-btn-no-swal',
                        confirmButton: 'cmb-btn-primary-swal'
                    },
                    cancelButtonText: "NO",
                    confirmButtonText: "YES",
                    showCancelButton: true,
                    showConfirmButton: true,
                    reverseButtons: true
                }).then((willDelete) => {
                    if (willDelete.value) {
                        let cart_items = this.state.cart_items.filter(x => x.id != cart_item.id)

                        cart_item.application_id = item.application_id

                        this.setState({
                            cart_items,
                            isBtnLoading: false
                        }, () => this.updateInputQuantities(cart_items))


                        if (this.props.delete) {
                            this.props.delete(cart_item)
                        }
                    } else {
                        this.setState({ isBtnLoading: false }, this.updateInputQuantities(this.state.cart_items))
                    }
                })
            }

        }
        else {

            let cart_items = this.state.cart_items

            let itemToUpdate = cart_items.find(x => x.id == cart_item.id)

            itemToUpdate.quantity = inputQuantity ? inputQuantity : (action === 'decrement' && item.quantity !== 0 ? item.quantity-1 : action === 'increment' ? item.quantity+1 : 0)

            itemToUpdate.application_id = item.application_id

            this.setState({cart_items, isBtnLoading: false}, () => {
                this.updateInputQuantities(cart_items)
            })

            if (this.props.update) {
                this.props.update(itemToUpdate)
            }

        }
    }

    handleAddress = (product) => {
        triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.onBrandUpdate, {
            brand_id: product.brand_id,
            app_key: product.application_id,
            classNames: product.application_id == process.env.REACT_APP_EATS_APP_ID ? 'srn-ff-eats-new' : 'srn-ff-market-new'
        })
        triggerSerinoEvent('srn-app-key-update', product.application_id)
        setTimeout(() => {
            mainAddressHandler()
        }, 80)
    }


    handleAddToCart = (product) => {

        let app_id = product.application_id

        let address_object = localStorage.getItem(`address_object_${app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${app_id}`)) : null

        if (!address_object) {
            localStorage.setItem('brand_id', product.brand_id)
            this.handleAddress(product)
            return
        }

        let address = address_object.find(x => x.brand_id == product.brand_id)

        if (!address) {
            localStorage.setItem('brand_id', product.brand_id)
            this.handleAddress(product)
            return
        }

        let cartItemCheck = this.getCartItemQuantity(product.id)

        if (cartItemCheck > 0) {
            this.handleUpdateCart(product, 'increment')
            return
        }

        if (this.state.isBtnLoading == product.id) {
            return
        }

        let cart_item = this.setCartDetails(product, address.branch_id)


        this.setState({
            cart_items: [...this.state.cart_items, cart_item]
        })

        if (this.props.add) {
            this.props.add(product)
        }

    }

    setCartDetails = (product, branch_id) => {


        let price
        let price_object = {}
        let savings = {}
        let custom_data = []

        let member = product.product_prices.find((p => {
            return "member" === p.name
        }))

        let nonmember = product.product_prices.find((p => {
            return "nonmember" === p.name
        }))

        if (member) {
            if (membershipChecker()) {
                price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
                price_object = member
            } else {
                price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
                price_object = nonmember
            }

            savings = {
                name: 'savings',
                value: nonmember.price - member.price
            }
            custom_data.push(price_object)
            custom_data.push(savings)
        }
        else {
            price = product.price
        }

        // SAVE ADDRESS IN CART START
        if (getAddressFromObjectChecker) {
            let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

            if (!address) {
                // triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
            }

            custom_data.push({
                name: 'address',
                value: address
            })
        }
        // SAVE ADDRESS IN CART END

        let payload = {
            "id": product.id,
            "itemReferenceId": product.id,
            "itemReferenceType": product.type,
            "itemName": product.name,
            "itemSKU": product.sku,
            "itemImage": getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
            "itemDescription": product.description,
            "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
            "basePrice": nonmember.price,
            "price": price,
            "instructions": "",
            "domainReferenceId": branch_id,
            "domainReferenceType": "1",
            "item_type": product.sell_by_type ? product.sell_by_type : 0,
            "include_promo": true
        }

        if (custom_data && custom_data.length > 0) {
            payload.custom_data = JSON.stringify(custom_data)
        }

        return payload
    }

    inputHandler = (e) => {
        const { value, maxLength } = e.target;
        if (String(value).length >= maxLength) {
            e.preventDefault();
            return;
        }
    };

    handlePriceAdjustments = (products) => {
        let mutated = products.map(product => {
            
            if (product.sell_by_type == '1') {

                let newProductPrices = product.product_prices.map(price_item => {

                    let price = parseFloat(price_item.price) * parseFloat(product.weight)
                    let original_price = parseFloat(price_item.original_price) * parseFloat(product.weight)
                    
                    let price_rounded = Math.round(price.toFixed(3) * 100) / 100
                    let original_price_rounded = Math.round(original_price.toFixed(3) * 100) / 100

                    price_item.price = price_rounded
                    price_item.original_price = original_price_rounded

                    return price_item
                })

                product.product_prices = newProductPrices
            }

            return product
        })

        return mutated
    }

    getFilteredProductsByStock = (products) => {

        let filteredByStocks = products.filter(product => product.inventory && product.inventory.length > 0 && product.inventory[0].stock && product.inventory[0].stock.length > 0 && product.inventory[0]?.threshold && product.inventory[0]?.threshold[0]?.value)

        filteredByStocks = filteredByStocks.filter(product => parseInt(product.inventory[0]?.stock) > parseInt(product.inventory[0]?.threshold[0]?.value))

        return filteredByStocks
    }

    render() {

        const loadingCSS = { 
            height: "100px", 
            margin: "30px",
        }

        const loadingBundleCSS = { 
            height: "10px", 
            margin: "10px",
        }

        const loadingTextCSS = { display: this.state.isProductLoading ? "block" : "none" }

        return (
            <>  
                {
                    this.state.isLoading &&
                    <div className="srn-line-loader-container">
                        {loadingDisplay('dark')}
                    </div>
                }
                {
                    this.props.type === 'bundle' ?
                    this.state.products.map((product, i) => (
                        <Fragment>
                            <div className="srn-product-item-container">
                                <div className="srn-product-item-img-container">
                                    {
                                       product.product_images != null ?
                                       <LazyLoadImage
                                           className="srn-product-item-img"
                                           src={getThumbnail(product) ? getThumbnail(product) : product.product_images[0]}
                                           alt={product.name}
                                           effect="blur"
                                           data-cy={`img-${product.id}`}
                                       /> : <span>No Image</span>
                                    }
                                </div>
                                <div className="srn-product-item-content-container">
                                    <p className="srn-product-item-title">{product.name || ''}</p>
                                    <p className="srn-product-item-subtitle"></p>
                                    <p className={`srn-product-item-price ${(localStorage.getItem('isMember') === 'true' || this.state.isMembershipActive == true) ? "d-none" : ""}`}>
                                        {
                                            // Separated prices for member & nonmembers prices
                                            product.product_prices?.filter(x => x.name === "nonmember").map((p, i) => {
                                                if (i == 0) {
                                                    return(
                                                        <Fragment>
                                                            {
                                                                p.original_price > 0 &&
                                                                    <span className={`cmb-product-list-price discounted-price ${p.price >= p.original_price ? 'd-none' : ''}`} id={`cmb-product-list-price-${p.name}`}>
                                                                    ₱ {(Math.round(p.original_price * 100) / 100).toFixed(2)}
                                                                    </span>
                                                            }
                                                        <span className="cmb-product-list-price" id={`cmb-product-list-price-${p.name}`}>
                                                            ₱ {(Math.round((p.discounted_price ? p.discounted_price : p.price) * 100) / 100).toFixed(2)}
                                                        </span>
                                                        </Fragment>
                                                        
                                                    )
                                                }
                                            })
                                        }
                                    </p>
                                    <p className={`srn-product-item-price ${(localStorage.getItem('isMember') === 'true' || this.state.isMembershipActive == true) ? "" : "d-none"}`}>
                                        {
                                            // Separated prices for member & nonmembers prices
                                            product.product_prices?.filter(x => x.name === "member").map((p, i) => {
                                                if (i == 0) {
                                                    return(
                                                        <Fragment>
                                                            {
                                                                p.original_price > 0 &&
                                                                    <span className={`cmb-product-list-price discounted-price ${p.price >= p.original_price ? 'd-none' : ''}`} id={`cmb-product-list-price-${p.name}`}>
                                                                    ₱ {(Math.round(p.original_price * 100) / 100).toFixed(2)}
                                                                    </span>
                                                            }
                                                        <span className="cmb-product-list-price" id={`cmb-product-list-price-${p.name}`}>
                                                            ₱ {(Math.round((p.discounted_price ? p.discounted_price : p.price) * 100) / 100).toFixed(2)}
                                                        </span>
                                                        </Fragment>
                                                        
                                                    )
                                                }
                                            })
                                        }
                                    </p>
                                </div>
                                {
                                    this.getCartItemQuantity(product.id) > 0 ?
                                    <div className="srn-product-item-actions">
                                        <button
                                            className="btn srn-product-item-btn srn-product-item-decrement"
                                            disabled={this.state.isBtnLoading == product.id}
                                            data-id={product.id}
                                            onClick={() => this.state.isBtnLoading != product.id && this.handleUpdateCart(product, 'decrement', 'market')}>
                                            {
                                                this.state.isBtnLoading == product.id ?
                                                    <FontAwesomeIcon icon={faSpinner} spin /> :
                                                    <FontAwesomeIcon icon={faMinus} />
                                            }
                                        </button>
                                        <input 
                                            id={`srn-product-list-item-quantity-input-${product.id}`}
                                            className="srn-product-item-input" type='number' name="quantity"
                                            maxLength={4}
                                            onKeyPress={(e) => this.inputHandler(e)} 
                                            defaultValue={parseInt(this.getCartItemQuantity(product.id))}
                                            onChange={(event) => this.setCurrentProduct(event, product, "market")}
                                        />
                                        <button
                                            disabled={this.state.isBtnLoading == product.id}
                                            className="btn srn-product-item-btn srn-product-item-increment"
                                            data-id={product.id}
                                            onClick={() => this.handleUpdateCart(product, 'increment', 'market')}>
                                            {
                                                this.state.isBtnLoading == product.id ?
                                                    <FontAwesomeIcon icon={faSpinner} spin /> :
                                                    <FontAwesomeIcon icon={faPlus} />
                                            }
                                        </button>
                                    </div> :                                    
                                    <button className="btn srn-product-item-add-btn" onClick={() => this.setAddToCartProduct(product)}>
                                        {
                                            this.state.isBtnLoading == product.id ?
                                            <div className="srn-line-loader">
                                                <div>{[...Array(5)].map((x, i) => <span key={i}></span>)}</div>
                                            </div> :
                                            'ADD'
                                        }
                                    </button>
                                }
                        </div>
                        </Fragment>
                    )) :
                    <div className="srn-horizontal-products-container">
                        {
                            this.state.products.map(product => (
                                <div className="srn-product-v2-container">
                                    <div className="srn-product-v2-image-container">
                                        <img className="srn-product-v2-image" src={getThumbnail(product) ? getThumbnail(product) : product.product_images[0]} />
                                    </div>
                                    <div className="srn-product-v2-content-container">
                                        <div className="srn-product-v2-logo-container">
                                            <img className="srn-product-v2-logo-image" src={product.brand_logo} />
                                        </div>
                                        <div className="srn-product-v2-title-container">
                                            <h6 className="srn-product-v2-title">{product.name}</h6>
                                            <p className="srn-product-v2-brand-name-container">by <span className="srn-product-v2-brand-name">Popeyes</span></p>
                                        </div>
                                    </div>
                                    <div className="srn-product-v2-price-container">
                                        {
                                            product.product_prices?.filter((x) => x.name === (localStorage.getItem('isMember') === 'true' ? "member" : "nonmember")).map((p) => (
                                                <h6 className="srn-product-v2-price-main">
                                                {this.props.currency}{" "} {(Math.abs((p.discounted_price || p.price) * 100) / 100).toFixed(2)}
                                                </h6>
                                            ))
                                        }
                                        {
                                            product.product_prices?.filter((x) => x.name === (localStorage.getItem('isMember') === 'true' ? "member" : "nonmember")).map((p) => {
                                                if (p.original_price > 0 && p.price >= p.original_price) {
                                                    return (
                                                        <h6 className="srn-product-v2-price-sub">
                                                            {this.props.currency}{" "} {(Math.abs(p.original_price * 100) / 100).toFixed(2)}
                                                        </h6>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="srn-product-v2-btn-container">
                                        {
                                            this.getCartItemQuantity(product.id) > 0 ? 
                                            <div className="srn-product-item-actions">
                                                <button
                                                    className="btn srn-product-item-btn srn-product-item-decrement"
                                                    disabled={this.state.isBtnLoading == product.id}
                                                    data-id={product.id}
                                                    onClick={() => this.state.isBtnLoading != product.id && this.handleUpdateCart(product, 'decrement', 'market')}>
                                                    {
                                                        this.state.isBtnLoading == product.id ?
                                                            <FontAwesomeIcon icon={faSpinner} spin /> :
                                                            <FontAwesomeIcon icon={faMinus} />
                                                    }
                                                </button>
                                                <input 
                                                    id={`srn-product-list-item-quantity-input-${product.id}`}
                                                    className="srn-product-item-input" type='number' name="quantity"
                                                    maxLength={4}
                                                    onKeyPress={(e) => this.inputHandler(e)} 
                                                    defaultValue={parseInt(this.getCartItemQuantity(product.id))}
                                                    onChange={(event) => this.setCurrentProduct(event, product, "market")}
                                                />
                                                <button
                                                    disabled={this.state.isBtnLoading == product.id}
                                                    className="btn srn-product-item-btn srn-product-item-increment"
                                                    data-id={product.id}
                                                    onClick={() => this.handleUpdateCart(product, 'increment', 'market')}>
                                                    {
                                                        this.state.isBtnLoading == product.id ?
                                                            <FontAwesomeIcon icon={faSpinner} spin /> :
                                                            <FontAwesomeIcon icon={faPlus} />
                                                    }
                                                </button>
                                            </div>  :
                                            <a href="#!" className="srn-product-v2-btn" onClick={() => this.handleAddToCart(product)}>ADD TO CART</a>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }
            </>
        )
    }
}