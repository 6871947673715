import React, { Component, Fragment } from "react";
import $ from 'jquery'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'

import CODImg from '../../assets/images/cod_gray@2x.png'
import CODcolored from '../../assets/images/cod.svg'
import PaymayaImg from '../../assets/images/paymaya_gray@2x.png'
import CCImg from '../../assets/images/master-card.svg'
import CCcolored from '../../assets/images/master-card-colored.svg'

import debitCard_colored from '../../assets/images/debitCard_colored.png'
import paymaya_colored from '../../assets/images/paymaya_colored.png'
import paymayaBlue_colored from '../../assets/images/paymayaBlue_colored.png'
import COD_colored from '../../assets/images/COD_colored.png'

import CentralMainLogo from '../../assets/images/central_logo@2x.png'

import { getAccessToken, getUrl, log, triggerSerinoEvent, getSwalContent, handleExpiredToken } from '../../helper/helper'
import { setAnalyticsSessionToEmpty, createOrderAnalytics } from '../../helper/analytics'
import { fbqEvent } from '../../helper/fbpixel'
import axios from 'axios'

import { faSpinner, faCheck, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uuid } from 'uuidv4';
import LocationPicker from '../partials/LocationPicker'
import jwt_decode from 'jwt-decode'
import LoginApi from '../../_core/login'
import Cart from '../../_core/cart'
import landersLogo from '../../assets/images/landers_membership_logo.png'
import CreditCardSpace from 'credit-card-space';
 
const crypto = require('crypto');

let merchant_pk = process.env.REACT_APP_PK
let merchant_sk = process.env.REACT_APP_SK
let paymayaKey = process.env.REACT_APP_PAYMAYAKEY
let x_app_key = process.env.REACT_APP_X_APP_KEY
let clickcnt = 0;
const booking_key = process.env.REACT_APP_BOOKING_KEY
const market_app_key =  process.env.REACT_APP_MARKET_APP_ID
const eats_app_key =  process.env.REACT_APP_EATS_APP_ID
let apps_counter = 0
let mountcounter = 0
let loadCount = 0
var login = new LoginApi()
var cart = new Cart()

export default class SuccessPage extends Component {
    constructor() {
        super()
        this.state = {
            orderDetails: [],
            customerDetails: {},
            grandTotal: 0,
            grandTotals: [],
            sumGrandTotal: 0,
            rating_message: "",
            rating_star: "",
            ratedStar: "",
            total: '',
            isOMRSEnabled: process.env.REACT_APP_OMRS_STATUS == 1 ? true : false
        };

    }


    async componentDidMount() {

        let order_details = localStorage.getItem('created_order_details') ? JSON.parse(localStorage.getItem('created_order_details')) : null

        if (order_details) {

            let data = {}
            if (this.state.isOMRSEnabled) {
                data = order_details.data.data[0]
            }
            else {

                data = order_details.data.message.data
            }

            await this.setState({tracking_number: data.tracking_number})

            let order_details_component = window.serino_components.OrderDetailsComponent.create({
                access_token: localStorage.getItem("access_token"),
                x_app_key: process.env.REACT_APP_X_APP_KEY,
                tracking_number: data.tracking_number,
                currency: "₱",
                logo: <img src={landersLogo} className="srn-landers-logo" />,
                omrs_status: this.state.isOMRSEnabled,
                x_public_key: process.env.REACT_APP_X_PUBLIC_KEY,
                mode: '2'
            }, "srn-order-details-component");

            document.addEventListener(window.serino_components.OrderDetailsComponent.events.onTrackingSuccess, async (e) => {
                log(e.detail)
                await this.setState(e.detail)
                let pickupOrders = this.state.orderDetails.filter(x => x.fulfillmentType === "Pickup")
                log({pickupOrders})

                if (pickupOrders && pickupOrders.length > 0) {
                    sessionStorage.setItem('trigger_pickup', true)
                }
            })

            document.addEventListener(window.serino_components.OrderDetailsComponent.events.onTrackingFailed, (e) => {
              log(e.detail)
              if (e?.detail?.error?.status) {
                log('e.detail.response', e.detail.error.status)
                if (e?.detail?.error?.status) {
                  handleExpiredToken()
                }
                // else {
                //   Swal.fire({
                //     text: "Invalid tracking number.",
                //     icon: "warning",
                //   }).then(function () {
                //     window.location = `/track-order`;
                //   });
                // }
              }
              // else {
              //   Swal.fire({
              //     text: "Invalid tracking number.",
              //     icon: "warning",
              //   }).then(function () {
              //     window.location = `/track-order`;
              //   });
              // }
            })

            this.datalayerPush()
            this.datalayerPushPurchase()

        }

        
    }


  getTotal = (id) => {
    let total = 0;
    let fees = [];
    let adjustments = [];
    let grandTotal = [];
    let subtotal;
    let orderDetails = this.state.orderDetails;


    // get order by id
    let order = orderDetails.filter((order) => {
      return id === order.id;
    });

    // get fees, adjustments and subtotal
    order.map((o) => {
      if (o.fees) {
        fees.push(o.fees);
      }
      if (o.adjustment && o.adjustment.length > 0) {
        o.adjustment.forEach((x) => adjustments.push(x));
        subtotal = o.subtotal;
      }

      if (o.fees_and_adjustments && o.fees_and_adjustments.length > 0) {
        o.fees_and_adjustments.forEach((x) => adjustments.push(x));
        subtotal = o.sub_total;
      }
    });

    let fVal = 0
    if (fees && fees.length > 0) {
      fVal = fees[0].map((f) => {
        return parseFloat(f.value);
      });
    }

    let adjustmentsVal = 0
    if (adjustments && adjustments.length > 0) {
      adjustmentsVal = adjustments.map((f) => {
        return parseFloat(f.value);
      });
    }

    let totalFees = 0
    if (fVal) {
      totalFees = fVal.reduce((a, b) => a + b, 0);
    }

    let totalAdjustments = 0
    if (adjustments && adjustments.length > 0) {
      totalAdjustments = adjustments.filter(x => parseFloat(x.value) !== 0).reduce(
        (a, b) => +a + (+b.value ? +b.value : +b.computedValue),
        0
      );
    }

    log({subtotal})

    let totalWithFees = parseFloat(subtotal) + totalFees;
    log({totalWithFees})
    let totalWithAdjustmentsAndFees = totalWithFees + totalAdjustments;
    log({totalWithAdjustmentsAndFees})

    return totalWithAdjustmentsAndFees;
  };
    datalayerPush = () => {
        let analytics_push = JSON.parse(sessionStorage.getItem('analytics_push'))
        // log({analytics_push})
        if (analytics_push) {
            var dataLayer = window.dataLayer || [];
            dataLayer.push(analytics_push)
            setTimeout(() => {
                sessionStorage.removeItem('analytics_push')
            }, 80)
        }
    }

    datalayerPushPurchase = () => {
        let analytics_push = JSON.parse(sessionStorage.getItem('analytics_push_purchase'))
        if (analytics_push) {
            fbqEvent('Purchase', {value: parseFloat((analytics_push?.ecommerce?.value).toFixed(2)), currency: 'PHP'})
            if (sessionStorage.getItem('trigger_pickup') && sessionStorage.getItem('trigger_pickup') === 'true') {
                fbqEvent('Schedule')
            }
            var dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });
            dataLayer.push(analytics_push)
            setTimeout(() => {
                sessionStorage.removeItem('analytics_push_purchase')
                sessionStorage.removeItem('trigger_pickup')
            }, 80)
        }
    }

    render() {
        let customer_details = this.state.customerDetails
        return (
            <div className="d-flex flex-column h-100" id="checkoutmaincontainer">

                {/* Order Success content*/}

                <div id="srn-order-details-component"></div>

                <div className="srn-success-page-main-container">
                    <div className="srn-success-page-content-container">
                        <div className="srn-success-page-content-body">
                            <div className="srn-success-page-order-details mb-4">
                                <div className="srn-success-page-order-detail-item">
                                    <h6 className="srn-success-page-content-title">Order Placed</h6>
                                </div>
                                <div className="srn-success-page-order-detail-item srn-grand-total">
                                    <div className="srn-success-page-total-label">Grand Total</div>
                                    <div className="srn-success-page-total-value">₱ {parseFloat(this.state.grandTotal).toFixed(2)}</div>
                                </div>
                            </div>                            
                            <p className="srn-success-page-content-text">Hi <span className="srn-capitalize">{customer_details.fname}</span>,<br />
                                Thank you for placing your order. Your order will be ready/delivered soon. You can check the order detals below:
                            </p>
                            <div className="srn-success-page-details-container">
                                <div className="srn-success-page-details-item">
                                    <div className="srn-success-page-details-item">
                                        <p className="srn-success-page-details-item-title">Tracking no</p>
                                        <p className="srn-success-page-details-item-content">{this.state.tracking_number}</p>
                                        <p className="srn-success-page-details-item-title">Name</p>
                                        <p className="srn-success-page-details-item-content srn-capitalize">{customer_details.fname} {customer_details.lname}</p>
                                        <p className="srn-success-page-details-item-title">Delivery address</p>
                                        <p className="srn-success-page-details-item-content">{this.state.isOMRSEnabled ? customer_details.customer_address : customer_details.address || null}</p>
                                        <p className="srn-success-page-details-item-title">Email address</p>
                                        <p className="srn-success-page-details-item-content">{customer_details.email || null}</p>
                                    </div>
                                </div>
                                <div className="srn-success-page-details-item">
                                    <div className="srn-success-page-details-item">
                                        <p className="srn-success-page-details-item-title">Contact no</p>
                                        <p className="srn-success-page-details-item-content">{customer_details.contact}</p>
                                        <p className="srn-success-page-details-item-title">Payment Status</p>
                                        <p className="srn-success-page-details-item-content">{customer_details.payment_status}</p>
                                        <p className="srn-success-page-details-item-title">Payment Method</p>
                                        <p className="srn-success-page-details-item-content">{this.state.isOMRSEnabled && this.state.orderDetails && this.state.orderDetails.length > 0 ? this.state.orderDetails[0].mode_of_payment_option : customer_details.modeOfPayment}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.orderDetails.map((order, i) => {
                            return (
                                <div className="srn-success-page-content-container">
                                    <div className="srn-success-page-content-header">
                                        <div className="srn-success-page-order-details">
                                            <div className="srn-success-page-order-detail-item">
                                                <div className="srn-success-page-app-name">Central {order.brand_code == 'MKT' ? 'Market' : 'Eats'}</div>
                                                <div className="srn-success-page-order-number">Order No: {this.state.isOMRSEnabled ? order.code : order.order_code}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="srn-success-page-content-body">
                                        <div className="srn-success-page-brand-details-container">
                                            <div className="srn-success-page-brand-logo-container">
                                                {
                                                    order.brand_logo && 
                                                    <img className="srn-success-page-brand-logo" src={order.brand_logo} />
                                                }
                                            </div>
                                            <div className="srn-success-page-brand-details">
                                                <p className="srn-success-page-brand-name">
                                                    {
                                                        order.brand_name
                                                    }
                                                </p>
                                                <p className="srn-success-page-brand-info">
                                                    Tracking #{this.state.tracking_number} · Order #{this.state.isOMRSEnabled ? order.code : order.order_code}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="srn-success-page-order-summary-container table-responsive">
                                            <table class="table srn-success-page-order-summary-table">
                                                <thead>
                                                <tr>
                                                    <th scope="col">PRODUCT</th>
                                                    <th scope="col">UNIT COST</th>
                                                    <th scope="col">QUANTITY</th>
                                                    <th scope="col">AMOUNT</th>
                                                </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            order.items && order.items.length > 0 && order.items.map(item => (
                                                                <tr>
                                                                    <td>{item.name || item.item_ref_name}</td>
                                                                    <td>₱ {item.price}</td>
                                                                    <td>{parseFloat(item.quantity).toFixed(0)}</td>
                                                                    <td>₱ {item.total ? item.total : (parseFloat(item.price) * parseFloat(item.quantity))}</td>
                                                                </tr>
                                                            ))
                                                        }

                                                        {
                                                            order.fees && order.fees.length > 0 && order.fees.map(fee => (
                                                                <tr className="srn-tr-adjustments">
                                                                    <td className="srn-td-filler"></td>
                                                                    <td className="srn-td-filler"></td>
                                                                    <td>{fee.name}</td>
                                                                    <td>{fee.value}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        {
                                                            order.adjustment && order.adjustment.length > 0 && order.adjustment.map(fee => (
                                                                <tr className="srn-tr-adjustments">
                                                                    <td className="srn-td-filler"></td>
                                                                    <td className="srn-td-filler"></td>
                                                                    <td>{fee.name}</td>
                                                                    <td>{fee.computedValue}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        {
                                                            order.fees_and_adjustments && order.fees_and_adjustments.length > 0 && order.fees_and_adjustments.map(fee => (
                                                                <tr className={`srn-tr-adjustments cart-rule-${fee.name}`}>
                                                                    <td className="srn-td-filler"></td>
                                                                    <td className="srn-td-filler"></td>
                                                                    <td>{fee.type === 'promotion_discount' ? fee.reference : fee.name}</td>
                                                                    <td>{fee.value}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        {
                                                            <tr className="srn-tr-adjustments">
                                                                <td className="srn-td-filler"></td>
                                                                <td className="srn-td-filler"></td>
                                                                <td>Total (VAT-Inc.)</td>
                                                                <td>₱ {this.getTotal(order.id).toFixed(2)}</td>
                                                            </tr>
                                                        }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                )

                        })
                    }
                    <p className="srn-success-page-more-info">
                        If you have questions, you may reach us at <span className="srn-success-page-more-info-link">support@central.ph</span> with your order number, name, mobile and email address. We will gladly get back to you within 24 hours.
                    </p>
                    <div className="srn-success-page-view-order-btn-container">
                        <a className="btn srn-success-page-view-order-btn" href={`/track-order?order-number=${this.state.tracking_number}`}>
                            VIEW ORDER STATUS
                        </a>
                    </div>
                </div>

                {/* modal */}
                <div className="modal fade success-modal" id="track-order-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <img src={CentralMainLogo}/>
                                <button type="button" className="btn srn-ft-close-btn" data-dismiss="modal" aria-label="Close">
                                    <a href="#" className="close"/>
                                </button> 
                            </div>
                            <div className="modal-body">
                                <h6 className="checkout-header-order text-center mt-0">Order Placed Successfully!</h6>

                                <p className="checkout-paragraph">
                                    Your order is being processed. A confirmation email will be sent to you shortly.
                                </p>

                                <p className="checkout-paragraph-bold">
                                    You can track your order at the “Track Order” page using your tracking no.
                                </p>
                                
                                <p className="checkout-paragraph text-center font-weight-bold">
                                    {this.state.tracking_number}
                                </p>

                                {
                                    this.state.isClearCartLoading ? 
                                    <a href={`#`} className="btn btn-secondary btn-block checkout-track-order-btn">
                                        <FontAwesomeIcon icon={faSpinner} />
                                    </a> : 
                                    <a href={`/track-order?order-number=${this.state.tracking_number}`} className="btn btn-secondary btn-block checkout-track-order-btn">
                                        TRACK ORDER
                                    </a>

                                }

                                {/* <p className="checkout-paragraph text-center">
                                    You have earned 3 Reward Points
                                </p>

                                <button className="btn btn-block checkout-earn-point-btn">
                                    SHOP MORE TO EARN POINTS!
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal srn-min-max-modal" id="srn-min-max-modal">
                    <div className="modal-dialog modal-dialog-centered modal-xs">
                        <div className="modal-content srn-min-max-modal-content">
                            <div className="modal-body srn-min-max-modal-body">
                                <h4 className="srn-min-max-modal-title">Below Minimum</h4>
                                <p className="srn-min-max-modal-description">Your order does not meet the <br/> minimum amount for delivery</p>
                                {
                                    this.state.cartsBelowMinimum?.map(item => (
                                        <Fragment>
                                            <p className="srn-min-max-modal-selected-cart">{item.brand.id == 1 ? 'Central Market' : item.brand.name}</p>
                                        </Fragment>
                                    ))
                                    // <p>{JSON.stringify(this.state.cartsBelowMinimum)}</p>
                                }
                            </div>
                            <div className="srn-min-max-modal-btn-container">
                                <button className="btn btn-default srn-min-max-modal-btn" data-dismiss="modal">ok</button>
                            </div>
                        </div>
                    </div>
                </div>             
            </div>
            
        )
   
    }
  
}


$(document).ready(function () {
    $("input.srn-crm-phonenumber").attr('maxlength', '10');    
});