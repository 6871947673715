import $ from 'jquery'

let IDMS_URL = process.env.REACT_APP_IDMS_URL
export default class LoginAPI {

    verifyEmail(access_token, x_app_key, username) {
        return $.ajax({
            url: IDMS_URL + `/guest/clients/${username}/metas`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        });
    }


    // confirmSignup(access_token, x_app_key, payload) {
    //     return $.ajax({
    //         url: IDMS_URL + `/signup/confirm`,
    //         method: 'POST',
    //         headers: {
    //             "Authorization": "Bearer " + access_token,
    //             "x-app-key": x_app_key
    //         },
    //         contentType: 'application/json',
	// 		data: JSON.stringify(payload)
    //     })
    // }
    confirmSignup(access_token, x_app_key, payload) {
        return $.ajax({
            url: IDMS_URL + `/auth/login`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }
    resendConfirmation(access_token, x_app_key, payload) {
        return $.ajax({
            url: IDMS_URL + `/signup/resend-code`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    getClientAccessToken(payload) {
        return $.ajax({
            url: IDMS_URL + `/auth/login`,
            method: 'POST',
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    getClientDetails(access_token, x_app_key) {
        return $.ajax({
            url: IDMS_URL + `/me`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        })
    }

    getClientMeta(access_token, x_app_key) {
        return $.ajax({
            url: IDMS_URL + `/me/metas`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        })
    }

    addNewMeta(access_token, x_app_key, payload) {
        return $.ajax({
            url: IDMS_URL + `/me/metas`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    getClientAddress(access_token, x_app_key) {
        return $.ajax({
            url: IDMS_URL + `/me/addresses`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        })
    }

    handleRefreshToken(access_token, x_app_key) {
        let payload = {
            "refreshToken": localStorage.getItem('refresh_token')
        }
        return $.ajax({
            url: IDMS_URL + `/auth/refresh-token`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": 1
            },
            accept: 'application/json',
            data: payload
        })
    }
}