import $ from 'jquery'

let IDMS_URL = process.env.REACT_APP_IDMS_URL
let API_URL = process.env.REACT_APP_SERINO_API;
let X_PUBLIC_KEY = process.env.REACT_APP_X_PUBLIC_KEY

export default class ProfileAPI {

    getAllOrders(access_token) {
        return $.ajax({
            url: API_URL + `/orders/get/all`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": 2
            },
            accept: 'application/json',
			dataType: 'json',
        })
    } 

    addAddress(access_token, x_app_key, payload){
        return $.ajax({
            url: IDMS_URL + `/me/addresses`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    updateAddress(access_token, x_app_key, payload, id){
        return $.ajax({
            url: IDMS_URL + `/me/addresses/${id}`,
            method: 'PUT',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    deleteAddress(access_token, x_app_key, payload, id){
        return $.ajax({
            url: IDMS_URL + `/me/addresses/${id}`,
            method: 'DELETE',
            headers: {
                "Authorization": "Bearer " + access_token,
                "x-app-key": x_app_key
            },
            contentType: 'application/json',
			data: JSON.stringify(payload)
        })
    }

    getAllOrdersOMRS(access_token) {
        return $.ajax({
            url: `${process.env.REACT_APP_OMRS_URL}/public/my-orders?&per_page=100`,
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-PUBLIC-KEY": X_PUBLIC_KEY
            },
            accept: 'application/json',
            dataType: 'json',
        })
    } 
}