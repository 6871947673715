import {log, membershipChecker} from './helper'
import { fbqEvent } from './fbpixel'

let DataHubKey = process.env.REACT_APP_DATA_HUB_KEY;
const DataHub = new window.DataHub(DataHubKey)

const isAnalyticsActive = false
const isDatahubActive = false


export const addToCartAnalytics = () => {    
    if (!isAnalyticsActive) {
        return;
    }

    // for google analytics
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': '+'
    })
}

export const updateCartAnalytics = () => {
    if (!isAnalyticsActive) {
        return;
    }

    var dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': '+'
    })
}
export const addToCartHub = (product, branch_id) => {    
    if (!isDatahubActive) {
        return;
    }

    var params = {
        module: "analytics",
        sub: "customer-behavior",
        data: {
            action: "addToCart",
            product_id: product.id,
            product_name: product.name,
            product_sku: product.sku,
            quantity: 1,
            product_price: product.price,
            store_id: branch_id,
            store_name: localStorage.getItem('branch_name')
        }
    }

    DataHub.publish(params)
}

export const updateCartHub = (item, action) => {
    if (!isDatahubActive) {
        return;
    }

    var params = {
        module: "analytics",
        sub: "customer-behavior",
        data: {
            action: action === 'decrement' && item.quantity !== 0 ? 'updateToCartDecrement' : action === 'increment' ? 'updateToCartIncrement' : null,
            product_id: item.itemReferenceId,
            product_name: item.itemName,
            product_sku: item.itemSKU,
            quantity: action === 'decrement' && item.quantity !== 0 ? -1 : action === 'increment' ? 1 : 0,
            product_price: item.price,
            store_id: localStorage.getItem('branch_id'),
            store_name: localStorage.getItem('branch_name')
        }
    }

    // DataHub.publish(params)
}

export const orderDetailsHub = (ratingValue, commentValue, orderCode) => {
    if (!isDatahubActive) {
        return;
    }

    var params = {
        module: "analytics",
        sub: "customer-behavior",
        data: {
            action: "surveyAnswer",
            rate: ratingValue,
            comment: commentValue,
            order_id: orderCode
        }
    }

    DataHub.publish(params)
}

export const datalayer = () => {
    if (!isAnalyticsActive) {
        return;
    }

    var analytics = sessionStorage.getItem('analytics');
    if (analytics != "") {
        var dataLayer = window.dataLayer || [];
        dataLayer.push(JSON.parse(analytics));
        sessionStorage.setItem('analytics', '');
    }
}

export const createOrderAnalytics = (orderData, app) => {
    if (!isAnalyticsActive) {
        return;
    }

    let cart_items = []

    app.cart.forEach(x => {
        x.cart_items.forEach(c => {
            let ct = c
            ct.brand = x.brand.name
            cart_items.push(ct)
        })
    })

    let data = orderData.data.message.data
    let orderNumber = data.tracking_number
    let total = data.order_amounts.total_items_amount
    let product_items = []

    cart_items.forEach(cart_item => {
        product_items.push({
            'name': cart_item.itemName,
            'id': cart_item.itemReferenceId,
            'price': parseFloat(cart_item.price),
            'brand': cart_item.brand,
            'quantity': parseInt(cart_item.quantity)
        });
    })

    let datalayer = {
        'event': `transaction`,
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': orderNumber,
                    'affiliation': 'Online Store',
                    'revenue': parseFloat(total)
                },
                'products': product_items
            }
        }
    }
    sessionStorage.setItem('analytics', JSON.stringify(datalayer));

}

export const createOrderAnalyticsOMRS = (orderData, apps, grandTotal) => {
    if (!isAnalyticsActive) {
        return;
    }

    let cart_items = []

    apps.forEach((app) => {
        app.cart.forEach(x => {
            x.cart_items.forEach(c => {
                let ct = c
                ct.brand = x.brand.name
                cart_items.push(ct)
            })
        })
    })

    log('items from createOrderAnalyticsOMRS', cart_items)

    let data = orderData.data.data[0]
    let orderNumber = data.tracking_number
    let total = grandTotal
    let product_items = []

    cart_items.forEach(cart_item => {
        product_items.push({
            'name': cart_item.itemName,
            'id': cart_item.itemReferenceId,
            'price': parseFloat(cart_item.price),
            'brand': cart_item.brand,
            'quantity': parseInt(cart_item.quantity)
        });
    })

    let datalayer = {
        'event': `transaction`,
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': orderNumber,
                    'affiliation': 'Online Store',
                    'revenue': parseFloat(total)
                },
                'products': product_items
            }
        }
    }
    sessionStorage.setItem('analytics', JSON.stringify(datalayer));

}

export const setAnalyticsSessionToEmpty = () => {
    if (!isAnalyticsActive) {
        return;
    }

    sessionStorage.setItem('analytics', '');
}

export const GAViewProduct = (product) => {
    let list = product.application_id === '2' ? 'Eats Products' : 'Market Products'

    let price

    let category = ''

    if (product.category) {
        category = product.category
    }

    let member = product.product_prices?.find((p => {
        return "member" === p.name
    }))

    let nonmember = product.product_prices?.find((p => {
        return "nonmember" === p.name
    }))

    if (member) {
        if (membershipChecker()) {
            price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
        } else {
            price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
        }
    }
    else {
        price = product.price
    }

    var dataLayer = window.dataLayer || [];

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'ecommerce': {
        'detail': {
          'actionField': {'list': list},    // 'detail' actions have an optional list property.
          'products': [{
            'name': product.name,         // Name or ID is required.
            'id': product.id,
            'price': parseFloat(price),
            'brand': product.brand_name,
            'category': category,
            'variant': ''
           }]
         }
       }
    });

}

export const GAPurchase = (od, grandTotal, apps) => {

    let cart_items = []
    let fees = []
    let totalShipping = 0

    for (const app of apps) {
        app.cart.forEach(x => {
            x.cart_items.forEach(c => {
                let ct = c
                ct.brand = x.brand.name
                cart_items.push(ct)
            })
            x.rule_value_only.forEach(r => fees.push(parseFloat(r)))
        })
    }

    totalShipping = fees.reduce((a, b) => +a + +b, 0).toFixed(2)

    let orderNumber = od.tracking_number
    let total = od.order_amounts.total_items_amount
    let product_items = cart_items.map(cart_item => {
        return {
            'name': cart_item.itemName,
            'id': cart_item.itemReferenceId,
            'price': parseFloat(cart_item.price),
            'brand': cart_item.brand,
            'quantity': parseInt(cart_item.quantity)
        };
    })

    let datalayer = {
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': od.tracking_number,                         // Transaction ID. Required for purchases and refunds.
            'affiliation': 'Online Store',
            'revenue': parseFloat(total),
            'tax': 0,
            'shipping': parseFloat(totalShipping)
          },
          'products': product_items
        }
      }
    }
    
    sessionStorage.setItem('analytics_push_purchase', JSON.stringify(datalayer));

}

export const GAPurchaseOMRS = (od, grandTotal, apps) => {

    let cart_items = []
    let fees = []
    let totalShipping = 0

    for (const app of apps) {
        app.cart.forEach(x => {
            x.cart_items.forEach(c => {
                let ct = c
                ct.brand = x.brand.name
                cart_items.push(ct)
            })
            x.rule_value_only.forEach(r => fees.push(parseFloat(r)))
        })
    }

    totalShipping = fees.reduce((a, b) => +a + +b, 0).toFixed(2)

    let orderNumber = od[0].tracking_number
    let total = grandTotal
    let product_items = cart_items.map(cart_item => {
        return {
            item_id: cart_item.itemReferenceId,
            item_name: cart_item.itemName,
            price: parseFloat(cart_item.price),
            quantity: parseInt(cart_item.quantity),
            item_brand: cart_item.brand
        };
    })

    const purchase_datalayer = {
        event: 'purchase',
        ecommerce: {
            transaction_id: orderNumber,
            value: parseFloat(total),
            tax: 0,
            shipping: parseFloat(totalShipping),
            currency: 'PHP',
            items: product_items
        }
    }
    
    sessionStorage.setItem('analytics_push_purchase', JSON.stringify(purchase_datalayer));

}

export const GACheckoutMOP = (apps, mode_of_payment) => {

    let cart_items = []

    for (const app of apps) {
        app.cart.forEach(x => {
            x.cart_items.forEach(c => {
                let ct = c
                ct.brand = x.brand.name
                cart_items.push(ct)
            })
        })
    }
    let product_items = cart_items.map(cart_item => {
        return {
            'name': cart_item.itemName,
            'id': cart_item.itemReferenceId,
            'price': parseFloat(cart_item.price),
            'brand': cart_item.brand,
            'quantity': parseInt(cart_item.quantity)
        };
    })

    let datalayer = {
        'event': 'checkout',
        'ecommerce': {
            'checkout': {
                'actionField': {'step': 1, 'option': mode_of_payment},
                'products': product_items
            }
        }
    }
    var dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(datalayer);
    sessionStorage.setItem('analytics_push_mop', JSON.stringify(datalayer));

}

export const GAImpressions = (products, list) => {
    
    var dataLayer = window.dataLayer || [];

    let impressions = products.map(product => {

        let price;

        if (product.product_prices && product.product_prices.length > 0) {
            let member = product.product_prices.find((p => {
                return "member" === p.name
            }))

            let nonmember = product.product_prices.find((p => {
                return "nonmember" === p.name
            }))

            if (member) {
                if (membershipChecker()) {
                    price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
                } else {
                    price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
                }
            }
            else {
                price = product.price
            }
        }
        else {
            price = product.price
        }


        return {
            'name': product.name,
            'id': product.id,
            'price': parseFloat(price),
            'brand': product.brand_name,
            'list': list,
            'position': 1
        }
    })

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'ecommerce': {
        'impressions': impressions
      }
    });
}

export const GAAddToCart = (payload, app_id) => {
    fbqEvent('AddToCart', {
        content_name: payload?.itemName || '',
        content_ids: [payload?.itemSKU],
        content_type: 'product',
        value: payload?.price,
        currency: 'PHP'
    })

    let address = null
    let brand_name = ''

    let address_object = localStorage.getItem(`address_object_${app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${app_id}`)) : null

    if (address_object) {
        address = address_object.find(x => x.branch_id == payload.domainReferenceId)
    }

    if (app_id == 1) {
        brand_name = 'Central Market'
    }
    else {
        if (address) {
            brand_name = address.brand_name
        }
    }

    var dataLayer = window.dataLayer || [];

    let product = {
        'name': payload.itemName,
        'id': payload.itemReferenceId,
        'price': parseFloat(payload.price),
        'brand': brand_name,
        'category': '',
        'variant': '',
        'quantity': parseInt(payload.quantity)
    }

    let cart_items = []

    let parsed_cart = localStorage.getItem('ga_cart_items') ? JSON.parse(localStorage.getItem('ga_cart_items')) : null

    if (parsed_cart) {
        cart_items = [...parsed_cart, product]
    }
    else {
        localStorage.setItem('ga_cart_items', JSON.stringify([product]))
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'PHP',
        'add': {                                // 'add' actionFieldObject measures.
          'products': [product]
        }
      }
    });
}

export const GARemoveCartItem = (id, app_id, cart_items) => {

    if (!cart_items || !app_id) {
        return
    }

    let address = null
    let brand_name = ''

    let cart_item = cart_items.find(x => x.id == id)

    if (!cart_item) {
        return
    }

    let address_object = localStorage.getItem(`address_object_${app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${app_id}`)) : null

    if (address_object) {
        address = address_object.find(x => x.branch_id == cart_item.domainReferenceId)
    }

    if (app_id == 1) {
        brand_name = 'Central Market'
    }
    else {
        if (address) {
            brand_name = address.brand_name
        }
    }

    let product = {
        'name': cart_item.itemName,
        'id': cart_item.itemReferenceId,
        'price': parseFloat(cart_item.price),
        'brand': brand_name,
        'category': '',
        'variant': '',
        'quantity': parseInt(cart_item.quantity)
    }

    var dataLayer = window.dataLayer || [];

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      'event': 'removeFromCart',
      'ecommerce': {
        'remove': {                               // 'remove' actionFieldObject measures.
          'products': [product]
        }
      }
    });
}

export const GAPlaceOrder = (grandTotal) => {
    log('grandtotal', grandTotal)
    let ga = window.ga || null
    if (ga) {
        let tracker = ga.getAll()[0]
        if (tracker) {
            log('GAPlaceOrder called', grandTotal)
            tracker.send('event', 'Enhanced Ecommerce', 'click', 'Amount', grandTotal)
        }
    }
}