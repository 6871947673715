import { render } from 'react-dom'
import React, { Component, Fragment, createElement } from 'react'

class CartComponent extends Component {
    constructor() {
        super()
        this.state = {
            count: 0
        }
    }

    handleClick = () => {
        let count = this.state.count
        count++
        this.setState({
            count
        })

        const event = new CustomEvent('srn-cart-add-cart-item', {
            bubbles: true,
            composed: true,
            detail: {
                items:
                    [{
                        name: 'Rice',
                        quantity: count
                    }]
            }
        })

        document.dispatchEvent(event)
    }

    render() {
        return (
            <Fragment>
                <div>
                    <p>Hello World, I am Cart!</p>
                    <p>{this.state.count}</p>
                    <button onClick={this.handleClick}>+</button>
                </div>
            </Fragment>
        )
    }
}

class ProductListComponent extends Component {
    constructor() {
        super()
        this.state = {
            cartItems: []
        }
    }
    componentDidMount() {
        document.addEventListener('srn-productlist-update-cart-items', this.updateCartItems)
    }

    updateCartItems = (event) => {
        const detail = event.detail
        this.setState({
            items: detail.items
        })
    }

    render() {
        return (
            <Fragment>
                {
                    this.state?.items?.map(item => {
                        return (
                            <div>
                                <p>Name: {item.name}</p>
                                <p>Quantity: {item.quantity}</p>
                            </div>
                        )
                    })
                }
            </Fragment>
        )
    }
}

class AppSelector extends Component {
    constructor() {
        super()
        this.state = {
            appId: 0
        }
    }

    componentDidMount() {
        document.addEventListener('srn-appSelector-selectApp', this.selectApp)
    }

    selectApp = (event) => {
        const detail = event.detail
        this.goToApp(detail.appId, true)
    }

    goToApp = (appId, triggered) => {
        this.setState({
            appId: appId
        })

        const customEvent = new CustomEvent('srn-appSelector-onClick', {
            bubbles: true,
            composed: true,
            detail: {
                appId: appId,
                triggered: triggered
            }
        })

        document.dispatchEvent(customEvent)
    }

    render() {
        return (
            <Fragment>
                <button onClick={() => this.goToApp(1)}>GO TO APP 1</button>
                <button onClick={() => this.goToApp(2)}>GO TO APP 2</button>
                <span>Selected App: {this.state.appId}</span>
            </Fragment>
        )
    }
}

export const serinoComponents = {
    cart: {
        create: (options, container) => {
            const element = createElement(CartComponent, options)
            render(element, document.getElementById(container))
            return element
        },
        prefix: 'srn-cart',
        events: {
            onCartItemIncrement: 'srn-cart-add-cart-item'
        },
        subscriptions: {
            onAddNewCartItem: 'srn-cart-add-new-cart-item'
        }
    },
    productList: {
        create: (options, container) => {
            const element = createElement(ProductListComponent, options)
            render(element, document.getElementById(container))
            return element
        },
        prefix: 'srn-productlist',
        events: {
        },
        subscriptions: {
            updateCartItems: 'srn-productlist-update-cart-items'
        }
    },
    appSelector: {
        create: (options, container) => {
            const element = createElement(AppSelector, options)
            render(element, document.getElementById(container))
            return element
        },
        prefix: 'srn-appSelector',
        events: {
            onClick: 'srn-appSelector-onClick'
        },
        subscriptions: {
            selectApp: 'srn-appSelector-selectApp'
        }
    }
}