import { AppSelector } from '../../../../wrappers'
import { headerActions } from '../redux/actions'

export class HeaderAppSelector extends AppSelector {
    onAppSelect = (event) => {
        super.onAppSelect(event)
        const detail = event.detail
        const isClicked = !detail.triggered
        this.dispatch(headerActions.selectApplication({ appId: detail.appId, redirect: isClicked }))
    }

    selectApp = (args) => {
        super.selectApp(args)
    }
}