const LOCAL_BASE_URL = 'http://localhost:3000'
const DEV_BASE_URL = 'https://devsrn7-central.serino.com'
const QA_BASE_URL = 'https://qasrn7-central2.serino.com'
const UAT_BASE_URL = 'https://uat-centralweb.serino.com'
const PROD_BASE_URL = 'https://central.ph'

/**
 *  * Custom payment config for specific brands 
 * Will add brands here for upcoming tasks
 * Fields:
 * brand_name {string} - brand name
 * max_online_payment_amount {number} - max amount Allowed for online payment
 */
const PAYMENT_CONFIG = []

export {
  LOCAL_BASE_URL,
  DEV_BASE_URL,
  QA_BASE_URL,
  UAT_BASE_URL,
  PROD_BASE_URL,
  PAYMENT_CONFIG,
}