import $ from 'jquery'
import axios from 'axios'

let CART_SERVICE_URL = process.env.REACT_APP_CART_SERVICE_URL
let SERINO_API = process.env.REACT_APP_SERINO_API
let REACT_APP_LAYER_URL = process.env.REACT_APP_LAYER_URL
let BASIC_TOKEN = process.env.REACT_APP_BASIC_TOKEN
let PUBLIC_APP_KEY = process.env.REACT_APP_X_PUBLIC_KEY

export const getAppToken = (appId, user) => {
    return BASIC_TOKEN
}

export default class CheckoutAPI {

    getServerDateTime() {
        return axios.get(`${SERINO_API}/server/public/time`)
    }

    createOrderOMRS(access_token, payload) {
        return $.ajax({
            url: `${process.env.REACT_APP_OMRS_URL}/public/create`,
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-PUBLIC-KEY": PUBLIC_APP_KEY
            },
            contentType: 'application/json',
            data: JSON.stringify(payload)
        })
    }
} 

