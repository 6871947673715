import { Component } from "react";
// import topBanner from '../../assets/images/about-us-top-banner.jpg'
import topBanner from '../../assets/images/about-us-banner.png'
import ParallaxComponent from '../partials/ParallaxComponent';

export default class AboutUs extends Component {

    render() {  
        return (
            <div style={{marginTop: "5.5rem"}}>
                <ParallaxComponent page="generic" />
                <div className="about-us-container">
                    <div className="main-landing-banner row">
                        <img src={topBanner} className="web-banner"/>
                    </div>
                    <div className="about-us-header-div row">
                        <h6 className="about-us-header">About Us</h6>
                    </div>

                    <div className="about-us-description row">
                        {/* <img src={banner1}/> */}
                        <h6>Best of everything.</h6>
                        <p className="p-aboutus">
                            Central is where you get the best of what life has to offer. Whether you’re looking for a birthday treat 
                            or planning to fill your pantry with all your favorites, we’re here to indulge you with premium finds and 
                            exclusive deals for everything and anything you need, want, or crave.</p>
                    </div>

                    <div className="about-us-description row">
                        {/* <img src={banner1}/> */}
                        <h6>Delivering the best to you.</h6>
                        <p className="p-aboutus">
                            We make sure to offer products and deliver services that meet your high standards—the kind that 
                            you’d always expect to have and experience every day.
                            You have super access to a wide selection of local and imported products, some of which you can 
                            only get here. 
                            Our roster of restaurants serves the best-tasting dishes and rewards you with deals to perk up your 
                            appetite.</p>
                    </div>
                    
                    <div className="about-us-description row">
                        {/* <img src={banner1}/> */}
                        <h6>Effortless and powerful.</h6>
                        <p className="p-aboutus">
                        Here, you’re in control of the things that you want. You also have the freedom when to get it. All it 
                            takes is a few taps or clicks.</p>
                    </div>

                    <div className="about-us-description row">
                        {/* <img src={banner1}/> */}
                        <h6>Reap the rewards.</h6>
                        <p className="p-aboutus">
                        Dedicated to giving you the best and more, so you not only get to enjoy what you want, but you also 
                        get to have fun with all the treats we have in store for you.</p>
                    </div>
                </div>
            </div>
        )
    }
}