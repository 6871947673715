import React from 'react'
import MarketLogo from '../../../assets/images/market-footer.png'
import {  getUrl } from '../../../helper/helper'

export const MarketFooter = () => (
    <footer className="app-footer">
        <div className="market-footer-green"></div>
        <div className="container">
        	<a href={`${getUrl('market')}`}>
            	<img className="footer-logo" src={MarketLogo}/>
        	</a>
        </div>
    </footer>
)

export default MarketFooter;