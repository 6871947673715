import React, { Component } from "react";
import $ from 'jquery'
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ShoppingBasketComponent from './ShoppingBasketComponent2'
import MarketSVG from '../../assets/images/new/central-market-logo-2.svg'
import { getCrossCart, log, addEvt, genericAddCartItem, mainAddressHandler } from '../../helper/helper'
import ProductListComponent from './ProductListComponent'
import ProductList from '../../_core/products'
import Cart from '../../_core/cart'

let productsAPI = new ProductList()
let cartAPI = new Cart()

const market_app_id = process.env.REACT_APP_MARKET_APP_ID

class SearchComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			access_token: '',
			page: '',
			cart_items: [],
			tagProducts: [],
			areComponentsHidden: false
		}
	}
    componentDidUpdate(prevProps) {
        if (this.props.cart_items !== prevProps.cart_items) {
            this.setState({cart_items: this.props.cart_items})
        }
    }

	componentDidMount() {
		addEvt(window.serino_components.SearchProductListComponent.events.onSearch, this.onSearchFunc)

        $('#search_modal').on('shown.bs.modal', (e) => {
        	this.getCart()
        });

        this.getCart()
        this.getTagProducts()
	}

	onSearchFunc = (evt) => {
		if (evt.detail.length > 0) {
			this.setState({
				areComponentsHidden: true
			})
		}
		else {
			this.setState({
				areComponentsHidden: false
			})
		}
		if (evt.detail === '') {
			this.setState({
				areComponentsHidden: false
			})
		}
	}

	getTagProducts = async () => {

		let productTagsResult = await productsAPI.getProductByTags('central eats payday', process.env.REACT_APP_EATS_APP_ID)

		if (productTagsResult && productTagsResult.data && productTagsResult.data.data) {

			let data = productTagsResult.data.data

			let product_reference = data.map(x => `&product_reference[]=${x.sku}`).join('')


			if (product_reference) {
				let inventoryProductsResult = await productsAPI.getInventoryProducts(product_reference, 6, 'sku', process.env.REACT_APP_EATS_APP_ID)

				if (inventoryProductsResult.data && inventoryProductsResult.data.data && inventoryProductsResult.data.data.items) {
					this.setState({
						tagProducts: inventoryProductsResult.data.data.items
					})
				}

			}

		}


	}

	addSearchEvents = () => {
		let searchComponentEvent = window.serino_components.SearchProductListComponent.events

		document.addEventListener(searchComponentEvent.onAddToCart, (e) => {
			if (this.props.add) {
				this.props.add(e)
			}
		})

		document.addEventListener(searchComponentEvent.onUpdateIncrement, (e) => {
			if (this.props.increment) {
				this.props.increment(e)
			}
		})

		document.addEventListener(searchComponentEvent.onUpdateIncrement, (e) => {
			if (this.props.increment) {
				this.props.increment(e)
			}
		})

		document.addEventListener(searchComponentEvent.onUpdateDecrement, (e) => {
			if (this.props.decrement) {
				this.props.decrement(e)
			}
		})

		document.addEventListener(searchComponentEvent.onDeleteItem, (e) => {
			if (this.props.delete) {
				this.props.delete(e)
			}
		})
	}

    add = async (product) => {
        genericAddCartItem(product, this.handleMainAddress, this.handleUpdateCart)
    }

	update = async (cart_item) => {
        let updateCartItemResult = await cartAPI.updateQuantity(localStorage.getItem('access_token'), cart_item.application_id, cart_item.id, {
            quantity: cart_item.quantity,
            include_promo: true
        })
	}

	delete = async (cart_item) => {
		let deleteCartItemResult = await cartAPI.removeCartItem(localStorage.getItem('access_token'), cart_item.application_id, cart_item.id)
	}

    handleMainAddress = (appId) => {
        mainAddressHandler(appId)
    }

    handleUpdateCart = (cart_items) => {

    	let all_cart_items = this.state.cart_items

        let current_items = this.state.cart_items
        let newCartList = current_items.concat(cart_items)


        var uniq = newCartList.reduce(function(a,b){
          function indexOfProperty (a, b){
              for (var i=0;i<a.length;i++){
                  if((a[i].itemReferenceId == b.itemReferenceId) && (a[i].optionIds == b.optionIds)){
                       return i;
                   }
              }
             return -1;
          }

          if (indexOfProperty(a,b) < 0 ) a.push(b);
            return a;
        },[]);

        if (uniq) {
            all_cart_items = uniq
        }
        else {
            all_cart_items = this.state.cart_items
        }

        this.setState({
            cart_items: all_cart_items
        })

        this.props.dispatch({type: 'UPDATE', data: all_cart_items})
        
    }

    getMarketBranch = () => {
        let addressString = localStorage.getItem(`address_object_${market_app_id}`)

        if (!addressString) {
            return null
        }

        let parsed = JSON.parse(addressString)

        if (!parsed) {
            return null
        }

        let branch_id = parsed.branch_id

        if (!branch_id) {
            return null
        }

        return branch_id

    }

    getCart = async (isInitial = true, cart_items) => {
    	if (!isInitial && cart_items) {
    		this.setState({
    			cart_items: cart_items
    		})
    	}
    	else {
	    	const all_cart_items = await getCrossCart(localStorage.getItem('access_token'))


	    	this.setState({
	    		cart_items: all_cart_items
	    	})

	        this.props.dispatch({type: 'UPDATE', data: all_cart_items})

	    	return all_cart_items
    	}
    }

    handleBundleAdd = () => {
    	return
    }

    handleDeleteItems = () => {
    	return
    }

	render() {
		return (
			this.props.type === "2" ?
	        <div className={`modal fade`} id="search_modal" tabIndex="-1" role="dialog" aria-labelledby="search-modal" aria-hidden="true">
	            <div className="modal-dialog modal-dialog-scrollable" role="document">
	                <div className="modal-content srn-ft-main-container">
	                    <div className="modal-body fulfillmenttype-body">
	                    	<div className="srn-header-search-mweb" onClick={() => this.props.toggleSearch() ? this.props.toggleSearch() : null}>
		                        <span data-dismiss="modal" aria-label="Close" style={{marginBottom: "10px"}}>
		                          <FontAwesomeIcon icon={faChevronLeft} />
		                        </span>
		                        <li className="nav-item li-search ">
		                            <div className="srn-header-search-container">
		                            	<div id={this.props.id}></div>
		                              	<div className="srn-header-search-icon-container" onClick={() => this.props.toggleSearch() ? this.props.toggleSearch() : null}></div>
		                            </div>
		                        </li>
		                    </div>
			                    {/*<div className="srn-search-header">
				                    <div className="srn-search-header-title-container">
				                    	<h6 className="srn-search-header-title">Search in:</h6>
				                    </div>
				                    <div className="srn-search-header-navigation">
					                    <div className="srn-search-header-navigation-list">
						                    <a href="#!" className="srn-search-header-navigation-item srn-search-header-navigation-item-active">ALL</a>
						                    <a href="#!" className="srn-search-header-navigation-item">MARKET</a>
						                    <a href="#!" className="srn-search-header-navigation-item">EATS</a>
					                    </div>
				                    </div>
			                    </div>*/}
			                <div className={`srn-search-components ${this.state.areComponentsHidden ? 'd-none' : ''}`}>
			                	
	                            <div className="srn-title-container-v3">
	                                <h6 className="srn-title-v3">Shopping Baskets</h6>
	                                <a href="#!" className="srn-title-link-v4" onClick={() => window.location = "/market?shoppingbasket"}>VIEW ALL <FontAwesomeIcon icon={faAngleRight} className="ml-1" /></a>
	                            </div>
	                            <ShoppingBasketComponent 
	                                app_key={market_app_id}
	                                id="search_modal"
	                                className="srn-bundle-market"
	                                // handleClick={this.handleCollectionSelect}
	                                branch_id={this.getMarketBranch()}
	                                cart_items={this.state.cart_items}
	                                handleUpdateCart={this.getCart}
	                                handleInitialAdd={this.handleBundleAdd}
	                                handleDeleteItems={this.handleBundleDelete}
	                            />
	                            <div className="srn-title-container-v3">
	                                <h6 className="srn-title-v3">Best Sellers</h6>
	                                <a href="#!" className="srn-title-link-v4" onClick={() => window.location = "/eats"}>VIEW ALL <FontAwesomeIcon icon={faAngleRight} className="ml-1" /></a>
	                            </div>
								<ProductListComponent
                                    id="sc-product-list"
									products={this.state.tagProducts}
									cart_items={this.state.cart_items}
									add={this.add}
									update={this.update}
									delete={this.delete}
								/>
			                </div>
	                    	<div id="srn-best-deal"></div>
	                    </div>
	                </div>
	            </div>
	        </div> :
			<div id={this.props.id}></div>
		)
	}
}

const mapStateToProps = (state) => {
    return {
        cart_items: state.cart.cart_items
    };
}

export default connect(mapStateToProps)(SearchComponent);