import React, { Component, Fragment } from "react";
import $ from 'jquery'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faMapMarkerAlt, faTimes, faSpinner } from '@fortawesome/free-solid-svg-icons'
import FulfillmentTypeComponent from './fulfillmetType'
import Cart from '../../_core/cart'
import { setCrossStorage, getCrossStorage, getAccessToken, triggerSerinoEvent, log, getSwalContent, setHeaderAddress, handleExpiredToken, addEvt, setCurrentBranches } from '../../helper/helper'
import { fbqEvent } from "../../helper/fbpixel";

let market_app_key =  process.env.REACT_APP_MARKET_APP_ID
let eats_app_key =  process.env.REACT_APP_EATS_APP_ID
let cart = new Cart()
let counter = 0
let swalCounter = 0

export default class LocationPickerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pathName: window.location.pathname,
            address: localStorage.getItem('Address') ? localStorage.getItem('Address') : '',
            isSearchActive: false,
            Loading: false,
            host: [],
            domain: '',
            fulfillmentType: localStorage.getItem('fulfillment_type_status') ? localStorage.getItem('fulfillment_type_status') : '1',
            closing_time: '',
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : this.props.brand_id,
            brand_name: localStorage.getItem('brand_name') ? localStorage.getItem('brand_name') : '',
            busyStore: false ,
            offHours: false,
            domain_name: '',
            fulfillment_details: {},
            lat: '',
            lng: '',
            address_object: [],
            current_address: {},
            isAddressInputLoading: false,
            isLoggedIn: false,
            product: this.props.product ? this.props.product : {},
            cart_items: this.props.cart_items ? this.props.cart_items : {},
            previousBranch : '',
            isHidden: true,
            label: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.brand_id !== prevProps.brand_id) {
            // log('BRAND ID UPDATED ')
            this.setState({brand_id: this.props.brand_id})
        }
        if (this.props.product !== prevProps.product) {
            this.setState({product: this.props.product})
        }
        if (this.props.cart_items !== prevProps.cart_items) {
            this.setState({cart_items: this.props.cart_items}, () => {
                // this.setAddressesFromCart()
            })
        }
        if (this.props.triggerGetDefaultAddress !== prevProps.triggerGetDefaultAddress) {
            log('app key from triggerGetDefaultAddress', this.props.triggerGetDefaultAddress.app_key)
            this.getDefaultAddress(this.props.triggerGetDefaultAddress.app_key)
        }

        // if (this.state.address) {
            // setTimeout(() => {
                // Will add this feature in Component
                // document.getElementById('query-ft-modal-location').value = this.state.address
            //}, 1000)
            // $('#query-ft-modal-location').val(this.state.address)
        //}
    }

    componentDidMount = async () => {
        if (localStorage.getItem('Address') && localStorage.getItem('Address') === 'undefined') {
            localStorage.removeItem('Address')
        }
        counter++
        // console.log(this.props)
        let tmp = this
        var host = window.location.host
        var parts = host.split('.')
        let domain = (parts[0] == 'eats') || (parts[0] == 'market') ? 'sub' : 'main'

        this.setState({
            host: parts,
            domain: domain,
            domain_name: parts[0],
            // isAddressInputLoading: true
            // address_object: address_object
        })

        $('#descriptionModal').on('hidden.bs.modal', (e) => {
            triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
        })

        $('#descriptionDiscountModal').on('hidden.bs.modal', (e) => {
            triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
        })

        // let searchProductSide = window.serino_components.SearchProductListComponent.create({
        //     access_token: localStorage.getItem('access_token'),
        //     x_app_key: this.props.x_app_key,
        //     categordId: 13,
        //     branch_id: '',
        //     mode: this.props.x_app_key == market_app_key ? 1 : 2,
        //     id: 'location-picker'
        // }, "srn-search-product-list-container-side");

        let searchComponentEvent =
          window.serino_components.SearchProductListComponent.events;
          
        /** Event listener for clicking View All in search for Market */
        document.addEventListener(searchComponentEvent.onViewAllResult, (e) => {
            const searchObject = {
                query: e.detail.searchKeyword,
                app: 1
            }
            sessionStorage.setItem('searchQueryObj', JSON.stringify(searchObject))
            window.location = `/search`
        })

        /** Event listener for clicking View All in search for Eats */
        document.addEventListener(searchComponentEvent.onViewAllResultEats, (e) => {
            const searchObject = {
                query: e.detail.searchKeyword,
                app: 2,
                brand: e.detail.brand,
            }
            sessionStorage.setItem('searchQueryObj', JSON.stringify(searchObject))
            window.location = `/search`
        })

        document.addEventListener(window.serino_components.FulfillmentTypeComponent.events.onCheckStoreStatusFail, (e) => {
            swalCounter++
            if (swalCounter == 1) {
                Swal.fire({
                    html: getSwalContent('this store is currently unavailable in your initial location'),
                    customClass: {
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                    }
                }).then(res => {
                    $('#fulfillmenttype_modal').modal('show')
                    setTimeout(() => {
                        swalCounter = 0
                    }, 2000)
                })
            }
        })
        document.addEventListener(window.serino_components.FulfillmentTypeComponent.events.onTokenExpire, () => {
            handleExpiredToken()
        })
    }

    setAddressesFromCart = () => {

        if (this.state.cart_items && this.state.cart_items.length > 0) {

            let items = this.state.cart_items

            let page = this.props.page

            let customDataArray = items.map(x => {
                let custom_data = x.custom_data && x.custom_data.length > 0 ? JSON.parse(x.custom_data) : null

                if (!custom_data) {
                    return null
                }
                let mappedCustomData = custom_data.filter(x => x.name === 'address').map(x => x.value)

                return mappedCustomData
            })
        }

    }

    setAddressObject = async (closing_time, offHours, busy_store, branch_id, app_id, fulfillmentType, pickupDateTime, note_to_store) => {

        let address_object = await getCrossStorage(`address_object_${app_id}`, localStorage.getItem('domain'))
        // console.log(address_object)
        if (address_object && address_object.length > 0) {
            address_object = JSON.parse(address_object)
        }
        else {
            address_object = []
        }

        let location_object = {}
        let fulfillment_details = this.state.fulfillment_details

        if (fulfillmentType && fulfillmentType == 2) {
            let pickup_offhours = offHours == null ? false : offHours
            let pickup_busy = busy_store == null ? false :  busy_store
            location_object = {
                app_id: app_id,
                address: localStorage.getItem('Address'),
                brand_id: localStorage.getItem('brand_id'),
                brand_name: localStorage.getItem('brand_name'),
                branch_name: fulfillment_details.branch_name,
                branch_id: branch_id,
                fulfillmentType_status: fulfillment_details.fulfillmentType_status,
                fulfillmentType: 'Pickup',
                offHours: pickup_offhours,
                busyStore: pickup_busy
            }
        }
        else {
            
            let fulfillmentType_status = 1
            let fulfillmentType = 'Delivery'

            if (fulfillment_details.busyStore || fulfillment_details.offHours || app_id == market_app_key) {
                fulfillmentType_status = 3
                fulfillmentType = 'Deliver Later'
            }
            location_object = {
                address: localStorage.getItem('Address'),
                house_no: fulfillment_details.house_no,
                floor: fulfillment_details.floor,
                brand_id: localStorage.getItem('brand_id'),
                brand_name: localStorage.getItem('brand_name'),
                lng: this.state.lng,
                lat: this.state.lat,
                closing_time: closing_time,
                offHours: offHours,
                busyStore: busy_store,
                app_id: app_id,
                fulfillmentType_status: fulfillmentType_status,
                fulfillmentType: fulfillmentType,
                branch_id: branch_id,
                province: fulfillment_details.province,
                city: fulfillment_details.city,
                barangay: fulfillment_details.barangay,
                landmark: fulfillment_details.note_to_store,
                label: this.state.label
            }

            log({location_object})
        }

        if (location_object && location_object.brand_id && location_object.brand_id.toString().length > 0 && address_object && address_object.filter(e => e.brand_id == location_object.brand_id).length > 0) {
            let i = address_object.findIndex(e => e.brand_id == location_object.brand_id)
            address_object[i] = location_object

            let cart_items = []

            let cartResult = await cart.getCartItems(localStorage.getItem('access_token'), app_id)

            localStorage.setItem(`${app_id == eats_app_key ? 'eats' : 'market'}_cart_count`, 0)

            console.log('cart from clear', cartResult.data)
            console.log('selected location object', address_object[i])

            if (cartResult.data && cartResult.data.length > 0) {
                cartResult.data.forEach(cart_item => {
                    log('previousBranch', this.state.previousBranch)
                    log('domainReferenceId', cart_item.domainReferenceId)
                    if (cart_item.domainReferenceId == this.state.previousBranch) {
                        cart_items.push(cart_item.id)
                    }
                })

                let existingBranches = new Set(address_object.map(x => parseInt(x.branch_id)));

                // Delete items without fulfillment
                cartResult.data.forEach(cart_item => {
                    if (!existingBranches.has(parseInt(cart_item.domainReferenceId))) {
                      cart_items.push(cart_item.id) 
                    }
                })

                cart_items = [...new Set(cart_items)];

                let clearCartResult = cart.deleteCartItems(localStorage.getItem('access_token'), app_id, cart_items).then(res => {
                    // if (this.props.handleLocationChange) {
                    //     this.setState({previousBranch: localStorage.getItem('branch_id')})
                    //     this.props.handleLocationChange(address_object)
                    // }
                    // else {
                    //     window.location.reload()
                    // }
                })

            }
            else {
                if (this.props.handleLocationChange) {
                    this.setState({previousBranch: localStorage.getItem('branch_id')})
                    setCurrentBranches()
                    this.props.handleLocationChange(address_object)
                }
                else {
                    window.location.reload()
                }
            }
        }
        else {
            address_object.push(location_object)
        }


        this.setState({
            address_object,
            isAddressInputLoading: false,
            isHidden: false
        })
        let stringified = JSON.stringify(address_object)

        log('fulfillmentType', this.state.fulfillmentType)

        if (this.state.fulfillmentType && this.state.fulfillmentType != 2) {
            
            log('address object from setAddressObject', location_object)

            let main_address = {
                address: location_object.address,
                floor: location_object.floor,
                house_no: location_object.house_no,
                province: location_object.province,
                landmark: location_object.landmark,
                city: location_object.city,
                barangay: location_object.barangay,
                lat: location_object.lat,
                lng: location_object.lng,
                fulfillmentType_status: location_object.fulfillmentType_status,
                fulfillmentType: location_object.fulfillmentType,
                label: location_object.label
            }

            log('fulfillment_details.actionType', fulfillment_details.actionType)

            let main_address_object = localStorage.getItem('main_address')

            // if (fulfillment_details.actionType === 'initial') {

            //     if (!main_address_object || main_address_object == null || main_address_object.length <= 2) {
            //         log({main_address})

            //         await this.handleClearCart()

            //         localStorage.removeItem(`address_object_${market_app_key}`)
            //         localStorage.removeItem(`address_object_${eats_app_key}`)

            //         setHeaderAddress(main_address)
            //         localStorage.setItem(`main_address`, JSON.stringify(main_address))
            //         setCrossStorage(`main_address`, JSON.stringify(main_address), localStorage.getItem('domain'))
            //     }
            //     else {
            //         setHeaderAddress(main_address)
            //         localStorage.setItem(`address_object_${app_id}`, stringified)
            //         setCrossStorage(`address_object_${app_id}`, stringified, localStorage.getItem('domain'))

            //         // let eats_address_object = localStorage.getItem(`address_object_${eats_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${eats_app_key}`)) : []
            //         // let market_address_object = localStorage.getItem(`address_object_${market_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_key}`)) : []

            //         // let combined = eats_address_object.concat(market_address_object)

            //         // log({combined})
            //     }
            // }

            if (fulfillment_details.actionType === 'initial') {

                log({main_address})

                await this.handleClearCart()

                localStorage.removeItem(`address_object_${market_app_key}`)
                localStorage.removeItem(`address_object_${eats_app_key}`)

                setHeaderAddress(main_address)
                localStorage.setItem(`main_address`, JSON.stringify(main_address))
                setCrossStorage(`main_address`, JSON.stringify(main_address), localStorage.getItem('domain'))
            }
            else {

                if (!main_address_object || main_address_object == null || main_address_object.length <= 2) {
                    log({main_address})

                    setHeaderAddress(main_address)
                    localStorage.setItem(`main_address`, JSON.stringify(main_address))
                    setCrossStorage(`main_address`, JSON.stringify(main_address), localStorage.getItem('domain'))
                }

                setHeaderAddress(main_address)
                localStorage.setItem(`address_object_${app_id}`, stringified)
                setCrossStorage(`address_object_${app_id}`, stringified, localStorage.getItem('domain'))

            }
        }
        else {

            localStorage.setItem(`address_object_${app_id}`, stringified)
            setCrossStorage(`address_object_${app_id}`, stringified, localStorage.getItem('domain'))
            log('pickup setheaded')
            let domain = window.location.pathname.includes('market') || window.location.pathname.includes('eats-products') ? 'sub' : 'main'
            if (domain === 'sub') {
                setHeaderAddress(location_object)
            }
        }


        if (this.props.handleLocationChange) {
            setCurrentBranches()
            // console.log('handleLocationChange')
            this.props.handleLocationChange(address_object, branch_id)
        }


    }

    handleClearCart = async () => {
        let marketCartResult = await cart.getCartItems(localStorage.getItem('access_token'), market_app_key)
        let eatsCartResult = await cart.getCartItems(localStorage.getItem('access_token'), eats_app_key)

        console.log('cart from handleClearCart', eatsCartResult.data)
        
        if (marketCartResult.data && marketCartResult.data.length > 0) {

            let marketItems = marketCartResult.data.map(x => x.id)

            log({marketItems})

            let marketClearCartResult = cart.deleteCartItems(localStorage.getItem('access_token'), market_app_key, marketItems)
            
        }

        if (eatsCartResult.data && eatsCartResult.data.length > 0) {

            let eatsItems = eatsCartResult.data.map(x => x.id)

            log({eatsItems})

            let marketClearCartResult = cart.deleteCartItems(localStorage.getItem('access_token'), eats_app_key, eatsItems)
        }

        localStorage.setItem('cart_items', [])

        $('.srn-cart-quantity').text(0)
    }

    getAddressObject = async (domain) => {

        log('DOMAIN', domain)

        let eats_address_object = []

        eats_address_object = await getCrossStorage(`address_object_${eats_app_key}`, domain).then(res => {
            if (res && res.length > 0) {
                return res
            }
            else {
                return []
            }
        })

        if (eats_address_object && eats_address_object.length > 0) {
            log('ADDRESS OBJECT FROM GET ADDRESS OBJECT', eats_address_object)
            let parsed = JSON.parse(eats_address_object)
            let address = parsed.find(x => x.brand_id == localStorage.getItem('brand_id'))
            this.setState({
                address_object: parsed,
                current_address: address,
                address
            }, () => {
                if (this.props.getAddressObject) {
                    this.props.getAddressObject(eats_address_object, address)
                }
            })
        }

    }

    getFulfillmentDetails = (data) => {

        console.log('fulfillment data', data)

        let app_id = data.appId
        
        this.setState({fulfillment_details: data, previousBranch: localStorage.getItem('branch_id')}, () => {
            log('previousBranch latest', this.state.previousBranch)
            if (data.fulfillmentType_status != 2 && data.location && data.location.position && data.location.position.lat) {                
                localStorage.setItem('Address', data.address)
                localStorage.setItem('branch_id', data.branch_id)
                localStorage.setItem('closing_time', data.closing_time)
                localStorage.setItem('offHours', data.offHours)
                localStorage.setItem('busy_store', data.busyStore)
                this.setState({
                    address: data.address,
                    branch_id: data.branch_id,
                    lat: data.location.position.lat,
                    lng: data.location.position.lng,
                    isAddressInputLoading: true,
                    fulfillmentType: data.fulfillmentType_status,
                    label: data.label
                })
                this.setAddressObject(data.closing_time, data.offHours, data.busyStore, data.branch_id, app_id)
            }
            else {
                this.setState({
                    address: data.address,
                    branch_id: data.branch_id,
                    isAddressInputLoading: true,                    
                    fulfillmentType: data.fulfillmentType_status
                })
                localStorage.setItem('Address', data.address)
                localStorage.setItem('branch_id', data.branch_id)
                this.setAddressObject(null, null, null, data.branch_id, app_id, data.fulfillmentType_status, data.pickupDateTime, data.note_to_store)
            }
        })
    }
    
    searchLoading(e) {
        if (e.detail == 1){
            this.setState({ Loading: true,  isSearchActive: false})
            setTimeout(() => { 
                document.getElementById(`search-${this.props.id}`).click() 
                this.setState({ Loading: false,  isSearchActive: true})
            }, 500)
        }
    }

    openFulfillmentTypeModal = async () => {
        triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.onFulfillmentOpen, true)
        let cart_items = this.props.cart_items

        if (cart_items && cart_items.length > 0) {
            let clearCartConfirmation2 = await Swal.fire({
                html: getSwalContent('Deleting your address will clear all the items in your cart. Continue?'),
                confirmButtonText: 'YES',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'                        }
            })

            if (clearCartConfirmation2.isDismissed) {
                return
            }
        }


        $('#fulfillmenttype_modal').modal({
            backdrop: 'static',
            keyboard: false
        })

    }

    clearAddress = async () => {

        let clearCartConfirmation = await Swal.fire({
            html: getSwalContent('Deleting your address will clear all the items in your cart. Continue?'),
            confirmButtonText: 'YES',
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            customClass: {
                confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                cancelButton: 'cmb-btn-primary-swal-checkoutcancel'                        }
        })

        if (clearCartConfirmation.isDismissed) {
            return
        }

        let cart_items = []

        let cartResult = await cart.getCartItems(localStorage.getItem('access_token'), this.props.x_app_key)

        console.log('cart from clear', cartResult)

        if (cartResult.data && cartResult.data.length > 0) {
            cartResult.data.forEach(cart_item => {
                if (cart_item.domainReferenceId == localStorage.getItem('branch_id')) {
                    cart_items.push(cart_item.id)
                }
            })

            log('cart_items', cart_items)

            let clearCartResult = await cart.deleteCartItems(localStorage.getItem('access_token'), this.props.x_app_key, cart_items)
        }

         
        document.getElementById(`query-${this.props.id}`).value = ''

        let res = await getCrossStorage(`address_object_${this.props.x_app_key}`, this.state.domain)

        let address_object = []

        log('res', res)
        
        if (res && res.length > 0) {
            address_object = JSON.parse(res)
        }

        if (address_object.filter(e => e.brand_id == localStorage.getItem('brand_id')).length > 0) {
            let i = address_object.findIndex(e => e.brand_id == localStorage.getItem('brand_id'))
            address_object.splice(i, 1)

            console.log('address object', address_object)
            
            let stringified = JSON.stringify(address_object)

            var host = window.location.host
            var parts = host.split('.')
            let domain = (parts[0] == 'eats') || (parts[0] == 'market') ? 'sub' : 'main'

            log('host', host)
            log('domain', domain)
            log('domain localhost', domain)

            setCrossStorage(`address_object_${this.props.x_app_key}`, '[]', localStorage.getItem('domain'))
            localStorage.setItem(`address_object_${this.props.x_app_key}`, '[]')
        }
     
        // localStorage.removeItem('brand_id')
        localStorage.removeItem('branch_id')
        localStorage.removeItem('Address')
        localStorage.removeItem('longitude')
        localStorage.removeItem('latitude')
        localStorage.removeItem('fulfillment_type_status')
        localStorage.removeItem('address_object')
        localStorage.removeItem(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`)
        sessionStorage.setItem('clickClear', 1)


        this.setState({
            address_object: [],
            address: '',
            current_address: '',
            branch_id: '',
        }, () => {
            if (this.props.handleClearAddress) {
                this.props.handleClearAddress()
            }
        })
    }

    getDefaultAddress = async (app_key) => {
        log('getDefaultAddress called')
        getCrossStorage('isLoggedIn', localStorage.getItem('domain'))
            .then((res) => {
                console.log('__isLoggedin', res)
                if (res === true || res === "true") {
                    setTimeout(() => {
                        if (sessionStorage.getItem('clickClear') && sessionStorage.getItem('clickClear') === 1 || sessionStorage.getItem('clickClear') === "1") {
                            return
                        } else {

                            // console.log('___getDEFAULTADDRESS')
                            let addresslist_crm_events = window.serino_components.crm.events.addressEvents
                            let srn_addresslist_component = window.serino_components.crm.createAddressComponent({
                                access_token: localStorage.getItem('access_token'),
                                x_app_key: this.props.x_app_key
                            }, "srn-crm-addresslist-container");
                            
                            document.addEventListener(addresslist_crm_events.defaultAddress, (e) => {
                                if (e.detail && e.detail.length >=1) {
                                    let primaryAddress = e.detail.find(x => x.isPrimary == 1)
                                    if (!primaryAddress) {
                                        return;
                                    }
                                    // log({primaryAddress})
                                    let address = primaryAddress.city + ', ' + primaryAddress.province + ', ' + primaryAddress.country
                                    let parsed_data = JSON.parse(primaryAddress.custom_data) ? JSON.parse(primaryAddress.custom_data) : null
                                    if (parsed_data && parsed_data.address && parsed_data.address != undefined) {
                                        address = (parsed_data && parsed_data.address) && parsed_data.address != undefined ? parsed_data.address : primaryAddress.city+", "+primaryAddress.province+" "+primaryAddress.country
                                    }
                                    log('address from default', address)
                                    if (!primaryAddress.custom_data) {
                                        return;
                                    }
                                    let custom_data = JSON.parse(primaryAddress.custom_data)
                                    if (!custom_data) {
                                        return;
                                    }
                                    // console.log({address})
                                    // console.log('___getDEFAULTADDRESS00', e.detail)
                                    // console.log('___getDEFAULTADDRESS00', address)
                                    this.setState({
                                        address: address
                                    }, () => {
                                        localStorage.setItem('Address', address)
                                        // if (document.getElementById(`query-${this.props.id}`)) {
                                        //     document.getElementById(`query-${this.props.id}`).text = address
                                        //     document.getElementById(`query-${this.props.id}`).value = address
                                        // }

                                        let main_address = localStorage.getItem('main_address')

                                        if (!custom_data.barangay) {
                                            return;
                                        }

                                        log('main address from locationpicker', main_address)

                                        if (!main_address) {
                                            let new_main = {
                                                "address": address,
                                                "floor": primaryAddress.line2,
                                                "house_no": primaryAddress.line1,
                                                "province": primaryAddress.province,
                                                "city": primaryAddress.city,
                                                "barangay": custom_data.barangay,
                                                "lat": primaryAddress.latitude,
                                                "lng": primaryAddress.longitude,
                                                "fulfillmentType_status": this.props.x_app_key === market_app_key ? 3 : 1,
                                                "fulfillmentType": this.props.x_app_key === market_app_key ? "Deliver Later" : "Delivery",
                                                "label": primaryAddress.label,
                                                "landmark": custom_data.landmark
                                            }
                                            localStorage.setItem('default_address', JSON.stringify(new_main))
                                            

                                            setHeaderAddress({
                                                address: address,
                                                fulfillmentType: this.props.x_app_key == market_app_key ? 'Deliver Later' : 'Delivery'
                                            })
                                        }
                                        else {
                                            let parsed_main_address = JSON.parse(main_address)?.address
                                            localStorage.setItem('Address', parsed_main_address)
                                            setHeaderAddress({
                                                address: JSON.parse(localStorage.getItem('main_address'))?.address,
                                                fulfillmentType: this.props.x_app_key == market_app_key ? 'Deliver Later' : 'Delivery'
                                            })
                                        }

                                        // triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.onCheckStoreStatus, {
                                        //     location: JSON.parse(localStorage.getItem('main_address')),
                                        //     brand_id: localStorage.getItem('brand_id'),
                                        //     type: 'initial'
                                        // })

                                    })
                                    
                                }
                                else {                
                                    localStorage.removeItem('branch_id')
                                    localStorage.removeItem('address_object')
                                    localStorage.removeItem(`address_object_${app_key}`)
                                    localStorage.removeItem('Address')
                                    localStorage.removeItem('fulfillment_type_status')
                                    if (document.getElementById(`query-${this.props.id}`)) {
                                        document.getElementById(`query-${this.props.id}`).value = ''
                                        document.getElementById(`query-${this.props.id}`).text = ''
                                    }
                                }

                            })

                            this.setState({
                                isAddressInputLoading: false,            
                                isHidden: false
                            })
                        }
                    }, 2000);
                }
                else {
                    this.setState({
                        isAddressInputLoading: false,
                        isHidden: false
                    })
                    this.handleMainAddress()
                }
            }).catch(() => {
                this.setState({
                    isAddressInputLoading: false,
                    isHidden: false
                })
                this.handleMainAddress()
            })
    }

    handleMainAddress = () => {
        
        let main_address = localStorage.getItem('main_address')
        
        let parsed_main_address = JSON.parse(main_address)?.address
        localStorage.setItem('Address', parsed_main_address)
        setHeaderAddress({
            address: JSON.parse(localStorage.getItem('main_address'))?.address,
            fulfillmentType: this.props.x_app_key == market_app_key ? 'Deliver Later' : 'Delivery'
        })
    }

    render() {
        return (
            <Fragment>
                
                <div className={`location-picker-container-container ${(this.props.hidden && this.props.hidden == true) ? 'd-none' : ''}`}>
                    <div className={`location-picker-container srn-location-picker-container`} id="location-picker-container">
                        <div className={`input-group location-picker srn-location-picker-content`}>
                            <div className="input-group-prepend">
                                {
                                    // this.state.pathName && this.state.pathName === '/products' || this.state.pathName === '/cart' ?
                                     <button className="cmb-logo-deliver-btn" onClick={() => this.openFulfillmentTypeModal()}>
                                        <span className="srn-map-icon">
                                            {
                                                this.state.isAddressInputLoading ?
                                                <FontAwesomeIcon icon={faSpinner} spin /> :
                                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                            }
                                        </span>
                                        {
                                            localStorage.getItem('fulfillment_type_status') === '3' ?
                                            "Deliver Later to" :
                                            localStorage.getItem('fulfillment_type_status') === '2' ?
                                            "Pickup" :
                                            "Deliver to"
                                        } 
                                        <FontAwesomeIcon icon={faAngleRight} className="mr-2" />
                                    </button>
                                    // : null
                                }
                                
                            </div>
                            <input  className="form-control rounded-0 h-100 cmb-here-location-picker"
                                type="text"
                                id={`query-${this.props.id}`}
                                name="address"
                                value={localStorage.getItem('Address') && localStorage.getItem('Address') != undefined ? localStorage.getItem('Address') : '' }
                                readOnly={true}
                                placeholder={`Enter street, city and click search`}
                                disabled={this.props.disabled != null ? this.props.disabled : false}
                                onClick={() => this.openFulfillmentTypeModal()}
                            />
                            {
                                this.state.address && this.state.address.length > 0 ?
                                    <div className="input-group-append mr-15 new-location-search-btn">
                                        <button href="#!" className="btn btn-sm map-picker" id={`clear-${this.props.id}`}>
                                            {
                                                this.state.isAddressInputLoading ? 
                                                <FontAwesomeIcon icon={faSpinner} spin /> :
                                                <FontAwesomeIcon icon={faTimes} onClick={(e) => this.clearAddress(e,this.props.id)}/>
                                            }
                                        </button>
                                    </div>
                                : ''
                            }
                        </div>

                        <div id="srn-crm-addresslist-container" className="d-none"></div>

                    </div>
                    <div className={`location-picker-container srn-search-container srn-search-lp`}>
                        <div id="srn-search-product-list-container-side"></div>
                    </div>
                </div>
                <FulfillmentTypeComponent
                    access_token={this.props.access_token}
                    x_app_key={this.props.x_app_key}
                    closing_time={this.state.address_object.closing_time}
                    offHours={this.state.address_object.offHours}
                    brand_id={localStorage.getItem('brand_id')}
                    busyStore={this.state.address_object.busyStore}
                    getFulfillmentDetails={this.getFulfillmentDetails}
                    product={this.state.product}
                    isPickupDisabled={this.props.isPickupDisabled}
                    handleModalClose={this.props.handleModalClose ? this.props.handleModalClose : null}
                    main={this.props.main}
                />
            </Fragment>
        )
    }
}