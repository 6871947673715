const proceed = true

export const fbqEvent = (event, params) => {
	if (proceed) {
		switch (event) {
			case 'AddToCart':
				window.fbq('track', 'AddToCart', params)
				break;
			case 'AddPaymentInfo':
				window.fbq('track', 'AddPaymentInfo')
				break;
			case 'CompleteRegistration':
				window.fbq('track', 'CompleteRegistration')
				break;
			case 'InitiateCheckout':
				window.fbq('track', 'InitiateCheckout')
				break;
			case 'Purchase':
				window.fbq('track', 'Purchase', params);
				break;
			case 'ViewContent':
				window.fbq('track', 'ViewContent', params)
				break;
			case 'Search':
				window.fbq('track', 'Search')
				break;
			case 'Schedule':
				window.fbq('track', 'Schedule')
				break;
			default:
				break;
		}
	}
}
