import { headerActionTypes } from '../actions'

const activeAppInitialState = 0
const activeAppReducer = (state = activeAppInitialState, action) => {
    let newState

    switch (action.type) {
        case headerActionTypes.SELECT_APPLICATION:
            newState = action.payload.appId
            break
        default:
            newState = state
            break
    }

    return newState
}
const appSelectorInitialState = null
const appSelectorReducer = (state = appSelectorInitialState, action) => {
    let newState

    switch (action.type) {
        case headerActionTypes.MOUNT_APPSELECTOR:
            newState = action.payload.appSelector
            break
        default:
            newState = state
            break
    }

    return newState
}

export const headerReducers = {
    activeApp: activeAppReducer,
    appSelector: appSelectorReducer
}