/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import $ from "jquery";
import adstop from "../../../assets/images/eats_side_banner1.png";
import adsbot from "../../../assets/images/eats_side_banner2.png";
import React, { Component } from "react";
import EatsLogo from "../../../assets/images/central_gold.png";
import BeverageBanner from "../../../assets/images/Food Deal Banners/food_deals_beveragesBanner.jpg";
import BurgerBanner from "../../../assets/images/Food Deal Banners/food_deals_burgerBanner.jpg";
import ChickenBanner from "../../../assets/images/Food Deal Banners/food_deals_chickenBanner.jpg";
import DesertBanner from "../../../assets/images/Food Deal Banners/food_deals_dessertBanner.jpg";
import DimsumBanner from "../../../assets/images/Food Deal Banners/food_deals_dimsumBanner.jpg";
import PizzaBanner from "../../../assets/images/Food Deal Banners/food_deals_pizzaBanner.jpg";
import SeafoodBanner from "../../../assets/images/Food Deal Banners/food_deals_seafoodsBanner.jpg";
import NoodleBanner from "../../../assets/images/food_type_banner_noodles.jpg";
// import LandersLogo from "../../../assets/images/landers_central2x.png";
import {
  addToCartAnalytics,
  addToCartHub,
  updateCartAnalytics,
  updateCartHub,
  GAImpressions
} from "../../../helper/analytics";
import {
  getAccessToken,
  getCrossStorage,
  getSubdomainCartCount,
  getUrl,
  handleExpiredToken,
  log,
  setHeaderAddress,
  setSubdomainCartCount,
  triggerSerinoEvent,
  getThumbnail,
  mainAddressHandler,
  getAddressObject,
  getAddressFromObjectChecker,
  handleShowProductActions
} from "../../../helper/helper";
import ApplicationAPI from "../../../_core/application";
import Cart from "../../../_core/cart";
import LocationPicker from "../../partials/LocationPicker";
import BrandListComponent from "./brand-list-component";
import CoreAPI from '../../../_core/core'

import left_popeyes from "../../../../src/assets/images/Eats_sidebar-images/Popeyes_sidebar.png";
import left_kuyaj from "../../../../src/assets/images/Eats_sidebar-images/KuyaJ_sidebar.png";
import left_central from "../../../../src/assets/images/Eats_sidebar-images/Central_sidebar.png";
import left_bough from "../../../../src/assets/images/Eats_sidebar-images/Doughandco_sidebar.png";
import left_majestic from "../../../../src/assets/images/Eats_sidebar-images/Majestic_sidebar.png";
import left_gianni from "../../../../src/assets/images/Eats_sidebar-images/Gianni_sidebar.png";

const PopeyesLogo =
  "https://qa-central.s3-ap-southeast-1.amazonaws.com/popeyes_circle_v1.png";
const HnkLogo =
  "https://qa-centralmain.s3.ap-southeast-1.amazonaws.com/cms/media/1631503958990-Hungry%20Nomad%20Kitchen-Logo.png";
const KuyaJLogo =
  "https://prod-landers.s3-ap-southeast-1.amazonaws.com/kuyajlogo.png";
const LandersLogo =
  "https://s3-ap-southeast-1.amazonaws.com/qa-centralmain/LOGOS-1.2.png";
const DoughLogo =
  "https://s3-ap-southeast-1.amazonaws.com/qa-centralmain/banners/LOGOS-7.png";
const GianiLogo =
  "https://s3-ap-southeast-1.amazonaws.com/qa-centralmain/banners/LOGOS-6.png";
const MajesticLogo =
  "https://s3-ap-southeast-1.amazonaws.com/qa-centralmain/banners/LOGOS-8.png";
const TrattoriaLogo =
  "https://centraldelivery.ph/static/media/trattoria_da_gianni_logo.7786a291.png";

var cart = new Cart();
var application = new ApplicationAPI();
let coreAPI = new CoreAPI()

const API_URL = process.env.REACT_APP_SERINO_API;
const x_app_key = process.env.REACT_APP_EATS_APP_ID;

let mountCount = 0;

export default class EatsProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newKeyword: "",
      isProductLoading: false,
      isLoading: false,
      product: null,
      cart_items: [],
      srn_category_list_component: null,
      shippingFee:
        localStorage.getItem("fulfillment_type_status") &&
        localStorage.getItem("fulfillment_type_status") === 1
          ? 30
          : 0,
      access_token: "",
      similar_product_switch: false,
      branch_id: "",
      address_object: [],
      brand_banner: [],
      fromFoodType: false,
      fromSearch: false,
      searchKeyword: "",
      variants: null,
      similar_items: [],
      brandSelect: sessionStorage.getItem("brand_name")
        ? sessionStorage.getItem("brand_name")
        : "All brands",
      isDropdownActive: false,
      foodTypeBrands: [],
      triggerGetDefaultAddress: {
        state: false,
        app_key: x_app_key,
      },
      isAddBtnLoading: false,
      selected_category_id: "",
      isBundle: false,
      eatsProducts: [],
      isSearchActive: false
    };
  }
  async componentDidMount() {
    sessionStorage.removeItem("signupPage", true)

    if (sessionStorage.getItem("viewAction") != "foodTypeSelect") {
      this.handleBrandFromUrl()
    } else {
      this.setState({fromFoodType: true})
    }

    let bestPick = window.serino_components.ProductTagsComponent.create(
      {
        access_token: localStorage.getItem("access_token"),
        x_app_key: process.env.REACT_APP_MARKET_APP_ID,
        currency: "₱",
        time_interval: 8000,
        tags: sessionStorage.getItem("foodType")
          ? sessionStorage.getItem("foodType").toLocaleLowerCase()
          : "",
        branch_id: sessionStorage.getItem("brand_id") ? sessionStorage.getItem("brand_id") : 0,
        viewMoreEnabled: false,
        id: "main-landing-best-pick",
      },
      "food-deals"
    );

    axios.get(`${API_URL}/branches/public/brands/all?status=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "X-APP-KEY": x_app_key,
        },
      })
      .then((res) => {
        this.setState({
          foodTypeBrands: res.data.data,
          access_token: localStorage.getItem("access_token"),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  init = async () => {


    if (this.props.location.search?.length > 0) {
      let search = this.props.location.search.substring(1);
      let searchTest = this.props.location.search.substring(1, 5);
      log({ search });

      if (searchTest === "pdes") {
        let objFromUrl = JSON.parse(
          '{"' +
            decodeURI(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );
        let product_id = objFromUrl.pdesc;

        // if (product_id) {
        //   application.getProductDetails(x_app_key, product_id).then((res) => {
        //     // this.productDescription(res.data.items[0], x_app_key)
        //   });
        // }
      }

      // if (searchTest === 'type') {

      //     let objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')

      //     sessionStorage.setItem('viewAction', 'bundle')

      //     this.handleBundleRedirect(objFromUrl)
      // }
    }

    mountCount++;
    log(mountCount);
    const selected_category_id = this.props.location.state;
    this.setState({ selected_category_id });
    this.headerChange();
    localStorage.setItem("domain", "sub");

    // if (!localStorage.getItem('brand_id')) {
    //     window.location = '/'
    // }

    $(".carousel").carousel();

    // Get Address Object from Main page
    let address_object;

      let access_token = await getAccessToken();

      this.setState({ access_token: access_token });

      address_object = await getCrossStorage(
        `address_object_${process.env.REACT_APP_EATS_APP_ID}`,
        "sub"
      );

      if (address_object && address_object.length > 2) {
        let parsed = JSON.parse(address_object);
        log("parsed", parsed);
        if (parsed && parsed.length > 0) {
          let address = parsed.find(
            (x) => x.brand_id == localStorage.getItem("brand_id")
          );
          log("eats address", address);
          if (address && address != null) {
            localStorage.setItem("branch_id", address.branch_id);
            localStorage.setItem("address_object", address_object);
            localStorage.setItem(
              `address_object_${process.env.REACT_APP_EATS_APP_ID}`,
              address_object
            );
            localStorage.setItem("Address", address.address);
            localStorage.setItem(
              "fulfillment_type_status",
              address.fulfillmentType_status
            );
          } else {
            this.setMainAddress();
          }

          if (
            document.getElementById("query-eats_products_page") &&
            address &&
            address.address &&
            address.address != undefined
          ) {
            document.getElementById("query-eats_products_page").value =
              address.address;
          }

          this.getCart(null, 'initial');

          // document.getElementById('query-eats_products_page').value = parsed[0].address
        } else {
          localStorage.removeItem("branch_id");
          localStorage.removeItem(`Address`);

          if (document.getElementById("query-eats_products_page")) {
            document.getElementById("query-eats_products_page").text = "";
          }
        }
      } else {
        this.setMainAddress();
      }
        axios
          .get(`${API_URL}/branches/public/brands/all?status=1`, {
            headers: {
              Authorization: "Bearer " + access_token,
              "X-APP-KEY": x_app_key,
            },
          })
          .then((res) => {
            this.setState({
              foodTypeBrands: res.data.data,
              access_token: access_token,
            });
          })
          .catch((err) => {
            console.log(err);
          });

      let brand_id = localStorage.getItem("brand_id");
      let banner = "";
      application
        .getBrandBanners(access_token, x_app_key)
        .then((res) => {
          banner = JSON.parse(res.data[3].value).find(
            (x) => x.brand_id === brand_id
          ).banners;
          this.setState({
            brand_banner: banner,
          });
          console.log("bannerstate",this.state.brand_banner)
        })
        .catch((err) => {
          console.log(err);
        });
      // place cart events inside variable
      let cartEvents = window.serino_components.cart.events;

      // listen to cart events
      // document.addEventListener(cartEvents.onLoad('eatsCartProducts'), (event) => {
      //     triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
      //     this.getCart()
      //     if (event.detail && event.detail.cart) {
      //         let current_cart = event.detail.cart.find(x => x.brand.id == localStorage.getItem('brand_id'))
      //         if (event.detail && event.detail.cart && current_cart) {

      //             let cart = event.detail.cart.length
      //             if (cart >= 1) {
      //                 this.setState({
      //                     cart_items: current_cart.cart_items
      //                 }, () => {
      //                     this.productListSubscription()
      //                     this.productDescriptionSubscription()
      //                 })
      //             }
      //         }
      //     }

      // })

      // document.addEventListener(cartEvents.onCartUpdated('eatsCartProducts'), (event) => {
      //     triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
      //     console.log('cart onCartUpdated', event)
      // })

      // let component1 = window.serino_components.BrandListComponent.create({
      //     access_token: access_token,
      //     x_app_key: x_app_key,
      //     time_interval: 10000,
      //     status: 1// blank = all, 0 = inactive, 1 = active
      // }, "srn-brand-selector-container")

      // let brandListEvents = window.serino_components.BrandListComponent.events

      this.initializeComponents(access_token)


    getSubdomainCartCount();
  }

  initializeComponents = (access_token) => {
      setTimeout(() => {
        if (sessionStorage.getItem("searchKeyWord") != null && sessionStorage.getItem('viewAllAction') === 'viewSearchResult') {
          log('search detected')
          log(window.serino_components.ProductListComponent.subscriptions.onViewSearchResult)
          triggerSerinoEvent(window.serino_components.ProductListComponent.subscriptions.onViewSearchResult, {
            branch_id: localStorage.getItem("branch_id")
                ? localStorage.getItem("branch_id")
                : localStorage.getItem("brand_id"),
            keyword : sessionStorage.getItem("searchKeyWord"),
          })

          // localStorage.removeItem('brand_id')
          // if (!localStorage.getItem('brand_id')) {
          //   localStorage.setItem('brand_id', 24)
          // }

          this.setState({ fromSearch: true, newKeyword: decodeURIComponent(sessionStorage.getItem("searchKeyWord")) });
        }
      }, 2000)

      const selected_category_id = this.props.location.state;

        let category = [
          {
            id: 36,
            type: "collection",
          },
        ];

        // category list component
        let srn_category_list_component =
          window.serino_components.CategoryListComponent.create(
            {
              access_token: access_token,
              brand_id: localStorage.getItem("brand_id"),
              branch_id: localStorage.getItem("brand_id"), // 43874
              x_app_key: process.env.REACT_APP_EATS_APP_ID,
              mode: 2,
              brand_name: localStorage.getItem("brand_name"),
              brandN: localStorage.getItem("brandN"),
              logo: this.switchLogo(window.location.search.slice(1)),
              // activeCategory: ''
            },
            "srn-category-component-container"
            );

        let category_list_component_events =
          window.serino_components.CategoryListComponent.events;
          document.addEventListener(
            category_list_component_events.category_select,
            (e) => {
              let windowWidth = window.innerWidth
              log({windowWidth})
              // if (windowWidth < 1001) {
              //   this.handleSelectCategoryMobile(e.detail);
              // }
              // else {
                this.handleSelectCategory(e.detail);
              // }
            }
        );
        // only this work
        let searchEvents =
          window.serino_components.SearchProductListComponent.events;

        // document.addEventListener(searchEvents.onViewAllResult, (e) => {
        //   this.setState({
        //     fromSearch: true,
        //     searchKeyword: e.detail.searchKeyword,
        //     newKeyword: e.detail.searchKeyword,
        //   });

        //   let productListComponentSubscription =
        //     window.serino_components.ProductListComponent.subscriptions;
        //   let onLoadViewSearchResult = new CustomEvent(
        //     productListComponentSubscription.onViewSearchResult,
        //     {
        //       bubbles: true,
        //       branch_id: localStorage.getItem("brand_id"),
        //       detail: e.detail.searchKeyword,
        //     }
        //   );
        //   document.dispatchEvent(onLoadViewSearchResult);
        //   this.decodeSearcKeyword();
        // });

        //SEARCH BAR PER BRAND
        // let searchProductSide =
        //   window.serino_components.SearchProductListComponent.create(
        //     {
        //       access_token: access_token,
        //       x_app_key: x_app_key,
        //       categordId: category,
        //       // branch_id: localStorage.getItem("brand_id"),
        //       mode: 2,
        //     },
        //     "srn-search-product-list-eats"
        //   );

        let srn_product_list_component =
          window.serino_components.ProductListComponent.create(
            {
              access_token: access_token,
              activeCategory: "",
              brand_id: localStorage.getItem("brand_id"),
              branch_id: localStorage.getItem("branch_id")
                ? localStorage.getItem("branch_id")
                : localStorage.getItem("brand_id"), // 43874
              cart_items: this.state.cart_items,
              categoryId: category,
              mode: 2,
              x_app_key: process.env.REACT_APP_EATS_APP_ID,
              // categoryType: window.innerWidth < 1001 ? 2 : 1
            },
            "srn-product-list-container"
          )
          setTimeout(() => {
            this.sideCategAutoScroll()
          },3000)
          ;

        // search component event
        let searchComponentEvent =
          window.serino_components.SearchProductListComponent.events;
        document.addEventListener(searchComponentEvent.onSelect, (e) => {
          this.productDescription(e.detail.product, x_app_key);

          document.getElementById(
            "srn-product-search-result-container"
          ).style.display = "none";
        });

        this.addSearchEvents(access_token)

        document.addEventListener(searchComponentEvent.onAddToCart, (e) => {
          this.setState({ product: e.detail }, () => {
            // document.getElementById(
            //   "srn-product-search-result-container"
            // ).style.display = "none";
            // log("on add to cart", e.detail);
            this.setState({
              addToCartType: 'search'
            })
            this.addToCart(e.detail);
          });
        });

        // manual input of quantity
        let productListEvent =
          window.serino_components.ProductListComponent.events;
        document.addEventListener(productListEvent.onAddtoCart, (e) => {
          if (e.detail.product_details && e.detail.product_details.brand_id) {
            localStorage.setItem('brand_id', e.detail.product_details.brand_id)
          }
          log({ productListEvent });
          this.setState(
            {
              product: e.detail.product_details,
              addToCartType: 'product-list'
            },
            () => {
              this.addToCart(this.state.product);
            }
          );
        });

        document.addEventListener(productListEvent.updateQuantity, (e) => {
          let id = e.detail.detail.id;
          let payload = {
            quantity: e.detail.detail.quantity,
            include_promo: true,
          };
          if (payload.quantity != "") {
            cart
              .updateQuantity(access_token, x_app_key, id, payload)
              .then((res) => {
                log("quantity update", res);
                log("quantity update", e.detail.detail.cart_items);
                if (res.status === 200 || res.status === 201) {
                  // this.getCart()
                  this.updateCartCount(e.detail.detail.cart_items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });

        // increment button
        document.addEventListener(productListEvent.onIncrementCart, (e) => {
          log("e deats", e.detail);
          let id = e.detail.cartItemId;
          let payload = {
            quantity: e.detail.quantity,
            include_promo: true,
          };
          cart
            .updateQuantity(access_token, x_app_key, id, payload)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                updateCartAnalytics();
                // this.getCart()
                this.updateCartCount(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // decrement button
        document.addEventListener(productListEvent.onDecrementCart, (e) => {
          let id = e.detail.cartItemId;
          let payload = {
            quantity: e.detail.quantity,
            include_promo: true,
          };
          cart
            .updateQuantity(access_token, x_app_key, id, payload)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                // this.getCart() // update cart item
                this.updateCartCount(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // remove item
        document.addEventListener(productListEvent.onDeleteItem, (e) => {
          log('onDeleteItem products', e.detail)
          let id = e.detail.cartItemId;
          cart
            .removeCartItem(access_token, x_app_key, id, this.state.cart_items)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                // this.getCart() // update cart item
                this.getCart(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // show product description
        document.addEventListener(productListEvent.onSelectedProduct, (e) => {
          if (process.env.REACT_APP_ENV != "local") {
            this.props.history.push(`?pdesc=${e.detail.product_details.id}`);
          }
          this.productDescription(e.detail.product_details, access_token);
          this.setState({ product: e.detail.product_details });
          // this.getCart()
          // this.updateCartCount(e.detail.cart_items)
          // this.getCartEats()
        });

        let productDescriptionEvent =
          window.serino_components.ProductDescriptionComponent.events;

        document.addEventListener(productDescriptionEvent.addtoCart, (e) => {
          this.setState(
            {
              product: e.detail.product_details.id,
              variants: e.detail.product_details.variants,
            },
            () => {
              log("product description", this.state.product);
              this.addToCart(
                e.detail.product_details.id,
                e.detail.product_details.variants
              );
            }
          );
        });

        document.addEventListener(
          productDescriptionEvent.onIncrementCart,
          (e) => {
            console.log(e.detail);
            let id = e.detail.cartItemId;
            let app_key = e.detail.app_key;
            let payload = {
              quantity: e.detail.quantity,
              include_promo: true,
            };
            cart
              .updateQuantity(access_token, x_app_key, id, payload)
              .then((res) => {
                log(res);
                log(res.status);
                if (res.status === 200 || res.status === 201) {
                  // this.getCart()
                  this.updateCartCount(e.detail.cart_items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        );

        document.addEventListener(
          productDescriptionEvent.onDecrementCart,
          (e) => {
            console.log(e.detail);
            let id = e.detail.cartItemId;
            let app_key = e.detail.app_key;
            let payload = {
              quantity: e.detail.quantity,
              include_promo: true,
            };
            cart
              .updateQuantity(access_token, x_app_key, id, payload)
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                  // this.getCart()
                  this.updateCartCount(e.detail.cart_items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        );

        document.addEventListener(
          productDescriptionEvent.updateQuantity,
          (e) => {
            let id = e.detail.detail.id;
            let payload = {
              quantity: e.detail.detail.quantity,
              include_promo: true,
            };
            log(e.detail.cart_items);
            if (payload.quantity != "") {
              cart
                .updateQuantity(access_token, x_app_key, id, payload)
                .then((res) => {
                  if (res.status === 200 || res.status === 201) {
                    // this.getCart()
                    this.updateCartCount(e.detail.detail.cart_items);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        );

        document.addEventListener(productListEvent.onProductsLoad, (e) => {
          let products = []
          if (e.detail) {
            e.detail.forEach((x) => {
              x.product_items.forEach(y => {
                y.brand_name = localStorage.getItem('brand_name_str')
                products.push(y)
              })
            })
          }
          products = [...new Set(products)]

          GAImpressions(products, 'Eats Brand Page Products')

          this.setState({
            products: products
          }, () => {
              let branch_id = localStorage.getItem('branch_id')
              if (branch_id && this.state.product && this.state.product.id) {
                if (
                  this.state.variants &&
                  Object.keys(this.state.variants).length > 0
                ) {
                  this.addToCartComponent(
                    this.state.product,
                    branch_id,
                    this.state.variants
                  );
                } else {
                  this.addToCartComponent(this.state.product, branch_id);
                }
              }
              // else {
              //   this.productListSubscription();
              //   this.productDescriptionSubscription();
              //   this.productTagsSubscription();
              //   this.updateCartCount(this.state.cart_items, true);
              // }
          })
          if (selected_category_id) {
            window.location.hash = selected_category_id;
            setTimeout(() => {
              const section1 = document.getElementById(
                this.state.selected_category_id
              );
              if (section1) {
                section1.scrollIntoView();
              }
            }, 300);
          }
        });

        // product tags

        let prduct_tags_by_brand_component =
          window.serino_components.ProductTagsComponent.create(
            {
              access_token: access_token,
              x_app_key: x_app_key,
              time_interval: 10000,
              currency: "₱",
              tags: "Featured",
              cart_item: "",
              id: 'featured-tags',
              branch_id: localStorage.getItem("branch_id")
                ? localStorage.getItem("branch_id")
                : localStorage.getItem("brand_id"), // 43874
              // isParent: true,
            },
            "srn-double-treats-component"
          );

        let product_tags_component =
          window.serino_components.ProductTagsComponent.create(
            {
              access_token: access_token,
              x_app_key: process.env.REACT_APP_EATS_APP_ID,
              branch_id: localStorage.getItem("branch_id")
                ? localStorage.getItem("branch_id")
                : localStorage.getItem("brand_id"), // 43874
              time_interval: 8000,
              currency: "₱",
              tags: localStorage.getItem("brand_best_seller"),
              cart_item: "",
            },
            "srn-product-tags-component"
          );

        document.addEventListener(
          window.serino_components.ProductTagsComponent.events.onAddToCart,
          (e) => {
            this.setState(
              {
                product: e.detail.product_details,
                addToCartType: 'product-tags'
              },
              () => {
                this.addToCart(e.detail.product_details);
              }
            );
          }
        );

        let bestPick = window.serino_components.ProductTagsComponent.create(
          {
            access_token: localStorage.getItem("access_token"),
            x_app_key: process.env.REACT_APP_MARKET_APP_ID,
            currency: "₱",
            time_interval: 8000,
            tags: sessionStorage.getItem("foodType")
              ? sessionStorage.getItem("foodType").toLocaleLowerCase()
              : "",
            branch_id: sessionStorage.getItem("brand_id")
              ? sessionStorage.getItem("brand_id")
              : 0,
            viewMoreEnabled: false,
            id: "main-landing-best-pick",
          },
          "food-deals"
        );

        let productTagsEvent1 =
          window.serino_components.ProductTagsComponent.events;

        document.addEventListener(
          productTagsEvent1.onShowProductDetails,
          (e) => {
            // alert(e.detail.product_details.name)
            handleShowProductActions(e.detail.product_details)

            let product = e.detail.product_details;
            this.setState({ product });

            let srn_product_description =
              window.serino_components.ProductDescriptionComponent.create(
                {
                  access_token: access_token,
                  product: product,
                  currency: "₱",
                  mode: 2,
                },
                "srn-demo-product-description-component"
              );
            if (process.env.REACT_APP_ENV != "local") {
              this.props.history.push(`?pdesc=${product.id}`);
            }
            // this.getCart()
            this.productDescriptionSubscription();
            log(e.detail.cart_items);
            this.onDescriptionModal(product, x_app_key);
          }
        );

        // fulfillment type event CONFIRM
        let fulfillmentTypeEvent =
          window.serino_components.FulfillmentTypeComponent.events;
        document.addEventListener(fulfillmentTypeEvent.onConfirm, (e) => {
          let branch_id = e.detail.detail.branch_id;
          localStorage.setItem("branch_id", branch_id);

          this.setState(
            {
              branch_id: e.detail.detail.branch_id,
            },
            () => {
              this.reloadProductList(branch_id);
            }
          );
        });

        // category drag to scroll
        const categories = document.querySelector(".cmb-categories-list");
        let isDown = false;
        let startX;
        let scrollLeft;
        const curretLogo = window.location.search.slice(1);
        categories.addEventListener("mousedown", (e) => {
          isDown = true;
          categories.classList.add("grabMe");
          categories.classList.add(curretLogo);
          startX = e.pageX - categories.offsetLeft;
          scrollLeft = categories.scrollLeft;
        });

        categories.addEventListener("mouseLeave", (e) => {
          isDown = false;
          categories.classList.remove("grabMe");
        });

        categories.addEventListener("mouseup", (e) => {
          isDown = false;
          categories.classList.remove("grabMe");
        });

        categories.addEventListener("mousemove", (e) => {
          if (!isDown) {
            return;
          }

          e.preventDefault();
          const x = e.pageX - categories.offsetLeft;
          const walk = (x - startX) * 3;
          categories.scrollLeft = scrollLeft - walk;
        });

        // document.addEventListener(cartEvents.onLoad('eatsCartProducts'), (event) =>  {
        //     log('onload', event.detail)
        //     this.getCart()
        // })

        let productTagsEvent =
          window.serino_components.ProductTagsComponent.events;

        document.addEventListener(productTagsEvent.onProductTagsLoad('featured-tags'), (e) => {
          log('featured-tags', e.detail)
            this.setState({
                isSharedDataLoaded: true,
                eatsProducts: e.detail
            })
        })

        // increment button
        document.addEventListener(productTagsEvent.onUpdateIncrement, (e) => {
          console.log("incerment::", e.detail);
          let id = e.detail.payload.cartItemId;
          let payload = {
            quantity: e.detail.payload.quantity,
            include_promo: true,
          };
          cart
            .updateQuantity(access_token, x_app_key, id, payload)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                // this.getCart()
                this.updateCartCount(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // decrement button
        document.addEventListener(productTagsEvent.onUpdateDecrement, (e) => {
          let id = e.detail.payload.cartItemId;
          let payload = {
            quantity: e.detail.payload.quantity,
            include_promo: true,
          };
          cart
            .updateQuantity(access_token, x_app_key, id, payload)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                // this.getCart() // update cart item
                this.updateCartCount(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // remove item
        document.addEventListener(productTagsEvent.onDeleteItem, (e) => {
          let id = e.detail.detail.cartItemEntity;
          cart
            .removeCartItem(access_token, x_app_key, id, this.state.cart_items)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                // this.getCart() // update cart item
                this.getCart(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // removed item from description modal
        document.addEventListener(productDescriptionEvent.onDeleteItem, (e) => {
          console.log(e.detail);
          let app_key = e.detail.app_key;
          let id = e.detail.cartItemId;
          cart
            .removeCartItem(access_token, x_app_key, id, this.state.cart_items)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                // this.getCart() // update cart item
                this.getCart(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // search list component
        let searchProduct =
          window.serino_components.SearchProductListComponent.create(
            {
              access_token: access_token,
              x_app_key: x_app_key,
              categordId: 13,
              brand_id: 2,
              mode: 1,
            },
            "srn-search-product-list-container"
          );

        let component = window.serino_components.BrandListComponent.create(
          {
            access_token: access_token,
            x_app_key: x_app_key,
            time_interval: 10000,
            status: 1, // blank = all, 0 = inactive, 1 = active
          },
          "srn-brand-selector-container"
        );

        let brandListEvents =
          window.serino_components.BrandListComponent.events;

        // document.addEventListener(brandListEvents.onBrandSelect, (e) => {
        //     console.log(e.detail)
        //     this.handleGotoProducts(e.detail)
        // })----------------------------------------------------------------------------------------------------------------------------

        document.addEventListener(brandListEvents.onDataLoad, (e) => {
          this.setState({
            brands: e.detail,
          });
        });

        let viewAction = sessionStorage.getItem("viewAction");
        switch (viewAction) {
          case "foodTypeSelect":
            this.setState({
              fromFoodType: true,
            });
            break;
          case "bundle":
            this.setState({
              isBundle: true,
            });
            break;
          default:
            return;
        }
      }

  setMainAddress = async () => {
    let main_address = "";
    let getMainAddressTries = 0;

    let getMainAddressesInterval = setInterval(async () => {
      let main_address = await getCrossStorage("main_address", "sub");

      getMainAddressTries++;

      if (main_address || getMainAddressTries == 3) {
        clearInterval(getMainAddressesInterval);

        if (getMainAddressTries < 5) {
          if (main_address && main_address.length > 0) {
            log("test");

            let parsed = JSON.parse(main_address);

            if (parsed && parsed.address) {
              localStorage.setItem("main_address", main_address);

              localStorage.setItem("Address", parsed.address);

              // if (document.getElementById("query-eats_products_page")) {
              //   document.getElementById("query-eats_products_page").text =
              //     parsed.address;
              // }

              setHeaderAddress(parsed);

              this.handleMainAddress("initial");
            } else {
              localStorage.removeItem("main_address");
            }
          }
        }
      } else {
        localStorage.removeItem("branch_id");
        localStorage.removeItem(`Address`);

        if (document.getElementById("query-eats_products_page")) {
          document.getElementById("query-eats_products_page").text = "";
        }

        this.setState({
          triggerGetDefaultAddress: {
            state: !this.state.triggerGetDefaultAddress,
            app_key: x_app_key,
          },
        });
      }
    });
  };

  // handleGotoProducts = (brand) => {

  //     var str = brand.brand.name
  //     str = str.replace(/\s+/g, '-').toLowerCase()
  //     localStorage.setItem('brand_logo', brand.brand.logo)

  //     localStorage.removeItem('branch_id')
  //     localStorage.removeItem('Address')
  //     localStorage.removeItem('brand_name')
  //     localStorage.removeItem('brand_best_seller')
  //     localStorage.removeItem('fulfillment_type_status')
  //     localStorage.setItem('brand_name', str+'-brand')
  //     localStorage.setItem('brand_name_str', brand.name)
  //     localStorage.setItem('brand_best_seller', brand.brand.name.toLowerCase())
  //     localStorage.setItem('brand_id', brand.brand.id.toString())
  //     sessionStorage.setItem('viewAction', 'brandSelect')
  //     sessionStorage.removeItem('foodType')
  //     setTimeout(() => {
  //         this.props.history.push(`/products/?${str}`)
  //     }, 300)
  // }----------------------------------------------------------------------------------------------------------------------

  onDescriptionModal = (detail, x_app_key) => {
    $("#descriptionDiscountModal").modal("show");
  };

  getCart = async (cartItems, isInitial = false) => {

    let cart_items = []

    if (isInitial) {

      try {
        let getCartResult = await cart.getCartItems(
          localStorage.getItem("access_token"),
          x_app_key
        );
        cart_items = getCartResult.data
        let filtered_cart = cart_items.filter(x => x.domainReferenceId == localStorage.getItem('branch_id'))
        if (filtered_cart) {
          sessionStorage.setItem('eats_cart_items', JSON.stringify(filtered_cart))
        }
      } catch (cartError) {
        if (cartError.responseJSON.message == "Invalid Access Token") {
          handleExpiredToken();
        }
      }
    }
    else {
      cart_items = cartItems
    }

    if (this.state.isSearchActive) {
        this.handleSearchDisplay(true)
    }

    this.setState(
      {
        cart_items: cart_items,
        isAddBtnLoading: false,
        cart_initialized: 1,
        isSearchActive: false,
        product: {}
      },
      () => {
        log('cart list updated!!!', this.state.cart_items)
        // update cart items in component
        this.productDescriptionSubscription();
        this.productListSubscription();
        this.productTagsSubscription();
        this.updateCartCount(this.state.cart_items, true);
      }
      )
  };

  updateCartCount = async (cart_items, isGetCart) => {
    log('updateCartCount from products', cart_items)
    let totalCartCount = 0;
    if (cart_items && cart_items.length > 0) {
      let eats_cartCount = cart_items.reduce((a, b) => +a + +b.quantity, 0);
      log({ eats_cartCount });
      if (eats_cartCount && eats_cartCount > 0) {
        totalCartCount = eats_cartCount;
      }
    }
    else {
      this.setState({
        cart_items: []
      })
    }
    let details = {
      eats: totalCartCount,
      market: localStorage.getItem("market_cart_count"),
      app: "eats",
    };
    if (!isGetCart) {
      this.productDescriptionSubscription();
      this.productListSubscription();
      this.productTagsSubscription();
    }
    setSubdomainCartCount(details);
  };

  productTagsSubscription = () => {
    triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.onCartItems('featured-tags'), this.state.cart_items)
    triggerSerinoEvent(window.serino_components.SearchProductListComponent.subscriptions.onCartItems, this.state.cart_items)
  }

  getCartEats = () => {
    let cartEvents = window.serino_components.cart.events;
    let cartSubscriptions = window.serino_components.cart.subscriptions;
    let refreshCart = new CustomEvent(
      cartSubscriptions.refreshCart("eatsCartProducts")
    );
    document.dispatchEvent(refreshCart);

    document.addEventListener(cartEvents.onLoad("eatsCartProducts"), (e) => {
      console.log("cart_____", e.detail);
      // if (e.detail && e.detail.cart && e.detail.cart.length > 0) {
      //     let cart_items = e.detail.cart
      //     if (cart_items && cart_items.length > 0) {
      //         this.setState({
      //             cart_items: e.detail.cart[0].cart_items
      //         }, () => {
      //             console.log('______________', this.state.cart_items)
      //         })
      //     }
      // }
    });
  };

  productListSubscription = () => {
    // subscription for cart quantity
    let productListSubscription =
      window.serino_components.ProductListComponent.subscriptions;
    let productListCartItems = new CustomEvent(
      productListSubscription.onCartItems,
      {
        detail: this.state.cart_items,
      }
    );
    document.dispatchEvent(productListCartItems);
  };

  productDescriptionSubscription = () => {
    // subscription for cart quantity
    let productDescriptionSubscription =
      window.serino_components.ProductDescriptionComponent.subscriptions;
    let productDescriptionItems = new CustomEvent(
      productDescriptionSubscription.onCartItems,
      {
        detail: this.state.cart_items,
      }
    );
    document.dispatchEvent(productDescriptionItems);

    // $('#descriptionDiscountModal').modal('hide')
  };

  productDescription = (detail, access_token) => {
    $("#descriptionDiscountModal").modal("show");

    handleShowProductActions(detail)

    let productDescription =
      window.serino_components.ProductDescriptionComponent.create(
        {
          product: detail,
          currency: "₱",
          mode: 2,
          access_token: access_token,
        },
        "srn-demo-product-description-component"
      );

    setTimeout(() => {
      this.productDescriptionSubscription();
    }, 500);
  };

  onFulfillmentModal = () => {
    $("#fulfillmenttype").modal("show");
    $("#descriptionDiscountModal").modal("hide");
  };

  handleSelectCategoryMobile = (category) => {
    triggerSerinoEvent(window.serino_components.ProductListComponent.subscriptions.onCategorySelection, category.col_cal.name)
  }

  handleSelectCategory = (category) => {
    let query = "";
  
    if (category.selected_category === "collection") {
      query = "col_" + category.col_cal.id;
    } else {
      query = "cat_" + category.col_cal.id;
    }
    let element = document.getElementById(query);

    if (element) {
      element.scrollIntoView({
        block: "start",
        inline: "start",
        behavior: "smooth",
      });
    }
  };

  sideCategAutoScroll = () => {
    let query = "";
    let type = localStorage.getItem("categ_type")
    let id = localStorage.getItem("categ_id")
    
    if (type == "collection") {
      query = "col_" + id;
    } else {
      query = "cat_" + id;
    }
    let element = document.getElementById(query);
      if (element) {
        element.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });
      }
    setTimeout(() => {
      localStorage.removeItem("categ_type")
      localStorage.removeItem("categ_id")
      localStorage.removeItem("from_side_categ")
    },10000)
  }

  addToCart = async (product, variants) => {
    log("addtocart product", product);

    if (this.state.isAddBtnLoading == product.id) {
      return;
    }
    this.setState({
      isAddBtnLoading: product.id,
    });

    triggerSerinoEvent(
      window.serino_components.ProductDescriptionComponent.subscriptions
        .setButtonLoading,
      product.id
    );

    const proceed = async (branch_id) => {
      // log('product from proceed', product)

      let custom_data = []

      let payload = {
        itemReferenceId: product.id,
        itemReferenceType: product.type,
        itemName: product.name,
        itemSKU: product.sku,
        itemImage: getThumbnail(product) ? getThumbnail(product) : product.images,
        itemDescription: product.description,
        quantity: product.quantity && product.quantity > 0 ? product.quantity : 1,
        basePrice: product.price,
        price: product.newPrice ? product.newPrice : product.price,
        instructions: "",
        domainReferenceId: branch_id,
        domainReferenceType: "1",
        item_type: product.sell_by_type ? product.sell_by_type : 0,
        include_promo: true,
      };

      // SAVE ADDRESS IN CART START
      if (getAddressFromObjectChecker) {
          let address = getAddressObject(branch_id, process.env.REACT_APP_EATS_APP_ID)

          if (!address) {
              triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
          }

          custom_data.push({
              name: 'address',
              value: address
          })
      }
      // SAVE ADDRESS IN CART 
      
      if (custom_data && custom_data.length > 0) {
        payload.custom_data = JSON.stringify(custom_data);
      }

      if (variants) {
        payload.optionIds = JSON.stringify(variants.option_ids);
        payload.optionText = variants.option_texts.join("|");
      }

      try {
        const addCartItemResult = await cart.addCartItem(
          localStorage.getItem("access_token"),
          x_app_key,
          payload
        );
        // for google analytics
        addToCartAnalytics();
        addToCartHub(product, branch_id);

        setTimeout(() => {
          this.getCart(addCartItemResult.items);
        }, 100);
      }
      catch (err) {
        if (err.responseJSON.message == "Invalid Access Token") {
          handleExpiredToken();
        }
      }
    };

    let locationPicker = document.getElementById(
      "query-eats_products_page"
    ).value;

    // if(locationPicker != '') {
    //     proceed(localStorage.getItem('branch_id'))
    // }
    // else {
    let localAddressObject = localStorage.getItem(
      `address_object_${x_app_key}`
    );
    let address_object = [];
    if (localAddressObject && localAddressObject.length > 0) {
      address_object = JSON.parse(localAddressObject);
    }
    log("address_object", address_object);
    if (address_object && address_object.length > 0) {
      // console.log(address_object)
      // console.log(product)
      let address = address_object.find(
        (x) => x.brand_id == localStorage.getItem("brand_id")
      );
      log("address", address);
      if (address) {
        if (address.branch_id && address.branch_id != null) {
          log("address", address.brand_id);
          proceed(address.branch_id);
        } else {
          this.handleMainAddress();
        }
      } else {
        this.handleMainAddress();
      }
    } else {
      this.handleMainAddress();
    }
    // }
  };

  handleMainAddress = (type = "addtocart") => {
    this.setState({ isAddBtnLoading: false });
    mainAddressHandler()
  };

  reloadProductList = (branch_id) => {
    let productListSubscription =
      window.serino_components.ProductListComponent.subscriptions;
    let releadoProductList = new CustomEvent(
      productListSubscription.reloadProductList,
      {
        detail: branch_id,
      }
    );
    document.dispatchEvent(releadoProductList);
  };


  findUpdatedProduct = (productProps) => {
    let products = []
      switch (this.state.addToCartType) {
        case ('product-tags'):
          products = this.state.eatsProducts
          break;
        case ('product-list'):
          products = this.state.products
          break;

      }
        let product = products.find(x => x.id == productProps.id)
        log('findUpdatedProduct', product)
        if (product) {
            if (productProps && productProps.quantity && productProps.quantity > 0) {
              product.quantity = productProps.quantity
            }
            return product
        }
        else {
            return null
        }
  }

  addToCartComponent = async (productProps , branch_id, variants) => {
    if (this.state.isAddBtnLoading == productProps.id) {
      return;
    }

    let product = this.findUpdatedProduct(productProps)
    // let product = productProps

    if (!product){
      return;
    }
    this.setState({
      isAddBtnLoading: product.id,
    });

    triggerSerinoEvent(
      window.serino_components.ProductDescriptionComponent.subscriptions
        .setButtonLoading,
      product.id
    );

    let payload = {
      itemReferenceId: product.id,
      itemReferenceType: product.type,
      itemName: product.name,
      itemSKU: product.sku,
      itemImage: product.images,
      itemDescription: product.description,
      quantity: product.quantity && product.quantity > 0 ? product.quantity : 1,
      basePrice: product.price,
      price: product.newPrice ? product.newPrice : product.price,
      instructions: "",
      domainReferenceId: branch_id ? branch_id : 5,
      domainReferenceType: "1",
      item_type: product.sell_by_type ? product.sell_by_type : 0,
      include_promo: true,
    };

    let custom_data = []

    // SAVE ADDRESS IN CART START
    if (getAddressFromObjectChecker) {
        let address = getAddressObject(branch_id, process.env.REACT_APP_EATS_APP_ID)

        if (!address) {
            triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
        }

        custom_data.push({
            name: 'address',
            value: address
        })
    }
    // SAVE ADDRESS IN CART 
    
    if (custom_data && custom_data.length > 0) {
      payload.custom_data = JSON.stringify(custom_data);
    }

    if (variants) {
      payload.optionIds = variants.option_ids.toString();
      payload.optionText = variants.option_texts.join("|");
    }

    try {
      const addCartItemResult = await cart.addCartItem(
        localStorage.getItem("access_token"),
        x_app_key,
        payload
      );

      // for google analytics
      addToCartAnalytics();
      addToCartHub(product, branch_id);

      setTimeout(() => {
        this.getCart(addCartItemResult.items);
      }, 100);
    }
    catch (err) {
      if (err.responseJSON.message == "Invalid Access Token") {
        handleExpiredToken();
      }
    }

  };

  getAddressObject = (address_object) => {
    this.setState({ address_object });
  };

  headerChange = () => {
    $(".borderTop").addClass("red");
  };

  handlePageRedirect = (domain) => {
    if (this.state.fromSearch === true) {
      let branch_id =
        localStorage.getItem("branch_id") != null
          ? localStorage.getItem("branch_id")
          : 24;
      this.setState({ fromSearch: false });

      this.reloadProductList(branch_id);
    } else {
      window.location.href = getUrl(domain);
    }
  };
  searchResultBack = () => {
    window.location.reload();
  };

  handleLocationChange = (address_object) => {
    log("handleLocationChange", address_object);
    this.setState(
      {
        address_object: address_object,
        isAddBtnLoading: false,
        cart_items: [],
      },
      () => {
        // window.location.reload()
        let address = address_object.find(
          (x) => x.brand_id == localStorage.getItem("brand_id")
        );
        log({ address });
        if (sessionStorage.getItem('viewAllAction') !== 'viewSearchResult') {
          this.reloadProductList(address.branch_id)
        }
        this.productListSubscription();
        this.productDescriptionSubscription();
        this.productTagsSubscription();
        if (address) {
          setHeaderAddress(address);
        }
      }
    );
  };

  handleModalClose = () => {
      this.productDescriptionSubscription();
      this.productListSubscription();
      this.productTagsSubscription();
    // this.productTagsSubscription();
  };

  decodeSearcKeyword = () => {
    this.setState({
      newKeyword: decodeURIComponent(this.state.searchKeyword),
    });
  };

  handleClearAddress = () => {
    this.setState(
      {
        cart_items: [],
      },
      () => {
        this.getCart();
        this.productListSubscription();
        this.productDescriptionSubscription();
        this.productTagsSubscription();
      }
    );
  };

  brandSelection = () => {
    if (this.state.isDropdownActive != true) {
      this.setState({ isDropdownActive: true });
    } else {
      this.setState({ isDropdownActive: false });
    }
  };

  foodBrandSelet = (name, id) => {
    sessionStorage.setItem("brand_id", id);
    sessionStorage.setItem("brand_name", name);
    // this.props.history.push(`/eats-products`);
    window.location.reload();
    this.setState({
      brandSelect: sessionStorage.getItem("brand_name"),
      isDropdownActive: false,
    });
  };

  showSideCategories = () => {
    document.getElementById("side-categ").style.width = "250px";
    document.getElementById("side-categ").style.padding = "20px 10px";
    document.getElementById("side-categ-backdrop").style.display = "block";

    // accordion for side category
    var accordion = document.getElementsByClassName("cmb-collection-sub-items");
    var i;
    for (i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener("click", function () {
        this.childNodes[0].classList.toggle("active-categ");
        var sub_categ = this.childNodes[1].children;
        for (var x = 0; x < sub_categ.length; x++) {
          if (sub_categ[x].style.display === "block") {
            sub_categ[x].style.display = "none";
          } else {
            sub_categ[x].style.display = "block";
          }
        }
      });
    }
  };

  closeSideNav = () => {
    document.getElementById("side-categ").style.width = "0";
    document.getElementById("side-categ").style.padding = "0";
    document.getElementById("side-categ-backdrop").style.display = "none";
  };

  handleGotoProductsFromCategory = (category) => {
    if (category) {
      this.props.history.push(`/eats-products`, category);
    } else {
      this.props.history.push(`/eats-products`);
    }
  };

  handleBundleRedirect = (parsed) => {
    triggerSerinoEvent(
      window.serino_components.ProductListComponent.subscriptions
        .onBundleSelection,
      {
        id: parsed.collection_id,
      }
    );
  };


  switchLogo = (logo) => {
    switch (logo) {
      case "popeyes":
        return PopeyesLogo;
      case "kuya-j":
        return KuyaJLogo;
      case "hungry-nomad-kitchen":
        return HnkLogo;
      case "landers-central":
        return LandersLogo;
      case "dough-and-co.":
        return DoughLogo;
      case "da-gianni-cucina-italiana":
        return GianiLogo;
      case "majestic":
        return MajesticLogo;
      case "trattoria-da-gianni":
        return TrattoriaLogo;
      default:
        return null;
    }
  };


  addSearchEvents = (access_token) => {
    let searchComponentEvent = window.serino_components.SearchProductListComponent.events
     // delete item event
      document.addEventListener(searchComponentEvent.onDeleteItem, (e) => {
        this.setState({isSearchActive: true})
        log('delte vent', e.detail.detail)
        let id = e.detail.detail.cartItemEntity;
        log('onDeleteItem', e.detail.detail)
        cart
          .removeCartItem(access_token, e.detail.detail.app_key, id)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              this.getCart(res.items);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

      // manual input quantity
      document.addEventListener(searchComponentEvent.updateQuantity, (e) => {
        this.setState({isSearchActive: true})
        let id = e.detail.detail.id;
        let payload = {
          quantity: e.detail.detail.quantity,
          include_promo: true,
        };
        cart
          .updateQuantity(access_token, e.detail.app_key, id, payload)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              updateCartAnalytics();
              let cart_item = e.detail.payload.item;
              if (cart_item) {
                let action = null;
                if (payload.quantity > cart_item.quantity) {
                  action = "increment";
                }
                if (payload.quantity < cart_item.quantity) {
                  action = "decrement";
                }
                updateCartHub(cart_item, action);
              }
              // this.getCart()
              this.updateCartCount(e.detail.payload.cart_items);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

      // increment button
      document.addEventListener(searchComponentEvent.onUpdateIncrement, (e) => {
        this.setState({isSearchActive: true})
        let id = e.detail.payload.cartItemId;
        let payload = {
          quantity: e.detail.payload.quantity,
          include_promo: true,
        };
        cart
          .updateQuantity(access_token, e.detail.app_key, id, payload)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              updateCartAnalytics();
              let cart_item = e.detail.payload.item;
              if (cart_item) {
                updateCartHub(cart_item, "increment");
              }
              // this.getCart()
              this.updateCartCount(e.detail.payload.cart_items);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

      // decrement button
      document.addEventListener(searchComponentEvent.onUpdateDecrement, (e) => {
        this.setState({isSearchActive: true})
        let id = e.detail.payload.cartItemId;
        let payload = {
          quantity: e.detail.payload.quantity,
          include_promo: true,
        };
        cart
          .updateQuantity(access_token, e.detail.app_key, id, payload)
          .then((res) => {
            let cart_item = e.detail.payload.item;
            if (cart_item) {
              updateCartHub(cart_item, "decrement");
            }
            // if (res.status === 200 || res.status === 201) {
            // this.getCart() // update cart item
            this.updateCartCount(e.detail.payload.cart_items);
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }
  

  handleSearchDisplay = (bool) => {
      if (document.getElementById('srn-product-search-result-container')) {
        document.getElementById('srn-product-search-result-container').style.display = 'block'
      }
  }

  handleBrandFromUrl = async () => {


    if (sessionStorage.getItem("searchKeyWord") != null && sessionStorage.getItem('viewAllAction') === 'viewSearchResult') {
      this.init()
      return
    }

    let brands = []
    let apps = []

    try {
        const dataFromJSON = await coreAPI.getDataFromJSON()

        if (!dataFromJSON) {
          return
        }
        if (dataFromJSON.data && dataFromJSON.data.brands) {
            brands = dataFromJSON.data.brands
        }
        if (dataFromJSON.data && dataFromJSON.data.apps) {
            apps = dataFromJSON.data.apps
        }
        this.setState({brands, apps}, () => {
          log('filter using: ', window.location.search)


          let url = window.location.search

          const locatedBrand = brands.find(brand => {
            var str = brand.name
            str = str.replace(/\s+/g, "-").toLowerCase()
            return url.includes(str)
          })

          if (locatedBrand) {
            this.handleGotoProducts(locatedBrand)
          }
          else {
            window.location = '/eats'
          }

        })
    }
    catch(getDataErr) {
        log(getDataErr)
    }
  }

  handleGotoProducts = (brand) => {
      var str = brand.name;
      str = str.replace(/\s+/g, "-").toLowerCase();
      localStorage.setItem("brand_logo", brand.logo);
      localStorage.setItem("brand_id", brand.id);

      localStorage.removeItem("branch_id");
      localStorage.removeItem("Address");
      localStorage.removeItem("brand_name");
      localStorage.removeItem("brand_best_seller");
      localStorage.removeItem("fulfillment_type_status");
      localStorage.setItem("brand_name", str + "-brand");
      localStorage.setItem("brand_name_str", brand.name);
      localStorage.setItem("brand_best_seller", brand.name.toLowerCase());
      localStorage.setItem("brand_id", brand.id.toString());
      sessionStorage.setItem("viewAction", "brandSelect");
      localStorage.setItem("brandN", str);
      sessionStorage.removeItem("foodType");
      sessionStorage.removeItem("viewAllAction");
      sessionStorage.removeItem("searchKeyWord");
      setTimeout(() => {
        this.init()
      }, 300);
  };

  render() {
    const switchLogo = (logo) => {
      switch (logo) {
        case "popeyes":
          return PopeyesLogo;
        case "kuya-j":
          return KuyaJLogo;
        case "hungry-nomad-kitchen":
          return HnkLogo;
        case "landers-central":
          return LandersLogo;
        case "dough-and-co.":
          return DoughLogo;
        case "da-gianni-cucina-italiana":
          return GianiLogo;
        case "majestic":
          return MajesticLogo;
        case "trattoria-da-gianni":
          return TrattoriaLogo;
        default:
          return null;
      }
    };
    const switchLeftBg = (logo) => {
      switch (logo) {
        case "popeyes":
          return left_popeyes;
        case "kuya-j":
          return left_kuyaj;
        case "hungry-nomad-kitchen":
          return left_majestic;
        case "landers-central":
          return left_central;
        case "dough-and-co.":
          return left_bough;
        case "da-gianni-cucina-italiana":
          return left_gianni;
        case "majestic":
          return left_majestic;
        case "trattoria-da-gianni":
          return left_gianni;
        default:
          return null;
      }
    };
    const curretLogo = window.location.search.slice(1);
    const eatsProducts = [
      "popeyes",
      "kuya-j",
      "hungry-nomad-kitchen",
      "landers-central",
      "dough-and-co.",
      "da-gianni-cucina-italiana",
      "majestic",
      "trattoria-da-gianni",
    ];
    const url = window.location.search.slice(1).toLowerCase();
    const isProductEatsPage = eatsProducts.find((e) => e === url);
    const leftNavBar = (
      <div className="leftNav-main-container">
        <div className="d-flex leftNav-img-container">
          <img src={switchLeftBg(curretLogo)} alt="" width="150px" />
          <div className="leftNav-logo">
            <img
              alt=""
              height="150px"
              width="150px"
              src={switchLogo(curretLogo)}
            />
          </div>
        </div>
      </div>
    );

    const rightNAv = isProductEatsPage && (
      <div className="rightNav-container d-none">
        {/* <img src={adsbot} alt="" width="200px" />
        <img src={adstop} alt="" width="200px" className="mt-5" /> */}
      </div>
    );
    let similar_items = [1, 2, 3, 4, 5];
    let brand_logo = localStorage.getItem("brand_logo")
      ? localStorage.getItem("brand_logo")
      : EatsLogo;

    let foodTypeBanner, foodTypeLabel;
    let foodType =
      this.state.fromFoodType === true ? sessionStorage.getItem("foodType").toLowerCase() : "fromBrandSelect";
    switch (foodType) {
      case "chicken":
        foodTypeBanner = ChickenBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      case "burgers":
        foodTypeBanner = BurgerBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      case "beverages":
        foodTypeBanner = BeverageBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      case "desserts":
        foodTypeBanner = DesertBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      case "dimsum":
        foodTypeBanner = DimsumBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      case "noodles":
        foodTypeBanner = NoodleBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      case "pizza":
        foodTypeBanner = PizzaBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      case "seafood":
        foodTypeBanner = SeafoodBanner;
        foodTypeLabel = sessionStorage.getItem("foodType");
        break;
      default:
        foodTypeBanner = "";
        foodTypeLabel = "";
    }
    return (
      <div
        style={{ position: "relative" }}
        className="d-flex flex-column h-100"
        id="eatproductpage"
      >
        {rightNAv}
        <div
          className="main-landing-container cmb-eats-product-container cmb-eats-product-style-2-container"
          id="eat-landing-container"
        >
          {
            // this.state.access_token &&
            <LocationPicker
              access_token={this.state.access_token}
              id="eats_products_page"
              x_app_key={2}
              getAddressObject={this.getAddressObject}
              brand_id={localStorage.getItem("brand_id")}
              address={localStorage.getItem("Address")}
              handleLocationChange={this.handleLocationChange}
              handleModalClose={this.handleModalClose}
              handleClearAddress={this.handleClearAddress}
              cart_items={this.state.cart_items}
              triggerGetDefaultAddress={this.state.triggerGetDefaultAddress}
              className="d-none"
            />
          }

          {/* <div id="side-categ" className="sidenav side-categ">
            <h6>Select Brands</h6>
            <BrandListComponent
              id="srn-category-list-container"
              brands={this.state.brands}
              handleGotoProducts={this.handleGotoProductsFromCategory}
            />
          </div> */}
          <div
            id="side-categ-backdrop"
            className="d-none side-menu-backdrop side-menu-backdrop-transition"
            onClick={() => this.closeSideNav()}
          ></div>
          <div className="categ-search-section">
            <button
              className="btn-categ"
              onClick={() => this.showSideCategories()}
            >
              RESTAURANTS
            </button>
            <div id="srn-search-product-list-container"></div>
          </div>
          {/*

                        <div className={` row no-gutters back-button ${this.state.fromSearch === true ? 'd-none' : ''}`} onClick={() => this.handlePageRedirect('eats')}>
                            <i class="fa fa-chevron-left"><span>Back</span></i>
                        </div>
                        <div className={` row no-gutters back-button ${this.state.fromSearch === true ? '' : 'd-none'}`} onClick={() => this.searchResultBack()}>
                            <i class="fa fa-chevron-left"><span>Back</span></i>
                        </div>
                    */}
          {this.state.fromSearch === true ? (
            <div className="srn-search-page-container">
                <div className="srn-search-page-products-container">
                  <div className="srn-search-header srn-search-header-v2 mt-2">
                      <div className="srn-search-header-title-container">
                          <h6 className="srn-search-header-title">Search in:</h6>
                      </div>
                      <div className="srn-search-header-navigation">
                          <div className="srn-search-header-navigation-list">
                              <a href="#!"className={`srn-search-header-navigation-item`}>ALL</a>
                              <a href="#!"className={`srn-search-header-navigation-item`}>MARKET</a>
                              <a href="#!"className={`srn-search-header-navigation-item srn-search-header-navigation-item-active`}>EATS</a>
                          </div>
                      </div>
                  </div>
                  <h3 className={`srn-search-page-header ${this.state.fromSearch === true ? "" : "d-none"}`} onClick={() => this.decodeSearcKeyword()} > Search result for{" "} "{(this.state.newKeyword = decodeURIComponent(this.state.newKeyword )) }" </h3>
                  <div id="srn-product-list-container"className="srn-product-list-market"></div>
                </div>
            </div>
          ) : null}

          <div
            className={`row no-gutters eats-content ${
              this.state.fromFoodType === true || this.state.isBundle === true
                ? "d-none"
                : ""
            }`}
          >
            <div className="col-12">

              <div className={`eats-category-bar ${ this.state.fromSearch === true ? "d-none" : ""}`}>
                {/* <i class="fa fa-arrow-left" onClick={() => window.history.back()}></i> */}
                <div id="srn-category-component-container" className="cmb-category-containter cmb-categ categ-sticky srn-eats-category-container"></div>
                <div id="srn-search-product-list-eats" className={`x${localStorage.getItem("brand_id")} d-none`}></div>
              </div>

              <div className={`eats-landing-banner ${this.state.fromSearch === true ? "d-none" : ""} hidelogo-${localStorage.getItem("brand_id")}`} >
                <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-interval="5000" data-ride="carousel">
                  <div className="carousel-inner">
                    {this.state.brand_banner.slice(0, 1).map((brand, i) => {
                      return (
                        <div className="carousel-item active" key={i}>
                          <img
                            className="d-block w-100 eats-product-banner"
                            src={brand}
                            alt=""
                          />
                          <img
                            className="brand-logo"
                            alt=""
                            height="200px"
                            width="200px"
                            src={localStorage.getItem("brand_logo")}
                          />
                        </div>
                      );
                    })}
                    {this.state.brand_banner.slice(1, 2).map((brand, i) => {
                      return (
                        <div className="carousel-item" key={i}>
                          <img
                            className="d-block w-100 eats-product-banner"
                            src={brand}
                            alt=""
                          />
                          <img
                            className="brand-logo"
                            alt=""
                            height="200px"
                            width="200px"
                            src={localStorage.getItem("brand_logo")}
                          />
                        </div>
                      );
                    })}
                        {/* <img
                            className="brand-logo"
                            alt=""
                            height="200px"
                            width="200px"
                            src={localStorage.getItem("brand_logo")}
                          /> */}
                  </div>
                  <h6 className="eatsbrandname">{localStorage.getItem("brand_best_seller")}</h6>
                </div>
              </div>
            </div>
            <div className={`col-12 dough-${localStorage.getItem("brand_id")} eats-content-container`} id={`brand-container-${localStorage.getItem("brand_name")}`} >
              <div className={`eats-category-bar ${ this.state.fromSearch === true ? "d-none" : ""}`}>
                <div id="srn-category-component-container" className="cmb-category-containter cmb-categ categ-sticky srn-eats-category-container"></div>
                <div id="srn-search-product-list-eats" className={`x${localStorage.getItem("brand_id")} d-none`}></div>
              </div>

              <div className="row eats-brand-product-container">
                {/* <div
                  className={` eats-leftNav-container ${
                    switchLogo(curretLogo) ? "d-flex" : "d-none"
                  }  `}
                >
                  {leftNavBar}
                </div> */}
                <div className="col-12 tilefullwidth">
                  <div
                    id="srn-double-treats-component"
                    className={`d-none srn-landing-product-list-container srn-double-treats-products srn-eats-products-style-2 ${this.state.fromSearch === true ? "d-none" : ""}`}
                  ></div>
                  <div className="flex-fill cmb-products-list">
                    <div
                      id="srn-product-list-container"
                      className="cmb-eats-product-list-component-container"
                    ></div>
                  </div>
                </div>
                <div
                  className={`col-2 best-seller-section ${
                    this.state.fromSearch === true ? "d-none" : "d-none"
                  }`}
                  id={`x${localStorage.getItem("brand_id")}`}
                >
                  <h6 className="srn-eats-best-seller-title">Best Seller</h6>
                  <div
                    id="srn-product-tags-component"
                    className="srn-eats-product-tags-component-container"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* FOOOOD DEEEEEAAALLLSSS */}
          <div className={`row no-gutters food-type-content ${this.state.fromFoodType === true ? "" : "d-none"}`}>
            <div className="col-12">
              <div className="food-type-banner-container">
                <img className="food-type-banner-image" src={foodTypeBanner} />
                {/* <h1 className="food-type-banner-header">{foodTypeLabel}</h1> */}
              </div>
            </div>
            <div
              className="foodcatcontainer"
              style={{
                marginTop: "2rem",
                display: "flex",
                width: "50vw",
                paddingLeft: "10rem",
              }}
            >
              <div className="col-12 food-deals-products-header">
                <span>{foodTypeLabel}</span>
              </div>

              <div className="col-12 food-type-brand-select d-flex">
                <div className="dropdown">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle"
                    type="button"
                    onClick={() => this.brandSelection()}
                  >
                    <span>{this.state.brandSelect}</span>
                  </button>
                  <div>
                    {this.state.isDropdownActive && (
                      <div className="food-type-brand-menu">
                        <li
                          onClick={(e) => this.foodBrandSelet("All brands", 0)}
                        >
                          All brand
                        </li>
                        {this.state.foodTypeBrands.map((brand) => {
                          return (
                            <li className={`brand-${brand.id}`}
                              onClick={(e) =>
                                this.foodBrandSelet(brand.name, brand.id)
                              }
                            >
                              {brand.name}
                            </li>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div
                id="food-deals"
                className="srn-products-best-picks bestpickshover"
              ></div>
            </div>
          </div>

          {/* BUNDLE CONTAINER */}
          <div
            className={`row no-gutters food-type-content ${
              this.state.isBundle === true ? "" : "d-none"
            }`}
          >
            <div className="col-12">
              <div className="food-type-banner-container">
                {/* <h1 className="food-type-banner-header">{foodTypeLabel}</h1> */}
              </div>
            </div>
            <div className="col-12 food-type-brand-select d-flex">
              <span>Select Brand</span>
            </div>
            <div className="col-12 food-deals-products-header"></div>
            <div className="col-12"></div>
          </div>

          <div className="modal" id="descriptionDiscountModal" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div
                className="modal-content srn-ft-main-container"
                id="eats-brand-page-modal"
              >
                <div className="modal-header srn-ft-modal-header">
                  <div className="modal-logo">
                    <img src={EatsLogo} className="srn-ft-brand-logo" />
                    {sessionStorage.getItem("viewAction") !=
                    "foodTypeSelect" ? (
                      <img src={brand_logo} className="srn-ft-brand-logo" />
                    ) : null}
                  </div>
                  <button type="button" className="close d-block" data-dismiss="modal" aria-label="Close">
                      <span >&times;</span>
                  </button>
                </div>
                <div className="modal-body" id="eats-product-modal">
                  <div
                    id="srn-demo-product-description-component"
                    className="srn-demo-product-description-component"
                  ></div>
                  <h6
                    className={`srn-best-partner-product-label ${
                      this.state.similar_items.length > 0 ? "" : "d-none"
                    }`}
                  >
                    Best Partner
                  </h6>
                  <div
                    id="srn-partner-product-container"
                    className={`srn-partner-product-container ${
                      this.state.similar_items.length > 0 ? "" : "d-none"
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal srn-eats-fulfillment-type-modal"
            id="fulfillmenttype"
            role="dialog"
            data-backdrop="static"
            data-keyboard="false"
            data-show="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="fulfillment-type-title-container">
                  <img src={brand_logo} className="srn-ft-brand-logo" />
                  <h6 className="fulfillment-type-title">
                    Select Fulfillment Type
                  </h6>
                </div>
                <div className="modal-body">
                  <div className="fulfillment-type-container">
                    <div
                      id="srn-fulfillmenttype-container"
                      className="srn-fulfillmenttype-component-container"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="srn-brand-selector-container" className="d-none"></div>
      </div>
    );
  }
}
