import { Component } from "react";
import Swal from "sweetalert2";
import landersLogo from "../../assets/images/landers_membership_logo.png";
import { getAccessToken, handleExpiredToken, log } from "../../helper/helper";
import ParallaxComponent from '../partials/ParallaxComponent';

const app_env = process.env.REACT_APP_ENV;

export default class TrackOrder extends Component {
  constructor() {
    super();
    this.state = {
      trackingNumber: "",
      order_details_component: {},
      objFromUrl: {},
      order_details_page: null,
      isLoggedIn: localStorage.getItem("isLoggedIn"),
      isOMRSEnabled: process.env.REACT_APP_OMRS_STATUS == 1 ? true : false
    };
  }

  componentDidMount() {
    let objFromUrl;
    let search;
    let tmp = this;

    if (this.props.location.search.length > 0) {
      getAccessToken().then(() => {
        search = this.props.location.search
          .substring(1)
          .toString()
          .replace(/%/g, "~~pct~~")
          .replace(/&/g, '","');
        objFromUrl = JSON.parse(
          '{"' +
            decodeURIComponent(search)
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
              .replace(/%/g, '","') +
            '"}'
        );

        console.log(objFromUrl);

        let order_details_component =
          window.serino_components.OrderDetailsComponent.create(
            {
              access_token: localStorage.getItem("access_token"),
              x_app_key: process.env.REACT_APP_X_APP_KEY,
              tracking_number: objFromUrl["order-number"],
              currency: "₱",
              omrs_status: this.state.isOMRSEnabled,
              x_public_key: process.env.REACT_APP_X_PUBLIC_KEY,
              logo: <img src={landersLogo} className="srn-landers-logo" />,
            },
            "srn-order-details-component"
          );

        document.addEventListener(window.serino_components.OrderDetailsComponent.events.onTrackingFailed, (e) => {
          log(e.detail)
          if (e?.detail?.error?.status) {
            log('e.detail.response', e.detail.error.status)
            if (e?.detail?.error?.status) {
              handleExpiredToken()
            }
            else {
              Swal.fire({
                text: "Invalid tracking number.",
                icon: "warning",
              }).then(function () {
                window.location = `/track-order`;
              });
            }
          }
          else {
            Swal.fire({
              text: "Invalid tracking number.",
              icon: "warning",
            }).then(function () {
              window.location = `/track-order`;
            });
          }
        })

        this.setState({ order_details_component, objFromUrl, order_details_page: true });
      });
    }

    // let getTokenTries = 0

    // let access_token_interval = setInterval(async () => {

    //     let access_token = await getCrossDomainToken()

    //     getTokenTries++

    //     if (access_token || getTokenTries == 5) {

    //         clearInterval(access_token_interval)

    //         if (access_token && access_token != null) {
    //             localStorage.setItem('access_token', access_token)
    //         }
    //         else {
    //             access_token = await getAccessToken()
    //             localStorage.setItem('access_token', access_token)
    //             setCrossStorage('access_token', access_token, 'sub')
    //         }
    //     }
    // })
  }

  handlePageRedirect = (path, query) => {
    window.location.href = `/${path}`
  };

  handleFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getOrderDetails = (TN) => {
    if (TN.length > 0) {
      window.location = `/track-order?order-number=${TN}`;
    } else {
      Swal.fire({
        text: "Tracking number is required.",
        icon: "warning",
      });
    }
  };

  render() {
    return (
      <div className="d-flex flex-column h-100">
        {
          this.state.order_details_page == true ?  null :  <ParallaxComponent page="generic" />
        }
        <div className="container track-order-container">
          {Object.keys(this.state.objFromUrl).length == 0 && (
            <div className="row justify-content-md-center">
              <div className="col-md-5">
                <h5 className="track-order-header">TRACK ORDER</h5>
                <label className="track-order-label">
                  Enter Tracking No.
                  <span className="track-order-label-asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control track-order-input"
                  name="trackingNumber"
                  onChange={(e) => this.handleFieldChange(e)}
                ></input>
                <button
                  className="btn d-flex justify-content-center align-items-center btn-secondary btn-block track-order-btn"
                  onClick={() =>
                    this.getOrderDetails(this.state.trackingNumber)
                  }
                >
                  SUBMIT
                </button>
              </div>
            </div>
          )}
        </div>
        {
          this.state.isLoggedIn == "true"  &&  Object.keys(this.state.objFromUrl).length != 0? (
            <div className="track-back-button" onClick={() => this.handlePageRedirect("my-orders")}>
              <i class="fa fa-arrow-left">
                <span>MY ORDERS</span>
              </i>
            </div>
          ) : null
        }
        <div
          id="srn-order-details-component"
          className="srn-order-details-component"
        ></div>
      </div>
    );
  }
}
