import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import { faSpinner, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import LocationPickerComponentInput from '../partials/LocationPickerInput'
import ProfileAPI from '../../_core/profile'
import LoginApi from '../../_core/login'
import Swal from "sweetalert2";
import FulfillmentTypeComponent from '../partials/fulfillmetType'

import cardLogo from '../../assets/images/landers_card_logo.png'

import { setCrossStorage, getAccessToken, getUrl, handleExpiredToken, log } from '.../../../src/helper/helper'
import RewadAPI from "../../_core/link-reward";
import moment from "moment";
import ParallaxComponent from '../partials/ParallaxComponent'

var rewardAPI = new RewadAPI()
var profileAPI = new ProfileAPI
const app_env = process.env.REACT_APP_ENV
const x_app_key = process.env.REACT_APP_X_APP_KEY
var login = new LoginApi()

const handlePageRedirect = (domain) => {
    window.location.href = getUrl(domain);
};

export default class Profile extends Component {
    constructor() {
        super()
        this.state = {
            profileMenu: [
                {
                    id: 1,
                    name: 'Personal Details'
                },
                {
                    id: 2,
                    name: 'My Addresses'
                },
                {
                    id: 3,
                    name: 'Change Password'
                },
                {
                    id: 4,
                    name: 'Link Landers Membership Card'
                }


            ],
            city: '',
            province: '',
            country: '',
            label_address: '',
            addressID: '',
            street_name: '',
            bldg_name: '',
            isChecked: false,
            edit: false,
            isLoggedIn: localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false,
            host: '',
            domain: '',
            domain_name: '',
            card_number: '',
            membership_name: '',
            email: '',
            clearInput: false,
            addressesLength: '',
            isLoading: false,
            hasLinkedCard: false,
            membership_id: '',
            membership_expiration: '',
            membership_name: '',
            longitude: '',
            latitude: '',
            isCreateAddressSubmitLoading: false,
            client: {}
        }
    }

    async componentDidMount() {

        let client;
        login.getClientDetails(localStorage.getItem('access_token'), process.env.REACT_APP_X_APP_KEY)
            .then((res) => {
                this.setState({
                    client: {
                        first_name: res.data.firstName,
                        last_name: res.data.lastName,
                        email: res.data.email,
                        phone_number: res.data.contactNumbers.find(x => x.isPrimary),
                        metas: res.data.metas
                    }
                })
            }).catch(err => {
                handleExpiredToken()
            })
        // create component
        let crm = window.serino_components.crm;
        let profileComponent = crm.createProfileComponent({
            access_token: localStorage.getItem('access_token'),
            x_app_key: x_app_key
        }, "srn-crm-profile-container");

        document.getElementsByClassName('srn-crm-mobile')[0].setAttribute("maxlength", "10");
        document.getElementsByClassName('srn-crm-mobile')[1].setAttribute("maxlength", "10");


        var host = window.location.host
        var parts = host.split('.')
        let domain = (parts[0] == 'eats') || (parts[0] == 'market') ? 'sub' : 'main'

        this.setState({
            host: parts,
            domain: domain,
            domain_name: parts[0],
        })

        // address list component
        let addresslist_crm_events = crm.events.addressEvents
        let srn_addresslist_component = crm.createAddressComponent({
            access_token: localStorage.getItem('access_token'),
            x_app_key: x_app_key
        }, "srn-crm-addresslist-container");

        document.addEventListener(addresslist_crm_events.defaultAddress, (e) => {
            if (e.detail && e.detail.length >= 1) {
                let address = e.detail[0].city + ', ' + e.detail[0].province + ', ' + e.detail[0].country

                setCrossStorage('Address', address, this.state.domain)

            }

        })

        document.addEventListener(addresslist_crm_events.addressList, (e) => {
            if (e.detail.addresses) {
                this.setState({
                    addressesLength: e.detail.addresses.length
                })
            }

        })

        document.addEventListener(addresslist_crm_events.onAdd, (e) => {
            localStorage.setItem("fulfillment_mode", 1)
            sessionStorage.setItem("signUpEnabled", true)
            sessionStorage.setItem("profileEnabled", true)

            sessionStorage.removeItem("addressEdit")
            if (document.getElementsByClassName('srn-ft-change-address-btn')[0] != null) {
                document.getElementsByClassName('srn-ft-change-address-btn')[0].click()
                $("#fulfillmenttype_modal").modal("show");
            } else {
                $("#fulfillmenttype_modal").modal("show");

            }
            // document.getElementById('srn-crm-addresslist-container').style.display = 'none'
            // document.getElementsByClassName('srn-crm-add-address-container')[0].style.display = 'none'
            // document.getElementById('srn-crm-add-address-form').style.display = 'block'
            // document.getElementById('query-add_address_component').value = ''
            this.setState({ edit: false, label_address: '', street_name: '', bldg_name: '', clearInput: true })

            localStorage.removeItem('longitude')
            localStorage.removeItem('latitude')

        })

        document.addEventListener(addresslist_crm_events.onUpdate, (e) => {
            log('edit', e.detail)
            let parsed_data = JSON.parse(e.detail.items.custom_data) ? JSON.parse(e.detail.items.custom_data) : null
            let address = (parsed_data && parsed_data.address) && parsed_data.address != undefined ? parsed_data.address : e.detail.items.city + ", " + e.detail.items.province + " " + e.detail.items.country
            let barangay = parsed_data ? parsed_data.barangay : ""
            let landmark = parsed_data ? parsed_data.landmark : ""
            localStorage.setItem("addressProfile", address)
            sessionStorage.setItem("addressId", e.detail.items.id)
            //fulfilment mode
            localStorage.setItem("fulfillment_mode", 1)
            sessionStorage.setItem("signUpEnabled", true)
            sessionStorage.setItem("profileEnabled", true)
            //added edit
            sessionStorage.setItem("addressEdit", true)
            sessionStorage.setItem("addressUpdate", true)
            //address values
            sessionStorage.setItem("isPrimary", e.detail.items.isPrimary)
            sessionStorage.setItem("addressId", e.detail.items.id)
            sessionStorage.setItem("address_label", e.detail.items.label)
            // sessionStorage.setItem("barangay", barangay)
            sessionStorage.setItem("barangay", barangay != "undefined" ? barangay : "")
            sessionStorage.setItem("house_no", e.detail.items.line1)
            sessionStorage.setItem("building", e.detail.items.line2)
            sessionStorage.setItem("landmark", landmark != "undefined" ? landmark : "")
            localStorage.setItem('longitude', e.detail.items.longitude)
            localStorage.setItem('latitude', e.detail.items.latitude)


            document.getElementById('ui-id-1').style.opacity = "0"
            //POPULATE EDIT ADDRESS
            if (document.getElementsByClassName('srn-ft-change-address-btn')[0] != null) {
                $("#Loading-modal").modal("show");
                document.getElementsByClassName('srn-ft-change-address-btn')[0].click()
                setTimeout(() => {
                    document.getElementById('query-ft-modal-location').value = address
                    if (document.getElementById('srn-ft-location-search-btn') != null) {
                        document.getElementsByClassName('ui-menu')[0].display = "none"
                        setTimeout(() => {
                            if (document.getElementById('update-address') != null) {
                                document.getElementById('update-address').click()
                                document.getElementById('srn-ft-location-search-btn').click()
                            }
                        }, 1000)
                        setTimeout(() => {
                            if (document.getElementsByClassName('ui-menu-item') != null) {
                                document.getElementsByClassName('ui-menu-item')[0].click()
                                $("#Loading-modal").modal("hide");
                            }
                            if ((sessionStorage.getItem("barangay") == undefined || sessionStorage.getItem("barangay") == "undefined")) {
                                sessionStorage.setItem("barangay", "")
                            }
                        }, 2500)
                        setTimeout(() => {
                            document.getElementsByClassName('srn-ft-proceed-btn-fulfillment')[0].click()
                            document.getElementById('query-ft-modal-location').value = address
                            $("#fulfillmenttype_modal").modal("show");
                            document.getElementById('ui-id-1').style.opacity = "1"
                            // if (e.detail.items.isPrimary == 1) {
                            //     document.getElementById('srn-ft-is-primary-check').click()
                            // }
                        }, 4000)
                    } else {
                        document.getElementById('ui-id-1').style.opacity = "1"
                        $("#Loading-modal").modal("hide");
                        $("#fulfillmenttype_modal").modal("show");
                        document.getElementById('update-address').click()
                    }
                }, 500)
            } else {
                if (document.getElementById('srn-ft-location-search-btn') != null) {
                    $("#Loading-modal").modal("show");
                    setTimeout(() => {
                        document.getElementById('update-address').click()
                        document.getElementById('srn-ft-location-search-btn').click()
                    }, 1000)
                    setTimeout(() => {
                        if (document.getElementsByClassName('ui-menu-item') != null) {
                            document.getElementsByClassName('ui-menu-item')[0].click()
                            $("#Loading-modal").modal("hide");
                        }
                        if ((sessionStorage.getItem("barangay") == undefined || sessionStorage.getItem("barangay") == "undefined")) {
                            sessionStorage.setItem("barangay", "")
                        }
                    }, 2500)
                    setTimeout(() => {
                        document.getElementsByClassName('srn-ft-proceed-btn-fulfillment')[0].click()
                        document.getElementById('query-ft-modal-location').value = address
                        $("#fulfillmenttype_modal").modal("show");
                        document.getElementById('ui-id-1').style.opacity = "1"
                        document.getElementById('update-address').click()
                        // if (e.detail.items.isPrimary == 1) {
                        //     document.getElementById('srn-ft-is-primary-check').click()
                        // }
                    }, 4000)
                } else {
                    document.getElementById('ui-id-1').style.opacity = "1"
                    $("#Loading-modal").modal("hide");
                    $("#fulfillmenttype_modal").modal("show");

                    // document.getElementById('query-ft-modal-location').value = address
                    document.getElementById('srn-ft-house').value = e.detail.items.line1
                    document.getElementById('srn-ft-building').value = e.detail.items.line2
                }
                setTimeout(() => {
                    sessionStorage.removeItem("addressUpdate")
                }, 6000)
            }
            //revert
            // console.log('edit', e.detail)

            // //address values
            // sessionStorage.setItem("addressId", e.detail.items.id)
            // sessionStorage.setItem("address_label", e.detail.items.label)
            // sessionStorage.setItem("house_no", e.detail.items.line1)
            // sessionStorage.setItem("building", e.detail.items.line2)
            // localStorage.setItem('longitude', e.detail.items.longitude)
            // localStorage.setItem('latitude', e.detail.items.latitude)

            // localStorage.setItem("fulfillment_mode", 1)
            // sessionStorage.setItem("signUpEnabled", true)
            // sessionStorage.setItem("profileEnabled", true)
            // sessionStorage.setItem("addressEdit", true)
            // sessionStorage.setItem("addressId", e.detail.items.id)

            // localStorage.setItem('longitude', e.detail.items.longitude)
            // localStorage.setItem('latitude', e.detail.items.latitude)

            // $("#fulfillmenttype_modal").modal("show");
        })

        let removeAddress_crm_events = crm.events.deleteAddressEvent
        document.addEventListener(removeAddress_crm_events.failed, (e) => {
            Swal.fire({
                icon: 'error',
                text: e.detail.error,
                customClass: {
                    confirmButton: 'btn btn-block srn-crm-ok-btn'
                },
                buttonsStyling: false
            })
        })

        // changepass word component
        crm.createForgotPasswordComponentV2({
            access_token: localStorage.getItem('access_token'),
            x_app_key: x_app_key
        }, "change-password-container")


        // changepass event
        let changePassSecurityEvent = crm.events.forgotPasswordEventsV2
        document.addEventListener(changePassSecurityEvent.continue_failed, (e) => {
            Swal.fire({
                title: 'Invalid Password',
                text: 'Password is incorrect',
                customClass: {
                    confirmButton: 'btn btn-block srn-crm-ok-btn'
                },
                buttonsStyling: false
            })
        })

        document.addEventListener(changePassSecurityEvent.continue_success, (e) => {
            Swal.fire({
                title: 'Success!',
                text: 'You have successfully changed your password',
                customClass: {
                    confirmButton: 'btn btn-block srn-crm-ok-btn'
                },
                buttonsStyling: false
            })
        })


        document.getElementById('flex-fill').classList.add('gray-body')
        let default_active_tab = document.getElementById("srn-crm-profile-menu-item-1")
        setTimeout(() => {
            default_active_tab.click();
        }, 500)


        let profileEvents = window.serino_components.crm.events.profileEvents
        document.addEventListener(profileEvents.failed, (e) => {
            Swal.fire({
                icon: 'Error',
                text: 'Please enter a valid mobile number.'
            }).then(() => {
                window.location.reload()
            })
        })

        document.addEventListener(profileEvents.success, (e) => {
            Swal.fire({
                icon: 'success',
                text: 'Changes successfully save.'
            })
        })

        sessionStorage.setItem('profile_default', 6)

        let getMetaInterval = setInterval(async () => {
            let metas = this.state.client.metas
            if (metas != undefined) {
                clearInterval(getMetaInterval)
                let linkedReward = metas.filter((m, i) => {
                    return "RewardsLinked" === m.key
                })

                let getMemberShipID = metas.filter((m, i) => {
                    return "MembershipId" === m.key
                })

                let getMemberShipExpiration = metas.filter((m, i) => {
                    return "MembershipEx" === m.key
                })

                let getMemberName = metas.filter((m, i) => {
                    return "MemberName" === m.key
                })

                if (linkedReward.length !== 0) {
                    if (linkedReward[0].value === 1 || linkedReward[0].value === "1") {
                        this.setState({
                            hasLinkedCard: true,
                            membership_id: getMemberShipID.length === 0 ? '' : getMemberShipID[0].value,
                            membership_expiration: getMemberShipExpiration.length === 0 ? '' : getMemberShipExpiration[0].value,
                            membership_name: getMemberName.length === 0 ? '' : getMemberName[0].value
                        })
                    }
                } else {
                    return
                }
            }

        }, 1500)
    }

    handleSelectedForm = (evt, id) => {
        var i, tablinks, tabcontent
        let objFromUrl;
        let search;

        if (this.props.location.search.length > 0) {
            search = this.props.location.search.substring(1);
            objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            if (objFromUrl.page != "5" && sessionStorage.getItem('profile_default') != 5) {
                document.getElementById('srn-crm-profile-tab-' + objFromUrl.page).style.display = "block";
                document.getElementById('srn-crm-profile-menu-item-' + objFromUrl.page).className += " active-profile";
                setTimeout(() => {
                    sessionStorage.setItem('profile_default', 5)
                }, 3000)

            } else if (sessionStorage.getItem('profile_default') === "5") {
                // Get all elements with class="tabcontent" and hide them
                tabcontent = document.getElementsByClassName("tab-content");
                for (i = 0; i < tabcontent.length; i++) {
                    tabcontent[i].style.display = "none";
                }
                //Get all elements with class="tablinks" and remove the class "active"
                tablinks = document.getElementsByClassName("tablinks");
                for (i = 0; i < tablinks.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" active-profile", "");
                }
                // Show the current tab, and add an "active" class to the button that opened the tab
                document.getElementById('srn-crm-profile-tab-' + id).style.display = "block";
                evt.currentTarget.className += " active-profile";
            }
        }

    }

    handleAddAddress = (e) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('handle add address fired', this.state.isCreateAddressSubmitLoading)

        if (this.state.isCreateAddressSubmitLoading) {
            return
        }

        this.setState({
            isCreateAddressSubmitLoading: true
        })

        let address = localStorage.getItem('address_input') ? localStorage.getItem('address_input').split(',') : ''

        let payload = {
            "label": this.state.label_address,
            "line1": this.state.street_name,
            "line2": this.state.bldg_name,
            "city": address[0],
            "province": address[1],
            "country": address[2],
            "zipCode": "1111",
            "longitude": localStorage.getItem('longitude'),
            "latitude": localStorage.getItem('latitude'),
            "isPrimary": this.state.isChecked
        }

        if (payload.label === '' || payload.line1 === '' || payload.city === '' || payload.province === '' || payload.country === '') {
            Swal.fire({
                text: 'Please complete required fields.'
            }).then(() => {
                this.setState({
                    isCreateAddressSubmitLoading: false
                })
            })
        } else {
            if (this.state.edit === true) {
                profileAPI.updateAddress(localStorage.getItem('access_token'), x_app_key, payload, this.state.addressID)
                    .then((res) => {
                        Swal.fire({
                            text: 'Address save successfully.',
                            icon: 'success'
                        }).then(() => {
                            document.getElementById('srn-crm-addresslist-container').style.display = 'block'
                            document.getElementsByClassName('srn-crm-add-address-container')[0].style.display = 'block'
                            document.getElementById('srn-crm-add-address-form').style.display = 'none'

                            let crm = window.serino_components.crm;
                            let addresslist_crm_events = crm.events.addressEvents
                            let loadAddressList = new CustomEvent(addresslist_crm_events.onLoad)
                            document.dispatchEvent(loadAddressList)

                            this.setState({
                                clearInput: true,
                                isCreateAddressSubmitLoading: false
                            })
                        })
                    })
                    .catch((err) => {
                        handleExpiredToken()
                        this.setState({
                            isCreateAddressSubmitLoading: false
                        })
                        console.log(err)
                    })
            } else {
                profileAPI.addAddress(localStorage.getItem('access_token'), x_app_key, payload)
                    .then((res) => {
                        Swal.fire({
                            text: 'Address save successfully.',
                            icon: 'success'
                        }).then(() => {
                            document.getElementById('srn-crm-addresslist-container').style.display = 'block'
                            document.getElementsByClassName('srn-crm-add-address-container')[0].style.display = 'block'
                            document.getElementById('srn-crm-add-address-form').style.display = 'none'

                            let crm = window.serino_components.crm;
                            let addresslist_crm_events = crm.events.addressEvents
                            let loadAddressList = new CustomEvent(addresslist_crm_events.onLoad)
                            document.dispatchEvent(loadAddressList)

                            this.setState({
                                clearInput: true,
                                isCreateAddressSubmitLoading: false
                            })
                        })

                    })
                    .catch((err) => {
                        handleExpiredToken()
                        console.log(err)
                        this.setState({
                            isCreateAddressSubmitLoading: false
                        })
                    })
            }
        }

    }

    linkMembershipCard = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isLoading: true })
        let email = this.state.client.email
        let name = this.state.membership_name
        let memberhip_id = this.state.card_number
        if (this.state.card_number === "") {
            Swal.fire({
                text: 'Kindly enter card number',
                icon: 'warning'
            }).then(() => {
                this.setState({ isLoading: false })
            })
        } else {
            setTimeout(() => {
                let payload = {
                    "email": email,
                    "name": name,
                    "membership_id": memberhip_id
                }
                rewardAPI.linkMembership(payload, localStorage.getItem('access_token'))//Temporary fix 12/28/2021
                rewardAPI.linkMembership(payload, localStorage.getItem('access_token'))
                    .then((res) => {
                        console.log('link membership', res)
                        if (res.message == "Membership linked successfully") {
                            Swal.fire({
                                html: res.message,
                                icon: 'success'
                            }).then(() => {
                                window.location.reload()
                            })
                        } else {
                            Swal.fire({
                                html: res.message,
                                icon: 'error'
                            })
                        }
                        this.setState({ isLoading: false })
                    })
                    .catch(err => {
                        console.log("asdasdasd", err.successful, err)
                        if (err.status != "0") {
                            Swal.fire({
                                html: err.responseJSON.message,
                                icon: 'error'
                            }).then(() => {
                                this.setState({ isLoading: false })
                            })
                        } else {
                            Swal.fire({
                                html: "failed to link membership",
                                icon: 'error'
                            }).then(() => {
                                this.setState({ isLoading: false })
                            })
                        }
                    })
            }, 1000)
        }
    }

    cancelAddress = () => {
        document.getElementById('srn-crm-addresslist-container').style.display = 'block'
        document.getElementsByClassName('srn-crm-add-address-container')[0].style.display = 'block'
        document.getElementById('srn-crm-add-address-form').style.display = 'none'
        this.setState({ edit: false, label_address: '', street_name: '', bldg_name: '', clearInput: true })
    }

    handleFieldChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    setChecked = () => {
        this.setState({
            isChecked: !this.state.isChecked
        })
    }
    onLogout = () => {
        this.setState({
            isLoggedIn: false
        })

        if (sessionStorage.getItem("fromSignupApply") == true || sessionStorage.getItem("fromSignupApply") == "true") {
            handlePageRedirect('main')
        } else {
            setCrossStorage('isLoggedIn', false, this.state.domain)
            localStorage.setItem('isLoggedIn', false)
            localStorage.removeItem('access_token')
            localStorage.removeItem('Address')
            localStorage.removeItem('fulfillment_type_status')
            localStorage.removeItem('category_branch_id')
            localStorage.removeItem('isMember')
            getAccessToken().then(() => {
                window.location = '/'
            })
        }
    }

    applyReward = () => {
        window.location = 'https://qa-loyalty.serino.com/'
    }


    render() {
        return (
            <div className="profile-container row justify-content-center">
                <ParallaxComponent page="generic" />
                <div className="profile-nav col-md-3">
                    <div className="card card-profile sidenav-card-profile">
                        {/* <img class="Profile image" src="..."/> */}
                        <div className="container">
                            <ul className="profile-menu-list">
                                {
                                    this.state.profileMenu.map((list, i) => {
                                        return (
                                            <li key={i}>
                                                <a href="#!" id={`srn-crm-profile-menu-item-${list.id}`}
                                                    className={`tablinks-${list.id} tablinks srn-crm-profile-menu-item`}
                                                    onClick={(e) => this.handleSelectedForm(e, list.id)}>{list.name}</a>
                                                <span className="li-dot">•</span>
                                            </li>
                                        )
                                    })
                                }
                                <li><a href="#!" id="srn-crm-profile-menu-item" className="tablinks srn-crm-profile-menu-item profile-signout"
                                    onClick={() => this.onLogout()}>Sign Out</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="profile-details col-md-5">
                    <div id="srn-crm-profile-tab-1" className="tab-content">
                        <div className="card card-profile">
                            <div id="srn-crm-profile-container"></div>
                        </div>
                    </div>

                    <div id="srn-crm-profile-tab-2" className="tab-content">
                        <div className="card card-profile">
                            <div id="srn-crm-addresslist-container"></div>
                            <FulfillmentTypeComponent
                                access_token={localStorage.getItem("access_token")}
                                x_app_key={1}
                                brand_id={0}
                                product={null}
                                isPickupDisabled
                                handleModalClose={this.props.handleModalClose ? this.props.handleModalClose : null}
                                main={this.props.main}
                            />
                            <div className="srn-crm-add-address-form" id="srn-crm-add-address-form">
                                <h3 class="srn-crm-title">My ADDRESS</h3>
                                <div className="form-group">

                                    <label className="srn-crm-label">Label <span className="srn-required-indicator">*</span></label>
                                    <input name="label_address" type="text"
                                        className="form-control srn-crm-form-control srn-crm-label-address"
                                        placeholder="Address label"
                                        value={this.state.label_address || ''}
                                        onChange={(e) => this.handleFieldChange(e)}></input>
                                </div>

                                <div className="form-group">
                                    <label className="srn-crm-label">Delivery Address <span className="srn-required-indicator">*</span></label>
                                    <span className="srn-ft-location-icon">
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    </span>
                                    <LocationPickerComponentInput id="add_address_component" clearInput={this.state.clearInput} />
                                </div>


                                <div className="form-group">
                                    <label className="srn-crm-label">House No. / Street Name <span className="srn-required-indicator">*</span></label>
                                    <input name="street_name" type="text"
                                        className="form-control srn-crm-form-control srn-crm-street-name"
                                        required={true}
                                        value={this.state.street_name || ''}
                                        onChange={(e) => this.handleFieldChange(e)}></input>
                                </div>

                                <div className="form-group">
                                    <label className="srn-crm-label">Floor / Unit / Building Name (optional)</label>
                                    <input name="bldg_name" type="text"
                                        className="form-control srn-crm-form-control srn-crm-street-name"
                                        onChange={(e) => this.handleFieldChange(e)}></input>
                                </div>

                                <div className="form-check mb-5">
                                    <input type="checkbox" name="default_address_chckbx"
                                        id="default_address_chckbx"
                                        className="form-check-input srn-crm-checkbox"
                                        onChange={() => this.setChecked()}
                                        checked={this.state.isChecked}></input>
                                    <label className="form-check-label srn-crm-label" for="default_address_chckbx">Use as my default address</label>
                                </div>

                                <div className="form-group">
                                    <button className="btn btn-block srn-crm-save-btn" onClick={(e) => this.handleAddAddress(e)} disabled={this.state.isCreateAddressSubmitLoading}>
                                        {
                                            this.state.isCreateAddressSubmitLoading &&
                                            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                        }
                                        Save
                                    </button>
                                    <button type="button" className="btn btn-outline-dark btn-block srn-crm-cancel-btn" onClick={() => this.cancelAddress()}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="srn-crm-profile-tab-3" className="tab-content">
                        <div className="card card-profile">
                            <form className="change_password_form">
                                <h3 className="srn-security-header-profile">Change Password</h3>
                                <div id="change-password-container">
                                </div>
                            </form>
                        </div>
                    </div>

                    <div id="srn-crm-profile-tab-4" className="tab-content">
                        <div className="card card-profile" id="card-link-membership">
                            <div className="card-body">
                                <form className={`srn-crm-link-membership-form ${this.state.hasLinkedCard === true ? 'd-none' : ''}`} onSubmit={(event) => this.linkMembershipCard(event)} id="srn-crm-link-membership-form">
                                    <h3 className="srn-crm-title">Link Landers<br />Membership Card</h3>
                                    <span className="srn-crm-description">Verify and link your Landers membership code with this account to enjoy exclusive online deals!</span>

                                    <div className="form-group form-fullname">
                                        <label className="srn-crm-label">Membership Full Name *</label>
                                        <input name="membership_name" type="text"
                                            className="form-control srn-crm-form-control srn-crm-membership_name"
                                            placeholder="Enter your Membership full name"
                                            value={this.state.membership_name || ''}
                                            onChange={(e) => this.handleFieldChange(e)}
                                            required
                                        ></input>
                                    </div>

                                    <div className="form-group">
                                        <label className="srn-crm-label">Membership Card Number *</label>
                                        <input name="card_number" type="text"
                                            className="form-control srn-crm-form-control srn-crm-card_number"
                                            placeholder="Enter membership card number"
                                            value={this.state.card_number || ''}
                                            onChange={(e) => this.handleFieldChange(e)}
                                            required
                                        ></input>
                                    </div>

                                    <div className="form-group srn-crm-action-btn">
                                        <button type="button" className="btn btn-outline-dark btn-block srn-crm-skip-btn">
                                            Skip
                                        </button>
                                        <button type="submit" className="btn btn-block srn-crm-save-btn membership-verify-btn">
                                            {
                                                this.state.isLoading != true ?
                                                    'Verify' :
                                                    <FontAwesomeIcon icon={faSpinner} spin />
                                            }
                                        </button>
                                    </div>
                                </form>

                                {/* HAS LINKED CARD */}
                                {
                                    this.state.hasLinkedCard === true ?
                                        <div className="linked-card-container">
                                            <h3 className="srn-crm-title">Link Landers<br />Membership Card</h3>
                                            <div className="card linked-card">
                                                <img className="linked-card-logo" src={cardLogo}></img>
                                                <h5 className="linked-card-label">PREMIUM CARD</h5>
                                                <h3 className="linked-card-number">{this.state.membership_id}</h3>
                                                <div className="linked-card-info-container">
                                                    <h6 className="linked-card-name">{(this.state.membership_name).toUpperCase()}</h6>
                                                    <h6 className="linked-card-membershipDate">
                                                        <small>DATE OF EXPIRY</small> {moment(this.state.membership_expiration).format('MM/YY')}
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={`card-body apply-membership-card ${this.state.hasLinkedCard === true ? 'd-none' : ''}`}>
                                <div className="form-group apply-membership-container">
                                    <span>Don't Have A Membership Card Yet?</span>
                                    <button type="button" className="btn btn-block srn-crm-save-btn" onClick={() => window.location = '/membership'}>
                                        APPLY MEMBERSHIP CARD
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" id="Loading-modal" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div className="modal-body">
                                <FontAwesomeIcon icon={faSpinner} spin />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}


