import axios from 'axios'
import $ from 'jquery'

let SERINO_API = process.env.REACT_APP_SERINO_API
let X_PUBLIC_KEY = process.env.REACT_APP_X_PUBLIC_KEY
let OMRS_BRANDS_URL = process.env.REACT_APP_OMRS_BRANDS_URL
let OMRS_URL = process.env.REACT_APP_OMRS_URL
let SOMS_PUBLIC_KEY = process.env.REACT_APP_SOMS_PUBLIC_KEY
let BASIC_TOKEN = process.env.REACT_APP_BASIC_TOKEN

export const getAppToken = (appId, user) => {
    return BASIC_TOKEN
}

export default class BrandsOMRSAPI {
    getBrands(access_token, appkey) {
            return $.ajax({
                url: `${OMRS_BRANDS_URL}/public/${SOMS_PUBLIC_KEY}/1/merchant-groups/${appkey}/brands`,
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + access_token,
                    // "X-PUBLIC-KEY": X_PUBLIC_KEY
                },
                contentType: 'application/json'
            })
    } 
}
