import { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import LinkRewardAPI from '../../_core/link-reward'
import LoginApi from '../../_core/login'
import Swal from 'sweetalert2'

var linkReward = new LinkRewardAPI()
var login = new LoginApi()

export default class LinkRewardCard extends Component {
    constructor() {
        super()
        this.state = {
            membership_name: '',
            membership_id: '',
            isLoading: false
        }
    }

    componentDidMount() {
        document.getElementById('flex-fill').classList.add('gray-body')
        console.log(process.env.REACT_APP_MEMBERSHIP_URL)
    }

    verifyAccount = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isLoading: true })
        let client;
        login.getClientDetails(localStorage.getItem('access_token'), process.env.REACT_APP_X_APP_KEY)
        .then((res) => {
            client = {
                first_name: res.data.firstName,
                last_name: res.data.lastName,
                email: res.data.email,
                phone_number: res.data.contactNumbers.find(x => x.isPrimary),
                metas: res.data.metas
            }
        })
        .catch((err) => {            
            if (err.status === 500) {
                this.setState({
                    isLoggedIn: false
                })
                localStorage.setItem('isLoggedIn', false)
            }
        })
        let payload = {
            "email": client.email,
            "name": this.state.membership_name,
            "membership_id": this.state.membership_id
        }
        linkReward.linkRewardCard(localStorage.getItem('access_token'), payload)
            .then((res) => {
                console.log(res)
                if (res.data.card_existing) {
                    Swal.fire({
                        text: 'Link rewards success',
                        icon: 'success'
                    }).then(() => {
                        window.location = '/'
                    })
                } else {
                    Swal.fire({
                        text: res.data.reason,
                        icon: 'error'
                    }).then(() => {
                        window.location = '/link-reward-card'
                    })
                }
                this.setState({ isLoading: false })
            })
            .catch((err) => {
                if (err.successful) {
                    Swal.fire({
                        text: err.data.reason,
                        icon: 'error'
                    }).then(() => {
                        window.location = '/link-reward-card'
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    handleFieldChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    render() {
        return (
            <div className="link-reward-container">
                <div className="container">
                    <h5 className="srn-crm-title">Link Reward Card</h5>

                    <form className="link-reward-card-form" onSubmit={(e) => this.verifyAccount(e)}>
                        <div className="form-group">
                            <label className="srn-crm-label">Enter Membership Name</label>
                            <input type="text" name="membership_name" className="form-control srn-crm-form-control" placeholder="Enter name" required={true} onChange={(e) => this.handleFieldChange(e)}></input>
                        </div>

                        <div className="form-group">
                            <label className="srn-crm-label">Enter Membership id</label>
                            <input type="text" name="membership_id" className="form-control srn-crm-form-control" placeholder="Enter membership id no." required={true} onChange={(e) => this.handleFieldChange(e)}></input>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-block srn-crm-save-btn" disabled={this.state.isLoading}>
                                { this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin />  } VERIFY</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}