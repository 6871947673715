import { store } from '../redux/store'
// const serinoComponents = window.serino_components
import { serinoComponents } from './mock'

export const triggerSerinoEvent = (eventId, detail) => {

    const customEvent = new CustomEvent(eventId, {
        detail: detail,
        bubbles: true,
        composed: true
    })

    document.dispatchEvent(customEvent);
}

export const log = (message) => {
    if (process.env.REACT_APP_DEBUG_MODE) {
        console.log(message)
    }
}
export class SerinoComponent {
    constructor(opts, container, name) {
        this.component = serinoComponents[name]
        this.component.create(opts, container)

        this.setupEvents()
    }

    setupEvents() {
    }

    triggerComponentSubscription(eventId, detail) {
        triggerSerinoEvent(eventId, detail)
    }

    dispatch(action) {
        store.dispatch(action)
    }
}