import axios from 'axios'
let SERINO_API = process.env.REACT_APP_SERINO_API

export default class FulfillmentAPI {
	getAvailableBranch(position, access_token, x_app_key) {
		try {
			let axiosPromise = axios.get(`${SERINO_API}/branches/public/serviceable/closest-by-location-with-brand?longitude=${position.lng}&latitude=${position.lat}`, {
				headers: {
					"Authorization": 'Bearer ' + access_token,
					"x-app-key": x_app_key
				}
			})

			return new Promise((resolve, reject) => {
				try {
					axiosPromise
					.then(response => {
						if (response.status == 200) {
							resolve(response.data)
						}
						else {
							reject(response)
						}
					})
					.catch((error) => {
						reject(error.response);
					})
				}
				catch (error) {
					reject(error)
				}
			})
		}
		catch (error) {
			throw error;
		}

	}
}