import { Component} from "react";
import $ from 'jquery'
import {triggerSerinoEvent, log } from '../../helper/helper'
import EatsLogo from '../../assets/images/central_gold.png'
import CentralLogo from '../../assets/images/new/central-logo-2-white.svg'
import checkCircle from '../../assets/images/check_circle.svg'
import ProfileAPI from '../../_core/profile'
import Swal from 'sweetalert2'

const booking_key = process.env.REACT_APP_BOOKING_KEY
const x_app_key = process.env.REACT_APP_X_APP_KEY
let market_app_key = process.env.REACT_APP_MARKET_APP_ID
let eats_app_key = process.env.REACT_APP_EATS_APP_ID
var profileAPI = new ProfileAPI

export default class FulfillmentTypeComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            brand_id: this.props.brand_id ? this.props.brand_id : localStorage.getItem('brand_id'),
            product: this.props.product ? this.props.product : {},
            access_token: '',
            logo: CentralLogo,
            isOMRSEnabled: process.env.REACT_APP_OMRS_STATUS == 1 ? true : false
        }
    }
    componentDidUpdate(prevProps) {
        if ((this.props.brand_id !== prevProps.brand_id || this.props.x_app_key !== prevProps.x_app_key) && localStorage.getItem('fulfillment_mode') !== 1) {
            console.log('brand_id from fulfillmenttype js in app', this.state.brand_id)
            this.setState({brand_id: this.props.brand_id}, () => {

                // let topLogo = this.state.logo
                
                // if (!localStorage.getItem('fulfillment_mode')) {
                //     if (this.props.x_app_key == eats_app_key) {
                //         topLogo = EatsSVG
                //     }
                //     else {
                //         topLogo = MarketSVG
                //     }
                // }

                // log({topLogo})

                triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.onBrandUpdate, {
                    brand_id: this.state.brand_id,
                    app_key: this.props.x_app_key,
                    classNames: this.props.x_app_key == eats_app_key ? 'srn-ff-eats-new' : 'srn-ff-market-new'
                    // logo: <img src={topLogo} />
                })
            })
        }
        if (this.props.product !== prevProps.product) {
            this.setState({product: this.props.product})
        }
        if (this.props.access_token !== prevProps.access_token) {
            this.setState({access_token: this.props.access_token}, () => this.init(this.props.access_token))
        }
    }
    componentDidMount() {
        const appHeight = () => {
            document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
        };
        window.addEventListener('resize', appHeight);
        appHeight();
         // $('#fulfillmenttype_modal').modal('show')
        // $('#fulfillmenttype_modal').on('show.bs.modal', (e) => {
        //     setTimeout(() => {
        //     }, 300);
        // });

        if (this.props.access_token && this.props.access_token != null) {
            this.init(this.props.access_token)
        }

        $('#fulfillmenttype_modal').on('hidden.bs.modal', (e) => {
            if (localStorage.getItem('isLoggedIn') && localStorage.getItem('isLoggedIn') === 'true' && window.location.pathname !== '/login') {
                sessionStorage.removeItem('signUpEnabled')
            }
            localStorage.removeItem('fulfillment_mode')
            triggerSerinoEvent(window.serino_components.ProductListComponent.subscriptions.resetSelectedProduct)
            triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.resetSelectedProduct)
            triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.resetSelectedProduct)
            if (this.props.handleModalClose) {
                this.props.handleModalClose()
            }
        })
        $('#fulfillmenttype_modal').on('shown.bs.modal', (e) => {
            log('brand_id', localStorage.getItem('brand_id'))
            // if (sessionStorage.getItem('appId')) {
            //     triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.setAppId, sessionStorage.getItem('appId'))
            // }

            if (!localStorage.getItem('brand_id')) {
                localStorage.setItem('brand_id', market_app_key)
            }
            triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
        });
    }

    init = (access_token) => {        
        // console.log('brand___::', this.props.brand_id)
        // console.log('app_key::', this.props.x_app_key)

        let address_array = this.getAddressArray()

        let fulfillmenttype = window.serino_components.FulfillmentTypeComponent.create({
            access_token: access_token,
            booking_key: booking_key, // key for booking api
            x_app_key: this.props.x_app_key,
            offhours: this.props.offhours,
            busyStore: this.props.busyStore,
            branch_id: localStorage.getItem('branch_id'), // happy path branch
            brand_id: localStorage.getItem('brand_id'),
            closing_time: this.props.closing_time,
            location_id: 'ft-modal-location',
            mode: this.props.x_app_key,
            location_key: process.env.REACT_APP_MAP_JS_LIB_KEY,
            address_array: address_array,
            logo: <img src={this.state.logo} className="srn-ft-default-logo" />,
            isOMRSEnabled: this.state.isOMRSEnabled
            // isPickupDisabled: this.props.isPickupDisabled ? this.props.isPickupDisabled : false 
        }, "srn-fulfillmenttype-container")

        let fulfillmentTypeEvent = window.serino_components.FulfillmentTypeComponent.events

        document.addEventListener(fulfillmentTypeEvent.onConfirm, (e) => {
            localStorage.setItem('fulfillment_type_status', e.detail.detail.fulfillmentType_status)
            this.props.getFulfillmentDetails(e.detail.detail)
            $('#fulfillmenttype_modal').modal('hide')
        })

        document.addEventListener(fulfillmentTypeEvent.onConfirmProfile, (e) => {
            this.handleAddAddress(e.detail)
        })
    }

    handleAddAddress = (e) => {
        console.log("detail from handle add", e)
        let payload = {
            "address": e.detail.address,
            "label": e.detail.label,
            "line1": e.detail.line1,
            "line2": e.detail.line2,
            "city": e.detail.city,
            "province": e.detail.province,
            "country": "Philippines",
            "zipCode": "1111",
            "longitude": e.detail.longitude,
            "latitude": e.detail.latitude,
            "isPrimary": e.detail.isPrimary,
            "custom_data": e.detail.custom_data
        }
        if (sessionStorage.getItem("addressEdit") === "true") {
            profileAPI.updateAddress(localStorage.getItem('access_token'), x_app_key, payload, sessionStorage.getItem("addressId"))
            .then((res) => {
                if(res.message == "Updated successfully") {
                    Swal.fire({
                        imageUrl: checkCircle,
                        title: "success",
                        html: "address has been </br>updated!",
                        className: "swal-profile",
                        customClass: {
                            container: 'swal-address-container',
                            popup: 'swal-address-update',
                            image: 'swal-icon-success'
                        }
                    }).then(() => {
                        window.location.reload()
                    })
                } else {
                    Swal.fire({
                        text: "Update Failed",
                        icon: 'error',
                    }).then(() => {
                        window.location.reload()
                    })
                }
            }).catch((err) => {                        
                console.log(err)
            })
            // "Created successfully"
        } else {
            profileAPI.addAddress(localStorage.getItem('access_token'), x_app_key, payload)
                .then((res) => {
                    if(res.message == "Created successfully") {
                        Swal.fire({
                            imageUrl: checkCircle,
                            title: "success",
                            html: "address has been </br>added!",
                            className: "swal-profile",
                            customClass: {
                                container: 'swal-address-container',
                                popup: 'swal-address-update',
                                image: 'swal-icon-success'
                            }
                        }).then(() => {
                            window.location.reload()
                        })
                    } else {
                        Swal.fire({
                            text: "Update Failed",
                            icon: 'error',
                        }).then(() => {
                            window.location.reload()
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }
    
    getAddressArray = () => {

        let addresses = []

        let market_addresses = localStorage.getItem(`address_object_${market_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_key}`)) : []
        let eats_addresses = localStorage.getItem(`address_object_${eats_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${eats_app_key}`)) : []

        if (market_addresses && market_addresses.length > 0) {
            market_addresses.map(x => addresses.push(x))
        }

        if (eats_addresses && eats_addresses.length > 0) {
            eats_addresses.map(x => addresses.push(x))
        }

        return addresses

    }

    render() {
        let brand_logo = localStorage.getItem('brand_logo') ? localStorage.getItem('brand_logo') : EatsLogo
        return (
            <div className={`modal fade srn-ft-modal-container`} id="fulfillmenttype_modal" tabIndex="-1" role="dialog" aria-labelledby="fulfillmenttype_lbl" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    
                    <div className="modal-content srn-ft-main-container">
                        <span className="d-none" data-dismiss="modal" id="srn-ft-modal-signup-close">check</span>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        {

                         // <div className="modal-header srn-ft-modal-header">
                            // {
                                // this.state.product && Object.keys(this.state.product).length > 0 ?
                                // <Fragment>                                
                                    // {                                        
                                        // this.props.x_app_key == market_app_key ?
                                            // <img src={MarketLogo} className="srn-ft-brand-logo" />
                                        // :
                                        // <img src={this.state.product.brand_logo} className="srn-ft-brand-logo" />
                                    // }                                    
                                // </Fragment> :
                                // <Fragment>
                                    // {                                        
                                        // this.props.x_app_key == market_app_key ?
                                            // <img src={MarketLogo} className="srn-ft-brand-logo" />
                                        // :
                                        // <img src={EatsLogo} className="srn-ft-brand-logo" />
                                    // }
                                // </Fragment>
                            // }
                            // {/* <img src={brand_logo} className="srn-ft-brand-logo" /> */}
                            // <label className="modal-title fulfillmenttype-lbl p-0" id="fulfillmenttype_lbl">Select Fulfillment Type</label>
                         // </div>
                        }
                        <div className="modal-body fulfillmenttype-body">
                            {
                                <div id="srn-fulfillmenttype-container"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
}