import React, { Component } from "react";

import shoppingBasket from "../../assets/images/central_shopping_basket.png";
import { getUrl } from "../../helper/helper";

import eatsbotnav from "../../assets/images/mweb-bot-nav-eats.png";
import marketbotnav from "../../assets/images/mweb-bot-nav-market.svg";
import profilebotnav from "../../assets/images/mweb-bot-nav-profile.png";

import youtube from "../../assets/images/YTx2.svg"
import facebook from "../../assets/images/FBx2.svg"
import instagram from "../../assets/images/IGx2.svg"

import CoreAPI from '../../_core/core'

const handlePageRedirect = (domain) => {
  window.location.href = getUrl(domain);
};

let coreAPI = new CoreAPI()

export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      brands: [],
      apps: [],
    };
  }

  componentDidMount = () => {
    this.getDataFromJSON()
  }

  handleGotoProducts = (brand) => {
    var str = brand.name;
    str = str.replace(/\s+/g, "-").toLowerCase();
    localStorage.setItem("brand_logo", brand.logo);
    localStorage.setItem("brand_id", brand.id);

    localStorage.removeItem("branch_id");
    localStorage.removeItem("Address");
    localStorage.removeItem("brand_name");
    localStorage.removeItem("brand_best_seller");
    localStorage.removeItem("fulfillment_type_status");
    localStorage.setItem("brand_name", str + "-brand");
    localStorage.setItem("brand_name_str", brand.name);
    localStorage.setItem("brand_best_seller", brand.name.toLowerCase());
    localStorage.setItem("brand_id", brand.id.toString());
    sessionStorage.setItem("viewAction", "brandSelect");
    localStorage.setItem("brandN", str);
    sessionStorage.removeItem("foodType");
    sessionStorage.removeItem("viewAllAction");
    sessionStorage.removeItem("searchKeyWord");
    setTimeout(() => {
      // this.props.history.push(`/eats-products?${str}`);
      window.location = `/eats-products?${str}`
    }, 300);
  };

  getDataFromJSON = async () => {
    let brands = []
    let apps = []

    try {
        const dataFromJSON = await coreAPI.getDataFromJSON()

        if (!dataFromJSON) {
            return
        }

        if (dataFromJSON.data && dataFromJSON.data.brands) {
            brands = dataFromJSON.data.brands
        }
        if (dataFromJSON.data && dataFromJSON.data.apps) {
            apps = dataFromJSON.data.apps
        }
        this.setState({brands, apps})
        console.log("brandss", this.state.brands.status != 0)
    }
    catch(getDataErr) {
        console.log(getDataErr)
    }
  }

  render() {
    return (
      <footer className="cmb-footer">
        <div className="web-footer">
          <div className="container row footer-container">
            <div className="col-md-12 d-flex justify-content-between footer-nav-container">
              <div className="col footer-nav footer-brands" id="footer-brands">
                <h6>OUR RESTAURANTS</h6>
                <span className="col-12">
                  <span className="row">
                    {
                      this.state.brands.filter(x => x.status == 1 && x.name != "Da Gianni Cucina Italiana").map((brand, i) => {
                        return(
                          <a className="col-5 brand-name" onClick={() => this.handleGotoProducts(brand)}>{brand.name}</a>
                        )
                      })
                    }
                    {
                      this.state.brands.filter(x => x.status == 1 && x.name == "Da Gianni Cucina Italiana").map((brand, i) => {
                        return(
                          <a className="col-5 brand-name" onClick={() => this.handleGotoProducts(brand)}>{brand.name}</a>
                        )
                      })
                    }
                  </span>
                </span>

              </div>
              <div className="col footer-nav d-none">
                <h6>FOLLOW US</h6>
                <a href="/eats">Central Eats</a>
                <div className="social-media">
                  <img src={facebook} onClick={() => window.open('https://www.facebook.com/bycentraleatsph', "_blank")}/>
                  <img src={instagram} onClick={() => window.open('https://www.instagram.com/bycentraleatsph', "_blank")}/>
                  {/* <img src={youtube}/> */}
                </div>
                <a href="/market">Central Market</a>
                <div className="social-media">
                  <img src={facebook} onClick={() => window.open('https://www.facebook.com/bycentralmarketph', "_blank")}/>
                  <img src={instagram} onClick={() => window.open('https://www.instagram.com/centralmarketph/', "_blank")}/>
                  {/* <img src={youtube}/> */}
                </div>
              </div>
              <div className="col footer-nav">
                <h6>GROCERIES</h6>
                <a href="/market">Shop Now</a>
                <a href="/market?shoppingbasket">Shopping Baskets</a>
                {/* <a href="">Our Bundles</a> */}
                <a href="/membership">Apply for a Membership</a>
              </div>
              <div className="col footer-nav">
                <h6>INFORMATION</h6>
                <a href="/about-us">About Us</a>
                <a href="/terms-conditions">Terms and Conditions</a>
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/contact-us">Contact Us</a>
              </div>
            </div>
            <div className="footer-end col-12">
              <div className="footer-container">
                <span>© 2022 Central</span>
                <div className="social-media">
                  <img src={facebook} onClick={() => window.open('https://www.facebook.com/bycentral.ph/ ', "_blank")}/>
                  <img src={instagram} onClick={() => window.open('https://www.instagram.com/bycentral.ph/', "_blank")}/>
                  {/* <img src={youtube}/> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`container row footer-container mweb-footer ${window.location.pathname == "/cart" || window.location.pathname == "/checkout" ? "d-none" : ""}`}>
          <div className="mweb-footer-container">
            <ul className="mweb-footer-nav-list">
              <li className="footer-nav-home" onClick={() => window.location = "/"}><span>C</span>Central</li>
              <li onClick={() => window.location = "/eats"}><img src={eatsbotnav}/>Eats</li>
              <li className="footer-nav-basket" onClick={() => window.location = "/cart"}>
                <img className="footer-nav-basket-img" src={shoppingBasket}/>
                <span className={`srn-v3-cart-count srn-cart-quantity ${localStorage.getItem('cart_items') && localStorage.getItem('cart_items').length > 2 ? '' : 'd-none'}`}></span>
              </li>
              <li onClick={() => window.location = "/market"}><img src={marketbotnav}/>Market</li>
              {
                localStorage.getItem("isLoggedIn") == "true" ?
                <li onClick={() => window.location = "/profile?page=1"}><img src={profilebotnav}/>Me</li>
                :
                <li onClick={() => window.location = "/login"}><img src={profilebotnav}/>Me</li>
              }
            </ul>
          </div>
        </div>

      </footer>
    );
  }
}
