import { Component} from "react";
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProductList from '../../_core/products'
import { log } from '../../helper/helper'

let productList = new ProductList()

export default class BundleComponent extends Component {
	constructor(props) {
		super(props) 
		this.state = {
			bundles: [
				// {
				// 	key: 1,
				// 	name: 'Blanched Thyme & Parsley Yak'
				// },
				// {
				// 	key: 2,
				// 	name: 'Gentle-Fried Dark Ale Ostrich'
				// },
				// {
				// 	key: 3,
				// 	name: 'Pressure-Fried Chestnuts & Scallops'
				// },
				// {
				// 	key: 4,
				// 	name: 'Seared Juniper Fish'
				// },
				// {
				// 	key: 5,
				// 	name: 'Engine-Cooked Coriander & Lemon Goulash'
				// },
				// {
				// 	key: 6,
				// 	name: 'Oven-Baked Orange & Olive Lasagna'
				// },
				// {
				// 	key: 1,
				// 	name: 'Blanched Thyme & Parsley Yak'
				// },
				// {
				// 	key: 2,
				// 	name: 'Gentle-Fried Dark Ale Ostrich'
				// },
				// {
				// 	key: 3,
				// 	name: 'Pressure-Fried Chestnuts & Scallops'
				// },
				// {
				// 	key: 4,
				// 	name: 'Seared Juniper Fish'
				// },
				// {
				// 	key: 5,
				// 	name: 'Engine-Cooked Coriander & Lemon Goulash'
				// },
				// {
				// 	key: 6,
				// 	name: 'Oven-Baked Orange & Olive Lasagna'
				// },
			]
		}
	}

	async componentDidMount() {

        const collectionListResult = await productList.getCollections(this.props.app_key)
        log({collectionListResult})

        let bundles = collectionListResult.data.items.filter(x => x.tags === 'Bundle' && x.name.charAt(0) !== ' ')
        // let bundles = collectionListResult.data.items

        log({bundles})

        this.setState({bundles})

	}

	handleScroll = (direction)  => {
    	let element = document.getElementById(`${this.props.id}-srn-bundle-items`)
		let leftArrow = document.getElementById(`${this.props.id}-srn-bundle-arrow-left`)
		let parentElement = document.getElementById(`${this.props.id}-srn-bundle-container`)
		let parentWidth = parentElement.clientWidth

		if (direction == 1) {
			element.scrollLeft = (element.scrollLeft + parentWidth)
		}
		if (direction == 0) {
			element.scrollLeft = (element.scrollLeft - parentWidth)
		}
	}

	handleBundleClick = (item) => {
		if (this.props.handleClick) {
			this.props.handleClick(item)
		}
	}

	render() {
		return(
			<div id={`${this.props.id}-srn-bundle-container`} className={`srn-bundle-container ${this.props.className}`}>
				<div id={`${this.props.id}-srn-bundle-items`} className="srn-bundle-items">
					{this.props.logo || null }
					{
						this.state.bundles.map((item,i) => (
							<div className="srn-bundle-item" key={item.key} onClick={() => this.handleBundleClick(item)}>
								<div className="srn-bundle-item-img-container">
									{
										item.images && 
										<img className="srn-bundle-item-img" src={item.images}/>
									}
								</div>
								<p className="srn-bundle-item-name" title={item.name}>{item.name || <span>&nbsp;</span>}</p>
							</div>
						))
					}

					<a id={`${this.props.id}-srn-bundle-arrow-left}`} href="#!" className={`srn-bundle-arrow srn-bundle-arrow-left`} onClick={() => this.handleScroll(0)}>
						<FontAwesomeIcon icon={faAngleLeft} />
					</a>
					<a id={`${this.props.id}-srn-bundle-arrow-right}`} href="#!" className={`srn-bundle-arrow srn-bundle-arrow-right`} onClick={() => this.handleScroll(1)}>
						<FontAwesomeIcon icon={faAngleRight} />
					</a>
				</div>
			</div>
		)
	}
}