const SELECT_APPLICATION = '[COMMAND][HEADER] SELECT APPLICATION'
const selectApplication = ({ appId, redirect, redirectUrl }) => {
    return {
        type: SELECT_APPLICATION,
        payload: {
            appId,
            redirect,
            redirectUrl
        }
    }
}

const TRIGGER_SELECT_APPLICATION = '[COMMAND][HEADER] TRIGGER SELECT APPLICATION'
const triggerSelectApplication = ({ appId }) => {
    return {
        type: TRIGGER_SELECT_APPLICATION,
        payload: {
            appId
        }
    }
}

const MOUNT_HEADER = '[EVENT][HEADER] MOUNT HEADER'
const mountHeader = ({ containerId }) => {
    return {
        type: MOUNT_HEADER,
        payload: {
            containerId
        }
    }
}

const MOUNT_APPSELECTOR = '[COMMAND][HEADER] MOUNT APPSELECTOR'
const mountAppSelector = ({ containerId, options, appSelector }) => {
    return {
        type: MOUNT_APPSELECTOR,
        payload: {
            containerId,
            options,
            appSelector
        }
    }
}

export const headerActionTypes = {
    SELECT_APPLICATION,
    TRIGGER_SELECT_APPLICATION,
    MOUNT_HEADER,
    MOUNT_APPSELECTOR
}

export const headerActions = {
    selectApplication,
    triggerSelectApplication,
    mountHeader,
    mountAppSelector
}