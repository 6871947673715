import React, { Component, Fragment } from "react";
import $ from 'jquery'

// MAIN
import plxRight1 from '../../assets/images/parallax/main/plx-right-1.png'
import plxRight2 from '../../assets/images/parallax/main/plx-right-2.png'
import plxLeft1 from '../../assets/images/parallax/main/plx-left-1.png'
import plxLeft2 from '../../assets/images/parallax/main/plx-left-2.png'

//Market
import plxMarketLeft1 from '../../assets/images/parallax/market/market_landing_plx.png'
import plxMarketProductLeft1 from '../../assets/images/parallax/market/market_product_plx1.png'
import plxMarketProductLeft2 from '../../assets/images/parallax/market/market_product_plx2.png'
import plxMarketProductLeft3 from '../../assets/images/parallax/market/market_product_plx3.png'

// EATS
import plxEatsMain from '../../assets/images/parallax/eats/main.png'
import plxEatsGroup1 from '../../assets/images/parallax/eats/eats_group1.png'
import plxEatsGroup2 from '../../assets/images/parallax/eats/eats_group2.png'

//LOGO
import EatsSVG from '../../assets/images/new/central-eats-logo-2.svg'
import MarketSVG from '../../assets/images/new/central-market-logo-2.svg'

export class ParallaxComponent extends Component {
	componentDidMount() {

		let el = document.querySelector('#root')

		el.addEventListener('scroll', () => {
			// Generic
			this.parallaxFunc('srn-plx-left-1', 50)
			this.parallaxFunc('srn-plx-left-2', 30)
			this.parallaxFunc('srn-plx-right-1', 80)
			this.parallaxFunc('srn-plx-right-2', 30)

			//market landing
			//this.parallaxFunc('plx-market-landing', 50)

			//market product
			this.parallaxFunc('plx-market-product1', 50)
			this.parallaxFunc('plx-market-product2', 30)
			this.parallaxFunc('plx-market-product3', 80)

			//eats landing
			this.parallaxFunc('plx-eats-main-1', 35)
			this.parallaxFunc('plx-eats-main-2', 50)

		})
	}

	parallaxFunc = (className, speed, offset) => {

		let el = document.querySelector('#root')

		let scrollTop = document.body.scrollTop

		var scrollPos = $(el).scrollTop();

		let calcScroll = (offset ? (scrollPos - offset) : scrollPos) / speed

		// console.log({calcScroll})

		$(`.${className}`).css({
			'transform' : 'translate(0px, '+ calcScroll +'%)'
		});
	}

	getContent = (page) => {
		switch (page) {
			case 'main-landing':
				return (
					<div class="srn-plx-content">
						<img src={plxLeft1} alt=" " className="srn-plx-img srn-plx-left srn-plx-left-1" />
						<img src={plxLeft2} alt=" " className="srn-plx-img srn-plx-left srn-plx-left-2" />
						<img src={plxRight2} alt=" " className="srn-plx-img srn-plx-right srn-plx-right-2" />
						<img src={plxRight1} alt=" " className="srn-plx-img srn-plx-right srn-plx-right-1" />
					</div>
				)
			case 'market-landing':
				return (
					<div class="srn-plx-content">
						<img src={plxMarketLeft1} alt=" " className="srn-plx-img plx-market-landing" />
					</div>
				)
			case 'market-product':
				return (
					<div class="srn-plx-content plx-market-product-container">
						<img src={plxMarketProductLeft1} alt=" " className="srn-plx-img plx-market-product1" />
						<img src={plxMarketProductLeft2} alt=" " className="srn-plx-img plx-market-product2" />
						<img src={plxMarketProductLeft3} alt=" " className="srn-plx-img plx-market-product3" />

						<img src={plxMarketProductLeft1} alt=" " className="srn-plx-img plx-market-product4" />
						<img src={plxMarketProductLeft2} alt=" " className="srn-plx-img plx-market-product5" />
						<img src={plxMarketProductLeft3} alt=" " className="srn-plx-img plx-market-product6" />
					</div>
				)
			case 'eats-landing':
				return (
					<div class="srn-plx-content">
						<img src={plxEatsGroup1} alt=" " className="srn-plx-img plx-eats-main plx-eats-main-1" />
						<img src={plxEatsGroup2} alt=" " className="srn-plx-img plx-eats-main plx-eats-main-2" />
						{/* <img src={plxEatsMain} alt=" " className="srn-plx-img plx-eats-main" style={{top: "700px", opacity: ".5"}}/> */}
					</div>
				)
			case 'generic':
				return (
					<div class="srn-plx-content">
						<img src={plxLeft1} alt=" " className="srn-plx-img srn-plx-left srn-plx-left-1" />
						{

						// <img src={MarketSVG} alt=" " className="srn-plx-img srn-plx-left srn-plx-left-logo" />
						}
						<img src={plxLeft2} alt=" " className="srn-plx-img srn-plx-left srn-plx-left-2" />
						<img src={plxRight2} alt=" " className="srn-plx-img srn-plx-right srn-plx-right-2" />
						{
							
						// <img src={EatsSVG} alt=" " className="srn-plx-img srn-plx-right srn-plx-right-logo" />
						}
						<img src={plxRight1} alt=" " className="srn-plx-img srn-plx-right srn-plx-right-1" />
					</div>
				)
			default:
				break;
		}
	}

	render() {
		return (
			<div class="srn-plx-container">
				{
					this.getContent(this.props.page)
				}
			</div>
		)
	}
}


export default ParallaxComponent