import { headerActionTypes, headerActions } from '../actions'
import { HeaderAppSelector } from '../../jslib'
import { actions } from '../../../../../redux/actions'

const triggerSelectApplicationMiddleware = ({ dispatch, getState }) => next => action => {

    if (action.type === headerActionTypes.TRIGGER_SELECT_APPLICATION) {
        document.onreadystatechange = () => {
            if (document.readyState === "complete") {
                getState().appSelector.selectApp(action.payload.appId)
            }
        }
    }

    next(action)
}

const mountHeaderMiddleware = ({ dispatch }) => next => action => {

    if (action.type === headerActionTypes.MOUNT_HEADER) {
        const { containerId, options } = action.payload

        dispatch(headerActions.mountAppSelector({
            options: options,
            containerId: containerId
        }))
    }

    next(action)
}

const mountAppSelector = ({ dispatch }) => next => action => {

    if (action.type === headerActionTypes.MOUNT_APPSELECTOR) {
        const { options, containerId } = action.payload
        action.payload.appSelector = new HeaderAppSelector(options, containerId)
    }

    next(action)
}

const selectApplicationMiddleware = ({ dispatch }) => next => action => {
    

    if (action.type === headerActionTypes.SELECT_APPLICATION) {
        const { appId, redirect } = action.payload

        if (redirect) {
            let redirectUrl
            switch (appId.toString()) {
                case process.env.REACT_APP_MARKET_APP_ID:
                    redirectUrl = 'http://market.central.ph:3001'
                    break
                case process.env.REACT_APP_EATS_APP_ID:
                    redirectUrl = 'http://eats.central.ph:3001'
                    break
                default:
                    redirectUrl = 'http://central.ph:3001'
                    break
            }

            dispatch(actions.redirect({
                to: redirectUrl
            }))
        }
    }

    next(action)
}

export const headerMiddlewares = [
    triggerSelectApplicationMiddleware,
    mountHeaderMiddleware,
    mountAppSelector,
    selectApplicationMiddleware
]