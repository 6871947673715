import $ from 'jquery'
import axios from 'axios'

let CART_SERVICE_URL = process.env.REACT_APP_CART_SERVICE_URL
let SERINO_API = process.env.REACT_APP_SERINO_API
let REACT_APP_LAYER_URL = process.env.REACT_APP_LAYER_URL
let BASIC_TOKEN = process.env.REACT_APP_BASIC_TOKEN
let REACT_APP_PRODCAT = process.env.REACT_APP_PRODCAT
export const getAppToken = () => {
    return BASIC_TOKEN
}

export default class ProductList {

    getCollections(x_app_key) {
        return axios.get(`${SERINO_API}/products/public/pcat-collection-items`, {
            headers: {
                "Authorization": `Basic ${getAppToken()}`,
                "content-type": "application/json",
                "X-APP-KEY": x_app_key,
            }
        })
    }

    getCollectionsByBranch(x_app_key, branch_id) {
        return axios.get(`${SERINO_API}/products/public/pcat-collection-items-by-branch/${branch_id}`, {
            headers: {
                "Authorization": `Basic ${getAppToken()}`,
                "content-type": "application/json",
                "X-APP-KEY": x_app_key,
            }
        })
    }

    getProductsByAutotag(branch_id, collection) {
        return axios.get(`${SERINO_API}/products/public/autoTagging/branch_id/${branch_id}?autotag=${collection}`, {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('access_token'),
                "X-APP-KEY": process.env.REACT_APP_X_APP_KEY,
            }
        })
    }

    getInventoryProduct(product_sku, branch_id, app_id) {
        return axios.get(`${REACT_APP_LAYER_URL}/products/inventory-transactions?perPage=10&product_reference_type=sku&product_reference[]=${product_sku}&application_id=${app_id}&store_id=${branch_id}`, {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('access_token'),
                "X-APP-KEY": process.env.REACT_APP_X_APP_KEY,
            }
        })
    }

    getProductsByBundle(collection_id) {
        return axios.get(`${REACT_APP_PRODCAT}/pcat/collections/${collection_id}`, {
            headers: {
                "Authorization": `Basic ${getAppToken()}`,
                "X-APP-KEY": process.env.REACT_APP_X_APP_KEY,
            }
        })
    }

    getInventoryProducts(product_reference, store_id, ref_type) {
        return axios.get(`${REACT_APP_LAYER_URL}/products/inventory-transactions?perPage=100&product_reference_type=${ref_type ? ref_type : 'sku'}${product_reference}&store_id=${store_id}`, {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('access_token'),
                "X-APP-KEY": process.env.REACT_APP_X_APP_KEY,
            }
        })
    }

    getProductOptionGroupsWithBranch(product_id, branch_id) {
        return axios.get(`${SERINO_API}/public/product/${product_id}/option-groups-with-branch/${branch_id}`)
    }

    getProductOptionGroups(product_id, branch_id) {
        return axios.get(`${SERINO_API}/public/product/${product_id}/option-groups`)
    }

    getProductByTags(tags, app_id) {
        return axios.get(`${SERINO_API}/products/public/product_tags?tags=${tags}`, {
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('access_token'),
                "X-APP-KEY": app_id,
            }
        })
    }
}