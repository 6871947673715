import React, { Component, Fragment } from "react";
import $ from 'jquery'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'

import CODImg from '../../assets/images/cod_gray@2x.png'
import CODcolored from '../../assets/images/cod.svg'
import PaymayaImg from '../../assets/images/paymaya_gray@2x.png'
import CCImg from '../../assets/images/master-card.svg'
import CCcolored from '../../assets/images/master-card-colored.svg'

import debitCard_colored from '../../assets/images/debitCard_colored.png'
import paymaya_colored from '../../assets/images/paymaya_colored.png'
import paymayaBlue_colored from '../../assets/images/Maya_logo 1.png'
import COD_colored from '../../assets/images/Vector.png'

import CentralMainLogo from '../../assets/images/central_logo@2x.png'

import { getAccessToken, getUrl, log, triggerSerinoEvent, getSwalContent, handleExpiredToken, validateAddresses, getMaxOnlinePaymentAmountObject, getBrandsWithDisabledCash, generateYearsArray, getSessionStorage, loadingDisplay, formatMobileNumber } from '../../helper/helper'
import { setAnalyticsSessionToEmpty, createOrderAnalytics, GAPurchase, GAPurchaseOMRS, GACheckoutMOP, GAPlaceOrder } from '../../helper/analytics'
import { fbqEvent } from '../../helper/fbpixel'
import axios from 'axios'

import { faSpinner, faCheck, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uuid } from 'uuidv4';
import LocationPicker from '../partials/LocationPicker'
import jwt_decode from 'jwt-decode'
import LoginApi from '../../_core/login'
import Cart from '../../_core/cart'
import CheckoutAPI from '../../_core/checkout'
import landersLogo from '../../assets/images/landers_membership_logo.png'
import CreditCardSpace from 'credit-card-space';
import CoreAPI from "../../_core/core";
 
const crypto = require('crypto');

let merchant_pk = process.env.REACT_APP_PK
let merchant_sk = process.env.REACT_APP_SK
let paymayaKey = process.env.REACT_APP_PAYMAYAKEY
let x_app_key = process.env.REACT_APP_X_APP_KEY
let clickcnt = 0;
const booking_key = process.env.REACT_APP_BOOKING_KEY
const market_app_key =  process.env.REACT_APP_MARKET_APP_ID
const eats_app_key =  process.env.REACT_APP_EATS_APP_ID
let apps_counter = 0
let mountcounter = 0
let loadCount = 0
var login = new LoginApi()
var cart = new Cart()
var checkoutAPI = new CheckoutAPI()
const coreAPI = new CoreAPI();

export default class Checkout extends Component {
    constructor() {
        super()
        this.state = {
            appKey: '',
            paymentMethod: 0,
            isLoading: false,
            cartsBelowMinimum: [],
            cart_items: [],
            personal_details: {},
            apps: [],
            isOrderLoading: false,
            tracking_number: '',
            modeOfPayment: "Cash",
            currency: 'PHP',
            order_items: [],
            grandTotal: '',
            totalSavings: 0,
            memberSavings: 0,
            objFromUrl: {
                s: '',
                b: '',
                oid: '',
                tn: ''
            },
            error: [],
            changeFor: 0,
            current_brand_id: '',
            failed_orders: [],
            riderChange: '',
            isAgreementChecked: false,
            client: {},
            /** Make sure the value from session storage is precisely 'true'. Without the === operator, it will always return true if the value exists */
            isCashEnabled: sessionStorage.getItem('isCashEnabled') === 'true' ? true : false,
            isOnlinePaymentEnabled: true,
            isMember: localStorage.getItem('isMember') == null ? 'false' : localStorage.getItem('isMember'),

            // payment form
            cvc: "",
            expMonth: "",
            expYear: "",
            name: "",
            number: "",

            // payment form error states
            cvcError: "",
            expMonthError: "",
            expYearError: "",
            nameError: "",
            numberError: "",

            months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            // isDataLoaded: false,
            appsCount: 0,
            x_app_key: market_app_key,
            returnedProducts: [],
            isScrolled: false,
            isCheckoutLoading: false,
            isOMRSEnabled: process.env.REACT_APP_OMRS_STATUS == 1 ? true : false,
            paymentmode: "",
            isCartLoading: false,
        }
        this.topContainer = React.createRef();
    }


    async componentDidMount() {

        
        window.addEventListener('tp-event-submit', async () => {
            await this.createOrder()
        })

        
        window.addEventListener('tp-event-success', async () => {
            const orderResponse = await JSON.parse(localStorage.getItem("created_order_details"))
            const order_info = orderResponse.data.data
            await GAPurchaseOMRS(order_info, this.state.grandTotal, this.state.apps)
        })
        
        setTimeout(() => {
            const el = document.getElementById('root')
            el.scrollIntoView()
        }, 500)

        this.setState({
            isCartLoading: true
        })

        setTimeout(() => {
            this.setState({isScrolled: true})
        }, 800)
        
        let locationPicker = document.getElementById('srn-header-location-picker')
        let locationPicker2 = document.getElementById('srn-header-location-picker-2')

        if (locationPicker) {
            locationPicker.style.display = 'none'
        }
        if (locationPicker2) {
            locationPicker2.style.display = 'none'
        }

        triggerSerinoEvent('srn-on-path-change', '/checkout')
        mountcounter++
        // if (!document.getElementById('paymaya')) {
        //     var elem = document.createElement('div');
        //     elem.id = 'paymaya'
        //     elem.style.cssText = 'height: 500px;';
        //     document.body.appendChild(elem);
        // }

        $('#srn-min-modal').on('hidden.bs.modal', (e) => {
            window.location = '/cart'
        })
        $('#srn-max-modal').on('hidden.bs.modal', (e) => {
            window.location = '/cart'
        })

        // Temporary fix for Paymaya not working
        // localStorage.removeItem('isLoggedIn')


        await this.resetFulfillmentDates()

        let access_token = await getAccessToken()

        let cartSummarySubscriptions = window.serino_components.cartSummary.subscriptions
        let cartTotalSubscriptions = window.serino_components.cartTotal.subscriptions
        let cartTotalEvents = window.serino_components.cartTotal.events
        let cartEvents = window.serino_components.cart.events
        let crm = window.serino_components;
        let personal_details_crm_events = crm.PersonalDetailsComponent.events;
        // log('personal_details_crm_events', personal_details_crm_events.onFieldChange)
        document.addEventListener(personal_details_crm_events.onFieldChange, (e) => {
            log('onfieldchange called', e.detail)
            this.setState({
                personal_details: e.detail
            }, () => {
                log('after called', this.state.personal_details)
                let stringify = JSON.stringify(e.detail)
                localStorage.setItem('SRN-CHECKOUT-PERSONAL-DETAILS', stringify)
            })
        })

        // create component
        
        let srn_register_component = crm.PersonalDetailsComponent.create({
            access_token: localStorage.getItem('access_token')
        }, "srn-crm-personal-details-container");

        if (localStorage.getItem('client_details')) {
            let client_details = JSON.parse(localStorage.getItem('client_details'))
            log({client_details})
            this.setState({
              client: {
                  first_name: client_details.first_name,
                  last_name: client_details.last_name,
                  email: client_details.email,
                  phone_number: client_details.phone_number,
                  metas: client_details.metas
              }
          }, () => {
            log('client details', this.state.client)
            triggerSerinoEvent(window.serino_components.PersonalDetailsComponent.subscriptions.populateFields, this.state.client)
          })
        }

        if (localStorage.getItem('isLoggedIn') &&localStorage.getItem('isLoggedIn') === 'true') {
            login.getClientDetails(access_token, x_app_key)
            .then((res) => {
                this.setState({
                  client: {
                      first_name: res.data.firstName,
                      last_name: res.data.lastName,
                      email: res.data.email,
                      phone_number: res.data.contactNumbers.find(x => x.isPrimary),
                      metas: res.data.metas
                  }
              }, () => {
                log('contact details field', this.state.client)
                triggerSerinoEvent(window.serino_components.PersonalDetailsComponent.subscriptions.populateFields, this.state.client)
              })
            }).catch(err => log(err))
        }

        let apps = JSON.parse(localStorage.getItem('SRN-CURRENT-APPS'))

        let market_cart = null
        let eats_cart = null

        if (apps && apps.length > 0) {
            let market_app = apps.find(x => x.app.id == process.env.REACT_APP_MARKET_APP_ID)
            if (market_app) {
                market_cart = market_app.cart[0]
            }
            let eats_app = apps.find(x => x.app.id == process.env.REACT_APP_EATS_APP_ID)
            if (eats_app) {
                eats_cart = eats_app.cart[0]
            }
        }

        await validateAddresses(market_app_key)
        await validateAddresses(eats_app_key)

        let marketCart = window.serino_components.cart.create({
            access_token: access_token,
            app_key: market_app_key,
            id: 'marketCart',
            fulfillmentType: 1,
            mode: 'view',                
            booking_key: booking_key,
            address: localStorage.getItem(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`),
            timeSlotInterval: 60,
            isFulfillmentOptionDisabled: true,
            cart: market_cart,
            isOMRSEnabled: this.state.isOMRSEnabled
        }, 'srn-cart-market-container')

        let eatsCart = window.serino_components.cart.create({
            access_token: access_token,
            app_key: eats_app_key,
            id: 'eatsCart',
            fulfillmentType: 1,
            mode: 'view',                
            booking_key: booking_key,
            address: localStorage.getItem(`address_object_${process.env.REACT_APP_EATS_APP_ID}`),
            timeSlotInterval: 30,
            showBrandName: true,
            showBrand: true,
            cart: eats_cart,
            isOMRSEnabled: this.state.isOMRSEnabled
        }, 'srn-cart-eats-container')

        let marketSummaryComponent = window.serino_components.cartSummary.create({
            id: 'marketSummary',
            // isPromoOn: true,
            // mode: 'edit'
        }, 'srn-market-summary-container')

        let eatsSummaryComponent = window.serino_components.cartSummary.create({
            id: 'eatsSummary',
            // isPromoOn: true,
            // mode: 'edit'
        }, 'srn-eats-summary-container')

        let cartTotalComponent = window.serino_components.cartTotal.create({
            access_token: access_token,
            id: 'cartTotal',
            logo: <img src={landersLogo} className="srn-landers-logo" />
        }, 'srn-cart-total-container')

        // let cartTotalComponent2 = window.serino_components.cartTotal.create({
        //     access_token: access_token,
        //     id: 'cartTotal',
        //     logo: <img src={landersLogo} className="srn-landers-logo" />
        // }, 'srn-cart-total-container2')


        document.addEventListener(cartTotalEvents.onCartBelowMinimum('cartTotal'), (event) =>  {

            // let cartEvents = window.serino_components.cart.subscriptions

            // const triggerBelowMinimumEvent = (eventId, detail) => {
            //     let event = new CustomEvent(cartEvents.minMaxError(eventId), {
            //         detail: detail,
            //         bubbles: true,
            //         composed: true
            //     })
            //     document.dispatchEvent(event);
            // }

            // switch(event.detail.app) {
            //     case(1):
            //         triggerBelowMinimumEvent('marketCart', event.detail)
            //         break;
            //     case(2):
            //         triggerBelowMinimumEvent('eatsCart', event.detail)
            //         break;
            //     default:
            //         break;
            // }

            let cartsBelowMinimum = event.detail.carts
            // log('cartsBelowMinimum', cartsBelowMinimum)
            this.setState({cartsBelowMinimum})

            $('#srn-min-modal').modal('show')
        })

        document.addEventListener(cartTotalEvents.onCartAboveMaximum('cartTotal'), (event) =>  {
            this.setState({
                cartsAboveMaximum: event.detail.carts
            })
            $('#srn-max-modal').modal('show')
        })

        document.addEventListener(cartTotalEvents.onGetGrandTotal('cartTotal'), (event) =>  {
            this.setState({grandTotal: event.detail}, () => {
                this.getFulfillmentDetails()
            })
        })

        // Get total savings
        document.addEventListener(cartTotalEvents.onGetTotalSavings('cartTotal'), (event) =>  {
            this.setState({totalSavings: parseFloat(event.detail).toFixed(2)})
        })

        // Get member's savings
        document.addEventListener(cartTotalEvents.onGetMemberSavings('cartTotal'), (event) =>  {
            this.setState({memberSavings: parseFloat(event.detail).toFixed(2)})
        })

        document.addEventListener(cartEvents.onLoad('marketCart'), (event) => {
            // log('on load called')
            this.updateSummaryItems(event, 'marketSummary', 'srn-market-summary-container')
            triggerSerinoEvent(cartTotalSubscriptions.updateCartItems('cartTotal'), this.state.apps)
            triggerSerinoEvent(cartTotalSubscriptions.onCheckout('cartTotal'), this.state.apps)
        })

        document.addEventListener(cartEvents.onLoad('eatsCart'), (event) =>  {
            // log('on load called')
            this.updateSummaryItems(event, 'eatsSummary', 'srn-eats-summary-container')
            triggerSerinoEvent(cartTotalSubscriptions.updateCartItems('cartTotal'), this.state.apps)
            triggerSerinoEvent(cartTotalSubscriptions.onCheckout('cartTotal'), this.state.apps)
        })

        document.addEventListener(cartEvents.onTokenExpire('marketCart'), (event) =>  {
            handleExpiredToken()
        })

        window.addEventListener('tp-event-token-expired', async () => {
            await handleExpiredToken()
        })


        // Handle Change Address
        document.addEventListener(cartEvents.onChangeAddress('eatsCart'), async (event) =>  {                
            let clearCartConfirmation = await Swal.fire({
                html: getSwalContent('Your cart will be reset if you switch to a different fulfillment type. Continue?'),
                confirmButtonText: 'YES',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'                        }
            })

            if (clearCartConfirmation.isDismissed) {
                return
            }
            // this.clearCart(localStorage.getItem('access_token'), eats_app_id)
            // console.log('onChangeAddress', event.detail.brand.id)
            localStorage.setItem('brand_id', event.detail.brand.id)
            localStorage.setItem('branch_id', event.detail.brand.branch_id)
            this.setState({current_brand_id: event.detail.brand.id})
            this.setState({ x_app_key: eats_app_key}, () => {
                $('#fulfillmenttype_modal').modal('show')
            })
        })

        document.addEventListener(cartEvents.onChangeAddress('marketCart'), async (event) =>  {                
            let clearCartConfirmation = await Swal.fire({
                html: getSwalContent('Your cart will be reset if you switch to a different fulfillment type. Continue?'),
                confirmButtonText: 'YES',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'                        }
            })

            if (clearCartConfirmation.isDismissed) {
                return
            }

            // this.clearCart(localStorage.getItem('access_token'), market_app_id)
            // console.log('onChangeAddress', event.detail.brand.id)
            localStorage.setItem('brand_id', event.detail.brand.id)
            localStorage.setItem('branch_id', event.detail.brand.branch_id)
            this.setState({ current_brand_id: event.detail.brand.id})
            this.setState({ x_app_key: market_app_key}, () => {
                $('#fulfillmenttype_modal').modal('show')
                // console.log("onchangeaddress",this.state.x_app_key)
            })
        })
        // listen to crm discount product description component events
        
        document.addEventListener(personal_details_crm_events.change_address, (e) => {
            // do something on change address..
            alert(personal_details_crm_events.change_address)
        })

        document.addEventListener(cartEvents.onFulfillmentUpdate('marketCart'), (event) => {
            // log('onFulfillmentUpdate', event.detail)
            this.handleFulfillmentUpdate(event.detail, market_app_key)
        })

        document.addEventListener(cartEvents.onFulfillmentUpdate('eatsCart'), (event) => {
            log('fulfillment test')
            this.handleFulfillmentUpdate(event.detail, eats_app_key)
        })

        // document.addEventListener(cartEvents.onDateUpdate('marketCart'), (event) => {
        //     this.updateSelectedDates(event.detail)
        // })

        // document.addEventListener(cartEvents.onDateUpdate('eatsCart'), (event) => {
        //     this.updateSelectedDates(event.detail)
        // })
        
        if (localStorage.getItem('Address')) {
            // log(window.serino_components.PersonalDetailsComponent.subscriptions.onAddressChange)
            triggerSerinoEvent(window.serino_components.PersonalDetailsComponent.subscriptions.onAddressChange, localStorage.getItem('Address'))
        }
        
        // this.initializePaymaya()

        const input = document.querySelector('#number');
        const ccSpace = new CreditCardSpace(input);
         
        const cardNumber = ccSpace.value();

        // document.addEventListener(cartEvents.onDeliveryDatesLoaded('eatsCart'), (event) => {
        //     let appCount = sessionStorage.getItem('appCount')

        //     log('this.state.appsCount', this.state.appsCount)

        //     if (appCount === '2' && this.state.appsCount === 2) {
        //         this.setState({
        //             isDataLoaded: true
        //         })
        //     }
        // })
        // document.addEventListener(cartEvents.onDeliveryDatesLoaded('marketCart'), (event) => {
        //     let appCount = sessionStorage.getItem('appCount')

        //     log('this.state.appsCount', this.state.appsCount)

        //     if (appCount === '2' && this.state.appsCount === 2) {
        //         this.setState({
        //             isDataLoaded: true
        //         })
        //     }
        // })
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    resetFulfillmentDates = () => {
        let address_object_1 = localStorage.getItem(`address_object_${market_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_key}`)) : null
        let address_object_2 = localStorage.getItem(`address_object_${eats_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${eats_app_key}`)) : null
        // log('address_object_1 before', address_object_1)
        // log('address_object_2 before', address_object_2)
        if (address_object_1 != null) {
            address_object_1.forEach(address => {
                delete address.deliverLaterDateTime
                delete address.pickupDateTime
                delete address.booking_duration_from
                delete address.booking_duration_to
                delete address.booking_resource_code
                delete address.selected_datetime
            })
            // log('address_object_1 after', address_object_1)
            localStorage.setItem(`address_object_${market_app_key}`, JSON.stringify(address_object_1))
        }
        if (address_object_2 != null) {
            address_object_2.forEach(address => {
                delete address.deliverLaterDateTime
                delete address.pickupDateTime
                delete address.booking_duration_from
                delete address.booking_duration_to
                delete address.booking_resource_code
                delete address.selected_datetime
            })
            // log('address_object_2 after', address_object_2)
            localStorage.setItem(`address_object_${eats_app_key}`, JSON.stringify(address_object_2))
        }

    }

    handleFulfillmentUpdate = (details, app_key) => {

        let address_object = JSON.parse(localStorage.getItem(`address_object_${app_key}`))

        if (details.branch_id.toString().length > 0 && address_object.filter(e => e.branch_id == details.branch_id).length > 0) {
            let i = address_object.findIndex(e => e.branch_id == details.branch_id)

            if (details.fulfillment_type == 1) {
                address_object[i].fulfillmentType_status = 1
                address_object[i].fulfillmentType = 'Delivery'
                address_object[i].note = details.note
                localStorage.setItem(`address_object_${app_key}`, JSON.stringify(address_object))
            }

            var selected_datetime = details.selected_datetime.split(' ').join('')

            if (details.fulfillment_type == 2) {
                address_object[i].pickupDateTime = details.selected_datetime
                address_object[i].booking_resource_code = details.booking_resource_code
                address_object[i].booking_duration_from = details.selected_datetime
                address_object[i].booking_duration_to = details.selected_datetime
                address_object[i].selected_datetime = details.selected_datetime
                address_object[i].note = details.note

                if (selected_datetime === '') {
                    address_object[i].pickupDateTime = ''
                    address_object[i].booking_duration_from = ''
                    address_object[i].booking_duration_to = ''
                    address_object[i].selected_datetime = ''
                }

                // log('new address object', address_object)

                localStorage.setItem(`address_object_${app_key}`, JSON.stringify(address_object))
            }
            if (details.fulfillment_type == 3) {
                address_object[i].deliverLaterDateTime = details.deliverLaterDateTime
                address_object[i].booking_resource_code = details.booking_resource_code
                address_object[i].booking_duration_from = details.booking_duration_from
                address_object[i].pickupDateTime = details.deliverLaterDateTime
                address_object[i].booking_duration_to = details.booking_duration_to
                address_object[i].note = details.note                        
                address_object[i].fulfillmentType_status = 3
                address_object[i].fulfillmentType = 'Deliver Later'
                address_object[i].selected_datetime = details.selected_datetime

                if (selected_datetime === '') {
                    address_object[i].pickupDateTime = ''
                    address_object[i].booking_duration_from = ''
                    address_object[i].booking_duration_to = ''
                    address_object[i].selected_datetime = ''
                }

                // log('new address object', address_object)

                localStorage.setItem(`address_object_${app_key}`, JSON.stringify(address_object))
            }
        }
    }

    updateSelectedDates = (app_id, date) => {

    }

    initializePaymaya = () => {

        var paymaya = window.paymaya

        // PAYMAYA

        log('PayMaya')

        let source = document.getElementById('paymaya')
        
        log('source', source)
        if (source) {
            let destination = document.getElementById('cmb-payment-form')
            destination.appendChild(source);
            log('destination', source)
        }

        const paymentFormResponse = (response) => {
            if (response.data.id === "handler") {
                if (response.data.success) {
                    var resp = JSON.parse(response.data.payload.resp);
                    let token = resp.paymentTokenId;

                    if (this.state.isLoading) {
                        return
                    }
                    // if (clickcnt < 1) {
                    //     clickcnt = 1;
                        this.setState({
                            guestPaymentToken: token,
                            isLoading: true
                        })

                        let isFormValid = this.validateForm()
                        
                        if (isFormValid) {
                            this.createOrder("cdc");                            
                        }
                        else {
                            window.location.hash = "section-personal"
                            this.setState({ isLoading: false })
                        }
                    // } else {

                    // }
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: "Please try again!",
                        icon: "error"
                    });
                }
            }
        }

        var options = {};
        options.pk = paymayaKey;
        options.handler = paymentFormResponse;
        options.source = "cdn.paymaya.com Source";
        options.style = {
            '#name': {
                "name": "ksjdkljaskldj",
                "display": "block",
                "width": "100%",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "maxWidth": "100%",
                "font-family": "'gotham-regular', sans-serif",
            },
            '#creditCard': {
                "display": "block",
                "width": "100%",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "maxWidth": "100%",
                "font-family": "'gotham-regular', sans-serif",
            },
            '#expMonth': {
                "width": "100px",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid #ced4da",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "font-family": "'gotham-regular', sans-serif",
            },
            '#expYear': {
                "width": "100px",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "font-family": "'gotham-regular', sans-serif"
            },
            '#cvv': {
                "display": "block",
                "width": "100px",
                "height": "calc(1.5em + .75rem + 2px)",
                "padding": ".375rem .75rem",
                "font-size": "16px",
                "font-weight": "400",
                "line-height": "1.5",
                "color": "#495057",
                "background-color": "#fff",
                "background-clip": "padding-box",
                "border": "1px solid white",
                "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "border-radius": "0",
                "height": "40px",
                "line-height": "40px",
                "font-family": "'gotham-regular', sans-serif",
            },
            'label': {
                "font-size": "13px",
                "font-family": "'gotham-regular', sans-serif"
            },
            'button': {
                "font-family": "'gotham-regular', sans-serif",
                "font-weight": "bold",
                "font-size": "14px",
                "box-sizing": "border-box",
                "margin-left": "20px",
                "margin-right": "20px",
                "width": "calc(100% - 40px)",
                "display": "inline-block",
                "border": "none",
                "border-radius": "0",
                "background-color": "#205818!important",       
                //"background-image": "-webkit-linear-gradient(left, #131216, #18181b, #1d1d1f, #232224, #282829, #282829, #282829, #282829, #232224, #1d1d1f, #18181b, #131216)",
                //"background-image": "-o-linear-gradient(left, #131216, #18181b, #1d1d1f, #232224, #282829, #282829, #282829, #282829, #232224, #1d1d1f, #18181b, #131216)",
                //"background-image": "linear-gradient(to right, #131216, #18181b, #1d1d1f, #232224, #282829, #282829, #282829, #282829, #232224, #1d1d1f, #18181b, #131216)",
                "color": "white",
                "height": "44px",
                "line-height": "44px",
                "text-transform": "uppercase",
            },            
            'iframe': {
                "width": "calc(100% - 40px)",
                "min-width": "calc(100% - 40px)",
                "margin-left": "20px",
                "margin-right": "20px"
            }
        }
        paymaya.createForm(options);
    }
    
    clearCart = (app_key, counter) => { 
        let api_url = process.env.REACT_APP_CART_SERVICE_URL

        let current_app = this.state.apps.find(x => x.app.id == app_key)

        let cart_items = []

        current_app.cart.forEach(curr => {
            curr.cart_items.map(cart_item => {
                log(cart_item)
                log(cart_item.id)
                cart_items.push(cart_item.id)
            })
        })

        log('cart_items', cart_items)

        axios.delete(`${api_url}/cart`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                "content-type": "application/json",
                "X-APP-KEY": app_key,
            },
            data: {
                ids: cart_items
            }
        }).then(res => {
            log(counter)
            localStorage.setItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID', '')
            if (counter == this.state.apps.length) {
                this.onCheckoutSuccess(app_key)

            }
        })
        .finally(() => {
            // this.setOrderLoading(false)
        })
    }

    onCheckoutSuccess = async (app_key) => {

        localStorage.removeItem('SRN-CHECKOUT-PERSONAL-DETAILS')
        localStorage.removeItem('SRN-CART-ID')
        localStorage.removeItem('newReturnedProducts')
        localStorage.setItem('market_cart_count', 0)
        localStorage.setItem('eats_cart_count', 0)

        if (this.state.isOMRSEnabled) {
            this.setOrderLoading(false)
            this.showModal()
            return;
        }

        let api_url = process.env.REACT_APP_SERINO_API
        let api = '/orders/create_order_email'
        let payload = {
            "group_id": localStorage.getItem('group-id-generated-on-bulkcreateorder-return')
        }

        let createOrderEmailResult = await axios.post(`${api_url}${api}`, payload, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                "content-type": "application/json",
                "X-APP-KEY": app_key,
            }
        })

        this.setState({
            isCheckoutLoading: false,
            isCartLoading: false,
        })

        this.setOrderLoading(false)

        // let apps = this.state.apps
        // let decoded_token = jwt_decode(localStorage.getItem('access_token'))

        // apps.forEach(app => {
        //     let carts = []
        //     apps.forEach((app) => app.cart.map(cart_item => carts.push(cart_item)))
        //     let promos = []
        //     carts.forEach(cart => cart.promos.map(promo => promos.push(promo.code)))
        //     log('PROMOOOOS', promos)
        //     promos.forEach(async(promo_code) => {
        //         let deleteAdjustmentAPI = `/orders/adjustment/${decoded_token.id}/${promo_code}`
        //         let deleteAdjustmentResult = await axios.delete(`${api_url}${deleteAdjustmentAPI}`, {
        //             headers: {
        //                 "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        //                 "content-type": "application/json",
        //                 "X-APP-KEY": app.app.id,
        //             }
        //         })
        //     })

        // })

        this.showModal()
    }

    handlePaymentMethodChange = async (method) => {
        this.setState({
            isOrderLoading: true,
            
        })

        await this.setState({
            paymentMethod: method,
            paymentmode: method == 1 ? "cdc" : method == 2 ? "pwp" : ""
        })
        // if (method  == 1) {
        //     this.initializePaymaya()
        // }
        let market_branches = []
        let market_unavailable_items = []
        let eats_branches = []
        let eats_unavailable_items = []
        this.state.apps.forEach((app) => {
            app.cart.forEach(cart => {
                log(app.id)
                if (app.app.id == market_app_key) {
                    market_branches.push(cart.brand.branch_id)
                }
                if (app.app.id == eats_app_key) {
                    eats_branches.push(cart.brand.branch_id)
                }
            })
        })

        GACheckoutMOP(this.state.apps, method == 1 ? "cdc" : method == 2 ? "pwp" : "cash")

        // log('eats_branches', eats_branches)
        // log('market_branches', market_branches)

        for (const branch of eats_branches) {
            let unavailable_items_per_branch = await cart.getUnavailableProducts(localStorage.getItem('access_token'), eats_app_key, branch)
            eats_unavailable_items.push.apply(eats_unavailable_items, unavailable_items_per_branch.data.items)
            // log('unavailable_items_per_branch', unavailable_items_per_branch)
        }
        for (const branch of market_branches) {
            let unavailable_items_per_branch = await cart.getUnavailableProducts(localStorage.getItem('access_token'), market_app_key, branch)
            market_unavailable_items.push.apply(market_unavailable_items, unavailable_items_per_branch.data.items)
            // log('unavailable_items_per_branch', unavailable_items_per_branch)
        }

        if ((eats_unavailable_items && eats_unavailable_items.length > 0) || (market_unavailable_items && market_unavailable_items.length > 0)) {
            await Swal.fire({
                html: getSwalContent('You have unavailable or sold out product/s in your cart. If you continue, the sold out product/s will be removed from your cart'),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            })

            if (eats_unavailable_items && eats_unavailable_items.length > 0) {

                let payload = {
                    "ids": eats_unavailable_items.map(item => item.id)
                }

                await cart.deleteUnavailableProducts(localStorage.getItem('access_token'), eats_app_key, payload)
                triggerSerinoEvent(window.serino_components.cart.subscriptions.refreshCart('eatsCart'))
            }
            if (market_unavailable_items && market_unavailable_items.length > 0) {

                let payload = {
                    "ids": market_unavailable_items.map(item => item.id)
                }
                
                await cart.deleteUnavailableProducts(localStorage.getItem('access_token'), market_app_key, payload)
                triggerSerinoEvent(window.serino_components.cart.subscriptions.refreshCart('marketCart'))
            }


        }

        this.setState({
            isOrderLoading: false
        })

    }

    showModal = () => {
        // log(getUrl('main') + '/order-success?s=s&oid=' + this.state.group_id + '&tn=' + this.state.tracking_number)
        window.location = getUrl('main') + '/order-success?s=s&oid=' + this.state.group_id + '&tn=' + this.state.tracking_number
        // $('.success-modal').modal('show')
        // if (process.env.REACT_APP_ENV === 'prod') {
        //     this.datalayerPush()
        // }
        // $('.success-modal').on('hide.bs.modal', function(){
        //     window.location =  getUrl('main')
        // })
    }

    handleFieldChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    
    updateSummaryItems = (event, summaryId, cartSummaryId) => {
        // log('updateSummaryItems', event.detail)
        apps_counter++

        let cartSummarySubscriptions = window.serino_components.cartSummary.subscriptions

        let app = {
            cart: event.detail.cart,
            app: event.detail.app
        }

        // console.log(app)

        let apps = this.state.apps

        if (apps.filter(e => e.app.id == app.app.id).length > 0) {
            let appIndex = apps.findIndex(e => e.app.id == app.app.id)
            if (apps[appIndex].cart.length > 0) {
                apps[appIndex] = app
            }
            else {
                apps.splice(appIndex)
            }
        }
        else {
            if (app.cart && app.cart.length > 0) {
                apps.push(app)            
            }
        }
        this.setState({apps, appsCount: apps_counter})

        // if (apps_counter == 2) {
            
        //     log('got in')
            
        //     let updatedCartItems = []
            
        //     apps.map(app => {
        //         app.cart?.map(x => updatedCartItems.push(x))
        //     })
            
        //     log({updatedCartItems})

        //     if (updatedCartItems.length == 0) {
        //         alert('empty cart')
        //     }

        //     apps_counter = 0
        // }

        localStorage.setItem('SRN-CURRENT-APPS', JSON.stringify(apps))
        // this.updateCartCount(apps)

        let objFromUrl;
        let search;

        if (this.props.location.search.length > 0) {
            search = this.props.location.search.substring(1);
            objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            this.setState({ objFromUrl, tracking_number: objFromUrl.tn })
            if (objFromUrl.s.length > 0 && objFromUrl.s === 's') {
                this.datalayer();
                log('success!')
                log('apps', this.state.apps)
                log('apps counter', apps_counter)
                this.setState({
                    isCheckoutLoading: true
                })
                if (apps_counter == 2) {                    
                    let clear_cart_counter = 0
                    apps.forEach(app => {
                        clear_cart_counter++
                        log('success coutner!', clear_cart_counter)
                        this.clearCart(app.app.id, clear_cart_counter)
                    })

                    if (apps.length === 0) {
                        log('apps length', apps.length.toString())
                        this.showModal()
                    }
                }
            }
            if (objFromUrl.s.length > 0 && objFromUrl.s === 'f') {
                Swal.fire({
                    confirmButtonText: 'TRY AGAIN',
                    showCancelButton: true,
                    cancelButtonText: 'CANCEL',
                    html: `<div class='col-md-12'>
                        <h3 class='cmb-title-2 my-3 text-center'>Failed</h3>
                        <h6 class='cmb-title-3 my-3 text-center text-secondary'>Order Failed</h6>
                        </div>`,
                    customClass: {
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                        cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                    }
                }).then(() => {
                    setAnalyticsSessionToEmpty()                    
                })

            }
        }

        triggerSerinoEvent(cartSummarySubscriptions.updateCartItems(summaryId), app)
        let cartTotalSubscriptions = window.serino_components.cartTotal.subscriptions        

        // this.getFulfillmentDetails()

    }

    setOrderLoading = (bool) => {
        this.setState({isOrderLoading: bool, isLoading: bool}, () => {
            // log(this.state.isOrderLoading)
        })
    }

    setCartItems = (cart) => {        
        let items = []
        cart.cart_items.map(cart_item => {
            let custom_data_string = cart_item.custom_data
            let custom_data = []
            let reference_1 = []
            if (custom_data_string && custom_data_string.length > 0) {
                custom_data = JSON.parse(custom_data_string)
            }
            if (cart_item.parent_id != null) {
                const parent = cart.cart_items.find(x => x.id == cart_item.parent_id)
                let freebie_obj = {
                    name: 'freebie_status',
                    parent_sku: parent.itemSKU,
                    value: 1
                }
                custom_data.push(freebie_obj)

                if (this.state.isOMRSEnabled) {
                    reference_1.push(freebie_obj)
                }
            }
            let item = {
                "itemType": cart_item.item_type == 1 ? 'weighted_product' : cart_item.itemReferenceType,
                "itemRefId": cart_item.itemReferenceId,
                "itemRefName": cart_item.itemName,
                "itemRefDesc": cart_item.itemDescription,
                "itemRefImg": cart_item.itemImage,
                "itemSku": cart_item.itemSKU,
                "qty": cart_item.item_type == 1 ? parseFloat(cart_item.unit_quantity) : parseFloat(cart_item.quantity),
                "price": cart_item.price,
                "selected_options": cart_item.optionIds,
                "item_custom": cart_item.optionText,
                "item_instruction": cart_item.instructions,
                "custom_data": cart_item.custom_data
                // "unit_quantity": cart_item.unit_quantity
            }

            if (this.state.isOMRSEnabled && cart_item.item_type == 1) {
                item.weight = custom_data.find(x => x.name === 'weight') ? parseFloat(custom_data.find(x => x.name === 'weight').value) : null
                let price_per_unit = (parseFloat(cart_item.price) / item.weight).toFixed(2)
                item.weight_price_per_unit = parseFloat(price_per_unit)
            }
            if (this.state.isOMRSEnabled) {
                let barcode = custom_data.find(x => x.name === 'barcode')?.value || ""
                item.barcode = barcode
                if (reference_1 && reference_1.length > 0) {
                    item.reference_1 = JSON.stringify(reference_1)
                }
                item.options = cart_item.options
            }

            if (custom_data.length > 0) {
                item.custom_data = JSON.stringify(custom_data)
            }

            items.push(item)
        })
        log({items})
        return items
    }

    createPayload = async (carts, group_id, app_id, modeOfPayment) => {
        let payload = []
        let user = this.state.personal_details
        let access_token = localStorage.getItem('access_token')
        let user_id = localStorage.getItem('SRN-CART-ID')
        let addresses = []

        if (localStorage.getItem(`address_object_${app_id}`) && localStorage.getItem(`address_object_${app_id}`).length > 0 && JSON.parse(localStorage.getItem(`address_object_${app_id}`)) && JSON.parse(localStorage.getItem(`address_object_${app_id}`)).length > 0) {
            addresses = JSON.parse(localStorage.getItem(`address_object_${app_id}`))
        }

        let failed_payload = []

        for (const cart of carts) {

            // log('cart', cart)
            let address = {}

            if (addresses.length > 0) {
                address = addresses.find(x => x.brand_id == cart.brand.id)
            }
            else {
                return false
            }

            let shippingAddress = address && address.address ? `${address.house_no ? address.house_no + ', ' : ''}${address.floor ? address.floor + ', ' : ''}${address.barangay ? address.barangay + ', ' : ''}${address.city ? address.city + ', ' : ''}${address.province ? address.province + ', ' : ''}Philippines` : ''

            log({address})
            log({shippingAddress})

            let customer_address_details = {
                address: address.address,
                province: address.province,
                city: address.city,
                barangay: address.barangay,
                floor: address.floor,
                house_no: address.house_no,
                landmark: address.landmark,
            }

            // log('address', address)
            let cart_items = this.setCartItems(cart)

            let payload_item = {
                "group_id": group_id,
                "shippingType": "",
                "notes": {
                    "title": `Note to ${address && address.fulfillmentType_status == 2 ? 'Store' : 'Rider'}`,
                    "note": address && address.note ? address.note : '',
                    "status": "",
                    "type": "",
                    "commentatorName": `${user.first_name} ${user.last_name}`
                },
                "firstName": user.first_name,
                "lastName": user.last_name,
                "email": user.email_address,
                "contactNumber": '+63' + user.phone_number,
                "billingAddress": "",
                "description": "",
                "items": cart_items,
                "storeId": cart.brand.branch_id,
                "trackingNumber": "",
                "type": "Products",
                "latitude": address && address.lat ? address.lat : '',
                "longitude": address && address.lng ? address.lng : '',
                "paymentStatus": "Pending",
                "modeOfPayment": "Cash",
                "cart_id": user_id,
                "pickupDatetime": '',
                "fulfillmentType": address && address.fulfillmentType ? address.fulfillmentType : 'Delivery',
                "shippingAddress": shippingAddress,
                "landmark": customer_address_details.landmark,
                "pinned_address": address.address,
                "user_input_address": shippingAddress,
                "changeFor": "",
                "booking_resource_code": "",
                "booking_duration_from": "",
                "booking_duration_to": "",
                "option_id": localStorage.getItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID') ? localStorage.getItem('SRN-CART-V2-SELECTED-ORDER-OPTION-ID') : '',
                "promo_transaction_id": cart.promo_transaction_id ? cart.promo_transaction_id : [],
                "customer_address_details": JSON.stringify(customer_address_details)
            }

            if (address && address.fulfillmentType_status && address.fulfillmentType_status != 1) {

                if (this.state.isOMRSEnabled) {
                    payload_item.schedule = address.selected_datetime
                }

                if (address && address.booking_duration_from && address.booking_duration_from.length > 0) {
                    payload_item.booking_resource_code = address.booking_resource_code
                    payload_item.booking_duration_from = address.booking_duration_from
                    payload_item.booking_duration_to = address.booking_duration_to
                    payload_item.pickupDatetime = address.pickupDateTime

                    let brand_name = ''
                    if (app_id == market_app_key) {
                        brand_name = 'Central Market'
                    }
                    else {
                        brand_name = cart.brand.name
                    }

                    if (address && address.fulfillmentType_status && address.fulfillmentType_status == 3) {
                        try {
                            let validateTimeSlotsResult = await this.validateTimeSlots(address, cart.cart_items, app_id)

                            log('validateTimeSlotsResult', validateTimeSlotsResult.data.is_available)

                            if (!validateTimeSlotsResult.data.is_available) {
                                payload_item.brand_name = brand_name
                                payload_item.isFailed = true
                                payload_item.fail_type = 'invalid_timeslot'
                                failed_payload.push(payload_item)
                            }

                            // validate with the updated time
                            let isSelectedTimeSlotValid = await this.validateUpdatedTimeSlot(address)

                            if (!isSelectedTimeSlotValid) {
                                payload_item.brand_name = brand_name
                                payload_item.isFailed = true
                                payload_item.fail_type = 'invalid_timeslot'
                                failed_payload.push(payload_item)
                            }

                            log({isSelectedTimeSlotValid})
                        }
                        catch(validateTimeSlotsError) {
                            log(validateTimeSlotsError)
                            payload_item.brand_name = brand_name
                            payload_item.isFailed = true
                            payload_item.fail_type = 'invalid_timeslot'
                            failed_payload.push(payload_item)
                        }
                    }

                    
                }
                else {
                    let brand_name = ''
                    if (app_id == market_app_key) {
                        brand_name = 'Central Market'
                    }
                    else {
                        brand_name = cart.brand.name
                    }
                    payload_item.brand_name = brand_name
                    payload_item.isFailed = true
                    payload_item.fail_type = 'timeslot_not_found'
                    failed_payload.push(payload_item)
                }
            }

            if(modeOfPayment?.paymentMethod?.name){
                payload_item.modeOfPayment = modeOfPayment?.paymentMethod?.name
            }
            payload.push(payload_item)
        }

        let payload_promise = new Promise(resolve => {            
            if (failed_payload.length > 0) {
                resolve(failed_payload[0])
            }
            else {
                resolve(payload)
            }
        })

        return payload_promise

    }

    createOrder = async () => {
        const modeOfPayment = JSON.parse(localStorage.getItem("third-party"))
        
        GAPlaceOrder(this.state.grandTotal)

        let isFormValid = this.validateForm()
        this.setState({isOrderLoading: true})

        if (isFormValid == false || isFormValid == "false") {
            // alert("please check all required fields")
            Swal.fire({
                showCloseButton: true,
                confirmButtonText: 'TRY AGAIN',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                html: `<div class='col-md-12'>
                    <h6 class='cmb-title-3 my-3 text-center'>Please check all the required fields.</h6>
                    </div>`,
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                }
            }).then(() => {
                this.setState({isOrderLoading: false})
            })
        }
        else  if ( !modeOfPayment.paymentMethod.value ) {
            Swal.fire({
                showCloseButton: true,
                confirmButtonText: 'TRY AGAIN',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                html: `<div class='col-md-12'>
                    <h6 class='cmb-title-3 my-3 text-center'>Please select payment method.</h6>
                    </div>`,
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                }
            }).then(() => {
                this.setState({isOrderLoading: false})
            })
        } 
        else {
            let createdOrders = []

            const proceed = (payload, app_key, counter) => {
                // console.log('proceeded', payload)
                let api_url = process.env.REACT_APP_SERINO_API
                let api = '/orders/bulkCreate'
                axios.post(`${api_url}${api}`, payload, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                        "content-type": "application/json",
                        "X-APP-KEY": app_key,
                    }
                })
                .then(data => {
                    // console.log('data after create order', data)
                    if (data.data.message.successful) {                    
                        let eats_app = this.state.apps.find(x => x.app.id == eats_app_key)
                        if (eats_app) {    
                            createOrderAnalytics(data, eats_app)
                        }
                        this.setState({
                            tracking_number: data.data.message.data.tracking_number, 
                            order_items: data.data.message.data.items,
                            group_id: data.data.message.data.group_id
                        }, () => {
                            // log('counter', counter)
                            // log('apps length', createdOrders.length)
                            let returnedProducts = data.data.message.data.amounts_breakdown
                            let newReturnedProducts = this.state.returnedProducts
                            newReturnedProducts.push.apply(newReturnedProducts, returnedProducts)
                            
                            newReturnedProducts = this.state.returnedProducts.filter(x => x.amount != null)

                            const uniqueReturnedProducts = newReturnedProducts.filter((v,i,a)=>a.findIndex(v2=>(v2.order_id===v.order_id))===i)

                            this.setState({
                                returnedProducts: uniqueReturnedProducts
                            })

                            let mappedProducts = uniqueReturnedProducts.map(x => {
                                return {
                                    ref_no: x.order_code,
                                    amount: x.amount,
                                }
                            })

                            localStorage.setItem('newReturnedProducts', JSON.stringify(mappedProducts))
                            // log('Failed orders', this.state.failed_orders)
                            // log('Failed orders', this.state.failed_orders.length)
                            if (counter == createdOrders.length) {
                                if (this.state.failed_orders && this.state.failed_orders.length > 0) {
                                    Swal.fire({
                                        confirmButtonText: 'TRY AGAIN',
                                        showCancelButton: true,
                                        cancelButtonText: 'CANCEL',
                                        html: `<div class='col-md-12'>
                                            <h3 class='cmb-title-2 my-3 text-center'>Failed</h3>
                                            <h6 class='cmb-title-3 my-3 text-center text-secondary'>Order Failed</h6>
                                            </div>`,
                                        customClass: {
                                            confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                                            cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                                        }
                                    }).then(() => {
                                        this.setState({
                                            failed_orders: []
                                        })
                                        // this.setOrderLoading(false)
                                    })
                                }
                                else {
                                    // log('paymentMethod', this.state.paymentMethod)
                                    if (modeOfPayment.paymentMethod.type === "online") {
                                        // log('paymentMethod', this.state.paymentMethod)
                                        this.handleCardPayment(data, modeOfPayment)
                                    }                                
                                    let clear_cart_counter = 0
                                    // this.setOrderLoading(false)
                                    createdOrders.forEach(app => {
                                        log('app', app)
                                        clear_cart_counter++
                                        if (modeOfPayment.paymentMethod.type == "cash") {
                                            this.clearCart(app.app_id, clear_cart_counter)
                                        }
                                    })
                                }
                            }
                        })

                        let order_info = data.data.message.data

                        let analytics_push = {
                            'event':'purchase',
                            'order_value': parseFloat(this.state.grandTotal), // should be the amount
                            'order_id': order_info.tracking_number, // transaction ID
                            'enhanced_conversion_data': {
                                "email": this.state.client.email,
                                "phone_number": this.state.client.phone_number,
                            }
                        }

                        if (order_info) {
                            GAPurchase(order_info, this.state.grandTotal, this.state.apps)
                        }

                        sessionStorage.removeItem("analytics_push");
                        sessionStorage.setItem("analytics_push", JSON.stringify(analytics_push));
                        localStorage.setItem("created_order_details", JSON.stringify(data));
                    }
                    else {
                        let error_message = ''
                        if (data && data.data && data.data.message) {
                            error_message = data.data.message
                        }
                        if (data && data.error && data.error.response && data.error.response.text && data.error.response.text.message) {
                            error_message = JSON.parse(data.error.response.text.message)
                        }
                        Swal.fire({
                            confirmButtonText: 'TRY AGAIN',
                            showCancelButton: true,
                            cancelButtonText: 'CANCEL',
                            html: `<div class='col-md-12'>
                                <h3 class='cmb-title-2 my-3 text-center'>Failed</h3>
                                <h6 class='cmb-title-3 my-3 text-center text-secondary'>${error_message.length > 0 ? error_message : 'Order Failed' }</h6>
                                </div>`,
                            customClass: {
                                confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                                cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                            }
                        }).then(() => {
                            if (error_message == 'Access Token has expired') {
                                localStorage.setItem('checkout error', data.error.response.text.message)
                                localStorage.removeItem('access_token')
                                window.location = '/'
                            }
                            this.setState({
                                failed_orders: []
                            })
                            this.setOrderLoading(false)

                        })                    
                    }
                })
                .catch(err => {
                    this.setState({
                        failed_orders: [this.state.failed_orders, ...payload]
                    })
                    this.setOrderLoading(false)
                })
                .finally(() => {
                    // this.setOrderLoading(false)
                })            
            }

            let isFormValid = this.validateForm()
            // log('isFormValid', isFormValid)

            let group_id = uuid()
            // log('apps', this.state.apps)

            localStorage.setItem('group-id-generated-on-bulkcreateorder-return', group_id)

            let isOrderSuccess = true
            for (const app of this.state.apps) {
                let payload = await this.createPayload(app.cart, group_id, app.app.id, modeOfPayment)

                log('payload', payload)

                if (payload.isFailed && payload.brand_name && payload.fail_type == 'timeslot_not_found') {
                    Swal.fire({
                        showCloseButton: true,
                        title: "Select Delivery Time",
                        html: getSwalContent(`Please select your preferred ${payload.fulfillmentType != "Deliver Later" ? "pickup" : "delivery"} time for your ${payload.brand_name} order.`),
                        customClass: {
                            confirmButton: 'voucher-confirm'
                        }
                    }).then(res => {
                        this.setOrderLoading(false)
                        isOrderSuccess = false
                        return
                    })
                    return
                }
                if (payload.isFailed && payload.brand_name && payload.fail_type == 'invalid_timeslot') {
                    Swal.fire({
                        showCloseButton: true,
                        title: "Select Delivery Time",
                        html: getSwalContent(`Timeslot is no longer valid for ${payload.brand_name}.`),
                        customClass: {
                            confirmButton: 'voucher-confirm'
                        }
                    }).then(res => {
                        this.setOrderLoading(false)
                        isOrderSuccess = false
                        return
                    })
                    return
                }
                if (!payload.isFailed) {
                    if (payload && payload.length > 0) {
                        localStorage.setItem('order_payload_sample', JSON.stringify(payload))
                        if (isFormValid) {
                            createdOrders.push({
                                payload: payload,
                                app_id: app.app.id
                            })
                        }
                        else {
                            let isOrderSuccess = true
                            this.setOrderLoading(false)
                            window.location.hash = "section-personal"
                        }
                    }
                    else {
                        return
                    }
                }
            }

        // log('isOrderSuccess', isOrderSuccess)


        if (isOrderSuccess) {
            if (this.state.isOMRSEnabled) {
                let OMRS_PAYLOAD = this.createOMRSPayload(createdOrders)
                localStorage.setItem('createdOrders', JSON.stringify(OMRS_PAYLOAD))
                // log({OMRS_PAYLOAD})
                // this.proceedOMRS(OMRS_PAYLOAD, modeOfPayment)
                
                // THIRD PARTY: Payload for Checkout GApp
                const customer = {
                    ...this.state.personal_details,
                    payee: `${this.state.personal_details.first_name} ${this.state.personal_details.last_name}` ,
                    phone_number: formatMobileNumber(this.state.personal_details.phone_number)
                }
                localStorage.setItem('tp-state-create-order', JSON.stringify({...OMRS_PAYLOAD, payment: { ...OMRS_PAYLOAD.payment, ...modeOfPayment.paymentMethod, option: modeOfPayment.paymentMethod?.name, amount: this.state.grandTotal.toFixed(2), customer }}))
            }
            else {
                let orderCounter = 0
                createdOrders.forEach((order) => {
                    orderCounter++
                    proceed(order.payload, order.app_id, orderCounter)

                })
            }
        }
        else {            
            Swal.fire({
                confirmButtonText: 'TRY AGAIN',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                html: `<div class='col-md-12'>
                    <h3 class='cmb-title-2 my-3 text-center'>Failed</h3>
                    <h6 class='cmb-title-3 my-3 text-center text-secondary'>Order Failed</h6>
                    </div>`,
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                }
            }).then(() => {
                this.setState({
                    failed_orders: []
                })
                this.setOrderLoading(false)
            })
        }}
    }

    validateForm = () => {
        let user = this.state.personal_details;
        // log('USER', user)

        let required_fields = ['first_name', 'last_name', 'phone_number', 'email_address']

        let error = []

        const getLabel = (name) => {
            switch(name) {                
                case ('first_name'): 
                    return 'First Name'
                    break
                case ('last_name'): 
                    return 'Last Name'
                    break
                case ('phone_number'): 
                    return 'Phone Number'
                    break
                case ('email_address'): 
                    return 'Email Address'
                    break
                default:
                    break
            }
        }

        required_fields.forEach(item => {
            if (!user[item] || user[item] == '') {
                let label = getLabel(item)
                error.push({
                    name: item,
                    content: `${label} is required`
                })
            }
        })


        const validateEmail = (email) => {
            var emailReg = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
            if (!emailReg.test(email.trim())) {
                return false
            } else {
                return true
            }
        }

        let isEmailValid = true

        if (user.email_address && user.email_address.length > 0) {
            isEmailValid = validateEmail(user.email_address)
            if (!isEmailValid) {
                if (error.filter(e => e.name == 'email_address').length > 0) {
                    let errorIndex = error.findIndex(e => e.name == 'email_address')
                    error[errorIndex].content = `Invalid Email Address`
                }
                else {
                    error.push({
                        name: 'email_address',
                        content: `Invalid Email Address`
                    })
                }
            }
        }

        if (user.phone_number && user.phone_number.length > 0) {

            var phoneRegex = /(^\+[6][3][9]\d{9}$)|(^[0][9]\d{9}$)/

            // Contact
            let contact = '+63' + user.phone_number
            let isContactValid = false

            if (phoneRegex.test(contact.trim())) {
                isContactValid = true
            }

            if (!isContactValid) {
                if (error.filter(e => e.name == 'phone_number').length > 0) {
                    let errorIndex = error.findIndex(e => e.name == 'phone_number')
                    error[errorIndex].content = `Invalid Phone Number`
                }
                else {
                    error.push({
                        name: 'phone_number',
                        content: `Invalid Phone Number`
                    })
                }
            }
        }

        this.setState({error})

        triggerSerinoEvent(window.serino_components.PersonalDetailsComponent.subscriptions.emitError, error)

        log('error', error)

        if (error.length > 0) {
            return false
        }
        else {
            return true
        }
    }

    hashString = (payload) => {
        let str = `${payload.secretKey}/${payload.publicKey}/${payload.merchantTrnId}/${payload.amount}/${payload.currency}/${payload.description}`;
        
        const shasum = crypto.createHash('sha256');
        return shasum.update(str).digest('hex');
    }

    handleCardPayment = (data, modeOfPayment) => {

        log('cardpayment data', data)
        log('cardpayment data', data.data)
        log('cardpayment data', data.data.data)
        const oid = this.state.isOMRSEnabled ? data.data.data[0].tracking_number : data.data.message.data.group_id
        log({oid})

        let hashPayload = {
            "merchantTrnId": oid,
            "amount": this.state.grandTotal.toFixed(2),
            "description": "Paymaya Payment Web App",
            "publicKey": merchant_pk,
            "secretKey": merchant_sk,
            "currency": "PHP"
        };
        

        let hash = this.hashString(hashPayload);
        localStorage.setItem('card payment data', JSON.stringify(data))
        const paymentCode = modeOfPayment?.paymentMethod?.details?.merchantProcessorCode || "CASH"
        switch (paymentCode) {
            case 'MAYA-VAULT':
                this.guestPayment(data, hash, modeOfPayment)
                break;
            case 'MAYA-WALLET':
                this.walletPayment(data, hash);
                break;
            default:
                break;
        }
    }

    walletPayment = (data, hash) => {
        const oid = this.state.isOMRSEnabled ? data.data.data[0].tracking_number : data.data.message.data.group_id
        const tracking_number = this.state.isOMRSEnabled ? oid : data.data.message.data.tracking_number
        let config = data.config && data.config.data ? JSON.parse(data.config.data) : null
        let user = this.state.personal_details

        let batchPayload = {
            "ref_id": [oid]
        }
        axios.post(`${process.env.REACT_APP_SERINO_API}/customer/billing/public/create-batch-payment`, batchPayload, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
                "content-type": "application/json",
                "X-APP-KEY": x_app_key,
            }
        }).then((batch_data) => {
            // log('batch_dataaaa', batch_data)
            let paymentPayload = {
                walletPayment: {
                    totalAmount: {
                        value: this.state.grandTotal.toFixed(2),
                        currency: this.state.currency
                    },
                    requestReferenceNumber: oid,
                    redirectUrl: {
                        success: getUrl('main') + '/checkout?s=s&oid=' + oid + '&tn=' + tracking_number,
                        failure: getUrl('main') + '/checkout?s=f&oid=' + oid + '&tn=' + tracking_number,
                        cancel: getUrl('main') + '/checkout?s=c&oid=' + oid + '&tn=' + tracking_number
                    },
                    "buyer": {
                        "firstName": user.first_name, 
                        "lastName": user.last_name,
                        "contact": {
                          "phone": `+63${user.phone_number}`,
                          "email": user.email_address
                        },
                        "shippingAddress": {
                          "firstName": user.first_name,
                          "lastName": user.last_name,
                          "phone": `+63${user.phone_number}`,
                          "email": user.email_address,
                          "line1": this.state.isOMRSEnabled ? data.data.data[0].shipping_details.full_address : config[0].shippingAddress,
                          "line2": "", // leave blank
                          "city": "", // leave blank
                          "zipCode": "", // leave blank
                          "countryCode": "PH", //hardcode to PH
                          "shippingType": "" // leave blank
                        },
                        "billingAddress": {
                          "line1": this.state.isOMRSEnabled ? data.data.data[0].shipping_details.full_address : config[0].shippingAddress,
                          "line2": "", // leave blank
                          "city": "", // leave blank
                          "zipCode": "", // leave blank
                          "countryCode": "PH" //hardcode to PH
                        }
                    }
                },
                transactionDetails: {
                    mode: "group",
                    currency: this.state.currency,
                    amount: this.state.grandTotal.toFixed(2),
                    products: JSON.stringify(this.state.order_items),
                    description: "Paymaya Payment Web App",
                    paymentMethod: "Paymaya Wallet",
                    merchantTxnId: oid,
                    merchantId: merchant_pk,
                    param1: oid,
                    param2: 'Central Main',
                    hashValue: hash.trim()
                },
                
                processor: "PAYMAYA WALLET"
            }

            localStorage.setItem('paymentPayload', JSON.stringify(paymentPayload))
            
            $.ajax({
                url: process.env.REACT_APP_PAYMENT_URL + '/api/cards/paymaya-wallet',
                method: 'POST',
                contentType: 'application/json',
                dataType: 'json',
                data: JSON.stringify(paymentPayload)
            }).done((result) => {
                // log('result', result)
                if (result.success) {
                    result.hasOwnProperty('redirectUrl') ? window.location = result.redirectUrl : this.updateOrdertoFailed(data, "Payment Wallet");
                } else {
                    this.updateOrdertoFailed(data, "Payment Wallet");
                }
            }).fail(function (result) {
                if (result.status === 401) {
                    Swal.fire({
                        title: "Error!",
                        text: "Session has expired",
                        icon: "error"
                    }).then((value) => {
                        this.removeInfo();
                        window.location = `${getUrl('main')}?page=logout`
                    });
                } else {
                    this.updateOrdertoFailed(data, "Payment Wallet");
                }
            });;
        })
        .catch((res) => {
            this.updateOrdertoFailed(data, "Payment Wallet");
        });
    }

    guestPayment = (data, hash, modeOfPayment) => {
        let config = data.config && data.config.data ? JSON.parse(data.config.data) : null
        let user = this.state.personal_details

        const oid = this.state.isOMRSEnabled ? data.data.data[0].tracking_number : data.data.message.data.group_id
        const tracking_number = this.state.isOMRSEnabled ? oid : data.data.message.data.tracking_number

        // let batchPayload = {
        //     "ref_id": data.data.message.data.group_id
        // }
        // axios.post(`${process.env.REACT_APP_SERINO_API}/customer/billing/public/create-batch-payment`, batchPayload, {
        //     headers: {
        //         "Authorization": `Bearer ${localStorage.getItem('access_token')}`,
        //         "content-type": "application/json",
        //         "X-APP-KEY": x_app_key,
        //     }
        // }).then((batch_data) => {
        // }).catch((res) => {
        //     this.updateOrdertoFailed(data, "Payment")
        // });
            // let returnedProducts = data.data.message.data.amounts_breakdown.map(x => {
            //     return {
            //         ref_no: x.order_code,
            //         amount: x.amount,
            //     }
            // })

            // log('dataaaaaaa', batch_data)
            const token = modeOfPayment?.paymentMethod?.details?.token || ""
            let paymentPayload = {
                tokenPayment: {
                    paymentTokenId: token,
                    totalAmount: {
                        amount: this.state.grandTotal.toFixed(2),
                        currency: this.state.currency
                    },
                    requestReferenceNumber: oid,
                    redirectUrl: {
                        success: getUrl('main') + '/checkout?s=s&oid=' + oid + '&tn=' + tracking_number,
                        failure: getUrl('main') + '/checkout?s=f&oid=' + oid + '&tn=' + tracking_number,
                        cancel: getUrl('main') + '/checkout?s=c&oid=' + oid + '&tn=' + tracking_number
                    },
                    "buyer": {
                        "firstName": user.first_name, 
                        "lastName": user.last_name,
                        "contact": {
                          "phone": `+63${user.phone_number}`,
                          "email": user.email_address
                        },
                        "shippingAddress": {
                          "firstName": user.first_name,
                          "lastName": user.last_name,
                          "phone": `+63${user.phone_number}`,
                          "email": user.email_address,
                          "line1": this.state.isOMRSEnabled ? data.data.data[0].shipping_details.full_address : config[0].shippingAddress,
                          "line2": "", // leave blank
                          "city": "", // leave blank
                          "zipCode": "", // leave blank
                          "countryCode": "PH", //hardcode to PH
                          "shippingType": "" // leave blank
                        },
                        "billingAddress": {
                          "line1": this.state.isOMRSEnabled ? data.data.data[0].shipping_details.full_address : config[0].shippingAddress,
                          "line2": "", // leave blank
                          "city": "", // leave blank
                          "zipCode": "", // leave blank
                          "countryCode": "PH" //hardcode to PH
                        }
                    }
                },
                transactionDetails: {
                    mode: "group",
                    currency: this.state.currency,
                    amount: this.state.grandTotal.toFixed(2),
                    products: JSON.stringify(this.state.order_items),
                    description: "Paymaya Payment Web App",
                    paymentMethod: "Credit / Debit Card",
                    merchantTxnId: oid,
                    merchantId: merchant_pk,
                    param1: oid,
                    param2: 'Central Main',
                    hashValue: hash.trim()
                },
                processor: "PAYMAYA"
        }

        // console.log(paymentPayload)
        localStorage.setItem('payment_payload', JSON.stringify(paymentPayload))
        axios.post(`${process.env.REACT_APP_PAYMENT_URL}/api/cards/token/pay`, paymentPayload, {
            headers: {
                "content-type": "application/json"
            }
        })
        .then((result) => {
            // console.log('ressssuuult', result)
            // console.log('ressssuuult', result.success)
            localStorage.setItem('payment result', JSON.stringify(result))
            if (result.data.success && result.data.status) {
                if (result.data.status == "FOR_AUTHENTICATION") {
                    window.location = result.data.verificationUrl

                } else {
                    Swal.fire({
                        title: "Success!",
                        text: "Payment Successful",
                        icon: "success"
                    }).then((value) => {
                        window.location = "/";
                    });
                }
            } else {
                this.updateOrdertoFailed(data, "Payment");
            }
        }).catch((err) => {
            log('ressssuuult FAILED', err)
            log('ressssuuult FAILED RESPONSE', err.response)
            if (err.status === 401) {
                localStorage.removeItem('access_token')
                this.removeInfo();
                window.location = "/";
            }
            else {
                this.updateOrdertoFailed(data, "Payment")
            }
            })
    }

    updateOrdertoFailed = (data, type, show = 1) => {
        console.log('type', type)
        if (show) {
            Swal.fire({
                confirmButtonText: 'TRY AGAIN',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                reverseButtons: true,
                html: `<div class='col-md-12'>
                    <h3 class='cmb-title-2-checkout my-3 text-center'>Payment Failed</h3>
                    <h6 class='cmb-title-3-checkout my-3 text-center text-secondary'>Payment Unsuccessful</h6>
                    </div>`,
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
                }
            }).then(() => {
                this.setOrderLoading(false)
            })
        }
        // this.setOrderLoading(false)
        // localStorage.setItem('data_from_ordertofailed', JSON.stringify(data))
        // $.ajax({
        //     url: process.env.REACT_APP_SERINO_API + '/order/paymentStatus2?transactionId=' + data.data.message.data.group_id + '&status=F',
        //     method: 'GET',
        //     contentType: 'application/json',
        //     dataType: 'json',

        // }).done(function (result) {
        //     if (show) {
        //         Swal.fire({
        //             confirmButtonText: 'TRY AGAIN',
        //             showCancelButton: true,
        //             cancelButtonText: 'CANCEL',
        //             reverseButtons: true,
        //             html: `<div class='col-md-12'>
        //                 <h3 class='cmb-title-2-checkout my-3 text-center'>Payment Failed</h3>
        //                 <h6 class='cmb-title-3-checkout my-3 text-center text-secondary'>Payment Unsuccessful</h6>
        //                 </div>`,
        //             customClass: {
        //                 confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
        //                 cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
        //             }
        //         }).then(() => {
        //             this.setOrderLoading(false)
        //         })
        //     }

        //     $(".checkout").removeClass("srn-loading-paymaya");
        // }).fail(function (data) {
        //     Swal.fire({
        //         title: "Error!",
        //         text: "An error has occured",
        //         icon: "error"
        //     })
        // });
    }

    datalayer = () => {
        var analytics = sessionStorage.getItem('analytics');
        if (analytics != "") {
            var dataLayer = window.dataLayer || [];
            dataLayer.push(JSON.parse(analytics));
            sessionStorage.setItem('analytics', '');
        }
    }

    getAddressObject = (address_object) => {
        this.setState({address_object})
    }

    handleLocationChange = (address_object) => {
        // this.setState({address_object: address_object})
        log('address_object', address_object)
        let cartEvents = window.serino_components.cart.subscriptions
        localStorage.removeItem('SRN-CURRENT-APPS')
        // window.location.reload()
        triggerSerinoEvent(cartEvents.refreshCart('eatsCart'))
        triggerSerinoEvent(cartEvents.refreshCart('marketCart'))

        setTimeout(() => {
            this.handleEmptyChecker()
        }, 2000)

    }

    handleEmptyChecker = () => {

        let apps = this.state.apps

        log('got in')
        
        let updatedCartItems = []
            
        apps.map(app => {
            app.cart?.map(x => updatedCartItems.push(x))
        })
        
        log({updatedCartItems})

        if (updatedCartItems.length == 0) {
            Swal.fire({
                html: getSwalContent('Your cart is empty.'),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            }).then(res => {
                window.location = '/'
            })
        }

    }

    handleAgreement = (e) => {
        this.setState({isAgreementChecked: e.currentTarget.checked})
        // THIRD PARTY: Event to listen in checkbox state
        window.dispatchEvent(new Event('tp-state-checked'));
    }
    
    checkoutDisable = ((e) => {
        if (this.state.isAgreementChecked == false ) {
            document.getElementById("checkout-btn-paymaya").disabled = false
            document.getElementById("checkout-btn-cod").disabled = false
        }
        else  {
            document.getElementById("checkout-btn-paymaya").disabled = true
            document.getElementById("checkout-btn-cod").disabled = true
        }
    })

    getRiderChange = (total) => {
        let change = 0
        let rounded = Math.floor(total/500) * 500
        if (total > rounded) {
            change = rounded + 500
        }
        else {
            change = rounded
        }
        return change
    }

    getTotalSavings = () => {
        let apps = this.state.apps
        let carts = []
        apps.forEach((app) => app.cart.map(cart_item => carts.push(cart_item)))
        let promos = []
        carts.forEach(cart => cart.promos.map(promo => promos.push(Math.abs(promo.computedValue))))
        return promos.reduce((a, b) => a + b, 0)
    }
    //mweb temporary
    showOrderDetails = () => {
        document.getElementById('section-cart').style.display = "block"
        document.getElementById('section-personal').style.display = "none"
        document.getElementById('prog-bar').style.marginLeft = "0"
    }
    showContactDetails = () => {
        document.getElementById('section-cart').style.display = "none"
        document.getElementById('checkout-mop').style.display = "none"
        document.getElementById('section-personal').style.display = "block"
        document.getElementById('srn-crm-personal-details-container').style.display = "block"
        document.getElementById('contact-header').style.display = "block"
        document.getElementById('prog-bar').style.marginLeft = "33%"
    }
    showPayment = () => {

        let isFormValid = this.validateForm()
        
        if (isFormValid) {
            document.getElementById('section-cart').style.display = "none"
            document.getElementById('contact-header').style.display = "none"
            document.getElementById('srn-crm-personal-details-container').style.display = "none"
            document.getElementById('checkout-mop').style.display = "block"
            document.getElementById('prog-bar').style.marginLeft = "70%"
        }
        else {
            window.location.hash = "section-personal"
            this.setState({ isLoading: false })
        }
    }

    closeMinModal = () => {
        $('#srn-min-modal').modal('hide')
    }

    getFulfillmentDetails = async () => {
        let carts = this.state.apps.map(app => app.cart)

        let cart_items = []
        carts.map(cart => {
            cart.map(item => {
                cart_items.push(item)
            })
        })

        const dataFromJSON = await coreAPI.getDataFromJSON()

        const brands = dataFromJSON?.data?.brands || [];

        const brandWithSmallestCashMax = getMaxOnlinePaymentAmountObject(this.state.apps, brands, 'cash')

        let maxCashAmt = brandWithSmallestCashMax?.max_cash_amount ?? 3000

        let fulfillments = cart_items.map(x => {
            if (x && x.fulfillment) {
                return x.fulfillment.fulfillmentType_status
            }
        })

        const handleCashDisable = getBrandsWithDisabledCash(this.state.apps, brands)
        let disableCash = handleCashDisable?.length > 0

        const isCashEnabled = disableCash ? false : (fulfillments.filter(x => x == 2).length < 1) && this.state.grandTotal < maxCashAmt && this.state.grandTotal != 0;
        
        // Trigger to hide/show COD in Third Party
        localStorage.setItem('tp-state-hide-cod', !isCashEnabled)
        window.dispatchEvent(new Event('tp-event-hide-cod'));
        
        this.setState({
            isCashEnabled,
            isCartLoading: false
        })
    }

    validateUpdatedTimeSlot = async (address) => {

        let interval = 30

        let serverDateTimeReturn = await checkoutAPI.getServerDateTime()

        log(serverDateTimeReturn)
        log(serverDateTimeReturn.data.result)

        let timeNow = moment(serverDateTimeReturn.data.result)

        let selected_datetime = moment(address.selected_datetime).subtract(interval, "minutes")

        log({timeNow})
        log({selected_datetime})

        if (timeNow < selected_datetime) {
            return true
        }
        else {
            return false
        }

    }

    validateTimeSlots = (address, items, app_id) => {
        let cart_items = items.map(x => {
            return {
                "sku": x.itemSKU,
                "quantity": x.quantity
            }
        })
        let selected_datetime = address.selected_datetime
        let payload = {
            "selected_slot": selected_datetime,
            "items": cart_items
        }
        return cart.validateTimeSlots(address.branch_id, payload, app_id)
    }
  
    handlePaymentFormSubmit = async (event) => {
        event.preventDefault()

        if (this.state.isLoading) {
            return
        }

        this.setState({
            isLoading: true,
            cvcError: '',
            expMonthError: '',
            expYearError: '',
            nameError: '',
            numberError: '',
        })

        if (this.state.name.length == 0) {
            this.setState({
                nameError: 'This field is required',
                isLoading: false
            })
            return;
        }

        let cvc =  this.state.cvc
        let expMonth = this.state.expMonth
        let expYear =  this.state.expYear
        let name =  this.state.name
        let number =  this.state.number.split(' ').join('')
        let pk =  paymayaKey

        let stagingPayload = {
            card: {
                name: name,
                number: number,
                expMonth: expMonth,
                expYear: expYear,
                cvc: cvc
            }
        }
        // let stagingPayload = {"name":"DEVTEST","number":"4834420042179618","expMonth":"09","expYear":"2023","cvc":"616","pk":"pk-0R8yRwTYBuM2T6kYC9cNsTMt5Hu5LldVtquvl9FPPFw"}

        // const encodeBuffer = Buffer.from(paymayaKey + ':')
        // const encodeBuffer = Buffer.from(paymayaKey)
        // const token = encodeBuffer.toString('base64')

        let api_url = process.env.REACT_APP_PAYMAYA_API
        let api = `/payments/v1/payment-tokens`

        try {

            let stagingResult = await axios.post(`${api_url}${api}`, stagingPayload, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Basic " + window.btoa(paymayaKey + ':'),
                    origin: "https://cdn.paymaya.com"
                }
            })

            log({stagingResult})
            log(stagingResult.data)
            if (stagingResult) {
                localStorage.setItem('stagingResult', JSON.stringify(stagingResult))
            }

            if (stagingResult.status == 200) {
                let token = stagingResult.data.paymentTokenId;
                document.getElementById("card-form-valid").click()

                // if (clickcnt < 1) {
                //     clickcnt = 1;
                    fbqEvent('AddPaymentInfo')
                    this.setState({
                        guestPaymentToken: token,
                    })

                    let isFormValid = this.validateForm()
                    
                    if (isFormValid) {
                        // this.createOrder("cdc");
                        document.getElementById("card-form-valid").click()
                    }
                    else {
                        window.location.hash = "section-personal"
                        this.setState({ isLoading: false })
                    }
                // } else {

                // }
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "Please try again!",
                    icon: "error"
                });
            }

        }
        catch (stagingResultError) {
            log('stagingResultError', stagingResultError)
            log('stagingResultError', stagingResultError.response)
            let error = stagingResultError.response.data.parameters

            const hasError = (field) => {
                return  error.find(x => x.field == `card.${field}`)
            }

            if (this.state.name.length == 0) {
                this.setState({
                    nameError: 'This field is required',
                    isLoading: false
                })
            }

            this.setState({
                cvcError: hasError('cvc') ? hasError('cvc').description : '',
                expMonthError: hasError('expMonth') ? hasError('expMonth').description : '',
                expYearError: hasError('expYear') ? hasError('expYear').description : '',
                nameError: hasError('name') ? hasError('name').description : this.state.name.length == 0 ? 'This field is required' : '',
                numberError: hasError('number') ? hasError('number').description : '',
                isLoading: false
            })

            if (stagingResultError.response.status == 400) {

                Swal.fire({
                    title: "Error!",
                    text: "Please try again!",
                    icon: "error"
                });
            }
        }
    }

    updateCartCount = (apps) => {

        setTimeout(() => {

            let count = apps.map(app => app.cart).length

            let carts = apps.map(app => app.cart)
            
            let cart_items = []

            if (carts && carts.length > 0) {
                carts.map(cart => {
                    if (cart) {
                        cart.map(x => x.cart_items.map(y => cart_items.push(y)))
                    }
                })            
            }

            let cartCount = 0

            if (cart_items.length > 0) {
                cartCount = cart_items.reduce((a, b) => + + +b.quantity, 0)
            }

            if (cartCount > 0) {
                $('.srn-cart-quantity').text(cartCount)
            }
        }, 500)
    }
    
    datalayerPush = () => {
        let analytics_push = JSON.parse(localStorage.getItem('analytics_push'))
        log({analytics_push})
        var dataLayer = window.dataLayer || [];
        dataLayer.push(analytics_push)
        setTimeout(() => {
            localStorage.removeItem('analytics_push')
        }, 80)
    }

    createOMRSPayload = (created_orders) => {
        let payloads = []

        // THIRD PARTY: Payment Method Payload
        const modeOfPayment = JSON.parse(localStorage.getItem("third-party"))
        const paymentMethod = modeOfPayment.paymentMethod || {}

        log({created_orders})

        created_orders.forEach(item => {
            let items = item.payload.map(x => {
                return {
                    ...x,
                    app_id: item.app_id
                }
            })
            payloads = [...payloads, ...items]
        })

        const getPaymentOption = () => {
            switch (this.state.paymentMethod) {
                case 1:
                     return 'Paymaya Credit/Debit'
                    break
                case 2:
                     return 'Paymaya Wallet'
                    break
                case 3:
                     return 'Cash On Delivery'
                    break
                default:
                    break
            }
        }

        const orders = payloads.map(item => {
            return {
                "store_reference_id": item.storeId.toString(),
                "merchant_reference_id": item.app_id == 2 ? 'eats' : 'market',
                "fulfillment_type": item.fulfillmentType.toLowerCase(),
                "change_for": "0",
                "promo_reference_id": item.promo_transaction_id && item.promo_transaction_id.length > 0 ? item.promo_transaction_id.join(',') : '',
                "cart_id": item.cart_id,
                "shipping_address": {
                    "full_address": item.shippingAddress,
                    "pinned_address": item.pinned_address,
                    "region": "",
                    "city": "",
                    "barangay": "",
                    "street": "",
                    "landmark": item.landmark,
                    "zip_code": "",
                    "latitude": item.latitude ? parseFloat(item.latitude) : 0,
                    "longitude": item.longitude ? parseFloat(item.longitude) : 0,
                    "details": ""
                },
                "customer": {
                    "first_name": item.firstName,
                    "last_name": item.lastName,
                    "email": item.email,
                    "contact_number": item.contactNumber,
                    "reference_1": "",
                    "reference_2": "",
                    "reference_3": ""
                },
                "schedule": `${moment(item.schedule).format('YYYY-MM-DD')} ${moment(item.schedule).format('HH:mm:ss')}`,
                "reservation_reference": item.booking_resource_code,
                "notes": [
                    {
                        "type": "Order Notes",
                        "notes": item.notes.note
                    }
                ],
                "items": item.items.map(x => {
                        return {
                            "type": x.itemType === 'weighted_product' ? 'weighted' : x.itemType,
                            "reference_id": x.itemRefId,
                            "sku": x.itemSku,
                            "barcode": x.barcode ? x.barcode : '',
                            "name": x.itemRefName,
                            "description": x.itemRefDesc,
                            "quantity": x.qty,
                            "price": parseFloat(x.price),
                            "image": x.itemRefImg,
                            "options": x.options ? x.options : '',
                            "instructions": x.item_instruction,
                            "reference_1": x.reference_1 ? x.reference_1 : '',
                            "reference_2": "",
                            "reference_3": "",
                            "weight": x.weight ? parseFloat(x.weight) : 0,
                            "weight_uom": x.itemType === 'weighted_product' ? 'kg' : '',
                            "weight_price_per_unit": x.weight_price_per_unit ? parseFloat(x.weight_price_per_unit) : 0
                        }
                    }),
                "action_to_proceed": localStorage.getItem('selected_order_option_text') ? localStorage.getItem('selected_order_option_text') : '',
                "source": "Central PH",
                "platform": "Web",
                "platform_details": navigator.userAgent,
                "reference_1": "",
                "reference_2": "",
                "reference_3": ""
            }
        })

        let payload =  {
            "payment": {
                "type": paymentMethod.type,
                "option": paymentMethod.option
            },
            "orders": orders
        }

        return payload
    }

    proceedOMRS = async (payload, modeOfPayment) => {

        log('proceedOMRS', payload)

        checkoutAPI.createOrderOMRS(localStorage.getItem('access_token'), payload).then(async data => {
            if (data.successful) {
                localStorage.setItem("created_order_details", JSON.stringify(data));
                log(data.items)
                log(data.data.data)

                //@todo GAPurchaseOMRS
                const order_info = data.data.data
                GAPurchaseOMRS(order_info, this.state.grandTotal, this.state.apps)

                let order_items = data.data.data.map(x => {
                    return {
                        ref_no: x.code,
                        amount: x.total_amount ? x.total_amount : 0.00
                    }
                })

                log({order_items})

                this.setState({
                    tracking_number: data.data.data[0].tracking_number,
                    order_items: order_items,
                    group_id: data.data.data[0].tracking_number
                }, async () => {

                    if (modeOfPayment.paymentMethod.type === "online") {
                        this.handleCardPayment(data, modeOfPayment)
                    }
                    if (modeOfPayment.paymentMethod.type === "cash") {
                        await cart.clearCart(localStorage.getItem('access_token'), market_app_key)
                        await cart.clearCart(localStorage.getItem('access_token'), eats_app_key)
                        this.onCheckoutSuccess()
                    }
                })
            }
            if (data.errors && data.errors.length > 0) {
                let orderErrorMessage = `<ul>${data.errors.map(orderErr => `<li>${orderErr}</li>`)}</ul>`
                Swal.fire({
                    showCloseButton: true,
                    title: "Order Failed",
                    html: getSwalContent(orderErrorMessage),
                    customClass: {
                        confirmButton: 'voucher-confirm'
                    }
                }).then(res => {
                    this.setState({isOrderLoading: false})
                })
            }
        }).catch(err => {
            if (err?.responseJSON?.errors && err?.responseJSON?.errors.length > 0) {
                let orderErrorMessage = `<ul class="srn-alert-ul">${err.responseJSON.errors.map(orderErr => `<li class="srn-alert-list-item">${orderErr}</li>`)}</ul>`
                Swal.fire({
                    showCloseButton: true,
                    title: "Order Failed",
                    html: getSwalContent(orderErrorMessage),
                    customClass: {
                        confirmButton: 'voucher-confirm'
                    }
                }).then(res => {
                    this.setState({isOrderLoading: false})
                })
            }
        })

        // log({mock_return})
    }

    showCreditCardModal = () => {
        $('#checkout-creditcard').modal('show')
    }

    render() {
        return (
            <div className={`d-flex flex-column h-100 ${this.state.isScrolled ? '' : 'srn-v-none'}`} id="checkoutmaincontainer" ref={this.topContainer}>
                {
                    this.state.isCheckoutLoading &&
                    <div className="srn-page-loader">
                        <div className="srn-line-loader">
                            <div>{[...Array(5)].map((x, i) => <span key={i}></span>)}</div>
                        </div>
                    </div>
                }
                {/* <button onClick={() => this.createOrder("cdc")}>asdasdasd</button> */}
                <div className={`container-x checkout-container`} style={{padding: "50px"}}>
                    <div className="srn-checkout-v3-container col-md-7">
                        <div className="mweb-checkout-category">
                            <ul>
                                <li onClick= {() => this.showOrderDetails()}>Order details</li>
                                <li onClick= {() => this.showContactDetails()}>Contact details</li>
                                <li onClick= {() => this.showPayment()}>Payment details</li>
                            </ul>
                            <div className="progress">
                                <div className="progress-bar" id="prog-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    
                        {
                            (this.state.x_app_key === market_app_key || this.state.x_app_key === eats_app_key) && localStorage.getItem('access_token') ?
                            <LocationPicker
                                access_token={localStorage.getItem('access_token')}
                                id="eats_products_page"
                                x_app_key={this.state.x_app_key}
                                hidden={true}
                                getAddressObject={this.getAddressObject} 
                                handleLocationChange={this.handleLocationChange}
                                brand_id={this.state.current_brand_id}
                            /> : ''
                        }
                        <div className="srn-checkout-personal-container">
                            <div id="section-personal" className="srn-section-personal">
                                <h5 className="checkout-header srn-crm-checkout-title" id="contact-header">Contact Details</h5>
                                <div id="srn-crm-personal-details-container" className="srn-crm-personal-details-container"></div>
                            </div>
                        </div>              
                        <div className="srn-checkout-cart-container">
                            <div id="section-cart" className="srn-section-cart">
                                <h6 className="checkout-header">Order Details</h6>
                                {
                                    this.state.isCartLoading && 
                                    <div className="srn-line-loader-container">
                                        {loadingDisplay('dark')}
                                    </div>
                                }
                                <div className={`cmb-order-details-container`}>
                                    {/*
                                        this.state.apps.find(x => x.app.id == market_app_key) &&
                                        <Fragment>
                                            <h4 className="srn-3-cart-title srn-3-cart-title-market"> CENTRAL MARKET </h4>
                                            <div id="srn-cart-market-container" className="srn-cart-market-container srn-cart-component-container srn-cart-view"></div>
                                            <div id="srn-market-summary-container" className="srn-cart-component-container srn-cart-summary-container srn-cart-summary-view d-none"></div>
                                        </Fragment>
                                    */}
                                    {/*
                                        this.state.apps.find(x => x.app.id == eats_app_key) &&
                                        <Fragment>
                                            <h4 className="srn-3-cart-title srn-3-cart-title-eats"> CENTRAL EATS </h4>
                                            <div id="srn-cart-eats-container" className="srn-cart-eats-container srn-cart-component-container srn-cart-view"></div>
                                            <div id="srn-eats-summary-container" className="srn-cart-component-container srn-cart-summary-container srn-cart-summary-view d-none"></div>
                                            <div id="srn-cart-total-container" className="srn-cart-total-eats-container srn-cart-total-view"></div>
                                        </Fragment>
                                    */}
                                    {/* <h4 className="srn-3-cart-title srn-3-cart-title-market"> CENTRAL MARKET </h4> */}
                                    <div id="srn-cart-market-container" className="srn-cart-market-container srn-cart-component-container srn-cart-view"></div>
                                    <div id="srn-market-summary-container" className="srn-cart-component-container srn-cart-summary-container srn-cart-summary-view d-none"></div>
                                    {/* <h4 className="srn-3-cart-title srn-3-cart-title-eats"> CENTRAL EATS </h4> */}
                                    <div id="srn-cart-eats-container" className="srn-cart-eats-container srn-cart-component-container srn-cart-view"></div>
                                    <div id="srn-eats-summary-container" className="srn-cart-component-container srn-cart-summary-container srn-cart-summary-view d-none"></div>
                                    {/* <div id="srn-cart-total-container" className="srn-cart-total-eats-container srn-cart-total-view"></div> */}
                                </div>
                            </div>
                        </div>


                        <div className="srn-checkout-mop-container">
                            <div className="checkout-mop-container" id="checkout-mop">
                                <h6 className="checkout-header">Payment Options</h6>
                                <h6 className="select-payment text-secondary">Payment Options</h6>
                                <p className="payment-subtext">Please Select One</p>
                                
                                {/* Third Party: Payment GApp */}
                                <div id="payment-gapp"></div>

                                <div className="srn-main-tnc-agreement-container-checkout">
                                    <div className="form-check form-check-inline srn-main-custom-checkbox">
                                        <label htmlFor="privPolCHK" className="srn-main-custom-checkbox-container">
                                            <span className="srn-main-tnc-agreement-checkbox">
                                                <input type="checkbox" id="privPolCHK" 
                                                required 
                                                checked={this.state.isAgreementChecked}
                                                onChange={(e) => this.handleAgreement(e)}
                                                onClick={(e) => this.checkoutDisable(e)}
                                                />
                                                <span className="srn-main-custom-checkbox-toggle">
                                                    <i><FontAwesomeIcon icon={faCheck} /></i>
                                                </span>
                                            </span>
                                            <div className="srn-main-main-tnc-agreement-label">
                                                I agree to Central by Landers’&nbsp;
                                                <a href="/terms-conditions" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">TERMS & CONDITIONS</strong></a>&nbsp;and&nbsp;
                                                <a href="/privacy-policy" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">PRIVACY POLICY</strong></a>.
                                            </div>
                                        </label>
                                    </div>
                                </div> 
                                                         
                                

                                <div className={`d-none ${this.state.paymentMethod == 2 ? 'd-none' : 'd-none'} paymaya-payment-form`}>
                                    {/* <h5 className="checkout-header">PayMaya Account</h5> */}
                                    <p className="cmb-content cmb-payment-content">
                                        Place order and login to your PayMaya account to complete order
                                    </p>
                                    <div className="srn-main-tnc-agreement-container-checkout">
                                        <div className="form-check form-check-inline srn-main-custom-checkbox">
                                            <label htmlFor="privPolCHK" className="srn-main-custom-checkbox-container">
                                                <span className="srn-main-tnc-agreement-checkbox">
                                                    <input type="checkbox" id="privPolCHK" 
                                                    required 
                                                    checked={this.state.isAgreementChecked}
                                                    onChange={(e) => this.handleAgreement(e)}
                                                    onClick={(e) => this.checkoutDisable(e)}
                                                    />
                                                    <span className="srn-main-custom-checkbox-toggle">
                                                        <i><FontAwesomeIcon icon={faCircle} /></i>
                                                    </span>
                                                </span>
                                                <div className="srn-main-main-tnc-agreement-label">
                                                    I agree to Central by Landers’&nbsp;
                                                    <a href="/terms-conditions" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">TERMS & CONDITIONS</strong></a>&nbsp;and&nbsp;
                                                    <a href="/privacy-policy" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">PRIVACY POLICY</strong></a>.
                                                </div>
                                            </label>
                                        </div>
                                    </div> 
                                    <div className="checkout-payment-total-container" id="checkout-payment-total">
                                        <div className="srn-cart-total-container" style={{display: "block"}}>
                                            <div className="srn-cart-total-main-container">
                                                <p className="srn-cart-total-label">{this.props.mode && this.props.mode == 'view' ? 'Total' : 'Total'} 
                                                <span className="srn-cart-total-subtitle">&nbsp;(Vat included)</span></p>
                                                <p className="srn-cart-total-value">₱ {parseFloat(this.state.grandTotal).toFixed(2)}</p>
                                            </div>
                                            <div className="srn-cart-total-savings">
                                                <div className={`srn-cart-total-discount-container ${this.state.isMember === 'true' ? 'srn-cart-total-disabled' : ''}`}>
                                                    <p className="srn-cart-total-discount-label">Total Savings</p>
                                                    <p className="srn-cart-total-discount-value">₱ {this.state.totalSavings}</p>
                                                </div>
                                                {
                                                    this.state.isMember === 'true' ?
                                                    <div className={`srn-cart-total-discount-container srn-cart-total-savings-container d-none ${this.state.isMember === 'true' ? 'srn-cart-total-enabled' : ''}`}>
                                                        <p className="srn-cart-total-discount-label">Member's Savings</p>
                                                        <p className="srn-cart-total-discount-value">₱ {this.state.memberSavings}</p>
                                                    </div> :
                                                    <div className={`srn-cart-total-discount-container srn-cart-total-savings-container d-none guest checkout ${this.state.isMember === 'true' ? 'srn-cart-total-enabled' : ''}`}>
                                                        <img src={landersLogo} className="srn-landers-logo" />
                                                        <p className="srn-cart-total-discount-label">Be a Landers Member and Save</p>
                                                        <p className="srn-cart-total-discount-value">- ₱ {this.state.memberSavings}</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.isAgreementChecked == false &&
                                        <div className="terms-condition-indicator">
                                            <span>You must agree to our Terms & Conditions and Privacy Policy to proceed</span>
                                        </div>
                                    }
                                    <button
                                        className="btn btn-secondary btn-lg btn-block checkout-btn" id="checkout-btn-paymaya"
                                        onClick={() => this.createOrder('pwp')}
                                        disabled={this.state.isOrderLoading || !this.state.isAgreementChecked}
                                    >
                                        { this.state.isOrderLoading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> }
                                        PLACE ORDER
                                    </button>
                                </div>
                                
                                {/* RC 13 REMOVE ----------------------------------------------------------------------------------------------------------------------*/}
                                <div className={`${this.state.paymentMethod == 3 && this.state.isCashEnabled ? 'd-none' : 'd-none'} cod-payment-form`}>
                                      {
                                        this.state.apps.map((app, i) => (
                                            <Fragment key={i}>
                                                {
                                                    app.cart.map((cart_item, x) => (
                                                        <Fragment key={x}>
                                                            <div className="checkout-payment-total-container">
                                                                <div className="srn-checkout-cart-total-container">
                                                                    <div className="srn-checkout-cart-total-images">
                                                                        <img src={app.app.logo} className="srn-checkout-cart-total-app-logo" />
                                                                        {
                                                                            app.app.id == eats_app_key &&
                                                                            <img src={cart_item.brand.logo} className="srn-checkout-cart-total-brand-logo" />
                                                                        }
                                                                    </div>
                                                                    <div className="srn-checkout-cart-total">
                                                                        <label htmlFor="" className="srn-checkout-cart-total-label">Total <small className="srn-checkout-small">&nbsp;(Vat included)</small></label>
                                                                        <p className="srn-checkout-cart-total">₱ {cart_item.total.toFixed(2)}</p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    cart_item.fulfillment && cart_item.fulfillment.fulfillmentType_status && cart_item.fulfillment.fulfillmentType_status != 2 &&
                                                                    <div className="srn-checkout-change-for-container">
                                                                        <p className="srn-checkout-change-for-subtext">Rider prepared change for ₱ {this.getRiderChange(cart_item.total)}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Fragment>
                                                    ))
                                                }
                                            </Fragment>
                                        ))
                                     }
                                      <div className="srn-main-tnc-agreement-container-checkout">
                                            <div className="form-check form-check-inline srn-main-custom-checkbox">
                                                <label htmlFor="privPolCHK" className="srn-main-custom-checkbox-container">
                                                    <span className="srn-main-tnc-agreement-checkbox">
                                                        <input type="checkbox" id="privPolCHK" 
                                                        required 
                                                        checked={this.state.isAgreementChecked}
                                                        onChange={(e) => this.handleAgreement(e)}
                                                        onClick={(e) => this.checkoutDisable(e)}
                                                        />
                                                        <span className="srn-main-custom-checkbox-toggle">
                                                            <i><FontAwesomeIcon icon={faCheck} /></i>
                                                        </span>
                                                    </span>
                                                    <div className="srn-main-main-tnc-agreement-label">
                                                        I agree to Central by Landers’&nbsp;
                                                        <a href="/terms-conditions" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">TERMS & CONDITIONS</strong></a>&nbsp;and&nbsp;
                                                        <a href="/privacy-policy" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">PRIVACY POLICY</strong></a>.
                                                    </div>                                        
                                                </label>
                                            </div>
                                        </div>
                                        <div className="checkout-payment-total-container" id="checkout-payment-total">
                                            <div className="srn-cart-total-container" style={{display: "block"}}>
                                                <div className="srn-cart-total-main-container">
                                                    <p className="srn-cart-total-label">{this.props.mode && this.props.mode == 'view' ? 'Total' : 'Total'} 
                                                    <span className="srn-cart-total-subtitle">&nbsp;(Vat included)</span></p>
                                                    <p className="srn-cart-total-value">₱ {parseFloat(this.state.grandTotal).toFixed(2)}</p>
                                                </div>
                                                <div className="srn-cart-total-savings">
                                                    <div className={`srn-cart-total-discount-container ${this.state.isMember === 'true' ? 'srn-cart-total-disabled' : ''}`}>
                                                        <p className="srn-cart-total-discount-label">Total Savings</p>
                                                        <p className="srn-cart-total-discount-value">₱ {this.state.totalSavings}</p>
                                                    </div>
                                                    {
                                                        this.state.isMember === 'true' ?
                                                        <div className={`srn-cart-total-discount-container srn-cart-total-savings-container d-none ${this.state.isMember === 'true' ? 'srn-cart-total-enabled' : ''}`}>
                                                            <p className="srn-cart-total-discount-label">Member's Savings</p>
                                                            <p className="srn-cart-total-discount-value">₱ {this.state.memberSavings}</p>
                                                        </div> :
                                                        <div className={`srn-cart-total-discount-container srn-cart-total-savings-container d-none guest checkout ${this.state.isMember === 'true' ? 'srn-cart-total-enabled' : ''}`}>
                                                            <img src={landersLogo} className="srn-landers-logo" />
                                                            <p className="srn-cart-total-discount-label">Be a Landers Member and Save</p>
                                                            <p className="srn-cart-total-discount-value">- ₱ {this.state.memberSavings}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                        this.state.isAgreementChecked == false &&
                                        <div className="terms-condition-indicator">
                                            <span>You must agree to our Terms & Conditions and Privacy Policy to proceed</span>
                                        </div>
                                        }
                                    <button
                                        className="btn btn-secondary btn-lg btn-block checkout-btn" id="checkout-btn-cod"
                                        onClick={() => this.createOrder()}
                                        disabled={this.state.isOrderLoading || !this.state.isAgreementChecked}
                                    >
                                        { this.state.isOrderLoading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> }
                                        PLACE ORDER
                                    </button>
                                </div>

                            </div>
                        </div>
                       
                    </div>
                    <div className={`col-md-5 mweb-class-total-checkout`}>
                        {/* <div id="srn-market-summary-container" className="srn-cart-component-container srn-cart-summary-container"></div>
                        <div id="srn-eats-summary-container" className="srn-cart-component-container srn-cart-summary-container"></div> */}
                        <div className="cartpositionfixed">
                            <div id="srn-cart-total-container" className="srn-cart-total-eats-container"></div>
                                <div className="srn-main-tnc-agreement-container-checkout">
                                    <div className="form-check form-check-inline srn-main-custom-checkbox">
                                        <label htmlFor="privPolCHK" className="srn-main-custom-checkbox-container">
                                            <span className="srn-main-tnc-agreement-checkbox">
                                                <input type="checkbox" id="privPolCHK" 
                                                required 
                                                checked={this.state.isAgreementChecked}
                                                onChange={(e) => this.handleAgreement(e)}
                                                onClick={(e) => this.checkoutDisable(e)}
                                                />
                                                <span className="srn-main-custom-checkbox-toggle">
                                                    <i><FontAwesomeIcon icon={faCheck} /></i>
                                                </span>
                                            </span>
                                            <div className="srn-main-main-tnc-agreement-label">
                                                I agree to Central by Landers’&nbsp;
                                                <a href="/terms-conditions" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">TERMS & CONDITIONS</strong></a>&nbsp;and&nbsp;
                                                <a href="/privacy-policy" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">PRIVACY POLICY</strong></a>.
                                            </div>
                                        </label>
                                    </div>
                                </div> 
                            {
                                this.state.isAgreementChecked == false &&
                                <div className="terms-condition-indicator">
                                    <span>You must agree to our Terms & Conditions and Privacy Policy to proceed</span>
                                </div>
                            }

                            {/* Third Party: Checkout GApp */}
                            <div id="checkout-gapp"></div>

                            {/* <div className="srn-cart-total-proceed-button" onClick={() => this.createOrder(this.state.paymentmode)}>
                                <button className="btn btn-primary" id="btn-checkout" disabled={this.state.isOrderLoading || !this.state.isAgreementChecked || this.state.isCartLoading}>
                                    { this.state.isOrderLoading || this.state.isCartLoading ? loadingDisplay() : 'PLACE ORDER' }
                                </button>
                            </div> */}
                            
                        </div>
                    </div>
                </div>

                {/* modal */}
                <div className="modal fade success-modal" id="track-order-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <img src={CentralMainLogo}/>
                                <button type="button" className="btn srn-ft-close-btn" data-dismiss="modal" aria-label="Close">
                                    <a href="#" className="close"/>
                                </button> 
                            </div>
                            <div className="modal-body">
                                <h6 className="checkout-header-order text-center mt-0">Order Placed Successfully!</h6>

                                <p className="checkout-paragraph">
                                    Your order is being processed. A confirmation email will be sent to you shortly.
                                </p>

                                <p className="checkout-paragraph-bold">
                                    You can track your order at the “Track Order” page using your tracking no.
                                </p>
                                
                                <p className="checkout-paragraph text-center font-weight-bold">
                                    {this.state.tracking_number}
                                </p>

                                {
                                    this.state.isClearCartLoading ? 
                                    <a href={`#`} className="btn btn-secondary btn-block checkout-track-order-btn">
                                        <FontAwesomeIcon icon={faSpinner} />
                                    </a> : 
                                    <a href={`/track-order?order-number=${this.state.tracking_number}`} className="btn btn-secondary btn-block checkout-track-order-btn">
                                        TRACK ORDER
                                    </a>

                                }

                                {/* <p className="checkout-paragraph text-center">
                                    You have earned 3 Reward Points
                                </p>

                                <button className="btn btn-block checkout-earn-point-btn">
                                    SHOP MORE TO EARN POINTS!
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" tabIndex="-1" role="dialog" id="checkout-creditcard" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xs">
                        <div className="modal-content srn-min-max-modal-content">
                            <div className="modal-body srn-min-max-modal-body">
                                <span className="close-credit" data-dismiss="modal">X</span>  
                                <h6 className="credit-card-title-modal">Card Details</h6>

                                <div className={`cmb-payment-option`}>
                                    <div className="payment-name-container">
                                        <h6 className={`${this.state.paymentMethod == 1 ? 'test' : ''}`}>Credit / Debit Card</h6>
                                        <span>All Banks Accepted • Powered by Maya</span>
                                    </div>
                                    <div className="cmb-payment-img-container"><img src={debitCard_colored} /></div>
                                </div>
                                
                                <form onSubmit={(event) => this.handlePaymentFormSubmit(event)} className={`${this.state.paymentMethod == 1 ? 'd-block' : 'd-none'} cc-payment-form srn-paymaya-form-container`}>
                                    <div className={`cmb-cc-container ${this.state.isLoading ? 'cmb-cc-container-loading' : ''}`}>
                                        <div className="cmb-cc-loading">
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        </div>
                                        <div className="srn-paymaya-form">
                                            <div className="form-group">
                                                <label className="srn-paymaya-form-label" for="name">Full Name <span>*</span></label>
                                                <input name="name" type="text"className={`form-control srn-paymaya-form-input ${this.state.nameError?.length > 0 ? 'has-error' : ''}`} id="name" placeholder="Enter Full Name" onChange={(e) => this.handleFieldChange(e)} />
                                                    {
                                                        this.state.nameError?.length > 0 &&
                                                        <div class="invalid-feedback">
                                                            {this.state.nameError}
                                                        </div>
                                                    }
                                            </div>
                                            <div className="form-group">
                                                <label className="srn-paymaya-form-label" for="number">Card Number <span>*</span></label>
                                                <input name="number" type="int" className={`form-control srn-paymaya-form-input ${this.state.numberError?.length > 0 ? 'has-error' : ''}`} id="number" placeholder="Enter Card Number..." onChange={(e) => this.handleFieldChange(e)} />
                                                    {
                                                        this.state.numberError?.length > 0 &&
                                                        <div class="invalid-feedback">
                                                            {this.state.numberError}
                                                        </div>
                                                    }
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <div class="form-row">
                                                        <div class="col">
                                                            <div className="form-group">
                                                                <label className="srn-paymaya-form-label" for="expMonth">Expiration Date <span>*</span></label>
                                                                <select name="expMonth" className={`custom-select srn-paymaya-form-input ${this.state.expMonthError?.length > 0 ? 'has-error' : ''}`} onChange={(e) => this.handleFieldChange(e)}>
                                                                    <option selected value="">MM</option>
                                                                    {
                                                                        this.state.months.map(month => (
                                                                            <option value={month}>{month}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {
                                                                    this.state.expMonthError?.length > 0 &&
                                                                    <div class="invalid-feedback">
                                                                        {this.state.expMonthError}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div className="form-group">
                                                                <label className="srn-paymaya-form-label" for="expYear">&nbsp;</label>
                                                                <select name="expYear" className={`custom-select srn-paymaya-form-input ${this.state.expYearError?.length > 0 ? 'has-error' : ''}`} onChange={(e) => this.handleFieldChange(e)}>
                                                                    <option selected value="">YYYY</option>
                                                                    {
                                                                        generateYearsArray()?.map(year => (
                                                                            <option value={year}>{year}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                {
                                                                    this.state.expYearError?.length > 0 &&
                                                                    <div class="invalid-feedback">
                                                                        {this.state.expYearError}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div class="form-group">
                                                        <label className="srn-paymaya-form-label" for="cvc">CVC <span>*</span></label>
                                                        <input name="cvc" type="text" max="999" className={`form-control srn-paymaya-form-input ${this.state.cvcError?.length > 0 ? 'has-error' : ''}`} id="cvc" placeholder="Enter Code..." onChange={(e) => this.handleFieldChange(e)} />
                                                        {
                                                            this.state.cvcError?.length > 0 &&
                                                            <div class="invalid-feedback">
                                                                {this.state.cvcError}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="srn-main-tnc-agreement-container-checkout">
                                        <div className="form-check form-check-inline srn-main-custom-checkbox">
                                            <label htmlFor="privPolCHK" className="srn-main-custom-checkbox-container">
                                                <span className="srn-main-tnc-agreement-checkbox">
                                                    <input type="checkbox" id="privPolCHK" 
                                                    required 
                                                    checked={this.state.isAgreementChecked}
                                                    onChange={(e) => this.handleAgreement(e)}
                                                    onClick={(e) => this.checkoutDisable(e)}
                                                    />
                                                    <span className="srn-main-custom-checkbox-toggle">
                                                        <i><FontAwesomeIcon icon={faCircle} /></i>
                                                    </span>
                                                </span>
                                                <div className="srn-main-main-tnc-agreement-label">
                                                    I agree to Central by Landers’&nbsp;
                                                    <a href="/terms-conditions" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">TERMS & CONDITIONS</strong></a>&nbsp;and&nbsp;
                                                    <a href="/privacy-policy" target="_blank" className="checkbox-link"><strong className="srn-main-tnc-agreement-text">PRIVACY POLICY</strong></a>.
                                                </div>
                                            </label>
                                        </div>
                                    </div>  */}

                                    {/* <button type="submit" class="btn btn-primary checkout-btn srn-paymaya-submit-btn" disabled={this.state.isLoading || !this.state.isAgreementChecked}>
                                        {this.state.isLoading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />}
                                        PAY
                                    </button> */}
                                    <div className="card-button-container col-md-12 d-flex">
                                        <button className="col-md-6 btn btn-primary checkout-btn srn-paymaya-canel-btn " data-dismiss="modal" id="card-form-valid">
                                            CANCEL
                                        </button>

                                        <button type="submit" className="col-md-6  btn btn-primary checkout-btn srn-paymaya-submit-btn">
                                            CONFIRM
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div> 

                <div className="modal srn-min-max-modal" id="srn-min-modal">
                    <div className="modal-dialog modal-dialog-centered modal-xs">
                        <div className="modal-content srn-min-max-modal-content">
                            <div className="modal-body srn-min-max-modal-body">
                                <h4 className="srn-min-max-modal-title">Below Minimum</h4>
                                <p className="srn-min-max-modal-description">Your order does not meet the <br/> minimum amount for delivery</p>
                                {
                                    this.state.cartsBelowMinimum?.map(item => (
                                        <Fragment>
                                            <p className="srn-min-max-modal-selected-cart">{item.brand.id == 1 ? 'Central Market' : item.brand.name}</p>
                                        </Fragment>
                                    ))
                                    // <p>{JSON.stringify(this.state.cartsBelowMinimum)}</p>
                                }
                            </div>
                            <div className="srn-min-max-modal-btn-container">
                                <button className="btn btn-default srn-min-max-modal-btn" data-dismiss="modal">ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal srn-min-max-modal" id="srn-max-modal">
                    <div className="modal-dialog modal-dialog-centered modal-lg srn-min-max-modal-dialog">
                        <div className="modal-content srn-min-max-modal-content">
                            <div className="modal-body srn-min-max-modal-body">
                                <h4 className="srn-min-max-modal-title">Above Maximum</h4>
                                <p className="srn-min-max-modal-description">Your order exceeds the maximum amount for delivery or pickup.</p>
                                {
                                    this.state.cartsAboveMaximum?.length > 0 &&
                                    <div className="srn-min-max-brand-details-container">
                                        <div className="srn-min-max-brand-container">
                                            {
                                                this.state.cartsAboveMaximum?.map(item => (
                                                    <div className="srn-min-max-brand-details">
                                                        <div className="srn-min-max-logo-details">
                                                            <div className="srn-min-max-logo-and-button">
                                                                <img alt={item.brand.name} className="srn-min-max-logo"src={item.brand.logo}/>
                                                            </div>
                                                            <p className="m-0 srn-min-max-minimum">Maximum for {item.fulfillmentType} ₱{item.maximum}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="srn-min-max-modal-btn-container d-flex">
                                <span className="srn-min-max-backtocart" data-dismiss="modal">Back to cart</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
   
    }
  
}


$(document).ready(function () {
    $("input.srn-crm-phonenumber").attr('maxlength', '10');    
});
