import {log} from '../../helper/helper'

let eats_app_id = process.env.REACT_APP_EATS_APP_ID

export const BrandsComponent = (props) => {

	const isPromoValid = (promo)  => {
		let isPromoValid = true
		let promoDate = promo.expiration_date
		let today = new Date();
		let promo_expiration = new Date(promoDate)

		if (promo_expiration.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
			return false
		}

		// log(promo.name, promo.store_ids)

		if (promo.store_ids && promo.store_ids.length > 0) {
			let addressesFromStorage = localStorage.getItem(`address_object_${eats_app_id}`) || null

			if (promo.store_ids[0] === "all") {
				return true
			}

			// log('got in', promo.store_ids)

			if (addressesFromStorage) {
				let branches = []
				let addresses = JSON.parse(addressesFromStorage)

				if (addresses && addresses.length > 0) {
					branches = addresses.map(x => x.branch_id.toString())
					// log({branches})
					// log(promo.store_ids)
					let doesBranchExist = promo.store_ids.some(item => branches.includes(item))
					
					return doesBranchExist
				}
				else {
					return true
				}
			}
		}
		else {
			return true
		}
		return isPromoValid
	}

	const handlePageRedirect = (brand) => {
		if (props.handlePageRedirect) {
			props.handlePageRedirect(brand)
		}
	}

	let brands = props.data || null

	return (
        <div className="srn-brand-list-v3">
            <div className="srn-brand-list-v3-container">
            	{
            		brands && brands.length > 0 ?
            		<>
            			{
            				brands.filter(x => x.status == 1).map(brand => (
			                    <div key={brand.id} className="srn-brand-list-v3-item" onClick={() => handlePageRedirect(brand)}>
			                        <div className="srn-brand-list-v3-item-image-container" style={{backgroundImage: `url('${brand.featured_image ? brand.featured_image : ''}')`}}>
			                            <div className="srn-brand-list-v3-logo-container">
			                            	{/*
			                            		brand.logo && brand.logo.length > 0 ?
			                            		<img className="srn-brand-list-v3-logo" src={brand.logo} /> : null 
			                            	*/}
			                            </div>
			                        </div>
			                        {
			                        	brand.featured_products && brand.featured_products.length > 0 ?
			                        	<div className="srn-brand-list-v3-featured-products-container">
			                        		{
			                        			brand.featured_products.map(product => (
				                        			<img key={product.id} src={product.image} className="srn-brand-list-v3-featured-product-item" title={product.name || ''} />
			                        			))
			                        		}
			                        	</div> : null
			                        }
			                        <p className="srn-brand-list-v3-brand-name">{brand.name}</p>
			                        <p className="srn-brand-list-v3-info">{brand.eta}</p>
			                        <div className="srn-brand-list-v3-promos-container">
			                        	{
			                        		brand.promos && brand.promos.length > 0 && 
			                        		brand.promos.filter(x => x.status == 1).map(promo => (
			                        			<p className={`srn-brand-list-v3-promo ${isPromoValid(promo) ? '': 'd-none'}`}>
			                        				{promo.name}
			                        			</p>
			                        		))
			                        	}
			                        </div>
			                    </div>
            				))
            			}                			
            		</> : null
            	}
            </div>
        </div>
	)
}