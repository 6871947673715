import React, { Component } from "react";
import emptyIcon from "../../assets/images/empty_icon.svg";
import { triggerSerinoEvent, log, getAccessToken } from '../../helper/helper'
export default class SearchPageComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			query: this.props.query ? this.props.query : '',
			activeTab: 'all',
			cart_items: this.props.cart_items ? this.props.cart_items : []
		}
	}

    componentDidUpdate(prevProps) {
        if (this.props.cart_items !== prevProps.cart_items) {
            this.setState({cart_items: this.props.cart_items})
        }
        if (this.props.query !== prevProps.query) {
        	triggerSerinoEvent(window.serino_components.SearchProductListComponent.subscriptions.triggerSearch('search-page'), this.props.query)
            this.setState({query: this.props.query})
        }
    }

	componentDidMount() {
		getAccessToken().then(access_token => {
			let searchProduct2 =
			window.serino_components.SearchProductListComponent.create({
				access_token: localStorage.getItem("access_token"),
				x_app_key: 1,
				categordId: 13,
				brand: this.props.brand,
				app_id: this.props.app,
				branch_id: 5,
				type: 3,
				mode: 0,
				emptyIcon: <img src={emptyIcon} />,
				styleType: 3,
				id: 'search-page',
			}, "srn-search-page-component-container" );
		})

		setTimeout(() => {
			triggerSerinoEvent(window.serino_components.SearchProductListComponent.subscriptions.triggerSearch('search-page'), this.state.query)
		}, 100)

	}
	render() {
		return (
	        <div className="srn-search-page-container" id="eatproductpage">
	            <div className="srn-search-page-products-container">
	            	<div id="srn-search-page-component-container"></div>
	            </div>
	        </div>
		)
	}
}

