import { SerinoComponent } from "../core";

export class AppSelector extends SerinoComponent {
    constructor(options, containerId) {
        super(options, containerId, 'appSelector')
    }
    
    setupEvents() {
        super.setupEvents()
        document.addEventListener(this.component.events.onClick, (e) => this.onAppSelect(e))
    }

    // events
    onAppSelect(event) {
    }

    selectApp(appId) {
        this.triggerComponentSubscription(this.component.subscriptions.selectApp, {
            appId: appId
        })
    }
}