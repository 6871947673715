import $ from 'jquery'
import { connect } from 'react-redux'
import { Component} from "react";
import { faMinus, faPlus, faAngleLeft, faAngleRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductList from '../../_core/products'
import Cart from '../../_core/cart'
import { log, triggerSerinoEvent, getAccessToken, handleExpiredToken, membershipChecker, getThumbnail, loadingDisplay, getAddressFromObjectChecker, getAddressObject, getSwalContent, mainAddressHandler } from '../../helper/helper'
import Swal from 'sweetalert2'
import { ModalComponent } from './ModalComponent'
import ProductListComponent from './ProductListComponent'

let productList = new ProductList()
let cartAPI = new Cart()

class ShoppingBasketComponent extends Component {
	constructor(props) {
		super(props) 
		this.state = {
			bundles: [],
			isMember: localStorage.getItem("isMember"),
			cart_items: this.props.cart_items ? this.props.cart_items : [],
			selectedBundle: {},
			products: [],
			cart_details: [],
			totalPrice: null,
			totalSavings: null,
			itemsToUpdate: [],
			itemsToDelete: [],
			productListComponent: {},
			isLoading: false,
			branch_id: this.props.branch_id ? this.props.branch_id : 6,
			isUpdateCartActive: false,
			isProductListLoading: false,
			filteredProducts: []
		}
	}

	componentDidUpdate(prevProps) {
        if (this.props.branch_id !== prevProps.branch_id) {
        	this.setState({
        		branch_id: this.props.branch_id
        	}, () => {
        		this.initializeBundles()
        	})
        }
        if (this.props.cart_items !== prevProps.cart_items) {
        	this.setState({
        		cart_items: this.props.cart_items
        	})
        }
    }

	async componentDidMount() {

		this.handleDragContent()

		this.initializeBundles()

		// this.handleBundleActions()
	}

	initializeBundles = (isInitial) => {

		getAccessToken().then(async access_token => {

			try {
		        const collectionListResult = await productList.getCollectionsByBranch(this.props.app_key, this.props.branch_id ? this.props.branch_id : 6)

		        let bundles = collectionListResult.data.items.filter(x => x.tags === 'Bundle' && x.name.charAt(0) !== ' ')

		        this.setState({bundles})

			}
			catch(err) {
				console.log(err);
				handleExpiredToken()
			}

		})
	}

	initializeCart = (products) => {		
		if (this.state.cart_items && this.state.cart_items.length > 0) {
			let cartItemSKUS = this.state.cart_items.map(x => parseInt(x.itemSKU))

			let productSKUS = this.state.selectedBundle.product_skus.split(',').map(id => parseInt(id))

			let existingItems = productSKUS.some(r=> cartItemSKUS.includes(r))


			if (existingItems) {
				this.setState({
					isUpdateCartActive: true
				})
	        	let cart_details = this.state.cart_items.map((item) => {
	        		return {
		        		product_details: null,
		        		payload : item,
		        		bundle_id: null
		        	}
	        	})
	        	this.handleUpdateCart(cart_details)
			}
			else {
				this.setState({
					isUpdateCartActive: false
				})
    			this.handleInitialItems(products)
			}

        }
        else {
			this.setState({
				isUpdateCartActive: false
			})
    		this.handleInitialItems(products)
        }
	}

	handleInitialItems = (products) => {
		if (products && products.length > 0) {

			let cart_items = this.state.cart_details

			for (const product of products) {
		    	let cart_item = {
		    		product_details: product,
		    		payload : this.setCartDetails(product, this.state.branch_id),
		    		bundle_id: this.state.selectedBundle.id
		    	}

		    	let cart_details = this.state.cart_details
		    	let existing_cart_items = this.state.cart_items

		    	cart_items = [...cart_items, cart_item]
			}

	    	cart_items = [...new Set(cart_items)];


	    	this.handleUpdateCart(cart_items)
		}
	}

	handleBundleClick = (item) => {

		this.setState({
			products: []
		})

		let app_id = this.props.app_key

    	let address_object = localStorage.getItem(`address_object_${app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${app_id}`)) : null

    	if (!address_object) {
    		localStorage.setItem('brand_id', 1)
    		mainAddressHandler(app_id)
    		return
    	}

    	let address = address_object.find(x => x.brand_id == 1)

    	if (!address) {
    		localStorage.setItem('brand_id', 1)
    		mainAddressHandler(app_id)
    		return
    	}

		// triggerSerinoEvent(window.serino_components.ProductListComponent.subscriptions.onCartItems, [])

		this.setState({
			selectedBundle: item,
			cart_details: [],
			itemsToUpdate: [],
			itemsToDelete: [],
			totalPrice: null,
			totalSavings: null,
		}, () => {
			this.getProducts(item)
		})

		$(`#basket_content_${this.props.id}`).modal("show")
	}

	getProducts = async (item) => {

		this.setState({isProductListLoading: true})

		let productsResult = await productList.getProductsByBundle(item.id)

		let data = productsResult.data?.data || null

		if (data) {

			let productIds = data.productIds

			if (productIds) {
				let product_reference = productIds.map(x => `&product_reference[]=${x}`).join('')

				if (product_reference) {
					let inventoryProductsResult = await productList.getInventoryProducts(product_reference, 6, 'id')


					if (inventoryProductsResult.data && inventoryProductsResult.data.data && inventoryProductsResult.data.data.items) {
						let products = inventoryProductsResult.data.data.items
				        let filtered = this.getFilteredProductsByStock(products)
				        let transformedProducts = this.handlePriceAdjustments(filtered)

						this.setState({
							products: transformedProducts,
							isProductListLoading: false
						}, () => {
							this.initializeCart(transformedProducts)
						})
					}

				}
			}

		}

	}
	handleDragContent = () => {
        document.addEventListener('DOMContentLoaded', function () {
            const ele = document.getElementById('srn-baskets-items');
            ele.style.cursor = 'grab';

            let pos = { top: 0, left: 0, x: 0, y: 0 };

            const mouseDownHandler = function (e) {
                ele.style.cursor = 'grabbing';
                ele.style.userSelect = 'none';

                pos = {
                    left: ele.scrollLeft,
                    top: ele.scrollTop,
                    // Get the current mouse position
                    x: e.clientX,
                    y: e.clientY,
                };

                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            };

            const mouseMoveHandler = function (e) {
                // How far the mouse has been moved
                const dx = e.clientX - pos.x;
                const dy = e.clientY - pos.y;

                // Scroll the element
                ele.scrollTop = pos.top - dy;
                ele.scrollLeft = pos.left - dx;
            };

            const mouseUpHandler = function () {
                ele.style.cursor = 'grab';
                ele.style.removeProperty('user-select');

                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            };

            // Attach the handler
            ele.addEventListener('mousedown', mouseDownHandler);
        });
	}

	hideBasketModal = () => {
		$(`#basket_content_${this.props.id}`).modal("hide")
	}

	getCart = (bundle) => {

		if (this.state.cart_items && this.state.cart_items.length > 0) {
			let cartIds = this.state.cart_items.map(x => parseInt(x.itemSKU))
			if (bundle.product_skus) {
				let productIds = bundle.product_skus.split(',').map(x => parseInt(x))
				let common = this.findCommonElement(cartIds, productIds)
				
				return common
			}
		}

	}

	findCommonElement = (array1, array2) => {
     
	    // Loop for array1
	    for(let i = 0; i < array1.length; i++) {
	         
	        // Loop for array2
	        for(let j = 0; j < array2.length; j++) {
	             
	            // Compare the element of each and
	            // every element from both of the
	            // arrays
	            if(array1[i] === array2[j]) {
	             
	                // Return if common element found
	                return true;
	            }
	        }
	    }
	     
	    // Return if no common element exist
	    return false;
	}

	setCartDetails = (product, branch_id) => {
        let price
        let price_object = {}
        
        let member = product.product_prices.find((p => {
            return "member" === p.name
        }))
        let nonmember = product.product_prices.find((p => {
            return "nonmember" === p.name
        }))

        if (membershipChecker()) {
            price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
            price_object = member
        } else {
            price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
            price_object = nonmember
        }

        let custom_data = []

        let savings = {
            name: 'savings',
            value: nonmember.price - member.price
        }

        custom_data.push(savings)
        custom_data.push(price_object)

        // SAVE ADDRESS IN CART START
        if (getAddressFromObjectChecker) {
            let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

            if (!address) {
                // triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
            }

            custom_data.push({
                name: 'address',
                value: address
            })
        }
        // SAVE ADDRESS IN CART END

        let payload = {
        	"id": product.id,
            "itemReferenceId": product.id,
            "itemReferenceType": product.type,
            "itemName": product.name,
            "itemSKU": product.sku,
            "itemImage": getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
            "itemDescription": product.description,
            "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
            "basePrice": nonmember.price,
            "price": price,
            "instructions": "",
            "domainReferenceId": branch_id,
            "domainReferenceType": "1",
            "item_type": product.sell_by_type ? product.sell_by_type : 0,
            "include_promo": true
        }

        if (custom_data && custom_data.length > 0) {
            payload.custom_data = JSON.stringify(custom_data)
        }

        return payload
	}

	handleUpdateQuantity = (detail) => {
		let productIds = this.state.selectedBundle.product_skus.split(',').map(id => parseInt(id))

    	let itemsToUpdate = this.state.itemsToUpdate

		let itemFromCartAPI = this.state.cart_items.find(y => y.itemReferenceId == detail.item.itemReferenceId)

		if (itemFromCartAPI && itemFromCartAPI.id) {
			itemsToUpdate.push(itemFromCartAPI)
			itemsToUpdate = [...new Set(itemsToUpdate)]
		}

    	let cart_items = this.state.cart_details.map(x => {
    		let doesitexist = productIds.includes(parseInt(x.payload.itemSKU))
    		if (x.payload.itemSKU == detail.item.itemSKU) {
    			x.payload.quantity = detail.quantity
    		}
    		return x
    	})
    	this.setState({
    		itemsToUpdate: itemsToUpdate
    	})
    	this.handleUpdateCart(cart_items)
	}

    handleBundleActions = async () => {

        let productListEvent = window.serino_components.ProductListComponent.events;
        let productListSubscription = window.serino_components.ProductListComponent.subscriptions;

        // add button
        document.addEventListener(productListEvent.onAddtoCart, (e) => {

        	let app_id = e.detail.product_details.application_id

        	let address_object = localStorage.getItem(`address_object_${app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${app_id}`)) : null

        	if (!address_object) {
        		localStorage.setItem('brand_id', e.detail.product_details.brand_id)
        		this.props.handleInitialAdd(e.detail.product_details)
        		return
        	}

        	let address = address_object.find(x => x.brand_id == e.detail.product_details.brand_id)

        	if (!address) {
        		localStorage.setItem('brand_id', e.detail.product_details.brand_id)
        		this.props.handleInitialAdd(e.detail.product_details)
        		return
        	}

        	// triggerSerinoEvent(productListSubscription.setButtonLoading, e.detail.product_details.id)
        	let cart_item = {
        		product_details: e.detail.product_details,
        		payload : this.setCartDetails(e.detail.product_details, address.branch_id),
        		bundle_id: this.state.selectedBundle.id
        	}

        	let cart_details = this.state.cart_details
        	let existing_cart_items = this.state.cart_items

        	let cart_items = [...this.state.cart_details, cart_item]
        	cart_items = [...new Set(cart_items)];

        	this.handleUpdateCart(cart_items)
        });

        // increment button
        document.addEventListener(productListEvent.onIncrementCart, (e) => {
        	this.handleUpdateQuantity(e.detail)
        });

        // decrement button
        document.addEventListener(productListEvent.onDecrementCart, (e) => {
			log('decrement details', e.detail)
			this.handleUpdateQuantity(e.detail)
        });

        // manual input on quantity
        document.addEventListener(productListEvent.updateQuantity, (e) => {
        	log('input details', e.detail)
			this.handleUpdateQuantity(e.detail)
		});

        // remove item
        document.addEventListener(productListEvent.onDeleteItem, async (e) => {
        	log('delete details', e.detail)
        	let cart_items = this.state.cart_details.filter(x => x.payload.itemSKU != e.detail.cart_item.itemSKU)

	    	let itemsToDelete = this.state.itemsToDelete

			let itemFromCartAPI = this.state.cart_items.find(y => y.itemSKU == e.detail.cart_item.itemSKU)

			if (itemFromCartAPI && itemFromCartAPI.id) {
				itemsToDelete.push(itemFromCartAPI)
				itemsToDelete = [...new Set(itemsToDelete)]

				let deleteCartItemConfirmation = await Swal.fire({
                    html: getSwalContent('Are you sure you want to remove this item from your cart?'),
                    confirmButtonText: 'YES',
                    showCancelButton: true,
                    cancelButtonText: 'CANCEL',
                    customClass: {
                        confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                        cancelButton: 'cmb-btn-primary-swal-checkoutcancel'                        
                    }
                })

                if (deleteCartItemConfirmation.isDismissed) {
                	this.handleUpdateCart(this.state.cart_details)
                    return
                }
			}

			let itemsToUpdate = this.state.itemsToUpdate.filter(x => x.itemSKU != itemFromCartAPI.itemSKU)

	    	this.setState({
	    		itemsToDelete: itemsToDelete,
	    		itemsToUpdate: itemsToUpdate
	    	})

    		this.handleUpdateCart(cart_items)
        });

        document.addEventListener(productListEvent.onSelectedProduct, (e) => {

        });

        document.addEventListener(productListEvent.onProductsLoad, (e) => {
        	this.setState({
        		isLoading: false
        	})
        	this.initializeCart(e.detail)
        });


    }

    handleConfirmUpdate = async () => {
    	if (this.state.isConfirmLoading) {
    		return
    	}

    	this.setState({
    		isConfirmLoading: true
    	})

    	let existingCartItems = this.state.cart_items
    	let cart_details = this.state.cart_details
    	let toUpdate = this.state.itemsToUpdate
    	let toDelete = this.state.itemsToDelete


    	let toAdd = cart_details.filter(x => x.product_details !== null)

    	let updated_cart_items = []

    	if (toAdd && toAdd.length > 0) {
	    	for (const product of toAdd) {
	    		let addCartItemResult = await cartAPI.addCartItem(localStorage.getItem('access_token'), this.props.app_key, product.payload)
	    		updated_cart_items = addCartItemResult
	    	}
    	}

    	if (toUpdate && toUpdate.length > 0) {
    		for (const itemToUpdate of toUpdate) {
    			let updateCartItemResult = await cartAPI.updateQuantity(localStorage.getItem('access_token'), this.props.app_key, itemToUpdate.id, {
	                quantity: itemToUpdate.quantity,
	                include_promo: true
            	})
    			updated_cart_items = updateCartItemResult
    		}
    	}

    	if (toDelete && toDelete.length > 0) {
    		for (const itemToDelete of toDelete) {
    			let deleteCartItemResult = await cartAPI.removeCartItem(localStorage.getItem('access_token'), this.props.app_key, itemToDelete.id)
    			updated_cart_items = deleteCartItemResult
    			if (this.props.handleDeleteItems) {
    				this.props.handleDeleteItems(itemToDelete.id)
    			}
    		}
    	}

    	if (updated_cart_items && updated_cart_items.items) {
	    	if (this.props.handleUpdateCart) {
	    		this.props.handleUpdateCart(updated_cart_items)
	    	}
    	}

    	this.setState({
    		isConfirmLoading: false
    	})

    	this.hideBasketModal()

    }

    handleUpdateCart = (cart_items) => {
    	// let productListSubscriptions = window.serino_components.ProductListComponent.subscriptions;
    	let productIds = this.state.selectedBundle && this.state.selectedBundle.product_skus ? this.state.selectedBundle.product_skus.split(',').map(x => parseInt(x)) : null

    	if (!productIds) {
    		return
    	}

    	let includedItems = cart_items.filter(item => {
    		if (productIds.includes(parseInt(item.payload.itemSKU))) {
    			return item
    		}
    	})

    	let totalPrice = 0
    	let totalSavings = 0

    	if (includedItems && includedItems.length > 0) {

	    	totalPrice = includedItems
	    		.map((item) => (item.payload.price * item.payload.quantity))
	    		.reduce((a, b) => a + b, 0).toFixed(2)

	    	let savingsPerItem = includedItems.map(item => {
	    		let customData = JSON.parse(item.payload.custom_data)
	    		if (customData) {
                	let savings = customData.find(x => x.name == 'savings')
                	if (savings) {
                        savings.value = savings.value * item.payload.quantity
                	}
                	return savings
	    		}
	    	})

	    	totalSavings = savingsPerItem.reduce((a, b) => +a + +b.value, 0).toFixed(2)


    	}

    	this.setState({
    		cart_details: cart_items,
    		filteredProducts: cart_items.map(x => x.payload),
    		totalPrice: totalPrice > 0 ? totalPrice : null,
    		totalSavings: totalSavings > 0 ? totalSavings : null,
    	})

    	if (this.props.handleUpdateCart) {
    		this.props.handleUpdateCart(false, cart_items)
    	}

    }

    getCartItems = (type) => {
    	switch (type) {
    		case 'toAdd':
		    	let toAdd = this.state.cart_details.filter(x => x.product_details !== null).reduce((a, b) => +a + +b.payload.quantity, 0)
		    	return toAdd
	    		break;
	    	case 'existing':
		    	
		    	let productIds = this.state.selectedBundle.product_skus.split(',').map(x => parseInt(x))

		    	let includedItems = this.state.cart_details.filter(item => {
		    		if (productIds.includes(parseInt(item.payload.itemSKU))) {
		    			return item
		    		}
		    	})

		    	let existingItems = includedItems.filter(x => x.product_details === null)
		    	return existingItems.length
	    		break;
	    	default:
	    		return null
	    		break
    	}
    }

    addToCart = (product) => {
    	let app_id = product.application_id

    	let address_object = localStorage.getItem(`address_object_${app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${app_id}`)) : null

    	if (!address_object) {
    		localStorage.setItem('brand_id', product.brand_id)
    		mainAddressHandler()
    		return
    	}

    	let address = address_object.find(x => x.brand_id == product.brand_id)

    	if (!address) {
    		localStorage.setItem('brand_id', product.brand_id)
    		mainAddressHandler()
    		return
    	}

    	let cart_item = {
    		product_details: product,
    		payload : this.setCartDetails(product, address.branch_id),
    		bundle_id: this.state.selectedBundle.id
    	}

    	let cart_details = this.state.cart_details
    	let existing_cart_items = this.state.cart_items

    	let cart_items = [...this.state.cart_details, cart_item]
    	cart_items = [...new Set(cart_items)];

    	this.handleUpdateCart(cart_items)
    }

    updateCart = (cart_item) => {

		let productIds = this.state.selectedBundle.product_skus.split(',').map(id => parseInt(id))

    	let itemsToUpdate = this.state.itemsToUpdate

		let itemFromCartAPI = this.state.cart_items.find(y => y.itemReferenceId == cart_item.itemReferenceId)

		if (itemFromCartAPI && itemFromCartAPI.id) {
			itemsToUpdate.push(itemFromCartAPI)
			itemsToUpdate = [...new Set(itemsToUpdate)]
		}

    	let cart_items = this.state.cart_details.map(x => {
    		let doesitexist = productIds.includes(parseInt(x.payload.itemSKU))
    		if (x.payload.itemSKU == cart_item.itemSKU) {
    			x.payload.quantity = cart_item.quantity
    		}
    		return x
    	})
    	this.setState({
    		itemsToUpdate: itemsToUpdate
    	})
    	this.handleUpdateCart(cart_items)
    }


    deleteCart = async (cart_item) => {
    	let cart_items = this.state.cart_details.filter(x => x.payload.itemSKU != cart_item.itemSKU)

    	let itemsToDelete = this.state.itemsToDelete

		let itemFromCartAPI = this.state.cart_items.find(y => y.itemSKU == cart_item.itemSKU)

		if (itemFromCartAPI && itemFromCartAPI.id) {
			itemsToDelete.push(itemFromCartAPI)
			itemsToDelete = [...new Set(itemsToDelete)]

			let deleteCartItemConfirmation = await Swal.fire({
                html: getSwalContent('Are you sure you want to remove this item from your cart?'),
                confirmButtonText: 'YES',
                showCancelButton: true,
                cancelButtonText: 'CANCEL',
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
                    cancelButton: 'cmb-btn-primary-swal-checkoutcancel'                        
                }
            })

            if (deleteCartItemConfirmation.isDismissed) {
            	this.handleUpdateCart(this.state.cart_details)
                return
            }
		}

		let itemsToUpdate = this.state.itemsToUpdate.filter(x => x.itemSKU != itemFromCartAPI.itemSKU)

    	this.setState({
    		itemsToDelete: itemsToDelete,
    		itemsToUpdate: itemsToUpdate
    	})

		this.handleUpdateCart(cart_items)
    }

    handlePriceAdjustments = (products) => {
        let mutated = products.map(product => {
            
            if (product.sell_by_type == '1') {

                let newProductPrices = product.product_prices.map(price_item => {

                    let price = parseFloat(price_item.price) * parseFloat(product.weight)
                    let original_price = parseFloat(price_item.original_price) * parseFloat(product.weight)
                    
                    let price_rounded = Math.round(price.toFixed(3) * 100) / 100
                    let original_price_rounded = Math.round(original_price.toFixed(3) * 100) / 100

                    price_item.price = price_rounded
                    price_item.original_price = original_price_rounded

                    return price_item
                })

                product.product_prices = newProductPrices
            }

            return product
        })

        return mutated
    }

    getFilteredProductsByStock = (products) => {

        let filteredByStocks = products.filter(product => product.inventory && product.inventory.length > 0 && product.inventory[0].stock && product.inventory[0].stock.length > 0 && product.inventory[0]?.threshold && product.inventory[0]?.threshold[0]?.value)

        filteredByStocks = filteredByStocks.filter(product => parseInt(product.inventory[0]?.stock) > parseInt(product.inventory[0]?.threshold[0]?.value))

        return filteredByStocks
    }

	render() {
		return(
			<div className="srn-baskets-root">
				<div className={`srn-baskets-container ${this.props.className}`}>
					<div className="srn-baskets-items" id="srn-baskets-items">
						{
							this.state.bundles.map((item,i) => (
								<div className="srn-basket-item" key={item.key}>
									<div className="srn-basket-item-img-container">
										{
											item.images && 
											<img className="srn-basket-item-img" src={item.images}/>
										}
									</div>
									<div className="srn-basket-details-container">
										<p className="srn-basket-item-name" title={item.name || ''}>{item.name || <span>&nbsp;</span>}</p>
										<p className="srn-basket-item-description">{item.short_description || <span>&nbsp;</span>}</p>
										<div className="srn-basket-bottom-section">
											<p className="srn-basket-total">₱ {localStorage.getItem('isMember') ? (item.total_member_price ? parseFloat(item.total_member_price).toFixed(2) : '') : (item.total_nonmember_price ? parseFloat(item.total_nonmember_price).toFixed(2) : '')}&nbsp;</p>
											<button className={`btn srn-basket-add-btn ${this.getCart(item) ? 'bundle-edit' : ''}`} onClick={() => this.handleBundleClick(item)}>
												{this.getCart(item) ? 'EDIT' : 'ADD'}
											</button>
										</div>
									</div>
								</div>
							))
						}
					</div>
				</div>
				<ModalComponent id={`basket_content_${this.props.id}`} className="basket_content_container">
					<div className={`srn-baskets-modal-loading-container ${this.state.isLoading ? '' : 'd-none'}`}>
						{loadingDisplay('dark')}
					</div>
					<div className={`srn-baskets-modal-content-container ${this.state.isLoading ? 'd-none' : ''}`}>
						<span className="basket-close" onClick={() => this.hideBasketModal()}>
							<FontAwesomeIcon icon={faTimes} />
						</span>
						<div className="srn-baskets-modal-content-title">
							<h6 className="srn-title-v4">{this.state.selectedBundle && this.state.selectedBundle.name || '' }</h6>
						</div>
						<div className="srn-product-comp-container">
							<div id={`srn-bundle-products-container-${this.props.id}`} className="d-none"></div>
							<ProductListComponent
								id="sb-product-list"
								branch_id={this.state.branch_id}
								products={this.state.products}
								cart_items={this.state.filteredProducts}
								add={this.addToCart}
								update={this.updateCart}
								delete={this.deleteCart}
								type="bundle"
								isLoading={this.state.isProductListLoading}
							/>
							<div className="srn-baskets-modal-bottom-container">
								<div className="srn-baskets-modal-price-container">
									{
										this.state.selectedBundle &&
										<>
											<p className="srn-baskets-modal-price"> {this.state.totalPrice && this.state.totalPrice > 0 ? `₱ ${this.state.totalPrice}` : ''}</p>
											{
												this.state.totalSavings && this.state.isMember && this.state.isMember === true &&
												<p className="srn-baskets-modal-discount">Saved ₱ {this.state.totalSavings}</p>
											}
										</>
									}
								</div>
								<button className="btn srn-baskets-modal-bottom-button" onClick={() => this.handleConfirmUpdate()} disabled={this.state.isConfirmLoading || (this.getCartItems('toAdd') < 1 && this.state.itemsToUpdate.length < 1 && this.state.itemsToDelete.length < 1)}>
									{
										this.state.isConfirmLoading ? loadingDisplay() : 
										<>
											{
												this.getCartItems('toAdd') > 0 && !this.state.isUpdateCartActive ?
												`Add ${this.getCartItems('toAdd') || ''} item${this.getCartItems('toAdd') > 1 ? 's' : ''}`:
												'UPDATE CART'
											}
										</>
									}
								</button>
							</div>
						</div>
					</div>
				</ModalComponent>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
    return {
        cart_items: state.cart.cart_items
    };
}

export default connect(mapStateToProps)(ShoppingBasketComponent);