import React, { Component, Fragment } from 'react'
import { debounce } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSpinner } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { log } from '../../../helper/helper'

const x_app_key = process.env.REACT_APP_EATS_APP_ID

export default class BrandListComponent extends Component {
	constructor(props) {
		super(props)
		this.state = {
            categories: [],
            favorites: [],
            brands: this.props.brands ? this.props.brands : []
		}
	}

    componentDidUpdate(prevProps) {        
        if (this.props.brands !== prevProps.brands) {
        	log(this.props.brands)
            this.setState({brands: this.props.brands})
        }
    }

    handleMouseOver = debounce((brand) => {
        this.setState({
            isSubcategoriesLoading: true
        })
        this.getChildCategories(brand)
    }, 500)

    getChildCategories = async (brand) => {
        this.setState({
            categories: [],
            favorites: [],
        })

        let favorites = await this.getFavoriteCategoryList(brand.id)
        let categories = await this.getAllCategoriesList(brand.id)

        // console.log(favorites.data.items)
        // console.log(categories.data.items)
        let category_item = categories.data.items.find(x => x.name === brand.name)

        this.setState({
            favorites: favorites.data.items,
            categories: category_item.children,
            isSubcategoriesLoading: false
        })
    }

    getFavoriteCategoryList = (brand_id) => {
        
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        return axios.get(`${process.env.REACT_APP_SERINO_API}/products/public/pcat-collection-items-by-brand/${brand_id}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'X-APP-KEY': x_app_key 
                },
                cancelToken: source.token
            },
        )
        source.cancel('Operation canceled by the user.');
    }

    getAllCategoriesList = (brand_id) => {
        
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        return axios.get(`${process.env.REACT_APP_SERINO_API}/products/public/categories/tree?branch_id=`,
            {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'X-APP-KEY': x_app_key 
                },
                cancelToken: source.token
            },
        )
        source.cancel('Operation canceled by the user.');
        
    }

    handleMouseOut = () => {
        this.setState({isSubcategoriesLoading: false, favorites: [], categories: []})
    }

    handleGotoProductsFromCategory = (brand, category, item) => {
        log('handleGotoProductsFromCategory fired')
        var str = brand.name
        str = str.replace(/\s+/g, '-').toLowerCase()
        localStorage.setItem('brand_logo', brand.logo)

        localStorage.removeItem('branch_id')
        localStorage.removeItem('Address')
        localStorage.removeItem('brand_name')
        localStorage.removeItem('brand_best_seller')
        localStorage.removeItem('fulfillment_type_status')
        localStorage.setItem('brand_name', str+'-brand')
        localStorage.setItem('brand_name_str', brand.name)
        localStorage.setItem('brand_best_seller', brand.name.toLowerCase())
        localStorage.setItem('brand_id', brand.id.toString())
        sessionStorage.setItem('viewAction', 'brandSelect')
        sessionStorage.removeItem('foodType')

        localStorage.setItem("categ_id",category)
        localStorage.setItem("categ_type",item)
        localStorage.setItem("from_side_categ", true)
        
        setTimeout(() => {
        	if(this.props.handleGotoProducts(category)) {
        		this.props.handleGotoProducts(category)
        	}
        }, 300)
    }

    handleGotoBrand = (brand) => {
        this.props.handleGotoBrand({brand})
    }

	render() {
		return(			
            <div id={`${this.props.id}`} className="side-category-list eats-side-category">
                <div className="category-list-container">
                    <div className="row cmb-category">
                        <div className="cmb-category-markets">
                            <ul className="cmb-categories-list-markets">
                                {
                                    this.state.brands?.map((brand, brand_key)=> (
                                        <li
                                            className="cmb-collection-sub-items" key={brand_key}
                                            // onMouseOut={() => this.handleMouseOut()}
                                            onMouseEnter={() => this.handleMouseOver(brand)}
                                            onClick={() => this.handleGotoBrand(brand)}
                                            >
                                            <div className="label-icon">
                                                <a id="#!" className="category-name" onClick={() => this.handleGotoBrand(brand)}>
                                                    {brand.name}
                                                </a>
                                                <FontAwesomeIcon icon={faAngleDown} className="categ-caret" />
                                            </div>
                                            <ul className="cmb-category-items-content sub-category-lvl-1 srn-eats-category-menu">
                                                {
                                                    this.state.isSubcategoriesLoading &&
                                                    <li className="cmb-sub-category-items">
                                                        <a className="sub-category-name">
                                                            <FontAwesomeIcon icon={faSpinner} spin />
                                                        </a>
                                                    </li>
                                                }
                                                <Fragment>                                                                            
                                                    {
                                                        this.state.favorites?.map((item, cat_key) => (
                                                            <li className="cmb-sub-category-items" key={cat_key} onClick={() => this.handleGotoProductsFromCategory(brand,item.id,(item, "collection"))}>
                                                                <a className="sub-category-name">{item.name}</a>
                                                            </li>
                                                        ))
                                                    }
                                                    {
                                                        this.state.categories?.map((item, cat_key) => (
                                                            <li className="cmb-sub-category-items" key={cat_key} onClick={() => this.handleGotoProductsFromCategory(brand,item.id,(item, "category"))}>
                                                                <a className="sub-category-name">{item.name}</a>
                                                            </li>
                                                        ))
                                                    }                                                                   
                                                </Fragment>
                                            </ul>
                                        </li>
                                    ))

                                }
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
		)
	}
}