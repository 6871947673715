import axios from 'axios'
import moment from 'moment-timezone'
import $ from 'jquery';
import Cart from '../_core/cart'
import FulfillmentAPI from '../_core/branch'
import LoginApi from "../_core/login";
import ProductList from "../_core/products";

import Swal from 'sweetalert2'
import {
  addToCartAnalytics,
  addToCartHub,
  updateCartAnalytics,
  updateCartHub,
} from "./analytics";
import CoreAPI from '../_core/core';
import { DEV_BASE_URL, LOCAL_BASE_URL, PAYMENT_CONFIG, PROD_BASE_URL, QA_BASE_URL, UAT_BASE_URL } from '../config';
import { fbqEvent } from './fbpixel';

let IDMS_URL = process.env.REACT_APP_IDMS_URL
let TENANT_ID = process.env.REACT_APP_TENANT_ID

let DataHubKey = process.env.REACT_APP_DATA_HUB_KEY;
const DataHub = new window.DataHub(DataHubKey)

const app_env = process.env.REACT_APP_ENV

let market_app_key = process.env.REACT_APP_MARKET_APP_ID
let eats_app_key = process.env.REACT_APP_EATS_APP_ID

var cart = new Cart()
var loginAPI = new LoginApi()

export const getAccessToken = () => {
    let access_token = localStorage.getItem('access_token');

    let access_token_promise = new Promise(resolve => {
        if (access_token) {
            resolve(access_token)
        } else {
            // guest token
            const url = IDMS_URL + '/auth/login';
            const tenant_id = TENANT_ID

            axios.post(url, {
                tenant_id: tenant_id
            }).then((response) => {
                localStorage.setItem('login_payload', JSON.stringify({
                    tenant_id: tenant_id
                }))
                localStorage.setItem('access_token', response.data.data.AccessToken)
            }).catch((error) => {

            }).finally(() => {
                access_token = localStorage.getItem('access_token')
                resolve(access_token)
            })
        }
    })

    return access_token_promise
}

export const getTemporaryToken = () => {
    let access_token = localStorage.getItem('category_access_token');

    let access_token_promise = new Promise(resolve => {
        if (access_token) {
            resolve(access_token)
        } else {
            // guest token
            const url = IDMS_URL + '/auth/login';
            const tenant_id = TENANT_ID

            axios.post(url, {
                tenant_id: tenant_id
            }).then((response) => {
                access_token = response.data.data.AccessToken
                localStorage.setItem('category_access_token', response.data.data.AccessToken)
                resolve(access_token)
            }).catch((error) => {

            })
        }
    })

    return access_token_promise
}

export const getCrossDomainToken = () => {
    let access_token = localStorage.getItem('access_token');

    let access_token_promise = new Promise(resolve => {
        if (access_token) {
            resolve(access_token)
        } else {
            // guest token
            const url = IDMS_URL + '/auth/login';
            const tenant_id = TENANT_ID

            axios.post(url, {
                tenant_id: tenant_id
            }).then((response) => {
                localStorage.setItem('login_payload', JSON.stringify({
                    tenant_id: tenant_id
                }))
                localStorage.setItem('access_token', response.data.data.AccessToken)
            }).catch((error) => {

            }).finally(() => {
                access_token = localStorage.getItem('access_token')
                resolve(access_token)
            })
        }
    })

    return access_token_promise
}

export const getUrl = (domain) => {

    let url;

    if (domain == 'main') {        
        switch(app_env) {
            case 'qa':
                url = QA_BASE_URL
                break;
            case 'dev':
                url = DEV_BASE_URL
                break;
            case 'prod':
                url = PROD_BASE_URL
                break;
            case 'uat':
                url = UAT_BASE_URL
                break;
            case 'local':
                url = LOCAL_BASE_URL
                break;
            default:
                break;
        }
    }
    else {
        switch(app_env) {
            case 'qa':
                url = `${QA_BASE_URL}/${domain}`
                break;
            case 'dev':
                url = `${DEV_BASE_URL}/${domain}`
                break;
            case 'uat':
                url = `${UAT_BASE_URL}/${domain}`
                break;
            case 'prod':
                url = `${PROD_BASE_URL}/${domain}`
                break;
            case 'local':
                url = `${LOCAL_BASE_URL}/${domain}`
                break;
            default:
                break;
        }
    }
    return url
}

export const validateAvailability = (branches, current_brand_name, brandId, currentDate, customerAddress) => {
    let proceedFlag = false;
    // console.log('branches', branches)
    // console.log('brandId', brandId)
    // console.log('currentDate', currentDate)
    // console.log('customerAddress', customerAddress)
    if (!branches) {
        // let customerAddress = $('#react-google-places-autocomplete-input').val()
        if (customerAddress === "") {
            var params = {
                module: "analytics",
                sub: "customer-behavior",
                data: {
                    action: "pageVisit",
                    brand_name: localStorage.getItem('cap_brand_name'),
                    longitude: "--",
                    latitude: "--",
                    customer_address: "--",
                    result: "not-serviceable",
                    page: "home"
                }
            }
            
            DataHub.publish(params)
        } else {
            var params = {
                module: "analytics",
                sub: "customer-behavior",
                data: {
                    action: "pageVisit",
                    brand_name: localStorage.getItem('cap_brand_name'),
                    longitude: localStorage.getItem("longitude"),
                    latitude: localStorage.getItem("latitude"),
                    customer_address: customerAddress,
                    result: "not-serviceable",
                    page: "home"
                }
            }
            
            DataHub.publish(params)
        }

        return {
            success: proceedFlag,
            errorCode: 0,
            errorMessage: `Sorry, we can’t reach you yet. Please check back soon!`,
            hasLogo: true
        }
    }
    else {
        for (var i = 0; i < branches.length; i++) {
            let currentBranch = branches[i];
            if (currentBranch.brand_id == brandId) {

                // check if inside operating hours
                // get time now
                console.log()
                if (currentBranch.status == 1) {
                    
                    
                    
                    localStorage.setItem('closing_time', currentBranch.closing_time)
                    localStorage.setItem('opening_time', currentBranch.opening_time)
                    // let timeNow = new Date(currentDate).toLocaleTimeString("en-US", {timeZone: "Asia/Singapore"});
                    let timeNow = moment(currentDate).tz('Asia/Singapore').format('HH:mm:ss');
                    
                    if (timeNow >= currentBranch.opening_time && timeNow <= currentBranch.closing_time) {

                        // inside operating hours
                        if (currentBranch.availability == "Ready" || currentBranch.availability == "Slow Down") {
                            var params = {
                                module: "analytics",
                                sub: "customer-behavior",
                                data: {
                                    action: "pageVisit",
                                    brand_name: localStorage.getItem('cap_brand_name'),
                                    store_id: currentBranch.id,
                                    store_name: currentBranch.name,
                                    longitude: localStorage.getItem("longitude"),
                                    latitude: localStorage.getItem("latitude"),
                                    customer_address: customerAddress,
                                    result: "successful ",
                                    page: "home"
                                }
                            }
                            
                            DataHub.publish(params)

                            proceedFlag = true;

                            if (localStorage.getItem('fulfillmentType') == 2) {
                                proceedFlag = true;
                            }
                            return {
                                success: proceedFlag,
                                branch: currentBranch
                            }
                        }
                        else {
                            var params = {
                                module: "analytics",
                                sub: "customer-behavior",
                                data: {
                                    action: "pageVisit",
                                    brand_name: localStorage.getItem('cap_brand_name'),
                                    store_id: currentBranch.id,
                                    store_name: currentBranch.name,
                                    longitude: localStorage.getItem("longitude"),
                                    latitude: localStorage.getItem("latitude"),
                                    customer_address: customerAddress,
                                    result: "store-busy",
                                    page: "home"
                                }
                            }
                            
                            DataHub.publish(params)

                            if (localStorage.getItem('fulfillmentType') == 2) {
                                proceedFlag = true;
                            }

                            // error
                            // branch is unavailable
                            return {
                                success: proceedFlag,
                                errorCode: 1,
                                errorMessage: `The ${currentBranch.brand_name} store serving your area is currently experiencing high volume of orders. Please try again after 15 minutes. Thank you for your patronage.`,
                                hasLogo: true,
                                branch: currentBranch
                            }
                        }
                    }
                    else {
                        var params = {
                            module: "analytics",
                            sub: "customer-behavior",
                            data: {
                                action: "pageVisit",
                                brand_name: localStorage.getItem('cap_brand_name'),
                                store_id: currentBranch.id,
                                store_name: currentBranch.name,
                                longitude: localStorage.getItem("longitude"),
                                latitude: localStorage.getItem("latitude"),
                                customer_address: customerAddress,
                                result: "store-closed",
                                page: "home"
                            }
                        }
                        
                        DataHub.publish(params)


                        // error
                        // cannot order outside operating hours
                        let open = currentBranch['week_sched']['open'];
                        let close = currentBranch['week_sched']['close'];
                        let operating_hours_error_message = `The ${currentBranch.brand_name} store serving your area is unavailable.`;
                        if (open.length > 0) {
                            operating_hours_error_message += ` We are open on ${open.join(', ')}.`;
                        }

                        if (close.length > 0) {
                            operating_hours_error_message += ` We are closed on ${close.join(', ')}.`;
                        }

                        if (localStorage.getItem('fulfillmentType') == 2) {
                            proceedFlag = true;
                        }

                        return {
                            hasLogo: true,
                            success: proceedFlag,
                            errorCode: 2,
                            // errorMessage: `The ${currentBranch.brand_name} store serving your area is currently unavailable. ${currentBranch.brand_name} operating hours is ${currentBranch.opening_time} to ${currentBranch.closing_time}.`
                            errorMessage: operating_hours_error_message,
                            branch: currentBranch
                        }
                    }
                }
                else {
                    var params = {
                        module: "analytics",
                        sub: "customer-behavior",
                        data: {
                            action: "pageVisit",
                            brand_name: localStorage.getItem('cap_brand_name'),
                            store_id: currentBranch.id,
                            store_name: currentBranch.name,
                            longitude: localStorage.getItem("longitude"),
                            latitude: localStorage.getItem("latitude"),
                            customer_address: customerAddress,
                            result: "store-inactive",
                            page: "home"
                        }
                    }
                    
                    DataHub.publish(params)

                    if (localStorage.getItem('fulfillmentType') == 2) {
                        proceedFlag = true;
                    }

                    return {
                        success: proceedFlag,
                        errorCode: 3,
                        errorMessage: `The ${currentBranch.brand_name} store serving your area is currently unavailable. Please try our other brands. Thank you for your patronage.`,
                        branch: currentBranch
                    }
                }


                break;
            }

            if (i == branches.length - 1 && !proceedFlag) {
                if (customerAddress === "") {
                    var params = {
                        module: "analytics",
                        sub: "customer-behavior",
                        data: {
                            action: "pageVisit",
                            brand_name: localStorage.getItem('cap_brand_name'),
                            longitude: "--",
                            latitude: "--",
                            customer_address: "--",
                            result: "not-serviceable",
                            page: "home"
                        }
                    }
                    
                    DataHub.publish(params)
                } else {
                    var params = {
                        module: "analytics",
                        sub: "customer-behavior",
                        data: {
                            action: "pageVisit",
                            brand_name: localStorage.getItem('cap_brand_name'),
                            longitude: localStorage.getItem("longitude"),
                            latitude: localStorage.getItem("latitude"),
                            customer_address: customerAddress,
                            result: "not-serviceable",
                            page: "home"
                        }
                    }
                    
                    DataHub.publish(params)
                }

                return {
                    success: proceedFlag,
                    errorCode: 0,
                    errorMessage: `Sorry, we can’t reach you yet. Please check back soon!`,
                    hasLogo: true
                }
            }
        }
    }
};

export const GetFeatureFlags = (flag) => {
    return new Promise(resolve => {
        $.ajax({
            url: process.env.REACT_APP_SERINO_API + '/server/public/active-feature-flags',
            method: 'GET',
            accept: 'application/json',
            contentType: 'application/json',
            dataType: 'json'
        }).done((data) => {
            resolve(data.result)
        })
    })
}


export const triggerSerinoEvent = (eventId, detail) => {
    let event = new CustomEvent(eventId, {
        detail: detail,
        bubbles: true,
        composed: true
    })
    document.dispatchEvent(event);
}

export const log = (message, object) => {
    if (app_env === 'local') {
        if (object) {
            console.log(message, object)
        }
        else {
            console.log(message)
        }
    }
}

export const setCartCount = (access_token) => {
    let totalCartCount = new Promise(resolve => {
        const cart = new Cart()
        let marketCart = 0
        let eatsCart = 0
        let cartCount = 0
        cart.getCartItems(access_token, process.env.REACT_APP_MARKET_APP_ID).then(res => {
            let non_weighted = res.data.filter(x => x.item_type == 1).reduce((a, b) => +a + +b.unit_quantity, 0)
            let weighted = res.data.filter(x => x.item_type == 0).reduce((a, b) => +a + +b.quantity, 0)
            marketCart = non_weighted + weighted
            cart.getCartItems(access_token, process.env.REACT_APP_EATS_APP_ID).then(res => {                
                eatsCart = res.data.reduce((a, b) => +a + +b.quantity, 0)
                cartCount = marketCart + eatsCart
                $('.srn-cart-quantity').text(cartCount)
                resolve(cartCount)
            })
        })
    })
    return (totalCartCount)
}

export const getCrossCart = (access_token) => {
    if (access_token != null || access_token != undefined) {

        let crossCartPromise = new Promise(resolve => {
            const cart = new Cart()
            let marketCart = []
            let eatsCart = []
            let eatsCartCount = 0
            let marketCartCount = 0
            let crossCart = []
            let cartCount = 0
            cart.getCartItems(access_token, process.env.REACT_APP_MARKET_APP_ID).then(res => {
                
                marketCart = res.data
                let non_weighted = res.data.filter(x => x.item_type == 1).reduce((a, b) => +a + +b.unit_quantity, 0)
                let weighted = res.data.filter(x => x.item_type == 0).reduce((a, b) => +a + +b.quantity, 0)
                marketCartCount = non_weighted + weighted
                cart.getCartItems(access_token, process.env.REACT_APP_EATS_APP_ID).then(res => {                
                    eatsCart = res.data
                    eatsCartCount = res.data.reduce((a, b) => +a + +b.quantity, 0)
                    crossCart = eatsCart.concat(marketCart)
                    cartCount = marketCartCount + eatsCartCount
                    localStorage.setItem('market_cart_count', marketCartCount)
                    localStorage.setItem('eats_cart_count', eatsCartCount)
                    $('.srn-cart-quantity').text(cartCount)
                    resolve(crossCart)
                })
                .catch(err => {
                    if (err.responseJSON.message == 'Invalid Access Token') {
                        handleExpiredToken(2)
                    }
                })

            }).catch(err => {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken(2)
                }
            })
        })
        return (crossCartPromise)
    }
    
}

export const setCrossStorage = (key, value, domain) => {    
    
    // console.log(domain)

    // if (domain == 'sub') {
    //     guest.set(key, value, function(error, data) {
    //         console.log(data)
    //         console.log(error)
    //     });
    // }
    // else {        
        localStorage.setItem(key, value)
    // }
}

export const getCrossStorage = async (key, domain) => {
    let localStoragePromise = new Promise(resolve => {
        // if (domain == 'sub') {
        //     guest.get(key, function(error, value) {
        //         resolve(value)
        //     });
        // }
        // else {
            resolve(localStorage.getItem(key))
        // }
    })
    return localStoragePromise
}

export const getCrossStorageV2 = async (key, domain) => {
    let localStoragePromise = new Promise(resolve => {
        // if (domain == 'sub') {
        //     guest.get(key, function(error, value) {
        //         resolve(value)
        //     });
        // }
        // else {
            resolve(localStorage.getItem(key))
        // }
    })
    return localStoragePromise
}

export const removeCrossStorage = async (key, domain) => {
    log('key', key)
    log('domain', domain)
    let localStoragePromise = new Promise(resolve => {



        // if (domain == 'sub') {
        //     guest.remove(key, function(error, value) {
        //         resolve(value)
        //     });
        // }
        // else {
            resolve(localStorage.removeItem(key))
        // }
    })
    return localStoragePromise
}

export const getDomain = () => {
    var host = window.location.host
    var parts = host.split('.')
    let domain = (parts[0] == 'eats') || (parts[0] == 'market') ? 'sub' : 'main'
    return domain

}

export const getSwalContent = (message, title, message2) => {
    return ` <h5 class="srn-new-swal-title">${title ? title : ''}</h5> <div class="srn-new-swal-description">${message ? message : ''} ${message2 ? '<br/><br/>' + message2 : ''}</div>`
}

// Temporary fix for Landers Central Brand ID
export const handleLandersBrandId = (id, app_key) => {
    if (id == 1 && app_key == eats_app_key) {
        return 7
    }
    else {
        return id
    }
}

// get app token
export const getAppToken = (appId, user) => {
    try {
        // let decode_buff = new Buffer(secret, 'base64');
        const encoded_apps = process.env.REACT_APP_APPS
        const decodeBuffer = Buffer.from(encoded_apps, 'base64')
        const apps = JSON.parse(decodeBuffer.toString('ascii'))

        const appDetails = apps.find(app => app.application_id == appId)

        let key = `${appDetails.publicKey}:${appDetails.privateKey}:${process.env.REACT_APP_TENANT_SECRET}`

        if (user) {
            key += `${user.id}:${user.role}`
        }

        const encodeBuffer = Buffer.from(key)
        const token = encodeBuffer.toString('base64')

        return token
    }
    catch (err) {
        console.log('', err)
    }
}

export const nullCheck = (val) => {
    if (typeof(val) != 'string') {
        return (val && val.toString().length > 0 && val != null)
    }
    else {
        return (val && val.length > 0 && val != null)
    }
}

export const membershipChecker = () => {
    return localStorage.getItem('isMember') === "true"
}

export const getSubdomainCartCount = async () => {
    log('getSubdomainCartCount')
    let apps = [1, 2]
    for (const app in apps) {
        let marketCartCount = await getCrossStorage('market_cart_count', 'sub')
        let eatsCartCount = await getCrossStorage('eats_cart_count', 'sub')
        if (marketCartCount && eatsCartCount) {
            let cartCount = parseInt(marketCartCount) + 1
            localStorage.setItem('market_cart_count', marketCartCount)
            localStorage.setItem('eats_cart_count', eatsCartCount)
            // log({marketCartCount})
            // log({eatsCartCount})
            // log({cartCount})
            // $('.srn-cart-quantity').text(cartCount)
        }
    }
}

export const setSubdomainCartCount = async (details) => {
    let apps = [1, 2]
    for (const app in apps) {
        if (details.app == 'market') {
            await setCrossStorage('market_cart_count', details.market, 'sub')
        }
        else {
            await setCrossStorage('eats_cart_count', details.eats, 'sub')
        }
        let marketCount = parseInt(details.market)
        let eatsCount = parseInt(details.eats)
        let cartCount = parseInt(marketCount ? marketCount : 0) + parseInt(eatsCount ? eatsCount : 0)
        // log({marketCount})
        // log({eatsCount})
        if (cartCount != NaN) {
            $('.srn-cart-quantity').text(cartCount)
        }
    }
}


export const handleExpiredToken = async (param) => {

    log('handleExpiredToken fired')

    if (localStorage.getItem('isLoggedIn') === 'true') {
        try {
            let refreshTokenResult = await loginAPI.handleRefreshToken(localStorage.getItem('access_token'))

            log({refreshTokenResult})

            if (refreshTokenResult.message === 'Success') {
                localStorage.setItem('hasTokenBeenRefreshed', 'true')
                localStorage.setItem('access_token', refreshTokenResult.data.AccessToken)
                setTimeout(() => {
                    window.location.reload()
                }, 30)
            }
        }
        catch(RefreshTokenErr) {
            window.location = `${getUrl('main')}?page=logout`
        }
    }
    else {
        if (param === 2) {
            window.location = `${getUrl('main')}?page=logout`
        }
        else {
            Swal.fire({
                html: getSwalContent('Token Expired'),
                customClass: {
                    confirmButton: 'cmb-btn-primary-swal-checkoutconfirm'
                }
            }).then(res => {
                localStorage.clear();
                window.location = `${getUrl('main')}?page=logout`
            })
        }
    }

}

export const setHeaderAddress = (parsed) => {
    log('setHeaderAddress called', parsed)
    if (document.getElementById('srn-main-location')) {
        if (parsed.address != undefined) {
            document.getElementById('srn-main-location').innerHTML = parsed.address
        }
    }
    if (document.getElementById('srn-main-fulfillment')) {
        if (parsed.fulfillmentType != undefined) {
            document.getElementById('srn-main-fulfillment').innerHTML = parsed.fulfillmentType
        }
    }
    if (document.getElementById('srn-main-location2')) {
        if (parsed.address != undefined) {
            document.getElementById('srn-main-location2').innerHTML = parsed.address
        }
    }
    if (document.getElementById('srn-main-fulfillment2')) {
        if (parsed.fulfillmentType != undefined) {
            document.getElementById('srn-main-fulfillment2').innerHTML = parsed.fulfillmentType
        }
    }
}

export const getThumbnail = (product) => {
    if (product.image_thumbnail) {
        return product.image_thumbnail
    }

    if (product.product_images_thumbnail) {
        if (typeof(product.product_images_thumbnail) === 'string') {if (product.product_images_thumbnail.indexOf(',') > -1) {return product.product_images_thumbnail.split(',')[0] } else {return product.product_images_thumbnail } } else {return product.product_images_thumbnail[0] }
    }
}


export const genericAddCartItem = async (product, address_callback, addtocart_callback) => {

    const marketProceed = async (branch_id) => {
        let price
        let custom_data = []
        let price_object = {}

        let member = product.product_prices.find(p => p.name === "member")
        let nonmember = product.product_prices.find(p => p.name === "nonmember")
        
        if (membershipChecker()) {
            price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
            price_object = member
        } else {
            price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
            price_object = nonmember
        }

        let savings = {name: 'savings', value: nonmember.price - member.price }

        if (product.sell_by_type && product.sell_by_type == 1) {
            custom_data.push({
                name: 'weight',
                value: product.weight
            })
        }
        
        if (product.barcode && product.barcode.length > 0) {
            custom_data.push({
                name: 'barcode',
                value: product.barcode
            })   
        }
        
        custom_data.push(savings)
        custom_data.push(price_object)

        // SAVE ADDRESS IN CART START
        if (getAddressFromObjectChecker) {
            let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

            if (!address) {
                triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
            }

            log({address})

            custom_data.push({
                name: 'address',
                value: address
            })
        }
        // SAVE ADDRESS IN CART END


        let payload = {
            "itemReferenceId": product.id,
            "itemReferenceType": product.type,
            "itemName": product.name,
            "itemSKU": product.sku,
            "itemImage": getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
            "itemDescription": product.description,
            "quantity": product.quantity && product.quantity.length > 0 ? product.quantity : 1,
            "basePrice": nonmember.price,
            "price": price,
            "instructions": "",
            "domainReferenceId": branch_id,
            "domainReferenceType": "1",
            "item_type": product.sell_by_type ? product.sell_by_type : 0,
            "include_promo": true
        }

        if (custom_data && custom_data.length > 0) {
            payload.custom_data = JSON.stringify(custom_data)
        }

        const addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), product.application_id, payload)

        setTimeout(() => {
            addtocart_callback(addCartItemResult.items)
        }, 100)
    }

    const eatsProceed = async (branch_id) => {

        let custom_data = [];

        let price_object = product.product_prices.find(
          (x) => x.name == "nonmember"
        );

        if (price_object) {
          custom_data.push(price_object);
        }

        // SAVE ADDRESS IN CART START
        if (getAddressFromObjectChecker) {
            let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

            if (!address) {
                triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
            }

            log({address})

            custom_data.push({
                name: 'address',
                value: address
            })
        }
        // SAVE ADDRESS IN CART END

        let payload = {
            itemReferenceId: product.id,
            itemReferenceType: product.type,
            itemName: product.name,
            itemSKU: product.sku,
            itemImage: getThumbnail(product) ? getThumbnail(product) : product.images,
            itemDescription: product.description,
            quantity: product.quantity && product.quantity.length > 0 ? product.quantity : 1,
            basePrice: product.price,
            price: product.newPrice ? product.newPrice : product.price,
            instructions: "",
            domainReferenceId: branch_id,
            domainReferenceType: "1",
            item_type: product.sell_by_type ? product.sell_by_type : 0,
            include_promo: true,
        };

      if (custom_data && custom_data.length > 0) {
        payload.custom_data = JSON.stringify(custom_data);
      }

      const addCartItemResult = await cart.addCartItem(
        localStorage.getItem("access_token"),
        product.application_id,
        payload
      );

      addToCartAnalytics();
      addToCartHub(product, branch_id);

      setTimeout(
        () => {
          addtocart_callback(addCartItemResult.items);
        },
        100
      );
    };

    const updateFulfillmentProps = () => {
        triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.onBrandUpdate, {
            brand_id: product.brand_id,
            app_key: product.application_id,
            classNames: product.application_id == eats_app_key ? 'srn-ff-eats-new' : 'srn-ff-market-new'
            // logo: <img src={topLogo} />
        })
    }

    let localAddressObject = localStorage.getItem(
      `address_object_${product.application_id}`
    );
    let address_object = [];

    if (localAddressObject && localAddressObject.length > 0) {
      address_object = JSON.parse(localAddressObject);
    }

    if (address_object && address_object.length > 0) {
      
      let address = address_object.find(
        (x) => x.brand_id == product.brand_id
      );

      if (address) {
        if (address.branch_id && address.branch_id != null) {
          product.application_id == market_app_key ? marketProceed(address.branch_id) : eatsProceed(address.branch_id);
        } else {
          address_callback(product.application_id)
          updateFulfillmentProps()
        }
      } else {
        address_callback()
        updateFulfillmentProps()
      }
    } else {
      address_callback()
      updateFulfillmentProps()
    }
}

export const mainAddressHandler = (appId) => {
    $('#descriptionModal').modal('hide')
    $("#descriptionDiscountModal").modal('hide')
    // Close Shopping Basket modal
    $('.basket_content_container').modal('hide')
    // if (appId && appId != undefined) {
    //     sessionStorage.setItem('appId', appId)
    // }
    if (localStorage.getItem('main_address') && localStorage.getItem('main_address').length > 2) {
        triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.onCheckStoreStatus, {
            location: JSON.parse(localStorage.getItem('main_address')),
            brand_id: localStorage.getItem('brand_id'),
            // app_key: appId && appId != undefined ? appId : null
        })
    }
    else if (localStorage.getItem('default_address') && localStorage.getItem('default_address').length > 2) {
        triggerSerinoEvent(window.serino_components.FulfillmentTypeComponent.subscriptions.onCheckStoreStatus, {
            location: JSON.parse(localStorage.getItem('default_address')),
            brand_id: localStorage.getItem('brand_id'),
            // app_key: appId && appId != undefined ? appId : null
        })
    }
    else {
        $('#fulfillmenttype_modal').modal('show')
        $('#descriptionModal').modal('hide')
    }
}

export const handleSearchDisplay = (bool) => {
    document.getElementById('srn-product-search-result-container').style.display = 'block'
}

export const loadingDisplay = (mode) => {
    return (
        <div className={`srn-line-loader ${mode === 'dark' ? 'srn-line-loader-dark' : ''}`}>
            <div>{[...Array(5)].map((x, i) => <span key={i}></span>)}</div>
        </div>
    )
}

export const getAddressObject = (branch_id, app_id) => {
    const address_array = localStorage.getItem(`address_object_${app_id}`)
    
    if (!address_array) {
        return null;
    }
    
    const parsedArray = JSON.parse(localStorage.getItem(`address_object_${app_id}`))

    if (!parsedArray) {
        return null;
    }

    const address = parsedArray.find(address => address.branch_id.toString() === branch_id.toString())

    if (!address) {
        return null;
    }

    return address

}

export const getAddressFromObjectChecker = () => {
    const getAddressFromCartFlag = true    
    return getAddressFromCartFlag && localStorage.getItem("isLoggedIn") === "true"
}

export const getAddressesFromCart = (cart_items) => {
    
}

export const addEvt = (evt, cb) => {
    document.addEventListener(evt, (e) => {
        cb(e)
    })
}

export const validateAddresses = async  (app_key) => {
    log('validateAddresses started...')
    let addresses = []
    let addressFromlocalStorage = localStorage.getItem(`address_object_${app_key}`)

    if (addressFromlocalStorage && addressFromlocalStorage !== null) {
        addresses = JSON.parse(addressFromlocalStorage)
    }

    let fulfillmentAPI = new FulfillmentAPI()

    if (addresses && addresses.length > 0) {
        for (const address of addresses) {
            let position = {
                lat: address.lat,
                lng: address.lng
            }
            let brand_name = address.brand_name
            let brand_id = address.brand_id
            let label = address.address

            let fulfillmentAPIResult = await fulfillmentAPI.getAvailableBranch(position, localStorage.getItem('access_token'), app_key)

            let branches = fulfillmentAPIResult.models;
            let validateAvailabilityResult = validateAvailability(branches, brand_name, brand_id, fulfillmentAPIResult.currentDate, label, position);
            let errorCode = validateAvailabilityResult.errorCode
            // log('validateAvailabilityResult', validateAvailabilityResult)


            log('validateAvailabilityResult', validateAvailabilityResult)

            if (validateAvailabilityResult.success) {
                address.offHours = false
                address.busyStore = false
            }
            else {
                log('errorCode', errorCode)
                if ((errorCode === 1) || (errorCode === 2)) {
                    if (errorCode === 2) {
                       address.offHours = true
                    } else if (errorCode === 1) {
                       address.busyStore = true
                    }                    
                    if (address.fulfillmentType_status === 1) {
                       address.fulfillmentType_status = 3
                       address.fulfillmentType = 'Deliver Later'
                    }
                }
                else {
                    address.availability = false
                }
            }
        }
        
        log({addresses})

        localStorage.setItem(`address_object_${app_key}`, JSON.stringify(addresses))

        return addresses
    }

}

export const cleanAddresses = () => {
    let address_object_1 = localStorage.getItem(`address_object_${market_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_key}`)) : null
    let address_object_2 = localStorage.getItem(`address_object_${eats_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${eats_app_key}`)) : null
    // log('address_object_1 before', address_object_1)
    // log('address_object_2 before', address_object_2)
    if (address_object_1 != null) {
        address_object_1.forEach(address => {
            delete address.deliverLaterDateTime
            delete address.pickupDateTime
            delete address.booking_duration_from
            delete address.booking_duration_to
            delete address.booking_resource_code
            delete address.selected_datetime
        })
        // log('address_object_1 after', address_object_1)
        localStorage.setItem(`address_object_${market_app_key}`, JSON.stringify(address_object_1))
    }
    if (address_object_2 != null) {
        address_object_2.forEach(address => {
            delete address.deliverLaterDateTime
            delete address.pickupDateTime
            delete address.booking_duration_from
            delete address.booking_duration_to
            delete address.booking_resource_code
            delete address.selected_datetime
        })
        // log('address_object_2 after', address_object_2)
        localStorage.setItem(`address_object_${eats_app_key}`, JSON.stringify(address_object_2))
    }
}

export const setStorage = (property, value) => {
    localStorage.setItem(property, value)
}

export const getStorage = (property) => {
    localStorage.getItem(property)
}

export const setSessionStorage = (property, value) => {
    sessionStorage.setItem(property, value)
}

export const getSessionStorage = (property) => {
    sessionStorage.getItem(property)
}

export const setCurrentBranches = async () => {
    let brands = []
    let eats_brands = []
    let market_brands = []
    let brandsFromStorage = localStorage.getItem('srn-active-brands')

    if (brandsFromStorage) {
        brands = JSON.parse(brandsFromStorage)
        eats_brands = JSON.parse(brandsFromStorage)
    }
    else {
        try {
            const dataFromJSON = await CoreAPI.getDataFromJSON()
        
            if (!dataFromJSON) {
                return
            }
            
            if (dataFromJSON.data && dataFromJSON.data.brands) {
                brands = dataFromJSON.data.brands
                eats_brands = dataFromJSON.data.brands

                setStorage('srn-active-brands', JSON.stringify(brands))
            }
        }
        catch {
        }
    
    }
    // add market brand
    let marketBrand = {id: 1}
    brands.push(marketBrand)
    market_brands.push(marketBrand)
    
    let branch_ids = []
    let eats_branch_ids = []
    let market_branch_ids = []
    let branches = []
    let eats_branches = []
    let market_branches = []

    let address_object_1 = localStorage.getItem(`address_object_${market_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_key}`)) : null
    let address_object_2 = localStorage.getItem(`address_object_${eats_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${eats_app_key}`)) : null

    if (address_object_1 && address_object_1.length > 0) {
        address_object_1.forEach(address => {
            let mappedAddress = {
                brand_id: address.brand_id,
                branch_id: address.branch_id
            }
            branches.push(mappedAddress)
            market_branches.push(mappedAddress)
        })
    }
    if (address_object_2 && address_object_2.length > 0) {
        address_object_2.forEach(address => {
            let mappedAddress = {
                brand_id: address.brand_id,
                branch_id: address.branch_id
            }
            branches.push(mappedAddress)
            eats_branches.push(mappedAddress)
        })
    }

    log({eats_branches})

    branches.forEach(branch => branch_ids.push(branch.branch_id))
    eats_branches.forEach(branch => eats_branch_ids.push(branch.branch_id))
    market_branches.forEach(branch => market_branch_ids.push(branch.branch_id))

    brands.forEach(brand => {
        let existing = branches.find(x => x.brand_id == brand.id)
        if (!existing) {
            branch_ids.push(brand.id)
        }
    })
    eats_brands.forEach(brand => {
        let existing = eats_branches.find(x => x.brand_id == brand.id)
        if (!existing) {
            eats_branch_ids.push(brand.id)
        }
    })
    market_brands.forEach(brand => {
        let existing = market_branches.find(x => x.brand_id == brand.id)
        if (!existing) {
            market_branch_ids.push(brand.id)
        }
    })

    setStorage('srn-current-branches', JSON.stringify(branch_ids))
    setStorage('srn-current-branches-eats', JSON.stringify(eats_branch_ids))
    setStorage('srn-current-branches-market', JSON.stringify(market_branch_ids))

    triggerSerinoEvent('srn-evt-update-branches', branch_ids)
    triggerSerinoEvent('srn-evt-update-branches-eats', eats_branch_ids)
    triggerSerinoEvent('srn-evt-update-branches-market', market_branch_ids)
}

export const handlePriceAdjustments = (products) => {
    let mutated = products.map(product => {
        
        if (product.sell_by_type == '1') {

            let newProductPrices = product.product_prices.map(price_item => {

                let price = parseFloat(price_item.price) * parseFloat(product.weight)
                let original_price = parseFloat(price_item.original_price) * parseFloat(product.weight)
                
                let price_rounded = Math.round(price.toFixed(3) * 100) / 100
                let original_price_rounded = Math.round(original_price.toFixed(3) * 100) / 100

                price_item.price = price_rounded
                price_item.original_price = original_price_rounded

                return price_item
            })

            product.product_prices = newProductPrices
        }

        return product
    })

    return mutated
}

export const getFilteredProductsByStock = (products) => {

    let filteredByStocks = products.filter(product => 
        product.inventory?.length > 0 
        && product.inventory?.[0]?.stock?.length > 0
        && product.inventory?.[0]?.threshold?.[0]?.value
        && product.inventory?.[0]?.out_of_stock === 0);

    filteredByStocks = filteredByStocks.filter(product => parseInt(product.inventory?.[0]?.stock) > parseInt(product.inventory?.[0]?.threshold[0]?.value))
    return filteredByStocks
}

export const handleInitialAddToCart = async (selected_product, branch_id, callback) => {
    let sku = selected_product.sku

    if (sku) {
      if (selected_product && selected_product.id) {
        var productAPI = new ProductList();
        let getProductResult = await productAPI.getInventoryProduct(sku, branch_id, selected_product.application_id)
        
        if (getProductResult.data && getProductResult.data.data && getProductResult.data.data.items) {
          let transformedProducts = handlePriceAdjustments(getProductResult.data.data.items)

            if (selected_product.application_id == market_app_key) {
                transformedProducts = getFilteredProductsByStock(transformedProducts)
            }
                    
          
          if(transformedProducts && transformedProducts.length > 0) {        
            let product = transformedProducts.find(x => x.sku === sku)
            if (product) {
                log('got to the final')
              callback(product, branch_id)
            }
          }
        }
      }
    }
}

let savedProduct = ''

/** Actions performed upon showing product details */
export const handleShowProductActions = (product) => {
    // To avoid duplication, save current props to variable then compare 
    if (product?.sku === savedProduct?.sku) return;
    savedProduct = product;
    
    let price = 0.00;
    if (product?.product_prices?.length > 0) {
        let member = product?.product_prices?.find(p => p.name === "member")
        let nonmember = product?.product_prices?.find(p => p.name === "nonmember")
        
        if (membershipChecker() && member) {
            price = parseFloat(member?.discounted_price) > 0 ? parseFloat(member?.discounted_price) : member?.price
        } else {
            price = parseFloat(nonmember?.discounted_price) > 0 ? parseFloat(nonmember?.discounted_price) : nonmember?.price
        }
    }
    else {
        price = product?.price || 0.00
    }
    
    fbqEvent('ViewContent', {
        content_name: product?.name || '',
        content_category: product?.category ? product?.category : product?.reference_name ? product?.reference_name : '',
        content_ids: [product?.sku],
        content_type: 'product',
        value: price,
        currency: 'PHP'
    })
}

/** Handle Search redirection
 *  Saves search query in sessionStorage to prevent queries in URL
 */
export const handleSearchRedirect = (searchParams) => {
    sessionStorage.setItem('searchQueryObj', JSON.stringify(searchParams))
    window.location = `/search`
}

/**
 * Get the brand with the lowest maximum online payment amount
 */
export const getMaxOnlinePaymentAmountObject = (apps, brands, modeOfPayment) => {

    const maxField = modeOfPayment === 'cash' ? 'max_cash_amount' : 'max_online_amount'
    
    if (apps?.length === 0) return null;
    const cart_items = apps.flatMap(app => app.cart).filter(item => item);
    /**
     * Look for payment configs per brand
     */
    const brandNames = cart_items?.map(x => x?.brand.name) || []
    const uniqueBrands = [...new Set(brandNames)]
    const paymentConfig = brands?.filter(brand => uniqueBrands?.includes(brand?.name) && brand?.[maxField] !== null)
    
    if (paymentConfig.length === 0) {
        return null;
    }
    
    let maxAmt = paymentConfig?.[0];
    
    for (const config of paymentConfig) {
        if (config?.[maxField] < maxAmt?.[maxField]) {
            maxAmt = config;
        }
    }
    
    return maxAmt;
}

/**
 * Get the brand with the 0 maximum cash amount
 */
export const getBrandsWithDisabledCash = (apps, brands, modeOfPayment) => {

    if (apps?.length === 0) return null;
    const cart_items = apps.flatMap(app => app.cart).filter(item => item);
    /**
     * Look for payment configs per brand
     */
    const brandNames = cart_items?.map(x => x?.brand.name) || []
    const uniqueBrands = [...new Set(brandNames)]
    const paymentConfig = brands?.filter(brand => uniqueBrands?.includes(brand?.name) && brand?.max_cash_amount !== null)
    
    if (paymentConfig.length === 0) {
        return null;
    }
    
    const maxCashAmt = paymentConfig?.filter(x => x?.max_cash_amount === 0)
    
    return maxCashAmt;
}

/**
 * Get years range
 */
export const generateYearsArray = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 21 }, (_, index) => currentYear + index);
    return years;
};


// Format mobile number to always start output at "09"
// Why?: BE validation required mobile number length atleast 11
// Third Party helper
export const formatMobileNumber = (phoneNumber) => {
    // Remove any non-digit characters except "+"
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    // If the number starts with "+63", remove it and prepend "0"
    if (cleanedNumber.startsWith("+63")) {
        return `0${cleanedNumber.substring(3)}`;
    }

    // If the number starts with "9", prepend "0"
    if (cleanedNumber.startsWith("9")) {
        return `0${cleanedNumber}`;
    }

    // If the number already starts with "09", just return it
    if (cleanedNumber.startsWith("09")) {
        return cleanedNumber;
    }
  
    return cleanedNumber;
  };

/**
 * Extract object from URL
 */
export const extractObjectFromURL = (query) => {
    const search = query.substring(1);
    const objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
    return objFromUrl;
}