import { Component} from "react"
import { faMinus, faPlus, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class ProductComponent extends Component {
	constructor(props) {
		super(props) 
		this.state = {
			cart: this.props.cart ? this.props.cart : [],
		}
	}

	componentDidUpdate(prevProps) {
        if (this.props.cart !== prevProps.cart) {
            this.setState({cart: this.props.cart})
        }
	}

	render() {
		let product = this.props.product
		return (
			<div className="srn-product-item-container">
				<div className="srn-product-item-img-container">
					<img src="https://picsum.photos/100" className="srn-product-item-img" />
				</div>
				<div className="srn-product-item-content-container">
					<p className="srn-product-item-title">{product && product.name || ''}</p>
					<p className="srn-product-item-subtitle">100g</p>
					<p className="srn-product-item-price">₱ {product && product.price} </p>
				</div>
				<div className="srn-product-item-actions">
					<a href="#!" className="btn srn-product-item-btn srn-product-item-decrement">
						<FontAwesomeIcon icon={faMinus} />
					</a>
					<input className="srn-product-item-input" />
					<a href="#!" className="btn srn-product-item-btn srn-product-item-increment">
						<FontAwesomeIcon icon={faPlus} />
					</a>
				</div>
			</div>
		)
	}
}