import { createStore, combineReducers, applyMiddleware } from 'redux'
import { reducers } from './reducers'
import { middlewares } from './middlewares'

import { composeWithDevTools } from 'redux-devtools-extension'

let enhancers
if (process.env.REACT_APP_ENV !== 'prod') {
    enhancers = composeWithDevTools(applyMiddleware(...middlewares))
}
else {
    enhancers = applyMiddleware(...middlewares)
}

const rootReducer = combineReducers({
    ...reducers
})

export const store = createStore(rootReducer, enhancers)