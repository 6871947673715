import React, { Component } from "react";
import topBanner from "../../assets/images/contact-us-top-banner.jpg";
import icon_officehours from "../../assets/images/icon_officehours.png";
import icon_email from "../../assets/images/icon_email.png";
import icon_phone from "../../assets/images/icon_phone.png";
import axios from "axios";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAccessToken } from "../../helper/helper";
import ReactDOM from "react-dom";
import ParallaxComponent from '../partials/ParallaxComponent';

let API_URL = process.env.REACT_APP_SERINO_API;

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      branch: [],
      brand_id: [],
      access_token: "",
      modal: false,
      brandDetails: "",
    };
  }
  showModal = (data) => {
    this.setState({ modal: true });
    this.setState({ brandDetails: data });
  };

  hideModal = () => {
    this.setState({ modal: false });
    this.setState({ brandDetails: "" });
  };
  async componentDidMount() {

    getAccessToken().then((access_token) => {
      axios
        .get(`${API_URL}/branches/public/brands/all?status=1`, {
          headers: { Authorization: "Bearer " + access_token, "X-APP-KEY": 2 },
        })
        .then((res) => {
          this.setState({
            brands: res.data.data,
            brand_id: res.data.id,
            access_token: access_token,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });

    setTimeout(() => {
      document.getElementById("tab-popeyes").click();
    }, 3000);
  }

  getBranch = (brand_id) => {
    getAccessToken().then((access_token) => {
      axios
        .get(
          `${API_URL}/branches/public/get-branches-by-brand-id/${brand_id}`,
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "X-APP-KEY": 2,
            },
          }
        )
        .then((res) => {
          console.log("branchaaaaaaszzzzzzz", res);
          this.setState({
            branch: res.data.data,
          });
          console.log("branchaaaaa", this.state.brands);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  handleSelectedForm = (evt, id) => {
    var i, tablinks, tabcontent;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tab-content");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(
        " active-profile",
        ""
      );
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById("srn-crm-profile-tab-" + id).style.display =
      "block";
    document.getElementById("srn-crm-mobile-tab-" + id).style.display = "block";
    evt.currentTarget.className += " active-profile";
    this.getBranch(id);
  };

  handleFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const handleViewDetails = (data) => {
      this.showModal(data);
    };
    const fontHead = {
      fontSize: "14px",
      lineHeight: "1.5",
      color: "#5f5f5f",
      margin: "1rem 0 0 0",
    };
    const fontSubHead = {
      fontSize: "14px",
      lineHeight: "1.5",
      color: "#333333",
      margin: "0",
    };
    const currentBrands = this.state.brands.find(
      (e) => e.name === this.state.brandDetails
    );
    console.log(this.state.branch);
    const branches = this.state.branch.map((e) => (
      <div>
        <h3 style={fontHead}>{e?.city}</h3>
        <h4 style={fontSubHead}>{e?.address1}</h4>
      </div>
    ));
    const modalDetails = ReactDOM.createPortal(
      <div
        onClick={() => this.hideModal()}
        style={{
          height: "100vh",
          width: "100%",
          display: this.state.modal ? "flex" : "none",
          background: "rgba(1,2,3,.5)",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "99999",
          justifyContent: "center",
          justifyItems: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
            width: window.screen.width < 600 ? "20rem" : "30rem",
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            zIndex: "99999",
            padding: "2rem",
            borderRadius: "5px",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", top: ".5rem", right: ".5rem" }}>
            X
          </div>
          <div className="contact-us-modal-logo gap-3 flex-column align-items-center justify-content-center d-flex text-center">
            <img src={currentBrands?.logo} alt="" height={120} width={120} />
            <h4>{currentBrands?.name}</h4>
          </div>
          <div>
            <h4 style={fontHead}>Main Address</h4>
            <h4 style={fontSubHead}>{currentBrands?.address1}</h4>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <h3 style={fontHead}>Store Hours</h3>
              <h3 style={fontSubHead}>8:00 AM - 7:00 PM</h3>
            </div>
            <div>
              <h3 style={fontHead}>Contact</h3>
              <h3 style={fontSubHead}>support@central.ph</h3>
            </div>
          </div>
          <div>
            <h3 style={fontHead}>Branches</h3>
            {branches}
          </div>
        </div>
      </div>,
      document.getElementById("root1")
    );

    return (
      <div>
      <ParallaxComponent page="generic" />
      <div className="contact-us">
        {modalDetails}
        <div className="main-landing-banner row">
          <img src={topBanner} className="web-banner" />
        </div>
        <div className="contact-us-container">
          <h6>Contact Us</h6>
          <div className="contact-us-content row justify-content-center">
            <div className="contact-main-details col-md-6 col-sm-12">
              <div className="card contact-selected-brand">
                <div class="contact-item">
                  <div class="contacticon">
                    <img src={icon_officehours} />
                  </div>
                  <div class="lbl_contact">
                    <h6>Office Hours</h6>
                    <p>8:00AM - 5:00PM</p>
                  </div>
                </div>
                <div class="contact-item">
                  <div class="contacticon">
                    <img src={icon_email} />
                  </div>
                  <div class="lbl_contact">
                    <h6>Email Address</h6>
                    <p>support@central.ph</p>
                  </div>
                </div>
                {/* <div class="contact-item">
                  <div class="contacticon">
                    <img src={icon_phone} />
                  </div>
                  <div class="lbl_contact">
                    <h6>Hotline</h6>
                    <p>
                      #90-000
                    </p>
                    <p></p>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="contact-brands col-md-12 col-sm-12">
              <h6>Our Brands</h6>
              <div class="row brandcontainer">
                {
                  this.state.brands.map((brand) => {
                    return (
                    <div class="brand-items col-md-3">
                      <img src={brand.logo} />
                      <button
                        onClick={() => {
                          handleViewDetails(brand.name);
                          this.getBranch(brand.id);
                        }}
                      >
                        View Details
                      </button>
                    </div>
                    );
                  })
                }
                
                {/* <div class="brand-items col-md-3">
                  <img src={centralp} />
                  <button
                    onClick={() => {
                      this.getBranch(7);
                      handleViewDetails("Landers Central");
                    }}
                  >
                    View Details{" "}
                  </button>
                </div>
                <div class="brand-items col-md-3">
                  <img src={dac} />
                  <button
                    onClick={() => {
                      this.getBranch(9);
                      handleViewDetails("Dough and Co.");
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div class="brand-items col-md-3">
                  <img src={dagl} />
                  <button
                    onClick={() => {
                      this.getBranch(23);
                      handleViewDetails("Da Gianni Cucina Italiana");
                    }}
                  >
                    {" "}
                    View Details{" "}
                  </button>
                </div>
                <div class="brand-items col-md-3">
                  <img src={fradgianni} />
                  <button
                    onClick={() => {
                      this.getBranch(23);
                      handleViewDetails("Trattoria da Gianni");
                    }}
                  >
                    View Details
                  </button>
                </div>
                <div class="brand-items col-md-3">
                  <img src={majestic} />
                  <button
                    onClick={() => {
                      this.getBranch(21);
                      handleViewDetails("Majestic");
                    }}
                  >
                    View Details{" "}
                  </button>
                </div>
                <div class="brand-items col-md-3">
                  <img src={popeyes} />
                  <button
                    onClick={() => {
                      this.getBranch(24);
                      handleViewDetails("Popeyes");
                    }}
                  >
                    View Details
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="contact-brand col-md-6 col-sm-12">
            <div className="card h-100 contact-brands-card web-contact-brands-card">
              <div className="col-5 contact-brands-section">
                <ul className="contact-brand-list">
                  <h5>Brands</h5>
                  {this.state.brands.map((brand, i) => {
                    return (
                      <li
                        key={i}
                        className="contact-brand tablinks"
                        id={`tab-${brand.name.toLowerCase()}`}
                        onClick={(e) => this.handleSelectedForm(e, brand.id)}
                      >
                        <a href="#">{brand.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {this.state.brands.map((brand, i) => {
                return (
                  <div
                    className="col-7 contact-brand-details tab-content"
                    id={`srn-crm-profile-tab-${brand.id}`}
                  >
                    <div>
                      <img className="contact-brand-logo" src={brand.logo} />
                      <p className="contact-brand-address">{brand.address1}</p>
                      {this.state.branch.slice(0, 1).map((branch, i) => {
                        return (
                          <p className="contact-brand-store-hours">
                            <span>
                              {
                                branch.branch_schedules[0]["monday"][
                                  "opening_time"
                                ]
                              }{" "}
                              -{" "}
                            </span>
                            <span>
                              {
                                branch.branch_schedules[0]["monday"][
                                  "closing_time"
                                ]
                              }
                            </span>
                          </p>
                        );
                      })}
                      <ul className="contact-branch-list">
                        {this.state.branch.map((branch, i) => {
                          return (
                            <li className="contact-branch">
                              <span className="contact-branch-name">
                                {branch.name}
                              </span>
                              <br />
                              {branch.address1}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="contact-mweb-brand-list">
              <h5>Store</h5>
              <span className="mweb-brand-title">Brands</span>
              {this.state.brands.map((brand, i) => {
                return (
                  <div className="contact-brands-card">
                    <div className="col-12 contact-brands-section">
                      <div>
                        <ul className="contact-brand-list">
                          <li
                            key={i}
                            className="contact-brand tablinks"
                            id={`tab-${brand.name.toLowerCase()}`}
                            onClick={(e) =>
                              this.handleSelectedForm(e, brand.id)
                            }
                          >
                            <a href="#">{brand.name}</a>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </li>
                        </ul>
                        <div
                          className="card contact-brand-details tab-content"
                          id={`srn-crm-mobile-tab-${brand.id}`}
                        >
                          <div className="col-12 contact-brand-details">
                            <div>
                              <img
                                className="contact-brand-logo"
                                src={brand.logo}
                              />
                              <p className="contact-brand-address">
                                {brand.address1}
                              </p>
                              {this.state.branch
                                .slice(0, 1)
                                .map((branch, i) => {
                                  return (
                                    <p className="contact-brand-store-hours">
                                      <span>
                                        {
                                          branch.branch_schedules[0]["monday"][
                                            "opening_time"
                                          ]
                                        }{" "}
                                        -{" "}
                                      </span>
                                      <span>
                                        {
                                          branch.branch_schedules[0]["monday"][
                                            "closing_time"
                                          ]
                                        }
                                      </span>
                                    </p>
                                  );
                                })}
                              <ul className="contact-branch-list">
                                {this.state.branch.map((branch, i) => {
                                  return (
                                    <li className="contact-branch">
                                      <span className="contact-branch-name">
                                        {branch.name}
                                      </span>
                                      <br />
                                      {branch.address1}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
