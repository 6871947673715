import $ from 'jquery'
import { getAppToken } from '../helper/helper'

let IMDS_URL = process.env.REACT_APP_IDMS_URL
let  APP_LAYER_URL = process.env.REACT_APP_LAYER_URL
export default class ApplicationAPI {
    
    getAppMetas(access_token, x_app_key, tenant_id) {
        return $.ajax({
            url: IMDS_URL + `/tenants/${tenant_id}/applications/metas`,         
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        })
    }

    getProductDetails(x_app_key, product_reference) {
        return $.ajax({
            method: 'GET',
            url: APP_LAYER_URL + `/products?product_reference_type=id&product_reference[]=${product_reference}`,
            headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('access_token'),
				"x-app-key": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        })
    }

    getBrandBanners(access_token, x_app_key,) {
        return $.ajax({
            url: IMDS_URL + `/tenants/applications/2/metas`,         
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + access_token,
                "X-APP-KEY": x_app_key
            },
            accept: 'application/json',
			dataType: 'json',
        })
    }
}