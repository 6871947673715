/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import moment from "moment-timezone";
import React, { Component, Fragment } from "react";
import eatslogo from "../../assets/images/Central3.0_Eats_Logo.png";
import marketlogo from "../../assets/images/Central3.0_Markets_Logo.png";
import { getAccessToken, handleExpiredToken, log } from "../../helper/helper";
import ParallaxComponent from '../partials/ParallaxComponent';
import ProfileAPI from '../../_core/profile'

var profileAPI = new ProfileAPI()

let API_URL = process.env.REACT_APP_SERINO_API;

export default class MyOrders extends Component {
  constructor() {
    super();
    this.state = {
      orders: [],
      ordersOMRS: [],
      access_token: "",
      isOMRSEnabled: process.env.REACT_APP_OMRS_STATUS == 1 ? true : false
    };
  }

  async componentDidMount() {
    getAccessToken().then((access_token) => {

      if (this.state.isOMRSEnabled) {
        profileAPI.getAllOrdersOMRS(access_token).then(res => {
          log("OMRS ORDERS", res.data);
          this.setState({
            ordersOMRS: res?.data?.rows ? res.data.rows : [],
            access_token: access_token,
          });
        })
      }
      else {
        axios
          .get(`${API_URL}/orders/get/all`, {
            headers: { Authorization: "Bearer " + access_token, "X-APP-KEY": 2 },
          })
          .then((res) => {
            if (res.data.successful) {
              console.log("Orders all---", res);
              this.setState({
                orders: res.data.message,
                access_token: access_token,
              });
            }
            if (!res.data.successful) {
              if (res.data.error && res.data.error.status && res.data.error.status == 400) {
                handleExpiredToken()
              }
            }
          })
          .catch((err) => {
            console.log(err);
            handleExpiredToken()
          });
      }
    });

    // let default_active_tab = document.getElementById("btn-tab-2");
    // setTimeout(() => {
    //   default_active_tab.click();
    // }, 500);
  }

  handleSelectedForm = (evt, id) => {
    var i, tablinks, tabcontent;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tab-content");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    //Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("btn-tab");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(
        " active-profile",
        ""
      );
    }
    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById("srn-order-tab-" + id).style.display = "grid";
    document.getElementById("srn-order-tab-" + id).style.background = "#f4fcf4";
    document.getElementById("srn-order-tab-" + id).style.gridTemplateColumns =
      "1fr 1fr 1fr";
    evt.currentTarget.className += " active-profile";
  };

  orderTrack = (TN) => {
    sessionStorage.setItem("from", "myorders")
    window.location = `/track-order?order-number=${TN}`;
  };

  CardContainer = (order) => {
    const isMarket =
      order.application_id == process.env.react_app_market_app_id;
    const isEats = order.application_id == process.env.REACT_APP_EATS_APP_ID;
    return (
      <div className="all-orders tablink-content" style={{ position: "relative" }} onClick={() => this.orderTrack(order.tracking_number)}>
        <div className="" style={{ paddingTop: "1rem", textAlign: "left", paddingLeft: "6rem", }}>
          <h6 style={{ fontSize: "12px" }}>Delivery Status</h6>
          <div style={{ display: "flex", alignItems: "center", alignContent: "center", }} className="text-capitalize" >
            <span
              style={{ height: "auto", width: "15px" }}
              className="order-icon"
              id={`${order?.delivery_status?.replace(/\s/g, "").toLowerCase()}`}
            >
              &nbsp;
            </span>

            <p id={`order-status-name-${order.delivery_status}`}>
              {order.delivery_status}
            </p>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: "-1rem",
            left: "-1rem",
            height: "100px",
            width: "100px",
            zIndex: "99999",
          }}
          className=""
        >
          {isMarket && (
            <img
              className="order-logo"
              height="100px"
              width="100px"
              src={marketlogo}
            />
          )}
          {isEats && (
            <Fragment>
              <img
                className="order-logo"
                src={eatslogo}
                height="100px"
                width="100px"
              />
              {/* <img
                            className="order-brand-logo"
                            src={order.brand_logo}
                          /> */}
            </Fragment>
          )}
        </div>
        {/* <div className="tab-order-header">
                      <h6>Order No. {order.order_code}</h6>
                      {order.fulfillmentType !== "Pickup" ? (
                        <h6>Delivery</h6>
                      ) : (
                        <h6>Pickup</h6>
                      )}
                    </div> */}
        <div className="orders-content">
          <h6 className="tracking-no">Tracking no. {order.tracking_number}</h6>

          <div>
            <h6>Delivery address</h6>
            <p>{order.shipping_address}</p>
          </div>

          <div>
            <h6>Delivery Schedule</h6>
            {order.pickupDatetime != null ? (
              <p className="myorder-delivery-time">
                {moment
                  .utc(`${order.pickupDatetime}`)
                  .format("dddd / MMM DD, YYYY")}
                <br />
                {moment(`${order.pickupDatetime}`).format("h:mm A")} -{" "}
                {moment(`${order.pickupDatetime}`)
                  .add(60, "minutes")
                  .format("h:mm A")}
              </p>
            ) : (
              <p>Deliver Now</p>
            )}
          </div>
        </div>
        {/* <div
                      className="view-details-button"
                      onClick={() => this.orderTrack(order.tracking_number)}
                    >
                      <h6>VIEW DETAILS</h6>
                    </div> */}
      </div>
    );
  };
  render() {
    return (
      <div className="d-flex flex-column h-100">
        {/* <ParallaxComponent page="generic" /> */}
        <div className="container my-orders-container">

          <div className="my-orders-current-container">
            <h6 className="my-orders-header">My Active Orders</h6>
            <div className="current-orders row" id="srn-order-tab-2">
              {this.state.orders.map((order, i) => {
                const isMarket =
                  order.application_id == process.env.react_app_market_app_id;
                const isEats =
                  order.application_id == process.env.REACT_APP_EATS_APP_ID;
                return (
                  <Fragment>
                    {
                      order.delivery_status != "claimed" && order.delivery_status != "cancelled" && order.delivery_status != "Cancelled" && order.delivery_status != "delivered" ?
                      <div className={`my-orders-current-orders ${order.modeOfPayment == "Online Credit Card" && order.payment_status !== "Paid" ? "d-none" : ""}`} onClick={() => this.orderTrack(order.tracking_number)}>
                        <div className="my-orders-order-details-container">
                          <div className="header-logo-container">
                            <img className="order-logo" src={order.brand_logo} />
                          </div>
                          <div className="order-name-status">
                            <p className="my-orders-brand-name">{order.brand_name}</p>
                            <p className="my-orders-status" id={`order-status-name-${order.delivery_status}`}> <span>{order.delivery_status}</span></p>
                          </div>
                        </div>
                      </div>:null
                    }
                  </Fragment>
                );
              })}
              {this.state.ordersOMRS.map((order, i) => {
                const isMarket =
                  order.application_id == process.env.react_app_market_app_id;
                const isEats =
                  order.application_id == process.env.REACT_APP_EATS_APP_ID;
                return (
                  <Fragment>
                    {
                      order.request_status != "claimed" && order.request_status != "cancelled" && order.request_status != "Cancelled" && order.request_status != "delivered" ?
                      <div className={`my-orders-current-orders ${order.modeOfPayment == "Online Credit Card" && order.payment_status !== "Paid" ? "d-none" : ""}`} onClick={() => this.orderTrack(order.tracking_number)}>
                        <div className="my-orders-order-details-container">
                          <div className="header-logo-container">
                            <img className="order-logo" src={order.brand_logo} />
                          </div>
                          <div className="order-name-status">
                            <p className="my-orders-brand-name">{order.brand_name}</p>
                            <p className="my-orders-status" id={`order-status-name-${order.status}`}> <span>{order.status}</span></p>
                          </div>
                        </div>
                      </div>:null
                    }
                  </Fragment>
                );
              })}
            </div>
          </div>

          <div className="my-orders-previous-container">
            <h6 className="my-orders-header">Previous Orders</h6>
            <div className="current-orders row" id="srn-order-tab-2">
              {this.state.orders.map((order, i) => {
                const isMarket =
                  order.application_id == process.env.react_app_market_app_id;
                const isEats =
                  order.application_id == process.env.REACT_APP_EATS_APP_ID;
                return (
                  <Fragment>
                    {
                      order.delivery_status == "claimed" || order.delivery_status == "cancelled" || order.delivery_status == "delivered" || order.delivery_status == "Cancelled" ?
                      <div className={`my-orders-current-orders ${order.modeOfPayment == "Online Credit Card" && order.paymet_status != "Paid" ? "d-none" : ""}`} onClick={() => this.orderTrack(order.tracking_number)}>
                        <div className="my-orders-order-details-container">
                          <div className="header-logo-container">
                            <img className="order-logo" src={order.brand_logo} />
                          </div>
                          <div className="order-name-status">
                            <p className={`my-orders-brand-name ${order.delivery_status == "Cancelled" ? "cancelled-order-name" : ""}`}>{order.brand_name}</p>
                            <p className="my-orders-previous-status" id={`order-status-name-${order.delivery_status}`}>
                              {order.pickupDatetime != null ? (
                              <p className="my-order-delivery-time">{order.delivery_status} {moment.utc(`${order.pickupDatetime}`).format("MMM DD, YYYY")}</p>
                              ) : (
                              <p className="my-order-delivery-time">{order.delivery_status}  {moment.utc(`${order.date_created}`).format("MMM DD, YYYY")}</p>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>:null
                    }
                  </Fragment>
                );
              })}
            </div>
          </div>
          

          {/* All ORDERS------------------------------------------------------ */}
          {/* <div className="order-tab-container">
            <div className="order-tab-header">
              {/* <div className="">Tracking Number</div>
              <div className="">2 of 3 Completed</div> */}
            </div>

            {/* <div className="tab-content" id="srn-order-tab-1">
              {this.state.orders.map((order, i) => {
                const isMarket =
                  order.application_id == process.env.react_app_market_app_id;
                const isEats =
                  order.application_id == process.env.REACT_APP_EATS_APP_ID;
                return (
                  <div
                    className="all-orders tablink-content"
                    onClick={() => this.orderTrack(order.tracking_number)}
                  >
                    <div className="card-header-top">
                      <h6>Delivery Status</h6>
                      <div className="header-icon-container">
                        <span
                          style={{ height: "auto", width: "15px" }}
                          className="order-icon"
                          id={`${order?.delivery_status
                            ?.replace(/\s/g, "")
                            .toLowerCase()}`}
                        >
                          &nbsp;
                        </span>

                        <p id={`order-status-name-${order.delivery_status}`}>
                          {order.delivery_status}
                        </p>
                      </div>
                    </div>
                    <div className="header-logo-container">
                      {isMarket && (
                        <img
                          className="order-logo"
                          height="100px"
                          width="100px"
                          src={marketlogo}
                        />
                      )}
                      {isEats && (
                        <Fragment>
                          <img
                            className="order-logo"
                            src={eatslogo}
                            height="100px"
                            width="100px"
                          />
                        </Fragment>
                      )}
                    </div>
                    <div className="orders-content">
                      <h6 className="tracking-no">
                        Tracking no. {order.tracking_number}
                      </h6>

                      <div>
                        <h6>Delivery address</h6>
                        <p>{order.shipping_address}</p>
                      </div>

                      <div>
                        <h6>Delivery Schedule</h6>
                        {order.pickupDatetime != null ? (
                          <p className="myorder-delivery-time">
                            {moment
                              .utc(`${order.pickupDatetime}`)
                              .format("dddd / MMM DD, YYYY")}
                            <br />
                            {moment(`${order.pickupDatetime}`).format(
                              "h:mm A"
                            )}{" "}
                            -{" "}
                            {moment(`${order.pickupDatetime}`)
                              .add(60, "minutes")
                              .format("h:mm A")}
                          </p>
                        ) : (
                          <p>Deliver Now</p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}

          {/* CURRENT ORDERS------------------------------------------------------ */}
          {/* <div className="current-orders tab-content" id="srn-order-tab-2">
            {this.state.orders.map((order, i) => {
              const isMarket =
                order.application_id == process.env.react_app_market_app_id;
              const isEats =
                order.application_id == process.env.REACT_APP_EATS_APP_ID;
              return (
                <Fragment>
                  {
                    order.delivery_status != "claimed" && order.delivery_status != "Cancelled" && order.delivery_status != "delivered" ?
                    <div
                      className="all-orders tablink-content"
                      onClick={() => this.orderTrack(order.tracking_number)}
                    >
                      <div className="card-header-top">
                        <h6>Delivery Status</h6>
                        <div className="header-icon-container">
                          <span
                            style={{ height: "auto", width: "15px" }}
                            className="order-icon"
                            id={`${order?.delivery_status
                              ?.replace(/\s/g, "")
                              .toLowerCase()}`}
                          >
                            &nbsp;
                          </span>

                          <p id={`order-status-name-${order.delivery_status}`}>
                            {order.delivery_status}
                          </p>
                        </div>
                      </div>
                      <div className="header-logo-container">
                        {isMarket && (
                          <img
                            className="order-logo"
                            height="100px"
                            width="100px"
                            src={marketlogo}
                          />
                        )}
                        {isEats && (
                          <Fragment>
                            <img
                              className="order-logo"
                              src={eatslogo}
                              height="100px"
                              width="100px"
                            />
                          </Fragment>
                        )}
                      </div>
                      <div className="orders-content">
                        <h6 className="tracking-no">
                          Tracking no. {order.tracking_number}
                        </h6>

                        <div>
                          <h6>Delivery address</h6>
                          <p>{order.shipping_address}</p>
                        </div>

                        <div>
                          <h6>Delivery Schedule</h6>
                          {order.pickupDatetime != null ? (
                            <p className="myorder-delivery-time">
                              {moment
                                .utc(`${order.pickupDatetime}`)
                                .format("dddd / MMM DD, YYYY")}
                              <br />
                              {moment(`${order.pickupDatetime}`).format(
                                "h:mm A"
                              )} -{" "}
                              {moment(`${order.pickupDatetime}`)
                                .add(60, "minutes")
                                .format("h:mm A")}
                            </p>
                          ) : (
                            <p>Deliver Now</p>
                          )}
                        </div>
                      </div>
                    </div>:null
                  }
                </Fragment>
              );
            })}
          </div> */}

          {/* ORDER HISTORY------------------------------------------------------ */}
          {/* <div className="order-history tab-content" id="srn-order-tab-3">
            {this.state.orders.map((order, i) => {
              const isMarket =
                order.application_id == process.env.react_app_market_app_id;
              const isEats =
                order.application_id == process.env.REACT_APP_EATS_APP_ID;
                 
              return (
               <Fragment>
                 {
                  order.delivery_status == "claimed" || order.delivery_status == "Cancelled" || order.delivery_status == "delivered" ?
                  <div className="all-orders tablink-content" onClick={() => this.orderTrack(order.tracking_number)}>
                    <div className="card-header-top">
                      <h6>Delivery Status</h6>
                      <div className="header-icon-container">
                        <span style={{ height: "auto", width: "15px" }} className="order-icon" id={`${order?.delivery_status?.replace(/\s/g, "").toLowerCase()}`}>
                          &nbsp;
                        </span>
                        <p id={`order-status-name-${order.delivery_status}`}>
                          {order.delivery_status}
                        </p>
                      </div>
                    </div>
                    <div className="header-logo-container">
                      {isMarket && (
                        <img className="order-logo" height="100px" width="100px" src={marketlogo} />
                      )}
                      {isEats && (
                        <Fragment>
                          <img
                            className="order-logo"
                            src={eatslogo}
                            height="100px"
                            width="100px"
                          />
                        </Fragment>
                      )}
                    </div>
                    <div className="orders-content">
                      <h6 className="tracking-no">
                        Tracking no. {order.tracking_number}
                      </h6>

                      <div>
                        <h6>Delivery address</h6>
                        <p>{order.shipping_address}</p>
                      </div>

                      <div>
                        <h6>Delivery Schedule</h6>
                        {order.pickupDatetime != null ? (
                          <p className="myorder-delivery-time">
                            {moment
                              .utc(`${order.pickupDatetime}`)
                              .format("dddd / MMM DD, YYYY")}
                            <br />
                            {moment(`${order.pickupDatetime}`).format(
                              "h:mm A"
                            )} -{" "}
                            {moment(`${order.pickupDatetime}`)
                              .add(60, "minutes")
                              .format("h:mm A")}
                          </p>
                        ) : (
                          <p>Deliver Now</p>
                        )}
                      </div>
                    </div>
                  </div>
                  :null
                }
               </Fragment>
              );
            })}
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}
