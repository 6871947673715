import { headerMiddlewares } from '../../components/common/header/redux/middlewares'
import { appActionTypes, actions } from '../actions'
import { log } from '../../core'

const mountAppMiddleware = ({ dispatch }) => next => action => {
    // on mount app
    if (action.type === appActionTypes.MOUNT_APP) {
        const { host } = action.payload;

        const hostArray = host.split('.')
        const subdomain = hostArray[0]

        let appId;
        switch (subdomain.toLowerCase()) {
            case process.env.REACT_APP_MARKET_SUBDOMAIN:
                appId = process.env.REACT_APP_MARKET_APP_ID
                break
            case process.env.REACT_APP_EATS_SUBDOMAIN:
                appId = process.env.REACT_APP_EATS_APP_ID
                break
            default:
                appId = 0
                break
        }

        // select application, depending on subdomain
        dispatch(actions.triggerSelectApplication({ appId }))
    }

    next(action)
}

const loggerMiddleware = ({ dispatch }) => next => action => {
    log(action.type)
    next(action)
}

const redirectMiddleware = ({ dispatch }) => next => action => {
    if (action.type === appActionTypes.REDIRECT) {
        window.location.href = action.payload.to
    }

    next(action)
}

export const appMiddlewares = [
    loggerMiddleware,
    mountAppMiddleware,
    redirectMiddleware
]

export const middlewares = [
    ...appMiddlewares,
    ...headerMiddlewares,
]