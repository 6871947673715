let market_app_id = process.env.REACT_APP_MARKET_APP_ID
let eats_app_id = process.env.REACT_APP_EATS_APP_ID

export const EntryPoints = (props) => {
    let apps = props.data || null
    let eats = {}
    let market = {}
    if (apps) {
        eats = apps.find(x => x.id == eats_app_id)
        market = apps.find(x => x.id == market_app_id)
    }
    return (
        <>
            {
                apps && apps.length > 0 ?
                <div className="srn-main-landing-entrypoints">
                    {
                        eats ?
                        <div className="srn-main-landing-entrypoints-container eats-entry" onClick={() => window.location = "/eats"}>
                            <div className="srn-main-landing-entrypoints-details">
                                <h6>{eats.name || ''}</h6>
                                <span>{eats.slogan || ''}</span>
                                <button className="go-to">GO TO EATS</button>
                            </div>
                            
                            <img src={eats.featured_image || ''}/>
                        </div> : null
                    }
                    {
                        market ?
                        <div className="srn-main-landing-entrypoints-container market-entry" onClick={() => window.location = "/market"}>
                            <div className="srn-main-landing-entrypoints-details">
                                <h6>{market.name || ''}</h6>
                                <span>{market.slogan || ''}</span>
                                <button className="go-to">GO TO MARKET</button>
                            </div>
                            <img src={market.featured_image || ''}/>
                        </div> : null
                    }
                </div> : null
            }
        </>
    )
}