import React, { Component } from "react";
import axios from 'axios'
import $ from 'jquery'
import {
    getAccessToken,
    log,
    triggerSerinoEvent,
    getCrossCart,
    handleLandersBrandId,
    membershipChecker,
    getUrl,
    getTemporaryToken,
    setCrossStorage,
    setHeaderAddress,
    getSwalContent,
    getThumbnail,
    handleExpiredToken,
    mainAddressHandler,
    getAddressObject,
    getAddressFromObjectChecker,
    getAddressesFromCart,
    addEvt,
    setCurrentBranches,
    setStorage,
    handleInitialAddToCart,
    handleShowProductActions
} from '../../helper/helper'
import { addToCartAnalytics, addToCartHub, updateCartAnalytics, updateCartHub, GAImpressions } from '../../helper/analytics'
// import ClientBanner from '../../assets/images/131@2x.png'
// import SignupBanner from '../../assets/images/main_banner-1.png'
// import SignupBannerMweb from '../../assets/images/sign_up_banner_mweb@2x.png'
// import BestPicksTitleMweb from '../../assets/images/best_picks_title_mweb.png'
// import BestTreatsTitleMweb from '../../assets/images/best_treats_title_mweb.png'

import BestPicksTitleWeb from '../../assets/images/best_picks.svg'
import BestTreatsTitleWeb from '../../assets/images/best_dishes.svg'

import MarketLogo from '../../assets/images/market-footer2.png'
import EatsLogo from '../../assets/images/central_gold.png'

import EatsSVG from '../../assets/images/new/central-eats-logo-2.svg'
import MarketSVG from '../../assets/images/new/central-market-logo-2.svg'

import LoginAPI from '../../_core/login'
import Cart from '../../_core/cart'
import CoreAPI from '../../_core/core'
import BrandsOMRSAPI from '../../_core/brands'


//new mock up assets 21/29/2021
// import landingbanner1 from '../../assets/images/landing_banner1.jpg'
// import landingbanner2 from '../../assets/images/landing_banner2.jpg'

import marketAdsleft from '../../assets/images/landing_market_adsLeft.jpg'
import marketAdsright from '../../assets/images/landing_market_adsRight.jpg'
import eatsAdsleft from '../../assets/images/landing_eats_adsLeft.jpg'
import eatsAdsright from '../../assets/images/landing_eats_adsRight.jpg'

import mwebBanner1 from '../../assets/images/mobile/landing_banner.jpg'
import mwebBanner2 from '../../assets/images/mobile/landing_banner-1.jpg'
import mwebAds1 from '../../assets/images/mobile/landing_ad_1.png'
import mwebAds2 from '../../assets/images/mobile/landing_ad_2.png'
import mwebAds3 from '../../assets/images/mobile/birthday_bash.jpg'
import Swal from 'sweetalert2'

//Central 3.0 new --------------------------------->
import CentralLogo from '../../assets/images/central_logo@2x.png'

import LocationPicker from '../partials/LocationPicker'
import BundleComponent from '../partials/BundleComponent'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import ParallaxComponent from '../partials/ParallaxComponent'
import { BrandsComponent } from '../partials/BrandsComponent'
import ShoppingBasketComponent from '../partials/ShoppingBasketComponent'
import SearchPageComponent from './search'
import { connect } from "react-redux";

import ApplicationAPI from '../../_core/application'

import {EntryPoints} from './main/EntryPoints'

let cart = new Cart()
let coreAPI = new CoreAPI()
var application = new ApplicationAPI()
var brandsAPI = new BrandsOMRSAPI()

var login = new LoginAPI()
const x_app_key = process.env.REACT_APP_X_APP_KEY
const app_env = process.env.REACT_APP_ENV
let market_app_id = process.env.REACT_APP_MARKET_APP_ID
let eats_app_id = process.env.REACT_APP_EATS_APP_ID
let similar_items_id = []

let initCount = 0

const handlePageRedirect = (domain, searchKey) => {
    window.location.href = getUrl(domain)+`-products?search=${searchKey}`
    console.log('geturl',getUrl(domain)+`-products`)
}

class MainLanding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cart_items: [],
            isLoggedIn: localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false,
            metas: [],
            rewardsEnabled: false,
            pendingProduct: {},
            address_object: [],
            app_key: market_app_id,
            product: {},
            similar_items: [],
            apps: [],
            isAddBtnLoading: false,
            triggerGetDefaultAddress: {
                state: false,
                app_key: x_app_key
            },
            market_cart: [],
            eats_cart: [],
            eatsProducts: [],
            brands: [],
            apps: [],
            branch_id: '',
            isShoppingBasketActive: true,
            isSearchActive: false,
            isSearchPageActive: false,
            query: '',
            searchApp: '',
            searchBrand: '',
            // isOMRSEnabled: process.env.REACT_APP_OMRS_STATUS == 1 ? true : false
            isOMRSEnabled: false,
            mid_banner: null
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.cart_items !== prevProps.cart_items) {
            if (this.props.cart_items && this.props.cart_items.length > 0) {
                this.getAllCart({items: this.props.cart_items})
            }
        }
    }

    componentDidMount = () => {

        // let testString  = 'Ã±dfasdf'

        // if (testString .includes('Ã±')) {
        //     testString  = 'ñ'
        // }

        // log({testString })

        // sessionStorage.removeItem('signUpEnabled')

        addEvt('srn-app-key-update', (e) => {
            this.handleAppKeyUpdate(e.detail)
        })

        localStorage.removeItem('fulfillment_mode')
        sessionStorage.removeItem("signupPage", true)

        sessionStorage.removeItem('viewAction')
        // let isLoggedIn = localStorage.getItem('isLoggedIn') == 'true'

        // if (isLoggedIn) {            
        //     initCount++

        //     log('init count', initCount)
        //     if (initCount < 2) {
        //         return
        //     }
        // }

        this.getDataFromJSON()

        let objFromUrl;
        let search;

        if (this.props.location.search.length > 0) {
            search = this.props.location.search.substring(1);
            objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')

            if (objFromUrl.page && objFromUrl.page === "logout") {
                this.onLogout()
            }
            if (objFromUrl.page && objFromUrl.page === "search") {
                this.setState({
                    isSearchPageActive: true,
                    query: objFromUrl.query ? objFromUrl.query : '',
                    searchApp: objFromUrl.app ? objFromUrl.app : '',
                    searchBrand: objFromUrl.brand ? objFromUrl.brand : ''
                })
            }
        } 
        
        /** Handle Search Queries  */
        if (this.props.location.pathname === "/search") {
            let parsedSearchQuery = {
                query: '',
                app: '',
                brand: ''
            }
            const searchQueryObj = sessionStorage.getItem('searchQueryObj')
            if (searchQueryObj && searchQueryObj?.length > 0) {
                parsedSearchQuery = JSON.parse(searchQueryObj)
            }
            this.setState({
                isSearchPageActive: true,
                query: parsedSearchQuery.query ? parsedSearchQuery.query : '',
                searchApp: parsedSearchQuery.app ? parsedSearchQuery.app : '',
                searchBrand: parsedSearchQuery.brand ? parsedSearchQuery.brand : ''
            })
        }

        // Set initial address_object values
        if (!localStorage.getItem(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`)) {
            localStorage.setItem(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`, '[]')
        }
        if (!localStorage.getItem(`address_object_${process.env.REACT_APP_EATS_APP_ID}`)) {
            localStorage.setItem(`address_object_${process.env.REACT_APP_EATS_APP_ID}`, '[]')
        }
        if (this.state.isLoggedIn === 'true') {
            login.getClientMeta(localStorage.getItem('access_token'), x_app_key)
                .then((res) => {
                    this.setState({ metas: res.data.metas })                   

                    let metas = this.state.metas
                    metas?.filter((x,i) => {
                        this.setState({
                            rewardsEnabled: x.key
                        })
                    })                    
                    this.setState({access_token: localStorage.getItem('access_token')})
                })
                setTimeout(() => {
                    this.initialize()
                }, 300)
        } else {
            getAccessToken()
                .then((access_token) => {
                    this.setState({access_token})
                    this.initialize()
                })
            
        }

        this.setMainAddress()
        
        // Bundle Cart actions
        // if (this.state.isShoppingBasketActive) {
        //     this.handleBundleActions()
        // }
    }

    handleTempCart = async (cart_items) => {
        console.log('temp cart called')

        sessionStorage.removeItem('initial_login')

        console.log(localStorage.getItem('temp_cart'))

        let temp_cart = localStorage.getItem('temp_cart') ? JSON.parse(localStorage.getItem('temp_cart')) : null

        let updated_result = null

        if (temp_cart && temp_cart.length > 0) {

            for (const item of temp_cart) {
                

                const custom_data_string = item.custom_data
                if (custom_data_string.slice(custom_data_string.length -1) !== ']') {
                    log('ERROR IN CUSTOM DATA FIELD')
                    return
                }

                let custom_data = JSON.parse(item.custom_data).find(x => x.name === 'address')

                let existingItem = cart_items.find(x => {
                    return x.itemReferenceId == item.itemReferenceId
                })

                if (!existingItem) {
                    let payload = {
                        "itemReferenceId": item.itemReferenceId,
                        "itemReferenceType": item.itemReferenceType,
                        "itemName": item.itemName,
                        "itemSKU": item.itemSKU,
                        "itemImage": item.itemImage,
                        "itemDescription": item.itemDescription,
                        "quantity": item.quantity,
                        "basePrice": item.basePrice,
                        "price": item.price,
                        "instructions": item.instructions,
                        "domainReferenceId": item.domainReferenceId,
                        "domainReferenceType": item.domainReferenceType,
                        "item_type": item.item_type,
                        "include_promo": item.include_promo,
                        "custom_data": item.custom_data,
                        "optionIds": item.optionIds,
                        "optionText": item.optionText
                    }

                    const addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), custom_data.value.app_id, payload)

                    updated_result = addCartItemResult

                    log({addCartItemResult})
                }

            }
            localStorage.removeItem('temp_cart')
        }

        if (updated_result) {
            this.getAllCart(null, true)
        }

    }

    setMainAddress = () => {

        let main_address = localStorage.getItem('main_address')

        if (main_address && main_address.length > 2) {
            let parsed = JSON.parse(main_address)

            if (!parsed.barangay) {
                localStorage.removeItem('main_address')
                this.setState({
                    triggerGetDefaultAddress: {
                        state: !this.state.triggerGetDefaultAddress,
                        app_key: x_app_key
                    }
                })
            }
            else {
                setHeaderAddress(parsed)
            }

        }
        else {
            this.setState({
                triggerGetDefaultAddress: {
                    state: !this.state.triggerGetDefaultAddress,
                    app_key: x_app_key
                }
            })
        }
    }

    setExistingAddress = (cart_items) => {

        let custom_data_array = []

        cart_items.forEach(item => {
            let cust = null
            const custom_data = item.custom_data
            if (custom_data.slice(custom_data.length -1) !== ']') {
                log('ERROR IN CUSTOM DATA FIELD')
                return
            }

            cust = item.custom_data ? JSON.parse(item.custom_data) : null

            if (cust) {
                let address = cust.find(x => x.name === 'address')
                if (address) {
                    custom_data_array.push(address)
                }
            }
        })

        let marketAddresses = []
        let eatsAddresses = []

        if (custom_data_array && custom_data_array.length > 0) {
            
            let filteredMarketAddresses = custom_data_array.filter(x => x?.value?.app_id == market_app_id)
            if (filteredMarketAddresses) {
                marketAddresses = filteredMarketAddresses.map(y => y.value)
            }

            let filteredEatsAddresses = custom_data_array.filter(x => x?.value?.app_id == eats_app_id)
            if (filteredMarketAddresses) {
                eatsAddresses = filteredEatsAddresses.map(y => y.value)
            }

        }

        if (marketAddresses && marketAddresses.length > 0) {
            marketAddresses = marketAddresses.reduce(function(a,b){
              function indexOfProperty (a, b){
                  for (var i=0;i<a.length;i++){
                      if((a[i].brand_id == b.brand_id)){
                           return i;
                       }
                  }
                 return -1;
              }

              if (indexOfProperty(a,b) < 0 ) a.push(b);
                return a;
            },[]);

            if (!localStorage.getItem(`address_object_${market_app_id}`) || localStorage.getItem(`address_object_${market_app_id}`).length < 3) {
                localStorage.setItem(`address_object_${market_app_id}`, JSON.stringify(marketAddresses))
            }
        }

        if (eatsAddresses && eatsAddresses.length > 0) {
            eatsAddresses = eatsAddresses.reduce(function(a,b){
              function indexOfProperty (a, b){
                  for (var i=0;i<a.length;i++){
                      if((a[i].brand_id == b.brand_id)){
                           return i;
                       }
                  }
                 return -1;
              }

              if (indexOfProperty(a,b) < 0 ) a.push(b);
                return a;
            },[]);

            if (!localStorage.getItem(`address_object_${eats_app_id}`) || localStorage.getItem(`address_object_${eats_app_id}`).length < 3) {
                localStorage.setItem(`address_object_${eats_app_id}`, JSON.stringify(eatsAddresses))
            }

        }


    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
    
    initialize = async () => {
        let isLoggedIn = localStorage.getItem('isLoggedIn') == 'true'

        log('initialized')

        // log(localStorage.getItem('access_token'))
        // let address_object = await getCrossStorage('address_object', 'sub')
        // setTimeout(() => {
        //     if (address_object && address_object.length > 0) {                
        //         let parsed = JSON.parse(address_object)
        //         if (parsed && parsed.length > 0) {                    
        //             this.setState({
        //                 address_object: parsed
        //             })                
        //         }
        //     }
        // }, 2000)
        // search list component
        // let searchProduct = window.serino_components.SearchProductListComponent.create({
        //     access_token: localStorage.getItem('access_token'),
        //     x_app_key: x_app_key,
        //     categordId: 13,
        //     brand_id: 2,
        //     mode: 0
        // }, "srn-search-product-list-container");

        // search component event
        let searchComponentEvent = window.serino_components.SearchProductListComponent.events
        document.addEventListener(searchComponentEvent.onSelect, (e) => {
            this.productDescription(e.detail.product, x_app_key)

            // log('search view product', e.detail.product)

            // document.getElementById('srn-product-search-result-container').style.display = 'none'
        })

        // document.addEventListener(searchComponentEvent.onViewAllResult, (e) => {
        //     console.log(e.detail)
        //     localStorage.removeItem('banner_image')
        //     setCrossStorage('viewAllAction', 'viewSearchResult')
        //     setCrossStorage('searchKeyWord', e.detail.searchKeyword)
        //     // SEARCH FROM LANDING CHECKER
        //     setCrossStorage('searchMode', 0)
        //     // window.location = '/products'
        //     handlePageRedirect('market',e.detail.searchKeyword)
        // })

        // document.addEventListener(searchComponentEvent.onViewAllResult, (e) => {
        //     console.log(e.detail)
        //     localStorage.removeItem('banner_image')
        //     setCrossStorage('viewAllAction', 'viewSearchResult')
        //     setCrossStorage('searchKeyWord', e.detail.searchKeyword)
        //     // SEARCH FROM LANDING CHECKER
        //     setCrossStorage('searchMode', 0)
        //     // window.location = '/products'
        //     handlePageRedirect('market',e.detail.searchKeyword)
        // })

        this.addSearchEvents()
        
        getTemporaryToken().then(access_token => {

            let categoryListSide = window.serino_components.CategoryListComponent.create({
                access_token: access_token,
                activeCategory: localStorage.getItem("collection_id"),
                brand_id: 6,
                branch_id: 6,
                x_app_key: x_app_key,
                mode: 1,
            }, "srn-category-list-container-side");
            
            let categoryEvents = window.serino_components.CategoryListComponent.events

            this.userRole()

            // Reuse loaded categories
            // document.addEventListener(categoryEvents.onCategoryLoad, (e) => {    
            //     // category list component
            //     let categoryList = window.serino_components.CategoryListComponent.create({
            //         access_token: access_token,
            //         activeCategory: localStorage.getItem("collection_id"),
            //         brand_id: 6,
            //         branch_id: 6,
            //         x_app_key: x_app_key,
            //         mode: 1,
            //         data: e.detail
            //     }, "srn-category-list-container");

            //     this.setState({
            //         isCategoryLoading: false
            //     })
            // })

        })
      

        let categoryEvents = window.serino_components.CategoryListComponent.events

        document.addEventListener(categoryEvents.category_select, (e) => {
            log('collection selected', e.detail.col)
            localStorage.setItem("category_branch_id", e.detail.col_cat.branch_id)
            localStorage.setItem("collection_id", e.detail.col_cat.col_id)
            localStorage.setItem('banner_image', e.detail.col_cat.banner_img)
            sessionStorage.setItem('viewAllAction', 'categoryMenu')
            localStorage.removeItem("auto_tag")

            let categories_display = {
                category: e.detail.col_cat.collection,
                subcategory: e.detail.col_cat.sub_cat,
                sub_cat_collection_id: e.detail.col_cat.sub_cat_collection_id,
                parent_id: e.detail.col_cat.parent_id,
                children: e.detail.col_cat.children,
                banner_img: e.detail.col.image,
                branch_id: e.detail.col_cat.branch_id
            }
        localStorage.setItem("categories_display", JSON.stringify(categories_display))
        localStorage.setItem('selected_sub_cat_name', JSON.stringify(e.detail.col_cat.sub_cat))
        // localStorage.setItem('selected_child_category', JSON.stringify(e.detail.col_cat))
        let catUrl = sessionStorage.getItem("categoryUrl").replace(/ /g,"_");
        this.props.history.push(`/market-products?${catUrl}`)
        })

        let productTagsEvent = window.serino_components.ProductTagsComponent.events
        setTimeout(() => { //temporary fix for switching to members price
            let bestPick = window.serino_components.ProductTagsComponent.create({
                access_token: localStorage.getItem('access_token'),
                x_app_key: process.env.REACT_APP_MARKET_APP_ID,
                currency: '₱',
                time_interval: 0,
                tags: 'best picks',
                viewMoreEnabled: true,
                id: 'main-landing-best-pick',
                branch_id: 1,
                isParent: true,
                shared_tags: [ "best picks",],
            }, 'srn-product-tags-container-best-pick')
        },1500)
        
        let bestDeal = window.serino_components.ProductTagsComponent.create({
            access_token: localStorage.getItem('access_token'),
            x_app_key: process.env.REACT_APP_EATS_APP_ID,
            currency: '₱',
            time_interval: 0,
            tags: 'best treats eats', // can be group of tags separated with comma // payload items from cart
            viewMoreEnabled: true,
            id: 'main-landing-best-deal',
            mode: 2,
            landing: 1
        }, 'srn-product-tags-container-best-deal')

        // product tags add to cart
        document.addEventListener(productTagsEvent.onAddToCart, (e) => {
            console.log('product tags event triggered', e.detail)
            let brand_id = handleLandersBrandId(e.detail.product_details.brand_id, e.detail.app_key)
            localStorage.setItem('brand_id', brand_id)
            log('app key', e.detail.app_key)
            this.setState({
                product: e.detail.product_details,
                app_key: e.detail.app_key
            }, () => {
                log('app key', this.state.app_key)
                if (this.state.app_key == process.env.REACT_APP_MARKET_APP_ID) {
                    this.addToCartMarket(e.detail.product_details)
                } else {
                    this.addToCartEats(e.detail.product_details, e.detail.product_details.variants)
                }

                // this.addProductToCartComponent(e.detail.product_details)
            })
        })

        document.addEventListener(productTagsEvent.onProductTagsLoad('main-landing-best-deal'), (e) => {
            log('onProductTagsLoad', e.detail)
            this.setState({
                eatsProducts: e.detail
            })
        })

        document.addEventListener(productTagsEvent.onLoadSharedData, (e) => {
            if (initCount === 0) {
                GAImpressions(e.detail[0]['items'], 'Main Landing Products')
                initCount++
            }
        })


        // product tags increment quantity
        document.addEventListener(productTagsEvent.onUpdateIncrement, (e) => {
            let id = e.detail.payload.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                "quantity": e.detail.payload.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.payload.item
            let cart_items = e.detail.payload.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)

        })

        // product tags decrement quantity
        document.addEventListener(productTagsEvent.onUpdateDecrement, (e) =>{
            console.log(e.detail)
            let id = e.detail.payload.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                "quantity": e.detail.payload.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.payload.item
            let cart_items = e.detail.payload.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        // product tags removed item
        document.addEventListener(productTagsEvent.onDeleteItem, (e) => {
            console.log('e.detail', e.detail)
            let app_key = e.detail.detail.app_key

            let id = e.detail.detail.cartItemEntity
            this.removeCartItem(app_key, id)
        })

        // product tags manual quantity
        document.addEventListener(productTagsEvent.updateQuantity, (e) => {
            console.log(e.detail)
            let id = e.detail.detail.id
            let app_key = e.detail.detail.app_key
            let payload = {
                "quantity": e.detail.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.detail.item
            let cart_items = e.detail.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        // show description
        document.addEventListener(productTagsEvent.onShowProductDetails,(e) => {
            handleShowProductActions(e.detail.product_details)
            // this.proceedToApp(e.detail.x_app_key)

            let brand_id = handleLandersBrandId(e.detail.product_details.brand_id, e.detail.app_key)
            localStorage.setItem('brand_id', brand_id)

            this.setState({
                app_key: e.detail.x_app_key,
                product: e.detail.product_details
            }, () => {
                this.props.history.push(`?pdesc=${e.detail.product_details.id}`)
                this.productDescription(e.detail.product_details)
                this.productDescriptionSubscription()
            })
            
        })

        document.addEventListener(productTagsEvent.onViewMore, (e) =>{
            this.proceedToApp(e.detail)
        })


        // product description component event
        let productDescriptionEvent = window.serino_components.ProductDescriptionComponent.events
        let productDescriptionSubscription = window.serino_components.ProductDescriptionComponent.subscriptions
        
        document.addEventListener(productDescriptionEvent.addtoCart, (e) => {
            triggerSerinoEvent(productDescriptionSubscription.setButtonLoading, true)

            let brand_id = handleLandersBrandId(e.detail.product_details.id.brand_id, e.detail.app_key)
            localStorage.setItem('brand_id', brand_id)
            this.setState({
                product: e.detail.product_details.id,
                app_key: e.detail.app_key
            }, () => {
                if (this.state.app_key == process.env.REACT_APP_MARKET_APP_ID) {
                    this.addToCartMarket(e.detail.product_details.id)
                } else {
                    this.addToCartEats(e.detail.product_details.id, e.detail.product_details.variants)
                }
                // this.addProductToCartComponent(e.detail.product_details.id)
            })
            
            console.log(e.detail)
        })

        // product description manual quantity
        document.addEventListener(productDescriptionEvent.updateQuantity, (e) => {
            console.log('product description details', e.detail)
            let id = e.detail.detail.id
            let app_key = e.detail.detail.app_key
            let payload = {
                "quantity": e.detail.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.detail.item
            let cart_items = e.detail.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        // removed item from description modal
        document.addEventListener(productDescriptionEvent.onDeleteItem, (e) => {
            console.log(e.detail)
            let app_key = e.detail.app_key

            let id = e.detail.cartItemId
            this.removeCartItem(app_key, id)
        })

        // quantity from description modal
        document.addEventListener(productDescriptionEvent.onDecrementCart, (e) => {
            let id = e.detail.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.item
            let cart_items = e.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        // quantity from description modal
        document.addEventListener(productDescriptionEvent.onIncrementCart, (e) => {
            console.log(e.detail)
            let id = e.detail.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.item
            let cart_items = e.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        // similar items add to cart
        let similarProductEvent = window.serino_components.SimilarProductsComponent.events
        document.addEventListener(similarProductEvent.onAddToCart('best-pick-similar-item'), (e) => {
            this.addProductToCartComponent(e.detail.product_details)
        })

        document.addEventListener(similarProductEvent.onGetSimilarItems('best-pick-similar-item'), (e) => {
            this.setState({ similar_items: e.detail })
        })

        document.addEventListener(similarProductEvent.onUpdateIncrement('best-pick-similar-item'), (e) => {
            let id = e.detail.cartItemId
            let app_key = process.env.REACT_APP_MARKET_APP_ID
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.item
            let cart_items = e.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        document.addEventListener(similarProductEvent.onUpdateDecrement('best-pick-similar-item'), (e) => {
            let id = e.detail.cartItemId
            let app_key = process.env.REACT_APP_MARKET_APP_ID
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.item
            let cart_items = e.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        document.addEventListener(similarProductEvent.onDeleteItem('best-pick-similar-item'), (e) => {
            let app_key = process.env.REACT_APP_MARKET_APP_ID
            let id = e.detail.cartItemId
            this.removeCartItem(app_key, id)
        })

        document.addEventListener(similarProductEvent.updateQuantity('best-pick-similar-item'), (e) => {
            let id = e.detail.cartItemId
            let app_key = process.env.REACT_APP_MARKET_APP_ID
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.item
            let cart_items = e.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        document.addEventListener(similarProductEvent.onShowProductDetails('best-pick-similar-item'), (e) => {
            handleShowProductActions(e.detail.product_details)
            let brand_id = handleLandersBrandId(e.detail.product_details.brand_id, e.detail.app_key)
            localStorage.setItem('brand_id', brand_id)

            this.setState({
                app_key: e.detail.x_app_key,
                product: e.detail.product_details
            }, () => {
                this.props.history.push(`?pdesc=${e.detail.product_details.id}`)
                this.productDescription(e.detail.product_details)
            })
        })

        // fulfillment type event CONFIRM
        let fulfillmentTypeEvent = window.serino_components.FulfillmentTypeComponent.events
        // document.addEventListener(fulfillmentTypeEvent.onConfirm, (e) => {
        //     log('on fulfillment confirm event', e)
        //     let branch_id = e.detail.detail.branch_id
        //     if (branch_id) {
        //         this.addToCartComponent(this.state.product, branch_id)
        //     }
        // })

        // let cartEvents = window.serino_components.cart.events

        // document.addEventListener(cartEvents.onLoad('marketCartMain'), (event) => {
        //     this.getAllCart()
        // })

        // document.addEventListener(cartEvents.onCartUpdated('marketCartMain'), (event) => {
        //     triggerSerinoEvent(productDescriptionSubscription.setButtonLoading, false)
        // })

        // document.addEventListener(cartEvents.onLoad('eatsCartMain'), (event) =>  {
        //     this.getAllCart()
        // })

        // document.addEventListener(cartEvents.onCartUpdated('eatsCartMain'), (event) =>  {
        //     triggerSerinoEvent(productDescriptionSubscription.setButtonLoading, false)
        // })


        setTimeout(async() => {
            // if (!localStorage.getItem('oldCartRemoved')) {
            //     let clearCartMarketResult = await cart.clearCart(localStorage.getItem('access_token'), market_app_id)
            //     let clearCartEatsResult = await cart.clearCart(localStorage.getItem('access_token'), eats_app_id)
            //     localStorage.setItem('oldCartRemoved', 1)
            //     // sessionStorage.removeItem('initial_login')
            // }

            this.getAllCart(null, true)
        }, 1700)

        // get URL PARAM
        if (this.props.location.search.length > 0) {
            let search = this.props.location.search.substring(1)
            let objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            let product_id = objFromUrl.pdesc
            
            application.getProductDetails(x_app_key, product_id)
                .then((res) => {
                    this.setState({app_key: res.data.items[0]['application_id']})
                    this.productDescription(res.data.items[0], res.data.items[0]['application_id'])
                })
        }

        // let component = window.serino_components.BrandListComponent.create(
        //   {
        //     access_token: localStorage.getItem('access_token'),
        //     x_app_key: eats_app_id,
        //     time_interval: 10000,
        //     status: 1, // blank = all, 0 = inactive, 1 = active
        //   },
        //   "srn-brand-selector-container"
        // );

        // let brandListEvents =
        //   window.serino_components.BrandListComponent.events;
        // document.addEventListener(brandListEvents.onBrandSelect, (e) => {
        //   console.log("onBrandSelect", e.detail);
        //   this.handleGotoProducts(e.detail);
        // });
    }

    userRole = async () => {

        log('userRole')

        // let isLoggedIn = localStorage.getItem('isLoggedIn')
        let isLoggedIn = "false"

        let categoryListSubscription = window.serino_components.CategoryListComponent.subscriptions
        let categoryUserRole = new CustomEvent(categoryListSubscription.userRole, {
            detail: isLoggedIn
        })
        document.dispatchEvent(categoryUserRole)
    }

    onLogout = () => {
        this.setState({
            isLoggedIn: false
        })

        localStorage.setItem('isLoggedIn', false)
        localStorage.removeItem('isMember')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('Address')
        localStorage.removeItem('fulfillment_type_status')
        localStorage.removeItem('category_branch_id')
        localStorage.removeItem('SRN-CART-ID')
        localStorage.removeItem('isMember')
        localStorage.removeItem('main_address')
        localStorage.removeItem('default_address')
        localStorage.removeItem('cart_items')
        localStorage.removeItem('SRN-CURRENT-APPS')
        localStorage.setItem(`address_object_${process.env.REACT_APP_EATS_APP_ID}`, '[]')
        localStorage.setItem(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`, '[]')
        window.location = '/login'
    }

    getCartMarket = (cartResponse) => {
        this.setState({
            market_cart: cartResponse.items
        })
    }

    getCartEats = (cartResponse) => {
        this.setState({
            eats_cart: cartResponse.items
        })
    }

    updateCartQuantity = (app_key, id, payload, cart_item) => {
        log('cart_item', cart_item)
        let access_token = localStorage.getItem('access_token')
        cart.updateQuantity(access_token, app_key, id, payload)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    if (app_key == process.env.REACT_APP_EATS_APP_ID) {
                        // for google analytics
                        updateCartAnalytics()
                        if (cart_item) {
                            let action = null
                            if (payload.quantity > cart_item.quantity) {
                                action = 'increment'
                            }
                            if (payload.quantity < cart_item.quantity) {
                                action = 'decrement'
                            }
                            updateCartHub(cart_item, action)
                        }
                    }
                    this.getAllCart({items: this.state.cart_items})
                }
            })
            .catch((err) => {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken(2)
                }
                console.log(err)
            })
    }

    removeCartItem = (app_key, id) => {
        let access_token = localStorage.getItem('access_token')
        cart.removeCartItem(access_token, app_key, id, this.state.cart_items)
            .then((res) => {
            log('remove cart item called', res)

                this.setState({
                    cart_items: this.state.cart_items.filter(x => x.id != id)
                }, () => {
                    log('caert items after remove', this.state.cart_items)
                    this.getAllCart(res)
                })

            })
            .catch((err) => {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
                console.log(err)
            })
    }

    productDescription = (detail) => {
        $('#descriptionModal').modal('show')
        similar_items_id.push(detail.id) // product id for similar item

        log('detail', detail)
        log('this.state.app_key', this.state.app_key)

        let productDescription = window.serino_components.ProductDescriptionComponent.create({
            product: detail,
            currency: '₱',
            mode: (parseInt(this.state.app_key) == 1 || parseInt(this.state.app_key) == 7) ? 1 : 2,
            x_app_key: this.state.app_key
        }, "srn-product-description-container");

        let similarProductScubscription = window.serino_components.SimilarProductsComponent.subscriptions
        let initSimilarProducts = new CustomEvent(similarProductScubscription.onLoad('best-pick-similar-item'), {
            detail: similar_items_id
        })
        document.dispatchEvent(initSimilarProducts)

        
        let similarProduct = window.serino_components.SimilarProductsComponent.create({
            access_token: localStorage.getItem('access_token'),
            x_app_key: x_app_key,
            branch_id: 6,
            time_interval: 8000,
            currency: "₱",
            product_ids: similar_items_id,
            id: 'best-pick-similar-item'
        }, "srn-similar-product-container")

        $('#descriptionModal').animate({ scrollTop: 0 }, 'fast')

        // this.getCart(localStorage.getItem('access_token'), this.state.app_key)
    }

    addToCartMarket = async (product) => {

        log('product from addToCartMarket', product)

        localStorage.removeItem('fulfillment_mode')

        if (this.state.isAddBtnLoading == product.id) {
            return
        }

        this.setState({
            isAddBtnLoading: product.id
        })

        triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, product.id)
        console.log('product details', product)
        
        const proceed = async (branch_id) => {
            console.log('proceeded', product)
            let price
            let price_object = {}
            
            let member = product.product_prices.find((p => {
                return "member" === p.name
            }))
            let nonmember = product.product_prices.find((p => {
                return "nonmember" === p.name
            }))

            if (membershipChecker()) {
                price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
                price_object = member
            } else {
                price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
                price_object = nonmember
            }

            let custom_data = []

            let savings = {
                name: 'savings',
                value: nonmember.price - member.price
            }

            if (product.sell_by_type && product.sell_by_type == 1) {
                custom_data.push({
                    name: 'weight',
                    value: product.weight
                })
            }

            if (product.barcode && product.barcode.length > 0) {
                custom_data.push({
                    name: 'barcode',
                    value: product.barcode
                })   
            }

            custom_data.push(savings)
            custom_data.push(price_object)

            // SAVE ADDRESS IN CART START
            if (getAddressFromObjectChecker) {
                let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

                if (!address) {
                    triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
                }

                log({address})

                custom_data.push({
                    name: 'address',
                    value: address
                })
            }
            // SAVE ADDRESS IN CART END

            let payload = {
                "itemReferenceId": product.id,
                "itemReferenceType": product.type,
                "itemName": product.name,
                "itemSKU": product.sku,
                "itemImage": getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
                "itemDescription": product.description,
                "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
                "basePrice": nonmember.price,
                "price": price,
                "instructions": "",
                "domainReferenceId": branch_id ? branch_id  : localStorage.getItem('branch_id'),
                "domainReferenceType": "1",
                "item_type": product.sell_by_type ? product.sell_by_type : 0,
                "include_promo": true
            }

            if (custom_data && custom_data.length > 0) {
                payload.custom_data = JSON.stringify(custom_data)
            }
            
            try {
                const addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), process.env.REACT_APP_MARKET_APP_ID, payload)

                log('addCartItemResult', addCartItemResult)

                setTimeout(() => {
                    this.getAllCart(addCartItemResult)
                }, 100)

            }
            catch (err) {
                log({err})
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
            }


        }

        // if(localStorage.getItem('market_branch_id') != null) {
        //     proceed(localStorage.getItem('market_branch_id'))
        // }
        // else {
            let address_object_string = localStorage.getItem(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`)

            if (address_object_string && address_object_string.length > 2) {
                let address_object = JSON.parse(address_object_string)
                let address = address_object.find(x => x.brand_id == localStorage.getItem('brand_id'))
                if (address) {
                    if (address.branch_id && address.branch_id != null) {
                        localStorage.setItem('market_branch_id', address.branch_id)
                        localStorage.setItem('branch_id', address.branch_id)
                        proceed(address.branch_id)
                    }
                    else {
                        this.handleMainAddress()
                    }
                }
                else {
                    this.handleMainAddress()
                }
            }
            else {
                this.handleMainAddress()
            }
        // }
    }

    handleMainAddress = () => {
        this.setState({isAddBtnLoading: false})
        mainAddressHandler()
    }

    handleCartDuplicate = (product, variants) => {
        return this.state.cart_items.filter(x => x.itemReferenceId == product.id && x.optionIds == variants.option_ids.toString()).length > 0
    }

    addToCartEats = async (product, variants) => {
        if (this.state.isAddBtnLoading == product.id) {
            return
        }
        this.setState({
            isAddBtnLoading: product.id
        })

        triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, product.id)

        if (this.handleCartDuplicate(product, variants)) {
            return;
        }

        let custom_data = []

        let price_object = product.product_prices.find(x => x.name == 'nonmember')

        if (price_object) {
            custom_data.push(price_object)
        }

        const proceed = async (branch_id) => {

            let payload = {
                "itemReferenceId": product.id,
                "itemReferenceType": product.type,
                "itemName": product.name,
                "itemSKU": product.sku,
                "itemImage": getThumbnail(product) ? getThumbnail(product) : product.images,
                "itemDescription": product.description,
                "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
                "basePrice": product.price,
                "price": product.newPrice ? product.newPrice : product.price,
                "instructions": "",
                "domainReferenceId": branch_id ? branch_id  : localStorage.getItem('branch_id'),
                "domainReferenceType": "1",
                "item_type": product.sell_by_type ? product.sell_by_type : 0,
                "include_promo": true
            }

            // SAVE ADDRESS IN CART START
            if (getAddressFromObjectChecker) {

                let address = getAddressObject(branch_id, eats_app_id)

                if (!address) {
                    triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
                }

                custom_data.push({name: 'address', value: address})
            }
            // SAVE ADDRESS IN CART END

            if (variants) {
                payload.optionIds = variants.option_ids.toString()
                payload.optionText = variants.option_texts.join('|')
            }

            if (custom_data && custom_data.length > 0) {
                payload.custom_data = JSON.stringify(custom_data)
            }

            try {
                const addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), process.env.REACT_APP_EATS_APP_ID, payload)

                // triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.setButtonLoading, false)

                // log('addCartItemResult', addCartItemResult)

                // for google analytics
                addToCartAnalytics()
                addToCartHub(product, branch_id)

                setTimeout(() => {
                    this.getAllCart(addCartItemResult)
                }, 100)

            }
            catch(err) {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
                log(err)
            }
        }

        let address_object_string = localStorage.getItem(`address_object_${process.env.REACT_APP_EATS_APP_ID}`)
        
        if (address_object_string && address_object_string.length > 2) {
            let address_object = JSON.parse(address_object_string)
            let address = address_object.find(x => x.brand_id == localStorage.getItem('brand_id'))
            if (address) {
                if (address.branch_id && address.branch_id != null) {
                    // localStorage.removeItem('brand_id')
                    sessionStorage.setItem('eats_branch_id', address.branch_id)
                    localStorage.setItem('branch_id', address.branch_id)
                    proceed(address.branch_id)
                }
                else {
                    this.handleMainAddress()
                }
            }
            else {
                this.handleMainAddress()
            }
        }
        else {
            this.handleMainAddress()
        }
    }

    findUpdatedProduct = (productProps) => {
        let product = this.state.eatsProducts.find(x => x.id == productProps.id)
        log('findUpdatedProduct', product)

        if (product) {
            if (productProps && productProps.quantity && productProps.quantity > 0) {
                product.quantity = productProps.quantity
            }
            return product
        }
        else {
            return null
        }
    }


    // ADD TO CART AFTER ADDRESS SET UP
    addToCartComponent = async (productProps, branch_id) => {

        let product = productProps

        if (this.state.app_key == eats_app_id) {
            product = this.findUpdatedProduct(product)
        }

        if (!product){
            return;
        }

        let addCartItemResult;

        if (!product.id) {
            return;
        }

        if (this.state.isAddBtnLoading == product.id) {
            return
        }

        this.setState({
            isAddBtnLoading: product.id
        })

        triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, product.id)

        if (this.state.app_key == process.env.REACT_APP_MARKET_APP_ID) {
            console.log('app', this.state.app_key)
            let price

            let product_price = product.product_prices.find((p => {
                if  (membershipChecker()) {
                    return p.name === "member"
                }
                else {
                    return p.name === "nonmember"
                }
            }))

            price = parseFloat(product_price.discounted_price) > 0 ? parseFloat(product_price.discounted_price) : product_price.price

            let member = product.product_prices.find(p => p.name === "member")
            let nonmember = product.product_prices.find(p => p.name === "nonmember")

            let custom_data = []

            let savings = {
                name: 'savings',
                value: nonmember.price - member.price
            }

            if (product.sell_by_type && product.sell_by_type == 1) {
                custom_data.push({
                    name: 'weight',
                    value: product.weight
                })
            }

            if (product.barcode && product.barcode.length > 0) {
                custom_data.push({
                    name: 'barcode',
                    value: product.barcode
                })   
            }

            custom_data.push(savings)
            custom_data.push(product_price)
            
            // SAVE ADDRESS IN CART START
            if (getAddressFromObjectChecker) {

                let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

                if (!address) {
                    triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
                }

                log({address})

                custom_data.push({
                    name: 'address',
                    value: address
                })
            }
            // SAVE ADDRESS IN CART END


            let payload = {
                "itemReferenceId": product.id,
                "itemReferenceType": product.type,
                "itemName": product.name,
                "itemSKU": product.sku,
                "itemImage": getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
                "itemDescription": product.description,
                "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
                "basePrice": nonmember.price,
                "price": product.newPrice ? product.newPrice : price,
                "instructions": "",
                "domainReferenceId": branch_id ? branch_id  : localStorage.getItem('branch_id'),
                "domainReferenceType": "1",
                "item_type": product.sell_by_type ? product.sell_by_type : 0,
                "include_promo": true
            }

            if (custom_data && custom_data.length > 0) {
                payload.custom_data = JSON.stringify(custom_data)
            }

            try {
                addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), this.state.app_key, payload)
            }
            catch(err) {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
            }


            log({addCartItemResult})

        } else {
            console.log('app', this.state.app_key)
            let payload = {
                "itemReferenceId": product.id,
                "itemReferenceType": product.type,
                "itemName": product.name,
                "itemSKU": product.sku,
                "itemImage": getThumbnail(product) ? getThumbnail(product) : product.images,
                "itemDescription": product.description,
                "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
                "basePrice": product.price,
                "price": product.price,
                "instructions": "",
                "domainReferenceId": branch_id ? branch_id  : localStorage.getItem('branch_id'),
                "domainReferenceType": "1",
                "item_type": product.sell_by_type ? product.sell_by_type : 0,
                "include_promo": true
            }

            try {
                addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), process.env.REACT_APP_EATS_APP_ID, payload)
            }
            catch(err) {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
            }

            log({addCartItemResult})

            // for google analytics
            addToCartAnalytics()
            addToCartHub(product, branch_id)
        }

        setTimeout(() => {
            this.getAllCart(addCartItemResult)
        }, 100)
    }

    addProductToCartComponent = async (product) => {

        log('product', product)

        let price
        let itemImage
        if (membershipChecker()) {
            let member = product.product_prices.find((p => {
                return "member" === p.name
            }))
            price = member.price
            itemImage = product.product_images[0]
        } else {
            let nonmember = product.product_prices.find((p => {
                return "nonmember" === p.name
            }))
            price = nonmember.price
            itemImage = product.images
        }
        let member = product.product_prices.find(p => p.name === "member")

        let nonmember = product.product_prices.find(p => p.name === "nonmember")

        let custom_data = []

        let savings = {
            name: 'savings',
            value: nonmember.price - member.price
        }

        custom_data.push(savings)
        custom_data.push(nonmember)

        const proceed = async (branch_id) => {

            log('product', product)

            let payload = {
                "itemReferenceId": product.id,
                "itemReferenceType": product.type,
                "itemName": product.name,
                "itemSKU": product.sku,
                "itemImage": itemImage,
                "itemDescription": product.description,
                "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
                "basePrice": price,
                "price": product.newPrice ? product.newPrice : price,
                "instructions": "",
                "domainReferenceId": branch_id ? branch_id  : localStorage.getItem('branch_id'),
                "domainReferenceType": "1",
                "item_type": product.sell_by_type ? product.sell_by_type : 0,
                "include_promo": true
            }

            if (custom_data && custom_data.length > 0) {
                payload.custom_data = JSON.stringify(custom_data)
            }
            let cartSubscriptions = window.serino_components.cart.subscriptions

            try {
                const addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), this.state.app_key, payload)

                setTimeout(() => {
                    this.getAllCart(addCartItemResult)
                }, 1000)
            }
            catch(err) {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
            }
        }

        if(localStorage.getItem('branch_id')) {
            proceed(localStorage.getItem('branch_id'))
        }
        else {
            let address_object = this.state.address_object
            if (address_object && address_object.length > 0) {            
                let address = address_object.find(x => x.brand_id == localStorage.getItem('brand_id'))
                log('product', this.state.product.brand_id)
                if (address) {
                    if (address.branch_id && address.branch_id != null) {
                        localStorage.setItem('branch_id', address.branch_id)
                        proceed(address.branch_id)
                    }
                    else {
                        $('#fulfillmenttype_modal').modal('show')
                        this.setState({isAddBtnLoading: false})
                        $('#descriptionModal').modal('hide')
                    }
                }
                else {
                    $('#fulfillmenttype_modal').modal('show')
                    this.setState({isAddBtnLoading: false})
                    $('#descriptionModal').modal('hide')
                }
            }
            else {
                $('#fulfillmenttype_modal').modal('show')
                this.setState({isAddBtnLoading: false})
                $('#descriptionModal').modal('hide')
            }
        }

    }

    getCart = (access_token, x_app_key) => {
        cart.getCart(access_token, x_app_key)
        .then((res) => {            
            this.setState({
                cart_items: res.data
            }, () => {
                // update cart items in component
            })
        })
    }

    getAllCart = async (cartResult, isInitial = false) => {
        let all_cart_items = []

        // all_cart_items = await getCrossCart(localStorage.getItem('access_token'))

        if (isInitial === false) {
            let newCartItems = cartResult.items
            // temporary fix for cart count
            localStorage.setItem('market_cart_count', newCartItems.length)
            let current_items = this.state.cart_items
            let newCartList = current_items.concat(newCartItems)

            // remove duplicates

            var uniq = newCartList.reduce(function(a,b){
              function indexOfProperty (a, b){
                  for (var i=0;i<a.length;i++){
                      if((a[i].itemReferenceId == b.itemReferenceId) && (a[i].optionIds == b.optionIds)){
                           return i;
                       }
                  }
                 return -1;
              }

              if (indexOfProperty(a,b) < 0 ) a.push(b);
                return a;
            },[]);

            if (uniq) {
                all_cart_items = uniq
            }
            else {
                all_cart_items = this.state.cart_items
            }

            let cartCount = uniq.reduce((a, b) => +a + +b.quantity, 0)

            $('.srn-cart-quantity').text(cartCount)
        }
        else {
            all_cart_items = await getCrossCart(localStorage.getItem('access_token'))

            await this.setExistingAddress(all_cart_items)

            if (sessionStorage.getItem('initial_login') && all_cart_items.length < 1) {
                this.handleTempCart(all_cart_items)
            }
        }

        
        if (this.state.isSearchActive) {
            this.handleSearchDisplay(true)
        }

        this.setState({
            cart_items: all_cart_items,
            isAddBtnLoading: false,
            isSearchActive: false,
            product: {}
        }, () => {
            if (isInitial) {
                // remove old cart items
                this.cleanCart(all_cart_items)
            }
            getAddressesFromCart(all_cart_items)
            localStorage.setItem('cart_items', JSON.stringify(all_cart_items))
            triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
            triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.setButtonLoading, false)
            this.productTagsSubscription()
            this.productDescriptionSubscription()
            this.productListSubscription()
            this.productSearchSubscription()
        })

        return all_cart_items

    }

    productTagsSubscription = () => {
        setTimeout(() => {
            let tags = ['main-landing-best-pick','main-landing-best-deal']
            tags.map(tag => {
                triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.onCartItems(tag), this.state.cart_items)
            })
        })
    }

    productDescriptionSubscription = () => {
        // console.log('productDescription::', this.state.cart_items)
        // subscription for cart quantity
        log('cart items from product description', this.state.cart_items)
        let productDescriptionSubscription = window.serino_components.ProductDescriptionComponent.subscriptions
        let productDescriptionItems = new CustomEvent(productDescriptionSubscription.onCartItems, {
            detail: this.state.cart_items
        })
        document.dispatchEvent(productDescriptionItems)
    }

    productListSubscription = async () => {
        let productListSubscription = window.serino_components.ProductListComponent.subscriptions;
        triggerSerinoEvent(productListSubscription.onCartItems, this.state.cart_items)
    }
    
    productSearchSubscription = () => {
        let productSearchSubscription = window.serino_components.SearchProductListComponent.subscriptions
        let similarProduct = new CustomEvent(productSearchSubscription.onCartItems, {
            detail: this.state.cart_items
        })
        document.dispatchEvent(similarProduct)
    }

    proceedToApp = (app_id) => {
        window.location = `${getUrl(app_id == market_app_id ? 'market' : 'eats')}`
    }

    handleRedirect = () => {
        sessionStorage.setItem('signupredirect', true)
        window.location = '/login'
    }

    getAddressObject = (address_object) => {
        this.setState({address_object})
    }

    handleLocationChange = (address_object, branch_id, main_address) => {
        log('handleLocationChange 1')
        this.setState({address_object, cart_items: [], branch_id: branch_id }, () => {
            setCurrentBranches()
            let branch = branch_id
            if (branch) {
                log('handleLocationChange 2')
                triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.initializeProducts('main-landing-best-deal'))
                if (this.state.product && this.state.product.id) {
                    handleInitialAddToCart(this.state.product, branch_id, this.addToCartComponent)
                }
            }
            else {
                this.productTagsSubscription()
                this.productDescriptionSubscription()
                this.productListSubscription()
                this.productSearchSubscription()
            }
            // this.props.onLocationChange()
        })
    }

    handleModalClose = () => {
        this.productTagsSubscription()
        this.productDescriptionSubscription()
        this.productListSubscription()
        this.productSearchSubscription()
        triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
    }

    updateCartItems = (event, summaryId, cartSummaryId) => {        
        let cartSummarySubscriptions = window.serino_components.cartSummary.subscriptions

        let cart = event.detail.cart

        // cart.cart_items = cart.cart_items.filter(x => x.selected == true)

        let app = {
            cart: cart,
            app: event.detail.app
        }

        let apps = this.state.apps

        const updateCartItemsAndCount = (apps) => {

            let count = apps.map(app => app.cart).length
            // let count = apps.map(app => app.cart.length).reduce((a, b) => +a + +b, 0)

            let cart_items = []
            let carts = apps.map(app => app.cart)
            if (carts.map && carts.length > 0) {
                carts.map(cart => cart.map(x => x.cart_items.map(y => cart_items.push(y))))
            }

            this.setState({cart_items})
            
            let cartCount = cart_items.reduce((a, b) => +a + +b.quantity, 0)
            if (cartCount > 0) {
                log('apps', apps)
                log('cart_items', cart_items)
                log('cartCount', cartCount)
                $('.srn-cart-quantity').text(cartCount)
            }

        }
        
        if (apps.filter(e => e.app.id == app.app.id).length > 0) {
            let appIndex = apps.findIndex(e => e.app.id == app.app.id)
            apps[appIndex] = app
            // console.log(apps[appIndex]['cart'].length)
            this.setState({
                apps,
                cartQuantity: apps.length > 1 ? this.getCartQuantity(apps) : 'null',                
            }, () => {
                updateCartItemsAndCount(apps)
            })
        }
        else {
            apps.push(app)
            this.setState({
                apps,
                cartQuantity: apps.length > 1 ? this.getCartQuantity(apps) : 'null',                
            }, () => {
                updateCartItemsAndCount(apps)
            })
        }

        log('apps', apps)
        // log('apps', apps.length)
        triggerSerinoEvent(cartSummarySubscriptions.updateCartItems(summaryId), app)

    }

    getCartQuantity = (apps) => {        
        let cart_items = apps.map((item) => item.cart.reduce((a, b) => +a + +b.cart_items.length, 0))
        if (cart_items && cart_items.length > 0) {
            return cart_items.reduce((a, b) => a + b, 0)
        }
        else {
            return 0
        }
    }

    handleCollectionSelect = (collection) => {

        // log(`${getUrl(collection.application_id == eats_app_id ? 'eats' : 'market')}-products?type=bundle&collection_id=${collection.id}&collection_name=${collection.name}`)

        if (collection.application_id == market_app_id) {
            window.location = `${getUrl('market')}-products?type=bundle&collection_id=${collection.id}&collection_name=${collection.name}`
        }
        else {
            window.location = `${getUrl('eats')}`
        }

    }

    handleGotoProducts = (brand) => {
        var str = brand.name;
        str = str.replace(/\s+/g, "-").toLowerCase();
        localStorage.setItem("brand_logo", brand.logo);
        localStorage.setItem("brand_id", brand.id);

        localStorage.removeItem("branch_id");
        localStorage.removeItem("Address");
        localStorage.removeItem("brand_name");
        localStorage.removeItem("brand_best_seller");
        localStorage.removeItem("fulfillment_type_status");
        localStorage.setItem("brand_name", str + "-brand");
        localStorage.setItem("brand_name_str", brand.name);
        localStorage.setItem("brand_best_seller", brand.name.toLowerCase());
        localStorage.setItem("brand_id", brand.id.toString());
        sessionStorage.setItem("viewAction", "brandSelect");
        localStorage.setItem("brandN", str);
        sessionStorage.removeItem("foodType");
        sessionStorage.removeItem("viewAllAction");
        sessionStorage.removeItem("searchKeyWord");
        setTimeout(() => {
          // this.props.history.push(`/eats-products?${str}`);
          window.location = `/eats-products?${str}`
        }, 300);
    };

    getDataFromJSON = async () => {
        let brands = []
        let omrs_brands = []
        let apps = []
        let mid_banner = null

        try {
            const dataFromJSON = await coreAPI.getDataFromJSON()

            if (!dataFromJSON) {
                return
            }

            if (dataFromJSON.data && dataFromJSON.data.brands) {
                // if (this.state.isOMRSEnabled) {
                //     let brandsAPIResult = await brandsAPI.getBrands(localStorage.getItem('access_token'), eats_app_id)

                //     if (brandsAPIResult && brandsAPIResult.data) {
                //         omrs_brands = brandsAPIResult.data
                //         brands = dataFromJSON.data.brands.map(brand => {
                //             let omrs_brand = omrs_brands.find(x => x.id == brand.id)
                //             return {
                //                 ...brand,
                //                 status: omrs_brand ? omrs_brand.status : brand.status
                //             }
                //         })
                //         log('updated brands', brands)
                //     }
                // }
                // else {
                    brands = dataFromJSON.data.brands
                    let allBrands = brands.filter(x => x.status == 1)
                    setStorage('srn-active-brands', JSON.stringify(allBrands))
                // }
                setCurrentBranches()
            }

            if (dataFromJSON.data && dataFromJSON.data.apps) {
                apps = dataFromJSON.data.apps
                // Get middle banners with position === 'main_homepage'
                const mid_banners = dataFromJSON?.data?.mid_banners
                if (mid_banners && mid_banners?.length > 0) {
                    mid_banner = mid_banners?.find(x => x?.position === 'main_homepage' && x?.status == 1) || null;
                }
            }
            
            this.setState({
                brands,
                apps,
                mid_banner
            })
        }
        catch(getDataErr) {
            log(getDataErr)
        }
    }

    getMarketBranch = () => {
        let addressString = localStorage.getItem(`address_object_${market_app_id}`)

        if (!addressString) {
            return null
        }

        let parsed = JSON.parse(addressString)

        if (!parsed) {
            return null
        }

        let branch_id = parsed.branch_id

        if (!branch_id) {
            return null
        }

        return branch_id

    }

    addSearchEvents = () => {

        let searchComponentEvent = window.serino_components.SearchProductListComponent.events
        
        document.addEventListener(searchComponentEvent.onSelect, (e) => {
            if (e.detail && e.detail.product && e.detail.product.application_id) {
                this.setState({
                    app_key: e.detail.product.application_id
                }, () => {
                    handleShowProductActions(e.detail.product)
                    this.productDescription(e.detail.product, this.state.app_key)
                })
                
            }
        })

        document.addEventListener(searchComponentEvent.onAddToCart, (e) => {
            setTimeout(() => {
                this.handleSearchDisplay(true)
            }, 100)
            this.setState({
                isSearchActive: true
            })
            let brand_id = handleLandersBrandId(e.detail.brand_id, e.detail.application_id)
            localStorage.setItem('brand_id', brand_id)

            this.setState({product: e.detail, app_key: e.detail.application_id}, () => {
                if (e.detail.application_id == market_app_id) {
                    this.addToCartMarket(e.detail)
                }
                else {
                    this.addToCartEats(e.detail)
                }
            })
        })

        document.addEventListener(searchComponentEvent.updateQuantity, (e) => {
            this.setState({
                isSearchActive: true
            })
            let id = e.detail.detail.id
            let app_key = e.detail.detail.app_key
            let payload = {
                "quantity": e.detail.detail.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.detail.item
            let cart_items = e.detail.detail.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        document.addEventListener(searchComponentEvent.onUpdateIncrement, (e) => {
            this.setState({
                isSearchActive: true
            })
            log('search update increment')
            let id = e.detail.payload.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                "quantity": e.detail.payload.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.payload.item
            let cart_items = e.detail.payload.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        document.addEventListener(searchComponentEvent.onUpdateDecrement, (e) =>{
            this.setState({
                isSearchActive: true
            })
            console.log(e.detail)
            let id = e.detail.payload.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                "quantity": e.detail.payload.quantity,
                "include_promo": true
            }
            let cart_item = e.detail.payload.item
            let cart_items = e.detail.payload.cart_items
            this.updateCartQuantity(app_key, id, payload, cart_item, cart_items)
        })

        document.addEventListener(searchComponentEvent.onDeleteItem, (e) => {
            this.setState({
                isSearchActive: true
            })
            console.log('e.detail', e.detail)
            let app_key = e.detail.detail.app_key

            let id = e.detail.detail.cartItemEntity
            this.removeCartItem(app_key, id)
        })

        $('#descriptionModal').on('hidden.bs.modal', () => {
            similar_items_id = []
            setTimeout(() => {
                if (this.state.isSearchActive) {
                    this.handleSearchDisplay(true)
                }
            }, 300)
        })
    }

    handleSearchDisplay = (bool) => {
        document.getElementById('srn-product-search-result-container').style.display = 'block'
    }

    handleBundleAdd = (product) => {
        // this.setState({
        //     product: product
        // }, () => {
            this.addToCartMarket(product)
        // })
    }

    handleBundleDelete = (id) => {
        this.setState({
            cart_items: this.state.cart_items.filter(x => x.id !== id)
        })
    }

    cleanCart = async (cart_items) => {
        let marketAddresses = localStorage.getItem(`address_object_${market_app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_id}`)) : []
        let eatsAddresses = localStorage.getItem(`address_object_${eats_app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${eats_app_id}`)) : []

        let itemsToDelete = []

        for (const item of cart_items) {
            let parsed = null
            const custom_data = item.custom_data
            if (custom_data.slice(custom_data.length -1) !== ']') {
                log('ERROR IN CUSTOM DATA FIELD')
                itemsToDelete.push(item.id)
            }
            else {
                parsed = item.custom_data ? JSON.parse(item.custom_data) : null
                if (parsed) {
                    let custom_data = parsed.find(x => x.name === 'address')
                    if (!custom_data) {
                        itemsToDelete.push(item.id)
                    }
                    else {
                        let eats_address = eatsAddresses.find(x => x.branch_id == item.domainReferenceId)
                        let market_address = marketAddresses.find(x => x.branch_id == item.domainReferenceId)

                        if (!eats_address && !market_address) {
                            itemsToDelete.push(item.id)
                        }
                    }
                }
                else {
                    itemsToDelete.push(item.id)
                }
            }
        }

        log({itemsToDelete})

        if (itemsToDelete.length > 0) {
            let deleteCartItemsResultE = await cart.deleteCartItems(localStorage.getItem('access_token'), eats_app_id, itemsToDelete)
            let deleteCartItemsResultM = await cart.deleteCartItems(localStorage.getItem('access_token'), market_app_id, itemsToDelete)

            let newCartItems = cart_items.filter((el) => {
              return !itemsToDelete.includes(el.id);
            });

            log({newCartItems})

            this.getAllCart({items: newCartItems})
        }
    }

    getMarketBranch = () => {
        let parsed = localStorage.getItem(`address_object_${market_app_id}`) ? JSON.parse(localStorage.getItem(`address_object_${market_app_id}`)) : null
        if (parsed) {
            let address = parsed.find(x => x.app_id == market_app_id)
            if (address) {
                return address.branch_id
            }
            else {
                return 6
            }
        }
        else {
            return 6
        }
    }


    handleAppKeyUpdate = (appId) => {
        this.setState({
            app_key: appId
        })
    }

    getBrandsOMRS = async () => {
        log('getBrandsOMRS started')

        let brandsAPIResult = await brandsAPI.getBrands(localStorage.getItem('access_token'), eats_app_id)

        if (brandsAPIResult && brandsAPIResult.data) {
            let brands = brandsAPIResult.data

            if (brands && brands.length > 0) {
                brands = brands.map(brand => {
                    let promos = []
                    let featured_image = ""
                    let featured_products = []
                    let configs = brand.brand_configs

                    if (configs && configs.length > 0) {
                        promos = configs.find(x => x.name === 'promos')?.value || []

                        if (promos && promos.length > 0) {
                            promos = JSON.parse(promos)
                        }

                        featured_products = configs.find(x => x.name === 'featured_products')?.value || []

                        if (featured_products && featured_products.length > 0) {
                            featured_products = JSON.parse(featured_products)
                        }

                        featured_image = configs.find(x => x.name === 'featured_image')?.value || ""
                    }

                    return {
                        ...brand,
                        featured_image: featured_image,
                        featured_products: featured_products,
                        promos: promos
                    }
                })
            }

            this.setState({brands: brands.slice(0, 7)})
        }
    }

    handleMidBannerClick = (url) => {
        if (url && url?.length > 0) {
            window.location = url
        }
    }
    
    render() {
        return (
            <div className="d-flex flex-column h-100" id="centralmainlandingpage">
                {/*<ParallaxComponent page="main-landing" />*/}
                {
                    <div className={`container-fliud main-landing-container ${this.state.isSearchPageActive ? '' : 'd-none'}`}>
                     <SearchPageComponent
                        access_token={this.state.access_token}
                        query={this.state.query}
                        app={this.state.searchApp}
                        brand={this.state.searchBrand}
                     />
                    </div>
                }
                <div className={`container-fliud main-landing-container ${this.state.isSearchPageActive ? 'd-none' : ''}`}>
                    
                    <div className="srn-main-landing-main-container">
                        <EntryPoints data={this.state.apps} />
                        <div className="srn-main-landing-content srn-landing-container-v3">
                            {/* <div id="srn-search-product-list-container"></div> */}

                            <div className="srn-ad-container srn-ad-left">
                                <img className="srn-ad-img" src={eatsAdsright} />
                            </div>
                            {/* TEMPORARY REMOVED */}
                            {/* <h6 className="srn-3-new-title srn-srn-bundle-title">BUNDLED JUST FOR YOU</h6>
                            <BundleComponent
                                app_key={market_app_id}
                                id="market"
                                logo={<img src={MarketSVG} className="srn-bundle-logo-img" onClick={() => this.proceedToApp(1)}/>}
                                className="srn-bundle-market"
                                handleClick={this.handleCollectionSelect}
                            />
                            <BundleComponent
                                app_key={eats_app_id}
                                id="eats"
                                logo={<img src={EatsSVG} className="srn-bundle-logo-img" onClick={() => this.proceedToApp(2)}/>}
                                className="srn-bundle-eats"
                                handleClick={this.handleCollectionSelect}
                            /> */}
                            {
                                /** 
                                 * Middle Banner 
                                 * Get mid banner from brands JSON file
                                 */
                                this.state.mid_banner && this.state.mid_banner?.image?.length > 0 &&
                                <div className="srn-cntrl-mid-banner">
                                    <img src={this.state.mid_banner?.image} alt={this.state.mid_banner?.title} onClick={() => this.handleMidBannerClick(this.state.mid_banner?.url)} />
                                </div>
                            }
                           
                            <div className="srn-title-container-v3">
                                <h6 className="srn-title-v3">{this.state.apps?.find(x => x.id == eats_app_id) ? this.state.apps?.find(x => x.id == eats_app_id).title : 'Satisfy Your Cravings'}</h6>
                                <a href="#!" className="srn-title-link-v4" onClick={() => window.location = "/eats"}>Central Eats <FontAwesomeIcon icon={faAngleRight} className="ml-1" /></a>
                            </div>
                            <BrandsComponent 
                                data={this.state.brands}
                                isOMRSEnabled={this.state.isOMRSEnabled}
                                handlePageRedirect={this.handleGotoProducts}
                            />
                            <div className="cmb-best-brands-container d-none">
                                {
                                    // <div
                                    //     id="srn-brand-selector-container"
                                    //     className="srn-eats-brand-selector-container"
                                    // ></div>
                                }
                            </div>
                            {<div class="topdivider"></div>}
                            {
                                this.state.isShoppingBasketActive &&
                                <div className="srn-title-container-v3" id="shopping-baskets-title">
                                    <h6 className="srn-title-v3">Shopping Baskets</h6>
                                    <span onClick={() => window.location = "/market?shoppingbasket"}> View all</span>
                                </div>
                            }

                            {
                                this.state.isShoppingBasketActive &&
                                <ShoppingBasketComponent 
                                    app_key={market_app_id}
                                    id="market"
                                    logo={<img src={MarketSVG} className="srn-bundle-logo-img" onClick={() => this.proceedToApp(1)}/>}
                                    className="srn-bundle-market"
                                    handleClick={this.handleCollectionSelect}
                                    branch_id={this.getMarketBranch()}
                                    cart_items={this.state.cart_items}
                                    handleUpdateCart={this.getAllCart}
                                    handleInitialAdd={this.handleBundleAdd}
                                    handleDeleteItems={this.handleBundleDelete}
                                />
                            }
                            <div className="main-landing-best-eats-container d-none">
                                <div id="srn-product-tags-container-best-deal" className="srn-landing-best-deal-container  bestdealshover"></div>
                            </div>
                        </div>
                    </div>
                    <div className="srn-main-landing-main-container">
                        <div className="srn-main-landing-content srn-landing-container-v3">
                            <div className="srn-ad-container srn-ad-left">
                                {/* <img className="srn-ad-img" src={marketAdsright} /> */}
                            </div>
                            <div className="srn-mweb-ad-container srn-mt-2">
                                {/* <img className="srn-ad-img" src={mwebAds3} /> */}
                            </div>
                            <div class="topdivider"></div>
                            <div className="srn-title-container-v3 srn-title-grocery">
                                <h6 className="srn-title-v3">{this.state.apps?.find(x => x.id == market_app_id) ? this.state.apps?.find(x => x.id == market_app_id).title : 'Grocery Must-Haves'}</h6>
                                <a href="#!" className="srn-title-link-v3" onClick={() => window.location = "/market"}> <p>by </p><span>Central Market</span> <FontAwesomeIcon icon={faAngleRight} className="icon ml-1" /></a>
                            </div>
                            <div className="main-landing-best-market-container">
                                <div className="best-picks-title">
                                    <img src={BestPicksTitleWeb} className="best-picks-title-container" onClick={() => this.proceedToApp(1)}/>
                                </div>
                                <div id="srn-product-tags-container-best-pick" className="srn-products-best-picks bestpickshover"></div>
                            </div>
                            <div className="main-landing-categ">
                                {/* <h6 className="main-landing-categ-header">OR SHOP BY</h6> */}
                                <div id="srn-category-list-container-side" className="side-category-list"></div>
                                {/* <p className="main-landing-more-categ" onClick={() => this.proceedToApp(1)}><span>+ More Categories</span></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal srn-product-description-main" id="descriptionModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered srn-modal-md" role="document">
                        
                        <div className="modal-content" id={`x${this.state.app_key}`}>
                            <div className="modal-header srn-description-modal-header">
                                {
                                    this.state.app_key == process.env.REACT_APP_MARKET_APP_ID ?
                                        <img src={MarketLogo} className="srn-ft-brand-logo" />
                                        :
                                        <img src={EatsLogo} className="srn-ft-brand-logo" />
                                }
                                {/* <img src={brand_logo} className="srn-ft-brand-logo" /> */}
                                <button type="button" className="close d-block" data-dismiss="modal" aria-label="Close">
                                    <span >&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="srn-product-description-container"></div>
                                <h6 className={`srn-market-similar-product-label ${this.state.similar_items.length > 0 ? '' : 'd-none'}`}>Similar Products</h6>
                                <div
                                    id="srn-similar-product-container"
                                    className={`srn-market-similar-items-container ${this.state.similar_items.length > 0 ? '' : 'd-none'}`}>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>

                <div id="srn-market-cart-container" className="d-none"></div>
                <div id="srn-eats-cart-container" className="d-none"></div>
                <div id="srn-crm-addresslist-container" className="d-none"></div>
                
                {
                        //this.state.app_key && this.state.access_token ?
                        <LocationPicker 
                            access_token={this.state.access_token}
                            id="main_landing_page"
                            x_app_key={this.state.app_key}
                            hidden={true}
                            brand_id={this.state.product.brand_id}
                            handleLocationChange={this.handleLocationChange}
                            product={this.state.product}
                            handleModalClose={this.handleModalClose}
                            triggerGetDefaultAddress={this.state.triggerGetDefaultAddress}
                            main={true}
                            cart_items={this.state.cart_items}
                            page="main"
                        />
                        //: ''
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        cart_items: state.cart.cart_items
    };
}

export default connect(mapStateToProps)(MainLanding);