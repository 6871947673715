import { Component } from "react";
import ParallaxComponent from '../partials/ParallaxComponent';

export default class PrivacyPolicy extends Component {

    render() {  
        return (
            <div className="container-fluid" style={{marginTop: "3rem", padding: "0"}}>
                <ParallaxComponent page="generic" />
                <div className="privacy-policy-container">
                    <div className="col-12">
                        <h3 className="privacy-header py-3 text-center">Privacy Policy</h3>
                    </div>
                    <div className="privacy-content">
                        <div className="col-12">
                            <p>
                                This Security & Privacy Policy discloses the privacy practices for the Central by Landers website (the “Website” located at www.centraldelivery.ph). 
                                (Owner of Central by Landers), the provider of the Website (referred to as “us” or “we”), is committed to protecting your privacy online. 
                                Please read the following to learn what information we collect from you (the “User” or the “End User”) and how we use that information. 
                                If you have any questions about our security and privacy policy, please email us.
                            </p>
                        </div>
                        <div className="col-12">
                            <p>
                        
                                    PLEASE READ THIS SECURITY & PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING OUR WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, 
                                    UNDERSTAND, AND AGREE TO BE BOUND TO ALL THE TERMS OF THIS POLICY AND THE TERMS AND CONDITIONS OF OUR WEBSITE (LOCATED AT WWW.CENTRALDELIVERY.PH). 
                                    IF YOU DO NOT AGREE TO THESE TERMS, EXIT THIS PAGE AND DO NOT ACCESS OR USE THE WEBSITE.
                              
                            </p>
                        </div>
                        <div className="col-12">
                           <strong> <p className="cmb-title">1. Changes to Security & Privacy Policy</p> <br/> </strong>
                            <p>
                                If we decide to change our security and privacy policy, we will post those changes to this privacy statement, the home page, 
                                and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, 
                                if any, we disclose it. We reserve the right to modify this privacy statement at any time, so please review it frequently. 
                                If we make material changes to this policy, we will notify you here, by email, or by means of notice on our home page.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong><p className="cmb-title">2. Registration and Accounts</p> <br/></strong>
                            <p>
                                You do not need to Register to use much of the functionality of the Website or to access much of the Service. 
                                However, you must Register in order to make a Purchase from the Website. 
                                This is so that we can provide you with easy access to print your orders, view your past purchases and modify your preferences. 
                                We reserve the right to decline a new Registration or to cancel an account at any time.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong> <p className="cmb-title">3. Types of Information Collected and Uses of Collected Information</p> <br/></strong>
                            <p>We may collect two types of information about our Website Users: Personally Identifiable Information and Non-Personally Identifiable Information.</p>
                            <p>
                                Personally Identifiable Information. Personally Identifiable Information is information that identifies a specific End User. 
                                When you engage in certain activities on the Website, such as creating an account, ordering a product or service from us or our partners, 
                                submitting content and/or posting content in discussion forums, entering a contest or promotion sponsored by us or our partners, filling out a survey, 
                                    a review, sending us or our partners feedback, requesting information about our services, submitting an affiliate agreement, applying for a job, 
                                    posting a video or signing up for special offers from third parties through the Website (collectively, “Identification Activities”), 
                                    we may ask you to provide certain information about yourself. It is optional for you to engage in an Identification Activity. 
                                    If you elect to engage in an Identification Activity, however, we may ask you to provide us with certain personal information about yourself, 
                                    such as your first and last name, mailing address (including zip code), email address, telephone number, mobile number, and date of birth. 
                                    When you order products or services, we may also ask you to provide us with your credit card number, expiration date and authentication codes or related information. 
                                    Depending on the activity, some of the information we ask you to provide is identified as mandatory and some is identified as voluntary. 
                                    If you do not provide the mandatory information for a particular activity that requires it, you will not be permitted to engage in that activity.
                            </p>
                            <p>
                                Referral or Buy it for a Friend. If you choose to use our referral service or buy a deal for a friend, we will ask you for your friend's name and email address. 
                                We will automatically send your friend a one-time email with the gift you bought for them or inviting him or her to visit the site. 
                                We store this information for the sole purpose of sending this one-time email, sending their gift, and tracking the success of our referral program.
                            </p>
                            <p>
                                Your friend may contact us at support@central.ph to request that we remove this information from our database.
                            </p>
                            <p>
                                We use Personally Identifiable Information to provide products and services to you, administer promotions and contests, 
                                enhance the operation of the Website, improve our marketing and promotional efforts, analyze Website use, 
                                improve our product and service offerings, and to tailor your experience with third parties as provided in Item 3 of this Security & Privacy Policy. 
                                For example, if you send our customer service an email we may use your comments and feedback to tell others about our services, and may post your comment in our marketing materials or on our Website. 
                                If your comments may contain personal information such as your name, we will obtain consent from you to post your name along with your comment prior to posting your comment for public view. 
                                In addition, if you use our Website to send information or a product to another person, we may store your personal information. 
                                We may also use Personally Identifiable Information to troubleshoot, resolve disputes, accomplish administrative tasks, contact you, 
                                enforce our agreements with you, including our Website Terms and Conditions and this Security & Privacy Policy, comply with applicable law, and cooperate with law enforcement activities.
                            </p>
                            <p>
                                Non-Personally Identifiable Information. Non-Personally Identifiable Information is information that does not identify a specific End User. 
                                This type of information may include things like the Uniform Resource Locator (“URL”) of the website you visited before coming to our Website, 
                                the URL of the website you visit after leaving our Website, the type of browser you are using and your Internet Protocol (“IP”) address. 
                                We, and/or our authorized Third Party Service Providers and Advertisers, may automatically collect this information when you visit our Website through the use of electronic tools like Cookies and Web beacons or Pixel tags, 
                                as described in Items 4 and 8 of this Security & Privacy Policy.
                            </p>
                            <p>
                                We use Non-Personally Identifiable Information to troubleshoot, administer the Website, analyze trends, gather demographic information, comply with applicable law, and cooperate with law enforcement activities. 
                                We may also share this information with our authorized Third Party Service Providers and Advertisers to measure the overall effectiveness of our online advertising, content, and programming.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong> <p className="cmb-title">4. Release of Personally Identifiable Information</p> <br/></strong>
                            <p>We will not share, sell, rent, or trade your Personally Identifiable Information with other parties except as provided below:</p>
                            <p>
                               We may share your information with Central by Landers Merchants. From time to time, we provide offers from particular merchants (referred to as “Central by Landers Merchants”) who, 
                                as part of the offer, request information on Central by Landers customers who purchased the offer in order to aid redemption. 
                                In those cases, we share some of your Personally Identifiable Information with Central by Landers Merchants. 
                                Sharing this Information may allow a Central by Landers Merchant to market directly to you should they choose to do so. 
                                However, we will only share Personally Identifiable Information with a Central by Landers Merchant if you engage in an Identification Activity, 
                                and then only to the Central by Landers Merchant involved in that Identification Activity. 
                                We specifically disclaim any responsibility or liability for the actions of such Central by Landers Merchant. 
                                While we inform them that they are only permitted to communicate with you for the purposes of the specific promotion with Central by Landers, 
                                we do not have any responsibility for the actions of the Central by Landers Merchant, 
                                and the actions of the Central by Landers Merchant are not in our control.
                            </p>
                            <p>
                                We may share your information with Authorized Third Party Service Providers. We provide services and products through third parties. 
                                These “Third Party Service Providers” perform functions on our behalf, like sending out and distributing our administrative and promotional emails. 
                                We may share your Personally Identifiable Information with such Service Providers to fulfill orders, deliver packages, send postal or email, 
                                administer contests or promotions, remove repetitive information on customer lists, analyze data, provide marketing assistance, 
                                provide search results and links, process credit card payments, operate the Website, troubleshoot, and provide customer service. 
                                We may also collect personal information from individuals and companies (“Affiliates”) with whom we have business relationships and may share your information with Service Providers to accomplish our administrative tasks. 
                                For example, when you order a service, we release your credit card information to the card-issuing bank to confirm payment for the service and, if applicable, 
                                release your address to the delivery service to deliver the service. Likewise, we may release an Affiliate’s information to our bank to send out a payment. 
                                We encourage Central by Landers Merchants and Third Party Service Providers to adopt and post privacy policies. 
                                However, the use of your Personally Identifiable Information by such parties is governed by the privacy policies of such parties and is not subject to our control.
                            </p>
                            <p>
                                We may remarket your information. Remarketing is a way for us to connect with users, based upon your past interactions with the Central by Landers website. 
                                Third-party marketing vendors may be hired by Central by Landers to perform remarketing services. As a result, third-party vendors, including Google, 
                                may show Central by Landers ads on sites on the internet. Third-party vendors, including Google, use cookies to serve ads based on a user's prior visits to Central by Landers website. 
                                Users may opt out of Google's use of cookies by visiting the Google advertising opt-out page at http://www.google.com/privacy_ads.html. 
                                This information is used only for remarketing purposes and will not be used by them for any other purpose.
                            </p>
                            <p>
                                We may share your information in a Business Transfer. As with any other business, we could merge with, or be acquired by another company. 
                                If this occurs, the successor company would acquire the information we maintain, including Personally Identifiable Information. 
                                However, Personally Identifiable Information would remain subject to this Security & Privacy Policy.
                            </p>
                            <p>
                               We may share your information for our Protection and the Protection of Others.
                                We reserve the right to disclose your Personally Identifiable Information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply 
                                with a judicial proceeding, court order, or legal process served on our Website; enforce or apply this Security & Privacy Policy, our Website Terms and Conditions or other agreements; 
                                or protect the rights, property or safety of the Website, its Users or others.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong> <p className="cmb-title">5. Release of Non-Personally Identifiable Information</p> <br/></strong>
                            <p>
                                We may disclose or share Non-Personally Identifiable Information with Partners, Affiliates and Advertisers. 
                                For example, we may share aggregated demographic information (which does not include any Personally Identifiable Information) with “Third Party Advertisers” or “Third Party Advertising Companies” and other parties.
                            </p>
                            <p>
                                We also use Third Party Service Providers to track and analyze Non-Personally Identifiable usage and volume statistical information from our Users to administer our Website and constantly improve its quality. 
                                We may also publish this information for promotional purposes or as a representative audience for Advertisers. 
                                Please note that this is not Personally Identifiable Information, only general summaries of the activities of our Users. 
                                Such data is collected on our behalf, and is owned and used by us.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong> <p className="cmb-title">6. Updating and Correcting Information</p> <br/></strong>
                            <p>
                                We believe that you should have the ability to access and edit the Personally Identifiable Information you provide us. 
                                You may change any of your Personally Identifiable Information by logging into your account and accessing the "my account" section of the site, or by sending us an email at support@central.ph. 
                                We encourage you to promptly update your Personally Identifiable Information if it changes. 
                                You may ask to have the information on your account deleted or removed; 
                                however, because we keep track of past transactions, you cannot delete information associated with past transactions on the Website. 
                                In addition, in may be impossible for us to completely delete all of your information because we periodically backup information. 
                                Please note that you also have the right to lodge a complaint before the National Privacy Commission.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong> <p className="cmb-title">7. User Choices on Collection and Use of Information</p> <br/></strong>
                            <p>
                                As discussed above, you can always choose not to provide information, 
                                although it may be required to engage in a certain activity on the Website.
                            </p>
                            <p>
                                As a condition of purchase of our products and services, we will send you Administrative and Promotional emails. 
                                We will send you information regarding your account activity and purchases as well as updates about our products and promotional offers. 
                                You cannot opt-out of Administrative Emails. “Administrative Emails” relate to a User’s activity on the Website, and include emails regarding a particular User’s account, requests or inquiries, and purchases of products and services. 
                                In contrast to Administrative Emails, however, you do have a choice with respect to Promotional Emails. 
                                Promotional Emails advertise our products and services, including exclusive sales and other offers, and/or the products and services of our Advertisers and Affiliates. 
                                If you do not want to receive Promotional Emails from us, you may elect to opt-out of receiving Promotional Emails at any time after registering by e-mailing us at support@central.ph or by hitting the “unsubscribe” button at the bottom of any of our e-mails. 
                                When contacting us, please indicate your name, address, email address, and what Promotional Emails you do not want to receive.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong><p className="cmb-title">8. Security of Information</p> <br/></strong>
                            <p>
                                We take security seriously and take numerous precautions to protect the security of Personally Identifiable Information. 
                                You can access your Personally Identifiable Information on our Website through a password and your email address. This password is encrypted. We recommend that you do not share your password with anyone. 
                                In addition, your Personally Identifiable Information resides on a secure server that only selected personnel and contractors have access to. 
                                We encrypt certain sensitive information using Secure Socket Layer (SSL) technology to ensure that your Personally Identifiable Information is safe as it is transmitted to us.
                            </p>
                            <p>
                                Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. 
                                As a result, while we employ commercially reasonable security measures to protect data and seek to partner with companies which do the same, 
                                twe cannot guarantee the security of any information transmitted to or from the Website, and are not responsible for the actions of any third parties that may receive any such information.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong><p className="cmb-title">9. Data Tracking</p> <br/></strong>
                            <p>
                               Cookies. To facilitate and customize your experience with the Website, we store cookies on your computer. 
                                A cookie is a small text file that is stored on a User’s computer for record-keeping purposes which contains information about that User. 
                                We use cookies to save you time while using the Website, remind us who you are, and track and target User interests in order to provide a customized experience. 
                                Cookies also allow us to collect Non-Personally Identifiable Information from you, like which pages you visited and what links you clicked on. 
                                Use of this information helps us to create a more user-friendly experience for all visitors. 
                                In addition, we may use Third Party Advertising Companies to display advertisements on our Website. As part of their service, 
                                they may place separate cookies on your computer. We have no access to or control over these cookies. 
                                This Security & Privacy Policy covers the use of cookies by our Website only and does not cover the use of cookies by any Advertiser. 
                                Most browsers automatically accept cookies, but you may be able to modify your browser settings to decline cookies. 
                                Please note that if you decline or delete these cookies, some parts of the Website may not work properly.    
                            </p>
                            <p>
                                Other Tracking Devices. We may use other industry standard technologies like pixel tags and web beacons to track your use of our Website pages and promotions, or we may allow our Third Party Service Providers to use these devices on our behalf. 
                                Pixel tags and web beacons are tiny graphic images placed on certain pages on our Website, or in our emails that allow us to determine whether you have performed a specific action.    
                            </p>
                        </div>
                        <div className="col-12">
                        <strong><p className="cmb-title">10. Privacy Policies of Third Party Websites</p> <br/></strong>
                            <p>
                                This Security & Privacy Policy only addresses the use and disclosure of information we collect from you on www.CentralbyLanders.com. 
                                Other websites that may be accessible through this Website have their own privacy policies and data collection, use and disclosure practices. 
                                If you link to any such website, we urge you review the website’s security and privacy policy. We are not responsible for the policies or practices of third parties.
                            </p>
                        </div>
                        <div className="col-12">
                        <strong><p className="cmb-title">11. Miscellaneous Privacy Issues</p> <br/></strong>
                            <p>
                                Public Areas. We may provide areas on our Website where you can publicly post information about yourself, 
                                can communicate with others such as discussion boards or blogs, or can review products, restaurants, and submit media content. 
                                This information may be accessible by other consumers and companies and may appear on other websites or web searches, 
                                and therefore this information could be read, collected, and used by others. 
                                For example, if you post your email address along with a public restaurant review, you may receive unsolicited messages. 
                                We have no control over who reads your postings or what other users may do with the information you voluntarily post, 
                                so please use caution when posting any personal information. We do obtain consent from our users to post their name along with their reviews.
                            </p>
                        </div>
                        <div className="col-12 p_spacing">
                        <strong> <p className="cmb-title">12. Your Rights as the Data Subject</p> <br/></strong>
                            <p>
                                You, being the Data Subject, have the following rights in connection with the Processing of their Personal Data, 
                                to <i>wit</i>:
                            </p> <br/>
                            <p>
                                Right to be Informed. The Data Subject has the right to be informed whether Personal Data pertaining to him or her shall be, are being, or have been processed.
                            </p> <br/>
                            <p>
                                The Data Subject shall be notified and furnished with information indicated hereunder before the entry of his or her Personal Data into the records of Central by Landers, or at the next practical opportunity, through the Data Privacy Notice:
                            </p> <br/>
                            {/* <ol type="a"> */}
                                <p>description of the Personal Data to be entered into the system;</p>
                                <p>purposes for which they are being or will be processed, including Processing for direct marketing, profiling or historical, statistical or scientific purpose;</p>
                                <p>basis of Processing, when Processing is not based on the consent of the Data Subject;</p>
                                <p>scope and method of the Personal Data Processing;</p>
                                <p>
                                    methods utilized for automated access, if the same is allowed by the Data Subject, and the extent to which such access is authorized, 
                                    including meaningful information about the logic involved, as well Personal Data Privacy Policy as the significance and the envisaged consequences 
                                    of such Processing for the Data Subject;
                                </p>
                                <p>
                                    the identity and contact details of the Data Protection Officer (“DPO”);
                                </p>
                                <p>
                                    the period for which the Personal Data will be stored; and,
                                </p>
                                <p>
                                    the existence of their rights as Data Subjects, including the right to access, correction, and to object to the Processing, 
                                    as well as the right to lodge a complaint before the National Privacy Commission.
                                </p>
                            {/* </ol> */}
                            <p>
                                Right to Object. The Data Subject shall have the right to object to the Processing of his or her Personal Data, including Processing for direct marketing, automated Processing or profiling.
                            </p>
                            <p>
                                The person tasked with the Processing of the data shall notify and give an opportunity to the Data Subject to withhold consent to the Processing in case of changes or any amendment to the information supplied or declared to the Data Subject in the preceding paragraph.
                            </p>
                            <br/><p>When a Data Subject objects or withholds consent, Central by Landers shall no longer process the Personal Data, unless:</p> <br/>
                            {/* <ol type="1"> */}
                                <p>the Personal Data is needed pursuant to a subpoena;</p>
                                <p>the Processing is for obvious purposes, including, when it is necessary for the performance of or in relation to a contract or service to which the Data Subject is a party, or when necessary or desirable in the context of an employer-employee relationship between Central by Landers and the Data Subject; or,</p>
                                <p>the Personal Data is being collected and processed to comply with a legal obligation.</p>
                            {/* </ol> */}
                            <p>Right to Access. The Data Subject has the right to reasonable access to the following:</p>
                            {/* <ol type="1"> */}
                            <br/>
                                <p>Contents of his or her Personal Data that were processed;</p>
                                <p>Sources from which Personal Data were obtained;</p>
                                <p>Names and addresses of recipients of the Personal Data;</p>
                                <p>Manner by which his or her Personal Data were processed;</p>
                                <p>Reasons for the disclosure of the Personal Data to recipients, if any;</p>
                                <p>Information on automated processes where the Personal Data will, or is likely to, be made as the sole basis for any decision that significantly affects or will affect the Data Subject;</p>
                                <p>Date when Personal Data concerning the Data Subject were last accessed and modified;</p>
                                <p>CCTV footages and stills concerning the Data Subject; and,</p>
                                <p>The designation, name or identity, and address of the DPO.</p>
                            {/* </ol> */}
                            
                            <p>In making the request, the Data Subject should write a written request to Central by Landers’s DPO containing at least the following necessary information;</p>
                            {/* <ol type="1"> */} <br/>
                                <p>the name of the requesting party;</p>
                                <p>the type of Personal Data and/or information sought to be requested;</p>
                                <p>the date, time, and location of the recording, if applicable; and,</p>
                                <p>the purpose of the request.</p>
                            {/* </ol> */}
                            <p>In giving a person a copy of their data, the data controller may provide a still/series of still pictures, a tape or a disk with relevant images.</p> <br/>
                            <p>Right to Rectification. The Data Subject has the right to dispute the inaccuracy or rectify the error in his or her Personal Data, upon a written request to the DPO. Central by Landers shall correct it immediately and accordingly, unless the request is vexatious or otherwise unreasonable.</p><br/>
                            <p>If the Personal Data has been corrected, Central by Landers shall ensure the accessibility of both the new and the retracted Personal Data and the simultaneous receipt of the new and the retracted Personal Data by the intended recipients thereof; provided, that recipients or third parties who have previously received such processed Personal Data shall be informed of its inaccuracy and its rectification, upon reasonable request of the Data Subject.</p> <br/>
                            <p>Right to Erasure or Blocking. The Data Subject shall have the right to suspend, withdraw, or order the blocking, removal, or destruction of his or her Personal Data from Central by Landers’s filing system, upon a written request to the DPO.</p> <br/>
                            <p>This right may be exercised upon discovery and substantial proof of any of the following:</p> <br/>              
                            {/* <ol type="a"> */}
                                <p>The Personal Data is incomplete, outdated, false, or unlawfully obtained;</p>
                                <p>The Personal Data is being used for purpose not authorized by the Data Subject;</p>
                                <p>The Personal Data is no longer necessary for the purposes for which they were collected;</p>
                                <p>The Data Subject withdraws consent or objects to the Processing, and there is no other legal ground or overriding legitimate interest for the Processing by Central by Landers;</p>
                                <p>The Personal Data concerns private information that is prejudicial to Data Subject, unless justified by freedom of speech, of expression, or of the press or otherwise authorized;</p>
                                <p>The Processing is unlawful; or,</p>
                                <p>The Data Subject’s rights have been violated.</p>
                            {/* </ol> */}
                            <p>Transmissibility of Rights of Data Subjects. The lawful heirs and assigns of the Data Subject may invoke the rights of the Data Subject to which he or she is an heir or an assignee, at any time after the death of the Data Subject, or when the Data Subject is incapacitated or incapable of exercising his/her rights.</p> <br/>
                            <p>Data Portability. Where his or her Personal Data is processed by the Company through electronic means and in a structured and commonly used format, the Data Subject shall have the right to obtain a copy of such data in an electronic or structured format that is commonly used and allows for further use by the Data Subject.</p>
                            <br/><p>The exercise of this right shall primarily take into account the right of Data Subject to have control over his or her Personal Data being processed based on consent or contract, for commercial purpose, or through automated means.</p>
                        </div>
                </div>
            </div>
        </div>
        )
    }
}