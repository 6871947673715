import axios from 'axios'

export default class CoreAPI {

    getDataFromJSON() {
        let env = (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'dev') ? 'qa' : process.env.REACT_APP_ENV
        const JSONLink = document.getElementById(`srn-brands-json-${env}`).src
        return axios.get(JSONLink)
    }
    
    getWarningDataFromJSON() {
        let env = process.env.REACT_APP_ENV === 'prod' ? 'prod' : 'qa'
        const JSONLink = document.getElementById(`srn-warnings-json-${env}`).src
        return axios.get(JSONLink)
    }
} 

