import React, { Component } from "react";
import $ from 'jquery'
import LocationPicker from '../../partials/LocationPicker'
import market_ads from '../../../assets/images/market_ads.jpg'
import MarketLogo from '../../../assets/images/market_fulfillment_logo.svg'

import household_laundry_banner from '../../../assets/images/cat_banner_householdlaundry.jpg'
import pet_care_banner from '../../../assets/images/cat_banner_petcare.jpg'
import bakery_banner from '../../../assets/images/cat_banner_bakery.jpg'
import beer_wine_banner from '../../../assets/images/cat_banner_beerwin.jpg'

import market_Banner_1 from '../../../assets/images/market_banner_safehome.jpg'
import market_Banner_2 from '../../../assets/images/market_banner_pawsome.jpg'
import market_Banner_3 from '../../../assets/images/market_banner_bestreatsbite.jpg'
import market_Banner_4 from '../../../assets/images/market_banner_unwind.jpg'

import marketsidebanner1 from '../../../assets/images/market_side_banner01.jpg'
import marketsidebanner2 from '../../../assets/images/market_side_banner02.jpg'
import marketsidebanner3 from '../../../assets/images/market-side-adsfind.jpg'
import marketmidbanner2 from '../../../assets/images/market_mid_banner2.jpg'
import inside_ads from '../../../assets/images/inside_ads.jpg'
import ads_50 from '../../../assets/images/ads_50_market_landing.png'

import marketMwebanner1 from '../../../assets/images/market_mweb_banner_1.png'
import mwebAds1 from '../../../assets/images/landing_mweb_ads.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlus } from "@fortawesome/free-solid-svg-icons"
import {
    getCrossDomainToken, 
    triggerSerinoEvent, 
    getCrossCart, 
    log, 
    getCrossStorage, 
    handleLandersBrandId, 
    getAccessToken, 
    getTemporaryToken, 
    setCrossStorage, 
    membershipChecker, 
    getSubdomainCartCount, 
    setSubdomainCartCount,
    handleExpiredToken,
    setHeaderAddress,
    getThumbnail,
    mainAddressHandler,
    getAddressObject,
    getAddressFromObjectChecker,
    handleShowProductActions
} from '../../../helper/helper'
import { GAImpressions } from '../../../helper/analytics'

import Cart from '../../../_core/cart'
import ApplicationAPI from '../../../_core/application'
import LoginAPI from '../../../_core/login'
import ParallaxComponent from '../../partials/ParallaxComponent';
import CoreAPI from "../../../_core/core";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Slider from "react-slick";
import ShoppingBasketComponent from '../../partials/ShoppingBasketComponent'
import MarketSVG from '../../../assets/images/new/central-market-logo-2.svg'
import banner88 from '../../../assets/images/market_landing_88.png'
import ShoppingBasketBanner from "../../../assets/images/banner_shoppingbasket.png";

var cart = new Cart()
var coreAPI = new CoreAPI();
var application = new ApplicationAPI()
var login = new LoginAPI()

const API_URL = process.env.REACT_APP_SERINO_API
const x_app_key = process.env.REACT_APP_MARKET_APP_ID
const tenant_id = process.env.REACT_APP_TENANT_ID

let similar_items_id = []
let retry_interval = 1500

export default class MarketLanding extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            cart_items: [],
            collection: [],
            collectionList: [],
            product: {},
            address_object: [],
            isLoggedIn: localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn') : false,
            similar_items: [],
            access_token: '',
            triggerGetDefaultAddress: {
                state: false,
                app_key: x_app_key
            },
            isAddBtnLoading: false,
            isSharedDataLoaded: false,
            isCollectionSharedDataLoaded: false,
            isCategoryLoading: false,
            banners: [],
            isSearchActive: false,
            branch_id: '',
            products: [],
            isShoppingBasketActive: true,
            homecards: [],
            m_homecards: [],
            m_parent_card: {},
            m_child_cards: [],
            a_homecards: [],
            a_parent_card: {},
            a_child_cards: [],
            page: "",
            showbanner: false
        }
        this.sliderRef = React.createRef();
    }
    async componentDidMount() {

        if (this.props.location.search?.length > 0) {
            let asd = this.props.location.search.substring(1, 20);
            
            console.log("asd", asd)
           this.setState({page: asd})
        }

        this.headerChange()
        sessionStorage.removeItem("signupPage", true)

        await this.setBanners()
        localStorage.setItem('brand_id', process.env.REACT_APP_MARKET_APP_ID)
        localStorage.setItem('brand_name', 'Landers Central')
        localStorage.setItem('domain', 'sub')
        sessionStorage.removeItem("from")
        let address_object
        let getAddressesTries = 0
        let getTokenTries = 0

        this.initializecategories()
        
        let access_token = await getCrossDomainToken()

        // login.getClientDetails(localStorage.getItem('access_token'), process.env.REACT_APP_X_APP_KEY)
        // .then((res) => {
        //     let membership = res.data?.metas?.find((x) => x.key == "Rewards" || x.key == "RewardsLinked"); // temporary fix , need to get membershipID
        //     let user_role = res.data && res.data.roles ? res.data.roles[0].name : res.data.role;

        //     if (user_role == "Client") {
        //         localStorage.setItem("isLoggedIn", true);
        //     }
            
        //     if (user_role === "Guest") {
        //         localStorage.removeItem("isLoggedIn");
        //     }
        //     if (membership) {
        //         localStorage.setItem("isMember", true);
        //     } else {
        //     localStorage.removeItem("isMember");
        //     }
        //     console.log("loginGetClien",res)
        // })
        
        this.setState({access_token: access_token})

        localStorage.removeItem('bra')
        localStorage.setItem('brand_id', process.env.REACT_APP_MARKET_APP_ID)

        address_object = await getCrossStorage(`address_object_${process.env.REACT_APP_MARKET_APP_ID}`, 'sub')
        let main_address = localStorage.getItem(`main_address`)

        if (address_object) {
            let parsed = JSON.parse(address_object)
            if (parsed && parsed.length > 0) {
                let address = parsed.find(x => x.brand_id == localStorage.getItem('brand_id'))
                localStorage.setItem('branch_id', address.branch_id)
                localStorage.setItem('address_object', address_object)
                localStorage.setItem('Address', address.address)
                localStorage.setItem('fulfillment_type_status', address.fulfillmentType_status)
                this.setState({
                    address_object: parsed
                })
                if (document.getElementById('query-market_landing_page')) {
                    document.getElementById('query-market_landing_page').value = parsed[0].address
                }
            }
            else {
                localStorage.removeItem('branch_id')
                localStorage.removeItem('Address')
                localStorage.removeItem('fulfillment_type_status')
                this.setState({
                    triggerGetDefaultAddress: {
                        state: !this.state.triggerGetDefaultAddress,
                        app_key: x_app_key
                    }
                })
            }
        }
        else {
            localStorage.removeItem('branch_id')
            this.setMainAddress()
            this.setState({
                triggerGetDefaultAddress: {
                    state: !this.state.triggerGetDefaultAddress,
                    app_key: x_app_key
                }
            })
        }

        this.getCart(null, true)

         // auto tagging component
        let autoTaggingEvent = window.serino_components.AutoTaggingComponent.events

        let branch_id = 6

        if (localStorage.getItem("branch_id") && localStorage.getItem("branch_id").length > 0) {
            branch_id = localStorage.getItem("branch_id")
        }

        await this.setState({
            branch_id: branch_id
        })

        await this.getDataFromJSON()

        // search list component
        // let searchProduct = window.serino_components.SearchProductListComponent.create({
        //     access_token: access_token,
        //     x_app_key: x_app_key,
        //     categordId: 13,
        //     brand_id: 1,
        //     mode: 1
        // }, "srn-search-product-list-container");

        // let searchProductSide = window.serino_components.SearchProductListComponent.create({
        //     access_token: access_token,
        //     x_app_key: x_app_key,
        //     categordId: 13,
        //     brand_id: 1,
        //     mode: 1
        // }, "srn-search-product-list-container-side");

        // CATEGORY LIST
        // document.addEventListener(categoryEvents.categoryParent_select, () => {
        //     this.props.history.push(`/products`)
        //     })
        let categoryEvents = window.serino_components.CategoryListComponent.events

        document.addEventListener(categoryEvents.category_select, (e) => {
            localStorage.setItem("category_branch_id", e.detail.col_cat.branch_id)
            localStorage.setItem("collection_id", e.detail.col_cat.col_id)
            localStorage.setItem('banner_image', e.detail.col_cat.banner_img)
            sessionStorage.setItem('viewAllAction', 'categoryMenu')
            localStorage.removeItem("auto_tag")

            let categories_display = {
                category: e.detail.col_cat.collection,
                subcategory: e.detail.col_cat.sub_cat,
                sub_cat_collection_id: e.detail.col_cat.sub_cat_collection_id,
                parent_id: e.detail.col_cat.parent_id,
                children: e.detail.col_cat.children,
                banner_img: e.detail.col.image,
                branch_id: e.detail.col_cat.branch_id
            }
            localStorage.setItem("categories_display", JSON.stringify(categories_display))
            localStorage.setItem('selected_sub_cat_name', JSON.stringify(e.detail.col_cat.sub_cat))
            // localStorage.setItem('selected_child_category', JSON.stringify(e.detail.col_cat))
            let catUrl = sessionStorage.getItem("categoryUrl").replace(/ /g,"_");
            this.props.history.push(`/market-products?${catUrl}`)
            })

        // COLLECTION MENU
        document.addEventListener(categoryEvents.collection_select, (e) => {
            localStorage.setItem("collection_id", e.detail.id)
            localStorage.removeItem('banner_image')
            
            sessionStorage.setItem('viewAllAction', 'collectionMenu') //view from collection menu
            localStorage.setItem("auto_tag", e.detail.name)
            window.location = '/market-products?category'
        })

        document.addEventListener(categoryEvents.onBundleSelect, (e) => {
            window.location = `/market-products?type=bundle&collection_id=${e.detail.id}&collection_name=${e.detail.name}`;
        })
       
        // COLLECTION COMPONENT
        // let productCollectionsEvent = window.serino_components.ProductCollectionComponent.events
        // document.addEventListener(productCollectionsEvent.onViewAll, (e) => {
        //     localStorage.setItem("branch_id", e.detail.branch_id)
        //     localStorage.setItem("collection_id", e.detail.collection_id)
        //     localStorage.setItem("app_layer_ids", e.detail.productIDs)
        //     localStorage.setItem('product_sku', e.detail.productSKU)
        //     sessionStorage.setItem('viewAllAction', 'collectionMenu') // view from collection component
        //     localStorage.removeItem('banner_image')
            
        //     if (e.detail.collection_name.toLowerCase() === "what's new" || e.detail.collection_name.toLowerCase() === "hot offers") {
        //         localStorage.setItem("auto_tag", e.detail.collection_name)
        //     } else {
        //         localStorage.removeItem("auto_tag")
        //     }
        //     window.location = '/market-products'
        // })

        // document.addEventListener(productCollectionsEvent.onAddToCart, (e) => {
        //     this.setState({product: e.detail.product_details})
        //     this.addToCart(e.detail.product_details)
        // })

        // product description component event
        let productDescriptionEvent = window.serino_components.ProductDescriptionComponent.events
        document.addEventListener(productDescriptionEvent.addtoCart, (e) => {
            if (window.location.pathname != '/market-products') {
                this.addToCart(e.detail.product_details.id)
            }
        })

        // quantity from description modal
        document.addEventListener(productDescriptionEvent.onIncrementCart, (e) => {
            let id = e.detail.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            this.updateCartQuantity(x_app_key, id, payload)
            this.updateCartCount(e.detail.cart_items)
        })

        // quantity from description modal
        document.addEventListener(productDescriptionEvent.onDecrementCart, (e) => {
            let id = e.detail.cartItemId
            let app_key = e.detail.app_key
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            this.updateCartQuantity(x_app_key, id, payload)
            this.updateCartCount(e.detail.cart_items)
        })

        // remove item 
        document.addEventListener(productDescriptionEvent.onDeleteItem, (e) => {
            let id = e.detail.cartItemId
            this.removeCartItem(x_app_key, id)
        })

        // manual input quantity
        document.addEventListener(productDescriptionEvent.updateQuantity, (e) => {
            let id = e.detail.detail.id
            let payload = {
                quantity: e.detail.detail.quantity,
                "include_promo": true
            }
            this.updateCartQuantity(x_app_key, id, payload)
            this.updateCartCount(e.detail.detail.cart_items)
        })

         // similar items add to cart
        let similarProductEvent = window.serino_components.SimilarProductsComponent.events
        document.addEventListener(similarProductEvent.onAddToCart('market-landing-page'), (e) => {
            this.addToCart(e.detail.product_details)
        })

        document.addEventListener(similarProductEvent.onGetSimilarItems('market-landing-page'), (e) => {
            this.setState({ similar_items: e.detail })
        })

        document.addEventListener(similarProductEvent.onUpdateIncrement('market-landing-page'), (e) => {
            let id = e.detail.cartItemId
            let app_key = x_app_key
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            this.updateCartQuantity(app_key, id, payload)
        })

        document.addEventListener(similarProductEvent.onUpdateDecrement('market-landing-page'), (e) => {
            let id = e.detail.cartItemId
            let app_key = x_app_key
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            this.updateCartQuantity(app_key, id, payload)
        })

        document.addEventListener(similarProductEvent.onDeleteItem('market-landing-page'), (e) => {
            let app_key = x_app_key
            let id = e.detail.cartItemId
            this.removeCartItem(app_key, id)
        })

        document.addEventListener(similarProductEvent.updateQuantity('market-landing-page'), (e) => {
            let id = e.detail.cartItemId
            let app_key = x_app_key
            let payload = {
                quantity: e.detail.quantity,
                "include_promo": true
            }
            this.updateCartQuantity(app_key, id, payload)
        })

        $('#descriptionModal').on('hidden.bs.modal', function () {
            similar_items_id = []
        })

        // fulfillment type event CONFIRM
        let fulfillmentTypeEvent = window.serino_components.FulfillmentTypeComponent.events
        document.addEventListener(fulfillmentTypeEvent.onConfirm, (e) => {
            let branch_id = e.detail.detail.branch_id
            this.setBranchOnSearch(branch_id)
            localStorage.setItem('branch_id', branch_id)
            
        })

        this.addSearchEvents()

        getSubdomainCartCount()

        if (this.props.location.search.length > 0) {
            let search = this.props.location.search.substring(1)
            let objFromUrl = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            let product_id = objFromUrl.pdesc
            
            if (product_id) {
                application.getProductDetails(x_app_key, product_id)
                    .then((res) => {
                        this.productDescription(res.data.items[0], x_app_key)
                    })
            }
        }
        
        // this.setMainAddress()

        // Bundle Cart actions
        // if (this.state.isShoppingBasketActive) {
        //     this.handleBundleActions()
        // }
        setTimeout(() => {
            this.setState({showbanner: true})
        },500)
    }

    handleHomeCardEvents = () => {

        let autoTaggingEvent = window.serino_components.AutoTaggingComponent.events

        let homecards = this.state.homecards

        homecards.forEach(x => {
            document.addEventListener(autoTaggingEvent.onAddToCart(x.id), (e) => {
                this.setState({product: e.detail.product_details}, () => {
                    this.addToCart(e.detail.product_details)
                })
            })

            document.addEventListener(autoTaggingEvent.updateQuantity(x.id), (e) => {
                let id = e.detail.detail.id
                let payload = {
                    quantity: e.detail.detail.quantity,
                    "include_promo": true
                }
                this.updateCartQuantity(x_app_key, id, payload)
                this.updateCartCount(e.detail.detail.cart_items)
            })

            document.addEventListener(autoTaggingEvent.onUpdateIncrement(x.id), (e) => {
                let id = e.detail.payload.cartItemId
                let payload = {
                    quantity: e.detail.payload.quantity,
                    "include_promo": true
                }
                this.updateCartQuantity(x_app_key, id, payload)
                this.updateCartCount(e.detail.payload.cart_items)
            })


            document.addEventListener(autoTaggingEvent.onUpdateDecrement(x.id), (e) => {
                let id = e.detail.payload.cartItemId
                let payload = {
                    quantity: e.detail.payload.quantity,
                    "include_promo": true
                }
                this.updateCartQuantity(x_app_key, id, payload)
                this.updateCartCount(e.detail.payload.cart_items)
            })

            document.addEventListener(autoTaggingEvent.onDeleteItem(x.id), (e) => {
                let id = e.detail.detail.cartItemEntity
                this.removeCartItem(x_app_key, id)
            })

            document.addEventListener(autoTaggingEvent.onShowProductDetails(x.id), (e) => {
                handleShowProductActions(e.detail.product_details)
                this.setState({product: e.detail.product_details})
                this.props.history.push(`?pdesc=${e.detail.product_details.id}`)
                this.productDescription(e.detail.product_details, localStorage.getItem('access_token'))
            })

            if (x.type === 'manual') {
                document.addEventListener(autoTaggingEvent.onViewAll(x.id), (e) => {
                    // console.log('________', e.detail)
                    sessionStorage.setItem('viewAllAction', 'bundle') // view from collection
                    localStorage.setItem('auto_tag', x.title)
                    localStorage.setItem('collection_id', e.detail.collection_id)
                    localStorage.setItem('banner_image', x.banner)
                    window.location = '/market-products'
                })
            }
            else {
                document.addEventListener(autoTaggingEvent.onViewAll(x.id), (e) => {
                    console.log('________', e.detail)
                    localStorage.setItem('auto_tag', x.title)
                    localStorage.setItem("app_layer_ids", e.detail.productIDs)
                    localStorage.setItem('product_sku', e.detail.productSKU)
                    sessionStorage.setItem('viewAllAction', 'collectionMenu') // view from collection component
                    localStorage.setItem('banner_image', x.banner)
                    window.location = '/market-products?category'
                })
            }


        })

    }

    getMarketBranch = () => {
        let addressString = localStorage.getItem(`address_object_${x_app_key}`)

        if (!addressString) {
            return null
        }

        let parsed = JSON.parse(addressString)

        if (!parsed) {
            return null
        }

        let branch_id = parsed.branch_id

        if (!branch_id) {
            return null
        }

        return branch_id

    }

    handleBundleActions = () => {

        let productListEvent = window.serino_components.ProductListComponent.events;

        document.addEventListener(productListEvent.onAddtoCart, (e) => {

            let product = e.detail.product_details
            localStorage.setItem('brand_id', e.detail.product_details.brand_id)

            this.setState({
                product: e.detail.product_details,
                }, () => {
                    if (product.application_id == '1') {
                        this.addToCartMarket(e.detail.product_details)
                    }
                    else {
                        this.addToCartEats(e.detail.product_details)                        
                    }
                }
            )

        });

        document.addEventListener(productListEvent.onDeleteItem, (e) => {
            let id = e.detail.cartItemId;
            this.removeCartItem(x_app_key, id);
        });

        // increment button
        document.addEventListener(productListEvent.onIncrementCart, (e) => {
            let id = e.detail.cartItemId;
            let payload = {
                quantity: e.detail.quantity,
                include_promo: true,
            };
            let cart_items = e.detail.cart_items;
            this.updateCartQuantity(x_app_key, id, payload, cart_items);
        });

        // decrement button
        document.addEventListener(productListEvent.onDecrementCart, (e) => {
            let id = e.detail.cartItemId;
            let payload = {
                quantity: e.detail.quantity,
                include_promo: true,
            };
            let cart_items = e.detail.cart_items;
            this.updateCartQuantity(x_app_key, id, payload, cart_items);
        });

        // manual input on quantity
        document.addEventListener(productListEvent.updateQuantity, (e) => {
            let id = e.detail.detail.id;
            let payload = {
                quantity: e.detail.detail.quantity,
                include_promo: true,
            };
            let cart_items = e.detail.cart_items;

            this.updateCartQuantity(x_app_key, id, payload, cart_items);
        });

        // remove item
        document.addEventListener(productListEvent.onDeleteItem, (e) => {
            let id = e.detail.cartItemId;
            this.removeCartItem(x_app_key, id);
        });

        document.addEventListener(productListEvent.onSelectedProduct, (e) => {
            this.props.history.push(`?pdesc=${e.detail.product_details.id}`);
            this.productDescription(e.detail.product_details, localStorage.getItem('access_token'));
            this.setState({
                product: e.detail.product_details
            });
            this.productListSubscription();
        });
    }

    initializecategories = () => {
        this.setState({
            isCategoryLoading: true
        })

        getTemporaryToken().then(access_token => {

            let categoryListSide = window.serino_components.CategoryListComponent.create({
                access_token: access_token,
                activeCategory: localStorage.getItem("collection_id"),
                brand_id: 6,
                branch_id: 6,
                x_app_key: x_app_key,
                mode: 1,
            }, "srn-category-list-container-side");
            
            this.userRole()

            let categoryEvents = window.serino_components.CategoryListComponent.events

            // Reuse loaded categories
            document.addEventListener(categoryEvents.onCategoryLoad, (e) => {    
                // category list component
                let categoryList = window.serino_components.CategoryListComponent.create({
                    access_token: access_token,
                    activeCategory: localStorage.getItem("collection_id"),
                    brand_id: 6,
                    branch_id: 6,
                    x_app_key: x_app_key,
                    mode: 1,
                    data: e.detail
                }, "srn-category-list-container");

                this.setState({
                    isCategoryLoading: false
                })
            })

        })
    }

    setMainAddress = async () => {
        let main_address = ''
        let getMainAddressTries = 0

        let getMainAddressesInterval = setInterval(async() => {

            let main_address = await getCrossStorage('main_address', 'sub')
            
            getMainAddressTries++

            if ((main_address || getMainAddressTries == 3) && main_address != undefined && main_address !== '0') {
                clearInterval(getMainAddressesInterval)

                if (main_address && main_address.length > 0) {

                    localStorage.setItem('main_address', main_address)

                    let parsed = JSON.parse(main_address)

                    localStorage.setItem('Address', parsed.address)

                    if (document.getElementById('query-market_landing_page')) {
                        document.getElementById('query-market_landing_page').text = parsed.address
                    }
                    setHeaderAddress(parsed)
                }
            }
        })

    }
    setBranchOnSearch = (branch_id) => {
        console.log('_____::', branch_id)
        let searchComponentSubscription = window.serino_components.SearchProductListComponent.subscriptions
        let searchComponentBranchID = new CustomEvent(searchComponentSubscription.fulfillmentBranch, {
            detail: branch_id
        })
        document.dispatchEvent(searchComponentBranchID)
    }

    userRole = async () => {

        log('userRole')

        // let isLoggedIn = localStorage.getItem('isLoggedIn')
        let isLoggedIn = "false"

        let categoryListSubscription = window.serino_components.CategoryListComponent.subscriptions
        let categoryUserRole = new CustomEvent(categoryListSubscription.userRole, {
            detail: isLoggedIn
        })
        document.dispatchEvent(categoryUserRole)
    }

    removeCartItem = (app_key, id) => {
        let access_token = localStorage.getItem('access_token')
        cart.removeCartItem(access_token, app_key, id, this.state.cart_items)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.getCart(res.items) // update cart item
                }
            })
            .catch((err) => {
                console.log(err)
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
            })
    }

    getCart = async (cartItems, isInitial = false) => {
        let cart_items = []
        if (isInitial) {
            try {

                let cartResult = await cart.getCartItems(localStorage.getItem('access_token'), x_app_key)

                cart_items = cartResult.data
            }
            catch(cartError) {
                if (cartError.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
            }
        }
        else {
            cart_items = cartItems
        }

        if (this.state.isSearchActive) {
            this.handleSearchDisplay(true)
        }

        this.setState({
            cart_items: cart_items,
            isAddBtnLoading: false,
            isSearchActive: false
        }, () => {
            // update cart items in component
            this.productAutoTagSubscription()
            this.productDescriptionSubscription()
            this.similarProductScubscription()
            // this.productCollectionsSubscription()
            this.updateCartCount(this.state.cart_items)
        })
    }

    updateCartCount = async (cart_items) => {
        let cartCount = cart_items.reduce((a, b) => +a + +b.quantity, 0)
        let details = {
            market: cartCount,
            eats: localStorage.getItem('eats_cart_count'),
            app: 'market'
        }
        setSubdomainCartCount(details)
    }

    updateCartQuantity = (app_key, id, payload) => {
        let access_token = localStorage.getItem('access_token')
        cart.updateQuantity(access_token, app_key, id, payload)
            .then((res) => {
                // if (res.status === 200 || res.status === 201) {
                //     this.getCart(access_token, app_key)
                // }
                this.productAutoTagSubscription()
                this.productDescriptionSubscription()
                this.similarProductScubscription()
                // this.productCollectionsSubscription()
            })
            .catch((err) => {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
                console.log(err)
            })
    }

    handleCartDuplicate = (product, variants) => {
        if (this.state.cart_items && this.state.cart_items.length > 0) {
            return this.state.cart_items.filter(x => x.itemReferenceId == product.id).length > 0
        }
        else {
            return false
        }
    }

    addToCart = async (product) => {


        if (this.state.isAddBtnLoading == product.id) {
            return
        }

        this.setState({
            isAddBtnLoading: product.id
        })


        if (this.handleCartDuplicate(product)) {
            return;
        }

        log('addToCart called', product)

        // Temporary fix for duplicate event listener across different pages
        if (window.location.pathname === '/market-products') {
            return;
        }

        triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, product.id)

        if (localStorage.getItem('isLoggedIn') == null) {

            let isLoggedIn = await getCrossStorage('isLoggedIn', 'sub')

            localStorage.setItem('isLoggedIn', isLoggedIn)
        }

        const proceed = async (branch_id) => {
            let price
            let custom_data = []
            let price_object = {}

            let member = product.product_prices.find(p => p.name === "member")
            let nonmember = product.product_prices.find(p => p.name === "nonmember")
            
            if (membershipChecker()) {
                price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
                price_object = member
            } else {
                price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
                price_object = nonmember
            }

            let savings = {name: 'savings', value: nonmember.price - member.price }

            if (product.sell_by_type && product.sell_by_type == 1) {
                custom_data.push({
                    name: 'weight',
                    value: product.weight
                })
            }

            if (product.barcode && product.barcode.length > 0) {
                custom_data.push({
                    name: 'barcode',
                    value: product.barcode
                })   
            }

            custom_data.push(savings)
            custom_data.push(price_object)

            // SAVE ADDRESS IN CART START
            if (getAddressFromObjectChecker) {
                let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

                if (!address) {
                    triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
                }

                custom_data.push({
                    name: 'address',
                    value: address
                })
            }
            // SAVE ADDRESS IN CART 
            

            let payload = {
                "itemReferenceId": product.id,
                "itemReferenceType": product.type,
                "itemName": product.name,
                "itemSKU": product.sku,
                "itemImage": getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
                "itemDescription": product.description,
                "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
                "basePrice": nonmember.price,
                "price": price,
                "instructions": "",
                "domainReferenceId": branch_id,
                "domainReferenceType": "1",
                "item_type": product.sell_by_type ? product.sell_by_type : 0,
                "include_promo": true
            }
            if (custom_data && custom_data.length > 0) {
                payload.custom_data = JSON.stringify(custom_data)
            }

            try {

                const addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), x_app_key, payload)

                triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.setButtonLoading, false)

                this.getCart(addCartItemResult.items)
            }
            catch(err) {
                if (err.responseJSON.message == 'Invalid Access Token') {
                    handleExpiredToken()
                }
            }
            

        }

        let locationPicker = ''

        if (document.getElementById('query-market_landing_page')) {
            locationPicker = document.getElementById('query-market_landing_page').value
        }

        if(localStorage.getItem('branch_id')) {
            proceed(localStorage.getItem('branch_id'))
        }
        else {
            let localAddressObject = localStorage.getItem(`address_object_${x_app_key}`)
            let address_object = []
            if (localAddressObject && localAddressObject.length > 0) {
                address_object = JSON.parse(localAddressObject)
            }
            log('address_object', address_object)
            if (address_object && address_object.length > 0) {            
                let address = address_object.find(x => x.brand_id == localStorage.getItem('brand_id'))
                log('product', this.state.product.brand_id)
                if (address) {
                    if (address.branch_id && address.branch_id != null) {
                        log('address', address.brand_id)
                        localStorage.setItem('branch_id', address.branch_id)
                        proceed(address.branch_id)
                    }
                    else {
                        this.handleMainAddress()
                    }
                }
                else {
                    this.handleMainAddress()
                }
            }
            else {
                this.handleMainAddress()
            }
        }

    }
    
    handleMainAddress = () => {
        this.setState({isAddBtnLoading: false})
        mainAddressHandler()
    }

    addToCartComponent = async (product, branch_id) => {

        if (this.handleCartDuplicate(product)) {
            return;
        }

        log('addToCartComponent', product)

        // Disable add to cart button in Product Description
        triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, product.id)

        let price
        let price_object = {}
        let custom_data = []

        let member = product.product_prices.find(p => p.name === "member")
        let nonmember = product.product_prices.find(p => p.name === "nonmember")

        
        if (membershipChecker()) {
            price = parseFloat(member.discounted_price) > 0 ? parseFloat(member.discounted_price) : member.price
            price_object = member
        } else {
            price = parseFloat(nonmember.discounted_price) > 0 ? parseFloat(nonmember.discounted_price) : nonmember.price
            price_object = nonmember
        }

        // SAVE ADDRESS IN CART START
        if (getAddressFromObjectChecker) {
            let address = getAddressObject(branch_id, process.env.REACT_APP_MARKET_APP_ID)

            if (!address) {
                triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
            }

            custom_data.push({
                name: 'address',
                value: address
            })
        }
        // SAVE ADDRESS IN CART 

        let savings = {name: 'savings', value: nonmember.price - member.price }

        if (product.sell_by_type && product.sell_by_type == 1) {
            custom_data.push({
                name: 'weight',
                value: product.weight
            })
        }
        
        if (product.barcode && product.barcode.length > 0) {
            custom_data.push({
                name: 'barcode',
                value: product.barcode
            })   
        }
        
        custom_data.push(savings)
        custom_data.push(price_object)


        let payload = {
            "itemReferenceId": product.id,
            "itemReferenceType": product.type,
            "itemName": product.name,
            "itemSKU": product.sku,
            "itemImage": getThumbnail(product) ? getThumbnail(product) : product.product_images[0],
            "itemDescription": product.description,
            "quantity": product.quantity && product.quantity > 0 ? product.quantity : 1,
            "basePrice": nonmember.price,
            "price": price,
            "instructions": "",
            "domainReferenceId": branch_id ? branch_id  : localStorage.getItem('branch_id'),
            "domainReferenceType": "1",
            "item_type": product.sell_by_type ? product.sell_by_type : 0,
            "include_promo": true
        }

        if (custom_data && custom_data.length > 0) {
            payload.custom_data = JSON.stringify(custom_data)
        }

        try {

            const addCartItemResult = await cart.addCartItem(localStorage.getItem('access_token'), x_app_key, payload)

            triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.setButtonLoading, false)

            this.getCart(addCartItemResult.items)
        }
        catch(err) {
            if (err.responseJSON.message == 'Invalid Access Token') {
                handleExpiredToken()
            }
        }

    }

    productCollectionsSubscription = () => {
        // let productCollectionsSubscription = window.serino_components.ProductCollectionComponent.subscriptions
        // let productCartItems = new CustomEvent(productCollectionsSubscription.onCartItems, {
        //     detail: this.state.cart_items
        // })
        //     document.dispatchEvent(productCartItems)
    }

    productAutoTagSubscription = () => {
        
        let tags = this.state.a_homecards.map(x => x.id)
        let collections = this.state.m_homecards.map(x => x.id)

        let isCartLoadedInterval = setInterval(() => {
            if (this.state.isSharedDataLoaded) {
                clearInterval(isCartLoadedInterval)
                tags.map(tag => {
                    let autoTagSubsciption = window.serino_components.AutoTaggingComponent.subscriptions
                    let autoTagCartItems = new CustomEvent(autoTagSubsciption.onCartItems(tag), {
                        detail: this.state.cart_items
                    })
                    document.dispatchEvent(autoTagCartItems)
                })
            }
        }, 500)

        let isCollectionCartLoadedInterval = setInterval(() => {
            if (this.state.isCollectionSharedDataLoaded) {
                clearInterval(isCollectionCartLoadedInterval)
                collections.map(col => {
                    triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.onCartItems(col), this.state.cart_items)
                })
            }
        }, 500)

        triggerSerinoEvent(window.serino_components.SearchProductListComponent.subscriptions.onCartItems, this.state.cart_items)

    }

    productDescription = (detail, access_token) => {
        log('PRODUCT DESCRIPTION', detail)

        $('#descriptionModal').modal('show')

        similar_items_id.push(detail.id) // product id for similar item

        let productDescription = window.serino_components.ProductDescriptionComponent.create({
            product: detail,
            currency: '₱',
            mode: 1
        }, "srn-product-description-container");

        let similarProduct = window.serino_components.SimilarProductsComponent.create({
            access_token: access_token,
            x_app_key: x_app_key,
            branch_id: localStorage.getItem('branch_id') ? localStorage.getItem('branch_id') : 6,
            time_interval: 8000,
            currency: "₱",
            product_ids: similar_items_id,
            id: 'market-landing-page'
        }, "srn-similar-product-container")


        let similarProductEvents = window.serino_components.SimilarProductsComponent.events
        let similarProductScubscription = window.serino_components.SimilarProductsComponent.subscriptions
        let initSimilarProducts = new CustomEvent(similarProductScubscription.onLoad('market-landing-page'), {
            detail: similar_items_id
        })
        document.dispatchEvent(initSimilarProducts)


        document.addEventListener(similarProductEvents.onShowProductDetails('market-landing-page'), (e) => {
            handleShowProductActions(e.detail.product_details)
            let brand_id = handleLandersBrandId(e.detail.product_details.brand_id, e.detail.app_key)
            localStorage.setItem('brand_id', brand_id)

            this.setState({
                app_key: e.detail.x_app_key,
                product: e.detail.product_details
            }, () => {
                this.props.history.push(`?pdesc=${e.detail.product_details.id}`)
                this.productDescription(e.detail.product_details)
            })
        })
        
        // this.getCart(localStorage.getItem('access_token'), x_app_key)

        $('#descriptionModal').animate({ scrollTop: 0 }, 'fast')

        this.productDescriptionSubscription()
    }

    productDescriptionSubscription = () => {
        // console.log('productDescription::', this.state.cart_items)
        // subscription for cart quantity
        let productDescriptionSubscription = window.serino_components.ProductDescriptionComponent.subscriptions
        let productDescriptionItems = new CustomEvent(productDescriptionSubscription.onCartItems, {
            detail: this.state.cart_items
        })
        document.dispatchEvent(productDescriptionItems)
    }

    similarProductScubscription = () => {
        let similarProductScubscription = window.serino_components.SimilarProductsComponent.subscriptions
        let similarProductItems = new CustomEvent(similarProductScubscription.onCartItems('market-landing-page'), {
            detail: this.cart_items
        })
        document.dispatchEvent(similarProductItems)
    }

    showSideCategories = () => {
        document.getElementById("side-categ").style.width = "250px";
        document.getElementById("side-categ").style.padding = "20px 10px";
        document.getElementById('side-categ-backdrop').style.display = "block"

        // accordion for side category
        var accordion = document.getElementsByClassName("cmb-collection-sub-items")
        var i;
        for ( i = 0; i < accordion.length; i++) {
            accordion[i].addEventListener("click", function() {
                this.childNodes[0].classList.toggle('active-categ')
                var sub_categ = this.childNodes[1].children
                for (var x = 0; x < sub_categ.length; x++) {
                    if (sub_categ[x].style.display === "block") {
                        sub_categ[x].style.display = "none"
                    } else {
                        sub_categ[x].style.display = "block"
                    }
                }
            })
        }
    }

    closeSideNav = () => {
        document.getElementById("side-categ").style.width = "0";
        document.getElementById("side-categ").style.padding = "0";
        document.getElementById('side-categ-backdrop').style.display = "none"
    }

    getAddressObject = (address_object) => {
        this.setState({address_object})
    }
    
    headerChange = () => {
        $('.borderTop').addClass('green')
    }

    handleSignUpRedirect = () => {
        window.location = '/login'
    }

    handleProductRedirect = (category_id, category_name, sub_category_name, bannerImage) => {
        localStorage.setItem("category_branch_id", 6)
        localStorage.setItem("collection_id", category_id)
        localStorage.setItem('banner_image', bannerImage)
        sessionStorage.setItem('viewAllAction', 'categoryMenu') 
        localStorage.setItem("categories_display", JSON.stringify({
            category: category_name,
            subcategory: sub_category_name
        }))
        window.location = '/market-products'
    }

    handleModalClose = () => {
        this.productAutoTagSubscription()
        this.productDescriptionSubscription()
        this.similarProductScubscription()
    }

    handleClearAddress = () => {
        this.setState({
            cart_items: []
        }, () => {
            getCrossCart(localStorage.getItem('access_token'))
            this.productAutoTagSubscription()
            this.productDescriptionSubscription()
            this.similarProductScubscription()
            // this.productCollectionsSubscription()
        })
    }

    handleLocationChange = (address_object) => {
        let address = address_object.find(
          (x) => x.brand_id == localStorage.getItem("brand_id")
        );
        if (address) {
          setHeaderAddress(address);
        }
        this.setState(
          {
            address_object: address_object,
            isAddBtnLoading: false,
            cart_items: [],
            branch_id: address.branch_id
          },
          () => {
            this.productAutoTagSubscription()
            this.productDescriptionSubscription()
            this.similarProductScubscription()
            this.updateCartCount([])
            triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.initializeProducts(this.state.a_parent_card.id), address.branch_id)
            triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.initializeProducts(this.state.m_parent_card.id), address.branch_id)
          }
        );
    }

    setBanners = async () => {
        let banners = []
        try {
            const dataFromJSON = await coreAPI.getDataFromJSON()

            if (!dataFromJSON) {
                return
            }

            if (dataFromJSON.data && dataFromJSON.data.banners) {
                banners = dataFromJSON.data.banners.filter(x => x.application_id == 1)
            }

            log({banners})

            this.setState({banners})

            return
        }
        catch(getDataErr) {
            log(getDataErr)
        }
    }

    addSearchEvents = () => {

        // search component event
        let searchComponentEvent = window.serino_components.SearchProductListComponent.events
        document.addEventListener(searchComponentEvent.onSelect, (e) => {
            this.productDescription(e.detail.product, x_app_key)

            // log('search view product', e.detail.product)

            document.getElementById('srn-product-search-result-container').style.display = 'none'
        })

        // document.addEventListener(searchComponentEvent.onViewAllResult, (e) => {
        //     console.log(e.detail)
        //     localStorage.removeItem('banner_image')
        //     sessionStorage.setItem('viewAllAction', 'viewSearchResult')
        //     sessionStorage.setItem('searchKeyWord', e.detail.searchKeyword)
        //     window.location = '/market-products'
        // })

        document.addEventListener(searchComponentEvent.onAddToCart, (e) => {
            // document.getElementById('srn-product-search-result-container').style.display = 'none'
            this.setState({product: e.detail}, () => {
                log('addtocart called searchevent', e.detail)
                this.addToCart(e.detail)
            })
        })

        // delete item event
        document.addEventListener(searchComponentEvent.onDeleteItem, (e) => {
            if (window.location.pathname !== '/market') {
                return;
            }
            this.setState({
                isSearchActive: true
            })
            let id = e.detail.detail.cartItemEntity;
            this.removeCartItem(x_app_key, id)
        });

        // manual input quantity
        document.addEventListener(searchComponentEvent.updateQuantity, (e) => {
            if (window.location.pathname !== '/market') {
                return;
            }
            this.setState({
                isSearchActive: true
            })
            let id = e.detail.detail.id;
            let payload = {
                quantity: e.detail.detail.quantity,
                include_promo: true,
            };
            this.updateCartQuantity(x_app_key, id, payload)
            this.updateCartCount(e.detail.cart_items)
        });

        // increment button
        document.addEventListener(searchComponentEvent.onUpdateIncrement, (e) => {
            if (window.location.pathname !== '/market') {
                return;
            }
            this.setState({
                isSearchActive: true
            })
            let id = e.detail.payload.cartItemId;
            let payload = {
                quantity: e.detail.payload.quantity,
                include_promo: true,
            };
            this.updateCartQuantity(x_app_key, id, payload)
            this.updateCartCount(e.detail.cart_items)
        });

        // decrement button
        document.addEventListener(searchComponentEvent.onUpdateDecrement, (e) => {
            if (window.location.pathname !== '/market') {
                return;
            }
            this.setState({
                isSearchActive: true
            })
            let id = e.detail.payload.cartItemId;
            let payload = {
                quantity: e.detail.payload.quantity,
                include_promo: true,
            };
            this.updateCartQuantity(x_app_key, id, payload)
            this.updateCartCount(e.detail.cart_items)
        });
    }

    handleSearchDisplay = (bool) => {
      if (document.getElementById('srn-product-search-result-container')) {
        document.getElementById('srn-product-search-result-container').style.display = 'block'
      }
    }

    handleSharedData_a = (products) => {

        if (!this.state.isSharedDataLoaded) {
            if (this.state.a_child_cards && this.state.a_child_cards.length > 0) {
                this.state.a_child_cards.forEach(card => {
                    let autoComponent = window.serino_components.AutoTaggingComponent.create({
                        access_token: localStorage.getItem('access_token'),
                        x_app_key: x_app_key,
                        branch_id: this.state.branch_id,
                        auto_tag: card.tag,
                        sectionName: card.title,
                        currency: '₱',
                        id: card.id,
                        products: products,
                        style: 2
                    }, `srn-auto-tagging-component-${card.id}`);
                })
            }
        }

        if (this.state.isSharedDataLoaded) {
            if (this.state.a_child_cards && this.state.a_child_cards.length > 0) {
                this.state.a_child_cards.forEach(card => {
                    triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.initializeProducts(card.id), {
                        branch_id: this.state.branch_id,
                        products: products
                    })
                })
            }
        }

        setTimeout(() => {
            this.setState({
                isSharedDataLoaded: true
            })
        }, 300)
    }


    handleSharedData_m = (products) => {

        if (!this.state.isCollectionSharedDataLoaded) {

            if (this.state.m_child_cards && this.state.m_child_cards.length > 0) {
                this.state.m_child_cards.forEach(card => {
                    let manualComponent = window.serino_components.AutoTaggingComponent.create({
                        access_token: localStorage.getItem('access_token'),
                        x_app_key: x_app_key,
                        branch_id: this.state.branch_id,
                        productType: card.type === 'manual' ? 2 : 1,
                        sectionName: card.title,
                        currency: '₱',
                        id: card.id,
                        products: products,
                        collection_id: card.id,
                        no_of_items: card.no_of_items ? card.no_of_items : 7
                    }, `srn-auto-tagging-component-${card.id}`);
                })
            }
        }

        if (this.state.isCollectionSharedDataLoaded) {
            if (this.state.m_child_cards && this.state.m_child_cards.length > 0) {
                this.state.m_child_cards.forEach(card => {
                    triggerSerinoEvent(window.serino_components.AutoTaggingComponent.subscriptions.initializeProducts(card.id), {
                        branch_id: this.state.branch_id,
                        products: products
                    })
                })
            }
        }

        setTimeout(() => {
            this.setState({
                isCollectionSharedDataLoaded: true
            })
        }, 300)
    }


    handleSelectCategory = (category) => {
        let query = category;

        let element = document.getElementById(query);
    
        if (element) {
          element.scrollIntoView({
            block: "start",
            inline: "start",
            behavior: "smooth",
          });
        }
    };

    handleBundleAdd = (product) => {
        this.addToCart(product)
    }

    handleBundleDelete = (id) => {
        this.setState({
            cart_items: this.state.cart_items.filter(x => x.id !== id)
        })
    }

    handleBundleGetCart = (cart_return) => {
        if (cart_return && cart_return.items) {
            this.getCart(cart_return.items)
        }
    }

    getMarketBranch = () => {
        let parsed = localStorage.getItem(`address_object_${x_app_key}`) ? JSON.parse(localStorage.getItem(`address_object_${x_app_key}`)) : null
        if (parsed) {
            let address = parsed.find(x => x.app_id == x_app_key)
            if (address) {
                return address.branch_id
            }
            else {
                return 6
            }
        }
        else {
            return 6
        }
    }
    
    bannerNav = (e) => {
        setTimeout(() => {
            if (document.getElementById("viewall-"+e)) {
                document.getElementById("viewall-"+e).click()
            }
        },500)
    }

    getDataFromJSON = async () => {
        let apps = []

        try {
            const dataFromJSON = await coreAPI.getDataFromJSON()

            if (!dataFromJSON) {
                return
            }

            let data = dataFromJSON.data

            if (!data) {
                return
            }

            let homecards = data.homecards

            if (!homecards) {
                return
            }

            let m_homecards = data.homecards.filter(card => card.status == 1 && card.type === 'manual')
            let m_parent_card = m_homecards[0]
            let m_child_cards = m_homecards.slice(1)

            let a_homecards = data.homecards.filter(card => card.status == 1 && card.type === 'auto')
            let a_parent_card = a_homecards[0]
            let a_child_cards = a_homecards.slice(1)

            this.setState({
                homecards,
                m_homecards,
                m_parent_card,
                m_child_cards,
                a_homecards,
                a_parent_card,
                a_child_cards,
            }, () => {
                this.initializeHomeCards()
                this.handleHomeCardEvents()
            })
        }
        catch(getDataErr) {
            log(getDataErr)
        }
    }

    initializeHomeCards = () => {

        let m_parent_card = this.state.m_parent_card
        let m_parent_id = m_parent_card.id
        let m_collection_ids = this.state.m_homecards.map(x => x.id)

        let a_parent_card = this.state.a_parent_card
        let a_parent_tag = a_parent_card.tag
        log({a_parent_tag})
        let a_collection_tags = this.state.a_homecards.map(x => x.tag)

        let autoTaggingEvent = window.serino_components.AutoTaggingComponent.events

        if (this.state.m_homecards && this.state.m_homecards.length > 0) {

            let m_parent_element = document.getElementById(`srn-auto-tagging-component-${m_parent_card.id}`)

            if (m_parent_element) {
                let parentCardMComponent = window.serino_components.AutoTaggingComponent.create({
                    access_token: localStorage.getItem('access_token'),
                    x_app_key: x_app_key,
                    branch_id: this.state.branch_id,
                    sectionName: m_parent_card.title,
                    currency: '₱',
                    id: m_parent_id,
                    isParent: true,
                    style: 2,
                    productType: 2,
                    collection_ids: m_collection_ids,
                    collection_id: m_parent_id,
                    no_of_items: m_parent_card.no_of_items ? m_parent_card.no_of_items : 7
                }, `srn-auto-tagging-component-${m_parent_id}`)

                document.addEventListener(autoTaggingEvent.onLoadSharedData(m_parent_id), (e) => {
                    let products = []
                    e.detail.forEach(x => x.items.forEach(y => products.push(y)))
                    let uniqueProducts = [...new Set(products)]
                    GAImpressions(uniqueProducts, 'Market Manual Landing Products')

                    if (this.state.product && this.state.product.id) {
                        let selected_product = products.find(x => this.state.product.id == x.id)
                        if (selected_product) {
                            this.addToCart(selected_product)
                        }
                    }

                    this.setState({
                        products
                    }, () => {
                        this.handleSharedData_m(e.detail)
                    })

                })
            }

        }

        if (this.state.a_homecards && this.state.a_homecards.length > 0) {

            let a_parent_element = document.getElementById(`srn-auto-tagging-component-${a_parent_card.id}`)

            if (a_parent_element) {
                let parentCardAComponent = window.serino_components.AutoTaggingComponent.create({
                    access_token: localStorage.getItem('access_token'),
                    x_app_key: x_app_key,
                    branch_id: this.state.branch_id,
                    auto_tag: a_parent_tag,
                    tags: a_collection_tags,
                    sectionName: a_parent_card.title,
                    currency: '₱',
                    id: a_parent_card.id,
                    isParent: true,
                    style: 2,
                    no_of_items: a_parent_card.no_of_items ? a_parent_card.no_of_items : 7
                }, `srn-auto-tagging-component-${a_parent_card.id}`);

                document.addEventListener(autoTaggingEvent.onLoadSharedData(a_parent_card.id), (e) => {
                    let products = []
                    e.detail.forEach(x => x.items.forEach(y => products.push(y)))
                    let uniqueProducts = [...new Set(products)]
                    GAImpressions(uniqueProducts, 'Market Autotag Landing Products')
                    log('products from shared data', uniqueProducts)


                    if (this.state.product && this.state.product.id) {
                        let selected_product = products.find(x => this.state.product.id == x.id)
                        if (selected_product) {
                            this.addToCart(selected_product)
                        }
                    }

                    this.setState({
                        products
                    }, () => {
                        this.handleSharedData_a(e.detail)
                    })

                })
            }

        }

    }

    bannerClick = () => {
        document.getElementById("23694").click()
    }

    handleBannerClick = (e, banner) => {
        const id = banner?.id

        // Get slide container
        const parentEl = e?.currentTarget?.parentNode?.parentNode
        const prevSibling = parentEl?.previousSibling
        const nextSibling = parentEl?.nextSibling
    
        // Get slide container classlist
        const parentClassList = parentEl?.classList
        
        // Check if container is the main slide
        if (parentClassList?.contains('slick-current')) {
            if (banner?.url?.length > 0) {
                window.location = banner.url
                return;
            }
            if (id) {
                this.bannerNav(id)
            }
        }
        // Check if container is positioned right
        else if (prevSibling?.classList?.contains('slick-current')) {
          this.sliderRef.current.slickNext();
        }
        // Check if container is positioned left
        else if (nextSibling?.classList?.contains('slick-current')) {
          this.sliderRef.current.slickPrev();
        }
        // If the above conditions were not met, it means the user is on a mobile view, which utilizes the previous/next element from react-slick.
        else {
            if (banner?.url?.length > 0) {
                window.location = banner.url
                return;
            }
            if (id) {
                this.bannerNav(id)
            }
        }
      }

    render() {
        const settings = {
            dots: true,
            speed: 500,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '0px',
            slidesToShow: 3,
            adaptiveHeight: false,
            autoplay: false,
            infinite: true,
            cssEase: 'linear',
            variableWidth: true,
            variableHeight: true,
            prevArrow: <span>Prev</span>,
            nextArrow: <span>Next</span>,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerPadding: '0px',
                        swipeToSlide: true,
                        centerMode: true,
                        swipe: true,
                    
                    }
                }
            ]
            };
        return (
            <div className="d-flex flex-column h-100" id="marketmainlandingpage">
                {
                    this.state.page != "shoppingbasket" &&
                    <div className="market-header-scroll" id="market-header-scroll">
                        <div className="market-header-scroll-container">
                            <span onClick={() => this.handleSelectCategory("c1")}>Categories</span>
                            {
                                this.state.homecards.map(card => (
                                    <span onClick={() => this.handleSelectCategory(`c${card.id}`)}>{card.title}</span>
                                ))
                            }
                        </div>
                    </div>
                }
                 {
                    this.state.access_token &&
                    <LocationPicker 
                        access_token={this.state.access_token}
                        id="market_landing_page"
                        x_app_key={x_app_key}
                        brand_id={1}
                        getAddressObject={this.getAddressObject}
                        app_key={process.env.REACT_APP_MARKET_APP_ID}
                        handleModalClose={this.handleModalClose}
                        handleClearAddress={this.handleClearAddress}
                        handleLocationChange={this.handleLocationChange}
                        cart_items={this.state.cart_items}
                        triggerGetDefaultAddress={this.state.triggerGetDefaultAddress}
                        hidden={true}
                    />
                }
                {
                    this.state.page == "shoppingbasket" &&
                    <div className={`row ${this.state.page == "shoppingbasket" ? "shoppingbasket" : "d-none"}`} >
                        <div className="market-category-banner-container-shoppingbasket">
                                <>
                                    <img
                                        src={ShoppingBasketBanner}
                                        className="market-prod-banner-img"
                                        effect="blur"
                                    />
                                <h6 className="market-collection-name">
                                    Shopping Baskets
                                </h6>
                                </>
                        </div>
                        <div class="col-md-12">
                        <span className="back-button" onClick={() => window.location = "/market"}>← Back</span>
                        <h5 class="srn-product-collection-section-name mb-2">Shopping Baskets</h5>
                        {
                            this.state.isShoppingBasketActive &&
                                <ShoppingBasketComponent 
                                    app_key={x_app_key}
                                    id="market"
                                    logo={<img src={MarketSVG} className="srn-bundle-logo-img" onClick={() => this.proceedToApp(1)}/>}
                                    className="srn-bundle-market"
                                    // handleClick={this.handleCollectionSelect}
                                    branch_id={this.getMarketBranch()}
                                    cart_items={this.state.cart_items}
                                    handleUpdateCart={this.handleBundleGetCart}
                                    handleInitialAdd={this.handleBundleAdd}
                                    handleDeleteItems={this.handleBundleDelete}
                                />
                        }
                        </div>
                    </div>
                }
                <div className={`${this.state.page == "shoppingbasket" ? "d-none" : ""}`}>
                <div className="market-header-scroll d-none">
                    <div className="market-header-scroll-container">
                        <span onClick={() => this.handleSelectCategory("c1")}>Categories</span>
                        <span onClick={() => this.handleSelectCategory("c2")}>Shopping Baskets</span>
                        {/* <span onClick={() => this.handleSelectCategory("c9")}>Central Market Exclusives</span> */}
                        {
                            this.state.homecards.map(card => (
                                <span onClick={() => this.handleSelectCategory(`c${card.id}`)}>{card.title}</span>
                            ))
                        }
                    </div>
                </div>
               
                {/* <ParallaxComponent page="market-landing" /> */}
                <div className="container market-landing-container " id="market-container">

                    {/* SIDE CATEGORY LIST ON SMALL SCREEN */}
                    <div className="categ-search-section">
                        <button className="btn-categ" onClick={() => this.showSideCategories()}>Categories and Collection</button>
                        {/* <div id="srn-search-product-list-container"></div> */}
                    </div>

                    <div id="side-categ" className="sidenav side-categ">
                        <h6>Select Categories</h6>
                        {
                            this.state.isCategoryLoading &&
                            <div className="srn-category-loading-container">
                                <FontAwesomeIcon icon={faSpinner} spin />
                            </div>
                        }
                        <div id="srn-category-list-container" className="side-category-list"></div>
                    </div>

                    <div id="side-categ-backdrop" className="side-menu-backdrop side-menu-backdrop-transition" onClick={() => this.closeSideNav()}></div>
                    
                    <div className="row no-gutters">
                        {/* SIDE CATEGORY LIST catfixed */}
                        {/* <div className="col-md-2 col-sm-12 left-210 catfixed srn-category-container">
                            <h6>Select Categories</h6>
                            {
                                this.state.isCategoryLoading &&
                                <div className="srn-category-loading-container">
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </div>
                            }
                            <div id="srn-category-list-container-side" className="side-category-list"></div>
                        </div>                         */}
                        
                        <div className="col-md-12">
                            <div className="row no-gutters">
                                {/* {
                                    this.state.banners && this.state.banners.length > 0 &&  */}
                                    <div className="col-12">
                                        {/* <div className="market-landing-banner ">
                                            <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-interval="12000" data-ride="carousel">
                                                <div className="carousel-inner">
                                                    {

                                                        this.state.banners.filter(y => y.status == 1).map((banner, index) => (
                                                          <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                            {
                                                              banner.image &&
                                                               <LazyLoadImage
                                                                    className="d-block w-100 market-banner-top"
                                                                    src={banner.image || ''}
                                                                    alt={banner.title || ''}
                                                                    effect="blur"
                                                               /> || null
                                                            }
                                                          </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div> */}
                                        

                                        {/* <div className="market-landing-banner market-mweb-banner">
                                            <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-interval="12000" data-ride="carousel">
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <img className="d-block w-100 market-banner-top" src={marketMwebanner1} alt="First slide" onClick={() => this.handleProductRedirect(3293, 'Bakery', 'Bread', bakery_banner)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                {/* } */}
                            </div>

                            {/* <div className="row no-gutters" style={{display:'none'}}>
                                <div className="col-12">
                                    <div className="market-landing-banner2">
                                        <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-interval="12000" data-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item" >
                                                    <img className="market-banner-bottom" src={market_ads} alt="First slide" onClick={() => this.handleSignUpRedirect()}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <img className="srn-super-store-banner" src={marketmidbanner2} alt="Super Store banner"/>
                                </div>
                            </div> */}
                            <Slider {...settings} data-slick="arrows" arrows="true" className={`${this.state.showbanner == true ? "" : "d-none"}`} ref={this.sliderRef}>
                                {
                                    this.state.banners.filter(y => y.status == 1).map((banner, index) => (
                                    <div className={`slider__item `} onClick={(e) => this.handleBannerClick(e, banner)}>
                                        {
                                        banner.image &&
                                        <img
                                            className="web-banner"
                                            src={banner.image || ''}
                                            alt={banner.title || ''}
                                            // onClick = {() => this.bannerClick()}
                                        /> || null
                                        }
                                    </div>
                                    ))
                                }
                                    {/* {
                                    this.state.banners.filter(y => y.status == 1).slice(09,3).map((banner, index) => (
                                    <div className={`slider__item `} >
                                        {
                                        banner.image &&
                                        <img
                                            className="web-banner"
                                            src={banner.image || ''}
                                            alt={banner.title || ''}
                                        /> || null
                                        }
                                    </div>
                                    ))
                                } */}
                            </Slider>
                            <div className="row" id="c1">
                                <div className="col-md-12">
                                    <h5 className="srn-product-collection-section-name">Categories</h5>
                                    <div id="srn-category-list-container-side" className="side-category-list"></div>
                                    
                                </div>
                                {/* <div className="col-md-1 more-categ">
                                    <span className="icon"><FontAwesomeIcon icon={faPlus} /></span>
                                    <span className="name">More Categories</span>
                                </div> */}
                            </div>

                            <div className="row" id="c2">
                                <div class="col-md-12">
                                <h5 class="srn-product-collection-section-name mb-2 d-flex justify-content-between">
                                    <span>Shopping Baskets</span>
                                    <span id="srn-product-collection-view-all" onClick={() => window.location = '/market?shoppingbasket'}>View All</span>
                                </h5>
                                {
                                    this.state.isShoppingBasketActive && this.state.page != "shoppingbasket" &&
                                    <ShoppingBasketComponent 
                                        app_key={x_app_key}
                                        id="market"
                                        logo={<img src={MarketSVG} className="srn-bundle-logo-img" onClick={() => this.proceedToApp(1)}/>}
                                        className="srn-bundle-market"
                                        // handleClick={this.handleCollectionSelect}
                                        branch_id={this.getMarketBranch()}
                                        cart_items={this.state.cart_items}
                                        handleUpdateCart={this.handleBundleGetCart}
                                        handleInitialAdd={this.handleBundleAdd}
                                        handleDeleteItems={this.handleBundleDelete}
                                    />
                                }
                                </div>
                            </div>
                            <div classname="srn-market-divider"></div>
                                {
                                    this.state.homecards && this.state.homecards.length > 0 &&
                                    this.state.homecards.map((card, index) => (
                                        <div className="row srn-homecard-row" id={`c${card.id}`}>
                                            {
                                                this.state.isSharedDataLoaded && this.state.isCollectionSharedDataLoaded && card.middle_banner && card.middle_banner.length > 0 && card.middle_banner_position === 'top' &&
                                                <div className="col-md-12">
                                                    <div className="srn-middle-ad-container srn-ad-top">
                                                        <img
                                                            className={`w-100 ${card.middle_banner_id && card.middle_banner_id.length > 0 ? 'srn-banner-clickable' : ''}`}
                                                            src={card.middle_banner}
                                                            onClick={() => card.middle_banner_id && card.middle_banner_id.length > 0 ? this.bannerNav(card.middle_banner_id) : null}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-12">
                                                <div className="market-flash-sale-product-container" id={`srn-auto-tagging-component-container-${card.id}`}>
                                                    <div id={`srn-auto-tagging-component-${card.id}`} className="srn-srn-product-flash-sale-container-component-container srn-products-style-2"></div>
                                                </div>
                                            </div>
                                            {
                                                this.state.isSharedDataLoaded && this.state.isCollectionSharedDataLoaded && card.middle_banner && card.middle_banner.length > 0 && card.middle_banner_position === 'bottom' &&
                                                <div className="col-md-12">
                                                    <div className="srn-middle-ad-container srn-ad-bottom">
                                                        <img
                                                            className={`w-100 ${card.middle_banner_id && card.middle_banner_id.length > 0 ? 'srn-banner-clickable' : ''}`}
                                                            src={card.middle_banner}
                                                            onClick={() => card.middle_banner_id && card.middle_banner_id.length > 0 ? this.bannerNav(card.middle_banner_id) : null}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                            {/* <div className="col-md-2 col-sm-12 market-landing-ads">
                                <div className="sticky-top">
                                    <img className="srn-ad-img mb-4" src={marketsidebanner1} />
                                    <img className="srn-ad-img" src={marketsidebanner3} />
                                </div>
                            </div> */}
                        </div>
                    </div>

                    
                    <div id="srn-market-cart-container" className="d-none"></div>

                    <div className="modal srn-product-description-market" id="descriptionModal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header srn-ft-modal-header">
                                    <img src={MarketLogo} className="srn-ft-brand-logo" />
                                    {/* <img src={brand_logo} className="srn-ft-brand-logo" /> */}
                                    <button type="button" className="close d-block" data-dismiss="modal" aria-label="Close">
                                        <span >&times;</span>
                                    </button>
                                </div>
                            <div className="modal-body">
                                <div id="srn-product-description-container"></div>
                                <h6 className={`srn-market-similar-product-label ${this.state.similar_items.length > 0 ? '' : 'd-none'}`}>Similar Products</h6>
                                <div id="srn-similar-product-container" className={`srn-market-similar-items-container ${this.state.similar_items.length > 0 ? '' : 'd-none'}`}></div>  
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div> 
        )
    }
}