import React, { Component } from "react";
import CoreAPI from "../../_core/core";
import { extractObjectFromURL, log } from "../../helper/helper";
let coreAPI = new CoreAPI()

class PromoMechanicsAndGeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {}
    }
  }

  componentDidMount() {
    this.getDataFromJSON();
  }

  /** Get Data from Brands JSON */
  getDataFromJSON = async () => {


    try {
        const dataFromJSON = await coreAPI.getDataFromJSON()

        if (!dataFromJSON) {
          return;
        }

        if (dataFromJSON.data && dataFromJSON.data.terms_and_conditions) {
          if (this.props?.location?.search?.length > 0) {
            const contentArray = dataFromJSON.data.terms_and_conditions;
            const queryObj = extractObjectFromURL(this.props.location.search);
            const promoId = parseInt(queryObj?.id);

            if (!promoId) return;

            const promo = contentArray?.find(x => x?.id === promoId)

            if (!promo) return;

            this.setState({ promo })

          } 
        }
    }
    catch(getDataErr) {
        log(getDataErr)
    }
  }

  handleBack = () => {
    this.props?.history?.goBack();
  }

  render() { 
    return (
      <>
        <button className="btn srn-back-btn" onClick={() => this.handleBack()}>← Back</button>
        <div className="srn-promo-mechanics-container">
          
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: this.state.promo?.content || ''}} className="srn-promo-mechanics-content"></div>
          </div>
        </div>
      </>
    );
  }
}
 
export default PromoMechanicsAndGeneralInfo;