/* eslint-disable default-case */
import {
  faBars,
  faMapMarkerAlt,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import Swal from 'sweetalert2'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React, { Component, Fragment } from "react";
import CentralLogo from "../../assets/images/central_logo@2x.png";

import searchIcon from "../../assets/images/search-icon.png";
//Central 3.0
import marketsLogo from "../../assets/images/market3.0_logo.png";
import eatsLogo from "../../assets/images/eats3.0_logo.png";
import eatsProductLogo from "../../assets/images/eatsproduct3.0_logo.png";
import centralImg from '../../assets/images/logo/logo.svg'

import sidenavbg from "../../assets/images/mweb_sidenav_bg.png";
import shoppingBasket from "../../assets/images/central_shopping_basket.png";
import facebook from "../../assets/images/FBx2.svg"
import instagram from "../../assets/images/IGx2.svg"
import {
  handleExpiredToken,
  log,
  setCrossStorage,
  getTemporaryToken,
  triggerSerinoEvent,
  getUrl,
  getCrossDomainToken,
  getSwalContent,
  addEvt,
  cleanAddresses,
  handleSearchRedirect
} from "../../helper/helper";
import { GAViewProduct } from "../../helper/analytics";
import { fbqEvent } from "../../helper/fbpixel";
import Cart from "../../_core/cart";
import LoginAPI from "../../_core/login";
import ProfileAPI from '../../_core/profile';
import SearchComponent from '../partials/SearchComponent'
import emptyIcon from "../../assets/images/empty_icon.svg";
import { debounce } from 'lodash'
import CoreAPI from "../../_core/core";

var cart = new Cart();
var login = new LoginAPI();
var coreAPI = new CoreAPI();

const x_app_key = process.env.REACT_APP_X_APP_KEY;
const tenant_id = process.env.REACT_APP_TENANT_ID;
const app_env = process.env.REACT_APP_ENV;
const membership_feature_flag = process.env.REACT_APP_MEMBERSHIP_FLAG;
const market_app_id = process.env.REACT_APP_MARKET_APP_ID;
const eats_app_id = process.env.REACT_APP_EATS_APP_ID;

let retry_interval = 1500;

var profileAPI = new ProfileAPI

const handlePageRedirectApp = (domain) => {
  window.location.href = `/${domain || ''}`;
};

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathName: "",
      cartQuantity: "",
      isLoggedIn: localStorage.getItem("isLoggedIn")
        ? localStorage.getItem("isLoggedIn")
        : false,
      host: "",
      domain: "main",
      domain_name: "",
      app_key: "",
      access_token: "",
      user: {},
      client: {},
      user_role: "",
      isLoading: true,
      cart_Count: 0,
      trigger: "",
      showProfile: false,
      TrackorderActive: false,
      main_address: localStorage.getItem("main_address") ? JSON.parse(localStorage.getItem("main_address")) : null,
      default_address: localStorage.getItem("default_address") ? JSON.parse(localStorage.getItem("default_address")) : null,

      profile_link: [
        {
          id: 1,
          name: "Personal Details",
        },
        {
          id: 2,
          name: "My Addresses",
        },
        {
          id: 3,
          name: "Change Password",
        },
        {
          id: 4,
          name: "Link Landers Membership Card",
        },
      ],
      isSearchActive: false,
      isHome: false,
      client: {},
      userFirstName: "",
      userLastName: "",
      // State for delay warnings
      delayWarning: {
        status: 0,
        message: '',
      },
      search: '',
    };
  }

  async componentDidMount() {
    addEvt(window.serino_components.SearchProductListComponent.events.onSearch, (param) => {
    if (param?.detail !== this.state.search) {
      this.setState({
        search: param?.detail || ''
      })
        this.handleSearchDebonce()
      }
    })

    /** Event listener for clicking "Go" button in search */
    addEvt(window.serino_components.SearchProductListComponent.events.onSearchRedirect, (e) => {
      const searchObject = {
        query: e.detail.query || '',
        app: e.detail.app || '',
      }
      handleSearchRedirect(searchObject)
    })
    
    /** Event listener for clicking "Back to search" button in search component */
    addEvt(window.serino_components.SearchProductListComponent.events.onSearchBack, (e) => {
      const searchObject = {
        query: e.detail.query || '',
        app: e.detail.app || '',
      }
      handleSearchRedirect(searchObject)
    })

    // Get delay warning status from config
    try {
      const warningDataFromJSON = await coreAPI.getWarningDataFromJSON()
      const warningData = warningDataFromJSON?.data?.warnings
      let warningProperty = ''
      
      for (let property in warningData) {
        if (warningData[property].status === 1) {
          warningProperty = property;
          break;
        }
      }

      // Adjust scrollable menus in Market & Eats
      if (warningData?.[warningProperty]?.status === 1) {
        const marketMenuEl = document.getElementById('market-header-scroll')
        const eatsMenuEl = document.getElementById('srn-category-component-container')
        const mainContainerEl = document.querySelector('.cmb-container')
        // Adjust content padding
        if (mainContainerEl) {
          mainContainerEl.setAttribute('style', `padding-top: 52px`);
        }
        if (marketMenuEl) {
          marketMenuEl.style.top = window.innerWidth < 800 ? '12rem' : '8rem'
        }
        if (eatsMenuEl) {
          eatsMenuEl.setAttribute('style', `top: ${window.innerWidth < 800 ? '196px !important' : '125px !important'}`);
        }
      }
      this.setState({ delayWarning: warningData?.[warningProperty] })

    } catch (error) {
      console.error(error)
    }

    document.addEventListener(window.serino_components.ProductDescriptionComponent.events.onProductUpdated, (e) => {
      GAViewProduct(e.detail)
    })
    document.addEventListener('srn-on-path-change', (e) => {
      this.setState({
        pathName: e.detail
      })
    })

    cleanAddresses()

    sessionStorage.removeItem("profileEnabled");
    sessionStorage.removeItem("signupEnabled");
    sessionStorage.removeItem("addressEdit");

    // this.handleAccesToken()
    var host = window.location.host;
    var parts = host.split(".");

    let url = `${getUrl("main")}/`;
    let url1 = `${getUrl("main")}/#!`;
    let url2 = `${getUrl("main")}/eats`;
    let url3 = `${getUrl("main")}/eats#!`;
    // let url4 = `${getUrl("main")}/market`;
    // let url5 = `${getUrl("main")}/market#!`;

    let domain = window.location.pathname.includes('/market') || window.location.pathname.includes('/eats-products') ? 'sub' : 'main'

    let isHome = false;
    let isLanding = false;

    if (window.location.pathname == '/' || window.location.pathname == '/eats') {
      isHome = true;
    }

    if (window.location.pathname == '/' || window.location.pathname == '/cart') {
      isLanding = true;
    }

    this.setState({
      pathName: window.location.pathname,
      host: parts,
      domain: domain,
      domain_name: parts[0],
      isHome,
      isLanding,
    });

    let getTokenTries = 0;

    let access_token_interval = setInterval(async () => {
      let access_token = null
      if (window.location.host.includes('market') || window.location.host.includes('eats')) {
        access_token = await getCrossDomainToken();
      } else {
        access_token = await localStorage.getItem("access_token");
      }
      getTokenTries++;

      // log("access_token from header", access_token);

      if ((access_token && access_token != null) || getTokenTries == 8) {
        clearInterval(access_token_interval);

        this.initialize();

        try {
          let userDetails = await login.getClientDetails(
            access_token,
            x_app_key
          );

          // log("user details", userDetails);

          let user_role =
            userDetails.data && userDetails.data.roles
              ? userDetails.data.roles[0].name
              : userDetails.data.role;

          // log({user_role})

          if (
            user_role == "Client"
            // &&
            // window.location.pathname !== "/checkout"
          ) {
            localStorage.setItem("isLoggedIn", true);
          }

          let membership = userDetails.data?.metas?.find(
            (x) => x.key == "Rewards" || x.key == "RewardsLinked"
          ); // temporary fix , need to get membershipID

          // log("user role", user_role);
          // log("membership", membership);

          if (user_role === "Guest") {
            localStorage.removeItem("isLoggedIn");
          }

          if (user_role === "Client") {
            profileAPI.getAllOrders(localStorage.getItem('access_token'))
              .then((res) => {
                if (res.data.successful) {
                  let findOrder = res.message.filter((x) => x.delivery_status == "pending" || x.delivery_status == "Ready" ||
                    x.delivery_status == "in transit" || x.delivery_status == "on hold" || x.delivery_status == "preparing");

                  if (findOrder.length != "undefined" && findOrder.length > 0) {
                    this.setState({ TrackorderActive: true })
                  }
                }
                if (!res.data.successful) {
                  if (res.data.error && res.data.error.status && res.data.error.status == 400) {
                    handleExpiredToken()
                  }
                }
              })
            this.setState({
              client: {
                first_name: userDetails.data?.firstName,
                last_name: userDetails.data?.lastName,
                email: userDetails.data?.email,
                phone_number: userDetails.data?.contactNumbers.find(x => x.isPrimary),
                metas: userDetails.data?.metas
              },
              userFirstName: userDetails.data?.firstName,
              userLastName: userDetails.data?.lastName,
            }, () => {
              localStorage.setItem('client_details', JSON.stringify(this.state.client))
              triggerSerinoEvent(window.serino_components.PersonalDetailsComponent.subscriptions.populateFields, this.state.client)
            })
          }

          if (membership) {
            localStorage.setItem("isMember", true);
          } else {
            localStorage.removeItem("isMember");
          }

          localStorage.removeItem("new_session_attempt");

          this.setState({
            client: userDetails.data,
            user_role,
            isLoading: false,
          });
        } catch (err) {
          log("err", err);
          handleExpiredToken();
          // if (err.message === "Invalid Access Token" && access_token != null) {
          // }
        }
      }
    }, retry_interval);
  }

  initialize = async (access_token) => {
    getTemporaryToken().then(access_token => {
      window.serino_components.AppSelectorComponent.create(
        {
          access_token: access_token,
          x_app_key: x_app_key,
          tenant_id: tenant_id,
        },
        "srn-app-selector-container"
      );

      let appSelectorEvent = window.serino_components.AppSelectorComponent.events;

      document.addEventListener(appSelectorEvent.onAppSelect, (e) => {
        // console.log('app-selector', e.detail.app)
        this.handleSelectApp(e.detail.app);
      });

      // document.addEventListener(appSelectorEvent.onAppLoad, (e) => {
      //   setTimeout(() => {
      //     window.serino_components.AppSelectorComponent.create(
      //       {
      //         access_token: access_token,
      //         x_app_key: x_app_key,
      //         tenant_id: tenant_id,
      //         apps: e.detail,
      //       },
      //       "srn-app-selector-container-side"
      //     );
      //   }, 200);
      // });

      let host = window.location.pathname
      let app_id = ''

      let brand = ''

      let brand_id = localStorage.getItem('brand_id')

      if (host.includes('eats')) {
        app_id = process.env.REACT_APP_EATS_APP_ID
        if (host.includes('/eats-products')) {
          setCrossStorage('searchMode', 2)
          brand_id = localStorage.getItem('brand_id')
          brand = localStorage.getItem('brandN')
        }
      }
      if (host.includes('market')) {
        app_id = process.env.REACT_APP_MARKET_APP_ID
        brand_id = 6
      }

      let isCart = window.location.pathname === '/cart'

      let searchProduct =
        window.serino_components.SearchProductListComponent.create(
          {
            access_token: localStorage.getItem("access_token"),
            x_app_key: x_app_key,
            categordId: 13,
            branch_id: this.state.isHome ? null : (localStorage.getItem('branch_id') ? localStorage.getItem('branch_id') : brand_id),
            app_id: app_id,
            type: this.state.isLanding ? (isCart ? 3 : 0) : app_id,
            mode: 0,
            emptyIcon: <img src={emptyIcon} />,
            brand: brand,
          },
          "srn-search-product-list-container-header"
        );

      let searchProduct1 =
        window.serino_components.SearchProductListComponent.create(
          {
            access_token: localStorage.getItem("access_token"),
            x_app_key: x_app_key,
            categordId: 13,
            app_id: app_id,
            branch_id: this.state.isHome ? null : (localStorage.getItem('branch_id') ? localStorage.getItem('branch_id') : localStorage.getItem('brand_id')),
            // mode: app_id.length > 0 ? 1 : 0,
            type: this.state.isLanding ? (isCart ? 3 : 0) : app_id,
            mode: 5,
            emptyIcon: <img src={emptyIcon} />,
            brand: brand,
          },
          "srn-search-product-list-container-header-mobile"
        );

      let searchProduct2 =
        window.serino_components.SearchProductListComponent.create(
          {
            access_token: localStorage.getItem("access_token"),
            x_app_key: x_app_key,
            categordId: 13,
            app_id: "",
            branch_id: this.state.isHome ? null : (localStorage.getItem('branch_id') ? localStorage.getItem('branch_id') : brand_id),
            // mode: app_id.length > 0 ? 1 : 0,
            type: this.state.isLanding ? (isCart ? 3 : 0) : app_id,
            mode: 0,
            emptyIcon: <img src={emptyIcon} />,
            brand: brand,
            styleType: 2
          },
          "srn-search-product-list-container-header-mobile2"
        );


      document.getElementById("headerContainer").style.display = "flex";

      setTimeout(() => {
        this.handleAppActive();
      }, 1000);
    })
  };

  handleSelectApp = (app) => {
    let application_id = app.application_id;
    switch (application_id) {
      case 1:
        window.location.href = getUrl('market')
        break;
      case 2:
        window.location.href = getUrl('eats')
        break;
      case 3:
        window.location.href = process.env.REACT_APP_REWARD_PORTAL;
        break;
      default:
        window.location.href = getUrl('main');
        break;
    }
  };

  handleAppActive = () => {
    const host = window.location.pathname;
    const part = host.split("?");
    const parts = host.split("/");
    if (
      (parts.length === 2 && parts[1] === "market") ||
      parts[0] === "qasrn7-market2" ||
      parts[0] === "devsrn7-market" ||
      parts[1] === "market" ||
      parts[1] === "market-products"
    ) {
      // $('.navbar').addClass('marketactive')
      this.setState({ trigger: "market" });
    } else if (
      (parts.length === 2 && parts[1] === "eats") ||
      parts[0] === "qasrn7-eats2" ||
      parts[0] === "devsrn7-eats" ||
      parts[0] === "eats" ||
      parts[1] === "eats-products"
    ) {
      // $('.navbar').addClass('eatsactive')
      this.setState({ trigger: "eats" });
    }
  };

  handlePageRedirect = (path, query) => {
    if (path === 'login') {
      sessionStorage.removeItem('signUpEnabled')
    }
    let newPath = "";
    if (query) {
      newPath = path + query;
    } else {
      newPath = path;
    }
    window.location.href = `${getUrl('main')}/` + newPath
  };

  openSideNav = () => {
    document.getElementById("sideNav").style.width = "100%";
    document.getElementById("sideNav").style.zIndex = "999999";
    document.getElementById("side-menu-backdrop").style.display = "block";
  };

  closeSideNav = () => {
    document.getElementById("sideNav").style.width = "0";
    document.getElementById("side-menu-backdrop").style.display = "none";
    this.setState({ showProfile: false })
  };

  onLogout = () => {
    let path = "";
    this.setState({ isLoggedIn: false, });

    if (sessionStorage.getItem("fromSignupApply") == true || sessionStorage.getItem("fromSignupApply") == "true") {
      this.handleSelectApp(0)
    } else {
      setCrossStorage("isLoggedIn", false, this.state.domain);
      localStorage.setItem("isLoggedIn", false);
      localStorage.removeItem("isMember");
      localStorage.removeItem("access_token");
      localStorage.removeItem("Address");
      localStorage.removeItem("fulfillment_type_status");
      localStorage.removeItem("category_branch_id");
      localStorage.removeItem("SRN-CART-ID");
      localStorage.removeItem("isMember");
      localStorage.removeItem("main_address");
      localStorage.setItem(
        `address_object_${process.env.REACT_APP_EATS_APP_ID}`,
        "[]"
      );
      localStorage.setItem(
        `address_object_${process.env.REACT_APP_MARKET_APP_ID}`,
        "[]"
      );
      this.handlePageRedirect(path, "?page=logout");
    }
  };

  profileLink = (i) => {
    let path = "profile";
    this.handlePageRedirect(path + "?page=" + (i + 1));
    // setCrossStorage('profile_default', i+1, 'central.ph')
    // localStorage.setItem('profile_default', i+1, 'http://central.ph:3000/data')
  };
  myProfileLink = () => {
    let path = "profile";
    this.handlePageRedirect(path + "?page=" + 1);
  };

  showMyprofile = () => {
    if (this.state.showProfile == true) {
      this.setState({ showProfile: false })
    } else {
      this.setState({ showProfile: true })
    }
  };

  showBurgerMenu = () => {
    var dropdown = document.getElementsByClassName("side-burger-button");
    var i;
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  };

  //Temporary fix ---------------------------------------------------------------------------------------------------------------------------
  handleAccesToken = () => {
    try {
      // JSON.parse(localStorage.getItem('address_object_1'));
      JSON.parse(localStorage.getItem("access_token"));
    } catch (e) {
      return false;
    }
    window.location.reload();
    return false;
  };

  handleSearchModal = () => {
    $("#search_modal").modal("show")
    setTimeout(() => {
      if (document.getElementsByClassName("srn-search-input")[2]) {
        document.getElementsByClassName("srn-search-input")[2].focus();
      }
    }, 1000)
  }

  handleMainAddress = async () => {

    if (this.state.isHome) {
      localStorage.setItem("brand_id", 6);
      localStorage.setItem("fulfillment_mode", 1);
      // alert("isHome")
      let cart = localStorage.getItem('cart_items') ? JSON.parse(localStorage.getItem('cart_items')) : []
      let eats_count = localStorage.getItem('eats_cart_count') ? JSON.parse(localStorage.getItem('eats_cart_count')) : []

      if (cart?.length > 0 || parseInt(eats_count) > 0) {
        let clearCartConfirmation = await Swal.fire({
          html: getSwalContent('Your cart will be reset if you switch to a different address. Continue?'),
          confirmButtonText: 'YES',
          showCancelButton: true,
          cancelButtonText: 'CANCEL',
          customClass: {
            confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
            cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
          }
        })

        if (clearCartConfirmation.isDismissed) {
          return
        }
      }

      $("#fulfillmenttype_modal").modal("show");

      triggerSerinoEvent(
        window.serino_components.FulfillmentTypeComponent.subscriptions
          .onFulfillmentOpen,
        true
      )

      // triggerSerinoEvent(
      //   window.serino_components.FulfillmentTypeComponent.subscriptions
      //     .onBrandUpdate,
      //   {
      //     brand_id: process.env.REACT_APP_MARKET_APP_ID,
      //     app_key: process.env.REACT_APP_MARKET_APP_ID,
      //     classNames: "srn-ff-market-new",
      //     // logo: <img src={topLogo} />
      //   }
      // );
      let main_address = localStorage.getItem('main_address')
      let default_address = localStorage.getItem('default_address')

      if (main_address) {
        this.handleAddressChange(JSON.parse(main_address), 'home')
      }
      else if (default_address) {
        this.handleAddressChange(JSON.parse(default_address), 'home')
      }
    }
    else {

      let current_address = null;
      let default_address = localStorage.getItem('default_address')
      let main_address = localStorage.getItem('main_address')
      let market_address = localStorage.getItem(`address_object_${market_app_id}`) && localStorage.getItem(`address_object_${market_app_id}`).length > 2 ? JSON.parse(localStorage.getItem(`address_object_${market_app_id}`)) : null
      let eats_address = localStorage.getItem(`address_object_${eats_app_id}`) && localStorage.getItem(`address_object_${eats_app_id}`).length > 2 ? JSON.parse(localStorage.getItem(`address_object_${eats_app_id}`)) : null


      let page = window.location.pathname

      if (page.includes('market')) {
        current_address = market_address ? market_address[0] : null

        if (localStorage.getItem("market_cart_count") > 0) {
          let clearCartConfirmation = await Swal.fire({
            html: getSwalContent('Your cart will be reset if you switch to a different address. Continue?'),
            confirmButtonText: 'YES',
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            customClass: {
              confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
              cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
            }
          })

          if (clearCartConfirmation.isDismissed) {
            return
          }
        }

        $("#fulfillmenttype_modal").modal("show");

        triggerSerinoEvent(
          window.serino_components.FulfillmentTypeComponent.subscriptions
            .onFulfillmentOpen,
          true
        )

      }

      if (page.includes('eats-products')) {

        let eats_cart_items = sessionStorage.getItem('eats_cart_items') ? JSON.parse(sessionStorage.getItem('eats_cart_items')) : []

        if (eats_cart_items.length > 0) {
          let clearCartConfirmation = await Swal.fire({
            html: getSwalContent('Your cart will be reset if you switch to a different address. Continue?'),
            confirmButtonText: 'YES',
            showCancelButton: true,
            cancelButtonText: 'CANCEL',
            customClass: {
              confirmButton: 'cmb-btn-primary-swal-checkoutconfirm',
              cancelButton: 'cmb-btn-primary-swal-checkoutcancel'
            }
          })

          if (clearCartConfirmation.isDismissed) {
            return
          }
        }

        $("#fulfillmenttype_modal").modal("show");

        triggerSerinoEvent(
          window.serino_components.FulfillmentTypeComponent.subscriptions
            .onFulfillmentOpen,
          true
        )

        current_address = eats_address ? eats_address.find(x => x.brand_id == localStorage.getItem('brand_id')) : null
      }

      if (current_address) {
        this.handleAddressChange(current_address, 'brand')
      }
      else if (main_address) {
        this.handleAddressChange(JSON.parse(main_address), 'home')
      }
      else if (default_address) {
        this.handleAddressChange(JSON.parse(default_address), 'home')
      }
    }

    if (window.location.pathname == '/eats') {
      localStorage.setItem("fulfillment_mode", 1);
      localStorage.setItem("isPickupDisabled", 1);
    }
  };

  isProductPage =
    window.location.pathname.slice(1) === "products" &&
    !window.location.search.includes("search");
  isEats = window.location.pathname.includes("eats-products");

  toggleSearch = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive }, () => {
      if (this.state.isSearchActive === true) {
        setTimeout(() => {
          if (document.getElementById("srn-product-search-input")) {
            document.getElementById("srn-product-search-input").focus();
          }
        }, 300);
      }
    });
  };

  handleAddressChange = async (address, type) => {
    log('handleAddressChange', address)
    triggerSerinoEvent(
      window.serino_components.FulfillmentTypeComponent.subscriptions
        .onAddressFill,
      {
        address: address ? address : null,
        type: type
      }
    )
  }


  handleSearchDebonce = debounce(() => {
    this.handleSearchTrigger()
  }, 1000)

  handleSearchTrigger = () => {
    fbqEvent('Search')
  }


  render() {
    const isMwebAndisClient =
      window.screen.width < 600 && this.state.user_role == "Client"
        ? "my-orders"
        : "track-order";
    const eatsProducts = [
      "popeyes",
      "kuya-j",
      "hungry-nomad-kitchen",
      "landers-central",
      "dough-and-co.",
      "da-gianni-cucina-italiana",
      "majestic",
      "trattoria-da-gianni",
    ];
    const url = window.location.search.slice(1).toLowerCase();
    const isProductEatsPage =
      eatsProducts.find((e) => e === url) &&
      window.location.pathname.includes("eats-products");

    const addressStyle = isProductEatsPage
      ? {
        // display: "flex",
        // justifyContent: "center",
        // width: "100%",
        // paddingRight: "12%",
      }
      : {};
    const ulStyle = isProductEatsPage
      ? {
        // paddingLeft: "5%",
      }
      : {};
    const searchPadding = isProductEatsPage
      ? {
        paddingLeft: "1.5rem 0 0 1rem",
      }
      : {};
    const showLogo = isProductEatsPage ? (
      <a
        className=""
        href="#!"
        onClick={() => handlePageRedirectApp("eats")}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={this.isEats ? eatsProductLogo : marketsLogo}
          alt=""
          className="cmb-brand-eats-product"
          height="15px"
          onClick={() => handlePageRedirectApp("eats")}
        />
      </a>
    ) : (
      <a
        className="navbar-brand cmb-navbar mx-3"
        href="#!"
        onClick={() => this.handleSelectApp(0)}
      >
        {this.state.trigger == "market" && (
          <img src={marketsLogo} alt="" className="cmb-brand-market" />
        )}
        {this.state.trigger == "eats" && (
          <img src={eatsLogo} alt="" className="cmb-brand-eats" />
        )}
        {this.state.trigger != "market" && this.state.trigger != "eats" ? (
          <img src={CentralLogo} alt="" className="cmb-brand" />
        ) : null}
      </a>
    );

    // const curretLogo = window.location.search.slice(1);
    // const toggleZoomScreen = () => {
    //   document.body.style.zoom = curretLogo ? "80%" : "100%";
    // };
    // toggleZoomScreen();
    return (
      <React.Fragment>
        <nav
          style={{
            marginTop: isProductEatsPage ? "0" : "40px",
            paddingLeft: isProductEatsPage && this.state.delayWarning?.status !== 1 ? "2rem" : "0",
          }}
          className={`navbar sticky-top navbar-expand cmb-header fixed-navbar ${isProductEatsPage ? "app-eats-product" : ""}`}
        >
          <div className="borderTop"></div>
          <div className={`srn-header-container ${isProductEatsPage ? "header-eats-prod" : ""}`} id="srn-header-container">
            <div className="d-flex align-items-center w-100 cmb-navbar-container">

              <div className="d-flex">
                <img alt="logo" src={centralImg} className="central-logo" style={{ marginLeft: isProductEatsPage ? "80px" : "", }} onClick={() => handlePageRedirectApp("")} />
              </div>

              <ul className="navbar-nav mr-2 cmb-menu" id="headerContainer">
                <li className="nav-item search-header">
                  <div className="srn-header-search-container">
                    <div id="srn-search-product-list-container-header"></div>
                  </div>
                </li>
              </ul>
              <ul
                style={ulStyle}
                className="navbar-nav mr-2 cmb-menu ml-auto  header-navigation-container"
                id="headerContainer"
              >
                {this.state.pathName !== "/checkout" ? (
                  <React.Fragment>
                    <div id="srn-app-selector-container"></div>
                    <li className="nav-item">
                      <a className={this.state.pathName === "/track-order" ? "active" : ""} onClick={() =>
                        this.state.TrackorderActive != false ? this.handlePageRedirect("my-orders") : this.handlePageRedirect("track-order")} href="#!" >
                        Track order<span className={`track-order-notif ${this.state.TrackorderActive != false ? "" : "d-none"}`}>&nbsp;</span>
                      </a>
                    </li>
                    <li className="nav-item srn-header-location-picker-container">
                      <div className="srn-header-location-picker" id="srn-header-location-picker" onClick={() => this.handleMainAddress()}>
                        {this.state.domain == "main" ? (
                          <Fragment>
                            <p id="srn-main-fulfillment" className={`srn-header-location-picker-fulfillment ${this.state.main_address &&
                              Object.keys(this.state.main_address).length > 2 ? "deliver-to" : "default-deliver-to"}`}>
                              {this.state.main_address &&
                                this.state.main_address.length > 2 ? (
                                <Fragment>
                                  {this.state.main_address.fulfillmentType}
                                </Fragment>
                              ) : (
                                "Deliver to"
                              )}
                            </p>
                            <p id="srn-main-location2" className={`srn-header-location-picker-placeholder main-address ${this.state.main_address &&
                              Object.keys(this.state.main_address).length > 2 ? "placeholder-with-address" : "placeholder-no-address"}`}
                              title={
                                this.state.main_address &&
                                  Object.keys(this.state.main_address).length > 2
                                  ? this.state.main_address.address
                                  :
                                  this.state.default_address &&
                                    Object.keys(this.state.default_address).length > 2
                                    ? this.state.default_address.address
                                    : ""
                              }
                            >
                              {
                                this.state.main_address &&
                                  Object.keys(this.state.main_address).length > 2 ? (
                                  <Fragment>
                                    {this.state.main_address.address}
                                  </Fragment>

                                ) :
                                  this.state.default_address &&
                                    Object.keys(this.state.default_address).length > 2 ? (
                                    <Fragment>
                                      {this.state.default_address.address}
                                    </Fragment>
                                  ) : (
                                    ""
                                  )}
                            </p>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <p
                              id="srn-main-fulfillment2"
                              className="srn-header-location-picker-fulfillment"
                            >
                              {localStorage.getItem("fulfillment_type_status") === "3" ? "Deliver to"
                                :
                                localStorage.getItem("fulfillment_type_status") === "2" ? "Pickup from" : "Delivery to"}
                            </p>
                            {/* <div className="srn-header-location-picker-divider">
                              <FontAwesomeIcon icon={faAngleRight} />
                            </div> */}
                            <p
                              id="srn-main-location"
                              className="srn-header-location-picker-placeholder localStorage-fulfillment"
                              title={
                                localStorage.getItem("Address") &&
                                  localStorage.getItem("Address") !== "undefined"
                                  ? localStorage.getItem("Address")
                                  : ""
                              }
                            >
                              {localStorage.getItem("Address") &&
                                localStorage.getItem("Address") !== "undefined" ? (
                                <Fragment>
                                  {localStorage.getItem("Address")}
                                </Fragment>
                              ) : (
                                "enter location here"
                              )}
                            </p>
                          </Fragment>
                        )}
                      </div>
                    </li>
                    {this.state.isLoggedIn == true ||
                      this.state.user_role == "Client" || localStorage.getItem("isLoggedIn") == true ? (
                      <React.Fragment>
                        <li className="nav-item nav-user" dataid={this.state.user_role}>
                          <a id="nav-myprofile" className={this.state.pathName === "/profile" ? "active" : ""} onClick={() => this.myProfileLink()} href="#!">
                            <p class="srn-header-location-picker-fulfillment deliver-to">Hello,</p>
                            <p className="srn-header-location-picker-placeholder main-address">
                              {this.state.userFirstName} {this.state.userLastName}
                              {/* <i class="fa fa-chevron-down down-icon"></i> */}
                            </p>
                          </a>
                          <ul className="profile-dropdown" id="profile-drop">
                            <li onClick={() => window.location = "/my-orders"}>My Orders</li>
                            {this.state.profile_link.map((profile, i) => {
                              return (
                                <li onClick={() => this.profileLink(i)}>
                                  {profile.name}
                                </li>
                              );
                            })}
                            <li
                              onClick={() => this.onLogout()}
                              className="sign-out"
                            >
                              Sign Out
                            </li>
                          </ul>
                        </li>
                      </React.Fragment>
                    ) : this.state.isLoading ? null : (
                      <li className="nav-item side-nav-item nav-orders">
                        <a
                          id="nav-login"
                          className={
                            this.state.pathName === "/login" ? "active" : ""
                          }
                          href="#!"
                          onClick={() => this.handlePageRedirect("login")}
                        >
                          login / signup
                          <FontAwesomeIcon
                            // icon={faChevronDown}
                            style={{ marginLeft: ".5rem" }}
                          />
                        </a>
                      </li>
                    )}

                    {/* {
                      this.state.isLoggedIn == true ||this.state.user_role == "Client" ? (
                        <li className="nav-item nav-orders">
                          <a className={this.state.pathName === "/track-order" ? "active" : ""} onClick={() => this.handlePageRedirect("my-orders")} href="#!">
                            My orders 
                          </a>
                        </li>
                      ) : ( */}

                    {/* // )} */}

                    <li className="nav-item header-cart">
                      <a
                        className={
                          this.state.pathName === "/cart" ? "active" : ""
                        }
                        onClick={() => this.handlePageRedirect("cart")}
                        href="#!"
                      >
                        <img src={shoppingBasket} /><span className="srn-cart-quantity"></span>
                      </a>
                    </li>
                  </React.Fragment>
                ) : (
                  <li className="nav-item header-cart">
                    <div id="srn-app-selector-container d-none"></div>
                    <a
                      className={
                        this.state.pathName === "/cart" ? "active" : ""
                      }
                      onClick={() => this.handlePageRedirect("cart")}
                      href="#!"
                    >
                      back
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>

          {/* Delay Warning message - WEB */}
          <div className={`dw-container ${this.state.delayWarning?.status === 1 ? '' : 'd-none' }`}>
            <div className="dw-content">
              <p className="dw-message" dangerouslySetInnerHTML={{ __html: this.state.delayWarning?.message || ''}}></p>
            </div>
          </div>
        </nav>

        {/* MWEB --------------------------------------------------------------------------------------------------------------------------------------------*/}
        <nav className={`navbar fixed-top navbar-expand cmb-header side-navbar app-${this.state.trigger} mweb-header`} >
          <div className="borderTop"></div>
          <div className="container pt-0">
            <div className="d-flex align-items-center justify-content-start w-100 cmb-navbar-container">
              <a
                href="#"
                className="cmb-menu nav-burger-menu"
                onClick={() => this.openSideNav()}
              >
                <span className={`track-order-notif ${this.state.TrackorderActive != false ? "" : "d-none"}`}>&nbsp;</span>
                <FontAwesomeIcon icon={faBars} />
              </a>
              {
                this.state.pathName !== "/checkout" ?
                  <Fragment>
                    <ul
                      style={addressStyle}
                      className="navbar-nav mr-2 cmb-menu"
                      id="headerContainer"
                    >
                      <li className="nav-item srn-header-location-picker-container">

                        {
                          this.state.isSearchActive === false && this.state.pathName !== "/cart" ?
                            <div
                              id="srn-main-location-2"
                              className="srn-header-location-picker"
                              onClick={() => this.handleMainAddress()}
                            >
                              <div className="srn-header-location-picker-icon">
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                              </div>
                              {this.state.domain == "main" ? (
                                <Fragment>
                                  {
                                    this.state.main_address && this.state.main_address.address.length > 2 ?
                                      <p id="srn-main-fulfillment" className={`srn-header-location-picker-fulfillment`}>
                                        {
                                          this.state.main_address && this.state.main_address.address.length > 2 ? (
                                            <Fragment>
                                              {this.state.main_address.address}
                                            </Fragment>
                                          ) : (
                                            "Where are you located?"
                                          )}
                                      </p>
                                      :
                                      <p
                                        id="srn-main-location"
                                        className={`srn-header-location-picker-placeholder main-address`}
                                        title={this.state.main_address && this.state.main_address.length > 2 ? this.state.main_address.address : "Where are you located?"
                                        }
                                      >
                                        {this.state.main_address &&
                                          this.state.main_address.length > 2 ? (
                                          <Fragment>
                                            {this.state.main_address.address}
                                          </Fragment>
                                        ) : (
                                          "Where are you located?"
                                        )}
                                      </p>
                                  }
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <p
                                    id="srn-main-fulfillment"
                                    className="srn-header-location-picker-fulfillment d-none"
                                  >
                                    {localStorage.getItem("fulfillment_type_status") ===
                                      "3"
                                      ? "Deliver Later"
                                      : localStorage.getItem(
                                        "fulfillment_type_status"
                                      ) === "2"
                                        ? "Pickup"
                                        : "Delivery"}
                                  </p>
                                  {/* <div className="srn-header-location-picker-divider">
                              <FontAwesomeIcon icon={faAngleRight} />
                            </div> */}
                                  <p
                                    id="srn-main-location2"
                                    className="srn-header-location-picker-placeholder localStorage-fulfillment"
                                    title={
                                      localStorage.getItem("Address") &&
                                        localStorage.getItem("Address") !== "undefined"
                                        ? localStorage.getItem("Address")
                                        : ""
                                    }
                                  >
                                    {localStorage.getItem("Address") &&
                                      localStorage.getItem("Address") !== "undefined" ? (
                                      <Fragment>
                                        {localStorage.getItem("Address")}
                                      </Fragment>
                                    ) : (
                                      "Where are you located?"
                                    )}
                                  </p>
                                </Fragment>
                              )}
                            </div> : null
                        }
                      </li>
                    </ul>
                    <img alt="logo" src={centralImg} className="central-logo" onClick={() => handlePageRedirectApp("")} />
                  </Fragment>
                  :
                  <ul className="navbar-nav cmb-menu">
                    <li className="cmb-menu-side">
                      <a className="back-button" onClick={() => this.handlePageRedirect("cart")} href="#!">Back</a>
                    </li>
                  </ul>
              }

            </div>

            <div className="srn-header-search-mweb" onClick={() => this.handleSearchModal()}>
              <li className="nav-item li-search ">
                <div className="srn-header-search-container">
                  <div id="srn-search-product-list-container-header-mobile"></div>
                  <div className="srn-header-search-icon-container" >
                  </div>
                </div>
              </li>
            </div>
          </div>
          
          {/* Delay Warning message - MWEB */}
          <div className={`dw-container ${this.state.delayWarning?.status === 1 ? '' : 'd-none' }`}>
            <div className="dw-content">
              <p className="dw-message" dangerouslySetInnerHTML={{ __html: this.state.delayWarning?.message || ''}}></p>
            </div>
          </div>
        </nav>

        <div id="sideNav" className="sidenav side-navbar">
          <a>
            <div className="header-logo">
              <span aria-hidden="true" onClick={() => this.closeSideNav()}>&times;</span>
              <h3>CENTRAL</h3>
              <img src={sidenavbg} />
            </div>
          </a>
          <ul className="navbar-nav cmb-menu srn-side-menu">
            {this.state.pathName !== "/checkout-disabled" ? (
              <React.Fragment>
                {/* <div
                  id="srn-app-selector-container-side"
                  className="srn-app-selector-container-side"
                ></div> */}

                <li className="nav-item ">
                  <a className="nav-item" onClick={() => this.handleSelectApp(0)} href="#!">Home</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-item" href="/eats">Central Eats</a>
                </li>
                <li className="nav-item ">
                  <a className="nav-item" href="/market">Central Market</a>
                </li>
                <li className="nav-item sidenav-trackorder">
                  {/* this.state.pathName === "/track-order" ? "active" : "" */}
                  <a className={this.state.isLoggedIn == true || this.state.user_role == "Client" ? this.state.TrackorderActive != false ? "" : "d-none" : ""} onClick={() => window.location = "/track-order"} href="#!">
                    Track Order
                  </a>
                </li>
                {this.state.isLoggedIn == true ||
                  this.state.user_role == "Client" ? (
                  <React.Fragment>
                    <li
                      className="nav-item myprofile"
                      dataid={this.state.user_role}
                      onClick={() => this.showMyprofile()}
                    >
                      <a
                        id="nav-myprofile"
                        className={
                          this.state.pathName === "/profile" ? "active" : ""
                        }
                        href="#!"
                      >
                        my profile<i class="fa fa-chevron-down down-icon"></i>
                      </a>
                    </li>
                    <li className={`nav-item mweb-profile ${this.state.showProfile == true ? "" : "d-none"}`}>
                      <ul className="profile-dropdown" id="profile-drop">
                        <li onClick={() => window.location = "/my-orders"}>My Orders</li>
                        {this.state.profile_link.map((profile, i) => {
                          return (
                            <li onClick={() => this.profileLink(i)}>
                              {profile.name}
                            </li>
                          );
                        })}
                        <li
                          onClick={() => this.onLogout()}
                          className="sign-out"
                        >
                          Sign Out
                        </li>
                      </ul>
                    </li>
                  </React.Fragment>
                ) : this.state.isLoading ? null : (
                  <li className="nav-item side-nav-item">
                    <a
                      id="nav-login"
                      className={
                        this.state.pathName === "/login" ? "active" : ""
                      }
                      href="#!"
                      onClick={() => this.handlePageRedirect("login")}
                    >
                      Login Or Signup
                    </a>
                  </li>
                )}
              </React.Fragment>
            ) : (
              <li className="nav-item side-nav-item">
                <a
                  className={
                    this.state.pathName === "/checkout" ? "active" : ""
                  }
                  onClick={() => this.handlePageRedirect("checkout")}
                  href="#!"
                >
                  Checkout
                </a>
              </li>
            )}
            <li className="nav-item side-nav-item" onClick={() => this.handlePageRedirect("cart")}>
              <a>Cart(<span className="srn-cart-quantity"></span>)</a>
            </li>
          </ul>

          <div className="mweb-side-nav-footer">
            <div className="mweb-side-nav-footer-container">
              <div className="mweb-side-nav-footer-content d-none">
                <a href="/eats">Central Eats</a>
                <div className="social-media">
                  <img src={facebook} onClick={() => window.open('https://www.facebook.com/bycentraleatsph', "_blank")} />
                  <img src={instagram} onClick={() => window.open('https://www.instagram.com/bycentraleatsph', "_blank")} />
                </div>
              </div>

              <div className="mweb-side-nav-footer-content">
                <a href="/market">Follow Us</a>
                <div className="social-media">
                  <img src={facebook} onClick={() => window.open('https://www.facebook.com/bycentral.ph/ ', "_blank")} />
                  <img src={instagram} onClick={() => window.open('https://www.instagram.com/bycentral.ph/', "_blank")} />
                </div>
              </div>
            </div>
            <div className="mweb-right-reserved">
              <span>© 2022 Central by Landers</span>
            </div>
          </div>

        </div>

        <div
          id="side-menu-backdrop"
          className="side-menu-backdrop side-menu-backdrop-transition"
          onClick={() => this.closeSideNav()}
        ></div>
        <SearchComponent
          id="srn-search-product-list-container-header-mobile2"
          type="2"
          toggleSearch={this.toggleSearch}
        />
      </React.Fragment>
    );
  }
}
