import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { uuid } from "uuidv4";
//image
import KuyaJLogo from "./assets/images/KuyaJ2x.png";
import PopeyesLogo from "./assets/images/popeyes2x.png";
import Footer from "./components/footer/index";
import Header from "./components/header/index";
import AboutUs from "./components/pages/about-us";
import Cart from "./components/pages/cart";
import Checkout from "./components/pages/checkout";
import SuccessPage from "./components/pages/order-success";
import ContactUs from "./components/pages/contact-us";
// import EatsFooter from "./components/pages/eats/eats-footer";
import EatsFooter from "./components/pages/eats/eats-footer";
import EatsLandingPage from "./components/pages/eats/eats-landing-page";
import EatsProductPage from "./components/pages/eats/eats-product-page";
import LinkRewardCard from "./components/pages/link-reward-cards";
import Login from "./components/pages/login";
// pages
import MainLandingPage from "./components/pages/main-landing-page";
import MarketFooter from "./components/pages/market/market-footer";
import MarketLandingPage from "./components/pages/market/market-landing-page";
import MarketProductPage from "./components/pages/market/market-product-page";
import LandersMembership from "./components/pages/membership";
import MyOrders from "./components/pages/my-orders";
import PrivacyPolicy from "./components/pages/privacy-policy";
import Profile from "./components/pages/profile";
import TermsAndCondition from "./components/pages/terms-&-condition";
import TrackOrder from "./components/pages/track-order";
import LoginApi from "./_core/login";
import PromoMechanicsAndGeneralInfo from "./components/pages/promo-mechanics-and-general-info";

var login = new LoginApi();

const x_app_key = process.env.REACT_APP_X_APP_KEY;

const host = window.location.host;
const parts = host.split(".");

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: localStorage.getItem("isLoggedIn")
        ? localStorage.getItem("isLoggedIn")
        : false,
      client: {
        first_name: "",
        last_name: "",
        email: "",
        // address: localStorage.getItem('main_address') ? JSON.parse(localStorage.getItem('main_address')) : null
      },
      user: {},
    };
  }

  componentDidMount() {
    // Detect if you're in private browser
    // detect_private_browsing()

    // Delete bad token
    if (!localStorage.getItem("clearbadtoken19")) {
      localStorage.clear();
      localStorage.setItem("clearbadtoken19", 1);
      window.location = window.location.href;
    }

    if (!localStorage.getItem("SRN-CART-ID")) {
      let cart_id = uuid();
      localStorage.setItem("SRN-CART-ID", cart_id);
    }

    // Delete null addresses
    if (
      localStorage.getItem(
        `address_object_${process.env.REACT_APP_MARKET_APP_ID}`
      ) &&
      localStorage.getItem(
        `address_object_${process.env.REACT_APP_MARKET_APP_ID}`
      ) === "null"
    ) {
      localStorage.removeItem(
        `address_object_${process.env.REACT_APP_MARKET_APP_ID}`
      );
    }
    if (
      localStorage.getItem(
        `address_object_${process.env.REACT_APP_EATS_APP_ID}`
      ) &&
      localStorage.getItem(
        `address_object_${process.env.REACT_APP_EATS_APP_ID}`
      ) === "null"
    ) {
      localStorage.removeItem(
        `address_object_${process.env.REACT_APP_EATS_APP_ID}`
      );
    }
  }

  // handleAddressChange = () => {
  //   this.setState({
  //     address: localStorage.getItem('main_address')
  //   })
  // }

  // handlePageRedirect = (page) => {
  //   this.setState({
  //     page: page
  //   })
  // }

  render() {
    return (
      <div className="d-flex w-100 ">
        <Router>
          <div className="App cmb-responsive d-flex flex-column w-100">
            <Header
            // main_address={this.state.address}
            />
            <div className="flex-fill d-flex flex-column cmb-container">
              <React.Fragment>
                <div className="flex-fill" id="flex-fill">
                  <Route
                    exact
                    path="/"
                    component={(props) => (
                      <MainLandingPage
                        // onLocationChange={this.handleAddressChange}
                        {...props}
                        client={this.state.client}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/membership"
                    component={(props) =>
                      process.env.REACT_APP_MEMBERSHIP_FLAG === "1" ? (
                        <LandersMembership {...props} />
                      ) : (
                        <Redirect to="/" />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/track-order"
                    component={(props) => <TrackOrder {...props} />}
                  />

                  <Route
                    exact
                    path="/my-orders"
                    component={(props) => <MyOrders {...props} />}
                  />

                  <Route
                    exact
                    path="/cart"
                    component={(props) => <Cart {...props} />}
                  />

                  <Route
                    exact
                    path="/checkout"
                    component={(props) => <Checkout {...props}
                    handlePageRedirect={this.handlePageRedirect} />}
                  />

                  <Route
                    exact
                    path="/order-success"
                    component={(props) => <SuccessPage {...props}
                    handlePageRedirect={this.handlePageRedirect} />}
                  />

                  <Route
                    exact
                    path="/terms-conditions"
                    component={(props) => <TermsAndCondition {...props} />}
                  />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={(props) => <PrivacyPolicy {...props} />}
                  />
                  <Route
                    exact
                    path="/about-us"
                    component={(props) => <AboutUs {...props} />}
                  />
                  <Route
                    exact
                    path="/contact-us"
                    component={(props) => <ContactUs {...props} />}
                  />
                  <Route
                    exact
                    path="/market"
                    component={(props) => <MarketLandingPage {...props} />}
                  />
                  <Route
                    exact
                    path="/eats"
                    component={(props) => <EatsLandingPage {...props} />}
                  />
                  <Route
                    exact
                    path="/market-products"
                    component={(props) => <MarketProductPage {...props} />}
                  />
                  <Route
                    exact
                    path="/eats-products"
                    component={(props) => <EatsProductPage {...props} />}
                  />
                  <Route
                    exact
                    path="/login"
                    component={(props) =>
                      this.state.isLoggedIn === true ||
                      this.state.isLoggedIn === "true" ? (
                        <Redirect to="/" />
                      ) : (
                        <Login {...props} />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/profile"
                    component={(props) =>
                      this.state.isLoggedIn === true ||
                      this.state.isLoggedIn === "true" ? (
                        <Profile {...props} client={this.state.client} />
                      ) : (
                        <Redirect to="/login" />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/link-reward-card"
                    component={(props) =>
                      this.state.isLoggedIn === true ||
                      this.state.isLoggedIn === "true" ? (
                        <LinkRewardCard {...props} client={this.state.client} />
                      ) : (
                        <Redirect to="/" />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/search"
                    component={(props) => (
                      <MainLandingPage
                        {...props}
                        client={this.state.client}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/promo-mechanics-and-general-info"
                    component={(props) => <PromoMechanicsAndGeneralInfo {...props} />}
                  />
                </div>
                {(parts.length === 3 && parts[0] === "eats") ||
                parts[0] === "qasrn7-eats" ||
                parts[0] === "devsrn7-eats" ||
                parts[0] === "eats" ? (
                  <Footer />
                ) : (parts.length === 3 && parts[0] === "market") ||
                  parts[0] === "qasrn7-market" ||
                  parts[0] === "devsrn7-market" ||
                  parts[0] === "market" ? (
                  <Footer />
                ) : (
                  <Footer />
                )}
              </React.Fragment>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}
