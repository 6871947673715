import { headerActions } from '../../components/common/header/redux/actions'

const MOUNT_APP = '[EVENT][APP] MOUNT APP'
const mountApp = ({ host }) => {
    return {
        type: MOUNT_APP,
        payload: {
            host
        }
    }
}

const REDIRECT = '[COMMAND][APP] REDIRECT'
const redirect = ({ to }) => {
    return {
        type: REDIRECT,
        payload: {
            to
        }
    }
}

const UPDATE = 'UPDATE'
const update = () => (dispatch) => {
  dispatch({ type: UPDATE, payload: null });
};


export const appActionTypes = {
    MOUNT_APP,
    REDIRECT,
    UPDATE
}

export const appActions = {
    mountApp,
    redirect,
    update
}

export const actions = {
    ...headerActions,
    ...appActions
}
