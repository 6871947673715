import { headerReducers } from '../../components/common/header/redux/reducers'

const initialState = {
    cart_items: []
}

const cartReducer = (state = initialState, action) => {
    switch(action.type) {
        case ('UPDATE'):
            return {
                ...state,
                cart_items: action.data
            }
            break;
        default:
            return state
            break
    }
};

const cart = {
    cart: cartReducer
}

export const reducers = {
    ...headerReducers,
    ...cart
}