
export const ModalComponent = (props) => (
    <div className={`modal fade srn-ft-modal-container ${props.className}`} id={props.id} role="dialog" aria-labelledby="" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content srn-ft-main-container">
                <span className="d-none" data-dismiss="modal">check</span>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-body srn-modal-body-comp">
                    {props.children}
                </div>
            </div>
        </div>
    </div>
)