import { Component } from "react";
import ParallaxComponent from '../partials/ParallaxComponent';

export default class TermsAndCondition extends Component {

    render() {
        return (
            <div className="container-fluid" style={{ marginTop: "3rem", paddingLeft: "0" }}>
                <ParallaxComponent page="generic" />
                <div className="termscondition-container">
                    <div className="col-12">
                        <h3 className="header-terms py-3 text-center">Terms and Conditions</h3>
                    </div>
                    <div className="terms-condition-content">
                        <div className="col-12">
                            <p>Welcome to Central by Landers ("the Site"). These Terms and Conditions lay down your legal rights and obligations with respect to your use of this Site and your account with Central by Landers, so please read the following carefully before using this site and/or opening a Central by Landers account. By accessing the Site and/or using the Services, you agree to be bound by these Terms of Use.</p>
                        </div>
                        <div className="col-12">
                            <p>You may use the Services only if you are above eighteen (18) years of age and can form legally binding contracts under Philippine Laws.</p>
                        </div>
                        <div className="col-12">
                            <strong><p className="mb-0 pb-0">I. About this Website<br /><br /></p></strong>
                            <p className="mt-0 pt-0">The use of this Site are each subject to this Terms and Conditions.</p>
                            <p>To use the Site and to make any Purchase, you must be 18 years of age or over.</p>
                            <p>The Site is for your non-commercial and personal use only and must not be used for business purposes. For the avoidance of doubt, scraping of the Site (and hacking of Site) is not allowed.</p>
                            <p>We reserve the right to prevent you from using the Site and its service (or any part of them) and to prevent you from making any purchase.</p>
                            <p>The use of the Site and the making of any purchase does not include the provision of a computer or other necessary equipment to access the Site or the service or making of any Purchase. To use the Site or service or to make a Purchase, you will require internet connectivity and appropriate telecommunication links. We shall not be liable for any telephone, telecommunications, or other costs that you may incur in the process.</p>
                            <p>We will use reasonable endeavors to correct any errors or omissions as soon as practicable after being notified of them. However, we do not guarantee that the Service or the Website will be free of faults (or Vouchers will be free of error) and we do not accept liability for any errors or omissions.</p>
                            <p>In the event of an error or fault, you should report it by email to: <b>support@central.ph</b></p>
                            <p>We do not warrant that your use of the Service or the Website will be uninterrupted and we do not warrant that any information (or messages) transmitted via the Service or the Website will be transmitted accurately, reliably, in a timely manner or at all</p>
                            <p>We do not give any warranty that the Service or the Website is free from viruses or anything else which may have a harmful effect on any technology.</p>
                            <p>Also, although we will try to allow uninterrupted access to the Service and the Website, access to the Service and the Website may be suspended, restricted or terminated at any time.</p>
                            <p>We reserve the right to change, modify, substitute, suspend or remove without notice any information or service on the Site or forming part of the Service from time to time. Your access to the Site and/or the Service may also be occasionally restricted to allow for repairs, maintenance, or the introduction of new facilities or services. We will attempt to restore such access as soon as we reasonably can.</p>
                            <p>We assume no responsibility for functionality which is dependent on your browser or other third party software to operate (including, without limitation, RSS feeds). For the avoidance of doubt, we may also withdraw any information from the Site or Service at any time.</p>
                            <p>We reserve the right to block access to and/or to edit or remove any material which in our reasonable opinion may give rise to a breach of any of this Terms and Conditions.</p>
                        </div>
                        <div className="col-12 p_spacing">
                            <strong><p>II. Definition of Terms</p></strong><br />

                            <p>Buyer refers to any member who has made a purchase in the Site.</p>
                            <p>Intellectual Property refers to all copyright, trademark, trade name, patent, and other proprietary rights of Landers that may be found in the Site.</p>
                            <p>We, Our, Us refer to Central by Landers delivery platform.</p>
                            <p>Member refers to a person who has registered and created a Central by Landers account in this site.</p>
                            <p>Password refers to the valid password that a Member may use together with the Username to access the Site.</p>
                            <p>Personal data means data provided by the Member in the Site that may be used to identify him/her.</p>
                            <p>Product means any product available for sale at the Site.</p>
                            <p>Rider or Delivery Personne refers to the authorized personnel delivering the items and also authorized to collect payment for the goods delivered.</p>
                            <p>Servicesmeans the services made available at the Site.</p>
                            <p>Site refers to the online delivery platform, Central by Landers.</p>
                            <p>User, You, Your refer to the person who is using this Site.</p>
                            <p>Guestis a user who opts to order without creating a Central by Landers account.</p>
                            <p>Partner Brand, Merchant, Vendor refer to the different brands fulfilling the orders in Central by Landers website.</p>
                            <p>Username refers to the unique login identification name which identifies a Member of the Site.</p>

                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong><p>III. Account Information</p></strong> <br />
                            {/* <ol type="1"> */}
                            <p>To access our Site, App, or Services, you will be encouraged to sign up for an account. To sign up for an account, you must elect your own unique username and password. You can also opt to order as GUEST.</p>
                            <p>You are solely responsible for safeguarding and maintaining the confidentiality of your password. You agree not to share or allow others to use your username and password. You may not authorize others to use your user account, and you may not assign or otherwise transfer your user account to any other person or entity. You must immediately notify us if any authorized third party becomes aware of that password or if there is any unauthorized use of your email address or any breach of security known to you. You agree that any person to whom its user name or password is disclosed is authorized to act as your agent for the purposes of using (and/or transacting via) the Site.</p>
                            <p>You are encouraged to change your password regularly in order to protect your account.</p>
                            <p>You shall be responsible for all communication and/or transactions made using your account and password. Central by Landers shall have no responsibility or liability for any loss, damage, cost, expenses, or liabilities arising as a result of or in connection with the wrongful or fraudulent use of your Account.</p>
                            <p>Please provide accurate and complete information in your Account.</p>
                            <p>We reserve the right to decline a new registration or to cancel an account at any time.</p>
                            <p>We reserve the right to close accounts if any user is seen to be using proxy IPs (Internet Protocol addresses) in order to attempt to hide the use of multiple registration accounts.</p>
                            <p>If you use multiple logins for the purpose of disrupting a community or annoying other users you may have action taken against all of your Accounts.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12">
                            <br /><strong><p>IV. Security and Privacy</p></strong> <br />
                            <p>Central by Landers recognizes the importance of keeping your personal information and data secure and private. To understand our security and privacy policies and practices, please read our <a className="privacy-link" href="/privacy-policy">Security & Privacy Policy.</a></p>
                        </div>
                        <div className="col-12 p_spacing">
                            <strong><p >V. Products</p> <br /></strong>
                            {/* <ol type="1"> */}
                            <p>We are making all possible efforts to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.</p>
                            <p>All descriptions of products are subject to change without notice, at our sole discretion.</p>
                            <p>Any offer for any product or Services made on this Site is void when it is prohibited by law.</p>
                            <p>Allergens – Central by Landers is not obligated to provide ingredient information or allergen information on the Site. Further, Central does not guarantee that the Products sold by Vendors are free of allergens. If you have allergies, allergic reactions or dietary restrictions and requirements, please contact the Vendor before placing an Order on our Site.</p>
                            <p>Special Instructions – Central by Landers and the Vendor (as the case may be) reasonably endeavor to comply with your special instructions for an Order. However, in some cases where this is not feasible, possible or commercially reasonable, Site and/or the Vendor reserve the right to proceed to prepare the Order in accordance with standard operating procedures. Neither Site nor the Vendor shall be responsible for replacing or refunding an Order that does not conform to special instructions provided by you.</p>
                            <p>Central by Landers reserves the right to: </p>

                            <p>Limit the sales of our products or Services to any person, geographic location or jurisdiction;</p>
                            <p>Limit the quantities of any products or Services that we offer; and</p>
                            <p>Discontinue any product at any time.</p>


                            {/* </ol> */}

                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong> <p >VI. Pricing</p> <br /> </strong>
                            {/* <ol type="1"> */}
                            <p>We endeavor to provide you with accurate and up-to-date pricing, product and promotional information. However, we do not guarantee that the pricing, images or other product or promotional information provided will be error-free, updated, or complete.</p>
                            <p>We shall not be liable for any errors in the pricing and product or promotional information listed on our Site or through our Services.</p>
                            <p>The price to be paid at checkout shall be based on our prevailing selling price at the time we receive your order, which may or may not be the same as reflected on the Site at any time thereafter due to price changes.</p>
                            <p>If the actual price of any item you ordered is higher than that reflected on the Site or through our Services, we will inform you and you will be given an option to confirm or cancel your purchase of such item based on the correct price. If we are unable to contact you, we will treat your order in respect of the incorrectly priced item as cancelled.</p>
                            <p>There will be minimum and maximum order amount per vendor/ merchant. Kindly confirm upon ordering.</p>
                            <p>For the availment of Senior Citizen or Person with Disability Discount, please contact <strong>support@central.ph</strong> for our Customer Service team to process your request and get back to you.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong> <p>VII. Payments</p> </strong><br />
                            <p>Payments of the orders shall be made in Philippine Peso. Central by Landers accepts the following payment methods;

                                <p>Cash on Delivery (COD) Cash payment is accepted. Buyers can pay the riders or delivery personnel upon receipt of the items.</p>
                                <p>Credit or debit card payments Credit/ debit card payments are processed through our partners and subject to the terms and conditions of the selected partners.
                                    While we do not store any of your credit card information, by agreeing to use this payment method, you authorize us to transmit information or to obtain
                                    information about you from third parties involving your debit or credit card number or credit reports in order to authenticate your identity, to validate
                                    your credit card, to obtain an initial credit card authorization, to protect you and us from fraud. We reserve the right to check whether Buyer is duly
                                    authorized to use certain payment method, and may suspend the transaction until such authorization is confirmed or cancel the relevant transaction where
                                    such confirmation is not available.</p>

                            </p>
                            <p>Central by Landers takes no responsibility and assume no liability for any loss or damages to Buyer arising from shipping information and/or payment information entered by Buyer in connection with the payment for the items purchased.</p>
                        </div>
                        <div className="col-12  p_spacing">
                            <br /><strong><p>VIII. Deliveries</p></strong> <br />

                            <p>
                                Delivery Areas At present, Central by Landers delivers to selected areas in Metro Manila. To find out if Central by Landers and all its partner brands deliver to your areas,
                                please input your delivery address upon ordering. Central by Landers will only receive and process orders within our delivery area coverage.
                            </p>
                            <p>
                                Delivery Time Frame

                                <p>Delivery slots are subject to availability.</p>
                                <p>Central by Landers will endeavor to deliver the products to your delivery address within the promised time frame but will NOT be liable for any delay caused by incorrect address and circumstances beyond our control such as but not limited to weather, traffic, transportation strike and other unforeseen event/s. Please refer to each brands’ delivery promised time upon ordering.</p>
                                <p>There will be maximum amount limit for each Central by Landers partner brand which will be confirmed upon ordering.</p>
                                <p>Exceeding orders will be considered bulk or volume order and will be treated separately by Central by Landers’ call center. Our agents will reach out to you via email or SMS to get your confirmation.</p>

                            </p>
                            <p>Delivery Address

                                <p>Central by Landers will endeavor to check the validity and accuracy of the delivery address registered in the site.</p>
                                <p>In case your delivery address is not accessible, we will contact you to determine the best alternative location and time to make sure you receive your order. Failure to take/accept our call will result to failed delivery and may entail additional delivery fee.</p>
                                <p>In case of change in your delivery address and contact information, please edit your account information before placing your order or checking out. Please note that change in delivery address is not possible once order has been verified.</p>

                            </p>

                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong> <p>IX. Cancellation, Returns & Refunds</p> </strong><br />
                            {/* <ol type="1"> */}
                            <p>Please contact us immediately via our hotline if you wish to cancel your Order after it has been placed. You have the right to cancel your Order provided a Vendor has not yet accepted your Order.</p>
                            <p>Refunds are the sole discretion of Central by Landers. Although an event may be cancelled or rescheduled, Central by Landers reserves the right to allow for consulting and coordinating when or if determining to allow for a cancelation or refund of any amount or for any reason.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong> <p>X. Feedback</p> </strong><br />
                            {/* <ol type="1"> */}
                            <p>You may provide or submit comments, feedback, suggestions and other content or information (collectively, "Comments") as long as the content is not illegal, criminal, immoral, inappropriate, hateful, obscene, defamatory, offensive, violent, abusive, political, religious, infringing or disrespectful in nature, and does not consist of or contain software viruses, solicitation or any form of "spam." You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a Comment.</p>
                            <p>We may, but are under no obligation to, monitor, edit or remove content that we determine, in our sole discretion, is unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party's intellectual property or these Terms of Use.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong> <p>XI. Your Obligations</p> </strong><br />
                            {/* <ol type="1"> */}
                            <p>In addition to other prohibitions as set forth in the Terms of Use, you are prohibited from using the Site, Services or Content:
                                {/* <ol type="a"> */}
                                <p>for any unlawful purpose;</p>
                                <p>to solicit others to perform or participate in any unlawful acts;</p>
                                <p>to violate any international or domestic regulations, rules, laws, or local ordinances;</p>
                                <p>to infringe upon or violate our intellectual property rights or the intellectual property rights of others;</p>
                                <p>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</p>
                                <p>to submit false or misleading information;</p>
                                <p>to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;</p>
                                <p>to collect or track the personal information of others;</p>
                                <p>to spam, phish, pharm, pretext, spider, crawl, or scrape;</p>
                                <p>for any obscene or immoral purpose;</p>
                                <p>to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</p>
                                <p>to send or receive any material which is not civil or tasteful;</p>
                                <p>to send or receive any material which is threatening, grossly offensive, of an indecent, obscene or menacing character, blasphemous or defamatory of any person, in contempt of court or in breach of confidence, copyright, rights of personality, publicity or privacy or any other third party rights;</p>
                                <p>to send or receive any material for which you have not obtained all necessary licences and/or approvals (from us or third parties); or which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party in any country in the world;</p>
                                <p>to send or receive any material which is technically harmful (including computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data);</p>
                                <p>to cause annoyance, inconvenience or needless anxiety; 6.4.6 to intercept or attempt to intercept any communications transmitted by way of a telecommunications system; 6.4.7 for a purpose other than which we have designed them or intended them to be used; 6.4.8 for any fraudulent purpose;</p>
                                <p>other than in conformance with accepted Internet practices and practices of any connected networks;</p>
                                <p>in any way which is calculated to incite hatred against any ethnic, religious or any other minority or is otherwise calculated to adversely affect any individual, group or entity.</p>
                                <p>furnishing false data including false names, addresses and contact details and fraudulent use of credit/debit card numbers;</p>
                                <p>attempting to circumvent our security or network including accessing data not intended for you, logging into a server or account you are not expressly authorized to access, or probing the security of other networks (such as running a port scan);</p>
                                <p>accessing the Service (or Website) in such a way as to, or commit any act that would or does, impose an unreasonable or disproportionately large load on our infrastructure;</p>
                                <p>executing any form of network monitoring which will intercept data not intended for you;</p>
                                <p>sending unsolicited mail messages, including the sending of "junk mail" or other advertising material to individuals who did not specifically request such material;</p>
                                <p>creating or forwarding "chain letters" or other "pyramid schemes" of any type, whether or not the recipient wishes to receive such mailings;</p>
                                <p>unauthorized use, or forging, of mail header information;or,</p>
                                <p>engage in any conduct which, in our exclusive reasonable opinion, restricts or inhibits any other customer from properly using or enjoying the Website and Service.</p>
                                {/* </ol> */}
                            </p>
                            <p>We reserve the right to terminate your use of the Site, Services, Content or any related website for violating any of the prohibited uses.</p>
                            <p>Merchants will have their own applicable terms and conditions, in relation to their own supply of their goods and services, and you agree to (and shall) abide by those terms and conditions. The responsibility to do so is yours alone.</p>
                            <p>You warrant that all information provided on Registration and contained as part of your account during the course of this Agreement is true, complete and accurate and that you will promptly inform us of any changes to such information by updating the details in your account.</p>
                            <p>It is your responsibility to ensure that any products, services or information available through the Site or the Service meet your specific requirements.</p>
                            <p>Without limitation, you undertake not to use or permit anyone else to use the Site or its service.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong> <p>XII. Licenses & Intellectual Property Rights</p></strong> <br />
                            {/* <ol type="1"> */}
                            <p>The copyright, patents, trademarks, registered designs and all intellectual property rights in the Services, the Site, and all Content shall vest in and remain with Central by Landers and its licensors.</p>
                            <p>Consent is granted to view, electronically copy, and print in hard copy portions of the Site for the sole purpose of placing an order for your use.</p>
                            <p>Any other use of the materials on this Site, including modification, distribution, or reproduction for purposes other than those noted above, without the prior written permission of Central by Landers is strictly prohibited. You acknowledge that Central by Landers and/or third-party content providers remain the owners of such material and that you do not acquire any of the ownership rights by downloading copyrighted material. We reserve the right to revoke this authorization at any time, and any use shall be discontinued immediately upon written notice from us.</p>
                            <p>The trademarks, logos and service marks ("Marks") displayed on this Site are the property of Central by Landers and other third parties, and all rights to the Marks are expressly reserved by Central by Landers and relevant third parties. You are not permitted to use the name of Central by Landers or any marks, including in any advertising or publicity or as a hyperlink, without the prior written consent of Central by Landers or such third party.</p>
                            <p>The domain name on which the Site is hosted on is the sole property of Central by Landers and you may not use or otherwise adopt a similar name for your own use.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong> <p>XIII. Indemnity</p> </strong> <br />
                            <p>You agree to indemnify, defend and hold Central by Landers, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Use or the documents they incorporate by reference, or your violation of any law or the rights of a third party.</p>
                        </div>
                        <div className="col-12 p_spacing">
                            <br /><strong><p>XIV. Force Majeure</p></strong><br />
                            <p>No party shall be liable to any breach of performance under the Agreement by reason of any delay in performing or any failure to perform any obligations if such delay or failure is due to any event or cause beyond reasonable control (each an event of "Force Majeure"). Without prejudice to the foregoing, the following events shall be regarded as events of Force Majeure:</p>
                            {/* <ol type="a"> */} <br />
                            <p>Act of God, explosion, flood, tempest, fire or accident;</p>
                            <p>War or threat of war, sabotage, insurrection, civil disturbance or requisition, act of terrorism or civil unrest;</p>
                            <p>Acts, restrictions, regulations, by-laws, prohibitions or measures of any kind on the part of any governmental, parliamentary or local authority;</p>
                            <p>Import or export regulations or embargoes;</p>
                            <p>Interruption of traffic, strikes, lock-outs or other industrial actions or trade disputes (whether involving employees of Landers or of a third party);</p>
                            <p>Health epidemics declared by the World Health Organization;</p>
                            <p>Interruption of production or operation, difficulties in obtaining raw materials, labor, fuel parts or machinery; and</p>
                            <p>Power failure or breakdown in machinery.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12">
                            <br /><strong> <p>XV. Amendments</p> </strong>
                            <p>These terms and conditions may be amended at any time by posting a revised version on Central by Landers website. The revised version shall be effective at the time of posting. If any provision shall be changed which in a way reduces Member's rights or increases his responsibilities, a notice by email shall be sent to Member prior to posting the updated policy on the website.</p>
                        </div>
                        <div className="col-12">
                            <strong> <p>XVI. Termination of Membership</p> </strong>
                            <p>We reserve the right to terminate or suspend any account with immediate effect upon notice for any reason whatsoever including breach of any provision of these Terms and Conditions.</p>
                            <p>If we suspend the Service or Website, we may refuse to restore the Service or Website or Voucher until we receive an assurance from you, in a form we deem acceptable that there will be no further breach of the provisions of this Terms and Conditions.</p>
                            <p>We shall fully co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity or locate anyone in breach of this Agreement.</p>
                            <p>We shall be entitled immediately or at any time (in whole or in part) to: i) suspend the Service and/or Website; ii) suspend your use of the Service and/or Website; iii) suspend the use of the Service and/or Website for persons we believe to be connected (in whatever manner) to you; and/or iv) terminate this Agreement immediately if: 1) you commit any breach of this Agreement;2) we suspect, on reasonable grounds, that you have, might or will commit a breach of these terms; or,3) we suspect, on reasonable grounds, that you may have committed or be committing any fraud against us or any person.</p>
                            <p>Our right to terminate this Agreement shall not prejudice any other right or remedy we may have in respect of any breach or any rights, obligations or liabilities accrued prior to termination.</p>
                        </div>
                        <div className="col-12">
                            <strong> <p>XVII. Standards and Limitation of Liability</p> </strong>
                            <p>We warrant that we will exercise reasonable care and skill in performing any obligation under this Terms and Conditions and we have the right to sell the Products in the Site. This clause prevails over all other clauses and sets forth our entire Liability.</p>
                            <p>We do not warrant and we exclude all Liability in respect of :1) the accuracy, completeness, fitness for purpose or legality of any information accessed using the Service or Website or otherwise; 2) the transmission or the reception of or the failure to transmit or to receive any material of whatever nature; and, 3) your use of any information or materials on the Website (which is entirely at your own risk and it is your responsibility).</p>
                            <p>We do not accept and hereby exclude any Liability for loss of or damage to your (or any person’s) tangible property other than that caused by our Breach of Duty.</p>
                            <p>We do not accept and hereby exclude any Liability for Breach of Duty other than any such Liability arising pursuant to the terms of this Agreement.</p>
                            <p>We shall have no Liability for:1) loss of revenue; 2) loss of actual or anticipated profits; 3) loss of contracts;4) loss of the use of money;5) loss of anticipated savings;6) loss of business;7) loss of opportunity;8) loss of goodwill;9) loss of reputation;10) loss of, damage to or corruption of data; or,any indirect or consequential lossand such Liability is excluded whether it is foreseeable, known, foreseen or otherwise.</p>
                            <p>The limitation of Liability has effect in relation both to any Liability expressly provided for under this Agreement and to any Liability arising by reason of the invalidity or unenforceability of any term of this Agreement. "Liability" means liability in or for breach of contract, Breach of Duty, misrepresentation, restitution or any other cause of action whatsoever relating to or arising under or in connection with this Agreement, including, without limitation, liability expressly provided for under this Agreement or arising by reason of the invalidity or unenforceability of any term of this Agreement (and for the purposes of this definition, all references to "this Agreement" shall be deemed to include any collateral contract); and, "Breach of Duty" means the breach of any (i) obligation arising from the express or implied terms of a contract to take reasonable care or exercise reasonable skill in the performance of the contract or (ii) common law duty to take reasonable care or exercise reasonable skill (but not any stricter duty).</p>
                        </div>
                        <div className="col-12">
                            <strong>  <p>XVIII. Advertisements and Links to and From Other Websites</p> </strong>
                            <p>We may place advertisements in different locations on the Website and at different points during use of the Service. These locations and points may change from time to time - but we will always clearly mark which goods and services are advertisements (i.e. from persons other than us), so that it is clear to you which goods and services are provided on an objective basis and which are not (i.e. the advertisements).</p>
                            <p>You are free to select or click on advertised goods and services or not as you see fit.</p>
                            <p>Any advertisements may be delivered on our behalf by a third party advertising company.</p>
                            <p>No personal data (for example your name, address, email address or telephone number) will be used during the course of serving our advertising, but, on our behalf, our third-party advertiser or affiliate may place or recognize a unique "cookie" on your browser (see our Privacy Policy here about this). This cookie will not collect personal data about you nor is it linked to any personal data about you.</p>
                            <p>Where the Website contains links to third party sites and to resources provided by third parties (together "Other Sites"), those Other Sites are merely linked to provide information only and are solely for your convenience. We have no control over and do not accept and we assume no responsibility for Other Sites or for the content or products or services of Other Sites (including, without limitation, relating to social networking sites such as Facebook) and we accept no responsibility for any loss or damage that may arise from your use of them. If you decide to access any of the third party websites linked to the website, you do so entirely at your own risk.</p>
                        </div>
                        <div className="col-12 p_spacing">
                            <strong>  <p>XIX. Dispute Resolution</p></strong> <br />
                            {/* <ol type="1"> */}
                            <p>In case of dispute arising out of Member's use of this Site or his Membership, Member hereby agrees to submit the same to mediation. The mediation shall be facilitated by a mediator jointly chosen by Central by Landers and Member. Member shall shoulder the mediation fees and expenses. Member agrees to refrain from adversarial legal proceedings (except in the case of an emergency necessitating such action) while the mediation process is ongoing</p>
                            <p>The Member agrees that in case of legal disputes or any judicial action arising from this Agreement, the venue of any court action shall exclusively be with the proper courts of Taguig City, to the exclusion of all other Courts.</p>
                            {/* </ol> */}
                        </div>
                        <div className="col-12">
                            <strong><br /> <p>XX. General Provisions</p></strong>
                            <p>This Agreement shall be governed by the laws of the Republic of Philippines.</p>
                            <p>Nothing in this Agreement shall be construed to create a joint venture, partnership or agency relationship between you and us and neither party shall have the right or authority to incur any liability debt or cost or enter into any contracts or other arrangements in the name of or on behalf of the other.</p>
                            <p>Except as expressly stated in this Terms and Conditions, all warranties, conditions and other terms, whether express or implied, by statute, common law or otherwise are hereby excluded to the fullest extent permitted by law.</p>
                            <p>You may not assign or delegate or otherwise deal with all or any of your rights or obligations under this Terms and Conditions. We shall have the right to assign or otherwise delegate all or any of our rights or obligations under this Terms and Conditions to any person.</p>
                            <p>This Agreement (and our Privacy Policy) contains all the terms agreed between the parties regarding its subject matter and supersedes and excludes any prior agreement, understanding or arrangement between the parties, whether oral or in writing. No representation, undertaking or promise shall be taken to have been given or be implied from anything said or written in negotiations between the parties prior to this Agreement except as expressly stated in this Agreement. Neither party shall have any remedy in respect of any untrue statement made by the other upon which that party relied in entering into this Agreement (unless such untrue statement was made fraudulently or was as to a matter fundamental to a party’s ability to perform this Agreement) and that party’s only remedies shall be for breach of contract as provided in this Agreement. However, the Service is provided to you under our operating rules, policies, and procedures as published from time to time on the Website.</p>
                            <p>No waiver by us of any default of yours under this Agreement shall operate or be construed as a waiver by us of any future defaults, whether or alike or different character. No granting of time or other forbearance or indulgence by us to you shall in any way release, discharge or otherwise affect your liability under this Agreement.</p>
                            <p>Unless otherwise stated within this Agreement, notices to be given to either party shall be in writing and shall be delivered by hand, electronic mail (other than, if you are sending a notice to us for the purpose of legal process) sent by fax or by pre-paid post, to you at the address you supplied to us or to us at our registered office.</p>
                            <p>If any part of this Terms and Conditions is held to be unlawful, invalid or unenforceable, that provision shall be deemed severed and where capable the validity and enforceability of the remaining provisions of this agreement shall not be affected.</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}