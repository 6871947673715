import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React, { Component } from "react";
import EatsLogo from "../../../assets/images/central_gold.png";
import bannertop2 from "../../../assets/images/eats_landing_bannerTop1.jpg";
import BeverageBanner from "../../../assets/images/Food Deal Banners/food_deals_beveragesBanner.jpg";
import BurgerBanner from "../../../assets/images/Food Deal Banners/food_deals_burgerBanner.jpg";
import ChickenBanner from "../../../assets/images/Food Deal Banners/food_deals_chickenBanner.jpg";
import DesertBanner from "../../../assets/images/Food Deal Banners/food_deals_dessertBanner.jpg";
import DimsumBanner from "../../../assets/images/Food Deal Banners/food_deals_dimsumBanner.jpg";
import PizzaBanner from "../../../assets/images/Food Deal Banners/food_deals_pizzaBanner.jpg";
import SeafoodBanner from "../../../assets/images/Food Deal Banners/food_deals_seafoodsBanner.jpg";
import NoodleBanner from "../../../assets/images/food_type_banner_noodles.jpg";

import {
  addToCartAnalytics,
  addToCartHub,
  updateCartAnalytics,
  updateCartHub,
  GAImpressions
} from "../../../helper/analytics";
import {
  getAccessToken,
  getCrossDomainToken,
  getCrossStorage,
  getSubdomainCartCount,
  handleExpiredToken,
  handleLandersBrandId,
  log,
  setCrossStorage,
  setHeaderAddress,
  setSubdomainCartCount,
  triggerSerinoEvent,
  getThumbnail,
  genericAddCartItem,
  getCrossCart,
  getUrl,
  mainAddressHandler,
  getAddressObject,
  getAddressFromObjectChecker,
  handleInitialAddToCart,
  handleShowProductActions,
  setStorage
} from "../../../helper/helper";
import ApplicationAPI from "../../../_core/application";
import Cart from "../../../_core/cart";
import CoreAPI from "../../../_core/core";
import LocationPicker from "../../partials/LocationPicker";
import BrandListComponent from "./brand-list-component";
import BundleComponent from '../../partials/BundleComponent';
import ParallaxComponent from '../../partials/ParallaxComponent';
import { BrandsComponent } from '../../partials/BrandsComponent';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


var cart = new Cart();
var coreAPI = new CoreAPI();
var application = new ApplicationAPI();

const x_app_key = process.env.REACT_APP_EATS_APP_ID;
const market_app_id = process.env.REACT_APP_MARKET_APP_ID;
var createGuest = require("cross-domain-storage/guest");
let retry_interval = 700;
let similar_items_id = [];
let eats_app_id = process.env.REACT_APP_EATS_APP_ID

export default class EatsLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      isLoggedin: "",
      address_object: [],
      variants: {},
      similar_items: [],
      triggerGetDefaultAddress: {
        state: false,
        app_key: x_app_key,
      },
      isAddBtnLoading: false,
      brands: [],
      categories: [],
      favorites: [],
      cart_initialized: 0,
      eatsProducts: [],
      app_key: x_app_key,
      isSharedDataLoaded: false,
      banners: [],
      brands_eats: [],
      apps: [],
      isSearchActive: false,
      mid_banner: null,
      fromFoodType: false,
      selectedFoodBrand: sessionStorage.getItem("brand_name") ?? "All brands",
      foodType: '',
    };
    this.sliderRef = React.createRef();
  }

  async componentDidMount() {
    localStorage.removeItem('fulfillment_mode')
    sessionStorage.removeItem("signupPage", true)

    this.headerChange();
    this.getDataFromJSON()
    await this.setBanners()
    localStorage.setItem("domain", "sub");
    localStorage.removeItem("brand_logo");
    sessionStorage.removeItem("signUpEnabled");

    // Get Address Object from Main page
    let address_object;
    let getAddressesTries = 0;
    let getTokenTries = 0;

    let access_token_interval = setInterval(async () => {
      let access_token = await getCrossDomainToken();

      getTokenTries++;

      if (access_token || getTokenTries == 3) {
        log("getTokenTries", getTokenTries);
        clearInterval(access_token_interval);

        if (access_token && access_token != null) {
          localStorage.setItem("access_token", access_token);
        } else {
          access_token = await getAccessToken();
          localStorage.setItem("access_token", access_token);
          setCrossStorage("access_token", access_token, "sub");
        }

        this.setState({ access_token: access_token });

        let getAddressesInterval = setInterval(async () => {
          // log('address retry', getAddressesTries)

          address_object = await getCrossStorage(
            `address_object_${process.env.REACT_APP_EATS_APP_ID}`,
            "sub"
          );

          getAddressesTries++;

          // log('address Object', address_object)

          if (address_object || getAddressesTries == 3) {
            clearInterval(getAddressesInterval);
            // log('address retry', getAddressesTries)

            if (address_object && address_object.length > 2) {
              localStorage.setItem(
                `address_object_${process.env.REACT_APP_EATS_APP_ID}`,
                address_object
              );
              let parsed = JSON.parse(address_object);
              if (parsed && parsed.length > 0) {
                this.setState({
                  address_object: parsed,
                });
              } else {
                localStorage.removeItem("branch_id");
                localStorage.removeItem("address_object");
                localStorage.removeItem(`address_object_${x_app_key}`);
                this.setState({
                  triggerGetDefaultAddress: {
                    state: !this.state.triggerGetDefaultAddress,
                    app_key: x_app_key,
                  },
                });
              }
            } else {
              localStorage.removeItem("branch_id");

              this.setState({
                triggerGetDefaultAddress: {
                  state: !this.state.triggerGetDefaultAddress,
                  app_key: x_app_key,
                },
              });
            }
          }
        }, retry_interval);

        // let cartV2Component = window.serino_components.cart.create({
        //     access_token: access_token,
        //     app_key: 2,
        //     id: 'eatsCartLanding',
        //     fulfillmentType: 1
        // }, 'srn-eats-cart-container')
        this.initializeComponents(access_token)
      }
    }, retry_interval);

    if (this.props.location.search.length > 0) {
      let search = this.props.location.search.substring(1);
      let objFromUrl = JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      let product_id = objFromUrl.pdesc;
      
      if (product_id) {
        application.getProductDetails(x_app_key, product_id).then((res) => {
          this.onDescriptionModal(res.data.items[0], x_app_key);
        });
      }
    }
    getSubdomainCartCount();
    this.setMainAddress();
  }

  handleClearBrandSelection = () => {
    sessionStorage.removeItem("viewAction");
    sessionStorage.removeItem("brand_id");
    sessionStorage.removeItem("brand_name");
  }

  initializeComponents = (access_token) => {

        /**
         * Check if Food Type is active
         */
        let URLObject;
        let search;
        if (this.props?.location?.search?.length > 0) {
          search = this.props.location.search.substring(1);
          URLObject = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')

          const foodType = URLObject?.foodtype;
          
          if (foodType && foodType?.length > 0) {
            sessionStorage.setItem("viewAction", "foodTypeSelect");
              
            window.serino_components.ProductTagsComponent.create({
              access_token: localStorage.getItem("access_token"),
              x_app_key: process.env.REACT_APP_EATS_APP_ID,
              currency: "₱",
              time_interval: 8000,
              tags: foodType?.toLocaleLowerCase() ?? "",
              branch_id: sessionStorage.getItem("brand_id") ? sessionStorage.getItem("brand_id") : 0,
              viewMoreEnabled: false,
              id: "main-landing-best-pick",
            }, "food-deals");

            this.setState({
              fromFoodType: true,
              foodType
            })
          }
          else {
            this.handleClearBrandSelection()
          }
        }
        else {
          this.handleClearBrandSelection()
        }

        this.userRole();

        // let component = window.serino_components.BrandListComponent.create(
        //   {
        //     access_token: access_token,
        //     x_app_key: x_app_key,
        //     time_interval: 10000,
        //     status: 1, // blank = all, 0 = inactive, 1 = active
        //   },
        //   "srn-brand-selector-container"
        // );

        let brandListEvents =
          window.serino_components.BrandListComponent.events;
        document.addEventListener(brandListEvents.onBrandSelect, (e) => {
          console.log("onBrandSelect", e.detail);
          this.handleGotoProducts(e.detail);
        });

        let food_type_component =
          window.serino_components.FoodTypeComponent.create(
            {
              access_token: access_token,
              x_app_key: x_app_key,
              food_type: "favorites",
            },
            "srn-food-type-component"
          );

        let double_treats_eats_component =
          window.serino_components.ProductTagsComponent.create({
              access_token: access_token,
              x_app_key: x_app_key,
              time_interval: 10000,
              currency: "₱",
              tags: "double treats eats",
              shared_tags: [ "double treats eats","central eats payday","landingpage3",],
              cart_item: "",
              isParent: true,
              landing: 1,
              id: 'eats-landing-parent'
            },"srn-double-treats-component");

        // let food_deal_tags_component = window.serino_components.ProductTagsComponent.create({
        //     access_token: access_token,
        //     x_app_key: x_app_key,
        //     time_interval: 10000,
        //     currency: '₱',
        //     tags: 'landingpage3',
        //     cart_item: ''
        // }, 'srn-food-deals-component')

        // FOOD TYPE EVENT
        let foodTypeEvent = window.serino_components.FoodTypeComponent.events;
        document.addEventListener(foodTypeEvent.onSelectFoodType, (e) => {          
          window.location = `/eats?foodtype=${e.detail.food_type.name}`
        });

        let productTagsEvent =
          window.serino_components.ProductTagsComponent.events;

        document.addEventListener(productTagsEvent.onProductTagsLoad('eats-landing-parent'), (e) => {
            log('onProductsLoad', e.detail)
            this.setState({
                eatsProducts: e.detail
            })
        })

        document.addEventListener(productTagsEvent.onLoadSharedData, (e) => {
          log('filtered', e.detail)

          let products = []
          e.detail.forEach(tag => {
            tag.items.forEach(x => products.push(x))
          })
          products = [...new Set(products)];

          GAImpressions(products, 'Eats Landing Products')

          let central_eats_payday_component =
            window.serino_components.ProductTagsComponent.create(
              {
                access_token: access_token,
                x_app_key: x_app_key,
                time_interval: 10000,
                currency: "₱",
                tags: "central eats payday",
                cart_item: "",
                products: e.detail,
                landing: 1,
                id: 'eats-landing-eats-payday'
              },
              "srn-payday-component"
            );

            let central_eats_payday_component2 = window.serino_components.ProductTagsComponent.create({
                access_token: access_token,
                x_app_key: x_app_key,
                time_interval: 10000,
                currency: '₱',
                tags: 'landingpage3',
                cart_item: '',
                products: e.detail,
                landing: 1,                
                id: 'landing-page-3'                
            }, 'srn-payday-component2')

            setTimeout(() => {
                this.setState({
                    isSharedDataLoaded: true
                })
            }, 300)
        });

        document.addEventListener(
          productTagsEvent.onShowProductDetails,
          (e) => {
            // alert(e.detail.product_details.name)
            handleShowProductActions(e.detail.product_details)

            let product = e.detail.product_details;
            this.setState({ product });

            let srn_product_description =
              window.serino_components.ProductDescriptionComponent.create(
                {
                  access_token: access_token,
                  product: product,
                  currency: "₱",
                  mode: 2,
                },
                "srn-demo-product-description-component"
              );
            this.props.history.push(`?pdesc=${product.id}`);
            // this.getCart(access_token);
            this.onDescriptionModal(product, x_app_key);
          }
        );

        let similarProductEvents =
          window.serino_components.SimilarProductsComponent.events;

        let product_description_crm_events =
          window.serino_components.ProductDescriptionComponent.events;

        // document.addEventListener(product_description_crm_events.addtoCart, (e) => {
        //     console.log(e.detail)
        //     if (window.location.pathname != '/eats-products') {
        //         this.addtoCart()
        //     }
        // })

        // add to cart event
        document.addEventListener(productTagsEvent.onAddToCart, (e) => {
          console.log(e.detail);
          let product = e.detail.product_details;
          let variants = e.detail.product_details.variants;
          let brand_id = handleLandersBrandId(product.brand_id, x_app_key);
          localStorage.setItem("brand_id", brand_id);
          log("brand_id", brand_id);
          this.setState({ product, variants }, () => this.addtoCart());
        });

        let searchComponentEvent = window.serino_components.SearchProductListComponent.events

        document.addEventListener(searchComponentEvent.onAddToCart, (e) => {
          console.log(e.detail);
          let product = e.detail;
          let brand_id = product.brand_id
          localStorage.setItem("brand_id", brand_id);
          this.setState({ product, app_key: product.application_id }, () => genericAddCartItem(product, this.handleMainAddress, this.getCart));
        });

        // delete item event
        document.addEventListener(productTagsEvent.onDeleteItem, (e) => {
          let id = e.detail.detail.cartItemEntity;
          log('onDeleteItem', e.detail.detail)
          cart
            .removeCartItem(access_token, x_app_key, id, this.state.cart_items)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.getCart(res.items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // manual input quantity
        document.addEventListener(productTagsEvent.updateQuantity, (e) => {
          let id = e.detail.detail.id;
          let payload = {
            quantity: e.detail.detail.quantity,
            include_promo: true,
          };
          cart
            .updateQuantity(access_token, x_app_key, id, payload)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                updateCartAnalytics();
                let cart_item = e.detail.payload.item;
                if (cart_item) {
                  let action = null;
                  if (payload.quantity > cart_item.quantity) {
                    action = "increment";
                  }
                  if (payload.quantity < cart_item.quantity) {
                    action = "decrement";
                  }
                  updateCartHub(cart_item, action);
                }
                // this.getCart()
                this.updateCartCount(e.detail.payload.cart_items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // increment button
        document.addEventListener(productTagsEvent.onUpdateIncrement, (e) => {
          let id = e.detail.payload.cartItemId;
          let payload = {
            quantity: e.detail.payload.quantity,
            include_promo: true,
          };
          cart
            .updateQuantity(access_token, x_app_key, id, payload)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                updateCartAnalytics();
                let cart_item = e.detail.payload.item;
                if (cart_item) {
                  updateCartHub(cart_item, "increment");
                }
                // this.getCart()
                this.updateCartCount(e.detail.payload.cart_items);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // decrement button
        document.addEventListener(productTagsEvent.onUpdateDecrement, (e) => {
          let id = e.detail.payload.cartItemId;
          let payload = {
            quantity: e.detail.payload.quantity,
            include_promo: true,
          };
          cart
            .updateQuantity(access_token, 2, id, payload)
            .then((res) => {
              let cart_item = e.detail.payload.item;
              if (cart_item) {
                updateCartHub(cart_item, "decrement");
              }
              // if (res.status === 200 || res.status === 201) {
              // this.getCart() // update cart item
              this.updateCartCount(e.detail.payload.cart_items);
              // }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        this.addSearchEvents(access_token)

        // // remove item
        // document.addEventListener(productTagsEvent.onDeleteItem, (e) => {
        //   let id = e.detail.detail.cartItemId;
        //   cart
        //     .removeCartItem(access_token, x_app_key, id)
        //     .then((res) => {
        //       if (res.status === 200 || res.status === 201) {
        //         this.getCart(res.items); // update cart item
        //       }
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        // });

        // product description events
        let productDescriptionEvent =
          window.serino_components.ProductDescriptionComponent.events;
        // add
        document.addEventListener(productDescriptionEvent.addtoCart, (e) => {
          let product = e.detail.product_details.id;
          let variants = e.detail.product_details.variants;
          let brand_id = handleLandersBrandId(product.brand_id, x_app_key);
          log("brand_id", product.brand_id);
          if (window.location.pathname != "/eats-products") {
            localStorage.setItem("brand_id", brand_id);
            this.setState({ product, variants }, () => this.addtoCart());
          }
        });
        // delete
        document.addEventListener(productDescriptionEvent.onDeleteItem, (e) => {
          let id = e.detail.cartItemId;
          cart
            .removeCartItem(access_token, x_app_key, id, this.state.cart_items)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.getCart(res.items); // update cart item
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
        // manual quantity
        document.addEventListener(
          productDescriptionEvent.updateQuantity,
          (e) => {
            let id = e.detail.detail.id;
            let payload = {
              quantity: e.detail.detail.quantity,
              include_promo: true,
            };
            cart
              .updateQuantity(access_token, x_app_key, id, payload)
              .then((res) => {
                let cart_item = e.detail.detail.item;
                if (cart_item) {
                  let action = null;
                  if (payload.quantity > cart_item.quantity) {
                    action = "increment";
                  }
                  if (payload.quantity < cart_item.quantity) {
                    action = "decrement";
                  }
                  updateCartHub(cart_item, action);
                }
                if (res.status === 200 || res.status === 201) {
                  // this.getCart()
                  this.updateCartCount(e.detail.detail.cart_items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        );
        // increment button
        document.addEventListener(
          productDescriptionEvent.onIncrementCart,
          (e) => {
            console.log("incerment::", e.detail);
            let id = e.detail.cartItemId;
            let payload = {
              quantity: e.detail.quantity,
              include_promo: true,
            };
            cart
              .updateQuantity(access_token, x_app_key, id, payload)
              .then((res) => {
                // for google analytics
                let cart_item = e.detail.item;
                if (cart_item) {
                  updateCartHub(cart_item, "increment");
                }
                if (res.status === 200 || res.status === 201) {
                  // this.getCart()
                  this.updateCartCount(e.detail.payload.cart_items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        );
        // decrement button
        document.addEventListener(
          productDescriptionEvent.onDecrementCart,
          (e) => {
            console.log("_____________:", e.detail);
            let id = e.detail.cartItemId;
            let payload = {
              quantity: e.detail.quantity,
              include_promo: true,
            };
            cart
              .updateQuantity(access_token, x_app_key, id, payload)
              .then((res) => {
                // for google analytics
                let cart_item = e.detail.item;
                if (cart_item) {
                  updateCartHub(cart_item, "increment");
                }
                if (res.status === 200 || res.status === 201) {
                  // this.getCart() // update cart item
                  this.updateCartCount(e.detail.payload.cart_items);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        );
        // remove item
        document.addEventListener(productDescriptionEvent.onDeleteItem, (e) => {
          let id = e.detail.cartItemId;
          cart
            .removeCartItem(access_token, x_app_key, id, this.state.cart_items)
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.getCart(res.items); // update cart item
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });

        // fulfillment type event CONFIRM
        let fulfillmentTypeEvent =
          window.serino_components.FulfillmentTypeComponent.events;
        document.addEventListener(fulfillmentTypeEvent.onConfirm, (e) => {
          $("#descriptionDiscountModal").modal("hide");
          let branch_id = e.detail.detail.branch_id;
          // if (branch_id) {
          //   this.addToCartComponent(this.state.product, branch_id);
          // }
        });

        $("#descriptionDiscountModal").on("hidden.bs.modal", () => {
          similar_items_id = [];
          setTimeout(() => {
            if (this.state.isSearchActive) {
              this.handleSearchDisplay(true)
            }
          }, 300)
        });

        document.addEventListener(brandListEvents.onDataLoad, (e) => {
          this.setState({
            brands: e.detail,
          });
        });

        this.getCart(null, true);
      }

  getCart = async (cartItems, isInitial = false) => {

    let cart_items = []

    if (isInitial) {

      try {
        let getCartResult = await cart.getCartItems(
          localStorage.getItem("access_token"),
          x_app_key
        );
        cart_items = getCartResult.data
      } catch (cartError) {
        if (cartError.responseJSON.message == "Invalid Access Token") {
          handleExpiredToken();
        }
      }
    }
    else {
      cart_items = cartItems
    }

    if (this.state.isSearchActive) {
        this.handleSearchDisplay(true)
    }

    this.setState(
      {
        cart_items: cart_items,
        isAddBtnLoading: false,
        cart_initialized: 1,
        isSearchActive: false,
        product: {}
      },
      () => {
        localStorage.removeItem('branch_id')
        // update cart items in component
        this.updateComponentCart()
        this.productDescriptionSubscription();
        this.updateCartCount(this.state.cart_items, true);
      }
      )
  };

  updateCartCount = async (cart_items, isGetCart = false) => {
    log("updateCartCount from landing", cart_items);
    let totalCartCount = 0;
    if (cart_items.length > 0) {
      let eats_cartCount = cart_items.reduce((a, b) => +a + +b.quantity, 0);
      log({ eats_cartCount });
      if (eats_cartCount && eats_cartCount > 0) {
        totalCartCount = eats_cartCount;
      }
    }
    log("updateCartCount", cart_items);
    log("cartCount", totalCartCount);
    let details = {
      eats: totalCartCount,
      market: localStorage.getItem("market_cart_count"),
      app: "eats",
    };
    if (!isGetCart) {
      this.productDescriptionSubscription();
      this.updateComponentCart();
    }
    setSubdomainCartCount(details);
  };

  productListSubscription = () => {
    // subscription for cart quantity
    let productListSubscription =
      window.serino_components.ProductTagsComponent.subscriptions;
    let productListCartItems = new CustomEvent(
      productListSubscription.onCartItems,
      {
        detail: this.state.cart_items,
      }
    );
    document.dispatchEvent(productListCartItems);
  };

  productTagsSubscription = () => {
    let productTagsSubscription =
      window.serino_components.ProductTagsComponent.subscriptions;
    let cartItems = new CustomEvent(productTagsSubscription.onCartItems, {
      detail: this.state.cart_items,
    });
    document.dispatchEvent(cartItems);
  };

  productDescriptionSubscription = () => {
    // subscription for cart quantity
    let productDescriptionSubscription =
      window.serino_components.ProductDescriptionComponent.subscriptions;
    let productDescriptionItems = new CustomEvent(
      productDescriptionSubscription.onCartItems,
      {
        detail: this.state.cart_items,
      }
    );
    document.dispatchEvent(productDescriptionItems);

    // $('#descriptionDiscountModal').modal('hide')
  };

  updateComponentCart = () => {

    let tags = ['eats-landing-parent', 'eats-landing-eats-payday', 'landing-page-3']
    let isCartLoadedInterval = setInterval(() => {
      if (this.state.isSharedDataLoaded) {
        clearInterval(isCartLoadedInterval)
          tags.map(tag => {
              triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.onCartItems(tag), this.state.cart_items)
          })
      }
    }, 500)
    // triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.onCartItems, this.state.cart_items)
    triggerSerinoEvent(window.serino_components.SearchProductListComponent.subscriptions.onCartItems, this.state.cart_items)
    triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.onCartItems('main-landing-best-pick'), this.state.cart_items)
  }

  handleGotoProducts = (brand) => {
    var str = brand.brand.name;
    str = str.replace(/\s+/g, "-").toLowerCase();
    localStorage.setItem("brand_logo", brand.brand.logo);
    localStorage.setItem("brand_id", brand.brand.id);

    localStorage.removeItem("branch_id");
    localStorage.removeItem("Address");
    localStorage.removeItem("brand_name");
    localStorage.removeItem("brand_best_seller");
    localStorage.removeItem("fulfillment_type_status");
    localStorage.setItem("brand_name", str + "-brand");
    localStorage.setItem("brand_name_str", brand.name);
    localStorage.setItem("brand_best_seller", brand.brand.name.toLowerCase());
    localStorage.setItem("brand_id", brand.brand.id.toString());
    sessionStorage.setItem("viewAction", "brandSelect");
    localStorage.setItem("brandN", str);
    sessionStorage.removeItem("foodType");
    sessionStorage.removeItem("viewAllAction");
    sessionStorage.removeItem("searchKeyWord");
    setTimeout(() => {
      this.props.history.push(`/eats-products?${str}`);
      window.location.reload();
    }, 300);
  };

  onDescriptionModal = (detail, x_app_key) => {
    $("#descriptionDiscountModal").modal("show");

    similar_items_id.push(detail.id); // product id for similar item

    let srn_product_description =
      window.serino_components.ProductDescriptionComponent.create(
        {
          access_token: localStorage.getItem("access_token"),
          product: detail,
          currency: "₱",
          mode: 2,
        },
        "srn-demo-product-description-component"
      );

      setTimeout(() => {
        this.productDescriptionSubscription()
      }, 80)

    let similarProduct =
      window.serino_components.SimilarProductsComponent.create(
        {
          access_token: localStorage.getItem("access_token"),
          x_app_key: x_app_key,
          branch_id: '',
          time_interval: 8000,
          currency: "₱",
          product_ids: similar_items_id,
          id: "best-partner-item",
        },
        "srn-partner-product-container"
      );

    let similarProductEvents =
      window.serino_components.SimilarProductsComponent.events;
    let similarProductScubscription =
      window.serino_components.SimilarProductsComponent.subscriptions;
    let initSimilarProducts = new CustomEvent(
      similarProductScubscription.onLoad("best-partner-item"),
      {
        detail: similar_items_id,
      }
    );
    document.dispatchEvent(initSimilarProducts);

    document.addEventListener(
      similarProductEvents.onGetSimilarItems("best-partner-item"),
      (e) => {
        this.setState({ similar_items: e.detail });
      }
    );

    document.addEventListener(
      similarProductEvents.onShowProductDetails("best-partner-item"),
      (e) => {
        handleShowProductActions(e.detail.product_details)
        let brand_id = handleLandersBrandId(
          e.detail.product_details.brand_id,
          e.detail.app_key
        );
        localStorage.setItem("brand_id", brand_id);

        this.setState(
          {
            app_key: e.detail.x_app_key,
            product: e.detail.product_details,
          },
          () => {
            this.props.history.push(`?pdesc=${e.detail.product_details.id}`);
            this.onDescriptionModal(e.detail.product_details);
          }
        );
      }
    );
  };

  addtoCart = async () => {
    log('add to cart started')
    let product = this.state.product;

    log({ product });

    if (this.state.isAddBtnLoading == product.id) {
      return;
    }
    this.setState({
      isAddBtnLoading: product.id,
    });

    let custom_data = [];

    let price_object = null

    if (product && product.prices) {
      price_object = product.product_prices.find(
        (x) => x.name == "nonmember"
      );
    }


    if (price_object) {
      custom_data.push(price_object);
    }

    triggerSerinoEvent(
      window.serino_components.ProductDescriptionComponent.subscriptions
        .setButtonLoading,
      product.id
    );

    const proceed = async (branch_id) => {
      let payload = {
        itemReferenceId: product.id,
        itemReferenceType: product.type,
        itemName: product.name,
        itemSKU: product.sku,
        itemImage: getThumbnail(product) ? getThumbnail(product) : product.images,
        itemDescription: product.description,
        quantity: product.quantity && product.quantity > 0 ? product.quantity : 1,
        basePrice: product.price,
        price: product.price,
        instructions: "",
        domainReferenceId: branch_id
          ? branch_id
          : localStorage.getItem("brand_id"),
        domainReferenceType: "1",
        item_type: product.sell_by_type ? product.sell_by_type : 0,
        include_promo: true,
      };

      // SAVE ADDRESS IN CART START
      if (getAddressFromObjectChecker) {
          let address = getAddressObject(branch_id, process.env.REACT_APP_EATS_APP_ID)

          if (!address) {
              triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
          }

          custom_data.push({
              name: 'address',
              value: address
          })
      }
      // SAVE ADDRESS IN CART 

      if (this.state.variants && Object.keys(this.state.variants).length > 0) {
        payload.optionIds = this.state.variants.option_ids.toString();
        payload.optionText = this.state.variants.option_texts.join("|");
      }

      if (custom_data && custom_data.length > 0) {
        payload.custom_data = JSON.stringify(custom_data);
      }

      try {
        const addCartItemResult = await cart.addCartItem(
          localStorage.getItem("access_token"),
          x_app_key,
          payload
        );

        log('addCartItemResult', addCartItemResult)
        // for google analytics
        addToCartAnalytics();
        addToCartHub(product, branch_id);

        setTimeout(
          () => {
            this.getCart(addCartItemResult.items);
          },
          100
        )
      }
      catch(err) {
        if (err.responseJSON.message == 'Invalid Access Token') {
          handleExpiredToken()
        }
      }
    };

    // if(localStorage.getItem('branch_id')) {
    //     proceed(localStorage.getItem('branch_id'))
    // }
    // else {

    let localAddressObject = localStorage.getItem(
      `address_object_${x_app_key}`
    );
    let address_object = [];
    if (localAddressObject && localAddressObject.length > 0) {
      address_object = JSON.parse(localAddressObject);
    }
    if (address_object && address_object.length > 0) {
      let address = address_object.find(
        (x) => x.brand_id == localStorage.getItem("brand_id")
      );
      if (address) {
        if (address.branch_id && address.branch_id != null) {
          localStorage.setItem("branch_id", address.branch_id);
          proceed(address.branch_id);
        } else {
          this.handleMainAddress();
        }
      } else {
        this.handleMainAddress();
      }
    } else {
      this.handleMainAddress();
    }
    // }
  };

  handleMainAddress = (type = "addtocart") => {
    this.setState({ isAddBtnLoading: false });
    mainAddressHandler()
  };

  findUpdatedProduct = (productProps) => {
      let product = this.state.eatsProducts.find(x => x.id == productProps.id)
      log('findUpdatedProduct', product)
      if (product) {
          if (productProps && productProps.quantity && productProps.quantity > 0) {
            product.quantity = productProps.quantity
          }
          return product
      }
      else {
          return null
      }
  }


  addToCartComponent = async (productProps, branch_id) => {

    log('addToCartComponent', productProps)

    if (productProps.application_id == market_app_id) {
      genericAddCartItem(productProps, this.handleMainAddress, this.getCart)
      return;
    }

    let product = this.findUpdatedProduct(productProps)

    if (!product){
      return;
    }

    if (this.state.isAddBtnLoading == product.id) {
      return;
    }
    this.setState({
      isAddBtnLoading: product.id,
    });

    triggerSerinoEvent(
      window.serino_components.ProductDescriptionComponent.subscriptions
        .setButtonLoading,
      product.id
    );

    let payload = {
      itemReferenceId: product.id,
      itemReferenceType: product.type,
      itemName: product.name,
      itemSKU: product.sku,
      itemImage: getThumbnail(product) ? getThumbnail(product) : product.images,
      itemDescription: product.description,
      quantity: product.quantity && product.quantity > 0 ? product.quantity : 1,
      basePrice: product.price,
      price: product.newPrice ? product.newPrice : product.price,
      instructions: "",
      domainReferenceId: branch_id
        ? branch_id
        : localStorage.getItem("brand_id"),
      domainReferenceType: "1",
      item_type: product.sell_by_type ? product.sell_by_type : 0,
      include_promo: true,
    };

    if (this.state.variants && Object.keys(this.state.variants).length > 0) {
      payload.optionIds = this.state.variants.option_ids.toString();
      payload.optionText = this.state.variants.option_texts.join("|");
    }

    let custom_data = [];

    let price_object = product.product_prices.find(
      (x) => x.name == "nonmember"
    );

    custom_data.push(price_object);

    // SAVE ADDRESS IN CART START
    if (getAddressFromObjectChecker) {
        let address = getAddressObject(branch_id, process.env.REACT_APP_EATS_APP_ID)

        if (!address) {
            triggerSerinoEvent(window.serino_components.ProductDescriptionComponent.subscriptions.setButtonLoading, false)
        }

        custom_data.push({
            name: 'address',
            value: address
        })
    }
    // SAVE ADDRESS IN CART 

    if (custom_data && custom_data.length > 0) {
      payload.custom_data = JSON.stringify(custom_data);
    }

    try {
      const addCartItemResult = await cart.addCartItem(
        localStorage.getItem("access_token"),
        x_app_key,
        payload
      );
      
      log("addCartItemResult", addCartItemResult);

      // for google analytics
      addToCartAnalytics();
      addToCartHub(product, branch_id);

      setTimeout(
        () => {
          this.getCart(addCartItemResult.items);
        },
        100
      );
    }
    catch(err) {
      if (err.responseJSON.message == 'Invalid Access Token') {
        handleExpiredToken()
      }
    }
  };

  deleteCartItem = (item, type) => {
    let payload = [
      {
        itemReferenceId: item.item_ref_id,
        itemName: item.item_ref_name,
      },
    ];
    let productTagsSubscription =
      window.serino_components.ProductTagsComponent.subscriptions;
    let deleteCartItemEvent = new CustomEvent(
      productTagsSubscription.onCartItems,
      {
        bubbles: true,
        detail: payload,
      }
    );
    document.dispatchEvent(deleteCartItemEvent);
  };

  headerChange = () => {
    $(".borderTop").addClass("red");
  };

  handleLocationChange = (address_object, branch_id) => {
    this.setState(
      {
        address_object: address_object,
        isAddBtnLoading: false,
        cart_items: [],
      },
      () => {
        triggerSerinoEvent(window.serino_components.ProductTagsComponent.subscriptions.initializeProducts('eats-landing-parent'))
        if (this.state.product && this.state.product.id) {
          handleInitialAddToCart(this.state.product, branch_id, this.handleInitialAdd)
        }
        this.productDescriptionSubscription();
        this.updateComponentCart();
      }
    );
  };

  handleInitialAdd = (product) => {
    this.setState({product}, () => this.addtoCart())
  }

  handleModalClose = () => {
    this.updateComponentCart()
  };

  userRole = async () => {
    let isLoggedIn = await getCrossStorage("isLoggedIn", "sub");

    localStorage.setItem("isLoggedIn", isLoggedIn);

    let categoryListSubscription =
      window.serino_components.CategoryListComponent.subscriptions;
    let categoryUserRole = new CustomEvent(categoryListSubscription.userRole, {
      detail: isLoggedIn,
    });
    document.dispatchEvent(categoryUserRole);
  };

  setMainAddress = async () => {
    let main_address = "";
    let getMainAddressTries = 0;

    let getMainAddressesInterval = setInterval(async () => {
      let main_address = await getCrossStorage("main_address", "sub");

      getMainAddressTries++;

      if (
        (main_address || getMainAddressTries == 3) &&
        main_address != undefined &&
        main_address !== "0"
      ) {
        log({ getMainAddressTries });
        log({ main_address });
        clearInterval(getMainAddressesInterval);

        if (main_address && main_address.length > 0) {
          localStorage.setItem("main_address", main_address);

          let parsed = JSON.parse(main_address);

          if (parsed && parsed.address != undefined) {
            localStorage.setItem("Address", parsed.address);

            if (document.getElementById("query-eats_landing_page")) {
              document.getElementById("query-eats_landing_page").text =
                parsed.address;
            }

            setHeaderAddress(parsed);
          }
        } else {
          localStorage.removeItem("main_address");
          localStorage.removeItem("Address");
        }
      }
    });
  };

  handleGotoProductsFromCategory = (category) => {
    log("handleGotoProductsFromCategory fired 1");
    if (category) {
      this.props.history.push(`/eats-products`, category);
    } else {
      this.props.history.push(`/eats-products`);
    }
  };

  showSideCategories = () => {
    document.getElementById("side-categ").style.width = "250px";
    document.getElementById("side-categ").style.padding = "20px 10px";
    document.getElementById("side-categ-backdrop").style.display = "block";

    // accordion for side category
    var accordion = document.getElementsByClassName("cmb-collection-sub-items");
    var i;
    for (i = 0; i < accordion.length; i++) {
      accordion[i].addEventListener("click", function () {
        this.childNodes[0].classList.toggle("active-categ");
        var sub_categ = this.childNodes[1].children;
        for (var x = 0; x < sub_categ.length; x++) {
          if (sub_categ[x].style.display === "block") {
            sub_categ[x].style.display = "none";
          } else {
            sub_categ[x].style.display = "block";
          }
        }
      });
    }
  };

  closeSideNav = () => {
    document.getElementById("side-categ").style.width = "0";
    document.getElementById("side-categ").style.padding = "0";
    document.getElementById("side-categ-backdrop").style.display = "none";
  };

  addSearchEvents = (access_token) => {
    let searchComponentEvent = window.serino_components.SearchProductListComponent.events
      this.setState({
          isSearchActive: true
      })
     // Show item detail
      document.addEventListener(searchComponentEvent.onSelect, (e) => {
        this.onDescriptionModal(e.detail.product);
      })
     // delete item event
      document.addEventListener(searchComponentEvent.onDeleteItem, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.detail.cartItemEntity;
        cart
          .removeCartItem(access_token, e.detail.detail.app_key, id, this.state.cart_items)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              this.getCart(res.items);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

      // manual input quantity
      document.addEventListener(searchComponentEvent.updateQuantity, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.detail.id;
        let payload = {
          quantity: e.detail.detail.quantity,
          include_promo: true,
        };
        cart
          .updateQuantity(access_token, e.detail.app_key, id, payload)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              updateCartAnalytics();
              let cart_item = e.detail.payload.item;
              if (cart_item) {
                let action = null;
                if (payload.quantity > cart_item.quantity) {
                  action = "increment";
                }
                if (payload.quantity < cart_item.quantity) {
                  action = "decrement";
                }
                updateCartHub(cart_item, action);
              }
              // this.getCart()
              this.updateCartCount(e.detail.payload.cart_items);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

      // increment button
      document.addEventListener(searchComponentEvent.onUpdateIncrement, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.payload.cartItemId;
        let payload = {
          quantity: e.detail.payload.quantity,
          include_promo: true,
        };
        cart
          .updateQuantity(access_token, e.detail.app_key, id, payload)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              updateCartAnalytics();
              let cart_item = e.detail.payload.item;
              if (cart_item) {
                updateCartHub(cart_item, "increment");
              }
              // this.getCart()
              this.updateCartCount(e.detail.payload.cart_items);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

      // decrement button
      document.addEventListener(searchComponentEvent.onUpdateDecrement, (e) => {
        this.setState({
            isSearchActive: true
        })
        let id = e.detail.payload.cartItemId;
        let payload = {
          quantity: e.detail.payload.quantity,
          include_promo: true,
        };
        cart
          .updateQuantity(access_token, e.detail.app_key, id, payload)
          .then((res) => {
            let cart_item = e.detail.payload.item;
            if (cart_item) {
              updateCartHub(cart_item, "decrement");
            }
            // if (res.status === 200 || res.status === 201) {
            // this.getCart() // update cart item
            this.updateCartCount(e.detail.payload.cart_items);
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  handleCollectionSelect = (collection) => {

    // log(`${getUrl(collection.application_id == eats_app_id ? 'eats' : 'market')}-products?type=bundle&collection_id=${collection.id}&collection_name=${collection.name}`)

    // window.location = `${getUrl(collection.application_id == eats_app_id ? 'eats' : 'market')}-products?type=bundle&collection_id=${collection.id}&collection_name=${collection.name}`
  }

  setBanners = async () => {
    let banners = []
    let mid_banner = null
    try {
        const dataFromJSON = await coreAPI.getDataFromJSON()

        if (!dataFromJSON) {
            return
        }

        if (dataFromJSON.data && dataFromJSON.data.banners) {
            banners = dataFromJSON.data.banners.filter(x => x.application_id == 2 && x.status == 1)
            // Get middle banners with position === 'eats_homepage'
            const mid_banners = dataFromJSON?.data?.mid_banners
            if (mid_banners && mid_banners?.length > 0) {
                mid_banner = mid_banners?.find(x => x?.position === 'eats_homepage' && x?.status == 1) || null;
            }
        }

        const brands = dataFromJSON?.data?.brands?.filter(x => Number(x?.status) === 1).map(brand => {
          return {
            id: brand?.id,
            name: brand?.name,
          }
        })

        this.setState({banners, mid_banner, brands})

        return
    }
    catch(getDataErr) {
        log(getDataErr)
    }
  }

  handleGotoProductsEats = (brand) => {
    var str = brand.name;
    str = str.replace(/\s+/g, "-").toLowerCase();
    localStorage.setItem("brand_logo", brand.logo);
    localStorage.setItem("brand_id", brand.id);

    localStorage.removeItem("branch_id");
    localStorage.removeItem("Address");
    localStorage.removeItem("brand_name");
    localStorage.removeItem("brand_best_seller");
    localStorage.removeItem("fulfillment_type_status");
    localStorage.setItem("brand_name", str + "-brand");
    localStorage.setItem("brand_name_str", brand.name);
    localStorage.setItem("brand_best_seller", brand.name.toLowerCase());
    localStorage.setItem("brand_id", brand.id.toString());
    sessionStorage.setItem("viewAction", "brandSelect");
    localStorage.setItem("brandN", str);
    sessionStorage.removeItem("foodType");
    sessionStorage.removeItem("viewAllAction");
    sessionStorage.removeItem("searchKeyWord");
    setTimeout(() => {
      // this.props.history.push(`/eats-products?${str}`);
      window.location = `/eats-products?${str}`
    }, 300);
  };

  getDataFromJSON = async () => {
      let brands_eats = []
      let apps = []

      try {
          const dataFromJSON = await coreAPI.getDataFromJSON()

          if (!dataFromJSON) {
              return
          }

          if (dataFromJSON.data && dataFromJSON.data.brands) {
              brands_eats = dataFromJSON.data.brands
          }
          if (dataFromJSON.data && dataFromJSON.data.apps) {
              apps = dataFromJSON.data.apps
          }
          this.setState({brands_eats, apps})
      }
      catch(getDataErr) {
          log(getDataErr)
      }
  }

  handleSearchDisplay = (bool) => {
      if (document.getElementById('srn-product-search-result-container')) {
        document.getElementById('srn-product-search-result-container').style.display = 'block'
      }
  }

  handleBannerClick = (e, banner) => {
    const brand = banner.brand
    // Replace space with dash
    const brandName = brand.replace(/\s+/g, "-").toLowerCase()
    
    // Get slide container
    const parentEl = e?.currentTarget?.parentNode?.parentNode?.parentNode
    const prevSibling = parentEl?.previousSibling
    const nextSibling = parentEl?.nextSibling

    // Get slide container classlist
    const parentClassList = parentEl?.classList
    
    // Check if container is the main slide
    if (parentClassList?.contains('slick-current')) {
      if (banner?.url?.length > 0) {
        window.location = banner.url
        return;
      }
      if (brand.length > 0) {
        window.location = `/eats-products?${brandName}`
      }
    }
    // Check if container is positioned right
    else if (prevSibling?.classList?.contains('slick-current')) {
      this.sliderRef.current.slickNext();
    }
    // Check if container is positioned left
    else if (nextSibling?.classList?.contains('slick-current')) {
      this.sliderRef.current.slickPrev();
    }
    // If the above conditions were not met, it means the user is on a mobile view, which utilizes the previous/next element from react-slick.
    else {
      if (banner?.url?.length > 0) {
        window.location = banner.url
        return;
      }
      if (brand.length > 0) {
        window.location = `/eats-products?${brandName}`
      }
    }
  }

  handleMidBannerClick = (url) => {
    if (url && url?.length > 0) {
      window.location = url
    }
  }

  brandSelection = () => {
    if (this.state.isDropdownActive != true) {
      this.setState({ isDropdownActive: true });
    } else {
      this.setState({ isDropdownActive: false });
    }
  };

  handleFoodBrandSelect = (name, id) => {
    setStorage("brand_id", id);
    sessionStorage.setItem("brand_id", id);
    sessionStorage.setItem("brand_name", name);
    this.setState({
      selectedFoodBrand: sessionStorage.getItem("brand_name"),
      isDropdownActive: false,
    });
    window.location.reload();
  };

  getFoodTypeBanner = () => {
    const foodType = this.state.fromFoodType ? this.state.foodType?.toLowerCase() : "fromBrandSelect";

    switch (foodType) {
      case "chicken":
        return ChickenBanner;
      case "burgers":
        return BurgerBanner;
      case "beverages":
        return BeverageBanner;
      case "desserts":
        return DesertBanner;
      case "dimsum":
        return DimsumBanner;
      case "noodles":
        return NoodleBanner;
      case "pizza":
        return PizzaBanner;
      case "seafood":
        return SeafoodBanner;
      default:
        return ""
    }
  }

  render() {
    const settings = {
      dots: true,
      speed: 500,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '0px',
      slidesToShow: 1,
      adaptiveHeight: false,
      autoplay: false,
      infinite: true,
      cssEase: 'linear',
      variableWidth: true,
      variableHeight: true,
      prevArrow: <span>Prev</span>,
      nextArrow: <span>Next</span>,
      responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: '0px',
                swipeToSlide: true,
                centerMode: true,
                swipe: true,
               
            }
        }
      ]
    };
    return (
      <div className="d-flex flex-column h-100" id="eatmainlandingpage">
        <div
          className={`${this.state.fromFoodType ? "" : "container main-landing-container"}`}
          id={this.state.fromFoodType ? "" : "eat-landing-container"}
        >
          {
            // this.state.product.brand_id && this.state.access_token ?
            <LocationPicker
              access_token={this.state.access_token}
              id="eats_landing_page"
              x_app_key={this.state.app_key}
              hidden={true}
              brand_id={localStorage.getItem("brand_id")}
              handleLocationChange={this.handleLocationChange}
              handleModalClose={this.handleModalClose}
              cart_items={this.state.cart_items}
              isPickupDisabled={true}
              triggerGetDefaultAddress={this.state.triggerGetDefaultAddress}
            />
          }

          {/* Food Deals */}
          <div className={`row no-gutters food-type-content ${this.state.fromFoodType ? "" : "d-none"}`}>
            <div className="col-12">
              <div className="food-type-banner-container">
                <img alt="Food Type Banner" className="food-type-banner-image" src={this.getFoodTypeBanner()} />
              </div>
            </div>
            <div className="col-12">
              <div className="foodcatcontainer">
                <div className="food-deals-products-header">
                  <span>{this.state.foodType}</span>
                </div>
                <div className="food-type-brand-select d-flex">
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      onClick={() => this.brandSelection()}
                    >
                      <span>{this.state.selectedFoodBrand}</span>
                    </button>
                    <div>
                      {this.state.isDropdownActive && (
                        <div className="food-type-brand-menu">
                          <li onClick={(e) => this.handleFoodBrandSelect("All brands", 0)}>
                            All brand
                          </li>
                          {this.state.brands?.map((brand) => {
                            return (
                              <li className={`brand-${brand.id}`}
                                onClick={(e) =>
                                  this.handleFoodBrandSelect(brand.name, brand.id)
                                }
                              >
                                {brand.name}
                              </li>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div
                id="food-deals"
                className="srn-products-best-picks bestpickshover"
              ></div>
            </div>
          </div>
          <div className={`cmb-eats-banner ${this.state.fromFoodType ? "d-none" : ""}`}>
            <div className="srn-landing-container">
              <div className="srn-main-landing-content srn-eats-landing-container">
                {/* <div className="srn-ad-container srn-eats-categories d-none">
                  <div className="srn-sticky-container d-none">
                    <div className="srn-sticky-content d-none">
                      <h6>Select Brands</h6>
                      <BrandListComponent
                        id="srn-category-list-container-side2"
                        brands={this.state.brands}
                        handleGotoProducts={this.handleGotoProductsFromCategory}
                        handleGotoBrand={this.handleGotoProducts}
                      />
                    </div>
                  </div>
                </div>
                <div id="side-categ" className="sidenav side-categ">
                  <h6>Select Brands</h6>
                  <BrandListComponent
                    id="srn-category-list-container"
                    brands={this.state.brands}
                    handleGotoProducts={this.handleGotoProductsFromCategory}
                    handleGotoBrand={this.handleGotoProducts}
                  />
                </div>
                <div
                  id="side-categ-backdrop"
                  className="side-menu-backdrop side-menu-backdrop-transition"
                  onClick={() => this.closeSideNav()}
                ></div> */}
                <div className="srn-main-content-container">
                
                {/* <Slider {...settings} data-slick="arrows" arrows="true">
                  
                      <div className={`slider__item `} >
                          <img className="web-banner" src={eats1}/>
                      </div>
                      <div className={`slider__item `} >
                          <img className="web-banner" src={eats2}/>
                      </div>
                      <div className={`slider__item `} >
                          <img className="web-banner" src={eats3}/>
                      </div>
                      <div className={`slider__item `} >
                          <img className="web-banner" src={eats4}/>
                      </div>
               
                </Slider> */}

                <Slider {...settings} data-slick="arrows" arrows="true" ref={this.sliderRef}>
                  {
                    this.state.banners.filter(y => y.status == 1).map((banner, index) => (
                      <div className={`slider__item `} >
                        {
                          banner.image &&
                          <img
                            className="web-banner"
                            src={banner.image || ''}
                            alt={banner.title || ''}
                            onClick={(e) => this.handleBannerClick(e, banner)}
                          /> || null
                        }
                      </div>
                    ))
                  }
                </Slider>
                  <div className="d-none">
                    {
                      this.state.banners && this.state.banners.length > 0 &&
                      <div className="srn-eats-banner-container">
                        <div
                          id="carouselExampleIndicators"
                          className="carousel slide carousel-fade srn-eats-carousel"
                          data-ride="carousel"
                          data-interval="12000"
                        >
                          {/* <div class="carousel-inner">
                            {

                                this.state.banners.filter(y => y.status == 1).map((banner, index) => (
                                  <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                    {
                                      banner.image &&
                                      <img
                                        className="d-block w-100 web-banner"
                                        src={banner.image || ''}
                                        alt={banner.title || ''}
                                      /> || null
                                    }
                                  </div>
                                ))
                            }
                          </div> */}
                          <div className="header-controls">
                            <a
                              className="srn-product-tags-list-arrow srn-product-tags-list-arrow-left"
                              href="#carouselExampleIndicators"
                              role="button"
                              data-slide="prev"
                            >
                              <FontAwesomeIcon icon={faAngleLeft} />
                            </a>
                            <a
                              className="srn-product-tags-list-arrow srn-product-tags-list-arrow-right"
                              href="#carouselExampleIndicators"
                              role="button"
                              data-slide="next"
                            >
                              <FontAwesomeIcon icon={faAngleRight} />
                            </a>
                          </div>
                        </div>
                        <div className="cmb-best-brands d-none">
                          <h5>Best Brands</h5>
                        </div>
                      </div>
                    }
                  </div>

                  {/* <div className="cmb-best-brands-container d-none">
                    <div
                      id="srn-brand-selector-container"
                      className="srn-eats-brand-selector-container"
                    ></div>
                  </div> */}

                  <div className="eats-landing-div-containers row justify-content-center">
                    <div className="col-12">
                      <div className="food-type-container">
                        <p className="food-type-header">CRAVING FOR SOMETHING?</p>
                        <div id="srn-food-type-component" className="srn-food-type-component-container srn-food-type-component-style-2"></div>
                      </div>
                    </div>
                  </div>

                  <div className="eats-landing-div-containers">
                    <div className="cmb-best-brands cmb-whats-new">
                      <h5>Central Eats Exclusives</h5>
                    </div>
                    <div id="srn-double-treats-component" className="srn-landing-product-list-container srn-double-treats-products srn-eats-products-style-2"></div>
                  </div>
                  <div class="topdivider"></div>          
                  {
                      /** 
                       * Middle Banner 
                       * Get mid banner from brands JSON file
                       */
                      this.state.mid_banner && this.state.mid_banner?.image?.length > 0 &&
                      <div className="srn-cntrl-mid-banner cntrl-mid-banner-2">
                        <img src={this.state.mid_banner?.image} alt={this.state.mid_banner?.title} onClick={() => this.handleMidBannerClick(this.state.mid_banner?.url)} />
                      </div>
                  }
                  <div className="eats-landing-div-containers ">
                           
                    <div className="cmb-best-brands cmb-whats-new" style={{marginBottom: "11px"}}>
                      <h5>Our Restaurants</h5>
                    </div>
                    <BrandsComponent data={this.state.brands_eats} handlePageRedirect={this.handleGotoProductsEats}/>
                  </div>
                  <div class="topdivider"></div>
                  <div className="eats-landing-div-containers">
                    <div className="cmb-best-brands cmb-whats-new">
                      <h5>Best Sellers</h5>
                    </div>
                    <div id="srn-payday-component" className="srn-landing-product-list-container srn-payday-products srn-eats-products-style-2"></div>
                  </div>

                  <div className="eats-landing-div-containers eats-middle-ads w-100">
                    <div className="eats-ads-container w-100">
                      <img className=" w-100" src={bannertop2}/>
                    </div>
                  </div>

                  <div className="eats-landing-div-containers">
                    <div className="cmb-best-brands cmb-whats-new">
                      <h5>Delicious Deals, Perfect Meals</h5>
                    </div>
                    <div id="srn-payday-component2" className="srn-landing-product-list-container srn-payday-products srn-eats-products-style-2"></div>
                  </div>

                  <div className="eats-landing-div-containers">
                    <div className="d-none cmb-best-brands">
                      <h5>FOOD DEALS</h5>
                    </div>
                    <div id="srn-food-deals-component" className="d-none srn-landing-product-list-container srn-food-deals-products srn-eats-products-style-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal eats-landing-product-description"
          id="descriptionDiscountModal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content srn-ft-main-container"
              id="eats-product-modal"
            >
              <div className="modal-header srn-eats-product-details-header">
                <img src={EatsLogo} className="srn-cart-details-app-logo" />
                {/* <img src={brand_logo} className="srn-ft-brand-logo" /> */}
                <button type="button" className="close d-block" data-dismiss="modal" aria-label="Close">
                    <span >&times;</span>
                </button>
              </div>
              <div className="modal-body" id="eats-product-modal">
                <div
                  id="srn-demo-product-description-component"
                  className="srn-demo-product-description-component"
                ></div>
                <h6
                  className={`srn-best-partner-product-label ${
                    this.state.similar_items.length > 0 ? "" : "d-none"
                  }`}
                >
                  Best Partner
                </h6>
                <div
                  id="srn-partner-product-container"
                  className={`srn-partner-product-container ${
                    this.state.similar_items.length > 0 ? "" : "d-none"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div id="srn-eats-cart-container" className="d-none"></div>
      </div>
    );
  }
}